import {
    SET_ACTIVE_SOURCE,
    HANDLE_NEED_CONFIRMATION,
    SET_FILTERED_ONLY_COUNT,
    RESET_TO_INITIAL_STATE,
    SET_SOURCE_PAYLOAD,
    HANDLE_SHOW_CONFIRMATION
} from "./triSourceActions";
import { Action, TriSourceInitialState } from "./types";

const TriSourceReducer = (
    state: TriSourceInitialState,
    action: Action
): TriSourceInitialState => {
    switch (action.type) {
        case SET_SOURCE_PAYLOAD: {
            const { newSourcePayload, isReady, isSelectedSingleItem } =
                action.payload;

            return {
                ...state,
                sourcePayload: newSourcePayload,
                isSourceReady: isReady,
                isSelectedSingleItem
            };
        }
        case SET_ACTIVE_SOURCE: {
            return {
                ...state,
                activeSource: action.payload
            };
        }
        case HANDLE_NEED_CONFIRMATION: {
            return {
                ...state,
                needConfirmation: action.payload
            };
        }
        case SET_FILTERED_ONLY_COUNT: {
            return {
                ...state,
                filteredOnlyCount: action.payload
            };
        }
        case HANDLE_SHOW_CONFIRMATION: {
            return {
                ...state,
                showConfirmation: action.payload
            };
        }
        case RESET_TO_INITIAL_STATE: {
            const { source, sourcePayload, isReady, isSelectedSingleItem } =
                action.payload;

            return {
                ...state,
                activeSource: source,
                sourcePayload,
                isSourceReady: isReady,
                isSelectedSingleItem,
                filteredOnlyCount: null,
                needConfirmation: true,
                showConfirmation: false
            };
        }
        default:
            return state;
    }
};

export default TriSourceReducer;
