import {
    FILTER_NAMES,
    FilterDropdownOptions,
    QueryParams,
    filteredExistingItems
} from "../../../shared";

export const getCompanyFilteredValues = (
    filterDropdownOptions: FilterDropdownOptions,
    params: QueryParams
) => {
    const companyFilterOptions = filterDropdownOptions[FILTER_NAMES.CompanyId];

    if (companyFilterOptions) {
        const companyValues = filteredExistingItems(
            companyFilterOptions,
            params[FILTER_NAMES.CompanyId]
        );

        return {
            filterName: FILTER_NAMES.CompanyId,
            value: companyValues
        };
    }

    return undefined;
};
