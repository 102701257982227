/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { DIRECTIONS, TabButtonProps } from "./types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

const SegmentedToggleButtons = (
    scrollButtonLeftMargin: string,
    scrollButtonRightMargin: string,
    props: TabButtonProps
) => {
    const isLeftButton = props.direction === DIRECTIONS.Left;

    return (
        <Button
            {...props}
            color={BUTTON_COLORS.White}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.IconOnly}
            icon={
                isLeftButton ? (
                    <ChevronLeftRoundedIcon />
                ) : (
                    <ChevronRightRoundedIcon />
                )
            }
            css={css({
                alignSelf: "center",
                boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)",
                margin: isLeftButton
                    ? scrollButtonLeftMargin
                    : scrollButtonRightMargin
            })}
        />
    );
};

export default SegmentedToggleButtons;
