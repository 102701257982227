/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { ContentBoxProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../../../context/theme/themeContext";
import InfoIcon from "../../../../../../assets/customIcons/status/InfoIcon";

const ContentBox = ({
    children,
    title,
    description,
    ...props
}: ContentBoxProps) => {
    const {
        colorsFacelift: { blue700, gray300, textDark }
    } = useContext(ThemeContext);

    return (
        <div {...props}>
            <div
                css={css({
                    marginBottom: "8px",
                    display: "flex",
                    alignItems: "center"
                })}
            >
                <span
                    css={css({
                        color: textDark,
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.15px"
                    })}
                >
                    {title}
                </span>

                {description && (
                    <TooltipGeneral
                        title={description}
                        placement={TOOLTIP_PLACEMENT.Top}
                    >
                        <span
                            css={css({
                                display: "flex",
                                marginLeft: "4px"
                            })}
                        >
                            <InfoIcon
                                css={css({
                                    fontSize: "16px",
                                    color: blue700
                                })}
                            />
                        </span>
                    </TooltipGeneral>
                )}
            </div>

            <div
                css={css({
                    border: `1px solid ${gray300}`,
                    borderRadius: "8px",
                    padding: "15px 23px",
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px"
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default ContentBox;
