import { useContext } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import { getFilteredQueryParams, getStringifiedParams } from "../../../shared";

const useStatisticsStringParams = () => {
    const { queryParams } = useContext(ParamsContext);

    const filteredParams = getFilteredQueryParams(queryParams, true);

    return getStringifiedParams(filteredParams);
};

export default useStatisticsStringParams;
