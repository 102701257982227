import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Description from "../components/Description";
import { FromFileProps } from "../types";

import { FILE_NAME } from "../../../Devices/types";

import Dropzone from "../../../../../Dropzone";
import SampleTemplate from "../../../../../DesignComponents/SampleTemplate";

import DropzoneContext from "../../../../../../context/dropzone/dropzoneContext";
import TriSourceContext from "../../../../../../context/triSource/triSourceContext";
import { TRI_SOURCES } from "../../../../../../shared";

const FromFile = ({ deviceId, description, setCount }: FromFileProps) => {
    const { lineCount } = useContext(DropzoneContext);
    const { handleNeedConfirmation } = useContext(TriSourceContext);

    const { t } = useTranslation();

    // Check if confirmation is required
    useEffect(() => {
        handleNeedConfirmation(lineCount > 20);
        setCount && setCount(lineCount);
    }, [lineCount, handleNeedConfirmation, setCount]);

    return (
        <div data-testid={`tri-source-${TRI_SOURCES.FromFile}`}>
            <Description
                text={t(
                    `Dialog##tri source##from file tab description##${description}`
                )}
            />

            {deviceId}

            <Dropzone supportedFormats={[".csv"]} countCsv />

            <SampleTemplate fileName={FILE_NAME.SampleImeiList} />
        </div>
    );
};

export default FromFile;
