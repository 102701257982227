import { useState } from "react";
import { DeleteOutlineRounded, ModeEdit } from "@mui/icons-material";

import { useDialog } from "../../ActionBar/hooks";
import { LICENSE_ACTIONS } from "../../types";

const useDesktopLicenseInlineActions = () => {
    const { isOpenDialog, closeDialog, openDialog } = useDialog();

    const [rowData, setRowData] = useState(null);

    const openLicenseDialog = (
        dialogName:
            | LICENSE_ACTIONS.EditDesktopLicense
            | LICENSE_ACTIONS.DeleteDesktopLicense,
        data: any
    ) => {
        setRowData(data);
        openDialog(dialogName);
    };

    const closeLicenseDialog = (
        dialogName:
            | LICENSE_ACTIONS.EditDesktopLicense
            | LICENSE_ACTIONS.DeleteDesktopLicense
    ) => {
        setRowData(null);
        closeDialog(dialogName);
    };

    const inlineActions = [
        {
            title: "edit desktop license",
            icon: ModeEdit,
            clickAction: (data: any) =>
                openLicenseDialog(LICENSE_ACTIONS.EditDesktopLicense, data)
        },
        {
            title: "delete desktop license",
            icon: DeleteOutlineRounded,
            clickAction: (data: any) =>
                openLicenseDialog(LICENSE_ACTIONS.DeleteDesktopLicense, data)
        }
    ];

    return {
        inlineActions,
        rowData,
        isEditOpen: isOpenDialog[LICENSE_ACTIONS.EditDesktopLicense],
        isDeleteOpen: isOpenDialog[LICENSE_ACTIONS.DeleteDesktopLicense],
        closeLicenseDialog
    };
};

export default useDesktopLicenseInlineActions;
