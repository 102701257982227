/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";
import { ROUTES } from "../../../shared";

const ForgotPassword = () => {
    const { t } = useTranslation();

    return (
        <TextLink
            component={RouterLink}
            to={ROUTES.RequestReset}
            underline="none"
            size={TEXT_LINK_SIZES.Normal}
            css={css({
                margin: "4px 0 16px 0"
            })}
        >
            {t("Auth##forgot password")}
        </TextLink>
    );
};

export default ForgotPassword;
