import { useContext } from "react";

import { FileType } from "../types";

import AuthContext from "../../../../../context/auth/authContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import { FILE_TYPES, isInternalTypeCompany } from "../../../../../shared";

const useFileTypes = () => {
    const { isAdminUser, isAdminManagerUser, isCarrierUser } =
        useContext(AuthContext);

    const { rootCompanyType } = useContext(ParamsContext);

    const types: FileType[] = [
        {
            value: FILE_TYPES.Configuration,
            extensions: [".cfg"],
            isAllowedToRender: true
        },
        {
            value: FILE_TYPES.Firmware,
            extensions: [".e.xim", ".e.cfw"],
            isAllowedToRender: true
        },
        {
            value: FILE_TYPES.Certificate,
            extensions: [".pem"],
            isAllowedToRender: true
        },
        {
            value: FILE_TYPES.BlueNrg,
            extensions: [".bin"],
            isAllowedToRender: true
        },
        {
            value: FILE_TYPES.BleFw,
            extensions: [".bin"],
            isAllowedToRender: true
        },
        {
            value: FILE_TYPES.AdasFw,
            extensions: [".zip"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.DsmFw,
            extensions: [".zip"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.GnssmDownloadAssistant,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.GnssmFw,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.CanChipFw,
            extensions: [".fwc"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.FmObdChipFw,
            extensions: [".fwc"],
            isAllowedToRender: isAdminManagerUser
        },
        {
            value: FILE_TYPES.Psub,
            extensions: [".bin"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.CarrierApxFw,
            extensions: [".tar"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },
        {
            value: FILE_TYPES.CarrierAdvanceFw,
            extensions: [".hex", ".bex"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },
        {
            value: FILE_TYPES.CarrierApxSettings,
            extensions: [".set"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },
        {
            value: FILE_TYPES.CarrierAdvanceSettings,
            extensions: [".set"],
            isAllowedToRender: isAdminUser || isCarrierUser
        },
        {
            value: FILE_TYPES.MeiGModemFw,
            extensions: [".pack"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.QuectelModemFw,
            extensions: [".bin", ".pack", ".zip"],
            isAllowedToRender: isAdminUser
        },
        {
            value: FILE_TYPES.Dtb,
            extensions: [".e.xim"],
            isAllowedToRender: isAdminManagerUser
        },
        {
            value: FILE_TYPES.FirmwareBundle,
            extensions: [".tpack"],
            isAllowedToRender:
                isAdminManagerUser && isInternalTypeCompany(rootCompanyType)
        }
    ];

    const visibleTypes = types.filter(type => type.isAllowedToRender);

    return { visibleTypes };
};

export default useFileTypes;
