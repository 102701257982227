/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Navigate } from "react-router-dom";

import TwoFactorSuggestion from "./TwoFactorSuggestion";

import Logo from "../../Logo";

import AuthContext from "../../../context/auth/authContext";
import { ROUTES } from "../../../shared";

const TwoFactorRequirement = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated ? (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                padding: "16px 24px"
            })}
        >
            <Logo />
            <TwoFactorSuggestion />
        </div>
    ) : (
        <Navigate to={{ pathname: ROUTES.Login }} replace />
    );
};

export default TwoFactorRequirement;
