import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState
} from "react";
import { useTranslation } from "react-i18next";

import {
    CookieButtonType,
    CookiesMainDescription,
    CookiesModalContent
} from ".";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import Dialog from "../../DesignComponents/Dialog";

import AuthContext from "../../../context/auth/authContext";
import { COOKIE_NAMES } from "../../../shared";

interface ICookiesModalProps {
    setShowBanner: Dispatch<SetStateAction<boolean>>;
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
}

const CookiesModal = ({
    isOpen,
    onClose,
    setShowBanner
}: ICookiesModalProps) => {
    const { setCookieConsent } = useContext(AuthContext);
    const { t } = useTranslation();

    const [isAnalyticsCookies, setIsAnalyticsCookies] = useState(false);

    const handleCloseDialog = useCallback(() => {
        onClose(false);
    }, [onClose]);

    const handleOnlyEssentialCookies = () => {
        setCookieConsent(COOKIE_NAMES.All, false);
        handleCloseDialog();
        setShowBanner(false);
    };

    const handleAcceptSelected = () => {
        setCookieConsent(COOKIE_NAMES.All, isAnalyticsCookies);
        handleCloseDialog();
        setShowBanner(false);
    };

    const COOKIE_MODAL_ACTION_BUTTONS: CookieButtonType[] = [
        {
            color: BUTTON_COLORS.Primary,
            buttonText: t("Button##only essential"),
            action: handleOnlyEssentialCookies
        },
        {
            color: BUTTON_COLORS.Primary,
            buttonText: t("Button##accept selected"),
            action: handleAcceptSelected
        }
    ];

    return (
        <Dialog
            title={t("Cookies##cookie modal title")}
            description={<CookiesMainDescription />}
            open={isOpen}
            close={handleCloseDialog}
            isTitleSeparator
            isActionsSeparator
            actions={
                <>
                    {COOKIE_MODAL_ACTION_BUTTONS.map(
                        (button: CookieButtonType) => (
                            <Button
                                key={button.buttonText}
                                fullWidth
                                color={button.color}
                                size={BUTTON_SIZES.Normal}
                                variant={BUTTON_VARIANTS.TextOnly}
                                onClick={button.action}
                            >
                                {button.buttonText}
                            </Button>
                        )
                    )}
                </>
            }
        >
            <CookiesModalContent
                setIsAnalyticsCookies={setIsAnalyticsCookies}
                isAnalyticsCookies={isAnalyticsCookies}
            />
        </Dialog>
    );
};

export default CookiesModal;
