/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { SeparatorProps } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Separator = ({ isListSeparator, ...props }: SeparatorProps) => {
    const {
        colorsFacelift: { gray300 }
    } = useContext(ThemeContext);

    return isListSeparator ? (
        <li
            {...props}
            css={css({
                height: "1px",
                background: gray300
            })}
        />
    ) : (
        <div
            {...props}
            css={css({
                height: "1px",
                background: gray300
            })}
        />
    );
};

export default Separator;
