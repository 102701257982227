import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import DesktopLicenseStatusIcon from "../../../Table/TableItem/CustomValues/DesktopLicenseStatusIcon";
import DateTime from "../../../DateTime";

import TableContext from "../../../../context/table/tableContext";

const General = () => {
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const {
        company: { name: companyName },
        license_key,
        hardware_key,
        client_ip,
        enabled,
        expires_at
    } = detailsData;

    return (
        <>
            <div>{t("Table##general")}</div>

            <div>
                <>
                    <span>{t("General##company")}:</span>

                    <span>
                        {companyName}

                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={companyName}
                        />
                    </span>
                </>

                <>
                    <span>{t("Table##license key")}:</span>

                    <span>
                        {license_key}

                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={license_key}
                        />
                    </span>
                </>

                <>
                    <span>{t("General##hardware key")}:</span>

                    <span>
                        {hardware_key}

                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={hardware_key}
                        />
                    </span>
                </>

                {client_ip !== null && (
                    <>
                        <span>{t("General##client ip")}:</span>

                        <span>
                            {client_ip}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={client_ip}
                            />
                        </span>
                    </>
                )}

                <>
                    <span>{t("Table##status")}:</span>

                    <span>
                        <DesktopLicenseStatusIcon isEnabled={enabled} />
                    </span>
                </>

                <>
                    <span>{t("Table##expiration date")}:</span>

                    <span>
                        <DateTime date={expires_at} format="L" />
                    </span>
                </>
            </div>
        </>
    );
};

export default General;
