/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Grid } from "@mui/material";

import StatsEntry from "./StatsEntry";

import { CompanyStatsProps } from "../types";

const CompanyStats = ({ source, target, isMoveAction }: CompanyStatsProps) => {
    return (
        <div
            css={css({
                marginBottom: "16px"
            })}
        >
            <Grid container>
                <Grid
                    data-testid="source-company-stats-grid"
                    item
                    xs={6}
                    css={css({
                        paddingRight: "16px"
                    })}
                >
                    {source && (
                        <StatsEntry data={source} isMoveAction={isMoveAction} />
                    )}
                </Grid>

                <Grid
                    data-testid="target-company-stats-grid"
                    item
                    xs={6}
                    css={css({
                        paddingLeft: "16px"
                    })}
                >
                    {target && (
                        <StatsEntry data={target} isMoveAction={isMoveAction} />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default CompanyStats;
