/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import TlsCertificate from "./TlsCertificate";
import InternalLog from "./InternalLog";
import CanAdapters from "./CanAdapters";
import CanVehicles from "./CanVehicles";
import FeatureControl from "./FeatureControl";
import GeneralDropdown from "./GeneralDropdown";
import { CUSTOM_SECOND_SELECT, SecondSelectContentProps } from "./types";

const SecondSelectContent = ({
    secondSelectName,
    type,
    changePayload
}: SecondSelectContentProps) => {
    const renderContent = () => {
        switch (secondSelectName) {
            case CUSTOM_SECOND_SELECT.Certificate:
                return (
                    <TlsCertificate
                        secondSelectName={secondSelectName}
                        changePayload={changePayload}
                    />
                );
            case CUSTOM_SECOND_SELECT.Logs:
                return <InternalLog changePayload={changePayload} />;
            case CUSTOM_SECOND_SELECT.CanAdapters:
                return (
                    <CanAdapters type={type} changePayload={changePayload} />
                );
            case CUSTOM_SECOND_SELECT.CanVehicles:
                return <CanVehicles changePayload={changePayload} />;
            case CUSTOM_SECOND_SELECT.FeatureControl:
                return <FeatureControl changePayload={changePayload} />;
            default:
                return (
                    <GeneralDropdown
                        secondSelectName={secondSelectName}
                        changePayload={changePayload}
                    />
                );
        }
    };

    return (
        <div
            css={css({
                marginTop: "16px"
            })}
        >
            {renderContent()}
        </div>
    );
};

export default SecondSelectContent;
