import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isSortableValueCorrect } from "./functions";
import { SortableLogsProps } from "./types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../../DesignComponents/InputField";

const SortableLogs = ({ order, value, change }: SortableLogsProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setError(null);
    }, [order]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isCorrectValue = isSortableValueCorrect(newValue);

        const errorValue =
            newValue && !isCorrectValue ? t("Dialog##invalid value") : null;

        setError(errorValue);
        change({ count: newValue });
    };

    return (
        <InputField
            fullWidth
            required
            size={INPUT_FIELD_SIZES.Medium}
            labelLeft={t("Dialog##files to download")}
            placeholder={`${t("Dialog##count")} (1 - 10)`}
            value={value}
            errorText={error}
            onChange={handleChange}
        />
    );
};

export default SortableLogs;
