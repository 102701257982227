import { useContext, useState, useEffect } from "react";

import { MobileDetailsProps } from "./types";
import DialogCustom from "../DesignComponents/DialogCustom";
import TableContext from "../../context/table/tableContext";

const MobileDetails = ({ children }: MobileDetailsProps) => {
    const { detailsData, setDetailsData } = useContext(TableContext);

    const [isOpen, setIsOpen] = useState(Boolean(detailsData));

    useEffect(() => {
        detailsData ? setIsOpen(true) : setIsOpen(false);
    }, [detailsData]);

    return detailsData ? (
        <DialogCustom
            fullWidth
            open={isOpen}
            close={() => setDetailsData(null)}
            transitionDuration={0}
            customPadding="16px 24px"
        >
            {children}
        </DialogCustom>
    ) : (
        <div></div>
    );
};

export default MobileDetails;
