import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import "moment/locale/en-gb";
import "moment/locale/lt";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/fr";

import LanguageContext from "./languageContext";
import { LanguageInitialState, LanguageStateProps } from "./types";

import AuthContext from "../auth/authContext";

import ukFlagSrc from "../../assets/flags/UnitedKingdom.png";
import ltFlagSrc from "../../assets/flags/Lithuania.png";
import ruFlagSrc from "../../assets/flags/Russia.png";
import esFlagSrc from "../../assets/flags/Spain.png";
import ptFlagSrc from "../../assets/flags/Portugal.png";
import frFlagSrc from "../../assets/flags/France.png";

import {
    ENDPOINTS,
    getValidLanguage,
    I18_NEXT_LANG,
    LANGUAGES,
    LOCALES,
    useApi
} from "../../shared";

const LanguageState = ({ children }: LanguageStateProps) => {
    const { user } = useContext(AuthContext);

    const { i18n } = useTranslation();
    const { updateData, handleResponse } = useApi();

    const getLanguage = () => {
        const localStorageLang = localStorage.getItem(I18_NEXT_LANG);

        return getValidLanguage(localStorageLang, i18n.language as LANGUAGES);
    };

    const state: LanguageInitialState = {
        languages: [
            {
                id: LANGUAGES.English,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.English)
                ].toLowerCase(),
                flagSrc: ukFlagSrc,
                momentLocale: LOCALES.Lithuanian
            },
            {
                id: LANGUAGES.Lithuanian,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.Lithuanian)
                ].toLowerCase(),
                flagSrc: ltFlagSrc,
                momentLocale: LOCALES.Lithuanian
            },
            {
                id: LANGUAGES.Russian,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.Russian)
                ].toLowerCase(),
                flagSrc: ruFlagSrc,
                momentLocale: LOCALES.Russian
            },
            {
                id: LANGUAGES.Spanish,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.Spanish)
                ].toLowerCase(),
                flagSrc: esFlagSrc,
                momentLocale: LOCALES.Spanish
            },
            {
                id: LANGUAGES.Portuguese,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.Portuguese)
                ].toLowerCase(),
                flagSrc: ptFlagSrc,
                momentLocale: LOCALES.Portuguese
            },
            {
                id: LANGUAGES.French,
                text: Object.keys(LANGUAGES)[
                    Object.values(LANGUAGES).indexOf(LANGUAGES.French)
                ].toLowerCase(),
                flagSrc: frFlagSrc,
                momentLocale: LOCALES.French
            }
        ],
        language: getLanguage()
    };

    useEffect(() => {
        setMomentLocale(state.language);
        // eslint-disable-next-line
    }, []);

    const setMomentLocale = useCallback(
        (newLang: LANGUAGES) => {
            const currentLanguage = state.languages.find(
                lang => lang.id === newLang
            );

            const localeLanguage = currentLanguage
                ? currentLanguage.momentLocale
                : "lt";

            moment.locale(localeLanguage);
        },
        [state.languages]
    );

    const setNewLanguage = useCallback(
        async (lang: LANGUAGES) => {
            i18n.changeLanguage(lang as unknown as string);
            localStorage.setItem(I18_NEXT_LANG, lang as unknown as string);
            setMomentLocale(lang);

            try {
                const payload = { language: lang };
                await updateData(ENDPOINTS.Users, user?.id as number, payload);
            } catch (error) {
                handleResponse(error);
            }
        },
        [i18n, user?.id, setMomentLocale, updateData, handleResponse]
    );

    const value = useMemo(
        () => ({
            languages: state.languages,
            language: state.language,
            setNewLanguage
        }),
        [state.languages, state.language, setNewLanguage]
    );

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageState;
