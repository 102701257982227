/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CompanyLevelProps } from "../types/interfaces";
import useCompanyLevelContent from "../../useCompanyLevelContent";

const CompanyLevelRectangle = ({ companyLevelName }: CompanyLevelProps) => {
    const { companyLevelHeaderParams } = useCompanyLevelContent();

    return (
        <div
            css={css({
                backgroundColor:
                    companyLevelHeaderParams.backgroundColors[
                        companyLevelName as keyof typeof companyLevelHeaderParams.backgroundColors
                    ],
                width: "100%",
                borderRadius: "2px",
                height: "8px"
            })}
        />
    );
};

export default CompanyLevelRectangle;
