/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import useTableHeadToolbar from "./hooks/useTableHeadToolbar";

import Checkbox from "../../DesignComponents/Checkbox";
import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";

import ThemeContext from "../../../context/theme/themeContext";
import { TABLE_NAMES, useMediaQueries } from "../../../shared";
import TableContext from "../../../context/table/tableContext";

interface TableHeadToolbarProps {
    children: ReactNode;
}

const TableHeadToolbar = ({ children }: TableHeadToolbarProps) => {
    const { tableName, deselectAllRows } = useContext(TableContext);

    const {
        colorsFacelift: { blue200, blue400, blue500, textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { toMd, fromMd } = useMediaQueries();

    const {
        atLeastSingleSelected,
        allSelected,
        rowsCount,
        handleCheckboxChange
    } = useTableHeadToolbar();

    return (
        <div
            css={css({
                display: "inline-flex",
                borderRadius: "8px",
                marginRight: "16px",

                "& > div": {
                    display: "flex",
                    alignItems: "center",
                    color: textDark,
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    letterSpacing: "0.1px",
                    border: `1px solid ${blue200}`,
                    marginRight: "-1px",
                    padding: "3px 15px",
                    cursor: "pointer",
                    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },

                "& > div:first-of-type": {
                    borderRadius: "8px 0 0 8px",
                    padding: "3px 15px 3px 7px"
                },

                "& > div:last-of-type": {
                    borderRadius:
                        tableName === TABLE_NAMES.Devices && !toMd
                            ? "8px"
                            : "0 8px 8px 0"
                },

                "& > div:hover": {
                    background: blue400,
                    borderColor: blue400,
                    zIndex: "1"
                },

                "& > div:active": {
                    background: blue500,
                    borderColor: blue500,
                    zIndex: "1"
                }
            })}
        >
            <div
                css={css({
                    div: {
                        marginLeft: toMd ? "6px" : "18px"
                    }
                })}
            >
                <Checkbox
                    label={`${t("Button##rows selected", {
                        count: rowsCount
                    } as any)}`}
                    checked={allSelected}
                    indeterminate={!allSelected && atLeastSingleSelected}
                    onChange={handleCheckboxChange}
                />
            </div>

            {children}

            {fromMd && (
                <TextLink
                    size={TEXT_LINK_SIZES.Normal}
                    onClick={deselectAllRows}
                    css={css({
                        marginLeft: "16px"
                    })}
                >
                    {t("Button##deselect all")}
                </TextLink>
            )}
        </div>
    );
};

export default TableHeadToolbar;
