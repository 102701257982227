/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import TableRowMui from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import TableColumn from "./TableColumn";
import { TableRowProps } from "./types";

import { useTableRowActions } from "../../hooks";

import Checkbox from "../../../DesignComponents/Checkbox";
import InlineActionIconButton from "../../../DesignComponents/Button/InlineActionIconButton";

import ThemeContext from "../../../../context/theme/themeContext";
import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";
import {
    Column,
    RenderConditionOptions,
    noop,
    tableColumnDropShadow,
    useCompanyLevelSettings
} from "../../../../shared";

const TableRow = ({
    data,
    isChecked,
    onCheckboxChange,
    isDisabled,
    scrollHorizontal
}: TableRowProps) => {
    const {
        colorsFacelift: {
            blue100,
            blue200,
            blue700,
            gray50,
            gray200,
            green50,
            white
        }
    } = useContext(ThemeContext);

    const { user, isSystemUser } = useContext(AuthContext);

    const {
        isNotSelectable,
        isTableLoading,
        idType,
        hasDetails,
        columns,
        inlineActions,
        detailsData,
        outerDetailsData,
        setDetailsData
    } = useContext(TableContext);

    const { t } = useTranslation();
    const { blink, resetBlink } = useTableRowActions(data);
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const [isInlineBtnLoading, setInlineBtnLoading] = useState(false);

    const boxShadowBottom = `inset 0px -1px 0px ${gray200}`;
    const checkedLeftBorder = `inset 2px 0px 0px ${blue700}`;

    const handleClick = () => {
        if (hasDetails) {
            setDetailsData(data);
        }

        noop();
    };

    return (
        <TableRowMui
            onAnimationEnd={resetBlink}
            onClick={handleClick}
            selected={detailsData && data[idType] === detailsData[idType]}
            css={css({
                cursor: isTableLoading || !hasDetails ? "auto" : "pointer",

                td: {
                    background: isChecked ? blue100 : white,
                    animationName: blink ? "blink" : "unset",
                    animationDuration: "0.5s",
                    animationTimingFunction: "ease-out",

                    "@keyframes blink": {
                        "25%": {
                            background: green50
                        },

                        "75%": {
                            background: green50
                        }
                    }
                },

                "&:hover td": {
                    background: isChecked ? blue200 : gray50
                }
            })}
        >
            {/* Checkbox */}
            {!isNotSelectable && (
                <TableCell
                    css={css({
                        position: "sticky",
                        left: "0",
                        boxShadow: isChecked
                            ? `${checkedLeftBorder}, ${boxShadowBottom}`
                            : boxShadowBottom,

                        "& > div": {
                            height: "24px"
                        }
                    })}
                >
                    <div>
                        <Checkbox
                            checked={isChecked}
                            disabled={isDisabled}
                            onChange={onCheckboxChange}
                            onClick={e => e.stopPropagation()}
                        />
                    </div>
                </TableCell>
            )}

            {/* Column values */}
            {columns.map(
                ({
                    name,
                    resource,
                    isActive,
                    isSticky,
                    isTranslatable,
                    customDateTimeFormat,
                    restriction
                }: Column) => {
                    const isRestricted =
                        isFeatureSettingsRestricted(restriction);

                    return (
                        isActive && (
                            <TableColumn
                                key={name}
                                data={data}
                                name={name}
                                resource={resource}
                                isRestricted={isRestricted}
                                isSticky={isSticky}
                                isTranslatable={isTranslatable}
                                customDateTimeFormat={customDateTimeFormat}
                                scrollHorizontal={scrollHorizontal}
                            />
                        )
                    );
                }
            )}

            {/* Inline actions */}
            {inlineActions.length > 0 && (
                <TableCell
                    onClick={e => e.stopPropagation()}
                    sx={[
                        {
                            position: "sticky",
                            right: "0",
                            padding: "0 !important",
                            overflowX: "unset !important",
                            background: `${white} !important`,
                            boxShadow: boxShadowBottom,
                            cursor: "auto"
                        },
                        tableColumnDropShadow(scrollHorizontal)
                    ]}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "end",

                            "& > span + span": {
                                marginLeft: "12px"
                            },

                            "& > span:first-of-type": {
                                marginLeft: "18px"
                            },

                            "& > span:last-of-type": {
                                marginRight: "18px"
                            }
                        })}
                    >
                        {inlineActions.map(
                            (
                                {
                                    title,
                                    icon,
                                    hasLoader,
                                    clickAction,
                                    renderConditions,
                                    getDisabledText
                                },
                                index,
                                allActions
                            ) => {
                                const Icon = icon;

                                const getRender = (
                                    renderFunction:
                                        | ((
                                              options: RenderConditionOptions
                                          ) => void)
                                        | undefined
                                ) =>
                                    renderFunction === undefined ||
                                    renderFunction({
                                        data,
                                        outerDetailsData,
                                        user,
                                        isSystemUser
                                    });

                                const getRenderLeftBorder = () => {
                                    if (index !== 0) {
                                        const previousAction =
                                            allActions[index - 1];

                                        return !getRender(
                                            previousAction.renderConditions
                                        );
                                    }

                                    return true;
                                };

                                const render = getRender(renderConditions);
                                const renderLeftBorder = getRenderLeftBorder();

                                const disabledText =
                                    getDisabledText && getDisabledText(data);

                                const onClick = () => {
                                    hasLoader && setInlineBtnLoading(true);

                                    clickAction(data, () =>
                                        setInlineBtnLoading(false)
                                    );
                                };

                                return render ? (
                                    <Fragment key={title}>
                                        {renderLeftBorder && (
                                            <div
                                                css={css({
                                                    position: "absolute",
                                                    top: "0",
                                                    bottom: "0",
                                                    left: "0",
                                                    width: "1px",
                                                    background: gray200
                                                })}
                                            />
                                        )}

                                        <InlineActionIconButton
                                            key={title}
                                            title={t(`General##${title}`)}
                                            icon={<Icon />}
                                            onButtonClick={onClick}
                                            disabledText={
                                                disabledText
                                                    ? t(
                                                          `General##${disabledText}`
                                                      )
                                                    : undefined
                                            }
                                            isLoading={
                                                hasLoader && isInlineBtnLoading
                                            }
                                        />
                                    </Fragment>
                                ) : null;
                            }
                        )}
                    </div>
                </TableCell>
            )}
        </TableRowMui>
    );
};

export default TableRow;
