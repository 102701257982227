import { useContext } from "react";
import moment, { unitOfTime } from "moment-timezone";

import { DateType } from "../types";
import TimeZoneContext from "../../context/timeZone/timeZoneContext";

export const useDateTime = () => {
    const { currentTimeZone } = useContext(TimeZoneContext);

    const currentlLocale = moment.locale();

    const formattedDate = (
        date: DateType,
        format: string = "L LTS",
        locale: string = currentlLocale
    ) => {
        if (date === null || date === "-") {
            return "-";
        }

        return moment
            .utc(date)
            .tz(currentTimeZone)
            .locale(locale)
            .format(format);
    };

    const startOfTime = (period: string) =>
        moment().startOf(period as unitOfTime.StartOf);

    return { formattedDate, startOfTime };
};
