export enum TOOLTIP_SIZES {
    Medium = "medium",
    Small = "small"
}

export enum TOOLTIP_PLACEMENT {
    BottomEnd = "bottom-end",
    BottomStart = "bottom-start",
    Bottom = "bottom",
    LeftEnd = "left-end",
    LeftStart = "left-start",
    Left = "left",
    RightEnd = "right-end",
    RightStart = "right-start",
    Right = "right",
    TopEnd = "top-end",
    TopStart = "top-start",
    Top = "top"
}
