import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const GroupsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M17 18V6C17.55 6 18.021 6.196 18.413 6.588C18.8043 6.97933 19 7.45 19 8V16C19 16.55 18.8043 17.021 18.413 17.413C18.021 17.8043 17.55 18 17 18ZM5 20C4.45 20 3.979 19.8043 3.587 19.413C3.19567 19.021 3 18.55 3 18V6C3 5.45 3.19567 4.979 3.587 4.587C3.979 4.19567 4.45 4 5 4H13C13.55 4 14.021 4.19567 14.413 4.587C14.8043 4.979 15 5.45 15 6V18C15 18.55 14.8043 19.021 14.413 19.413C14.021 19.8043 13.55 20 13 20H5ZM21 16V8C21.4167 8 21.7707 8.146 22.062 8.438C22.354 8.72933 22.5 9.08333 22.5 9.5V14.5C22.5 14.9167 22.354 15.2707 22.062 15.562C21.7707 15.854 21.4167 16 21 16ZM5 18H13V6H5V18Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default GroupsIcon;
