import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TransferDevicesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M23.012 20.1203C23.054 20.237 23.075 20.362 23.075 20.4953C23.075 20.6286 23.054 20.7536 23.012 20.8703C22.9707 20.987 22.9 21.0953 22.8 21.1953L20.2 23.7953C20.0167 23.9786 19.7833 24.0703 19.5 24.0703C19.2167 24.0703 18.9833 23.9786 18.8 23.7953C18.6167 23.612 18.525 23.3786 18.525 23.0953C18.525 22.812 18.6167 22.5786 18.8 22.3953L19.7 21.4953L16.5 21.4953C16.2167 21.4953 15.979 21.3993 15.787 21.2073C15.5957 21.016 15.5 20.7786 15.5 20.4953C15.5 20.212 15.5957 19.9743 15.787 19.7823C15.979 19.591 16.2167 19.4953 16.5 19.4953L19.7 19.4953L18.8 18.5953C18.6167 18.412 18.525 18.1786 18.525 17.8953C18.525 17.612 18.6167 17.3786 18.8 17.1953C18.9833 17.012 19.2167 16.9203 19.5 16.9203C19.7833 16.9203 20.0167 17.012 20.2 17.1953L22.8 19.7953C22.9 19.8953 22.9707 20.0036 23.012 20.1203Z"
                fill="currentColor"
            />
            <path
                d="M4.087 20.913C4.479 21.3043 4.95 21.5 5.5 21.5H13.5V19.5H5.5V8.5H19.5V14.8809H21.5V7C21.5 6.75 21.4583 6.525 21.375 6.325C21.2917 6.125 21.1833 5.93333 21.05 5.75L19.65 4.05C19.5167 3.86667 19.35 3.729 19.15 3.637C18.95 3.54567 18.7333 3.5 18.5 3.5H6.5C6.26667 3.5 6.05 3.54567 5.85 3.637C5.65 3.729 5.48333 3.86667 5.35 4.05L3.95 5.75C3.81667 5.93333 3.70833 6.125 3.625 6.325C3.54167 6.525 3.5 6.75 3.5 7V19.5C3.5 20.05 3.69567 20.521 4.087 20.913ZM19.1 6.5H5.9L6.75 5.5H18.25L19.1 6.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default TransferDevicesIcon;
