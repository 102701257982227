/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";

import FilterListBox from "./FilterListBox";
import { useFilterDropdown } from "./hooks";
import { FilterDropdownProps } from "./types";

import { getFilterDropdownValueIds } from "../functions";

import Autocomplete from "../../DesignComponents/Autocomplete";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    Filter,
    filterDropdownCustomWidth,
    isTranslatableFilter,
    RectangleLoader,
    DROPDOWN_SIZES,
    NOT_ASSIGNED
} from "../../../shared";

const FilterDropdown = ({
    filter,
    placeholder,
    additionalParams,
    containerCustomStyle,
    containerLoaderCustomStyle,
    ...props
}: FilterDropdownProps &
    Omit<
        MuiAutocompleteProps<any, boolean, boolean, boolean>,
        "renderInput" | "size" | "options"
    >) => {
    const { filteredValues: mainFilteredValues, setQueryParams } =
        useContext(ParamsContext);

    const {
        isInnerTable,
        innerTableFilteredValues,
        isInitialLoading,
        setInnerTableParams
    } = useContext(TableContext);

    const { t } = useTranslation();

    const { isOpen, isLoading, options, setOpen } = useFilterDropdown(
        filter,
        additionalParams
    );

    const [value, setValue] = useState<Filter[]>([]);

    const filteredValues = isInnerTable
        ? innerTableFilteredValues
        : mainFilteredValues;

    // Set value
    useEffect(() => {
        const actualFilter = filteredValues.find(
            filteredValue => filteredValue.filterName === filter
        );

        setValue(actualFilter ? actualFilter.value : []);
    }, [filteredValues, filter]);

    const handleChange = (newValue: Filter[]) => {
        const paramsPayload = {
            [filter]: getFilterDropdownValueIds(filter, newValue)
        };

        const filteredValuesPayload = {
            filterName: filter,
            value: newValue
        };

        (isInnerTable ? setInnerTableParams : setQueryParams)({
            params: paramsPayload,
            resetPage: true,
            filteredValues: filteredValuesPayload
        });
    };

    const getOptionLabel = (option: Filter) => {
        const name = String(option.name);
        const isTranslatable = isTranslatableFilter(filter);

        return isTranslatable || name === NOT_ASSIGNED
            ? t(`General##${name}`)
            : name;
    };

    return isInitialLoading ? (
        <RectangleLoader
            customStyle={
                containerLoaderCustomStyle || filterDropdownCustomWidth
            }
            height={36}
        />
    ) : (
        <Autocomplete
            {...props}
            fullWidth
            multiple
            size={DROPDOWN_SIZES.Small}
            includeInputInList
            disableClearable
            disableCloseOnSelect
            open={isOpen}
            value={value}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            loading={isLoading}
            onChange={(_, newFilterValue) => handleChange(newFilterValue)}
            ListboxComponent={FilterListBox}
            renderTags={() => null}
            textFieldParams={{
                placeholder
            }}
            getOptionLabel={(option: Filter) => getOptionLabel(option)}
            customStyle={containerCustomStyle}
            css={css(filterDropdownCustomWidth)}
        />
    );
};

export default FilterDropdown;
