export const CHANGE_ROOT_COMPANY = "CHANGE_ROOT_COMPANY";
export const CHANGE_ROOT_COMPANY_LEVEL = "CHANGE_ROOT_COMPANY_LEVEL";
export const RELOAD_ITEMS = "RELOAD_ITEMS";
export const RESET_ALL_PARAMS = "RESET_ALL_PARAMS";
export const RESET_FILTER_PARAMS = "RESET_FILTER_PARAMS";
export const SET_COMPANY_FILTERED_VALUES = "SET_COMPANY_FILTERED_VALUES";
export const SET_FILTERED_VALUES = "SET_FILTERED_VALUES";
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const SET_RESOURCE = "SET_RESOURCE";
export const TOGGLE_TABLE_ROW_DESELECTION = "TOGGLE_TABLE_ROW_DESELECTION";
export const UPDATE_FILTER_PARAMS = "UPDATE_FILTER_PARAMS";
