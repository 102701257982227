import { TABLE_NAMES } from "../types";

export const useWindowLocation = () => {
    const {
        pathname,
        search: searchStrings,
        host,
        href,
        port
    } = window.location;

    const resource: TABLE_NAMES = pathname.slice(1) as any;

    return { pathname, searchStrings, host, href, port, resource };
};
