/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";

import ThemeContext from "../../context/theme/themeContext";
import { useMediaQueries } from "../../shared";

const TaskGroupsTabLoader = () => {
    const {
        colorsFacelift: { gray100, gray200 }
    } = useContext(ThemeContext);

    const { fromSm, fromMd } = useMediaQueries();

    return (
        <div
            css={css({
                display: "flex",
                columnGap: "32px",
                padding: "20px 24px 12px 24px",
                boxShadow: `inset 0px -1px 0px ${gray200}`,

                "& > span": {
                    height: "20px",
                    borderRadius: "6px",
                    background: gray100
                }
            })}
        >
            <Skeleton variant="rectangular" width={36} />
            <Skeleton variant="rectangular" width={138} />

            {fromSm && <Skeleton variant="rectangular" width={140} />}
            {fromMd && <Skeleton variant="rectangular" width={169} />}
        </div>
    );
};

export default TaskGroupsTabLoader;
