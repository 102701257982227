import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import { Accessory } from "../../../../shared";
import TableContext from "../../../../context/table/tableContext";

const Accessories = () => {
    const { detailsData } = useContext(TableContext);

    const {
        accessories: { data }
    } = detailsData;

    const { t } = useTranslation();

    const renderAccessories = (stringifiedData: string) => {
        const parsedData = JSON.parse(stringifiedData);

        return parsedData.map(({ Id, Type, Interface }: Accessory) => (
            <Fragment key={Id}>
                <Fragment>
                    <span>{t("Table##type")}:</span>

                    <span>
                        {Type}{" "}
                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={Type}
                        />
                    </span>
                </Fragment>

                <Fragment>
                    <span>{t("Table##interface")}:</span>

                    <span>
                        {Interface}{" "}
                        <CopyButton
                            size={BUTTON_SIZES.Tiny}
                            copiedValue={Interface}
                        />
                    </span>
                </Fragment>
            </Fragment>
        ));
    };

    return (
        <Fragment>
            <div>{t("Table##accessories")}</div>

            <div>{renderAccessories(data)}</div>
        </Fragment>
    );
};

export default Accessories;
