import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import TableContext from "../../../../context/table/tableContext";

const Sim = () => {
    const { detailsData } = useContext(TableContext);

    const { gsm_number, iccid, imsi } = detailsData;

    const { t } = useTranslation();

    return (
        <Fragment>
            <div>{t("Table##sim")}</div>

            <div>
                {/* GSM number */}
                {gsm_number && (
                    <Fragment>
                        <span>{t("Table##gsm number")}:</span>

                        <span>
                            {gsm_number}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={gsm_number}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}

                {/* ICCID */}
                {iccid && (
                    <Fragment>
                        <span>{t("Table##iccid")}:</span>

                        <span>
                            {iccid}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={iccid}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}

                {/* IMSI */}
                {imsi && (
                    <Fragment>
                        <span>{t("Table##imsi")}:</span>

                        <span>
                            {imsi}{" "}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={imsi}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}
            </div>
        </Fragment>
    );
};

export default Sim;
