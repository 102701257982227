import { createContext } from "react";

interface TimeZoneContextState {
    currentTimeZone: string;
    changeTimeZone: (timeZone: string) => void;
}

const TimeZoneContext = createContext({} as TimeZoneContextState);

export default TimeZoneContext;
