import { COMPANY_LEVELS, COMPANY_TYPES } from "../../shared";

export const mockedUser = {
    id: 1,
    email: "test@test.lt",
    company_id: 101,
    role: 0,
    language: "Lt",
    company: {} as any,
    invited_by: "me",
    two_factor_auth_enabled: false,
    company_level: COMPANY_LEVELS.Standard,
    company_type: COMPANY_TYPES.Client,
    root_client_company_id: null,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    last_login: new Date().toISOString(),
    deleted_at: new Date().toISOString()
};
