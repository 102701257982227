/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import { UploadedFileSingleProps } from "../types";

import Separator from "../../DesignComponents/Separator";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import Tag, { TAG_COLORS, TAG_SIZES } from "../../DesignComponents/Tag";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";

import ThemeContext from "../../../context/theme/themeContext";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import FileIcon from "../../../assets/customIcons/FileIcon";
import { useFormattedNumber } from "../../../shared";

const UploadedFileSingle = ({ countCsv }: UploadedFileSingleProps) => {
    const {
        colorsFacelift: { gray200, gray700, textPlaceholder }
    } = useContext(ThemeContext);

    const { file, lineCount, setFile } = useContext(DropzoneContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    return (
        <div
            css={css({
                border: `1px solid ${gray200}`,
                borderRadius: "8px",
                padding: "16px"
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center"
                    })}
                >
                    <FileIcon
                        css={css({
                            alignSelf: "flex-start",
                            color: textPlaceholder,
                            marginRight: "8px"
                        })}
                    />

                    <span
                        css={css({
                            color: gray700,
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "600",
                            letterSpacing: "0.1px"
                        })}
                    >
                        {file?.name}
                    </span>
                </div>

                <TooltipGeneral
                    placement={TOOLTIP_PLACEMENT.Top}
                    title={t("Button##delete")}
                    disableTouchListener
                >
                    <Button
                        data-testid="delete-file-button"
                        color={BUTTON_COLORS.PrimarySubtle}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={<DeleteOutlineRoundedIcon />}
                        onClick={() => setFile(null)}
                        css={css({
                            alignSelf: "flex-start"
                        })}
                    />
                </TooltipGeneral>
            </div>

            {countCsv && (
                <Fragment>
                    <Separator
                        css={css({
                            margin: "16px 0"
                        })}
                    />

                    <div
                        css={css({
                            display: "flex",
                            alignItems: "Center"
                        })}
                    >
                        <Tag
                            data-testid="line-count-tag"
                            size={TAG_SIZES.Tiny}
                            color={TAG_COLORS.White}
                            title={renderFormattedNumber(lineCount)}
                        />

                        <span
                            css={css({
                                color: gray700,
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "600",
                                letterSpacing: "0.1px",
                                marginLeft: "8px"
                            })}
                        >
                            {t("Dropzone##rows detected")}
                        </span>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default UploadedFileSingle;
