import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FromFormIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M21.625 14L19.5 11.875L20.225 11.15C20.4083 10.9667 20.6417 10.875 20.925 10.875C21.2083 10.875 21.4417 10.9667 21.625 11.15L22.35 11.875C22.5333 12.0583 22.625 12.2917 22.625 12.575C22.625 12.8583 22.5333 13.0917 22.35 13.275L21.625 14ZM13.5 20V17.875L18.8 12.575L20.925 14.7L15.625 20H13.5ZM4.5 15V13H11.5V15H4.5ZM4.5 11V9H15.5V11H4.5ZM4.5 7V5H15.5V7H4.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default FromFormIcon;
