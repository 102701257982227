import { RESOURCES } from "../types";

import { RectangleLoader } from "../../../../../shared";

export const BodyLoader = () => {
    return Object.values(RESOURCES).map(resource => (
        <RectangleLoader
            key={resource}
            height={28}
            customStyle={{ marginTop: "16px" }}
        />
    ));
};
