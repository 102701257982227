import NotificationsContent from "./NotificationsContent";

import DialogCustom from "../../../components/DesignComponents/DialogCustom";
import { DialogHandler } from "../../../shared";

const NotificationsMobile = ({ isOpen, close }: DialogHandler) => {
    return (
        <DialogCustom noPadding open={isOpen} close={close}>
            <NotificationsContent close={close} />
        </DialogCustom>
    );
};

export default NotificationsMobile;
