import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { BACKGROUND_ACTION_STATUS } from "../types";

import AlertContext, { ALERT_STATUS } from "../../alert/alertContext";

import { ENDPOINTS, useApi, useBroadcasting } from "../../../shared";

const useBackgroundActions = () => {
    const {
        backgroundActionId,
        backgroundActionSuccessCallback,
        backgroundActionFailureCallback,
        setAlert
    } = useContext(AlertContext);

    const { t } = useTranslation();

    const { isCanceled, getData, handleResponse, updateData } = useApi();

    const { receiveBackgroundActionCompletion, leaveBackgroundActionChannel } =
        useBroadcasting();

    const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const notifyTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // Prevent from triggering the effect if there is no background action
        if (!backgroundActionId) return;

        const notifyMeLater = async () => {
            backgroundActionSuccessCallback?.();

            try {
                await updateData(
                    ENDPOINTS.BackgroundActions,
                    backgroundActionId,
                    { notify: true }
                );
            } catch (error) {
                if (!isCanceled(error)) {
                    handleResponse(error);
                }
            }
        };

        // "Notify me later" logic
        notifyTimeoutRef.current = setTimeout(() => {
            setAlert({
                status: ALERT_STATUS.Info,
                title: t("Alert##notify me later##title"),
                secondaryBtn: {
                    label: t("Alert##notify me later##button label"),
                    action: notifyMeLater
                },
                hasManualHide: true
            });
        }, 15000);

        // Subscribe to WS and trigger status check on updates
        receiveBackgroundActionCompletion(backgroundActionId, () =>
            getBackgroundActionStatus(backgroundActionId, null, null)
        );

        // Initial status check with retry logic
        getBackgroundActionStatus(backgroundActionId, 1000, 2000);

        // eslint-disable-next-line
    }, [backgroundActionId]);

    const getBackgroundActionStatus = async (
        id: string,
        delay: number | null,
        increment: number | null
    ) => {
        // Prevent from calling the API on recurring events if there is no background action
        if (!backgroundActionId) return;

        try {
            const { data } = await getData(
                `${ENDPOINTS.BackgroundActions}/${id}`
            );

            const { notify, status: newStatus } = data;

            if (notify) {
                leaveBackgroundActionChannel(id);
                clearTimeoutRefs();
            } else {
                handleBackgroundActionStatus(
                    newStatus,
                    id,
                    data,
                    delay,
                    increment
                );
            }
        } catch (error) {
            if (!isCanceled(error)) {
                handleResponse(error);
            }
        }
    };

    const handleBackgroundActionStatus = (
        status: BACKGROUND_ACTION_STATUS,
        id: string,
        data: any,
        delay: number | null,
        increment: number | null
    ) => {
        switch (status) {
            case BACKGROUND_ACTION_STATUS.Completed: {
                leaveBackgroundActionChannel(id);
                clearTimeoutRefs();

                setAlert({
                    status: ALERT_STATUS.Success,
                    title: t(
                        `Alert##background actions##success##${data.type}`
                    ),
                    description: data.details
                });

                // Close dialog
                backgroundActionSuccessCallback?.();

                break;
            }
            case BACKGROUND_ACTION_STATUS.Failed: {
                leaveBackgroundActionChannel(id);
                clearTimeoutRefs();

                setAlert({
                    status: ALERT_STATUS.Critical,
                    title: t(`Alert##background actions##failed`),
                    description: data.details,
                    hasManualHide: true
                });

                // Remove the loading
                backgroundActionFailureCallback?.();

                break;
            }
            default: {
                const hasRetry = delay !== null && increment !== null;

                if (hasRetry) {
                    retryTimeoutRef.current = setTimeout(() => {
                        const MAX_DELAY = 9000;
                        const newDelay = delay + increment;

                        const newFinalDelay =
                            newDelay > MAX_DELAY ? MAX_DELAY : newDelay;

                        getBackgroundActionStatus(id, newFinalDelay, increment);
                    }, delay);
                }
            }
        }
    };

    const clearTimeoutRefs = () => {
        if (retryTimeoutRef.current) {
            clearTimeout(retryTimeoutRef.current);
        }

        if (notifyTimeoutRef.current) {
            clearTimeout(notifyTimeoutRef.current);
        }
    };
};

export { useBackgroundActions };
