import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AddDeviceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M11.9782 5.79584C9.95485 5.79258 8.23085 6.42162 6.8062 7.68297L8.27416 9.15094C9.3014 8.28699 10.5412 7.85698 11.9935 7.8609C13.4452 7.86538 14.6837 8.29841 15.7089 9.15999L17.1803 7.68863C15.7362 6.43059 14.0021 5.79966 11.9782 5.79584Z"
                fill="currentColor"
            />

            <path
                d="M4.56952 5.0259L4.35932 5.23609L5.82728 6.70405L5.95004 6.5813C6.81363 5.85751 7.77947 5.31724 8.84755 4.96049C9.91507 4.60318 11.0028 4.42977 12.1108 4.44025C13.2188 4.45072 14.3002 4.64487 15.3551 5.02268C16.41 5.4005 17.3455 5.96208 18.1615 6.70745L19.6328 5.23609C18.6077 4.30398 17.4451 3.59092 16.1451 3.09694C14.8451 2.60296 13.5157 2.34191 12.1569 2.31381C10.7981 2.28683 9.46233 2.4986 8.14948 2.94913C6.83662 3.39966 5.6433 4.09192 4.56952 5.0259Z"
                fill="currentColor"
            />

            <path
                d="M18.2841 22.7113C18.4754 22.9033 18.7127 22.9993 18.9961 22.9993C19.2794 22.9993 19.5167 22.9033 19.7081 22.7113C19.9001 22.52 19.9961 22.2827 19.9961 21.9993V19.9993H21.9961C22.2794 19.9993 22.5167 19.9033 22.7081 19.7113C22.9001 19.52 22.9961 19.2827 22.9961 18.9993C22.9961 18.716 22.9001 18.4783 22.7081 18.2863C22.5167 18.095 22.2794 17.9993 21.9961 17.9993H19.9961V15.9993C19.9961 15.716 19.9001 15.4783 19.7081 15.2863C19.5167 15.095 19.2794 14.9993 18.9961 14.9993C18.7127 14.9993 18.4754 15.095 18.2841 15.2863C18.0921 15.4783 17.9961 15.716 17.9961 15.9993V17.9993H15.9961C15.7127 17.9993 15.4754 18.095 15.2841 18.2863C15.0921 18.4783 14.9961 18.716 14.9961 18.9993C14.9961 19.2827 15.0921 19.52 15.2841 19.7113C15.4754 19.9033 15.7127 19.9993 15.9961 19.9993H17.9961V21.9993C17.9961 22.2827 18.0921 22.52 18.2841 22.7113Z"
                fill="currentColor"
            />

            <path
                d="M13.1976 19.7175C12.8072 19.7719 12.4052 19.7991 11.9918 19.7991C9.80859 19.7991 7.94619 19.0408 6.40459 17.5239C4.86299 16.0071 4.09219 14.1823 4.09219 12.0495V11.5491C4.09219 11.3995 4.10859 11.2327 4.14139 11.0487L4.26619 8.17355C4.19979 8.09035 4.10419 8.02795 3.97939 7.98635C3.85459 7.94475 3.72539 7.92395 3.59179 7.92395C3.35819 7.92395 3.14579 7.99475 2.95459 8.13635C2.76259 8.27795 2.62499 8.46555 2.54179 8.69915C2.35859 9.21595 2.22099 9.74515 2.12899 10.2867C2.03779 10.8283 1.99219 11.3823 1.99219 11.9487C1.99219 13.3159 2.25459 14.5951 2.77939 15.7863C3.30419 16.9783 4.02099 18.0199 4.92979 18.9111C5.83779 19.8032 6.90019 20.5031 8.11699 21.0112C9.33379 21.52 10.6254 21.7827 11.9918 21.7995C12.4014 21.8045 12.8033 21.7868 13.1976 21.7464V19.7175Z"
                fill="currentColor"
            />

            <path
                d="M21.9271 13.1997C21.97 12.8235 21.9914 12.4401 21.9914 12.0495C21.9914 11.4823 21.9458 10.9279 21.8546 10.3863C21.7626 9.84475 21.625 9.31555 21.4418 8.79875C21.3586 8.56595 21.221 8.37875 21.029 8.23715C20.8378 8.09555 20.6254 8.02475 20.3918 8.02475C20.2582 8.02475 20.133 8.04555 20.0162 8.08715C19.9002 8.12875 19.8006 8.19115 19.7174 8.27435L19.8422 11.0487C19.875 11.2327 19.8914 11.3995 19.8914 11.5491V12.0495C19.8914 12.4434 19.8651 12.8268 19.8125 13.1997H21.9271Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default AddDeviceIcon;
