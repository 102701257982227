import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const LogoIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props} viewBox="0 0 30 24" fill="none">
            <path
                d="M23.2224 19.8643L15.3188 11.9988L23.2224 4.13684L26.0587 6.95864L29.5558 3.47932L26.0587 0L22.5615 3.48167L19.0619 0L15.5624 3.48167L12.064 0L0 11.9976L12.064 24L15.5635 20.5195L19.0607 24L22.5603 20.5195L26.0575 24L29.5547 20.5207L26.0575 17.0414L23.2212 19.8632L23.2224 19.8643ZM9.2277 19.8643L1.32534 11.9976L9.2277 4.13684L12.0652 6.95864L6.99674 11.9976L12.0652 17.0414L9.2277 19.8632V19.8643ZM16.2256 19.8643L8.32208 11.9988L16.2256 4.13684L19.0631 6.95864L14.0018 11.9976L19.0631 17.0414L16.2256 19.8632V19.8643Z"
                fill="currentColor"
            />

            <defs>
                <rect width="30" height="24" fill={white} />
            </defs>
        </SvgIcon>
    );
};

export default LogoIcon;
