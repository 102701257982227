/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import useCompanyLevelContent from "../../useCompanyLevelContent";
import Tag, { TAG_COLORS, TAG_SIZES } from "../../../DesignComponents/Tag";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

const CompanyLevelHeaderSubtitleTag = ({
    companyLevelName,
    currentLevel
}: any) => {
    const { companyLevelHeaderParams } = useCompanyLevelContent();

    return (
        <>
            <span
                css={css({
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginBottom: "8px",
                    letterSpacing: "0.1px"
                })}
            >
                {
                    companyLevelHeaderParams.subtitleText[
                        companyLevelName as keyof typeof companyLevelHeaderParams.subtitleText
                    ]
                }
            </span>

            <div>
                {companyLevelName === currentLevel ? (
                    <TooltipGeneral
                        placement={TOOLTIP_PLACEMENT.Top}
                        title="This is your company level"
                    >
                        <span>
                            <Tag
                                title="Your level"
                                color={TAG_COLORS.White}
                                size={TAG_SIZES.Small}
                                css={css({
                                    marginBottom: "8px"
                                })}
                            />
                        </span>
                    </TooltipGeneral>
                ) : (
                    <div
                        css={css({
                            height: "24px",
                            marginBottom: "8px"
                        })}
                    />
                )}
            </div>
        </>
    );
};

export default CompanyLevelHeaderSubtitleTag;
