import { useTranslation } from "react-i18next";

import { TaskStatusIconProps } from "./types";

import Badge, {
    BADGE_COLORS,
    BadgeData
} from "../../../../DesignComponents/Badge";

import { TASK_STATUSES } from "../../../../../shared/types/enums";

const TaskStatusIcon = ({ id, count }: TaskStatusIconProps) => {
    const { t } = useTranslation();

    const getBadgeData = (statusId: TASK_STATUSES): BadgeData => {
        switch (statusId) {
            case TASK_STATUSES.Pending:
                return { color: BADGE_COLORS.Premium, isInverted: true };
            case TASK_STATUSES.Running:
                return { color: BADGE_COLORS.Info, isInverted: true };
            case TASK_STATUSES.Completed:
                return { color: BADGE_COLORS.Success, isInverted: true };
            case TASK_STATUSES.CompletedWithErrors:
                return { color: BADGE_COLORS.Warning, isInverted: true };
            case TASK_STATUSES.Cancelled:
                return { color: BADGE_COLORS.Neutral, isInverted: false };
            case TASK_STATUSES.Failed:
                return { color: BADGE_COLORS.Critical, isInverted: true };
            case TASK_STATUSES.PendingApproval:
                return { color: BADGE_COLORS.Light, isInverted: false };
            default:
                return { color: BADGE_COLORS.Light, isInverted: true };
        }
    };

    const color = getBadgeData(id).color;
    const isInverted = getBadgeData(id).isInverted;

    const statusName = t(`Task status##${id}`);

    const name = count ? `${count} ${statusName}` : statusName;

    return (
        <Badge color={color} inverted={isInverted}>
            {name}
        </Badge>
    );
};

export default TaskStatusIcon;
