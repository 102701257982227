import { useContext } from "react";

import SharedToolbar from "./SharedToolbar";
import { TableHeadActionsProps } from "./types";

import {
    GROUP_ACTIONS,
    FILE_ACTIONS,
    COMPANY_ACTIONS,
    USER_ACTIONS,
    DEVICE_ACTIONS,
    PRODUCT_CODE_ACTIONS
} from "../types";

import TableContext from "../../../context/table/tableContext";
import { noop } from "../../../shared/functions/functions";
import { TABLE_NAMES } from "../../../shared";

const TableHeadActions = ({ toggleActiveHead }: TableHeadActionsProps) => {
    const { tableName } = useContext(TableContext);

    const resourceAction = (() => {
        switch (tableName) {
            case TABLE_NAMES.Companies:
                return COMPANY_ACTIONS.DeleteCompanies;

            case TABLE_NAMES.Tasks:
                return DEVICE_ACTIONS.CancelTasks;

            case TABLE_NAMES.Files:
                return FILE_ACTIONS.DeleteFiles;

            case TABLE_NAMES.DeviceGroups:
                return GROUP_ACTIONS.DeleteDeviceGroups;

            case TABLE_NAMES.Users:
                return USER_ACTIONS.DeleteUsers;

            case TABLE_NAMES.ProductCodes:
                return PRODUCT_CODE_ACTIONS.DeleteProductCode;

            default:
                return DEVICE_ACTIONS.NoTableActions;
        }
    })();

    return (
        <SharedToolbar
            resourceAction={resourceAction}
            toggleActiveHead={toggleActiveHead ? toggleActiveHead : noop}
        />
    );
};

export default TableHeadActions;
