import { LINK_IDS, ROUTES } from "../types";

import DevicesIcon from "../../assets/customIcons/navigation/DevicesIcon";
import TasksIcon from "../../assets/customIcons/navigation/TasksIcon";
import GroupsIcon from "../../assets/customIcons/navigation/GroupsIcon";
import FilesIcon from "../../assets/customIcons/navigation/FilesIcon";
import UsersIcon from "../../assets/customIcons/navigation/UsersIcon";
import CompaniesIconRounded from "../../assets/customIcons/navigation/CompaniesIconRounded";
import {
    CounterData,
    Link
} from "../../components/TableDetails/CompanyDetails/types/interfaces";

export const COUNTER_INITIAL_STATE: CounterData = {
    [LINK_IDS.Users]: 0,
    [LINK_IDS.Groups]: 0,
    [LINK_IDS.Files]: 0,
    [LINK_IDS.Devices]: 0,
    [LINK_IDS.Tasks]: 0,
    [LINK_IDS.ChildCompanies]: 0
};

export const LINKS: Link[] = [
    { id: LINK_IDS.Devices, path: ROUTES.Devices, icon: DevicesIcon },
    { id: LINK_IDS.Tasks, path: ROUTES.TaskGroups, icon: TasksIcon },
    { id: LINK_IDS.Groups, path: ROUTES.DeviceGroups, icon: GroupsIcon },
    { id: LINK_IDS.Files, path: ROUTES.Files, icon: FilesIcon },
    { id: LINK_IDS.Users, path: ROUTES.Users, icon: UsersIcon },
    {
        id: LINK_IDS.ChildCompanies,
        path: ROUTES.Companies,
        icon: CompaniesIconRounded
    }
];
