/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { GetSecretKey, SendOtpCode } from "./content";
import {
    ENABLE_AUTHENTICATION_STEPS,
    EnableTwoFactorAuthenticationProps
} from "./types";

import Dialog from "../../../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../../context/alert/alertContext";
import AuthContext from "../../../../../../../context/auth/authContext";
import {
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const EnableTwoFactorAuthentication = ({
    isOpen,
    close,
    isRequired
}: EnableTwoFactorAuthenticationProps) => {
    const { setAlert } = useContext(AlertContext);

    const { toggle2FaSuggestion, toggle2FaEnable } = useContext(AuthContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [step, setStep] = useState(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
    const [isLoading, setLoading] = useState(false);
    const [secretKey, setSecretKey] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleClose = () => {
        close();

        isSuccessMessageStep &&
            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Password##two-factor authentication enabled##alert")
            });

        toggle2FaSuggestion(false, true);
    };

    const changeStep = (newStep: ENABLE_AUTHENTICATION_STEPS) =>
        setStep(newStep);

    const changeOtpCode = (newValue: string) => {
        setError(null);
        setOtpCode(newValue);
    };

    const getSecretKeySubmit = async () => {
        try {
            setLoading(true);

            const {
                data: { authenticator_key }
            } = await postData(`${ENDPOINTS.Users}/tfa/generateKey`);

            setSecretKey(authenticator_key);
            changeStep(ENABLE_AUTHENTICATION_STEPS.SendOtpCode);
        } catch (error) {
            setSecretKey("");
            handleResponse(error);
        }

        setLoading(false);
    };

    const sendOtpCodeSubmit = async () => {
        try {
            setLoading(true);

            await postData(`${ENDPOINTS.Users}/tfa/confirm`, {
                otp_code: otpCode
            });

            toggle2FaEnable(true);
            changeStep(ENABLE_AUTHENTICATION_STEPS.SuccessMessage);
        } catch (error) {
            const err = error as AxiosError;

            if (err.response?.status === 400) {
                setError(t("Password##incorrect code"));
            } else {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    const steps = {
        [ENABLE_AUTHENTICATION_STEPS.GetSecretKey]: {
            hasSeparators: true,
            description: t("Password##generate key description"),
            secondaryBtn: isRequired ? null : t("Button##cancel"),
            submitBtn: t("Button##continue"),
            isSubmitBtnDisabled: false,
            content: <GetSecretKey />,
            onSecondaryBtnClick: handleClose,
            onSubmit: getSecretKeySubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
            hasSeparators: true,
            description: "",
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##verify"),
            isSubmitBtnDisabled: otpCode.length < 6,
            content: (
                <SendOtpCode
                    secretKey={secretKey}
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSecondaryBtnClick: () => {
                changeStep(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
                setOtpCode("");
                setError(null);
            },
            onSubmit: sendOtpCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SuccessMessage]: {
            hasSeparators: false,
            description: t(
                "Password##two-factor authentication enabled##description"
            ),
            secondaryBtn: isRequired ? null : t("Button##close"),
            submitBtn: isRequired ? t("Button##open fota web") : null,
            isSubmitBtnDisabled: false,
            content: null,
            onSecondaryBtnClick: handleClose,
            onSubmit: handleClose
        }
    };

    const isSuccessMessageStep =
        step === ENABLE_AUTHENTICATION_STEPS.SuccessMessage;

    const hasSeparators = steps[step].hasSeparators;
    const description = steps[step].description;
    const secondaryBtnName = steps[step].secondaryBtn;
    const submitBtnName = steps[step].submitBtn;
    const isDisabled = steps[step].isSubmitBtnDisabled;
    const content = steps[step].content;
    const onSecondaryBtnClick = steps[step].onSecondaryBtnClick;
    const onSubmit = steps[step].onSubmit;

    return (
        <Dialog
            hideBackdrop={isRequired}
            open={isOpen}
            close={isRequired ? undefined : handleClose}
            isTitleSeparator={hasSeparators}
            isActionsSeparator={hasSeparators}
            extendToMaxWidth
            title={
                isSuccessMessageStep
                    ? t("Password##two-factor authentication enabled##title")
                    : t("Password##two-factor authentication")
            }
            description={description}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => {
                    setStep(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
                    setSecretKey("");
                    setOtpCode("");
                    setError(null);
                }
            }}
            actions={
                <>
                    {secondaryBtnName && (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Secondary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={onSecondaryBtnClick}
                        >
                            {secondaryBtnName}
                        </Button>
                    )}

                    {submitBtnName && (
                        <Button
                            key={submitBtnName}
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            disabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {submitBtnName}
                        </Button>
                    )}
                </>
            }
            css={css(fromMd && maxDialogWidth)}
        >
            {content}
        </Dialog>
    );
};

export default EnableTwoFactorAuthentication;
