/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FileUploadResultProps } from "../../../types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";

import ThemeContext from "../../../../../../../context/theme/themeContext";

const FileUploadResult = ({
    successCounter,
    fileNumber,
    fileType,
    companies,
    description
}: FileUploadResultProps) => {
    const {
        colorsFacelift: { gray300, gray700, textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const errorCheck = successCounter !== fileNumber;
    const companyIds = companies.map(company => company.name);

    const failCounter = fileNumber - successCounter;
    const isSingleCompany = companies.length === 1;

    const singularPluralFail = failCounter === 1 ? "single" : "multiple";
    const singularPluralSuccess = isSingleCompany ? "single" : "multiple";

    const fileEndingTranslation = t(
        `Dialog##file upload##endings##${fileNumber === 1 ? "file" : "files"}`
    );

    return (
        <div>
            <BannerMessage
                data-testid="file-upload-result-banner"
                status={
                    errorCheck
                        ? BANNER_MESSAGE_STATUSES.Warning
                        : BANNER_MESSAGE_STATUSES.Success
                }
                title={
                    errorCheck
                        ? t(
                              `Dialog##file upload##fail##${singularPluralFail}`,
                              {
                                  failCount: failCounter,
                                  totalCount: fileNumber,
                                  fileEnding: fileEndingTranslation
                              }
                          )
                        : t(
                              `Dialog##file upload##success##${singularPluralSuccess}`
                          )
                }
                css={css({
                    marginBottom: "16px"
                })}
            />

            <div
                css={css({
                    "> div": {
                        display: "flex",
                        gridTemplateColumns: "auto auto"
                    },

                    "> div:last-of-type": {
                        marginBottom: "16px"
                    },

                    "div > span": {
                        margin: "8px 0",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    },

                    "div > span:first-of-type": {
                        color: gray700,
                        minWidth: "150px"
                    },

                    "div > span:last-of-type": {
                        color: textDark
                    },

                    "& > div:not(:first-of-type)": {
                        borderTop: `1px solid ${gray300}`
                    }
                })}
            >
                <div>
                    <span>{t("Table##file type")} </span>

                    <span>{t(`General##${fileType?.value}`)}</span>
                </div>

                <div>
                    <span>
                        {isSingleCompany
                            ? t("General##company")
                            : t("General##companies")}
                    </span>

                    <span>{companyIds.join(", ")}</span>
                </div>

                <div>
                    <span>{t("Table##description")} </span>
                    <span>{description ? description : "-"}</span>
                </div>
            </div>
        </div>
    );
};
export default FileUploadResult;
