import DeleteLicense from "../../../shared/DeleteLicense";

import { EditInlineDialogProps } from "../../../../types";

const DeleteOfflineLicense = ({
    data,
    isOpen,
    close
}: EditInlineDialogProps) => {
    return <DeleteLicense data={data} isOpen={isOpen} close={close} />;
};

export default DeleteOfflineLicense;
