import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const HistoryIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_5006_106176)">
                <path
                    d="M15.6281 16.2L11.8281 12.4V7H13.8281V11.6L17.0281 14.8L15.6281 16.2ZM12.8281 21C10.5281 21 8.52412 20.2373 6.81612 18.712C5.10746 17.1873 4.12812 15.2833 3.87812 13H5.92812C6.16146 14.7333 6.93213 16.1667 8.24013 17.3C9.54879 18.4333 11.0781 19 12.8281 19C14.7781 19 16.4321 18.3207 17.7901 16.962C19.1488 15.604 19.8281 13.95 19.8281 12C19.8281 10.05 19.1488 8.39567 17.7901 7.037C16.4321 5.679 14.7781 5 12.8281 5C11.6781 5 10.6031 5.26667 9.60312 5.8C8.60312 6.33333 7.76146 7.06667 7.07812 8H9.82812V10H3.82812V4H5.82812V6.35C6.67813 5.28333 7.71579 4.45833 8.94112 3.875C10.1658 3.29167 11.4615 3 12.8281 3C14.0781 3 15.2491 3.23733 16.3411 3.712C17.4325 4.18733 18.3825 4.829 19.1911 5.637C19.9991 6.44567 20.6408 7.39567 21.1161 8.487C21.5908 9.579 21.8281 10.75 21.8281 12C21.8281 13.25 21.5908 14.4207 21.1161 15.512C20.6408 16.604 19.9991 17.554 19.1911 18.362C18.3825 19.1707 17.4325 19.8127 16.3411 20.288C15.2491 20.7627 14.0781 21 12.8281 21Z"
                    fill="currentColor"
                />
            </g>

            <defs>
                <clipPath id="clip0_5006_106176">
                    <rect
                        width="24"
                        height="24"
                        fill={white}
                        transform="translate(0.828125)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default HistoryIcon;
