/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import {
    Chart,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarController,
    BarElement
} from "chart.js";

import { SingleBarChartProps } from "../types";

import {
    chartAreaBorder,
    filteredLabels,
    formBarChartDataArray
} from "../../constants";

import { translateStatName } from "../../../../../DeviceStatistics/shared/constants";

import ThemeContext from "../../../../../../context/theme/themeContext";
import { useMediaQueries } from "../../../../../../shared";

Chart.register(
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarController,
    BarElement
);

Chart.defaults.font.weight = 600;
Chart.defaults.font.style = "normal";
Chart.defaults.font.family = "Open Sans,sans-serif";
Chart.defaults.font.lineHeight = "16px";

export const SingleBarChart = ({
    statItem,
    statTitle
}: SingleBarChartProps) => {
    const {
        colorsFacelift: { blue500, gray100, textPlaceholder }
    } = useContext(ThemeContext);

    const { fromLg, toSm } = useMediaQueries();
    const { t } = useTranslation();

    const substringLastchartIndex = () => {
        let count: number;

        if (toSm) {
            count = 5;
        } else if (fromLg) {
            count = 9;
        } else {
            count = 6;
        }

        return count;
    };

    const statsNames = {
        unknown: t("General##unknown"),
        other: t("General##other")
    };

    const labelsWithTranslations = (isFromTooltip: boolean) => {
        const allFilteredLabels = filteredLabels(
            isFromTooltip,
            statItem,
            substringLastchartIndex()
        );

        return allFilteredLabels.map((item: string[]) => {
            return [
                translateStatName(
                    item[0],
                    undefined,
                    undefined,
                    undefined,
                    statsNames.unknown,
                    statsNames.other
                )
            ];
        });
    };

    const data = {
        labels: labelsWithTranslations(false),
        datasets: [
            {
                data: formBarChartDataArray(statItem),
                backgroundColor: [blue500],
                borderRadius: 4,
                borderSkipped: false,
                barThickness: 40
            }
        ]
    };

    const options = {
        responsive: true,
        // line below enables possibility to change height of chart
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    stepSize: 1,
                    fontSize: 12,
                    color: textPlaceholder
                },

                grid: {
                    display: false,
                    drawBorder: false
                },
                border: {
                    display: false
                }
            },
            y: {
                grace: 0.1,
                ticks: {
                    display: false
                },
                grid: {
                    drawTicks: false,
                    drawBorder: false,
                    color: gray100
                },
                border: {
                    display: false
                }
            }
        },
        plugins: {
            colors: {
                forceOverride: true
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    title: (context: any) => {
                        const dataIndex = context[0].dataIndex;
                        const allLabels = labelsWithTranslations(true);

                        return allLabels[dataIndex][0];
                    }
                }
            },
            legend: {
                display: false
            },
            chartAreaBorder: {
                borderColor: gray100,
                borderWidth: 1,
                borderline: 6
            }
        }
    };

    return (
        <>
            {statTitle}

            <div
                css={css({
                    paddingTop: "16px",
                    height: "120px",
                    width: "100%"
                })}
            >
                <Bar
                    data={data}
                    options={options as any}
                    plugins={[chartAreaBorder]}
                />
            </div>
        </>
    );
};
