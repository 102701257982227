/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import CompanyLevelRectangle from "../../../shared/components/CompanyLevelRectangle";
import { COMPANY_LEVEL_DIALOGS } from "../../../shared/types/enums";
import { CompanyLevelProps } from "../../../shared/types/interfaces";
import CompanyLevelHeaderSubtitleTag from "../../../shared/components/CompanyLevelHeaderSubtitleTag";

const CompanyLevelHeaderContent = ({
    companyLevelName,
    currentLevel
}: CompanyLevelProps) => {
    const { t } = useTranslation();

    return companyLevelName !== COMPANY_LEVEL_DIALOGS.InfoPanel ? (
        <div
            css={css({
                padding:
                    companyLevelName !== COMPANY_LEVEL_DIALOGS.Premium
                        ? "0 8px"
                        : "0 0px 0 8px",

                "span, div": {
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center"
                },
                span: { padding: "0 8px" },

                " > span:first-of-type": {
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "32px",
                    padding: "24px 0 8px 0"
                }
            })}
        >
            <CompanyLevelRectangle companyLevelName={companyLevelName} />

            <span>{t(`Dialog##company level##${companyLevelName}`)}</span>

            <CompanyLevelHeaderSubtitleTag
                currentLevel={currentLevel}
                companyLevelName={companyLevelName}
            />
        </div>
    ) : (
        <></>
    );
};

export default CompanyLevelHeaderContent;
