/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

import {
    CounterData,
    Link as LinkType
} from "../../../TableDetails/CompanyDetails/types";

import ThemeContext from "../../../../context/theme/themeContext";
import RoutesContext from "../../../../context/routes/routesContext";
import {
    DashboardResourceLoader,
    useFormattedNumber,
    useMediaQueries
} from "../../../../shared";

interface SingleResourceProps {
    data: LinkType;
    counter: CounterData;
    isLoading: boolean;
}

const SingleResource = ({ data, isLoading, counter }: SingleResourceProps) => {
    const {
        colorsFacelift: { blue700, gray100, gray200, gray700, textDark }
    } = useContext(ThemeContext);

    const { isDesktopDrawerOpen } = useContext(RoutesContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();
    const { toMd, toLg, toSm, fromLg } = useMediaQueries();

    const { id: linkId, path, icon: linkIcon } = data;

    const notEmpty = counter[linkId] > 0;

    const titleName = linkId;
    const Icon = linkIcon;

    const component = () => {
        if (!notEmpty) {
            return "div";
        }

        return RouterLink;
    };

    const mediaQuery = toSm || toMd || toLg || fromLg;

    const generateResourceMinWidth = () => {
        switch (mediaQuery) {
            case toSm:
                return "calc((100% / 2) - 16px)";
            case toMd:
                return "calc((100% / 3) - 16px)";
            case toLg:
                return "calc((100% / 4) - 16px)";
            case fromLg:
                return isDesktopDrawerOpen
                    ? "calc((100% / 2) - 16px)"
                    : "calc((100% / 3) - 16px)";
            default:
                return "calc((100% / 3) - 16px)";
        }
    };

    return (
        <Link
            component={component()}
            to={path}
            underline="none"
            css={css({
                cursor: `${notEmpty && !isLoading ? "pointer" : "auto"}`,
                display: "block",
                alignItems: "center",
                borderRadius: "8px",
                padding: "16px 8px",
                minWidth: generateResourceMinWidth(),
                boxSizing: "border-box",
                border: `1px solid ${gray200}`,
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "&:hover": {
                    background: notEmpty && !isLoading ? `${gray100}` : "unset"
                }
            })}
        >
            {isLoading ? (
                <DashboardResourceLoader />
            ) : (
                <Fragment>
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "4px",

                            "& > svg": {
                                fontSize: "24px",
                                color: blue700
                            }
                        })}
                    >
                        <Icon />
                    </div>

                    <div
                        css={css({
                            cursor: `${notEmpty ? "pointer" : "auto"}`,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontWeight: 600,

                            "& > span:first-of-type": {
                                color: textDark,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px"
                            },

                            "& > span:last-of-type": {
                                color: gray700,
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                marginTop: "2px",
                                textAlign: "center"
                            }
                        })}
                    >
                        <span>{renderFormattedNumber(counter[linkId])}</span>
                        <span>{t(`Table##${titleName}`)}</span>
                    </div>
                </Fragment>
            )}
        </Link>
    );
};

export default SingleResource;
