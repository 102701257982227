import { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { RedirectionProps } from "../types";
import AuthLoader from "../../Loaders/AuthLoader";
import { useRoutes } from "../../Routes/hooks";

import AuthContext from "../../../context/auth/authContext";
import ParamsContext from "../../../context/params/paramsContext";
import { ROUTES } from "../../../shared";

const Redirection = ({ children }: RedirectionProps) => {
    const { isAuthenticated, isAuthLoading } = useContext(AuthContext);
    const { stringParams, resource } = useContext(ParamsContext);

    const { routes } = useRoutes();

    const [pathname, setPathname] = useState(ROUTES.Dashboard);

    useEffect(() => {
        const path = resource || ROUTES.Dashboard;

        setPathname(path as ROUTES);
    }, [stringParams, resource]);

    if (isAuthLoading) {
        return <AuthLoader />;
    }

    if (isAuthenticated) {
        const isRestricted = () => {
            const route = routes.find(route => route.path === `/${pathname}`);

            return route === undefined || route.isRestricted;
        };

        return (
            <Navigate
                to={{
                    pathname: isRestricted() ? ROUTES.Dashboard : pathname,
                    search: isRestricted() ? "" : stringParams
                }}
                replace
            />
        );
    }

    return children;
};

export default Redirection;
