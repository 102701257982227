/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";

import Settings from "./Settings";

import Notifications from "../../../context/notifications/NotificationsState";
import { useMediaQueries } from "../../../shared";

interface ControlsProps {
    hideNotifications?: boolean;
    handleDrawer?: (value: boolean) => void;
}

const Controls = ({ hideNotifications, handleDrawer }: ControlsProps) => {
    const { toMd } = useMediaQueries();

    return (
        <Fragment>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginLeft: toMd ? "0" : "8px",

                    "button + button": {
                        marginLeft: toMd ? "0" : "8px"
                    },

                    "@media (max-width: 839px)": {
                        display: "block"
                    }
                })}
            >
                {!hideNotifications && <Notifications />}

                <Settings handleDrawer={handleDrawer} />
            </div>
        </Fragment>
    );
};

export default Controls;
