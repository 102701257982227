/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";

import ClientStats from "./ClientStats";
import SupportStats from "./SupportStats";

import StatsLoader from "../loaders/StatsLoader";
import { StatsEntryProps } from "../types";

import Separator from "../../../../../DesignComponents/Separator";
import ThemeContext from "../../../../../../context/theme/themeContext";
import AuthContext from "../../../../../../context/auth/authContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    TransferCompanyInfo,
    useApi,
    useCounterData,
    useIsMounted
} from "../../../../../../shared";

const StatsEntry = ({ data, isMoveAction }: StatsEntryProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { isSupportUser } = useContext(AuthContext);

    const { counter, isLoading } = useCounterData(data.id, true, isMoveAction);
    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();

    const [isParentDataLoading, setParentDataLoading] = useState(
        !data.transferCompanyInfo
    );

    const [parentInfo, setParentInfo] = useState<TransferCompanyInfo | null>(
        null
    );

    useEffect(() => {
        const getMergeParentCompanyData = async (id: number) => {
            try {
                const {
                    data: { code, vip_status, type, level }
                } = await getData(`${ENDPOINTS.Companies}/${id}`);

                if (isMounted) {
                    setParentInfo({
                        lzCode: code,
                        vipStatus: vip_status,
                        companyType: type,
                        companyLevel: level
                    });
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    setParentInfo(null);
                    handleResponse(error);
                }
            }

            setParentDataLoading(false);
        };

        !data.transferCompanyInfo && getMergeParentCompanyData(data.id);

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [data.id]);

    const dataArray: [string, number][] = Object.entries(counter || {});

    const fullData = data.transferCompanyInfo
        ? data
        : {
              ...data,
              transferCompanyInfo: parentInfo || undefined
          };

    return (
        <div
            data-testid="transfer-companies-stats"
            css={css({
                flex: "0.5",
                marginTop: "16px",

                "& > div": {
                    "& > div + div:not(:nth-of-type(7))": {
                        marginTop: "4px"
                    }
                }
            })}
        >
            {isLoading ? (
                <StatsLoader count={6} />
            ) : (
                <ClientStats dataArray={dataArray} />
            )}

            {isSupportUser && (
                <>
                    <Separator
                        css={css({
                            background: gray200,
                            margin: "16px 0"
                        })}
                    />

                    {isLoading || isParentDataLoading ? (
                        <StatsLoader count={4} />
                    ) : (
                        <SupportStats data={fullData} />
                    )}
                </>
            )}
        </div>
    );
};

export default StatsEntry;
