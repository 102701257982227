import { useTranslation } from "react-i18next";

import ChangePassword from "./ChangePassword";
import TwoFactorAuthentication from "./TwoFactorAuthentication";

import Dialog from "../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";

import { DialogHandler } from "../../../../../shared";

const Security = ({ isOpen, close }: DialogHandler) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpen}
            close={close}
            isTitleSeparator
            isActionsSeparator
            extendToMaxWidth
            title={t("Password##security settings")}
            description={t("Password##security settings description")}
            actions={
                <Button
                    fullWidth
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    onClick={close}
                >
                    {t("Button##cancel")}
                </Button>
            }
        >
            <ChangePassword />

            <TwoFactorAuthentication />
        </Dialog>
    );
};

export default Security;
