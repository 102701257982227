import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ReceiveDevicesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M15.5661 20.8699C15.5241 20.7533 15.5031 20.6283 15.5031 20.4949C15.5031 20.3616 15.5241 20.2366 15.5661 20.1199C15.6075 20.0033 15.6781 19.8949 15.7781 19.7949L18.3781 17.1949C18.5615 17.0116 18.7948 16.9199 19.0781 16.9199C19.3615 16.9199 19.5948 17.0116 19.7781 17.1949C19.9615 17.3783 20.0531 17.6116 20.0531 17.8949C20.0531 18.1783 19.9615 18.4116 19.7781 18.5949L18.8781 19.4949L22.0781 19.4949C22.3615 19.4949 22.5991 19.5909 22.7911 19.7829C22.9825 19.9743 23.0781 20.2116 23.0781 20.4949C23.0781 20.7783 22.9825 21.0159 22.7911 21.2079C22.5991 21.3993 22.3615 21.4949 22.0781 21.4949L18.8781 21.4949L19.7781 22.3949C19.9615 22.5783 20.0531 22.8116 20.0531 23.0949C20.0531 23.3783 19.9615 23.6116 19.7781 23.7949C19.5948 23.9783 19.3615 24.0699 19.0781 24.0699C18.7948 24.0699 18.5615 23.9783 18.3781 23.7949L15.7781 21.1949C15.6781 21.0949 15.6075 20.9866 15.5661 20.8699Z"
                fill="currentColor"
            />
            <path
                d="M4.087 20.913C4.479 21.3043 4.95 21.5 5.5 21.5H13.5V19.5H5.5V8.5H19.5V14.8809H21.5V7C21.5 6.75 21.4583 6.525 21.375 6.325C21.2917 6.125 21.1833 5.93333 21.05 5.75L19.65 4.05C19.5167 3.86667 19.35 3.729 19.15 3.637C18.95 3.54567 18.7333 3.5 18.5 3.5H6.5C6.26667 3.5 6.05 3.54567 5.85 3.637C5.65 3.729 5.48333 3.86667 5.35 4.05L3.95 5.75C3.81667 5.93333 3.70833 6.125 3.625 6.325C3.54167 6.525 3.5 6.75 3.5 7V19.5C3.5 20.05 3.69567 20.521 4.087 20.913ZM19.1 6.5H5.9L6.75 5.5H18.25L19.1 6.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ReceiveDevicesIcon;
