/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import SingleResource from "./SingleResource";

import Title from "../../Title";

import { LINKS } from "../../../../shared/constants/resources";
import { useCounterData } from "../../../../shared/hooks/useCounterData";
import ParamsContext from "../../../../context/params/paramsContext";
import { LinksTitleLoader, getFilteredCompaniesIds } from "../../../../shared";

const ResourcesWidget = () => {
    const { companyFilteredValues } = useContext(ParamsContext);

    const { t } = useTranslation();

    const filteredCompanies = getFilteredCompaniesIds(companyFilteredValues);

    const { isLoading, counter } = useCounterData(filteredCompanies);

    return (
        <div
            css={css({
                padding: "24px"
            })}
        >
            {isLoading ? (
                <LinksTitleLoader />
            ) : (
                <Title text={t("Welcome dashboard##resources")} />
            )}

            <div
                css={css({
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "16px",
                    rowGap: "16px",
                    paddingTop: "16px"
                })}
            >
                {LINKS.map(link => {
                    return (
                        <SingleResource
                            key={link.id}
                            data={link}
                            counter={counter}
                            isLoading={isLoading}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ResourcesWidget;
