import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DevicesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M19.8914 11.5493V12.0497C19.8914 14.1825 19.1206 16.0073 17.579 17.5241C16.0374 19.0409 14.175 19.7993 11.9918 19.7993C9.80859 19.7993 7.94619 19.0409 6.40459 17.5241C4.86299 16.0073 4.09219 14.1825 4.09219 12.0497V11.5493C4.09219 11.3997 4.10859 11.2329 4.14139 11.0489L4.26619 8.17374C4.19979 8.09054 4.10419 8.02814 3.97939 7.98654C3.85459 7.94494 3.72539 7.92414 3.59179 7.92414C3.35819 7.92414 3.14579 7.99494 2.95459 8.13654C2.76259 8.27814 2.62499 8.46574 2.54179 8.69934C2.35859 9.21614 2.22099 9.74534 2.12899 10.2869C2.03779 10.8285 1.99219 11.3825 1.99219 11.9489C1.99219 13.3161 2.25459 14.5953 2.77939 15.7865C3.30419 16.9785 4.02099 18.0201 4.92979 18.9113C5.83779 19.8033 6.90019 20.5033 8.11699 21.0113C9.33379 21.5201 10.6254 21.7829 11.9918 21.7997C13.375 21.8165 14.671 21.5745 15.8798 21.0737C17.0878 20.5745 18.1458 19.8829 19.0538 18.9989C19.9626 18.1157 20.6794 17.0785 21.2042 15.8873C21.729 14.6953 21.9914 13.4161 21.9914 12.0497C21.9914 11.4825 21.9458 10.9281 21.8546 10.3865C21.7626 9.84494 21.625 9.31574 21.4418 8.79894C21.3586 8.56614 21.221 8.37894 21.029 8.23734C20.8378 8.09574 20.6254 8.02494 20.3918 8.02494C20.2582 8.02494 20.133 8.04574 20.0162 8.08734C19.9002 8.12894 19.8006 8.19134 19.7174 8.27454L19.8422 11.0489C19.875 11.2329 19.8914 11.3997 19.8914 11.5493Z"
                fill="currentColor"
            />

            <path
                d="M11.9798 5.79584C9.95641 5.79258 8.23241 6.42162 6.80777 7.68297L8.27573 9.15094C9.30296 8.28699 10.5427 7.85698 11.995 7.8609C13.4468 7.86538 14.6853 8.29841 15.7105 9.15999L17.1819 7.68863C15.7377 6.43059 14.0037 5.79966 11.9798 5.79584Z"
                fill="currentColor"
            />

            <path
                d="M4.57108 5.0259L4.36088 5.23609L5.82885 6.70405L5.9516 6.5813C6.81519 5.85751 7.78103 5.31724 8.84911 4.96049C9.91664 4.60318 11.0044 4.42977 12.1123 4.44025C13.2203 4.45072 14.3018 4.64487 15.3567 5.02268C16.4116 5.4005 17.347 5.96209 18.163 6.70745L19.6344 5.23609C18.6092 4.30398 17.4466 3.59092 16.1466 3.09694C14.8466 2.60296 13.5172 2.34191 12.1585 2.31381C10.7997 2.28683 9.46389 2.4986 8.15104 2.94913C6.83819 3.39966 5.64487 4.09192 4.57108 5.0259Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default DevicesIcon;
