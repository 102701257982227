import { useState } from "react";
import EditIcon from "@mui/icons-material/ModeEdit";

import { RenderConditionOptions } from "../../../../shared";

const useUserInlineActions = () => {
    const [rowData, setRowData] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const openDialog = (data: any) => {
        setRowData(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setRowData(null);
        setOpen(false);
    };

    const isUserHimself = (id: number, loggedUserId: number) =>
        id === loggedUserId;

    const inlineActions = [
        {
            title: "edit user",
            icon: EditIcon,
            clickAction: (data: any) => openDialog(data),
            renderConditions: ({ data, user }: RenderConditionOptions) =>
                !isUserHimself(data.id, user.id)
        }
    ];

    return {
        inlineActions,
        rowData,
        isOpen,
        closeDialog
    };
};

export default useUserInlineActions;
