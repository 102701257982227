/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useContext } from "react";

import CompanyLevelHeader from "./components/CompanyLevelHeader";
import CompanyLevelBody from "./components/CompanyLevelDesktopBody";
import CompanyLevelFAQ from "../shared/components/CompanyLevelFAQ";
import CompanyLevelCallToAction from "../shared/components/CompanyLevelCallToAction";
import { CompanyLevelDesktopMobileProps } from "../shared/types/interfaces";
import ThemeContext from "../../../context/theme/themeContext";

const CompanyLevelDesktop = ({
    faqRef,
    currentLevel,
    isVirtualCompany
}: CompanyLevelDesktopMobileProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <div
            data-testid="company-level-desktop"
            css={css({
                scrollBehavior: "smooth",
                padding: "40px 32px 4px 32px"
            })}
        >
            <Grid
                data-testid="company-level-header-desktop"
                container
                css={css({
                    background: white
                })}
            >
                <CompanyLevelHeader currentLevel={currentLevel} />
            </Grid>

            <div data-testid="company-level-body-desktop">
                <Grid container>
                    <CompanyLevelBody />
                </Grid>
                <div
                    ref={faqRef}
                    css={css({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",

                        "> div": {
                            marginTop: "56px",
                            maxWidth: "552px"
                        }
                    })}
                >
                    {!isVirtualCompany && <CompanyLevelCallToAction />}
                    <CompanyLevelFAQ />
                </div>
            </div>
        </div>
    );
};

export default CompanyLevelDesktop;
