export enum TAG_SIZES {
    Medium = "medium",
    Small = "small",
    Tiny = "tiny"
}

export enum TAG_COLORS {
    Primary = "primary",
    White = "white",
    BasicBundle = "basicBundle",
    StandardBundle = "standardBundle",
    PremiumBundle = "premiumBundle"
}
