export enum DEVICE_ACTIONS {
    CancelTasks = "cancel tasks",
    CreateTask = "create task",
    TaskDetails = "task details",
    AddDevice = "add device",
    MoveDevices = "move devices",
    ImportDevices = "import devices",
    ExportDeviceData = "export device data",
    TransferDevice = "transfer device",
    AssignCanPackage = "assign can package",
    NoTableActions = "no table actions"
}

export enum GROUP_ACTIONS {
    CreateGroup = "create group",
    DeleteDeviceGroups = "delete device groups",
    EditGroup = "edit group"
}

export enum FILE_ACTIONS {
    UploadFiles = "upload files",
    DeleteFiles = "delete files"
}

export enum COMPANY_ACTIONS {
    CreateCompany = "create company",
    TransferCompanies = "transfer companies",
    MergeCompanies = "merge companies",
    DeleteCompanies = "delete companies",
    CompanyLevelManualChange = "company level manual change",
    CustomResourceLimits = "custom resource limits"
}

export enum USER_ACTIONS {
    InviteUser = "invite user",
    DeleteUsers = "delete users"
}

export enum TASK_GROUP_ACTIONS {
    CancelTasks = "cancel tasks",
    RetryFailedTasks = "retry failed tasks"
}

export enum PRODUCT_CODE_ACTIONS {
    AddProductCode = "add product code",
    DeleteProductCode = "delete product code"
}

export enum LICENSE_ACTIONS {
    CreateDesktopLicense = "create desktop license",
    CreateOfflineLicense = "create offline license",
    DeleteDesktopLicense = "delete desktop license",
    DeleteOfflineLicense = "delete offline license",
    EditDesktopLicense = "edit desktop license"
}
