import { useTranslation } from "react-i18next";

import StatsTag from "./StatsTag";

import { ClientStatsProps } from "../types";

const ClientStats = ({ dataArray }: ClientStatsProps) => {
    const { t } = useTranslation();

    return (
        <div data-testid="transfer-companies-client-stats">
            {dataArray.map(([name, count]) => {
                const translatedTitlePart = t(
                    `Dialog##company statistics##${name}`
                );

                const title = `${translatedTitlePart}: ${count}`;

                return <StatsTag key={name} title={title} />;
            })}
        </div>
    );
};

export default ClientStats;
