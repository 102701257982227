import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SelectCompanyIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M16.8182 9H18.5455V7H16.8182V9ZM16.8182 13H18.5455V11H16.8182V13ZM16.8182 17H18.5455V15H16.8182V17ZM16.8182 21V19H20.2727V5H12.5V6.4L10.7727 4.95C10.7727 4.41667 10.9419 3.95833 11.2801 3.575C11.6184 3.19167 12.025 3 12.5 3H20.2727C20.7477 3 21.1544 3.19583 21.4926 3.5875C21.8309 3.97917 22 4.45 22 5V19C22 19.55 21.8309 20.0208 21.4926 20.4125C21.1544 20.8042 20.7477 21 20.2727 21H16.8182ZM3 20V12.025C3 11.6917 3.06477 11.3833 3.19432 11.1C3.32386 10.8167 3.50379 10.5833 3.73409 10.4L8.05227 6.825C8.35455 6.575 8.68561 6.45 9.04545 6.45C9.4053 6.45 9.73636 6.575 10.0386 6.825L14.3568 10.4C14.5871 10.5833 14.767 10.8167 14.8966 11.1C15.0261 11.3833 15.0909 11.6917 15.0909 12.025V20C15.0909 20.2833 15.0081 20.5208 14.8426 20.7125C14.6771 20.9042 14.472 21 14.2273 21H10.9091C10.3568 21 9.90909 20.5523 9.90909 20V16H8.18182V20C8.18182 20.5523 7.7341 21 7.18182 21H3.86364C3.61894 21 3.41383 20.9042 3.2483 20.7125C3.08277 20.5208 3 20.2833 3 20ZM4.72727 19H6.45455V15C6.45455 14.4477 6.90226 14 7.45455 14H10.6364C11.1886 14 11.6364 14.4477 11.6364 15V19H13.3636V12L9.04545 8.45L4.72727 12V19Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SelectCompanyIcon;
