const setSingleBatch = (id: number) => {
    return {
        id,
        type: "TaskBulkCreate",
        source: "id_list",
        attributes: '{"filter":null,"file_path":null}',
        objects: 6,
        objects_processed: 6,
        company_id: 9254,
        created_by: {
            id: 5689,
            email: "jonas.jonaitis@teltonika.lt",
            company_id: 9254,
            role: 10,
            table_refresh: 0,
            language: "en-US",
            invited_by: 3876,
            deleted_at: null,
            created_at: "2022-11-14 09:38:27",
            updated_at: "2023-03-14 13:56:26",
            last_login: "2023-03-15 08:23:12",
            type: null
        },
        created_at: "2023-03-14 14:04:42",
        updated_at: "2023-03-14 14:04:43",
        approvable: 0,
        verified_by: 5689,
        task_type: "RxConfiguration",
        task_file_name: null,
        updated_by: null,
        task_stats: [
            {
                status_id: 4,
                count: 6
            }
        ]
    };
};

export const dummyBatchesData = {
    current_page: 1,
    from: 1,
    to: 25,
    total: 2,
    first_page: 1,
    last_page: 1,
    per_page: 25,
    data: [setSingleBatch(513), setSingleBatch(512)],
    first_page_url: `${process.env.REACT_APP_BASE_URL}/batches?page=1`,
    next_page_url: null,
    prev_page_url: null,
    last_page_url: `${process.env.REACT_APP_BASE_URL}/batches?page=1`
};
