/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import FindToolContent from "./components/FindToolContent";
import { FindToolDesktopProps } from "./types";

import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../DesignComponents/Menu";

const FindToolDesktop = ({ anchorEl, close }: FindToolDesktopProps) => {
    return (
        <Menu
            open={Boolean(anchorEl)}
            onClose={close}
            anchorEl={anchorEl}
            anchorOrigin={centeredAnchorOrigin}
            transformOrigin={centeredTransformOrigin}
            MenuListProps={{ disableListWrap: true }}
            css={css({
                "& .MuiMenu-paper": {
                    width: "564px"
                }
            })}
        >
            <li>
                <FindToolContent close={close} />
            </li>
        </Menu>
    );
};

export default FindToolDesktop;
