export enum PASSWORD_TYPES {
    Current = "current",
    Repeat = "repeat",
    NewPsw = "newPsw"
}

export enum BUTTON_TYPES {
    NavBarChange = "navBarChange",
    ResetPsw = "reset password",
    SignUp = "signup"
}

export enum SHOW_PASSWORD {
    Show = "text",
    Hide = "password"
}

export enum INPUT_FIELD {
    Email = "email",
    Password = "password"
}
