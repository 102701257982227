import { useContext } from "react";

import TableRow from "./TableRow";
import MobileCard from "./MobileCard";

import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

interface TableItemProps {
    data: any;
    isMobileHeadActive?: boolean;
    isFirstItem?: boolean;
    scrollHorizontal?: boolean;
}

const TableItem = ({
    data,
    isMobileHeadActive,
    isFirstItem,
    scrollHorizontal
}: TableItemProps) => {
    const {
        selectableRows,
        idType,
        selectedRows,
        toggleSelectionOfSingleRow
    } = useContext(TableContext);

    const { toMd } = useMediaQueries();

    const handleCheckboxChange = () => {
        toggleSelectionOfSingleRow(data[idType]);
    };

    const isDisabled = !selectableRows.some(
        selectableRow => selectableRow[idType] === data[idType]
    );

    const isChecked = selectedRows.includes(data[idType]);

    return toMd ? (
        <MobileCard
            data={data}
            isChecked={isChecked}
            isActiveHead={isMobileHeadActive}
            isFirst={isFirstItem}
            isDisabled={isDisabled}
            onCheckboxChange={handleCheckboxChange}
        />
    ) : (
        <TableRow
            data={data}
            isChecked={isChecked}
            isDisabled={isDisabled}
            onCheckboxChange={handleCheckboxChange}
            scrollHorizontal={scrollHorizontal}
        />
    );
};

export default TableItem;
