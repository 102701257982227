import { Fragment } from "react";

import ErrorElements, { TITLES } from "../ErrorElements";
import maintenanceAnimation from "../../assets/animations/maintenance_animation.json";

const Maintenance = () => {
    const animationCSS: Object = {
        height: "250px",

        "@media (max-width: 839px)": {
            height: "160px"
        },

        "@media (max-width: 599px)": {
            height: "140px"
        }
    };

    return (
        <Fragment>
            <ErrorElements
                title={TITLES.Maintenance}
                animation={maintenanceAnimation}
                animationCSS={animationCSS}
            />
        </Fragment>
    );
};

export default Maintenance;
