import {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import useResizeObserver from "use-resize-observer";
import _ from "lodash";

import { MIN_HEIGHT, TABLE_HEAD_HEIGHT, TABLE_ROW_HEIGHT } from "../constants";
import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import { NOT_FILTER_NAMES } from "../../../shared";

export const useTableDetailHeights = () => {
    const { queryParams } = useContext(ParamsContext);

    const {
        rows,
        idType,
        tableName,
        isInnerTable,
        isTableLoading,
        detailsData,
        setDetailsData
    } = useContext(TableContext);

    const {
        ref: tableSectionRef,
        height: tableSectionHeight
    } = useResizeObserver<HTMLElement>();

    const [defaultHeightForTrigger, setDefaultHeightForTrigger] = useState(0);

    const detailsHeights = useRef({
        initialDefaultHeight: 0,
        defaultHeight: 0,
        tableHeight: 0
    });

    const setDefaultHeigth = (height: number) => {
        detailsHeights.current = {
            ...detailsHeights.current,
            defaultHeight: height
        };

        localStorage.setItem(`${tableName}DetailsHeight`, String(height));
    };

    // Set the details data according to the Selected query param
    useEffect(() => {
        if (!isInnerTable && !isTableLoading && defaultHeightForTrigger !== 0) {
            const getDetailsData = () => {
                const selectedId = queryParams[NOT_FILTER_NAMES.Selected] as
                    | string
                    | undefined;

                if (selectedId) {
                    const rowData = rows.find(
                        (row: any) => String(row[idType]) === selectedId
                    );

                    return rowData || null;
                }

                return null;
            };

            const updatedDetailsData = getDetailsData();

            const areEqual = _.isEqual(detailsData, updatedDetailsData);

            !areEqual && setDetailsData(updatedDetailsData);
        }
        // eslint-disable-next-line
    }, [isInnerTable, isTableLoading, defaultHeightForTrigger]);

    // Get details heights
    useLayoutEffect(() => {
        if (!isInnerTable && tableSectionHeight !== undefined) {
            const savedHeight = Number(
                localStorage.getItem(`${tableName}DetailsHeight`)
            );

            const requiredMinHeight =
                TABLE_ROW_HEIGHT * 6 + TABLE_HEAD_HEIGHT + MIN_HEIGHT + 1;

            const getInitialHeight = () => {
                const height =
                    tableSectionHeight -
                    TABLE_HEAD_HEIGHT -
                    TABLE_ROW_HEIGHT * 6 +
                    1;

                return tableSectionHeight < requiredMinHeight
                    ? tableSectionHeight
                    : height;
            };

            const initialHeight = getInitialHeight();

            const getDefaultHeight = () => {
                if (savedHeight && savedHeight >= MIN_HEIGHT) {
                    return savedHeight;
                } else {
                    return initialHeight <= MIN_HEIGHT
                        ? MIN_HEIGHT
                        : initialHeight;
                }
            };

            detailsHeights.current = {
                ...detailsHeights.current,
                initialDefaultHeight: initialHeight,
                defaultHeight: getDefaultHeight(),
                tableHeight: tableSectionHeight
            };

            setDefaultHeightForTrigger(getDefaultHeight());
        }
    }, [isInnerTable, tableName, tableSectionHeight]);

    return {
        tableSectionRef,
        detailsHeights: detailsHeights.current,
        setDefaultHeigth
    };
};
