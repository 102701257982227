import {
    SyntheticEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer
} from "react";
import { useTranslation } from "react-i18next";

import TriSourceContext from "./triSourceContext";
import TriSourceReducer from "./triSourceReducer";
import {
    SET_ACTIVE_SOURCE,
    HANDLE_NEED_CONFIRMATION,
    SET_FILTERED_ONLY_COUNT,
    RESET_TO_INITIAL_STATE,
    SET_SOURCE_PAYLOAD,
    HANDLE_SHOW_CONFIRMATION
} from "./triSourceActions";
import { TriSourceStateProps } from "./types";

import ParamsContext from "../params/paramsContext";
import TableContext from "../table/tableContext";
import DropzoneContext from "../dropzone/dropzoneContext";

import {
    FILTER_NAMES,
    TRI_SOURCES,
    getFilterParamsWithoutRoot,
    useDecoder
} from "../../shared";

const TriSourceState = ({ children }: TriSourceStateProps) => {
    const { filterQueryParams } = useContext(ParamsContext);
    const { tableName, selectedRows } = useContext(TableContext);
    const { file, lineCount } = useContext(DropzoneContext);

    const { t } = useTranslation();
    const { decodeArray } = useDecoder();

    const numberOfSelectedRows = selectedRows.length;

    const filterParamsWithoutRoot =
        getFilterParamsWithoutRoot(filterQueryParams);

    const numberOfFiltersUsed = Object.keys(filterParamsWithoutRoot).length;

    const getSource = useCallback(() => {
        if (numberOfSelectedRows > 0) {
            return TRI_SOURCES.Selected;
        }

        if (numberOfFiltersUsed > 0) {
            return TRI_SOURCES.Filtered;
        }

        return TRI_SOURCES.FromFile;
    }, [numberOfSelectedRows, numberOfFiltersUsed]);

    const initialState = {
        activeSource: getSource(),
        sourcePayload: null,
        isSourceReady: false,
        isSelectedSingleItem: false,
        filteredOnlyCount: null,
        needConfirmation: true,
        showConfirmation: false
    };

    const [state, dispatch] = useReducer(TriSourceReducer, initialState);

    // Set active source
    useEffect(() => {
        setActiveSource(getSource());
    }, [getSource]);

    const getPayload = useCallback(
        (source: TRI_SOURCES) => {
            if (source === TRI_SOURCES.Selected) {
                return {
                    payload: selectedRows,
                    isReady: numberOfSelectedRows > 0,
                    isSelectedSingleItem: numberOfSelectedRows === 1
                };
            }

            if (source === TRI_SOURCES.Filtered) {
                const checkIfSingleValueOnlyAccepted = (
                    filterName: string,
                    filterValue: string
                ) => {
                    const decodedValue = decodeURIComponent(filterValue);

                    // In the future there will be enum list with all single filters
                    return filterName === FILTER_NAMES.RootCompany
                        ? decodedValue
                        : [decodedValue];
                };

                const decodedPayload = Object.entries(filterQueryParams).map(
                    ([filterName, filterValue]) => ({
                        [filterName]: Array.isArray(filterValue)
                            ? decodeArray([...filterValue])
                            : checkIfSingleValueOnlyAccepted(
                                  filterName,
                                  filterValue
                              )
                    })
                );

                return {
                    payload: decodedPayload,
                    isReady: Number(state.filteredOnlyCount) > 0
                };
            }

            return {
                payload: file,
                isReady: file !== null && lineCount > 0
            };
        },
        // eslint-disable-next-line
        [
            state.filteredOnlyCount,
            filterQueryParams,
            numberOfSelectedRows,
            selectedRows,
            lineCount,
            file
        ]
    );

    // Set source payload and check if at least one item is selected, filtered, or exists in a file
    useEffect(() => {
        const { payload, isReady, isSelectedSingleItem } = getPayload(
            state.activeSource
        );

        dispatch({
            type: SET_SOURCE_PAYLOAD,
            payload: {
                isReady,
                newSourcePayload: payload,
                isSelectedSingleItem: Boolean(isSelectedSingleItem)
            }
        });
    }, [state.activeSource, getPayload]);

    const getTooltipText = useCallback(
        (source: TRI_SOURCES) =>
            source === TRI_SOURCES.Selected
                ? t(`Dialog##select at least one##${tableName}`)
                : t("Dialog##use at least one filter"),
        [t, tableName]
    );

    const getDisabledStatus = useCallback(
        (source: TRI_SOURCES) => {
            if (source === TRI_SOURCES.Selected) {
                return numberOfSelectedRows < 1;
            }

            if (source === TRI_SOURCES.Filtered) {
                return numberOfFiltersUsed < 1;
            }

            return false;
        },
        [numberOfSelectedRows, numberOfFiltersUsed]
    );

    const setActiveSource = (source: TRI_SOURCES) =>
        dispatch({
            type: SET_ACTIVE_SOURCE,
            payload: source
        });

    const handleTabChange = useCallback(
        (_: SyntheticEvent, newValue: TRI_SOURCES) => setActiveSource(newValue),
        []
    );

    const handleNeedConfirmation = useCallback((confirmationState: boolean) => {
        dispatch({
            type: HANDLE_NEED_CONFIRMATION,
            payload: confirmationState
        });
    }, []);

    const setFilteredOnlyCount = (count: number) =>
        dispatch({
            type: SET_FILTERED_ONLY_COUNT,
            payload: count
        });

    const handleShowConfirmation = (open: boolean) =>
        dispatch({ type: HANDLE_SHOW_CONFIRMATION, payload: open });

    const resetToInitialState = useCallback(() => {
        const source = getSource();

        const {
            payload: sourcePayload,
            isReady,
            isSelectedSingleItem
        } = getPayload(source);

        dispatch({
            type: RESET_TO_INITIAL_STATE,
            payload: {
                source,
                sourcePayload,
                isReady,
                isSelectedSingleItem: Boolean(isSelectedSingleItem)
            }
        });
    }, [getSource, getPayload]);

    const value = useMemo(
        () => ({
            activeSource: state.activeSource,
            sourcePayload: state.sourcePayload,
            isSourceReady: state.isSourceReady,
            isSelectedSingleItem: state.isSelectedSingleItem,
            filteredOnlyCount: state.filteredOnlyCount,
            needConfirmation: state.needConfirmation,
            showConfirmation: state.showConfirmation,
            getTooltipText,
            getDisabledStatus,
            handleTabChange,
            handleNeedConfirmation,
            setFilteredOnlyCount,
            handleShowConfirmation,
            resetToInitialState
        }),
        [
            state.activeSource,
            state.sourcePayload,
            state.isSourceReady,
            state.isSelectedSingleItem,
            state.filteredOnlyCount,
            state.needConfirmation,
            state.showConfirmation,
            getTooltipText,
            getDisabledStatus,
            handleTabChange,
            handleNeedConfirmation,
            resetToInitialState
        ]
    );

    return (
        <TriSourceContext.Provider value={value}>
            {children}
        </TriSourceContext.Provider>
    );
};

export default TriSourceState;
