import { useContext } from "react";

import { ThemeContext } from "../../../context/theme";

const TableIsEmptyIconStopFragment = () => {
    const {
        colorsFacelift: { blue400 }
    } = useContext(ThemeContext);

    return (
        <>
            <stop stopColor={blue400} />
            <stop offset="0.13" stopColor={blue400} stopOpacity="0.98" />
            <stop offset="0.25" stopColor={blue400} stopOpacity="0.94" />
            <stop offset="0.38" stopColor={blue400} stopOpacity="0.86" />
            <stop offset="0.5" stopColor={blue400} stopOpacity="0.75" />
            <stop offset="0.63" stopColor={blue400} stopOpacity="0.6" />
            <stop offset="0.76" stopColor={blue400} stopOpacity="0.43" />
            <stop offset="0.88" stopColor={blue400} stopOpacity="0.23" />
            <stop offset="1" stopColor={blue400} stopOpacity="0" />
        </>
    );
};

export default TableIsEmptyIconStopFragment;
