import { Fragment } from "react";

import TableHeadToolbar from "../../TableHeadToolbar";
import ActionsForSelection from "../../../Actions/TableHeadActions";

interface TableHeadActiveProps {
    toggleActiveHead: (value: boolean) => void;
}

const TableHeadActive = ({ toggleActiveHead }: TableHeadActiveProps) => {
    return (
        <Fragment>
            <TableHeadToolbar>
                <ActionsForSelection toggleActiveHead={toggleActiveHead} />
            </TableHeadToolbar>
        </Fragment>
    );
};

export default TableHeadActive;
