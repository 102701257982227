/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Radio as MuiRadio } from "@mui/material";

import { CombinedRadioProps } from "./types";
import ThemeContext from "../../../context/theme/themeContext";
import RadioDefaultIcon from "../../../assets/customIcons/radio/DefaultIcon";
import RadioDefaultCheckedIcon from "../../../assets/customIcons/radio/DefaultCheckedIcon";
import RadioDisabledIcon from "../../../assets/customIcons/radio/DisabledIcon";
import RadioDisabledCheckedIcon from "../../../assets/customIcons/radio/DisabledCheckedIcon";
import RadioErrorIcon from "../../../assets/customIcons/radio/ErrorIcon";
import RadioErrorCheckedIcon from "../../../assets/customIcons/radio/ErrorCheckedIcon";

const Radio = ({ label, info, isError, ...props }: CombinedRadioProps) => {
    const {
        colorsFacelift: {
            blue700,
            blue800,
            gray300,
            gray400,
            gray700,
            textDark,
            textDarkDisabled
        }
    } = useContext(ThemeContext);

    const getIcons = () => {
        if (props.disabled) {
            return {
                icon: <RadioDisabledIcon />,
                checkedIcon: <RadioDisabledCheckedIcon />
            };
        } else if (isError) {
            return {
                icon: <RadioErrorIcon />,
                checkedIcon: <RadioErrorCheckedIcon />
            };
        } else {
            return {
                icon: <RadioDefaultIcon />,
                checkedIcon: <RadioDefaultCheckedIcon />
            };
        }
    };

    const icon = getIcons().icon;
    const checkedIcon = getIcons().checkedIcon;

    const isDefault = !props.disabled && !isError;

    return (
        <label
            css={css({
                display: "inline-flex",
                alignItems: "flex-start"
            })}
        >
            <MuiRadio
                {...props}
                size="small"
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{
                    padding: "0",
                    marginRight: label || info ? "8px" : "0",
                    color: gray300,

                    "& > svg": {
                        fontSize: "24px"
                    },

                    "&:hover": {
                        color: isDefault ? gray400 : "unset"
                    },
                    "&.Mui-checked": {
                        color: blue700
                    },
                    "&:hover.Mui-checked": {
                        color: isDefault ? blue800 : "unset"
                    }
                }}
            />

            <div
                css={css({
                    cursor: props.disabled ? "auto" : "pointer",
                    alignSelf: "center",

                    "& > span": {
                        display: "block"
                    }
                })}
            >
                {label && (
                    <span
                        css={css({
                            color: props.disabled ? textDarkDisabled : textDark,
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px"
                        })}
                    >
                        {label}
                    </span>
                )}

                {info && (
                    <span
                        css={css({
                            color: props.disabled ? textDarkDisabled : gray700,
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterspacing: "0.4px",
                            marginTop: label ? "0" : "2px"
                        })}
                    >
                        {info}
                    </span>
                )}
            </div>
        </label>
    );
};

export default Radio;
