import TabFilters from "./TabFilters";

import useUserInlineActions from "../Actions/InlineActions/hooks/useUserInlineActions";
import useUserInvitationInlineActions from "../Actions/InlineActions/hooks/useUserInvitationInlineActions";
import EditUserInline from "../Actions/dialogs/Users/Inline/EditUser/EditUserInline";
import MainContainer from "../MainContainer";
import Table from "../Table";

import {
    ID_TYPE,
    TABLE_NAMES,
    USER_COLUMNS,
    USER_INVITE_COLUMNS,
    useWindowLocation,
    ROUTES
} from "../../shared";

const Users = () => {
    const {
        inlineActions: usersInlineActions,
        rowData,
        isOpen,
        closeDialog
    } = useUserInlineActions();

    const { inviteInlineActions, ActionDialogs } =
        useUserInvitationInlineActions();

    const { pathname } = useWindowLocation();

    const isUsersTab = pathname === ROUTES.Users;

    return (
        <>
            <MainContainer>
                <Table
                    data-testid={
                        isUsersTab ? "users-table" : "user-invite-table"
                    }
                    key={
                        isUsersTab ? TABLE_NAMES.Users : TABLE_NAMES.UserInvite
                    }
                    name={
                        isUsersTab ? TABLE_NAMES.Users : TABLE_NAMES.UserInvite
                    }
                    initialColumns={
                        isUsersTab ? USER_COLUMNS : USER_INVITE_COLUMNS
                    }
                    inlineActions={
                        isUsersTab ? usersInlineActions : inviteInlineActions
                    }
                    idType={ID_TYPE.Id}
                    hideFilters
                    hasActionBar
                    isNotSelectable={pathname === ROUTES.UserInvite}
                    tabFilters={<TabFilters tableName={pathname} />}
                />

                <ActionDialogs />
            </MainContainer>

            {isUsersTab && (
                <EditUserInline
                    data={rowData}
                    isOpen={isOpen}
                    close={closeDialog}
                />
            )}
        </>
    );
};

export default Users;
