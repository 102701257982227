import { DateTimeProps } from "./types";

import { useDateTime } from "../../shared";

const DateTime = ({ date, format, locale }: DateTimeProps) => {
    const { formattedDate } = useDateTime();

    return <>{formattedDate(date, format, locale)}</>;
};

export default DateTime;
