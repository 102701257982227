/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import TableHeadActive from "./TableHeadActive";

import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";
import DefaultCheckedIcon from "../../../../assets/customIcons/checkbox/CheckboxDefaultCheckedIcon";

interface TableHeadProps {
    isActiveHead: boolean;
    toggleActiveHead: (value: boolean) => void;
}

const TableHead = ({ isActiveHead, toggleActiveHead }: TableHeadProps) => {
    const {
        colorsFacelift: {
            blue100,
            blue200,
            blue400,
            blue500,
            textDark,
            textPlaceholder
        }
    } = useContext(ThemeContext);

    const { isNotSelectable } = useContext(TableContext);

    const { t } = useTranslation();

    const renderContent = () => {
        if (isNotSelectable) {
            return (
                <div
                    css={css({
                        height: "32px",
                        width: "100%"
                    })}
                />
            );
        }

        return isActiveHead ? (
            <TableHeadActive toggleActiveHead={toggleActiveHead} />
        ) : (
            <div
                onClick={() => toggleActiveHead(true)}
                css={css({
                    display: "inline-flex",
                    alignItems: "center",
                    color: textDark,
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    letterSpacing: "0.1px",
                    border: `1px solid ${blue200}`,
                    borderRadius: "8px",
                    padding: "3px 15px 3px 7px",
                    cursor: "pointer",
                    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                    svg: {
                        color: textPlaceholder,
                        marginRight: "6px"
                    },

                    "&:hover": {
                        background: blue400,
                        borderColor: blue400
                    },

                    "&:active": {
                        background: blue500,
                        borderColor: blue500
                    }
                })}
            >
                <DefaultCheckedIcon />

                <span>{t("Button##select rows")}</span>
            </div>
        );
    };

    return (
        <div
            css={css({
                position: "sticky",
                top: "0",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "8px",
                background: blue100,
                borderTop: `1px solid ${blue200}`,
                borderBottom: `1px solid ${blue200}`,
                zIndex: "1",
                padding: "4px 24px"
            })}
        >
            {renderContent()}
        </div>
    );
};

export default TableHead;
