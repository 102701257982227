import { useState, useEffect, useContext } from "react";

import {
    getCompanyInfo,
    getDescription,
    getFileParams,
    getFilteredData,
    getQueryParams,
    getResourceParams,
    getRootCompanyParams,
    getTransferCompanyInfo
} from "../functions";

import { UseQueryDropdownOptions } from "../types";

import ParamsContext from "../../../context/params/paramsContext";
import AuthContext from "../../../context/auth/authContext";

import {
    API_IS_BEING_CANCELLED,
    getStringifiedParams,
    Query,
    useApi
} from "../../../shared";

export const useQueryDropdown = (parameters: UseQueryDropdownOptions) => {
    const { queryParams: allQueryParams } = useContext(ParamsContext);
    const { isAdminUser } = useContext(AuthContext);

    const { queryData, handleResponse, cancelSource, isCanceled } = useApi();

    const {
        resource,
        idType,
        descriptionResource,
        exceptions,
        hasOnlyInternalCompanies,
        hasNotAssigned,
        hasUploadedFile,
        isRootRequired,
        getOptionDisabledTooltip
    } = parameters;

    const [isLoading, setLoading] = useState(Boolean(resource));
    const [query, setQuery] = useState("");
    const [options, setOptions] = useState<Query[]>([]);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            if (resource) {
                try {
                    setLoading(true);

                    const [resourceName, resourceStringParams] =
                        resource.split("?");

                    const resourceParams =
                        getResourceParams(resourceStringParams);

                    const fileParams = getFileParams(resourceName);

                    const queryParams = getQueryParams(query);

                    const rootCompanyParams = getRootCompanyParams(
                        allQueryParams,
                        isRootRequired
                    );

                    const params = getStringifiedParams({
                        ...resourceParams,
                        ...fileParams,
                        ...queryParams,
                        ...rootCompanyParams
                    });

                    const {
                        data: { data }
                    } = await queryData(resourceName, params);

                    if (isSubscribed) {
                        const queryResult: Query[] = data.map(
                            (singleItem: any) => {
                                const disabledTooltip =
                                    getOptionDisabledTooltip &&
                                    getOptionDisabledTooltip(singleItem);

                                return {
                                    id: singleItem[idType],
                                    name: singleItem.name,
                                    disabledTooltip,
                                    ...getDescription(
                                        singleItem,
                                        isAdminUser,
                                        resource,
                                        descriptionResource
                                    ),
                                    ...getCompanyInfo(singleItem, resourceName),
                                    ...getTransferCompanyInfo(singleItem)
                                };
                            }
                        );

                        const filteredData = getFilteredData(
                            queryResult,
                            exceptions,
                            hasOnlyInternalCompanies,
                            hasNotAssigned
                        );

                        setOptions(filteredData);
                        setLoading(false);
                    }
                } catch (error) {
                    if (isSubscribed && !isCanceled(error)) {
                        handleResponse(error);
                        setLoading(false);
                    }
                }
            }
        };

        fetchData();

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [
        resource,
        query,
        exceptions,
        hasOnlyInternalCompanies,
        hasUploadedFile
    ]);

    return { isLoading, options, query, setQuery };
};
