/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useDialog } from "./hooks";
import ContentFrame from "./components/ContentFrame";

import CreateDesktopLicense from "../dialogs/Licenses/DesktopLicenses/CreateDesktopLicense";
import { LICENSE_ACTIONS } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";

import AuthContext from "../../../context/auth/authContext";
import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import { isInternalTypeCompany, useMediaQueries } from "../../../shared";

const LicensesBar = () => {
    const { isSystemUser } = useContext(AuthContext);
    const { rootCompanyType } = useContext(ParamsContext);
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const isInternalType = isInternalTypeCompany(rootCompanyType);
    const isCreateBtnDisabled = !isSystemUser && isInternalType;

    return (
        <>
            <ContentFrame>
                <ActionToolbarButtonWrapper>
                    <TooltipGeneral
                        title={
                            isCreateBtnDisabled
                                ? t("Button##cannot create desktop licenses")
                                : ""
                        }
                        placement={
                            toSm
                                ? TOOLTIP_PLACEMENT.Bottom
                                : TOOLTIP_PLACEMENT.Left
                        }
                    >
                        <span
                            css={css({
                                display: "inline-flex"
                            })}
                        >
                            <Button
                                fullWidth={toSm}
                                hasRectangleLoader={toSm && isInitialLoading}
                                variant={BUTTON_VARIANTS.TextOnly}
                                size={BUTTON_SIZES.Small}
                                color={BUTTON_COLORS.Primary}
                                disabled={isCreateBtnDisabled}
                                onClick={() =>
                                    openDialog(
                                        LICENSE_ACTIONS.CreateDesktopLicense
                                    )
                                }
                            >
                                {t("Button##create")}
                            </Button>
                        </span>
                    </TooltipGeneral>
                </ActionToolbarButtonWrapper>
            </ContentFrame>

            <CreateDesktopLicense
                isOpen={isOpenDialog[LICENSE_ACTIONS.CreateDesktopLicense]}
                close={closeDialog}
            />
        </>
    );
};

export default LicensesBar;
