import { useReducer, useEffect, useContext, useMemo, useCallback } from "react";

import { useTableFunctions } from "./hooks";
import Table from "./Table";

import ActionBar from "../Actions/ActionBar";

import ParamsContext from "../../context/params/paramsContext";
import AuthContext from "../../context/auth/authContext";
import TableContext from "../../context/table/tableContext";
import TableReducer from "../../context/table/tableReducer";
import {
    SET_TABLE_DATA,
    SET_INNER_TABLE_PARAMS,
    RESET_INNER_TABLE_ALL_PARAMS,
    RESET_INNER_TABLE_FILTER_PARAMS,
    TOGGLE_ACTIVE_COLUMN,
    RESTORE_ACTIVE_COLUMNS,
    SET_TABLE_LOADING,
    TOGGLE_SELECTION_OF_SINGLE_ROW,
    SELECT_MULTIPLE_ROWS,
    DESELECT_MULTIPLE_ROWS,
    DESELECT_ALL_AND_SELECT_MULTIPLE,
    DESELECT_ALL_ROWS,
    SET_DETAILS_DATA,
    UPDATE_SINGLE_ROW_DATA,
    DELETE_MULTIPLE_ROWS,
    SET_CUSTOM_RESOURCE
} from "../../context/table/tableActions";
import TriSourceState from "../../context/triSource/TriSourceState";
import { getStringifiedParams, NOT_FILTER_NAMES } from "../../shared";
import {
    SetInnerTableParamsOptions,
    TableStateProps
} from "../../context/table/types";

const TableState = ({
    name,
    idType,
    nameForFilters,
    isInnerTable,
    isNotSelectable,
    selectableCount,
    hideSearch,
    hideFilters,
    customResource,
    sortFromNewest,
    innerTableQueryParams,
    initialColumns,
    inlineActions,
    customTable,
    hasActionBar,
    tabFilters,
    hasDetails,
    customStyle,
    outerDetailsData,
    customData,
    getInnerTableRowsData
}: TableStateProps) => {
    const { tableRowDeselectionToggle, setQueryParams } =
        useContext(ParamsContext);

    const { user } = useContext(AuthContext);

    const { renderColumnsWithIsActive } = useTableFunctions();

    const initialInnerTableStringParams = innerTableQueryParams
        ? getStringifiedParams(innerTableQueryParams)
        : "";

    const initialState = {
        tableName: name,
        tableNameForFilters: nameForFilters || null,
        idType,
        isInnerTable: Boolean(isInnerTable),
        isNotSelectable: Boolean(isNotSelectable),
        totalSelectableRows: 0,
        hideSearch: Boolean(hideSearch),
        hideFilters: Boolean(hideFilters),
        customResource: customResource || "",
        sortFromNewest: Boolean(sortFromNewest),
        hasDetails: Boolean(hasDetails),
        columns: initialColumns
            ? renderColumnsWithIsActive(name, initialColumns)
            : [],
        rows: [],
        selectableRows: [],
        selectedRows: [],
        inlineActions: inlineActions || [],
        detailsData: null,
        outerDetailsData: outerDetailsData || null,
        customData: customData || null,
        innerTableQueryParams: innerTableQueryParams || {},
        innerTableStringParams: initialInnerTableStringParams,
        innerTableFilteredValues: [],
        innerTableInitialParamNames: innerTableQueryParams
            ? (Object.keys(innerTableQueryParams) as any)
            : [],
        currentPage: 1,
        rowsPerPage: 25,
        totalRows: 0,
        isInitialLoading: true,
        isTableLoading: true
    };

    const [state, dispatch] = useReducer(TableReducer, initialState);

    useEffect(() => {
        deselectAllRows();
    }, [tableRowDeselectionToggle]);

    useEffect(() => {
        innerTableQueryParams &&
            setInnerTableParams({ params: innerTableQueryParams });
    }, [innerTableQueryParams]);

    useEffect(() => {
        customResource && setCustomResource(customResource);
    }, [customResource]);

    useEffect(() => {
        customData &&
            setTableData({
                rows: customData,
                customData
            });
        // eslint-disable-next-line
    }, [customData]);

    const setTableData = useCallback(
        (data: any) => {
            dispatch({
                type: SET_TABLE_DATA,
                payload: {
                    ...data,
                    totalSelectableRows: selectableCount || 0,
                    outerDetailsData,
                    user
                }
            });
        },
        [outerDetailsData, selectableCount, user]
    );

    const setInnerTableParams = (parameters: SetInnerTableParamsOptions) => {
        const { params, resetPage, filteredValues } = parameters;

        const getParamsQuery = () => {
            if (params.query) {
                return params.query.length >= 3
                    ? { query: params.query }
                    : { query: "" };
            }

            return {};
        };

        const updatedParams = {
            ...params,
            ...getParamsQuery(),
            ...(Boolean(resetPage) && { page: "" })
        };

        dispatch({
            type: SET_INNER_TABLE_PARAMS,
            payload: {
                updatedParams,
                filteredValues
            }
        });
    };

    const setCustomResource = (resource: string) =>
        dispatch({ type: SET_CUSTOM_RESOURCE, payload: resource });

    const resetInnerTableAllParams = () =>
        dispatch({ type: RESET_INNER_TABLE_ALL_PARAMS });

    const resetInnerTableFilterParams = () =>
        dispatch({ type: RESET_INNER_TABLE_FILTER_PARAMS });

    const toggleActiveColumn = (columnName: string) =>
        dispatch({ type: TOGGLE_ACTIVE_COLUMN, payload: columnName });

    const restoreActiveColumns = () =>
        dispatch({ type: RESTORE_ACTIVE_COLUMNS });

    const setTableLoading = (value: boolean) =>
        dispatch({ type: SET_TABLE_LOADING, payload: value });

    const toggleSelectionOfSingleRow = (row: any) =>
        dispatch({ type: TOGGLE_SELECTION_OF_SINGLE_ROW, payload: row });

    const selectMultipleRows = (rows: any) =>
        dispatch({ type: SELECT_MULTIPLE_ROWS, payload: rows });

    const deselectMultipleRows = (rows: any) =>
        dispatch({ type: DESELECT_MULTIPLE_ROWS, payload: rows });

    const deselectAllAndSelectMultiple = (rows: any) =>
        dispatch({ type: DESELECT_ALL_AND_SELECT_MULTIPLE, payload: rows });

    const deselectAllRows = () => dispatch({ type: DESELECT_ALL_ROWS });

    const setDetailsData = useCallback(
        (detailsData: any) => {
            setQueryParams({
                params: {
                    [NOT_FILTER_NAMES.Selected]: detailsData
                        ? String(detailsData[state.idType])
                        : ""
                }
            });

            dispatch({
                type: SET_DETAILS_DATA,
                payload: { detailsData }
            });
        },
        [state.idType, setQueryParams]
    );

    const updateSingleRowData = useCallback(
        (data: any) => {
            dispatch({ type: UPDATE_SINGLE_ROW_DATA, payload: { data, user } });
        },
        [user]
    );

    const deleteMultipleRows = useCallback(
        (id: number[]) => {
            dispatch({ type: DELETE_MULTIPLE_ROWS, payload: { id, user } });
        },
        [user]
    );

    const value = useMemo(
        () => ({
            tableName: state.tableName,
            tableNameForFilters: state.tableNameForFilters,
            idType: state.idType,
            isInnerTable: state.isInnerTable,
            isNotSelectable: state.isNotSelectable,
            totalSelectableRows: state.totalSelectableRows,
            hideSearch: state.hideSearch,
            hideFilters: state.hideFilters,
            customResource: state.customResource,
            sortFromNewest: state.sortFromNewest,
            hasDetails: state.hasDetails,
            columns: state.columns,
            rows: state.rows,
            inlineActions: state.inlineActions,
            selectableRows: state.selectableRows,
            selectedRows: state.selectedRows,
            detailsData: state.detailsData,
            outerDetailsData: state.outerDetailsData,
            customData: state.customData,
            innerTableQueryParams: state.innerTableQueryParams,
            innerTableStringParams: state.innerTableStringParams,
            innerTableFilteredValues: state.innerTableFilteredValues,
            innerTableInitialParamNames: state.innerTableInitialParamNames,
            currentPage: state.currentPage,
            rowsPerPage: state.rowsPerPage,
            totalRows: state.totalRows,
            isInitialLoading: state.isInitialLoading,
            isTableLoading: state.isTableLoading,
            setTableData,
            setInnerTableParams,
            resetInnerTableAllParams,
            resetInnerTableFilterParams,
            toggleActiveColumn,
            restoreActiveColumns,
            setTableLoading,
            toggleSelectionOfSingleRow,
            selectMultipleRows,
            deselectMultipleRows,
            deselectAllAndSelectMultiple,
            deselectAllRows,
            setDetailsData,
            updateSingleRowData,
            deleteMultipleRows
        }),
        [
            state.tableName,
            state.tableNameForFilters,
            state.idType,
            state.isInnerTable,
            state.isNotSelectable,
            state.totalSelectableRows,
            state.hideSearch,
            state.hideFilters,
            state.customResource,
            state.sortFromNewest,
            state.hasDetails,
            state.columns,
            state.rows,
            state.inlineActions,
            state.selectableRows,
            state.selectedRows,
            state.detailsData,
            state.outerDetailsData,
            state.customData,
            state.innerTableQueryParams,
            state.innerTableStringParams,
            state.innerTableFilteredValues,
            state.innerTableInitialParamNames,
            state.currentPage,
            state.rowsPerPage,
            state.totalRows,
            state.isInitialLoading,
            state.isTableLoading,
            setTableData,
            setDetailsData,
            updateSingleRowData,
            deleteMultipleRows
        ]
    );

    return (
        <TableContext.Provider value={value}>
            {hasActionBar && (
                <TriSourceState>
                    <ActionBar />
                </TriSourceState>
            )}

            {tabFilters}

            {customTable || (
                <Table
                    getInnerTableRowsData={getInnerTableRowsData}
                    customStyle={customStyle}
                />
            )}
        </TableContext.Provider>
    );
};

export default TableState;
