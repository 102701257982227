import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TutorialIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M6 7H12C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13H6C5.45 13 5 12.55 5 12V8C5 7.45 5.45 7 6 7Z"
                fill="currentColor"
            />
            <path
                d="M21.175 22.9058L17.25 18.9808L16.5 21.2558C16.4166 21.4891 16.2583 21.6016 16.025 21.5933C15.7916 21.5849 15.6333 21.4641 15.55 21.2308L13.275 13.6558C13.2083 13.4724 13.25 13.3058 13.4 13.1558C13.55 13.0058 13.7166 12.9641 13.9 13.0308L21.475 15.3058C21.7083 15.3891 21.8291 15.5474 21.8375 15.7808C21.8458 16.0141 21.7333 16.1724 21.5 16.2558L19.225 17.0058L23.15 20.9308C23.25 21.0308 23.3 21.1474 23.3 21.2808C23.3 21.4141 23.25 21.5308 23.15 21.6308L21.875 22.9058C21.775 23.0058 21.6583 23.0558 21.525 23.0558C21.3916 23.0558 21.275 23.0058 21.175 22.9058Z"
                fill="currentColor"
            />
            <path
                d="M20 5H4C3.2 5 3 5.66667 3 6V18C3 18.8 3.66667 19 4 19H13.5L13.7764 19.5528C13.9172 19.8343 13.9172 20.1657 13.7764 20.4472L13.5 21H3C1.4 21 1 19.6667 1 19V5C1 3.4 2.33333 3 3 3H21C22.6 3 23 4.33333 23 5V14L22.8944 14.0528C22.3314 14.3343 21.6686 14.3343 21.1056 14.0528L21 14V6C21 5.2 20.3333 5 20 5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default TutorialIcon;
