/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { CompanyLevelTagProps } from "../types";

import Tag, { TAG_COLORS, TAG_SIZES } from "../../../DesignComponents/Tag";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import { COMPANY_LEVELS } from "../../../../shared";

const CompanyLevelTag = ({ level, onClick }: CompanyLevelTagProps) => {
    const { t } = useTranslation();

    const getTagColor = () => {
        switch (level) {
            case COMPANY_LEVELS.Basic:
                return TAG_COLORS.BasicBundle;
            case COMPANY_LEVELS.Standard:
                return TAG_COLORS.StandardBundle;
            case COMPANY_LEVELS.Premium:
                return TAG_COLORS.PremiumBundle;
            default:
                return TAG_COLORS.Primary;
        }
    };

    return (
        <TooltipGeneral
            placement={TOOLTIP_PLACEMENT.Top}
            title={onClick ? t("General##click for more details") : ""}
            disableTouchListener
        >
            <span
                css={css({
                    display: "inline-flex"
                })}
            >
                <Tag
                    size={TAG_SIZES.Tiny}
                    color={getTagColor()}
                    title={t(`Dialog##company level##${level}`)}
                    isNotSelectable
                    onClick={onClick}
                />
            </span>
        </TooltipGeneral>
    );
};

export default CompanyLevelTag;
