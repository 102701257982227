import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CheckboxErrorIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { red50, red700, white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="7"
                fill={white}
                stroke={red50}
                strokeWidth="2"
            />
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill={white}
                stroke={red700}
            />
        </SvgIcon>
    );
};

export default CheckboxErrorIcon;
