import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const TaskQueueIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect
                    x="4.5"
                    y="5.99988"
                    width="16"
                    height="4"
                    rx="1"
                    stroke={textDark}
                    strokeWidth="2"
                />

                <rect
                    x="4.5"
                    y="14.0001"
                    width="16"
                    height="4"
                    rx="1"
                    stroke={textDark}
                    strokeWidth="2"
                />

                <rect x="4.5" y="6" width="8" height="3" fill={textDark} />
                <rect x="4.5" y="15" width="4" height="3" fill={textDark} />
            </g>
        </SvgIcon>
    );
};

export default TaskQueueIcon;
