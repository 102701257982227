/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { SingleResourceLimitProps } from "./types";

import ThemeContext from "../../../../../context/theme/themeContext";

const SingleResourceLimit = ({
    title,
    current,
    limit
}: SingleResourceLimitProps) => {
    const {
        colorsFacelift: { gray200, gray500, red700, red800, textDark }
    } = useContext(ThemeContext);

    const getFilledPercentage = () => {
        if (isUnlimited) {
            return "100%";
        }

        if (isLimitReached) {
            return "0";
        }

        const fullPercentage = (current * 100) / limit;
        const roundedPercentage = Math.round(fullPercentage);

        return `${100 - roundedPercentage}%`;
    };

    const isUnlimited = limit === -1;
    const isLimitReached = current >= limit;

    const stats = isUnlimited ? current : `${current} / ${limit}`;

    return (
        <div
            css={css({
                marginTop: "16px"
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    marginBottom: "4px",

                    "& > span:first-of-type": {
                        color: textDark
                    },

                    "& > span:last-of-type": {
                        color:
                            isUnlimited || !isLimitReached ? textDark : red800
                    }
                })}
            >
                <span>{title}:</span>
                <span data-testid="resource-limit-stats">{stats}</span>
            </div>

            <div
                data-testid="resource-limit-progress-bar"
                css={css({
                    background: gray200,
                    height: "8px",
                    borderRadius: "100px",
                    position: "relative"
                })}
            >
                <span
                    data-testid="resource-limit-progress-bar-filled"
                    css={css({
                        background: isLimitReached ? red700 : gray500,
                        height: "100%",
                        borderRadius: "100px",
                        position: "absolute",
                        left: "0",
                        right: getFilledPercentage(),
                        transition: "right 0.4s linear"
                    })}
                />
            </div>
        </div>
    );
};

export default SingleResourceLimit;
