import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import NotificationList from "./NotificationList";
import { NotificationsContentProps } from "./types";

import NotificationsContext from "../../../../context/notifications/notificationsContext";
import ControlsItemFrame from "../../../../components/Navbar/Controls/components/ControlsItemFrame";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    useApi,
    useMediaQueries
} from "../../../../shared";

const NotificationsContent = ({ close }: NotificationsContentProps) => {
    const { setNotifications, setNotificationsCount } =
        useContext(NotificationsContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();
    const { cancelSource, getData, postData, isCanceled, handleResponse } =
        useApi();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true;

        const readAllNotifications = async () => {
            try {
                await postData(`${ENDPOINTS.Notifications}/markAllRead`);
                setNotificationsCount();
            } catch (error) {
                handleResponse(error);
            }
        };

        const getNotifications = async () => {
            try {
                const {
                    data: { data }
                } = await getData(`${ENDPOINTS.Notifications}?per_page=100`);

                if (isSubscribed) {
                    setNotifications(data);
                    readAllNotifications();
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getNotifications();

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ControlsItemFrame
                close={close}
                boxPadding={toMd ? "24px 4px 15px 16px" : "16px 24px 15px 24px"}
                itemName={t("Notifications##notifications")}
                hasBottomBorder
            />

            <NotificationList isLoading={isLoading} />
        </>
    );
};

export default NotificationsContent;
