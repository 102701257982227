import { useTranslation } from "react-i18next";

import { CUSTOM_RESOURCE_LIMITS } from "./constants";
import { RevertLimitsProps } from "./types";

import { Revert } from "../../shared/Revert";

const RevertLimits = ({ setDefaultValues }: RevertLimitsProps) => {
    const { t } = useTranslation();

    return (
        <Revert
            title={t(
                "Dialog##custom resource limits##revert custom resource limits title"
            )}
            description={t(
                "Dialog##custom resource limits##revert custom resource limits description"
            )}
            buttonText={t(
                "Dialog##custom resource limits##revert custom resource limits"
            )}
            confirmationTitle={t(
                "Dialog##custom resource limits##revert custom resource limits title confirmation"
            )}
            confirmationDescription={t(
                "Dialog##custom resource limits##revert custom resource limits description confirmation"
            )}
            deleteDataQuery={CUSTOM_RESOURCE_LIMITS}
            successMessage={t(
                "Flash##custom resource limits successfully reverted"
            )}
            successCallback={() => setDefaultValues()}
        />
    );
};

export default RevertLimits;
