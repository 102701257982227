import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CheckboxErrorCheckedIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { blue700, red50, red700, white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="7"
                fill={blue700}
                stroke={red50}
                strokeWidth="2"
            />
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                stroke={red700}
            />
            <path
                d="M8.29661 12.445C8.06788 12.2052 7.68809 12.1963 7.44832 12.425C7.20856 12.6538 7.19962 13.0336 7.42835 13.2733L9.81007 15.7699C10.0553 16.027 10.469 16.0158 10.7 15.7459L16.4274 9.05482C16.6429 8.80308 16.6135 8.42432 16.3618 8.20883C16.11 7.99335 15.7313 8.02274 15.5158 8.27448L10.5079 14.125C10.3539 14.3049 10.0781 14.3124 9.91458 14.141L8.29661 12.445Z"
                fill={white}
            />
        </SvgIcon>
    );
};

export default CheckboxErrorCheckedIcon;
