import { StatsTagProps } from "../types";

import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

const StatsTag = ({ title }: StatsTagProps) => {
    return (
        <div>
            <Tag
                title={title}
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
            />
        </div>
    );
};

export default StatsTag;
