/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import themeContext from "../../../../context/theme/themeContext";
import {
    ToggleSwitch,
    TOGGLE_SWITCH_LABEL_PLACEMENT,
    TOGGLE_SWITCH_SIZES
} from "../../../DesignComponents/ToggleSwitch";

interface ISingleCookieItemProps {
    isItemSeparator?: boolean;
    cookieDescription: string;
    cookieTitle: string;
    switcherLabel?: string;
    switcherLabelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT;
    toggleDisable?: boolean;
    toggleChecked?: boolean;
    onToggleChange: any;
    customStyle?: any;
}

export const SingleCookieItem = ({
    isItemSeparator,
    cookieDescription,
    cookieTitle,
    switcherLabel,
    switcherLabelPlacement,
    toggleDisable,
    toggleChecked,
    onToggleChange,
    customStyle
}: ISingleCookieItemProps) => {
    const {
        colorsFacelift: { gray300, gray700 }
    } = useContext(themeContext);

    return (
        <>
            <div
                css={css(
                    {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    },
                    customStyle
                )}
            >
                <div
                    css={css({
                        color: gray700,
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.15px",
                        maxWidth: "484px"
                    })}
                >
                    {cookieTitle}
                </div>
                <ToggleSwitch
                    size={TOGGLE_SWITCH_SIZES.Medium}
                    defaultChecked={toggleChecked}
                    disabled={toggleDisable}
                    label={switcherLabel}
                    labelPlacement={switcherLabelPlacement}
                    onChange={onToggleChange}
                />
            </div>
            <div
                css={css({
                    borderBottom: isItemSeparator
                        ? `1px solid ${gray300}`
                        : "unset"
                })}
            >
                <Typography
                    variant="body2"
                    css={css({
                        marginTop: "8px",
                        marginBottom: isItemSeparator ? "24px" : "unset",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: gray700
                    })}
                >
                    {cookieDescription}
                </Typography>
            </div>
        </>
    );
};
