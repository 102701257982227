/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import TableContext from "../../../context/table/tableContext";
import { ActionBarTitleLoader, useFormattedNumber } from "../../../shared";

const TaskGroupsBar = () => {
    const { totalRows, isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    return (
        <Fragment>
            {isInitialLoading ? (
                <ActionBarTitleLoader
                    customStyle={{
                        "@media(max-width: 599px)": { margin: "6px 0" }
                    }}
                />
            ) : (
                <h1
                    css={css({
                        margin: "6px 0"
                    })}
                >
                    <span>{t("General##task groups")}</span>
                    <span>({renderFormattedNumber(totalRows)})</span>
                </h1>
            )}
        </Fragment>
    );
};

export default TaskGroupsBar;
