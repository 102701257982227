/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableHead from "./TableHead";
import TableHeadActive from "./TableHead/TableHeadActive";

import TableItem from "../TableItem";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";

const TableDesktop = ({ scrollHorizontal }: any) => {
    const {
        colorsFacelift: { blue100, blue200, white }
    } = useContext(ThemeContext);

    const { rows, selectedRows, isTableLoading, idType, isNotSelectable } =
        useContext(TableContext);

    return (
        <Fragment>
            {selectedRows.length > 0 && <TableHeadActive />}

            <TableMui
                stickyHeader
                css={css({
                    tbody: {
                        opacity: isTableLoading ? "0.4" : "1",
                        transition: "opacity 300ms ease-out"
                    },

                    "th, td": {
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        whiteSpace: "nowrap"
                    },

                    th: {
                        background: blue100,
                        padding: isNotSelectable ? "10px 12px" : "8px 12px",
                        borderTop: `1px solid ${blue200}`,
                        borderBottom: `1px solid ${blue200}`
                    },

                    "th:last-of-type": {
                        padding: "8px 24px 8px 12px"
                    },

                    "th:first-of-type": {
                        padding: isNotSelectable
                            ? "8px 12px 8px 24px"
                            : "8px 6px 8px 24px"
                    },

                    td: {
                        background: white,
                        padding: "10px 12px",
                        maxWidth: "200px",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        borderBottom: "none"
                    },

                    "td:last-of-type": {
                        padding: "10px 24px 10px 12px"
                    },

                    "td:first-of-type": {
                        padding: "10px 12px 10px 24px"
                    },

                    "thead tr": {
                        height: "42px"
                    },

                    "tbody tr": {
                        height: "44px",

                        "&.Mui-selected td": {
                            background: blue100
                        },

                        "&:hover": {
                            "&.Mui-selected td": {
                                background: blue200
                            }
                        }
                    }
                })}
            >
                <TableHead scrollHorizontal={scrollHorizontal} />

                <TableBody>
                    {rows.map((row: any) => (
                        <TableItem
                            key={row[idType]}
                            data={row}
                            scrollHorizontal={scrollHorizontal}
                        />
                    ))}
                </TableBody>
            </TableMui>
        </Fragment>
    );
};

export default TableDesktop;
