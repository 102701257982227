import { createContext } from "react";
import { TutorialInitialState } from "./tutorialReducer";
import { STEP_GROUP } from "../../components/TutorialMode/types/enums";

interface TutorialContextState extends TutorialInitialState {
    setCompanyFilterOpen: (isOpen: boolean) => void;
    setDrawerStatus: (isOpen: boolean) => void;
    setStepIndex: (index: number) => void;
    setTutorialOpen: (
        isOpen: boolean,
        index?: number,
        stepGroup?: STEP_GROUP,
        keepActive?: boolean
    ) => void;
    setInitialRender: (render: boolean) => void;
}

const TutorialContext = createContext({} as TutorialContextState);

export default TutorialContext;
