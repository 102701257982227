/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Timestamp from "./Timestamp";
import MoreActions from "./MoreActions";
import { ActionBoxProps } from "./types";

const ActionBox = ({ id, date }: ActionBoxProps) => {
    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginLeft: "4px"
            })}
        >
            <Timestamp date={date} />
            <MoreActions id={id} />
        </div>
    );
};

export default ActionBox;
