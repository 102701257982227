/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

import Subtitle from "./Subtitle";
import { HeaderProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import {
    TooltipOptional,
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";
import Menu, { centeredTransformOrigin } from "../../DesignComponents/Menu";
import ListChoice from "../../DesignComponents/ListChoice";
import CancelTasksDialog from "../../Actions/dialogs/TaskGroups/CancelTasks";
import RetryFailedTasksDialog from "../../Actions/dialogs/TaskGroups/RetryFailedTasks";
import { useDialog, useMenu } from "../../Actions/ActionBar/hooks";
import { TASK_GROUP_ACTIONS } from "../../Actions/types";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import CancelAllTasksIcon from "../../../assets/customIcons/actions/CancelAllTasksIcon";
import {
    API_IS_BEING_CANCELLED,
    BatchTaskStatus,
    ENDPOINTS,
    TaskGroupsLoader,
    isFailedOrCompletedWithErrorsTask,
    isPendingOrRunningTask,
    isRetriableTaskType,
    useApi,
    useFormattedNumber,
    useIsMounted,
    useOverflow
} from "../../../shared";

const Header = ({ dataFromTables }: HeaderProps) => {
    const {
        colorsFacelift: { textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();
    const { measuredRef, isOverflowing } = useOverflow();
    const { renderFormattedNumber } = useFormattedNumber();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();
    const { cancelSource, getData, isCanceled, handleResponse } = useApi();
    const isMounted = useIsMounted();

    const { tasksRows, isTableLoading, totalRows } = dataFromTables;
    const { id, task_type, type, file, task_file_name } = detailsData;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [batchDetails, setBatchDetails] = useState({
        title: "",
        subtitle: {},
        file: null,
        fileName: null,
        isCancelBtnRendered: false,
        isRetryBtnRendered: false,
        cancelTasksCount: [] as BatchTaskStatus[],
        retryTasksCount: [] as BatchTaskStatus[]
    });

    useEffect(() => {
        if (!isTableLoading) {
            const getAllTaskStats = async () => {
                try {
                    const {
                        data: { task_stats: allTaskStats }
                    } = await getData(`${ENDPOINTS.Batches}/${id}`);

                    if (isMounted) {
                        const pendingAndRunningStatsCount: BatchTaskStatus[] =
                            allTaskStats.filter(
                                ({ status_id }: BatchTaskStatus) =>
                                    isPendingOrRunningTask(status_id)
                            );

                        const failedAndCompletedWithErrorsStatsCount: BatchTaskStatus[] =
                            allTaskStats.filter(
                                ({ status_id }: BatchTaskStatus) =>
                                    isFailedOrCompletedWithErrorsTask(status_id)
                            );

                        setBatchDetails({
                            title: task_type,
                            file: file,
                            fileName: task_file_name,
                            subtitle: detailsData,
                            isCancelBtnRendered:
                                pendingAndRunningStatsCount.length > 0,
                            isRetryBtnRendered:
                                failedAndCompletedWithErrorsStatsCount.length >
                                0,
                            cancelTasksCount: pendingAndRunningStatsCount,
                            retryTasksCount:
                                failedAndCompletedWithErrorsStatsCount
                        });
                    }
                } catch (error) {
                    if (isMounted && !isCanceled(error)) {
                        setBatchDetails({
                            title: task_type,
                            file: file,
                            fileName: task_file_name,
                            subtitle: detailsData,
                            isCancelBtnRendered: false,
                            isRetryBtnRendered: false,
                            cancelTasksCount: [],
                            retryTasksCount: []
                        });

                        handleResponse(error);
                    }
                }

                setIsLoading(false);
            };

            getAllTaskStats();

            return () => {
                cancelSource.cancel(API_IS_BEING_CANCELLED);
            };
        } else {
            setIsLoading(true);
        }

        // eslint-disable-next-line
    }, [isTableLoading, tasksRows]);

    const { isCancelBtnRendered, isRetryBtnRendered, title } = batchDetails;

    const batchTitle = title
        ? t(`General##${title}`)
        : t(`Task groups##bulk type##${type}`);

    const isRetriable: boolean = isRetriableTaskType(task_type);

    return (
        <Fragment>
            <Grid
                container
                columnSpacing={3}
                css={css({
                    paddingRight: "52px",
                    marginBottom: "4px",

                    "@media(max-width: 839px)": {
                        paddingRight: "0"
                    }
                })}
            >
                <Grid item xs0={12} md={7}>
                    <div
                        css={css({
                            display: "flex",

                            "& > span": {
                                color: textDark,
                                fontSize: "24px",
                                fontWeight: "600",
                                lineHeight: "32px"
                            },

                            "& > span: first-of-type": {
                                marginRight: "5px",
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis"
                            },

                            "span:last-of-type": {
                                color: textPlaceholder
                            },

                            "@media (max-width: 839px)": {
                                marginRight: "52px"
                            }
                        })}
                    >
                        {isLoading ? (
                            <TaskGroupsLoader
                                height="32px"
                                firstWidth="390px"
                                secondWidth="50px"
                            />
                        ) : (
                            <Fragment>
                                <TooltipGeneral
                                    placement={TOOLTIP_PLACEMENT.Top}
                                    title={batchTitle}
                                    disableHoverListener={!isOverflowing}
                                >
                                    <span ref={measuredRef}>{batchTitle}</span>
                                </TooltipGeneral>

                                <span>
                                    {`(${renderFormattedNumber(totalRows)})`}
                                </span>
                            </Fragment>
                        )}
                    </div>

                    <div>
                        <Subtitle
                            data={batchDetails.subtitle}
                            isLoading={isLoading}
                            file={batchDetails.file}
                            fileName={batchDetails.fileName}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    flexWrap="nowrap"
                    xs0={12}
                    md={5}
                    css={css({
                        "@media(max-width: 839px)": {
                            alignItems: "flex-end",
                            justifyContent: "flex-start"
                        }
                    })}
                >
                    <TooltipOptional
                        isHidden={isCancelBtnRendered || isRetryBtnRendered}
                        placement={TOOLTIP_PLACEMENT.Top}
                        title={t("Button##no actions available")}
                    >
                        <span>
                            <Button
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Small}
                                variant={BUTTON_VARIANTS.IconRight}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleOpenMenu(e);
                                }}
                                icon={<ExpandMoreRoundedIcon />}
                                css={css({
                                    "@media(max-width: 839px)": {
                                        marginTop: "16px"
                                    }
                                })}
                                disabled={
                                    !isCancelBtnRendered && !isRetryBtnRendered
                                }
                            >
                                {t("Button##actions")}
                            </Button>
                        </span>
                    </TooltipOptional>
                </Grid>
            </Grid>

            <Menu
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                onClick={e => e.stopPropagation()}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={centeredTransformOrigin}
                css={css({
                    "& .MuiMenu-paper": {
                        minWidth: "211px"
                    }
                })}
            >
                {isRetryBtnRendered && (
                    <TooltipOptional
                        isHidden={isRetriable}
                        placement={TOOLTIP_PLACEMENT.Top}
                        title={t("Button##task type cannot be retried")}
                    >
                        <span>
                            <ListChoice
                                title={t("Button##retry failed tasks")}
                                iconLeft={<ReplayRoundedIcon />}
                                onClick={() =>
                                    openDialog(
                                        TASK_GROUP_ACTIONS.RetryFailedTasks
                                    )
                                }
                                disabled={!isRetriable}
                            />
                        </span>
                    </TooltipOptional>
                )}

                {isCancelBtnRendered && (
                    <ListChoice
                        title={t("Button##cancel tasks")}
                        iconLeft={<CancelAllTasksIcon />}
                        onClick={() =>
                            openDialog(TASK_GROUP_ACTIONS.CancelTasks)
                        }
                    />
                )}
            </Menu>

            <CancelTasksDialog
                taskGroupId={id}
                data={batchDetails.cancelTasksCount}
                isOpen={isOpenDialog[TASK_GROUP_ACTIONS.CancelTasks]}
                close={() => closeDialog(TASK_GROUP_ACTIONS.CancelTasks)}
                closeMenu={handleCloseMenu}
            />

            <RetryFailedTasksDialog
                taskGroupId={id}
                data={batchDetails.retryTasksCount}
                isOpen={isOpenDialog[TASK_GROUP_ACTIONS.RetryFailedTasks]}
                close={() => closeDialog(TASK_GROUP_ACTIONS.RetryFailedTasks)}
                closeMenu={handleCloseMenu}
            />
        </Fragment>
    );
};

export default Header;
