export enum STEP_GROUP {
    TutorialCompanySelector = "tutorialCompanySelector",
    FirstTimeUser = "firstTimeUser",
    TutorialTaskGroups = "tutorialTaskGroups",
    FinishTutorial = "finishTutorial"
}

export enum TOOLTIP_ACTIONS {
    GetStarted = "Get started",
    Finish = "Finish",
    Next = "Next",
    NextFeature = "Next feature"
}
