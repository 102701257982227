import { ReactNode } from "react";

const getBgColor = (
    isNotification: boolean,
    blue100: string,
    blue200: string
) => (isNotification ? blue100 : blue200);

const getActiveBgColor = (isNotClickable: boolean, gray200: string) =>
    isNotClickable ? "unset" : gray200;

const getCursor = (isNotClickable: boolean) =>
    isNotClickable ? "default" : "pointer";

const getDescriptionColor = (
    isDisabled: boolean,
    textDarkDisabled: string,
    gray700: string
) => (isDisabled ? textDarkDisabled : gray700);

const getDescriptionFontSize = (isNotification: boolean) =>
    isNotification ? "14px" : "12px";

const getDescriptionLetterSpacing = (isNotification: boolean) =>
    isNotification ? "0.1px" : "0.4px";

const getDescriptionLineHeight = (isNotification: boolean) =>
    isNotification ? "20px" : "16px";

const getDescriptionMarginTop = (isNotification: boolean) =>
    isNotification ? "4px" : "0";

const getHoverBgColor = (isNotClickable: boolean, gray100: string) =>
    isNotClickable ? "unset" : gray100;

const getPadding = (
    isCheckbox: boolean,
    iconLeft: ReactNode,
    iconRight: ReactNode
) => {
    const isSmallerPadding = isCheckbox || iconLeft || iconRight;

    return isSmallerPadding ? "12px 16px" : "14px 16px";
};

const getSelectedBgColor = (
    isNotification: boolean,
    isCheckbox: boolean,
    blue100: string,
    blue200: string
) => {
    const bgColor = getBgColor(isNotification, blue100, blue200);

    return isCheckbox ? "unset" : bgColor;
};

const getSelectedHoverBgColor = (
    isNotification: boolean,
    isCheckbox: boolean,
    blue100: string,
    blue200: string,
    gray100: string
) => {
    const bgColor = getBgColor(isNotification, blue100, blue200);

    return isCheckbox ? gray100 : bgColor;
};

const getWhiteSpace = (isNormalWhiteSpace: boolean) =>
    isNormalWhiteSpace ? "normal" : "nowrap";

const getWordBreak = (isNormalWhiteSpace: boolean) =>
    isNormalWhiteSpace ? "break-word" : "normal";

export {
    getActiveBgColor,
    getBgColor,
    getCursor,
    getDescriptionColor,
    getDescriptionFontSize,
    getDescriptionLetterSpacing,
    getDescriptionLineHeight,
    getDescriptionMarginTop,
    getHoverBgColor,
    getPadding,
    getSelectedBgColor,
    getSelectedHoverBgColor,
    getWhiteSpace,
    getWordBreak
};
