/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import GeneralDropdown from "./GeneralDropdown";
import { SecondSelectProps } from "./types/interfaces";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";

const TlsCertificate = ({
    secondSelectName,
    changePayload
}: SecondSelectProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <GeneralDropdown
                secondSelectName={secondSelectName}
                changePayload={changePayload}
            />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Warning}
                title={t("Dialog##tls certificate transfer info")}
                css={css({
                    marginTop: "16px"
                })}
            />
        </Fragment>
    );
};

export default TlsCertificate;
