/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useMarks, useScheduleTask } from "./hooks";
import { ScheduleTaskProps } from "./types";

import Checkbox from "../../../../../../DesignComponents/Checkbox";
import Slider from "../../../../../../DesignComponents/Slider";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { TRACK_TYPE } from "../../../../../../DesignComponents/Slider/types";

const ScheduleTask = ({ change }: ScheduleTaskProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { marks } = useMarks();

    const {
        isInverted,
        value,
        fromDate,
        toDate,
        toggleInvertion,
        handleChange
    } = useScheduleTask(change);

    return (
        <Fragment>
            <Slider
                label={t("Dialog##select time interval")}
                value={value}
                onChange={(_, newValue) => handleChange(newValue as number[])}
                track={isInverted ? TRACK_TYPE.Inverted : TRACK_TYPE.Normal}
                marks={marks}
                min={0}
                max={48}
            />

            <div
                css={css({
                    color: textDark,
                    marginTop: "16px",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px"
                })}
            >
                {t("Dialog##selected time window", { fromDate, toDate })}
            </div>

            <Checkbox
                label={t("Dialog##inverted selection")}
                customStyle={{ marginTop: "16px" }}
                onChange={toggleInvertion}
            />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={t("Dialog##scheduled time warning")}
                css={css({
                    margin: "16px 0"
                })}
            />
        </Fragment>
    );
};

export default ScheduleTask;
