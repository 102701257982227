/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, ReactNode, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import CopyButton from "../../DesignComponents/CopyButton";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../DesignComponents/BannerMessage";
import { Query } from "../../../shared";

interface EditSectionProps {
    children: ReactNode;
    isLoading: boolean;
    isEditable: boolean;
    sectionName: string;
    value: Query | null | string;
    copiedValue: string | undefined;
    update: (e: SyntheticEvent) => Promise<void>;
    edit: () => void;
    cancel: () => void;
    hasBanner?: boolean;
}

const EditSection = ({
    children,
    isLoading,
    isEditable,
    sectionName,
    value,
    copiedValue,
    update,
    edit,
    cancel,
    hasBanner
}: EditSectionProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <span>{sectionName}:</span>

            <span>
                <form onSubmit={update}>
                    <div
                        css={css({
                            marginTop: "8px",
                            marginLeft: "8px"
                        })}
                    >
                        {children}
                    </div>

                    <div
                        css={css({
                            marginTop: "8px",
                            marginLeft: "8px",

                            "& > button + button": {
                                marginLeft: "8px"
                            }
                        })}
                    >
                        <Button
                            disableRipple
                            color={BUTTON_COLORS.Secondary}
                            size={BUTTON_SIZES.Tiny}
                            variant={BUTTON_VARIANTS.TextOnly}
                            isLoading={isLoading}
                            onClick={isEditable ? update : edit}
                        >
                            {isEditable ? t("Button##save") : t("Button##edit")}
                        </Button>

                        {isEditable && (
                            <Button
                                disableRipple
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Tiny}
                                variant={BUTTON_VARIANTS.TextOnly}
                                disabled={isLoading}
                                onClick={cancel}
                            >
                                {t("Button##cancel")}
                            </Button>
                        )}

                        {value && !isEditable && copiedValue && (
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={copiedValue}
                            />
                        )}
                    </div>
                    {isEditable && hasBanner && (
                        <BannerMessage
                            css={css({
                                marginTop: "8px",
                                marginLeft: "8px"
                            })}
                            status={BANNER_MESSAGE_STATUSES.Info}
                            title={t("Table##edit group banner")}
                        />
                    )}
                </form>
            </span>
        </Fragment>
    );
};

export default EditSection;
