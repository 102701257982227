import { useTranslation } from "react-i18next";

import { QueueIconProps } from "./types";

import Badge, {
    BADGE_COLORS,
    BadgeData
} from "../../../../DesignComponents/Badge";

import { TASK_QUEUE } from "../../../../../shared";

const QueueIcon = ({ status }: QueueIconProps) => {
    const { t } = useTranslation();

    const getBadgeData = (statusName: TASK_QUEUE): BadgeData => {
        switch (statusName) {
            case TASK_QUEUE.Executing:
                return { color: BADGE_COLORS.Info, isInverted: true };
            case TASK_QUEUE.Pending:
                return { color: BADGE_COLORS.Warning, isInverted: true };
            default:
                return { color: BADGE_COLORS.Neutral, isInverted: false };
        }
    };

    const color = getBadgeData(status).color;
    const isInverted = getBadgeData(status).isInverted;

    const name = t(`General##${status}`);

    return (
        <Badge color={color} inverted={isInverted}>
            {name}
        </Badge>
    );
};

export default QueueIcon;
