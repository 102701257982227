/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { useFilters } from "../hooks";
import FilterDropdown from "../FilterDropdown";
import FilterButton from "../components/FilterButton";
import FilterMenu from "../components/FilterMenu";

import {
    useMediaQueries,
    FILTER_NAMES,
    filterDropdownCustomMargin,
    deviceAndGroupfiltersBlockStyle
} from "../../../shared";

export const GroupFilters = () => {
    const { t } = useTranslation();
    const { fromLg, toLg } = useMediaQueries();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useFilters();

    return (
        <div css={css(deviceAndGroupfiltersBlockStyle)}>
            {fromLg && (
                <FilterDropdown
                    filter={FILTER_NAMES.ConfigurationFileId}
                    placeholder={t(
                        `Filter##${FILTER_NAMES.ConfigurationFileId}`
                    )}
                    css={css([filterDropdownCustomMargin])}
                />
            )}

            <FilterButton onOpen={handleOpenMenu}>
                {fromLg ? t("Button##more filters") : t("Button##filters")}
            </FilterButton>

            <FilterMenu
                isOpen={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
            >
                <div
                    css={css({
                        "& > div + div": {
                            marginTop: "12px"
                        }
                    })}
                >
                    {toLg && (
                        <FilterDropdown
                            filter={FILTER_NAMES.ConfigurationFileId}
                            placeholder={t(
                                `Filter##${FILTER_NAMES.ConfigurationFileId}`
                            )}
                        />
                    )}

                    <FilterDropdown
                        filter={FILTER_NAMES.FirmwareFileId}
                        placeholder={t(
                            `Filter##${FILTER_NAMES.FirmwareFileId}`
                        )}
                    />
                </div>
            </FilterMenu>
        </div>
    );
};
