import { useContext } from "react";

import ThemeContext from "../../../../../../context/theme/themeContext";
import { RectangleLoader } from "../../../../../../shared";

const RectangularSkeleton = () => {
    const {
        colorsFacelift: { blue100 }
    } = useContext(ThemeContext);

    return (
        <div>
            <RectangleLoader
                width={152}
                height={32}
                backgroundColor={blue100}
            />
        </div>
    );
};

export default RectangularSkeleton;
