/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { NOTIFICATION_STATUS, StatusBadgeProps } from "./types";

import ThemeContext from "../../../../../../theme/themeContext";

const StatusBadge = ({ status }: StatusBadgeProps) => {
    const {
        colorsFacelift: { blue600, green600, orange500, red700 }
    } = useContext(ThemeContext);

    const setBadgeColor = () => {
        switch (status) {
            case NOTIFICATION_STATUS.Succeeded:
                return green600;
            case NOTIFICATION_STATUS.Info:
            case NOTIFICATION_STATUS.Changed:
            case NOTIFICATION_STATUS.Reminder:
                return blue600;
            case NOTIFICATION_STATUS.Warning:
                return orange500;
            case NOTIFICATION_STATUS.Failed:
                return red700;
        }
    };

    const badgeColor = setBadgeColor();

    return (
        <span
            css={css({
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                margin: "6px 0",
                backgroundColor: badgeColor
            })}
        />
    );
};

export default StatusBadge;
