import { useContext } from "react";
import { BANNER_MESSAGE_STATUSES } from "..";

import ThemeContext from "../../../../context/theme/themeContext";
import InfoIcon from "../../../../assets/customIcons/status/InfoIcon";
import CriticalIcon from "../../../../assets/customIcons/status/CriticalIcon";
import WarningIcon from "../../../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../../../assets/customIcons/status/SuccessIcon";

export const useStyleOptions = () => {
    const {
        colorsFacelift: {
            blue100,
            blue500,
            green50,
            green300,
            orange50,
            orange300,
            red50,
            red300
        }
    } = useContext(ThemeContext);

    const styleVariants = {
        [BANNER_MESSAGE_STATUSES.Info]: {
            background: blue100,
            border: blue500,
            icon: InfoIcon
        },
        [BANNER_MESSAGE_STATUSES.Critical]: {
            background: red50,
            border: red300,
            icon: CriticalIcon
        },
        [BANNER_MESSAGE_STATUSES.Warning]: {
            background: orange50,
            border: orange300,
            icon: WarningIcon
        },
        [BANNER_MESSAGE_STATUSES.Success]: {
            background: green50,
            border: green300,
            icon: SuccessIcon
        }
    };

    return styleVariants;
};
