import { COLORS } from "../types";

export const ALL_COLORS = {
    beigeActive: COLORS.BeigeActive,
    beigeDefault: COLORS.BeigeDefault,
    beigeHover: COLORS.BeigeHover,
    black: COLORS.Black,
    blue100: COLORS.Blue100,
    blue200: COLORS.Blue200,
    blue300: COLORS.Blue300,
    blue400: COLORS.Blue400,
    blue500: COLORS.Blue500,
    blue600: COLORS.Blue600,
    blue700: COLORS.Blue700,
    blue800: COLORS.Blue800,
    blue900: COLORS.Blue900,
    blueSideNavigation: COLORS.BlueSideNavigation,
    cyan300: COLORS.Cyan300,
    cyan600: COLORS.Cyan600,
    gray50: COLORS.Gray50,
    gray100: COLORS.Gray100,
    gray200: COLORS.Gray200,
    gray300: COLORS.Gray300,
    gray400: COLORS.Gray400,
    gray500: COLORS.Gray500,
    gray600: COLORS.Gray600,
    gray700: COLORS.Gray700,
    gray800: COLORS.Gray800,
    green50: COLORS.Green50,
    green100: COLORS.Green100,
    green200: COLORS.Green200,
    green300: COLORS.Green300,
    green400: COLORS.Green400,
    green500: COLORS.Green500,
    green600: COLORS.Green600,
    green700: COLORS.Green700,
    green800: COLORS.Green800,
    green900: COLORS.Green900,
    grey80: COLORS.Grey80,
    inkNormal: COLORS.InkNormal,
    orange50: COLORS.Orange50,
    orange100: COLORS.Orange100,
    orange200: COLORS.Orange200,
    orange300: COLORS.Orange300,
    orange400: COLORS.Orange400,
    orange500: COLORS.Orange500,
    orange600: COLORS.Orange600,
    orange700: COLORS.Orange700,
    purple50: COLORS.Purple50,
    purple100: COLORS.Purple100,
    purple300: COLORS.Purple300,
    purple500: COLORS.Purple500,
    purple700: COLORS.Purple700,
    purple900: COLORS.Purple900,
    red50: COLORS.Red50,
    red100: COLORS.Red100,
    red200: COLORS.Red200,
    red300: COLORS.Red300,
    red600: COLORS.Red600,
    red700: COLORS.Red700,
    red800: COLORS.Red800,
    red900: COLORS.Red900,
    textDark: COLORS.TextDark,
    textDarkDisabled: COLORS.TextDarkDisabled,
    textIconDark: COLORS.TextIconDark,
    textPlaceholder: COLORS.TextPlaceholder,
    textSuccess: COLORS.TextSuccess,
    textWarning: COLORS.TextWarning,
    textWhite: COLORS.TextWhite,
    white: COLORS.White
};
