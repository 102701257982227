import { useEffect, useState } from "react";
import { ROUTES } from "../../../shared";

const useTabFilters = (pathname: string) => {
    const initialTabIndex = pathname === ROUTES.Users ? 0 : 1;

    const [tabIndex, setTabIndex] = useState(initialTabIndex);

    useEffect(() => {
        setTabIndex(initialTabIndex);
        // eslint-disable-next-line
    }, [pathname]);

    return { tabIndex };
};

export default useTabFilters;
