/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
    cloneElement,
    Fragment,
    MutableRefObject,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";

import Checkbox from "../../DesignComponents/Checkbox";
import ThemeContext from "../../../context/theme/themeContext";

interface ListChildProps {
    data: any;
    index: number;
    style: {};
}

const RenderRow = (
    rowHeights: MutableRefObject<any>,
    listRef: MutableRefObject<any>,
    isRootCompany: boolean,
    { data, index, style }: ListChildProps
) => {
    const {
        colorsFacelift: { gray100, gray700, white }
    } = useContext(ThemeContext);

    const [isLoading, setLoading] = useState(
        Object.keys(rowHeights.current).length === 0
    );

    const rowRef = useRef<any>({});

    useEffect(() => {
        if (rowRef.current) {
            const setRowHeight = (i: number, size: number) => {
                listRef.current.resetAfterIndex(0);

                rowHeights.current = {
                    ...rowHeights.current,
                    [i]: size
                };

                setLoading(false);
            };

            setRowHeight(index, rowRef.current.clientHeight);
        }

        // eslint-disable-next-line
    }, [rowRef]);

    return (
        <li
            css={css({
                ...style,
                opacity: isLoading ? "0" : "1",

                "& > div:hover": {
                    background: `${gray100} !important`
                }
            })}
        >
            {cloneElement(
                data[index],
                {
                    ref: rowRef,
                    style: {
                        padding: "12px 16px",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        columnGap: "8px",
                        background: white
                    }
                },
                <Fragment>
                    {isRootCompany ? (
                        <div>
                            <span css={css({ wordBreak: "break-word" })}>
                                {data[index].props.children[0]}
                            </span>

                            <span
                                css={css({
                                    color: gray700,
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                    letterSpacing: "0.4px"
                                })}
                            >
                                {`ID: ${data[index].props.children[1].props.children}`}
                            </span>
                        </div>
                    ) : (
                        <>
                            <span css={css({ wordBreak: "break-word" })}>
                                {data[index].props.children}
                            </span>

                            <Checkbox
                                checked={data[index].props["aria-selected"]}
                            />
                        </>
                    )}
                </Fragment>
            )}
        </li>
    );
};

export default RenderRow;
