import { DEVICE_STATISTICS } from "../../../../shared";

export const deviceStatsValues = {
    online: "Online",
    offline: "Offline",
    inactive: "Inactive",
    unknown: "Unknown",
    other: "Other"
};

export const translateStatName = (
    name: string,
    offline: string | any,
    online: string | any,
    inactive: string | any,
    unknown: string | any,
    other: string | any
) => {
    switch (name) {
        case deviceStatsValues.offline:
            return offline;
        case deviceStatsValues.online:
            return online;
        case deviceStatsValues.inactive:
            return inactive;
        case deviceStatsValues.unknown:
            return unknown;
        case deviceStatsValues.other:
            return other;
        default:
            return name;
    }
};

export const translateStatTitle = (
    query: string,
    activity: string,
    model: string,
    firmware: string,
    configuration: string
) => {
    switch (query) {
        case DEVICE_STATISTICS.ActivityStatus:
            return activity;
        case DEVICE_STATISTICS.Model:
            return model;
        case DEVICE_STATISTICS.CurrentFw:
            return firmware;
        case DEVICE_STATISTICS.CurrentCfg:
            return configuration;
        default:
            return query;
    }
};
