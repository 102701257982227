import { useTranslation } from "react-i18next";
import { FOUND_ITEM_NAMES, FoundResourceInfoRowProps } from "../types";
import { ROUTES, useUserRoles } from "../../../../shared";

const FoundResourceInfoRow = ({
    resourceData,
    resourceRoute
}: FoundResourceInfoRowProps) => {
    const { t } = useTranslation();
    const { getUserRoleInfo } = useUserRoles();

    const { model, role } = resourceData;

    const itemName =
        resourceRoute === ROUTES.Devices
            ? FOUND_ITEM_NAMES.DeviceModel
            : FOUND_ITEM_NAMES.Role;

    const getResourceValue = () => {
        if (resourceRoute === ROUTES.Devices) {
            return model;
        }

        return getUserRoleInfo(role).name;
    };

    return (
        <div>
            <span>{t(`Table##${itemName}`)}:</span>

            <span>{getResourceValue() ?? "—"}</span>
        </div>
    );
};

export default FoundResourceInfoRow;
