import { useContext } from "react";

import { ActionToolbarButtonWrapperProps } from "./types";
import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

const ActionToolbarButtonWrapper = ({
    children
}: ActionToolbarButtonWrapperProps) => {
    const { isInitialLoading } = useContext(TableContext);

    const { toSm } = useMediaQueries();

    const renderContent = () => {
        if (!toSm && isInitialLoading) {
            return;
        } else {
            return children;
        }
    };

    return <>{renderContent()}</>;
};

export default ActionToolbarButtonWrapper;
