const useDataValidation = () => {
    const validImei = (imei: string) => {
        let error = true;
        const imeiArray = imei.split("");

        if (imeiArray.length === 15) {
            const lastDigit = Number(imeiArray.pop());
            const doubled = imeiArray.map((item: string, index: number) =>
                index % 2 === 0 ? Number(item) : Number(item) * 2
            );
            const singleDigits = doubled.join("").split("");
            const sum = singleDigits.reduce(
                (total, current) => total + Number(current),
                0
            );
            const rounded = Math.ceil(sum / 10) * 10;
            error = rounded - sum === lastDigit;
        } else {
            error = false;
        }

        return error;
    };

    const validSerial = (serial: string) => serial.length === 10;

    return { validImei, validSerial };
};

export { useDataValidation };
