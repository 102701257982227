import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const NoResultsFoundIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { blue100, blue200, blue400, blue500, white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon viewBox="0 0 120 120" {...props}>
            <g>
                <path
                    opacity="0.6"
                    d="M58.7322 69.564C59.279 69.564 59.7222 69.1207 59.7222 68.574C59.7222 68.0272 59.279 67.584 58.7322 67.584C58.1854 67.584 57.7422 68.0272 57.7422 68.574C57.7422 69.1207 58.1854 69.564 58.7322 69.564Z"
                    fill={blue500}
                />
                <path
                    d="M59.1933 69.4503C58.9293 69.5223 58.6413 69.4863 58.3953 69.3243C57.9333 69.0243 57.8073 68.4123 58.1073 67.9503C58.2033 67.8063 58.3293 67.6923 58.4733 67.6143C58.2453 67.6743 58.0413 67.8183 57.9033 68.0283C57.6033 68.4903 57.7353 69.1023 58.1913 69.4023C58.5033 69.6063 58.8873 69.6123 59.1993 69.4443L59.1933 69.4503Z"
                    fill={blue500}
                />
                <path
                    d="M76.0683 99.0839C97.4682 99.0839 114.816 81.7358 114.816 60.3359C114.816 38.936 97.4682 21.5879 76.0683 21.5879C54.6684 21.5879 37.3203 38.936 37.3203 60.3359C37.3203 81.7358 54.6684 99.0839 76.0683 99.0839Z"
                    fill="url(#paint0_linear_8097_358216)"
                />
                <path
                    opacity="0.3"
                    d="M100.002 29.8623C105.186 36.4503 108.276 44.7603 108.276 53.7963C108.276 75.1983 90.9297 92.5443 69.5277 92.5443C60.4977 92.5443 52.1877 89.4543 45.5938 84.2703C52.6857 93.2943 63.7017 99.0903 76.0737 99.0903C97.4757 99.0903 114.822 81.7443 114.822 60.3423C114.822 47.9763 109.026 36.9603 100.002 29.8623Z"
                    fill={blue500}
                />
                <path
                    style={{ mixBlendMode: "multiply" }}
                    opacity="0.3"
                    d="M63.7058 53.7956C63.7058 44.0996 57.7658 35.7896 49.3238 32.3096C46.9358 34.5896 44.8358 37.1636 43.0898 39.9896C45.7718 40.4936 48.3338 41.7836 50.4038 43.8536C55.8938 49.3436 55.8938 58.2476 50.4038 63.7376C47.0318 67.1096 42.3758 68.4056 38.0078 67.6376C38.6378 70.9316 39.6758 74.0816 41.0798 77.0216C53.6258 76.6916 63.6998 66.4256 63.6998 53.7956H63.7058Z"
                    fill="url(#paint1_linear_8097_358216)"
                />
                <path
                    opacity="0.4"
                    d="M85.7324 37.0606C88.3786 34.5441 88.4838 30.359 85.9674 27.7127C83.4509 25.0665 79.2658 24.9613 76.6195 27.4778C73.9733 29.9942 73.8681 34.1794 76.3845 36.8256C78.901 39.4718 83.0862 39.577 85.7324 37.0606Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M84.2882 38.0996C82.5362 38.5796 80.5922 38.3396 78.9482 37.2716C75.8822 35.2856 75.0062 31.1936 76.9922 28.1276C77.6222 27.1496 78.4682 26.3996 79.4282 25.8896C77.9162 26.3036 76.5422 27.2516 75.6182 28.6736C73.6322 31.7396 74.5082 35.8376 77.5742 37.8176C79.6622 39.1736 82.2302 39.1916 84.2822 38.0996H84.2882Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M100.708 48.0181C102.537 45.6499 102.1 42.2474 99.7318 40.4184C97.3636 38.5893 93.9611 39.0264 92.1321 41.3946C90.303 43.7628 90.7401 47.1653 93.1083 48.9944C95.4765 50.8234 98.879 50.3863 100.708 48.0181Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M99.665 49.0436C98.309 49.6496 96.707 49.6976 95.243 49.0436C92.513 47.8196 91.295 44.6156 92.519 41.8856C92.909 41.0156 93.497 40.3016 94.211 39.7676C93.035 40.2896 92.045 41.2316 91.475 42.4976C90.251 45.2276 91.475 48.4316 94.199 49.6556C96.059 50.4896 98.141 50.1896 99.665 49.0496V49.0436Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M89.029 49.0364C89.8445 46.8862 88.7625 44.4819 86.6123 43.6664C84.462 42.8509 82.0578 43.9329 81.2423 46.0832C80.4267 48.2334 81.5088 50.6377 83.659 51.4532C85.8093 52.2687 88.2135 51.1867 89.029 49.0364Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M88.4859 50.0103C87.6219 50.7603 86.4579 51.1503 85.2339 50.9943C82.9539 50.7063 81.3399 48.6183 81.6339 46.3383C81.7239 45.6123 81.9999 44.9523 82.4079 44.4062C81.6639 45.0543 81.1419 45.9663 81.0099 47.0223C80.7219 49.3022 82.3299 51.3843 84.6099 51.6783C86.1639 51.8763 87.6279 51.1863 88.4919 50.0103H88.4859Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M91.8681 57.2283C93.2234 57.2283 94.3221 56.1296 94.3221 54.7743C94.3221 53.419 93.2234 52.3203 91.8681 52.3203C90.5128 52.3203 89.4141 53.419 89.4141 54.7743C89.4141 56.1296 90.5128 57.2283 91.8681 57.2283Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M93.8427 56.2199C93.3327 56.6639 92.6487 56.8919 91.9227 56.8019C90.5787 56.6279 89.6307 55.4039 89.7987 54.0599C89.8527 53.6339 90.0147 53.2439 90.2547 52.9199C89.8167 53.3039 89.5107 53.8379 89.4267 54.4619C89.2527 55.8059 90.2067 57.0359 91.5507 57.2039C92.4687 57.3179 93.3267 56.9159 93.8367 56.2199H93.8427Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M92.4114 36.3903C93.3161 36.3903 94.0494 35.6569 94.0494 34.7523C94.0494 33.8476 93.3161 33.1143 92.4114 33.1143C91.5068 33.1143 90.7734 33.8476 90.7734 34.7523C90.7734 35.6569 91.5068 36.3903 92.4114 36.3903Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M93.7345 35.7235C93.3925 36.0175 92.9365 36.1735 92.4565 36.1135C91.5625 35.9995 90.9265 35.1775 91.0405 34.2835C91.0765 33.9955 91.1845 33.7375 91.3465 33.5215C91.0525 33.7735 90.8485 34.1335 90.7945 34.5475C90.6805 35.4415 91.3165 36.2635 92.2105 36.3775C92.8225 36.4555 93.3985 36.1855 93.7345 35.7235Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M73.5936 85.2779C75.1079 85.2779 76.3356 84.0503 76.3356 82.5359C76.3356 81.0216 75.1079 79.7939 73.5936 79.7939C72.0792 79.7939 70.8516 81.0216 70.8516 82.5359C70.8516 84.0503 72.0792 85.2779 73.5936 85.2779Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M74.8197 84.9842C74.0877 85.1642 73.2837 85.0442 72.6177 84.5822C71.3697 83.7242 71.0517 82.0202 71.9097 80.7662C72.1797 80.3702 72.5397 80.0642 72.9417 79.8662C72.3117 80.0222 71.7297 80.4002 71.3337 80.9762C70.4757 82.2242 70.7937 83.9342 72.0417 84.7922C72.8937 85.3742 73.9557 85.4162 74.8197 84.9842Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M79.9119 43.5962C80.4586 43.5962 80.9019 43.153 80.9019 42.6062C80.9019 42.0594 80.4586 41.6162 79.9119 41.6162C79.3651 41.6162 78.9219 42.0594 78.9219 42.6062C78.9219 43.153 79.3651 43.5962 79.9119 43.5962Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M80.7203 43.1935C80.5163 43.3735 80.2343 43.4635 79.9463 43.4275C79.4003 43.3555 79.0163 42.8635 79.0883 42.3175C79.1123 42.1435 79.1783 41.9875 79.2743 41.8555C79.0943 42.0115 78.9743 42.2275 78.9383 42.4795C78.8663 43.0255 79.2563 43.5175 79.7963 43.5895C80.1683 43.6375 80.5163 43.4755 80.7203 43.1935Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M86.115 58.2417C86.6618 58.2417 87.105 57.7985 87.105 57.2517C87.105 56.705 86.6618 56.2617 86.115 56.2617C85.5682 56.2617 85.125 56.705 85.125 57.2517C85.125 57.7985 85.5682 58.2417 86.115 58.2417Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M86.9078 57.8341C86.7038 58.0141 86.4218 58.1041 86.1338 58.0681C85.5878 57.9961 85.2038 57.5041 85.2758 56.9581C85.2998 56.7841 85.3658 56.6281 85.4618 56.4961C85.2818 56.6521 85.1618 56.8681 85.1258 57.1201C85.0538 57.6661 85.4438 58.1581 85.9838 58.2301C86.3558 58.2781 86.7038 58.1161 86.9078 57.8341Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M75.0835 89.6943C75.7164 89.6943 76.2295 89.1813 76.2295 88.5483C76.2295 87.9154 75.7164 87.4023 75.0835 87.4023C74.4506 87.4023 73.9375 87.9154 73.9375 88.5483C73.9375 89.1813 74.4506 89.6943 75.0835 89.6943Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M74.5874 89.5744C74.3414 89.3764 74.1794 89.0824 74.1554 88.7464C74.1194 88.1164 74.5994 87.5764 75.2354 87.5404C75.4334 87.5284 75.6254 87.5704 75.7994 87.6544C75.5894 87.4864 75.3134 87.3904 75.0194 87.4084C74.3894 87.4444 73.9034 87.9844 73.9394 88.6144C73.9634 89.0464 74.2214 89.4064 74.5874 89.5804V89.5744Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M80.5544 88.5482C81.6976 88.5482 82.6244 87.6214 82.6244 86.4782C82.6244 85.335 81.6976 84.4082 80.5544 84.4082C79.4111 84.4082 78.4844 85.335 78.4844 86.4782C78.4844 87.6214 79.4111 88.5482 80.5544 88.5482Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M82.1265 87.8214C81.6705 88.1634 81.0765 88.3134 80.4765 88.1874C79.3545 87.9594 78.6345 86.8674 78.8625 85.7454C78.9345 85.3914 79.0965 85.0734 79.3185 84.8154C78.9225 85.1094 78.6345 85.5414 78.5265 86.0634C78.2985 87.1854 79.0185 88.2774 80.1405 88.5054C80.9025 88.6614 81.6585 88.3734 82.1265 87.8214Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M52.155 65.1799C54.8325 63.8439 55.9198 60.5903 54.5838 57.9128C53.2477 55.2354 49.9941 54.148 47.3167 55.4841C44.6392 56.8202 43.5519 60.0738 44.8879 62.7512C46.224 65.4287 49.4776 66.516 52.155 65.1799Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M50.7713 65.6519C49.2833 65.6039 47.8193 64.9499 46.7873 63.7199C44.8613 61.4279 45.1613 58.0139 47.4533 56.0879C48.1853 55.4759 49.0253 55.0919 49.9013 54.9179C48.6173 54.8759 47.3153 55.2899 46.2533 56.1839C43.9613 58.1099 43.6673 61.5239 45.5873 63.8159C46.9013 65.3759 48.9053 66.0119 50.7713 65.6459V65.6519Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M43.5712 72.2342C44.9265 72.2342 46.0252 71.1355 46.0252 69.7802C46.0252 68.4249 44.9265 67.3262 43.5712 67.3262C42.2159 67.3262 41.1172 68.4249 41.1172 69.7802C41.1172 71.1355 42.2159 72.2342 43.5712 72.2342Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M44.7168 71.9462C44.0688 72.1202 43.3488 72.0302 42.7368 71.6342C41.6028 70.8962 41.2788 69.3782 42.0168 68.2382C42.2508 67.8782 42.5688 67.5962 42.9228 67.4102C42.3588 67.5602 41.8488 67.9142 41.5068 68.4422C40.7688 69.5762 41.0868 71.0942 42.2268 71.8382C43.0008 72.3422 43.9548 72.3542 44.7168 71.9462Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M41.1697 65.9702C41.7165 65.9702 42.1597 65.527 42.1597 64.9802C42.1597 64.4335 41.7165 63.9902 41.1697 63.9902C40.6229 63.9902 40.1797 64.4335 40.1797 64.9802C40.1797 65.527 40.6229 65.9702 41.1697 65.9702Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M41.6308 65.8555C41.3668 65.9275 41.0788 65.8915 40.8328 65.7295C40.3708 65.4295 40.2448 64.8175 40.5448 64.3555C40.6408 64.2115 40.7668 64.0975 40.9108 64.0195C40.6828 64.0795 40.4788 64.2235 40.3408 64.4335C40.0408 64.8955 40.1728 65.5075 40.6288 65.8075C40.9408 66.0115 41.3248 66.0175 41.6368 65.8495L41.6308 65.8555Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M55.7314 76.895C57.4174 75.78 57.8802 73.5092 56.7651 71.8233C55.65 70.1373 53.3793 69.6745 51.6934 70.7896C50.0074 71.9046 49.5446 74.1753 50.6596 75.8613C51.7747 77.5473 54.0454 78.0101 55.7314 76.895Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M54.8219 77.3401C53.8199 77.4241 52.7879 77.0941 52.0019 76.3501C50.5319 74.9581 50.4719 72.6421 51.8639 71.1781C52.3079 70.7101 52.8419 70.3861 53.4179 70.2061C52.5539 70.2781 51.7079 70.6561 51.0659 71.3341C49.6739 72.8041 49.7399 75.1201 51.2039 76.5061C52.2059 77.4541 53.5979 77.7241 54.8279 77.3401H54.8219Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M90.0866 83.8267C92.7768 82.0736 93.5365 78.4717 91.7835 75.7815C90.0304 73.0913 86.4284 72.3315 83.7382 74.0846C81.048 75.8377 80.2883 79.4396 82.0414 82.1298C83.7944 84.82 87.3964 85.5798 90.0866 83.8267Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M88.6729 84.5038C87.0829 84.6358 85.4449 84.1198 84.1909 82.9378C81.8569 80.7298 81.7549 77.0518 83.9629 74.7238C84.6649 73.9798 85.5169 73.4638 86.4229 73.1758C85.0489 73.2898 83.7109 73.8898 82.6909 74.9698C80.4829 77.3038 80.5909 80.9818 82.9189 83.1838C84.5089 84.6898 86.7229 85.1158 88.6729 84.4978V84.5038Z"
                    fill={blue500}
                />
                <path
                    d="M51.4321 64.8779C57.4441 58.8659 57.4441 49.1099 51.4321 43.0979C48.4441 40.1099 44.5381 38.6099 40.6201 38.5859C37.0081 44.4359 34.9141 51.3239 34.9141 58.7039C34.9141 62.1899 35.3881 65.5679 36.2641 68.7779C41.4781 70.2779 47.3221 68.9759 51.4321 64.8719V64.8779Z"
                    fill="url(#paint2_linear_8097_358216)"
                />
                <path
                    opacity="0.4"
                    d="M49.6025 63.4949C52.2502 62.1736 53.3256 58.956 52.0043 56.3082C50.683 53.6605 47.4654 52.5851 44.8177 53.9064C42.1699 55.2277 41.0946 58.4453 42.4158 61.0931C43.7371 63.7408 46.9547 64.8162 49.6025 63.4949Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M48.2339 63.9655C46.7639 63.9175 45.3179 63.2695 44.2919 62.0575C42.3899 59.7895 42.6839 56.4115 44.9519 54.5095C45.6719 53.9035 46.5059 53.5195 47.3699 53.3515C46.0979 53.3095 44.8079 53.7235 43.7579 54.6055C41.4899 56.5075 41.1959 59.8915 43.0979 62.1535C44.3939 63.7015 46.3799 64.3255 48.2279 63.9655H48.2339Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M46.0296 50.1901C47.8323 50.1901 49.2936 48.7288 49.2936 46.9261C49.2936 45.1235 47.8323 43.6621 46.0296 43.6621C44.227 43.6621 42.7656 45.1235 42.7656 46.9261C42.7656 48.7288 44.227 50.1901 46.0296 50.1901Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M47.5691 49.8124C46.7051 50.0464 45.7451 49.9264 44.9351 49.3984C43.4231 48.4144 42.9971 46.3924 43.9811 44.8804C44.2931 44.4004 44.7131 44.0284 45.1871 43.7764C44.4371 43.9804 43.7591 44.4484 43.3031 45.1444C42.3191 46.6564 42.7451 48.6784 44.2571 49.6624C45.2891 50.3344 46.5551 50.3464 47.5691 49.8124Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M42.442 66C41.83 65.604 41.11 65.526 40.462 65.706C40.12 65.892 39.82 66.162 39.592 66.51C39.004 67.41 39.106 68.55 39.742 69.342C40.924 69.402 42.106 69.342 43.276 69.132C43.834 68.04 43.504 66.684 42.454 65.994L42.442 66Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M39.5841 66.5159C39.8121 66.1679 40.1121 65.8979 40.4541 65.7119C40.4061 65.7239 40.3581 65.7419 40.3101 65.7599C40.2501 65.7839 40.1841 65.8019 40.1241 65.8259C40.0461 65.8619 39.9681 65.9039 39.8961 65.9519C39.8421 65.9819 39.7821 66.0119 39.7341 66.0479C39.6501 66.1019 39.5781 66.1739 39.5061 66.2399C39.4641 66.2759 39.4221 66.3059 39.3861 66.3419C39.2781 66.4559 39.1761 66.5759 39.0861 66.7139C38.5581 67.5239 38.5821 68.5259 39.0561 69.2999C39.2841 69.3239 39.5121 69.3359 39.7401 69.3479C39.1041 68.5499 39.0081 67.4159 39.5901 66.5159H39.5841Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M40.4559 53.8259C41.3506 53.8259 42.0759 53.1006 42.0759 52.2059C42.0759 51.3112 41.3506 50.5859 40.4559 50.5859C39.5612 50.5859 38.8359 51.3112 38.8359 52.2059C38.8359 53.1006 39.5612 53.8259 40.4559 53.8259Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M41.2233 53.6335C40.7913 53.7475 40.3173 53.6875 39.9153 53.4295C39.1653 52.9435 38.9553 51.9415 39.4413 51.1915C39.5973 50.9515 39.8013 50.7715 40.0413 50.6455C39.6693 50.7475 39.3333 50.9755 39.1053 51.3235C38.6193 52.0735 38.8293 53.0755 39.5793 53.5615C40.0893 53.8915 40.7193 53.9035 41.2233 53.6335Z"
                    fill={blue500}
                />
                <path
                    opacity="0.4"
                    d="M38.7262 64.2776C39.2696 64.2776 39.7102 63.837 39.7102 63.2936C39.7102 62.7501 39.2696 62.3096 38.7262 62.3096C38.1827 62.3096 37.7422 62.7501 37.7422 63.2936C37.7422 63.837 38.1827 64.2776 38.7262 64.2776Z"
                    fill={blue500}
                />
                <path
                    opacity="0.6"
                    d="M39.1846 64.1636C38.9266 64.2356 38.6386 64.1996 38.3926 64.0376C37.9366 63.7436 37.8106 63.1316 38.1046 62.6816C38.2006 62.5376 38.3266 62.4236 38.4646 62.3516C38.2426 62.4116 38.0326 62.5556 37.9006 62.7656C37.6066 63.2216 37.7326 63.8276 38.1886 64.1216C38.5006 64.3256 38.8786 64.3256 39.1846 64.1636Z"
                    fill={blue500}
                />
                <path
                    opacity="0.5"
                    d="M40.5426 69.3899C49.0489 69.3899 55.9446 62.4942 55.9446 53.9879C55.9446 45.4816 49.0489 38.5859 40.5426 38.5859C32.0363 38.5859 25.1406 45.4816 25.1406 53.9879C25.1406 62.4942 32.0363 69.3899 40.5426 69.3899Z"
                    fill={blue100}
                />
                <path
                    d="M30.7447 63.7803L8.30469 86.2263"
                    stroke={blue500}
                    strokeWidth="3"
                    strokeMiterlimit="10"
                />
                <path
                    d="M12.3026 88.0401L26.9991 73.3436C27.6037 72.7391 27.6037 71.759 26.9991 71.1544L23.3802 67.5354C22.7756 66.9309 21.7955 66.9309 21.191 67.5354L6.49447 82.232C5.88994 82.8365 5.88994 83.8166 6.49447 84.4212L10.1134 88.0401C10.718 88.6447 11.6981 88.6447 12.3026 88.0401Z"
                    fill="url(#paint3_linear_8097_358216)"
                />
                <path
                    d="M40.5396 41.5861C43.8516 41.5861 46.9656 42.8761 49.3116 45.2161C54.1476 50.0521 54.1476 57.9181 49.3116 62.7541C46.9716 65.0941 43.8576 66.3841 40.5396 66.3841C37.2216 66.3841 34.1136 65.0941 31.7676 62.7541C29.4276 60.4141 28.1376 57.3001 28.1376 53.9821C28.1376 50.6641 29.4276 47.5561 31.7676 45.2101C34.1076 42.8641 37.2216 41.5801 40.5396 41.5801M40.5396 38.5801C36.5976 38.5801 32.6556 40.0861 29.6496 43.0921C23.6376 49.1041 23.6376 58.8601 29.6496 64.8721C32.6556 67.8781 36.5976 69.3841 40.5396 69.3841C44.4816 69.3841 48.4236 67.8781 51.4296 64.8721C57.4416 58.8601 57.4416 49.1041 51.4296 43.0921C48.4236 40.0861 44.4816 38.5801 40.5396 38.5801Z"
                    fill={blue500}
                />
                <path
                    d="M18.2966 38.7485C22.9391 38.7485 26.7026 34.985 26.7026 30.3425C26.7026 25.7 22.9391 21.9365 18.2966 21.9365C13.6541 21.9365 9.89062 25.7 9.89062 30.3425C9.89062 34.985 13.6541 38.7485 18.2966 38.7485Z"
                    fill="url(#paint4_linear_8097_358216)"
                />
                <path
                    d="M8.98832 40.2183C10.2423 41.4723 13.1823 40.7223 16.5543 38.5683C18.3243 37.4343 20.2143 35.9103 22.0383 34.0863C23.8683 32.2563 25.3863 30.3663 26.5263 28.5903C28.6803 25.2183 29.4243 22.2783 28.1763 21.0303C26.9283 19.7823 24.2463 20.4663 21.0603 22.3983C21.8163 22.6623 22.5483 23.0403 23.2263 23.5263C24.3063 23.1483 25.1463 23.1483 25.6023 23.6043C26.0823 24.0843 26.0523 25.0023 25.6023 26.1723C24.9063 28.0203 23.1843 30.4863 20.8143 32.8563C18.4443 35.2263 15.9783 36.9423 14.1303 37.6383C12.9603 38.0883 12.0363 38.1183 11.5563 37.6383C11.1003 37.1823 11.1063 36.3423 11.4783 35.2563C10.9923 34.5783 10.6143 33.8463 10.3503 33.0903C8.41232 36.2703 7.78832 39.0063 8.98232 40.2063L8.98832 40.2183Z"
                    fill={blue200}
                />
                <g opacity="0.7">
                    <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M25.5967 23.6047C25.1407 23.1487 24.3007 23.1487 23.2207 23.5267C22.5427 23.0407 21.8107 22.6627 21.0547 22.3987C24.2407 20.4667 26.9767 19.8367 28.1707 21.0307C28.2667 21.1267 28.3507 21.2407 28.4227 21.3607L25.8247 23.9587C25.7707 23.8207 25.6987 23.7007 25.5967 23.5987V23.6047Z"
                        fill="url(#paint5_linear_8097_358216)"
                    />
                    <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M8.99014 40.2185C7.79614 39.0245 8.42014 36.2885 10.3581 33.1025C10.6221 33.8585 11.0001 34.5905 11.4861 35.2685C11.1081 36.3485 11.1081 37.1945 11.5641 37.6505C11.6661 37.7525 11.7861 37.8245 11.9241 37.8785L9.32614 40.4765C9.20614 40.4045 9.09214 40.3205 8.99614 40.2245L8.99014 40.2185Z"
                        fill="url(#paint6_linear_8097_358216)"
                    />
                </g>
                <path
                    d="M40.1336 26.5268L40.6076 27.4928C40.6976 27.6788 40.8776 27.8108 41.0816 27.8348L42.1436 27.9908C42.6596 28.0688 42.8696 28.6988 42.4916 29.0648L41.7236 29.8148C41.5736 29.9588 41.5076 30.1688 41.5436 30.3728L41.7236 31.4348C41.8136 31.9508 41.2736 32.3408 40.8116 32.1008L39.8576 31.6028C39.6716 31.5068 39.4556 31.5068 39.2696 31.6028L38.3156 32.1008C37.8536 32.3468 37.3136 31.9508 37.4036 31.4348L37.5836 30.3728C37.6196 30.1688 37.5536 29.9588 37.4036 29.8148L36.6356 29.0648C36.2636 28.6988 36.4676 28.0628 36.9836 27.9908L38.0456 27.8348C38.2496 27.8048 38.4296 27.6788 38.5196 27.4928L38.9936 26.5268C39.2276 26.0588 39.8936 26.0588 40.1216 26.5268H40.1336Z"
                    fill={blue200}
                />
                <path
                    d="M31.1407 86.97L31.8067 88.32C31.9327 88.578 32.1847 88.764 32.4727 88.8L33.9607 89.016C34.6867 89.124 34.9747 90.012 34.4527 90.522L33.3727 91.572C33.1627 91.776 33.0668 92.07 33.1208 92.352L33.3727 93.834C33.4987 94.554 32.7367 95.106 32.0947 94.764L30.7628 94.062C30.5048 93.924 30.1987 93.924 29.9407 94.062L28.6088 94.764C27.9608 95.106 27.2047 94.554 27.3307 93.834L27.5827 92.352C27.6307 92.064 27.5347 91.776 27.3307 91.572L26.2507 90.522C25.7287 90.012 26.0167 89.124 26.7427 89.016L28.2307 88.8C28.5187 88.758 28.7648 88.578 28.8967 88.32L29.5628 86.97C29.8868 86.316 30.8228 86.316 31.1467 86.97H31.1407Z"
                    fill={blue200}
                />
                <path
                    d="M14.6967 51.8698L15.0747 52.6438C15.1467 52.7938 15.2907 52.8958 15.4527 52.9198L16.3047 53.0458C16.7187 53.1058 16.8807 53.6158 16.5867 53.9038L15.9687 54.5038C15.8487 54.6178 15.7947 54.7858 15.8247 54.9478L15.9687 55.7938C16.0407 56.2078 15.6087 56.5198 15.2367 56.3278L14.4747 55.9258C14.3307 55.8478 14.1507 55.8478 14.0067 55.9258L13.2447 56.3278C12.8727 56.5198 12.4407 56.2078 12.5127 55.7938L12.6567 54.9478C12.6867 54.7858 12.6327 54.6178 12.5127 54.5038L11.8947 53.9038C11.5947 53.6098 11.7627 53.1058 12.1767 53.0458L13.0287 52.9198C13.1907 52.8958 13.3347 52.7938 13.4067 52.6438L13.7847 51.8698C13.9707 51.4978 14.5047 51.4978 14.6907 51.8698H14.6967Z"
                    fill={blue200}
                />
            </g>

            <defs>
                <linearGradient
                    id="paint0_linear_8097_358216"
                    x1="37.3203"
                    y1="60.3359"
                    x2="114.816"
                    y2="60.3359"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue400} />
                    <stop offset="1" stopColor={blue100} />
                </linearGradient>

                <linearGradient
                    id="paint1_linear_8097_358216"
                    x1="36.7058"
                    y1="44.9096"
                    x2="59.4578"
                    y2="67.6616"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue500} stopOpacity="0" />
                    <stop offset="1" stopColor={blue500} />
                </linearGradient>

                <linearGradient
                    id="paint2_linear_8097_358216"
                    x1="34.9201"
                    y1="53.9819"
                    x2="111.6"
                    y2="53.9819"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue400} />
                    <stop offset="1" stopColor={blue100} />
                </linearGradient>

                <linearGradient
                    id="paint3_linear_8097_358216"
                    x1="25.1875"
                    y1="69.3428"
                    x2="8.30183"
                    y2="86.2285"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue200} />
                    <stop offset="1" stopColor={blue500} />
                </linearGradient>

                <linearGradient
                    id="paint4_linear_8097_358216"
                    x1="26.7046"
                    y1="30.348"
                    x2="9.88676"
                    y2="30.348"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue400} />
                    <stop offset="1" stopColor={blue200} />
                </linearGradient>

                <linearGradient
                    id="paint5_linear_8097_358216"
                    x1="28.0493"
                    y1="22.7793"
                    x2="21.4308"
                    y2="21.0059"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.2" stopColor={blue500} stopOpacity="0" />
                    <stop offset="1" stopColor={blue500} />
                </linearGradient>

                <linearGradient
                    id="paint6_linear_8097_358216"
                    x1="8.95889"
                    y1="33.4791"
                    x2="10.7323"
                    y2="40.0976"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={blue500} />
                    <stop offset="0.7" stopColor={blue500} stopOpacity="0" />
                </linearGradient>

                <clipPath id="clip0_8097_358216">
                    <rect width="120" height="120" fill={white} />
                </clipPath>

                <clipPath id="clip1_8097_358216">
                    <rect width="120" height="120" fill={white} />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default NoResultsFoundIcon;
