/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ContentFrameProps } from "../types";
import TableContext from "../../../../context/table/tableContext";
import { ActionBarTitleLoader, useFormattedNumber } from "../../../../shared";

const ContentFrame = ({ children }: ContentFrameProps) => {
    const { tableName, totalRows, isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    return (
        <>
            {isInitialLoading ? (
                <ActionBarTitleLoader
                    customStyle={{
                        "@media(max-width: 599px)": {
                            marginBottom: "8px"
                        }
                    }}
                />
            ) : (
                <h1
                    css={css({
                        "@media(max-width: 599px)": {
                            marginBottom: "8px"
                        }
                    })}
                >
                    <span>{t(`General##${tableName}`)}</span>
                    <span>({renderFormattedNumber(totalRows)})</span>
                </h1>
            )}

            <div
                css={css({
                    "& > button + button": {
                        marginLeft: "8px"
                    },

                    "@media(max-width: 599px)": {
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoColumns: "1fr",
                        gridColumnGap: "16px",

                        "& > button + button": {
                            marginLeft: "0"
                        }
                    }
                })}
            >
                {children}
            </div>
        </>
    );
};

export default ContentFrame;
