/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/ModeEdit";

import { STEPS, SecondButtonProps } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../DesignComponents/Tooltips";

const SecondButton = ({
    isLoading,
    isEditDisabled,
    isInitialStep,
    selectedConfiguration,
    handleStep,
    handleSubmitEdit
}: SecondButtonProps) => {
    const { t } = useTranslation();

    return isInitialStep ? (
        <TooltipGeneral
            placement={TOOLTIP_PLACEMENT.Top}
            title={isEditDisabled ? t("Tct##no editable configurations") : ""}
        >
            <span
                css={css({
                    display: "flex"
                })}
            >
                <Button
                    fullWidth
                    color={BUTTON_COLORS.PrimarySubtle}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.IconLeft}
                    icon={<EditIcon />}
                    disabled={isLoading || isEditDisabled}
                    onClick={() => handleStep(STEPS.EditConfiguration)}
                >
                    {t("Button##edit")}
                </Button>
            </span>
        </TooltipGeneral>
    ) : (
        <Button
            fullWidth
            color={BUTTON_COLORS.Primary}
            size={BUTTON_SIZES.Normal}
            variant={BUTTON_VARIANTS.TextOnly}
            type="submit"
            disabled={isLoading || !selectedConfiguration}
            isLoading={isLoading}
            onClick={handleSubmitEdit}
        >
            {t("Button##continue")}
        </Button>
    );
};

export default SecondButton;
