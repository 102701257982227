/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import TableHeadToolbar from "../../TableHeadToolbar";
import ActionsForSelection from "../../../Actions/TableHeadActions";
import ThemeContext from "../../../../context/theme/themeContext";

const TableHeadActive = () => {
    const {
        colorsFacelift: { blue200, blue100 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                position: "sticky",
                top: "0",
                left: "0",
                width: "100%",
                boxSizing: "border-box",
                marginTop: "-42px",
                zIndex: "4",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "8px",
                background: blue100,
                borderTop: `1px solid ${blue200}`,
                borderBottom: `1px solid ${blue200}`,
                borderRadius: "unset",
                padding: "4px 24px 4px 16px"
            })}
        >
            <TableHeadToolbar>
                <ActionsForSelection />
            </TableHeadToolbar>
        </div>
    );
};

export default TableHeadActive;
