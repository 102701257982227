import { useContext } from "react";

import useProductCodeInlineActions from "../Actions/InlineActions/hooks/useProductCodeInlineActions";
import EditProductCode from "../Actions/dialogs/ProductCodes/Inline/EditProductCode";
import MainContainer from "../MainContainer";
import Table from "../Table";

import AuthContext from "../../context/auth/authContext";
import {
    ID_TYPE,
    TABLE_NAMES,
    PRODUCT_CODE_COLUMNS as COLUMNS
} from "../../shared";

const ProductCodes = () => {
    const { isAdminManagerUser } = useContext(AuthContext);

    const { inlineActions, rowData, isOpen, closeDialog } =
        useProductCodeInlineActions();

    return (
        <>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.ProductCodes}
                    initialColumns={COLUMNS}
                    inlineActions={inlineActions}
                    idType={ID_TYPE.Id}
                    isNotSelectable={!isAdminManagerUser}
                    sortFromNewest
                    hideFilters
                    hasActionBar
                />
            </MainContainer>

            <EditProductCode
                data={rowData}
                isOpen={isOpen}
                close={closeDialog}
            />
        </>
    );
};

export default ProductCodes;
