/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { STEP_GROUP, TOOLTIP_ACTIONS, TutorialTooltipProps } from "./types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import ThemeContext from "../../context/theme/themeContext";
import TutorialContext from "../../context/tutorial/tutorialContext";
import AlertContext, { ALERT_STATUS } from "../../context/alert/alertContext";
import { ROUTES, useMediaQueries } from "../../shared";

export const TutorialTooltip = ({
    step,
    tooltipProps,
    isLastStep,
    size
}: TutorialTooltipProps) => {
    const { setAlert } = useContext(AlertContext);

    const {
        setDrawerStatus,
        setTutorialOpen,
        setStepIndex,
        setInitialRender,
        isInitialRender,
        stepGroup,
        stepIndex
    } = useContext(TutorialContext);

    const {
        colorsFacelift: { white, gray700 }
    } = useContext(ThemeContext);

    const navigate = useNavigate();

    const { toMd, fromMd } = useMediaQueries();

    const checkIfAllTutorialsAreDone = () => {
        const allTutorials = Object.values(STEP_GROUP).filter(currentStep => {
            return (
                currentStep !== STEP_GROUP.FinishTutorial &&
                currentStep !== STEP_GROUP.FirstTimeUser
            );
        });

        const areTutorialsFinished = allTutorials.every(tutorial => {
            return localStorage.getItem(tutorial) !== null;
        });

        setTutorialOpen(
            false,
            0,
            isInitialRender && areTutorialsFinished
                ? STEP_GROUP.FinishTutorial
                : STEP_GROUP.TutorialCompanySelector
        );

        if (isInitialRender) {
            localStorage.setItem("tutorialInitialRender", "false");
            setInitialRender(false);
        }

        return areTutorialsFinished;
    };

    const handleClick = () => {
        switch (true) {
            case stepGroup === STEP_GROUP.FirstTimeUser && stepIndex === 0:
                setTutorialOpen(true, 0, STEP_GROUP.TutorialCompanySelector);
                break;

            case stepGroup === STEP_GROUP.TutorialCompanySelector &&
                stepIndex === 0:
                setTutorialOpen(false, 1, undefined, true);
                localStorage.setItem(stepGroup, "true");
                break;

            case stepGroup === STEP_GROUP.TutorialCompanySelector &&
                stepIndex === 1:
                isInitialRender
                    ? setTutorialOpen(true, 0, STEP_GROUP.TutorialTaskGroups)
                    : setTutorialOpen(false, 0);
                break;

            case stepGroup === STEP_GROUP.TutorialTaskGroups && toMd:
                switch (stepIndex) {
                    case 0:
                        setTutorialOpen(false, 1, stepGroup, true);
                        document.getElementById("mobileDrawerBtn")?.click();
                        setDrawerStatus(true);
                        break;

                    case 1:
                        navigate(ROUTES.TaskGroups);
                        setDrawerStatus(false);
                        setStepIndex(stepIndex + 1);
                        localStorage.setItem(stepGroup, "true");
                        break;

                    case 2:
                        checkIfAllTutorialsAreDone();
                        break;

                    default:
                        setStepIndex(stepIndex + 1);
                        break;
                }

                break;

            case stepGroup === STEP_GROUP.TutorialTaskGroups && fromMd:
                if (stepIndex === 0) {
                    navigate(ROUTES.TaskGroups);
                    setStepIndex(stepIndex + 1);
                    localStorage.setItem(stepGroup, "true");
                } else {
                    checkIfAllTutorialsAreDone();
                }
                break;

            case stepGroup === STEP_GROUP.FinishTutorial && stepIndex === 0:
                setTutorialOpen(false, 0);
                setAlert({
                    status: ALERT_STATUS.Success,
                    title: "Tutorial successfully completed"
                });
                break;

            default:
                setStepIndex(stepIndex + 1);
                break;
        }
    };

    const getButtonText = () => {
        if (isInitialRender) {
            if (stepGroup === STEP_GROUP.FirstTimeUser) {
                return TOOLTIP_ACTIONS.GetStarted;
            }

            if (stepGroup === STEP_GROUP.FinishTutorial) {
                return TOOLTIP_ACTIONS.Finish;
            }

            if (isLastStep && stepGroup !== STEP_GROUP.TutorialTaskGroups) {
                return TOOLTIP_ACTIONS.NextFeature;
            }

            return TOOLTIP_ACTIONS.Next;
        } else {
            if (isLastStep) {
                return TOOLTIP_ACTIONS.Finish;
            }

            return TOOLTIP_ACTIONS.Next;
        }
    };

    return (
        <div
            css={css({
                padding: "16px",
                backgroundColor: gray700,
                maxWidth: "342px",
                width: "100%",
                borderRadius: "12px",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                color: white
            })}
            {...tooltipProps}
        >
            <div
                css={css({
                    marginBottom: "8px",
                    fontWeight: 600,
                    display: "flex",

                    "span:first-of-type": {
                        marginRight: "auto"
                    }
                })}
            >
                <span>{step.title}</span>
                {stepGroup !== STEP_GROUP.FinishTutorial && (
                    <span>
                        {stepIndex + 1}/{size}
                    </span>
                )}
            </div>

            <div
                css={css({
                    marginBottom: "16px",
                    fontWeight: 400,
                    whiteSpace: "pre-line"
                })}
            >
                {step.content}
            </div>

            <Button
                color={BUTTON_COLORS.Secondary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                fullWidth
                onClick={handleClick}
            >
                {getButtonText()}
            </Button>
        </div>
    );
};
