import FirstButton from "./FirstButton";
import SecondButton from "./SecondButton";

import { ActionsProps } from "../types";

const Actions = ({
    isLoading,
    isEditDisabled,
    isInitialStep,
    selectedConfiguration,
    setSelectedConfiguration,
    handleStep,
    handleSubmitCreate,
    handleSubmitEdit
}: ActionsProps) => {
    return (
        <>
            <FirstButton
                isInitialStep={isInitialStep}
                handleStep={handleStep}
                setSelectedConfiguration={setSelectedConfiguration}
                handleSubmitCreate={handleSubmitCreate}
            />

            <SecondButton
                isInitialStep={isInitialStep}
                isEditDisabled={isEditDisabled}
                isLoading={isLoading}
                selectedConfiguration={selectedConfiguration}
                handleStep={handleStep}
                handleSubmitEdit={handleSubmitEdit}
            />
        </>
    );
};

export default Actions;
