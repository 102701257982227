import {
    SET_COMPANY_FILTER_OPEN,
    SET_DRAWER_STATUS,
    SET_INITIAL_RENDER,
    SET_STEP_INDEX,
    SET_TUTORIAL_OPEN
} from "./tutorialActions";

import { STEP_GROUP } from "../../components/TutorialMode/types/enums";

export interface TutorialInitialState {
    isCompanyFilterOpen: boolean;
    isDrawerOpen: boolean;
    isInitialRender: boolean;
    isTutorialOpen: boolean;
    isTutorialActive: boolean;
    stepGroup: STEP_GROUP;
    stepIndex: number;
}

type Action =
    | { type: typeof SET_COMPANY_FILTER_OPEN; payload: boolean }
    | { type: typeof SET_DRAWER_STATUS; payload: boolean }
    | {
          type: typeof SET_TUTORIAL_OPEN;
          payload: {
              isOpen: boolean;
              index?: number;
              stepGroup?: STEP_GROUP;
              keepActive?: boolean;
          };
      }
    | { type: typeof SET_STEP_INDEX; payload: number }
    | { type: typeof SET_INITIAL_RENDER; payload: boolean };

export const TutorialReducer = (
    state: TutorialInitialState,
    action: Action
): TutorialInitialState => {
    switch (action.type) {
        case SET_COMPANY_FILTER_OPEN:
            return {
                ...state,
                isCompanyFilterOpen: action.payload
            };

        case SET_DRAWER_STATUS:
            return {
                ...state,
                isDrawerOpen: action.payload
            };

        case SET_STEP_INDEX:
            return {
                ...state,
                stepIndex: action.payload
            };

        case SET_INITIAL_RENDER:
            return {
                ...state,
                isInitialRender: action.payload
            };

        case SET_TUTORIAL_OPEN:
            const { isOpen, index, stepGroup, keepActive } = action.payload;

            return {
                ...state,
                isTutorialOpen: isOpen,
                isTutorialActive: keepActive ?? isOpen,
                stepIndex: index ?? state.stepIndex,
                stepGroup: stepGroup ?? state.stepGroup
            };

        default:
            return state;
    }
};
