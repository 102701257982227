import SelectedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import FilteredIcon from "@mui/icons-material/FilterAltOutlined";
import FromFileIcon from "@mui/icons-material/FileDownloadOutlined";

import { TRI_SOURCES } from "../../../../../../shared";

export const ALL_TABS = [
    {
        source: TRI_SOURCES.Selected,
        label: "selected",
        icon: SelectedIcon
    },
    {
        source: TRI_SOURCES.Filtered,
        label: "filtered",
        icon: FilteredIcon
    },
    { source: TRI_SOURCES.FromFile, label: "from file", icon: FromFileIcon }
];
