import { useTranslation } from "react-i18next";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import TravelExploreRoundedIcon from "@mui/icons-material/TravelExploreRounded";

import { FindToolProps } from "./types";
import FindToolDesktop from "./FindToolDesktop";
import FindToolMobile from "./FindToolMobile";

import { TooltipGeneral } from "../../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import {
    MenuAnchorEl,
    removeFindToolFilterFromStorage,
    useMediaQueries
} from "../../../shared";

const FindTool = (props: FindToolProps) => {
    const { t } = useTranslation();
    const { toMd, to899 } = useMediaQueries();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) =>
        setAnchorEl(e.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    useEffect(() => {
        closeMenu();
        closeDialog();
        removeFindToolFilterFromStorage();
    }, [toMd]);

    const renderButtons = () => {
        const handleClickFromMd = toMd ? openDialog : openMenu;

        return to899 ? (
            <TooltipGeneral title={t("Button##find tool")} disableTouchListener>
                <Button
                    {...props}
                    id="deviceFindOpenBtnMobile"
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<TravelExploreRoundedIcon />}
                    onClick={handleClickFromMd}
                />
            </TooltipGeneral>
        ) : (
            <Button
                {...props}
                id="deviceFindOpenBtn"
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconLeft}
                icon={<TravelExploreRoundedIcon />}
                onClick={handleClickFromMd}
            >
                <span>{t("Button##find tool")}</span>
            </Button>
        );
    };

    const renderContent = () =>
        toMd ? (
            <FindToolMobile isOpen={isDialogOpen} close={closeDialog} />
        ) : (
            <FindToolDesktop anchorEl={anchorEl} close={closeMenu} />
        );

    return (
        <Fragment>
            {renderButtons()}
            {renderContent()}
        </Fragment>
    );
};

export default FindTool;
