import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TasksIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5C3.89543 5 3 5.89543 3 7V9C3 10.1046 3.89543 11 5 11H19C20.1046 11 21 10.1046 21 9V7C21 5.89543 20.1046 5 19 5H5ZM19 7H12V9H19V7Z"
                fill="currentColor"
            />

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 13C3.89543 13 3 13.8954 3 15V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V15C21 13.8954 20.1046 13 19 13H5ZM8 15V17H19V15H8Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default TasksIcon;
