/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SettingsContent from "./SettingsContent";
import { DIALOG_NAMES } from "./types";

import Menu from "../../../DesignComponents/Menu";
import { MenuAnchorEl } from "../../../../shared";

interface SettingsMenuDesktopProps {
    isMobile: boolean;
    anchorEl: MenuAnchorEl;
    close: () => void;
    openDialog: (name: DIALOG_NAMES) => void;
}

const SettingsMenuDesktop = ({
    isMobile,
    anchorEl,
    close,
    openDialog
}: SettingsMenuDesktopProps) => {
    return (
        <Menu
            open={Boolean(anchorEl)}
            onClose={close}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            MenuListProps={{ disableListWrap: true }}
            css={css({
                "& .MuiMenu-paper": {
                    width: "315px"
                }
            })}
        >
            <SettingsContent
                isMobile={isMobile}
                openDialog={openDialog}
                closeMenu={close}
            />
        </Menu>
    );
};

export default SettingsMenuDesktop;
