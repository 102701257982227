/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

const ColoredCircles = () => {
    const {
        colorsFacelift: { red600, orange400, green500 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                position: "absolute",
                top: "24px",
                left: "16px",
                display: "flex",
                columnGap: "8px",

                span: {
                    display: "inline-block",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%"
                },

                "span:nth-of-type(1)": {
                    background: red600
                },

                "span:nth-of-type(2)": {
                    background: orange400
                },

                "span:nth-of-type(3)": {
                    background: green500
                },

                "@media (max-width: 839px)": {
                    top: "16px",
                    left: "10px",
                    columnGap: "5px",

                    span: {
                        width: "10px",
                        height: "10px"
                    }
                },

                "@media (max-width: 599px)": {
                    top: "12px",
                    left: "8px",
                    columnGap: "4px",

                    span: {
                        width: "8px",
                        height: "8px"
                    }
                }
            })}
        >
            <span />
            <span />
            <span />
        </div>
    );
};

export default ColoredCircles;
