/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { GenerateTokenProps, SELECTED_TOKEN_KEYS, TOKEN_TYPES } from "../types";

import Autocomplete from "../../../../../DesignComponents/Autocomplete";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../DesignComponents/Tooltips";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../DesignComponents/BannerMessage";
import CopyButton from "../../../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../../../DesignComponents/Button";

import {
    COMPANY_LEVEL_SETTINGS,
    DROPDOWN_SIZES,
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../shared";

const GenerateToken = ({ name, token, change }: GenerateTokenProps) => {
    const { t } = useTranslation();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const tokenTypes = [
        {
            id: TOKEN_TYPES.Tct,
            name: t(`Api tokens##titles##${TOKEN_TYPES.Tct}##name`),
            description: t(
                `Api tokens##titles##${TOKEN_TYPES.Tct}##description`
            ),
            isRestricted: false
        },
        {
            id: TOKEN_TYPES.Integration,
            name: t(`Api tokens##titles##${TOKEN_TYPES.Integration}##name`),
            description: t(
                `Api tokens##titles##${TOKEN_TYPES.Integration}##description`
            ),
            isRestricted: isFeatureSettingsRestricted(
                FEATURE_SETTINGS.ApiTokenSupport
            )
        }
    ];

    return token ? (
        <div>
            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Success}
                title={t("Api tokens##token was created")}
            />

            <div
                css={css({
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "16px",

                    "& > button": {
                        marginLeft: "8px"
                    }
                })}
            >
                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Small}
                    labelLeft={t("Api tokens##generated token")}
                    value={token}
                    readOnly
                />

                <CopyButton size={BUTTON_SIZES.Small} copiedValue={token} />
            </div>
        </div>
    ) : (
        <>
            <Autocomplete
                fullWidth
                isRequired
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("Api tokens##token type")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                options={tokenTypes}
                getOptionLabel={option => option.name}
                getOptionDisabled={option => option.isRestricted}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) =>
                    change(SELECTED_TOKEN_KEYS.Type, value?.id || null)
                }
                renderOption={(
                    propsData,
                    { id, name, description, isRestricted }
                ) => {
                    return (
                        <TooltipGeneral
                            key={id}
                            title={
                                isRestricted
                                    ? t(
                                          `Restrictions##${COMPANY_LEVEL_SETTINGS.FeatureSettings}##${FEATURE_SETTINGS.ApiTokenSupport}##input option`
                                      )
                                    : ""
                            }
                            placement={TOOLTIP_PLACEMENT.Top}
                        >
                            <li>
                                <span
                                    {...propsData}
                                    css={css({
                                        flexDirection: "column",

                                        "&.MuiAutocomplete-option": {
                                            alignItems: "flex-start"
                                        }
                                    })}
                                >
                                    <span
                                        css={css({
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            letterSpacing: "0.1px"
                                        })}
                                    >
                                        {name}
                                    </span>

                                    <span
                                        css={css({
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.4px"
                                        })}
                                    >
                                        {description}
                                    </span>
                                </span>
                            </li>
                        </TooltipGeneral>
                    );
                }}
            />

            <InputField
                fullWidth
                size={INPUT_FIELD_SIZES.Medium}
                labelLeft={t("Api tokens##name")}
                placeholder={t("Api tokens##example")}
                value={name}
                required
                onChange={e => change(SELECTED_TOKEN_KEYS.Name, e.target.value)}
                customStyle={{ marginTop: "16px" }}
            />
        </>
    );
};

export default GenerateToken;
