/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";

import SpecificLogs from "./SpecificLogs";
import SortableLogs from "./SortableLogs";
import { isSortableValueCorrect } from "./functions";
import { InputValue, ORDER } from "./types";

import { ChangePayloadProps } from "../types";

import Radio from "../../../../../../../DesignComponents/Radio";

import ThemeContext from "../../../../../../../../context/theme/themeContext";

const InternalLog = ({ changePayload }: ChangePayloadProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const [order, setOrder] = useState(ORDER.Newest);
    const [inputValue, setInputValue] = useState<InputValue | null>(null);

    useEffect(() => {
        const isLogValueCorrect =
            inputValue !== null &&
            (order !== ORDER.Specific
                ? isSortableValueCorrect(inputValue.count)
                : true);

        if (isLogValueCorrect) {
            const logPayload = {
                order,
                ...inputValue
            };

            changePayload({
                id: "attributes",
                value: JSON.stringify(logPayload)
            });
        } else {
            changePayload(null);
        }

        // eslint-disable-next-line
    }, [order, inputValue]);

    const changeOrder = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as ORDER;

        setInputValue(null);
        setOrder(value);
    };

    const changeInputValue = (value: InputValue | null) => setInputValue(value);

    const sortableLogsValue = inputValue ? inputValue.count : "";

    return (
        <div>
            <div
                css={css({
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    marginBottom: "6px"
                })}
            >
                {t("Dialog##begin download")}:
            </div>

            <RadioGroup
                value={order}
                onChange={changeOrder}
                sx={{
                    marginBottom: "14px",

                    "& > label + label": {
                        marginTop: "6px"
                    }
                }}
            >
                <Radio
                    value={ORDER.Newest}
                    label={t(`Dialog##${ORDER.Newest}`)}
                />

                <Radio
                    value={ORDER.Oldest}
                    label={t(`Dialog##${ORDER.Oldest}`)}
                />

                <Radio
                    value={ORDER.Specific}
                    label={t(`Dialog##${ORDER.Specific}`)}
                />
            </RadioGroup>

            {order === ORDER.Specific ? (
                <SpecificLogs order={order} change={changeInputValue} />
            ) : (
                <SortableLogs
                    order={order}
                    value={sortableLogsValue}
                    change={changeInputValue}
                />
            )}
        </div>
    );
};

export default InternalLog;
