import { useState } from "react";

import { useDialog } from "../../ActionBar/hooks";
import { DEVICE_ACTIONS } from "../../types";

import {
    RenderConditionOptions,
    checkIfDownloadIsAllowed,
    downloadFile,
    useApi
} from "../../../../shared";
import TaskDetailsIcon from "../../../../assets/customIcons/actions/TaskDetailsIcon";
import FileDownloadRoundedIcon from "../../../../assets/customIcons/actions/FileDownloadRoundedIcon";

const useTaskInlineActions = () => {
    const { getData, handleResponse } = useApi();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    const [taskData, setTaskData] = useState<any>(null);

    const inlineActions = [
        {
            title: "download file",
            hasLoader: true,
            icon: FileDownloadRoundedIcon,
            clickAction: (data: any, removeLoading: () => void) =>
                downloadFile({
                    data: data.file,
                    getData,
                    handleResponse,
                    removeLoading
                }),
            renderConditions: ({
                data,
                outerDetailsData
            }: RenderConditionOptions) => {
                const hasNoBatchFile = outerDetailsData
                    ? !outerDetailsData.file
                    : true;

                return (
                    hasNoBatchFile &&
                    data.file &&
                    checkIfDownloadIsAllowed(data.file)
                );
            }
        },
        {
            title: "task details",
            icon: TaskDetailsIcon,
            clickAction: (data: any) => {
                setTaskData(data);
                openDialog(DEVICE_ACTIONS.TaskDetails);
            }
        }
    ];

    return {
        inlineActions,
        taskData,
        isOpen: isOpenDialog[DEVICE_ACTIONS.TaskDetails],
        closeDialog,
        setTaskData
    };
};

export default useTaskInlineActions;
