/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect, SyntheticEvent } from "react";
import MuiSnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import TransitionLeft from "./TransitionLeft";
import { processAlertData, processSnackPack } from "./functions";
import { SnackbarData } from "./types";

import Alert from "../DesignComponents/Alert";

import AlertContext from "../../context/alert/alertContext";

const Snackbar = () => {
    const {
        status,
        title,
        description,
        primaryBtn,
        secondaryBtn,
        hasAutoHide,
        removeAlert
    } = useContext(AlertContext);

    const [isOpen, setOpen] = useState(false);
    const [snackPack, setSnackPack] = useState<SnackbarData[]>([]);

    const [alertData, setAlertData] = useState<SnackbarData>({
        status: null,
        title: null,
        description: null,
        primaryBtn: null,
        secondaryBtn: null
    });

    const {
        status: alertStatus,
        title: alertTitle,
        description: alertDescription,
        primaryBtn: alertPrimaryBtn,
        secondaryBtn: alertSecondaryBtn
    } = alertData;

    useEffect(() => {
        processSnackPack({
            status,
            snackPack,
            title,
            description,
            primaryBtn,
            secondaryBtn,
            setSnackPack,
            setOpen
        });
        // eslint-disable-next-line
    }, [status, title, description]);

    useEffect(() => {
        processAlertData(snackPack, alertStatus, isOpen, setAlertData, setOpen);
        // eslint-disable-next-line
    }, [snackPack, alertData, isOpen]);

    const handleClose = (
        _e: SyntheticEvent<any> | Event,
        reason?: SnackbarCloseReason
    ) => {
        const hasReasonNotClose = hasAutoHide
            ? reason === "clickaway"
            : reason === "clickaway" || reason === "escapeKeyDown";

        if (hasReasonNotClose) {
            return;
        }

        removeAlert();
    };

    const handleExited = () =>
        setAlertData({
            status: null,
            title: null,
            description: null,
            primaryBtn: null,
            secondaryBtn: null
        });

    return alertStatus && alertTitle ? (
        <MuiSnackbar
            open={isOpen}
            disableWindowBlurListener
            autoHideDuration={hasAutoHide ? 6000 : null}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            TransitionComponent={TransitionLeft}
        >
            <div
                css={css({
                    width: "448px"
                })}
            >
                <Alert
                    status={alertStatus}
                    title={alertTitle}
                    description={alertDescription || undefined}
                    primaryBtn={alertPrimaryBtn || undefined}
                    secondaryBtn={alertSecondaryBtn || undefined}
                    close={hasAutoHide ? handleClose : undefined}
                />
            </div>
        </MuiSnackbar>
    ) : null;
};

export default Snackbar;
