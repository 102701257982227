/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import DevicesBar from "./DevicesBar";
import DeviceGroupsBar from "./DeviceGroupsBar";
import FilesBar from "./FilesBar";
import CompaniesBar from "./CompaniesBar";
import UsersBar from "./UsersBar";
import TaskGroupsBar from "./TaskGroupsBar";
import DashboardBar from "./DashboardBar";
import ProductCodesBar from "./ProductCodesBar";
import LicensesBar from "./LicensesBar";
import { ActionBarProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import { TABLE_NAMES } from "../../../shared";

const ActionBar = ({ name }: ActionBarProps) => {
    const {
        colorsFacelift: { gray50, gray200, textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { tableName } = useContext(TableContext);

    const contentName = name ?? tableName;

    const renderContent = () => {
        switch (contentName) {
            case TABLE_NAMES.TaskGroups:
                return <TaskGroupsBar />;
            case TABLE_NAMES.Companies:
                return <CompaniesBar />;
            case TABLE_NAMES.Users:
            case TABLE_NAMES.UserInvite:
                return <UsersBar />;
            case TABLE_NAMES.Devices:
                return <DevicesBar />;
            case TABLE_NAMES.Files:
                return <FilesBar />;
            case TABLE_NAMES.DeviceGroups:
                return <DeviceGroupsBar />;
            case TABLE_NAMES.ProductCodes:
                return <ProductCodesBar />;
            case TABLE_NAMES.DesktopLicenses:
                return <LicensesBar />;
            default:
                return <DashboardBar />;
        }
    };

    return (
        <section>
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: gray50,
                    borderBottom: `1px solid ${gray200}`,
                    padding: (Object.values(TABLE_NAMES) as string[]).includes(
                        contentName
                    )
                        ? "16px 24px 15px 24px"
                        : "22px 0px 21px 24px",

                    h1: {
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",

                        "& > span:first-of-type": {
                            color: textDark,
                            marginRight: "5px"
                        },

                        "& > span:last-of-type": {
                            color: (
                                Object.values(TABLE_NAMES) as string[]
                            ).includes(contentName)
                                ? textPlaceholder
                                : textDark
                        }
                    },

                    "@media(max-width: 599px)": {
                        display: "block"
                    }
                })}
            >
                {renderContent()}
            </div>
        </section>
    );
};

export default ActionBar;
