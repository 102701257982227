/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { TwoIconsButtonProps } from "./types";

const TwoIconsButton = ({ title, icon }: TwoIconsButtonProps) => {
    return (
        <Fragment>
            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                `}
            >
                {icon[0]}
            </span>

            {title}

            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                `}
            >
                {icon[1]}
            </span>
        </Fragment>
    );
};

export default TwoIconsButton;
