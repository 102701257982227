/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../DesignComponents/BannerMessage";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../DesignComponents/InputField";
import CopyButton from "../../../../../../DesignComponents/CopyButton";

import AuthContext from "../../../../../../../context/auth/authContext";
import { BUTTON_SIZES } from "../../../../../../DesignComponents/Button";

const ReceiveDeviceContent = ({ token }: any) => {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const userCompany = user?.company;

    return !token ? (
        <InputField
            data-testid="receive-devices-token-company"
            labelLeft={t("Table##receiving company")}
            fullWidth
            readOnly
            value={userCompany?.name}
            size={INPUT_FIELD_SIZES.Medium}
        />
    ) : (
        <Fragment>
            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={t(
                    "Dialog##device transfer##receive devices##token info"
                )}
            />
            <div
                css={css({
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "16px",

                    "& > button": {
                        marginLeft: "8px"
                    }
                })}
            >
                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    color="secondary"
                    value={token}
                    readOnly
                    labelLeft={t("Api tokens##generated token")}
                />
                <CopyButton size={BUTTON_SIZES.Normal} copiedValue={token} />
            </div>
        </Fragment>
    );
};
export default ReceiveDeviceContent;
