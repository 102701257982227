import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { InputName } from "./generalReducer";

import { EDIT_TYPE } from "../../types/enums";
import EditSection from "../../../EditSection/EditSection";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

import TableContext from "../../../../../context/table/tableContext";
import { ENDPOINTS, useApi } from "../../../../../shared";

interface DescriptionProps {
    isEditable: boolean;
    value: string;
    toggleEditable: (name: InputName, value: boolean) => void;
    changeValue: (
        name: InputName,
        newValue: string,
        isUpdate?: boolean
    ) => void;
}

const Description = ({
    isEditable,
    value,
    toggleEditable,
    changeValue
}: DescriptionProps) => {
    const {
        detailsData: { imei, description }
    } = useContext(TableContext);

    const { t } = useTranslation();
    const { updateData, handleResponse } = useApi();

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        toggleEditable(EDIT_TYPE.Description, false);
        changeValue(EDIT_TYPE.Description, description || "");

        // eslint-disable-next-line
    }, [imei, description]);

    const edit = () => toggleEditable(EDIT_TYPE.Description, true);

    const cancel = () => {
        toggleEditable(EDIT_TYPE.Description, false);
    };

    const update = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            setLoading(true);

            const response = await updateData(ENDPOINTS.Devices, imei, {
                description: value
            });

            changeValue(EDIT_TYPE.Description, value, true);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    const handleChange = (e: { target: { value: string } }) =>
        changeValue(EDIT_TYPE.Description, e.target.value);

    const renderEditComponent = () => {
        if (isEditable) {
            return (
                <InputField
                    autoFocus
                    size={INPUT_FIELD_SIZES.Tiny}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        return value || "-";
    };

    return (
        <EditSection
            sectionName={t("Table##description")}
            update={update}
            isLoading={isLoading}
            isEditable={isEditable}
            edit={edit}
            cancel={cancel}
            value={value}
            copiedValue={value}
        >
            {renderEditComponent()}
        </EditSection>
    );
};

export default Description;
