import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MergeIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.69687 20.2998C5.51354 20.1165 5.42188 19.8831 5.42188 19.5998C5.42188 19.3165 5.51354 19.0831 5.69687 18.8998L9.82187 14.7498C10.2052 14.3665 10.4969 13.9331 10.6969 13.4498C10.8969 12.9665 10.9969 12.4581 10.9969 11.9248V6.8248L10.0969 7.7248C9.91354 7.90814 9.68454 7.9998 9.40987 7.9998C9.13454 7.9998 8.89687 7.8998 8.69687 7.6998C8.51354 7.51647 8.42188 7.28314 8.42188 6.9998C8.42188 6.71647 8.51354 6.48314 8.69687 6.2998L11.2969 3.6998C11.3969 3.5998 11.5052 3.5288 11.6219 3.4868C11.7385 3.44547 11.8635 3.4248 11.9969 3.4248C12.1302 3.4248 12.2552 3.44547 12.3719 3.4868C12.4885 3.5288 12.5969 3.5998 12.6969 3.6998L15.2969 6.2998C15.4802 6.48314 15.5762 6.71214 15.5849 6.9868C15.5929 7.26214 15.4969 7.4998 15.2969 7.6998C15.1135 7.88314 14.8802 7.9748 14.5969 7.9748C14.3135 7.9748 14.0802 7.88314 13.8969 7.6998L12.9969 6.8248V11.9248C12.9969 12.4581 13.0969 12.9665 13.2969 13.4498C13.4969 13.9331 13.7885 14.3665 14.1719 14.7498L18.2969 18.8998C18.4802 19.0831 18.5759 19.3121 18.5839 19.5868C18.5925 19.8621 18.4969 20.0998 18.2969 20.2998C18.1135 20.4831 17.8802 20.5748 17.5969 20.5748C17.3135 20.5748 17.0802 20.4831 16.8969 20.2998L11.9969 15.3998L7.09687 20.2998C6.91354 20.4831 6.68421 20.5788 6.40887 20.5868C6.13421 20.5955 5.89687 20.4998 5.69687 20.2998Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default MergeIcon;
