import { useContext } from "react";

import ThemeContext from "../../../../context/theme/themeContext";
import { RectangleLoader } from "../../../../shared";

const CompanyLevelTagLoader = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return <RectangleLoader width={65} height={20} backgroundColor={gray200} />;
};

export default CompanyLevelTagLoader;
