import { useContext } from "react";

import {
    COMPANY_LEVEL_SETTINGS,
    FEATURE_SETTINGS,
    RESOURCE_LIMIT_SETTINGS
} from "../types";

import ParamsContext from "../../context/params/paramsContext";
import AuthContext from "../../context/auth/authContext";

export const useCompanyLevelSettings = () => {
    const { rootCompanyLevel } = useContext(ParamsContext);
    const { allCompanyLevelSettings } = useContext(AuthContext);

    const actualLevelSettings = allCompanyLevelSettings?.[rootCompanyLevel];

    const isFeatureSettingsRestricted = (
        parameter: FEATURE_SETTINGS | undefined
    ) =>
        parameter !== undefined &&
        !actualLevelSettings?.[COMPANY_LEVEL_SETTINGS.FeatureSettings][
            parameter
        ];

    const getResourceLimit = (parameter: RESOURCE_LIMIT_SETTINGS) =>
        actualLevelSettings?.[COMPANY_LEVEL_SETTINGS.ResourceLimitSettings][
            parameter
        ] ?? -1;

    return { isFeatureSettingsRestricted, getResourceLimit };
};
