/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { StatusIconProps } from "../types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";

import WarningIcon from "../../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../../assets/customIcons/status/SuccessIcon";
import { FILE_UPLOAD_STATUS } from "../../../shared";

const StatusIcon = ({ uploadStatus }: StatusIconProps) => {
    const { t } = useTranslation();

    const isSuccess = uploadStatus === FILE_UPLOAD_STATUS.Success;

    return (
        <TooltipGeneral
            placement={TOOLTIP_PLACEMENT.Top}
            title={
                isSuccess
                    ? t("Dropzone##file successfully uploaded")
                    : t("Dropzone##error uploading the file")
            }
        >
            <span
                css={css({
                    "& > svg": { fontSize: "24px", margin: "6px 6px 1px 6px" }
                })}
            >
                {isSuccess ? <SuccessIcon /> : <WarningIcon />}
            </span>
        </TooltipGeneral>
    );
};

export default StatusIcon;
