/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FileUploadActions from "./FileUploadContent/FileUploadActions/FileUploadActions";
import FileUploadResult from "./FileUploadContent/FileUploadResult/FileUploadResult";

import { FileType } from "../types";
import useFileTypes from "../hooks/useFileTypes";

import { DialogProps, FILE_ACTIONS } from "../../../types";

import Autocomplete from "../../../../DesignComponents/Autocomplete";
import Dialog from "../../../../DesignComponents/Dialog";
import Dropzone from "../../../../Dropzone";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";
import QueryDropdown from "../../../../QueryDropdown";

import ParamsContext from "../../../../../context/params/paramsContext";
import DropzoneContext from "../../../../../context/dropzone/dropzoneContext";
import {
    Company,
    DROPDOWN_SIZES,
    ENDPOINTS,
    EventData,
    FILE_NAME_MAX_LENGTH,
    FILE_TYPES,
    FILE_UPLOAD_STATUS,
    Filter,
    globalCompany,
    ID_TYPE,
    maxDialogHeight,
    maxDialogWidth,
    SORT_RESOURCES,
    TABLE_NAMES,
    useApi,
    useMediaQueries
} from "../../../../../shared";

const UploadFile = ({
    isOpen,
    close,
    onEnter,
    onExited,
    successCallback
}: DialogProps) => {
    const { rootCompany, setReloadItems } = useContext(ParamsContext);

    const { multipleFiles, setStatus, removeAllFiles, setMessage } =
        useContext(DropzoneContext);

    const { t } = useTranslation();
    const { handleResponse, postData } = useApi();
    const { visibleTypes } = useFileTypes();
    const { toMd, fromMd } = useMediaQueries();

    const [counter, setCounter] = useState(0);
    const [companies, setCompanies] = useState([rootCompany as Filter]);
    const [description, setDescription] = useState("");
    const [fileType, setFileType] = useState<FileType | null>(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isFmObdFileTypeSelected = fileType?.value === FILE_TYPES.FmObdChipFw;

    useEffect(() => {
        if (isFmObdFileTypeSelected) {
            // If FM OBD Chip firmware file type is selected, set the Teltonika Files company
            setCompanies([globalCompany]);
        } else {
            // In other cases, set root company
            setCompanies([rootCompany as Filter]);
        }
    }, [isFmObdFileTypeSelected, rootCompany]);

    const isReady =
        companies &&
        companies.length > 0 &&
        fileType &&
        multipleFiles.length > 0;

    const getFormData = useCallback(() => {
        const payload: any[] = [];

        if (isReady) {
            multipleFiles.forEach(fileObj => {
                const { file, id } = fileObj;

                const formData = new FormData();

                const companyIds = companies.map(company => company?.id);

                companyIds.forEach((singleID, index) => {
                    formData.append(
                        `company_ids[${index}]`,
                        JSON.stringify(singleID)
                    );
                });

                formData.append("type", fileType.value);
                formData.append("file", file);

                description.trim().length > 0 &&
                    formData.append("description", description);

                payload.push({ formData, id });
            });

            return payload;
        }
    }, [companies, description, fileType, isReady, multipleFiles]);

    const handleCloseDialog = () => close(FILE_ACTIONS.UploadFiles);

    const handleChangeFileType = (type: FileType | null) => {
        setFileType(type);
        removeAllFiles();
    };

    const handleCompanyChange = (companyValue: Company[]) =>
        setCompanies(companyValue);

    const handleDescriptionChange = (e: { target: EventData }) => {
        const { value } = e.target;
        setDescription(value);
    };

    const handleSubmit = async () => {
        if (isReady) {
            try {
                setIsLoading(true);

                const payloadData = {
                    resource: ENDPOINTS.Files,
                    data: getFormData()
                };

                if (payloadData.data) {
                    for (const singlePayloadData of payloadData.data) {
                        const { formData, id } = singlePayloadData;

                        try {
                            await postData(ENDPOINTS.Files, formData);

                            setStatus(FILE_UPLOAD_STATUS.Success, id);
                            setCounter(prevCount => prevCount + 1);
                        } catch (error) {
                            setStatus(FILE_UPLOAD_STATUS.Failed, id, error);
                        }
                    }
                }

                setReloadItems(TABLE_NAMES.Files);
                successCallback?.();
            } catch (error) {
                handleResponse(error);
            }

            setIsUploaded(true);
            setMessage([], true);
        }

        setIsLoading(false);
    };

    const resetDialogWindow = (fullReset: boolean) => {
        setIsUploaded(false);
        setCounter(0);
        removeAllFiles();

        if (fullReset) {
            setCompanies([rootCompany as Filter]);
            setFileType(null);
            setDescription("");
        }
    };

    return (
        <Dialog
            data-testid="upload-file-dialog"
            title={t("Dialog##upload file")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            actions={
                <FileUploadActions
                    isUploaded={isUploaded}
                    isLoading={isLoading}
                    isReady={isReady}
                    close={handleCloseDialog}
                    resetDialog={() => resetDialogWindow(false)}
                />
            }
            TransitionProps={{
                onEnter: () => onEnter?.(),
                onExited: () => {
                    resetDialogWindow(true);
                    onExited?.();
                }
            }}
            css={css(fromMd && [maxDialogWidth, maxDialogHeight])}
            extendToMaxWidth={toMd}
        >
            {isUploaded ? (
                <FileUploadResult
                    fileNumber={multipleFiles.length}
                    successCounter={counter}
                    fileType={fileType}
                    companies={companies}
                    description={description}
                />
            ) : (
                <div
                    css={css({
                        "> div:first-of-type": {
                            marginTop: "8px"
                        },
                        "> div": {
                            marginBottom: "16px"
                        }
                    })}
                >
                    <Autocomplete
                        labelLeft={t("Table##file type")}
                        textFieldParams={{ placeholder: t("Dialog##select") }}
                        data-testid="file-type-dropdown"
                        fullWidth
                        isRequired
                        value={fileType}
                        size={DROPDOWN_SIZES.Medium}
                        options={visibleTypes}
                        getOptionLabel={option => t(`General##${option.value}`)}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        onChange={(_, val) => handleChangeFileType(val)}
                    />

                    <QueryDropdown
                        multiple
                        data-testid="company-file-upload-dropdown"
                        resource={ENDPOINTS.Companies}
                        idType={ID_TYPE.Id}
                        value={companies}
                        size={DROPDOWN_SIZES.Medium}
                        fullWidth
                        isRequired
                        change={(_, val) => handleCompanyChange(val)}
                        labelLeft={t("Dialog##company")}
                        isRootRequired
                        textFieldParams={{
                            placeholder:
                                companies.length > 0 ? "" : t("Dialog##select")
                        }}
                        readOnly={isFmObdFileTypeSelected}
                    />

                    <InputField
                        data-testid="description-input-field"
                        size={INPUT_FIELD_SIZES.Medium}
                        fullWidth
                        onChange={handleDescriptionChange}
                        value={description}
                        name={SORT_RESOURCES.Description}
                        labelLeft={`${t("Table##description")} (${t(
                            "Dialog##optional"
                        )})`}
                        placeholder={`${t("Dialog##example")}`}
                    />
                </div>
            )}

            <Dropzone
                data-testid="file-upload-dropzone"
                supportedFormats={fileType?.extensions || []}
                countCsv
                isDisabled={!fileType}
                isMultiple
                maxFileNameLength={FILE_NAME_MAX_LENGTH}
                isLoading={isLoading}
                isUploaded={isUploaded}
            />
        </Dialog>
    );
};
export default UploadFile;
