import { useTranslation } from "react-i18next";

import { DeviceStatusIconProps } from "./types";

import Badge, {
    BADGE_COLORS,
    BadgeData
} from "../../../../DesignComponents/Badge";

import { ACTIVITY_STATUS } from "../../../../../shared";

const DeviceStatusIcon = ({ status }: DeviceStatusIconProps) => {
    const { t } = useTranslation();

    const getBadgeData = (statusName: ACTIVITY_STATUS): BadgeData => {
        switch (statusName) {
            case ACTIVITY_STATUS.Online:
                return { color: BADGE_COLORS.Success, isInverted: true };
            case ACTIVITY_STATUS.Offline:
                return { color: BADGE_COLORS.Neutral, isInverted: false };
            default:
                return { color: BADGE_COLORS.Light, isInverted: false };
        }
    };

    const color = getBadgeData(status).color;
    const isInverted = getBadgeData(status).isInverted;

    const name = t(`General##${status}`);

    return (
        <Badge color={color} inverted={isInverted}>
            {name}
        </Badge>
    );
};

export default DeviceStatusIcon;
