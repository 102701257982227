import { useContext } from "react";

import useOfflineLicenseInlineActions from "../../../Actions/InlineActions/hooks/useOfflineLicenseInlineActions";
import DeleteOfflineLicense from "../../../Actions/dialogs/Licenses/OfflineLicenses/Inline/DeleteOfflineLicense";
import Table from "../../../Table";

import TableContext from "../../../../context/table/tableContext";
import {
    ID_TYPE,
    TABLE_NAMES,
    OFFLINE_LICENSE_COLUMNS as COLUMNS
} from "../../../../shared";

const OfflineLicenses = () => {
    const { detailsData } = useContext(TableContext);

    const { inlineActions, rowData, isOpen, closeDialog } =
        useOfflineLicenseInlineActions();

    const { offline_licenses } = detailsData;

    return (
        <>
            <Table
                name={TABLE_NAMES.OfflineLicenses}
                isInnerTable
                customData={offline_licenses}
                initialColumns={COLUMNS}
                inlineActions={inlineActions}
                sortFromNewest
                isNotSelectable
                idType={ID_TYPE.Id}
                customStyle={{ marginTop: "16px" }}
            />

            <DeleteOfflineLicense
                data={rowData}
                isOpen={isOpen}
                close={closeDialog}
            />
        </>
    );
};

export default OfflineLicenses;
