import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TwoFactorAuthenticationIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M10 16H14C14.2833 16 14.5208 15.9042 14.7125 15.7125C14.9042 15.5209 15 15.2834 15 15V12C15 11.7167 14.9042 11.4792 14.7125 11.2875C14.5208 11.0959 14.2833 11 14 11V10C14 9.45002 13.8042 8.97919 13.4125 8.58752C13.0208 8.19586 12.55 8.00002 12 8.00002C11.45 8.00002 10.9792 8.19586 10.5875 8.58752C10.1958 8.97919 10 9.45002 10 10V11C9.71667 11 9.47917 11.0959 9.2875 11.2875C9.09583 11.4792 9 11.7167 9 12V15C9 15.2834 9.09583 15.5209 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16ZM11 11V10C11 9.71669 11.0958 9.47919 11.2875 9.28752C11.4792 9.09586 11.7167 9.00002 12 9.00002C12.2833 9.00002 12.5208 9.09586 12.7125 9.28752C12.9042 9.47919 13 9.71669 13 10V11H11ZM12 21.9C11.8833 21.9 11.775 21.8917 11.675 21.875C11.575 21.8584 11.475 21.8334 11.375 21.8C9.125 21.05 7.33333 19.6625 6 17.6375C4.66667 15.6125 4 13.4334 4 11.1V6.37502C4 5.95836 4.12083 5.58336 4.3625 5.25002C4.60417 4.91669 4.91667 4.67502 5.3 4.52502L11.3 2.27502C11.5333 2.19169 11.7667 2.15002 12 2.15002C12.2333 2.15002 12.4667 2.19169 12.7 2.27502L18.7 4.52502C19.0833 4.67502 19.3958 4.91669 19.6375 5.25002C19.8792 5.58336 20 5.95836 20 6.37502V11.1C20 13.4334 19.3333 15.6125 18 17.6375C16.6667 19.6625 14.875 21.05 12.625 21.8C12.525 21.8334 12.425 21.8584 12.325 21.875C12.225 21.8917 12.1167 21.9 12 21.9ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.37502L12 4.12502L6 6.37502V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default TwoFactorAuthenticationIcon;
