import { useContext } from "react";

import DeviceStatisticsMobile from "./DeviceStatisticsMobile";
import DeviceStatisticsDesktop from "./DeviceStatisticsDesktop";

import AuthContext from "../../context/auth/authContext";
import { useMediaQueries } from "../../shared";

const DeviceStatistics = () => {
    const { isSupportUser } = useContext(AuthContext);
    const { toMd } = useMediaQueries();

    const renderDeviceStatistics = () => {
        if (toMd) {
            return <DeviceStatisticsMobile />;
        }

        return <DeviceStatisticsDesktop />;
    };

    return !isSupportUser ? renderDeviceStatistics() : null;
};

export default DeviceStatistics;
