import { FoundResourceCompanyRowProps } from "../types";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import { useOverflow } from "../../../../shared";

const FoundResourceCompanyRow = ({
    companyType,
    companyName
}: FoundResourceCompanyRowProps) => {
    const { measuredRef, isOverflowing } = useOverflow([]);

    return (
        <div>
            <span>{companyType}:</span>

            <TooltipGeneral
                title={companyName}
                disableHoverListener={!isOverflowing}
                placement={TOOLTIP_PLACEMENT.Top}
            >
                <span ref={measuredRef}>{companyName}</span>
            </TooltipGeneral>
        </div>
    );
};

export default FoundResourceCompanyRow;
