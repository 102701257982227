import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const DisabledCheckedIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { gray50, gray300 }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect x="2" y="2" width="20" height="20" rx="10" fill={gray300} />
            <circle cx="12" cy="12" r="3.5" fill={gray50} stroke={gray300} />
        </SvgIcon>
    );
};

export default DisabledCheckedIcon;
