import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CheckboxErrorIndeterminateIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { blue700, red50, red700, white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="7"
                fill={blue700}
                stroke={red50}
                strokeWidth="2"
            />
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                stroke={red700}
            />
            <path
                d="M16.2857 12.5H7.71429C7.32143 12.5 7 12.1625 7 11.75C7 11.3375 7.32143 11 7.71429 11H16.2857C16.6786 11 17 11.3375 17 11.75C17 12.1625 16.6786 12.5 16.2857 12.5Z"
                fill={white}
            />
        </SvgIcon>
    );
};

export default CheckboxErrorIndeterminateIcon;
