import { useCallback, useMemo, useReducer } from "react";

import NotificationsContext from "./notificationsContext";
import NotificationsReducer from "./notificationsReducer";
import {
    SET_NOTIFICATIONS_COUNT,
    SET_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    PUSH_PREVIOUS_NOTIFICATION
} from "./notificationsActions";
import Notifications from "./Notifications";
import { NotificationsProps } from "./types";

import { ENDPOINTS, Notification, useApi } from "../../shared";

const NotificationsState = ({ handleDrawer }: NotificationsProps) => {
    const { deleteData, getData, handleResponse } = useApi();

    const initialState = {
        readCount: 0,
        unreadCount: 0,
        notifications: []
    };

    const [state, dispatch] = useReducer(NotificationsReducer, initialState);

    const setNotificationsCount = useCallback(async () => {
        try {
            const { data } = await getData(`${ENDPOINTS.Notifications}/count`);

            dispatch({ type: SET_NOTIFICATIONS_COUNT, payload: data });
        } catch (error) {
            handleResponse(error);
        }
    }, [getData, handleResponse]);

    const setNotifications = (data: Notification[]) =>
        dispatch({ type: SET_NOTIFICATIONS, payload: data });

    const deleteNotification = useCallback(
        async (id: string) => {
            dispatch({ type: DELETE_NOTIFICATION, payload: id });

            try {
                await deleteData(ENDPOINTS.Notifications, id);
            } catch (error) {
                handleResponse(error);
            }
        },
        [deleteData, handleResponse]
    );

    const pushPreviousNotification = () =>
        dispatch({ type: PUSH_PREVIOUS_NOTIFICATION });

    const value = useMemo(
        () => ({
            readCount: state.readCount,
            unreadCount: state.unreadCount,
            notifications: state.notifications,
            setNotificationsCount,
            setNotifications,
            deleteNotification,
            pushPreviousNotification
        }),
        [
            state.readCount,
            state.unreadCount,
            state.notifications,
            setNotificationsCount,
            deleteNotification
        ]
    );

    return (
        <NotificationsContext.Provider value={value}>
            <Notifications handleDrawer={handleDrawer} />
        </NotificationsContext.Provider>
    );
};

export default NotificationsState;
