/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { ConfirmationDialogProps } from "./types";

import DialogConfirmation from "../../../../../DesignComponents/DialogConfirmation";
import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

import { useFormattedNumber } from "../../../../../../shared";

const ConfirmationDialog = ({
    isOpen,
    companyName,
    companiesCount,
    usersCount,
    tasksPerGroupCount,
    configurationFilesPerDeviceCount,
    close,
    handleSubmit
}: ConfirmationDialogProps) => {
    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    return (
        <DialogConfirmation
            isOpen={isOpen}
            title={t(
                "Dialog##custom resource limits##confirmation modal title"
            )}
            description={t(
                "Dialog##custom resource limits##confirmation modal description",
                { companyName }
            )}
            isLoading={false}
            onClose={close}
            onSubmit={() => {
                close();
                handleSubmit();
            }}
            confirmBtnTitle={t("Button##continue")}
        >
            <div
                css={css({
                    margin: "8px 0",

                    "& > div + div": {
                        marginTop: "8px"
                    }
                })}
            >
                <div>
                    <Tag
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={t(
                            `Dialog##custom resource limits##count##companies count`,
                            {
                                number: renderFormattedNumber(
                                    Number(companiesCount)
                                )
                            }
                        )}
                    />
                </div>

                <div>
                    <Tag
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={t(
                            `Dialog##custom resource limits##count##users count`,
                            {
                                number: renderFormattedNumber(
                                    Number(usersCount)
                                )
                            }
                        )}
                    />
                </div>

                <div>
                    <Tag
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={t(
                            `Dialog##custom resource limits##count##tasks per group count`,
                            {
                                number: renderFormattedNumber(
                                    Number(tasksPerGroupCount)
                                )
                            }
                        )}
                    />
                </div>

                <div>
                    <Tag
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={t(
                            `Dialog##custom resource limits##count##configuration files per device count`,
                            {
                                number: renderFormattedNumber(
                                    Number(configurationFilesPerDeviceCount)
                                )
                            }
                        )}
                    />
                </div>
            </div>
        </DialogConfirmation>
    );
};

export default ConfirmationDialog;
