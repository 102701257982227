/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { FilterSeparatorProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import { RectangleLoader } from "../../../shared";

const FilterSeparator = ({ width, height }: FilterSeparatorProps) => {
    const {
        colorsFacelift: { gray300 }
    } = useContext(ThemeContext);

    const { isInitialLoading } = useContext(TableContext);

    return isInitialLoading ? (
        <RectangleLoader
            width={width}
            height={height}
            customStyle={{ margin: "0 16px" }}
        />
    ) : (
        <div
            css={css({
                alignSelf: "stretch",
                flexShrink: "0",
                width: width,
                background: gray300,
                margin: "0 16px"
            })}
        />
    );
};

export default FilterSeparator;
