/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import MuiDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { CombinedDialogCustomProps } from "./types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import { useMediaQueries } from "../../../shared";

const DialogCustom = ({
    children,
    noPadding,
    customPadding,
    close,
    ...props
}: CombinedDialogCustomProps) => {
    const { toMd } = useMediaQueries();

    const showPaddingFor599px = () => {
        if (noPadding) {
            return "0";
        }

        if (customPadding) {
            return customPadding;
        }

        return "16px";
    };

    return (
        <MuiDialog
            {...props}
            onClose={close}
            fullScreen={toMd}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: toMd ? "unset" : "684px",
                    minWidth: toMd ? "unset" : "448px",
                    borderRadius: toMd ? "0" : "12px"
                }
            }}
        >
            {close && (
                <Button
                    id="dialogCustomCloseBtn"
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<CloseIcon />}
                    onClick={close}
                    css={css({
                        position: "absolute",
                        top: "10px",
                        right: "24px",
                        zIndex: "1"
                    })}
                />
            )}

            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    flex: "1",
                    overflowY: "auto",
                    padding: noPadding ? "0" : "16px 24px",

                    "@media (max-width: 599px)": {
                        padding: showPaddingFor599px()
                    }
                })}
            >
                {children}
            </div>
        </MuiDialog>
    );
};

export default DialogCustom;
