import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CustomCompanyLevelDialog from "./CustomCompanyLevelDialog";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import AuthContext from "../../context/auth/authContext";
import { COMPANY_TYPES, DialogHandler } from "../../shared";

const CompanyLevel = ({ isOpen, close }: DialogHandler) => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    const isVirtualCompany = user?.company_type === COMPANY_TYPES?.Virtual;

    return (
        <CustomCompanyLevelDialog
            data-testid="company-level-dialog"
            title={t("General##company level")}
            isActionsSeparator
            open={isOpen}
            currentLevel={user?.company_level}
            isVirtualCompany={isVirtualCompany}
            close={close}
            actions={
                <Button
                    data-testid="company-level-close-button"
                    fullWidth
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.TextOnly}
                    onClick={close}
                >
                    {t("Button##close")}
                </Button>
            }
        />
    );
};

export default CompanyLevel;
