import { useContext } from "react";
import { useTranslation } from "react-i18next";

import RootCompany from "./RootCompany";
import CompanySelector from "./CompanySelector";
import { CompanyFilterContentProps } from "../types";

import ControlsItemFrame from "../../../Navbar/Controls/components/ControlsItemFrame";
import { useMediaQueries } from "../../../../shared";
import AuthContext from "../../../../context/auth/authContext";

const CompanyFilterContent = ({
    isLoading,
    close
}: CompanyFilterContentProps) => {
    const { isSupportUser } = useContext(AuthContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    return (
        <div>
            <ControlsItemFrame
                close={close}
                boxPadding={
                    toMd ? "24px 16px 12px 16px" : "16px 16px 12px 16px"
                }
                itemName={t("Filter##companies selector")}
            />

            {isSupportUser && <RootCompany isLoading={isLoading} />}

            <CompanySelector />
        </div>
    );
};

export default CompanyFilterContent;
