import { ReactNode, useMemo, useReducer } from "react";
import { TutorialReducer } from "./tutorialReducer";
import {
    SET_COMPANY_FILTER_OPEN,
    SET_DRAWER_STATUS,
    SET_INITIAL_RENDER,
    SET_STEP_INDEX,
    SET_TUTORIAL_OPEN
} from "./tutorialActions";
import TutorialContext from "./tutorialContext";

import { STEP_GROUP } from "../../components/TutorialMode/types/enums";

interface TutorialStateProps {
    children: ReactNode;
}

const TutorialState = ({ children }: TutorialStateProps) => {
    const initialState = {
        isCompanyFilterOpen: false,
        isDrawerOpen: false,
        isInitialRender:
            localStorage.getItem("tutorialInitialRender") !== "false",
        isTutorialOpen: false,
        isTutorialActive: false,
        stepGroup: STEP_GROUP.TutorialCompanySelector,
        stepIndex: 0
    };

    const [state, dispatch] = useReducer(TutorialReducer, initialState);

    const setTutorialOpen = (
        isOpen: boolean,
        index?: number,
        stepGroup?: STEP_GROUP,
        keepActive?: boolean
    ) =>
        dispatch({
            type: SET_TUTORIAL_OPEN,
            payload: { isOpen, index, stepGroup, keepActive }
        });

    const setCompanyFilterOpen = (isOpen: boolean) =>
        dispatch({
            type: SET_COMPANY_FILTER_OPEN,
            payload: isOpen
        });

    const setDrawerStatus = (isOpen: boolean) =>
        dispatch({
            type: SET_DRAWER_STATUS,
            payload: isOpen
        });

    const setStepIndex = (index: number) =>
        dispatch({
            type: SET_STEP_INDEX,
            payload: index
        });

    const setInitialRender = (render: boolean) =>
        dispatch({
            type: SET_INITIAL_RENDER,
            payload: render
        });

    const value = useMemo(
        () => ({
            isCompanyFilterOpen: state.isCompanyFilterOpen,
            isDrawerOpen: state.isDrawerOpen,
            isInitialRender: state.isInitialRender,
            isTutorialOpen: state.isTutorialOpen,
            isTutorialActive: state.isTutorialActive,
            stepGroup: state.stepGroup,
            stepIndex: state.stepIndex,
            setCompanyFilterOpen,
            setDrawerStatus,
            setStepIndex,
            setTutorialOpen,
            setInitialRender
        }),
        [
            state.isCompanyFilterOpen,
            state.isDrawerOpen,
            state.isInitialRender,
            state.isTutorialOpen,
            state.isTutorialActive,
            state.stepGroup,
            state.stepIndex
        ]
    );

    return (
        <TutorialContext.Provider value={value}>
            {children}
        </TutorialContext.Provider>
    );
};
export default TutorialState;
