/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";

import { TAB_VALUES } from "./types";
import Tabs from "../DesignComponents/Tabs";
import TaskGroupsTabLoader from "../Loaders/TaskGroupsTabLoader";
import ThemeContext from "../../context/theme/themeContext";
import ParamsContext from "../../context/params/paramsContext";
import TableContext from "../../context/table/tableContext";

const TabFilters = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const {
        queryParams: { approvable },
        setQueryParams
    } = useContext(ParamsContext);

    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();

    const getValue = () => {
        if (approvable) {
            return approvable as TAB_VALUES;
        } else {
            return TAB_VALUES.All;
        }
    };

    const [value, setValue] = useState<TAB_VALUES>(getValue());

    useEffect(() => {
        setValue(getValue());
        // eslint-disable-next-line
    }, [approvable]);

    const handleChange = (_: SyntheticEvent, newValue: TAB_VALUES) => {
        setQueryParams({
            params: {
                approvable: newValue === TAB_VALUES.All ? "" : String(newValue)
            }
        });
    };

    return (
        <section>
            {isInitialLoading ? (
                <TaskGroupsTabLoader />
            ) : (
                <div
                    css={css({
                        padding: "8px 24px 0 24px",
                        boxShadow: `inset 0px -1px 0px ${gray200}`
                    })}
                >
                    <Tabs
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                    >
                        <Tab
                            value={TAB_VALUES.All}
                            label={t("Task groups##all")}
                        />

                        <Tab
                            value={TAB_VALUES.PendingApproval}
                            label={t("Task groups##pending approval")}
                        />
                        <Tab
                            value={TAB_VALUES.AlreadyApproved}
                            label={t("Task groups##already approved")}
                        />

                        <Tab
                            value={TAB_VALUES.NoApprovalRequired}
                            label={t("Task groups##no approval required")}
                        />
                    </Tabs>
                </div>
            )}
        </section>
    );
};

export default TabFilters;
