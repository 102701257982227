export const filteredLabels = (
    fromTooltip: boolean,
    statItem: any,
    substringNumber: number
) => {
    const filteredStatItems = statItem.filter(
        (_: any[], index: number) => index <= 4
    );

    return filteredStatItems.map((item: any[], i: number) => {
        let labelArr: string[] = [];

        let trimmedLabel =
            item[0].length >= substringNumber
                ? `${item[0].substring(0, substringNumber)}...`
                : item[0];

        if (statItem.length > 5) {
            if (i < 4) {
                fromTooltip
                    ? labelArr.push(item[0])
                    : labelArr.push(trimmedLabel);
            } else {
                labelArr.push("Other");
            }
        } else if (statItem.length === 5) {
            fromTooltip ? labelArr.push(item[0]) : labelArr.push(trimmedLabel);
        } else {
            labelArr.push(trimmedLabel);
        }

        return labelArr;
    });
};

export const chartAreaBorder = {
    id: "chartAreaBorder",
    beforeDraw(chart: any, _args: any, options: any) {
        const {
            ctx,
            chartArea: { left, top, width, height }
        } = chart;
        ctx.save();
        ctx.strokeStyle = options.borderColor;
        ctx.lineWidth = options.borderWidth;
        ctx.strokeRect(left, top, width, height);
        ctx.restore();
    }
};

export const emptyDoughnut = {
    id: "emptyDoughnut",
    afterDraw(chart: any, _args: any, options: any) {
        const { datasets } = chart.data;

        const { color, width, radiusDecrease } = options;
        let hasData = false;

        let total: number = 0;
        datasets.forEach((dataset: any) =>
            dataset.data.map((item: number) => {
                total = total + item;
                return total;
            })
        );

        if (total > 0) {
            hasData = true;
        }

        if (!hasData) {
            const {
                chartArea: { left, top, right, bottom },
                ctx
            } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.min(right - left, bottom - top) / 2;
            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
            ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
            ctx.stroke();
        }
    }
};

export const formBarChartDataArray = (statItem: any) => {
    let chartDataArray: number[] = [];
    let sumOfOtherItems: number = 0;
    let upToMaxItemsLength = false;
    statItem.map((dataItem: any, index: number) => {
        if (index < 4) {
            chartDataArray.push(dataItem[1].count);
        } else if (index >= 4) {
            upToMaxItemsLength = true;
            sumOfOtherItems = sumOfOtherItems + dataItem[1].count;
        }
        return chartDataArray;
    });
    upToMaxItemsLength && chartDataArray.push(sumOfOtherItems);
    return chartDataArray;
};

export const emptyDeviceStatusItem = {
    stats: [
        [
            "2",
            {
                name: "Online",
                count: 1
            }
        ],
        [
            "1",
            {
                name: "Offline",
                count: 1
            }
        ],
        [
            "0",
            {
                name: "Inactive",
                count: 80
            }
        ]
    ],
    total: 0
};

export const emptyConfigurationStatItem = {
    stats: [
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ]
    ],
    total: 0
};

export const emptyFirmwareStatsItem = {
    stats: [
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ],
        [
            "N/A",
            {
                name: "N/A",
                count: 0
            }
        ]
    ],
    total: 0
};
