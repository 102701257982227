/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useCallback, useState } from "react";

import ReceiveDevice from "./ReceiveDevice/ReceiveDevice";
import MainScreen from "./MainScreen/MainScreen";
import TransferDevices from "./TransferDevices/TransferDevices";

import { MODAL_WINDOW } from "../types";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { maxDialogWidth, useMediaQueries } from "../../../../../shared";

const TransferDevice = ({ isOpen, close }: DialogProps) => {
    const { fromMd } = useMediaQueries();

    const [backdropCSS, setBackdropCSS] = useState("");
    const [modalType, setModalType] = useState<MODAL_WINDOW>(MODAL_WINDOW.Main);
    const [resize, setResize] = useState(false);

    const handleCloseTransferDevice = useCallback(() => {
        setBackdropCSS("");
        close(DEVICE_ACTIONS.TransferDevice);
    }, [close]);

    const handleChangeDialog = (modalName: MODAL_WINDOW) => {
        modalName === MODAL_WINDOW.TransferDevices && setResize(true);
        setModalType(modalName);
    };

    const modalCSS = css(
        {
            "& .MuiDialog-container": {
                transition: backdropCSS
            },

            "& .MuiBackdrop-root": {
                transition: backdropCSS
            }
        },
        !resize && fromMd && maxDialogWidth
    );

    const changeBackdropCSS = (backdropAnimation: string) => {
        setBackdropCSS(backdropAnimation);
    };

    const handleResize = (value: boolean) => {
        setResize(value);
    };

    return (
        <Fragment>
            {modalType === MODAL_WINDOW.Main && (
                <MainScreen
                    isOpen={isOpen}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                    modalCSS={modalCSS}
                    changeBackdrop={changeBackdropCSS}
                />
            )}

            {modalType === MODAL_WINDOW.ReceiveDevices && (
                <ReceiveDevice
                    isOpen={isOpen}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                    modalCSS={modalCSS}
                />
            )}

            {modalType === MODAL_WINDOW.TransferDevices && (
                <TransferDevices
                    isOpen={isOpen}
                    close={handleCloseTransferDevice}
                    changeDialogWindow={handleChangeDialog}
                    modalCSS={modalCSS}
                    removeResize={handleResize}
                />
            )}
        </Fragment>
    );
};
export default TransferDevice;
