import SvgIcon from "@mui/material/SvgIcon";

interface CopyIconProps {
    isLarge?: boolean;
}

const CopyIcon = ({ isLarge, ...props }: CopyIconProps) => {
    return (
        <SvgIcon viewBox={isLarge ? "0 0 16 16" : "0 0 24 24"} {...props}>
            {isLarge ? (
                <path
                    d="M10.0026 0.666626H2.66927C1.93594 0.666626 1.33594 1.26663 1.33594 1.99996V10.6666C1.33594 11.0333 1.63594 11.3333 2.0026 11.3333C2.36927 11.3333 2.66927 11.0333 2.66927 10.6666V2.66663C2.66927 2.29996 2.96927 1.99996 3.33594 1.99996H10.0026C10.3693 1.99996 10.6693 1.69996 10.6693 1.33329C10.6693 0.966626 10.3693 0.666626 10.0026 0.666626ZM12.6693 3.33329H5.33594C4.6026 3.33329 4.0026 3.93329 4.0026 4.66663V14C4.0026 14.7333 4.6026 15.3333 5.33594 15.3333H12.6693C13.4026 15.3333 14.0026 14.7333 14.0026 14V4.66663C14.0026 3.93329 13.4026 3.33329 12.6693 3.33329ZM12.0026 14H6.0026C5.63594 14 5.33594 13.7 5.33594 13.3333V5.33329C5.33594 4.96663 5.63594 4.66663 6.0026 4.66663H12.0026C12.3693 4.66663 12.6693 4.96663 12.6693 5.33329V13.3333C12.6693 13.7 12.3693 14 12.0026 14Z"
                    fill="currentColor"
                />
            ) : (
                <path
                    d="M14.0026 4.6665H6.66927C5.93594 4.6665 5.33594 5.2665 5.33594 5.99984V14.6665C5.33594 15.0332 5.63594 15.3332 6.0026 15.3332C6.36927 15.3332 6.66927 15.0332 6.66927 14.6665V6.6665C6.66927 6.29984 6.96927 5.99984 7.33594 5.99984H14.0026C14.3693 5.99984 14.6693 5.69984 14.6693 5.33317C14.6693 4.9665 14.3693 4.6665 14.0026 4.6665ZM16.6693 7.33317H9.33594C8.6026 7.33317 8.0026 7.93317 8.0026 8.6665V17.9998C8.0026 18.7332 8.6026 19.3332 9.33594 19.3332H16.6693C17.4026 19.3332 18.0026 18.7332 18.0026 17.9998V8.6665C18.0026 7.93317 17.4026 7.33317 16.6693 7.33317ZM16.0026 17.9998H10.0026C9.63594 17.9998 9.33594 17.6998 9.33594 17.3332V9.33317C9.33594 8.9665 9.63594 8.6665 10.0026 8.6665H16.0026C16.3693 8.6665 16.6693 8.9665 16.6693 9.33317V17.3332C16.6693 17.6998 16.3693 17.9998 16.0026 17.9998Z"
                    fill="currentColor"
                />
            )}
        </SvgIcon>
    );
};

export default CopyIcon;
