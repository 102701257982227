/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import FilterDropdown from "../FilterDropdown";

import { FILTER_NAMES } from "../../../shared";

export const TaskGroupFilters = () => {
    const { t } = useTranslation();

    return (
        <div
            css={css({
                "@media (max-width:839px)": {
                    flex: "0.5"
                },

                "@media (max-width:599px)": {
                    flex: "1"
                }
            })}
        >
            <FilterDropdown
                filter={FILTER_NAMES.TaskType}
                placeholder={t(`Filter##${FILTER_NAMES.TaskType}`)}
            />
        </div>
    );
};
