import { useTranslation } from "react-i18next";

import StatsTag from "./StatsTag";

import { DataProps } from "../types";

const SupportStats = ({ data }: DataProps) => {
    const { t } = useTranslation();

    const { transferCompanyInfo } = data;

    const getTitle = (firstPart: string, secondPart?: string | number | null) =>
        `${firstPart}: ${secondPart || "—"}`;

    return (
        <div data-testid="transfer-companies-support-stats">
            <StatsTag
                title={getTitle(t(`Table##code`), transferCompanyInfo?.lzCode)}
            />

            <StatsTag
                title={getTitle(
                    t(`General##vip status`),
                    transferCompanyInfo?.vipStatus
                )}
            />

            <StatsTag
                title={getTitle(
                    t(`General##company type`),
                    transferCompanyInfo?.companyType
                )}
            />

            <StatsTag
                title={getTitle(
                    t(`General##company level`),
                    transferCompanyInfo?.companyLevel
                )}
            />
        </div>
    );
};

export default SupportStats;
