/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";

import AuthContext from "../../../context/auth/authContext";

const BackToSignIn = () => {
    const { toggle2FaVerification } = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <Fragment>
            <div
                css={css({
                    marginBottom: "8px"
                })}
            >
                {t("Auth##account verification description")}
            </div>

            <TextLink
                size={TEXT_LINK_SIZES.Large}
                onClick={() => toggle2FaVerification(false)}
            >
                {t("Auth##back to sign in screen")}
            </TextLink>
        </Fragment>
    );
};

export default BackToSignIn;
