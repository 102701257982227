import {
    TOGGLE_STATISTICS,
    CLOSE_STATISTICS,
    SET_DESKTOP_DRAWER_STATUS,
    SET_MOBILE_DRAWER_STATUS,
    TOGGLE_COMPANY_LEVEL_DIALOG_OPEN
} from "./routesActions";

import { IS_DEVICE_STATISTICS_OPEN } from "../../shared";
import { Action, RoutesInitialState } from "./types";

const RoutesReducer = (
    state: RoutesInitialState,
    action: Action
): RoutesInitialState => {
    switch (action.type) {
        case TOGGLE_STATISTICS: {
            localStorage.setItem(
                IS_DEVICE_STATISTICS_OPEN,
                JSON.stringify(!state.isStatisticsOpen)
            );

            return {
                ...state,
                isStatisticsOpen: !state.isStatisticsOpen
            };
        }
        case CLOSE_STATISTICS: {
            localStorage.setItem(IS_DEVICE_STATISTICS_OPEN, "false");

            return {
                ...state,
                isStatisticsOpen: false
            };
        }
        case SET_DESKTOP_DRAWER_STATUS: {
            return {
                ...state,
                isDesktopDrawerOpen: action.payload
            };
        }
        case SET_MOBILE_DRAWER_STATUS: {
            return {
                ...state,
                isMobileDrawerOpen: action.payload
            };
        }
        case TOGGLE_COMPANY_LEVEL_DIALOG_OPEN: {
            const { isOpen, isScroll } = action.payload;

            return {
                ...state,
                isCompanyLevelDialogOpen: isOpen,
                isCompanyLevelDialogScrollToFaq: isScroll
            };
        }
        default:
            return state;
    }
};

export default RoutesReducer;
