import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const InfoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default InfoIcon;
