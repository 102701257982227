import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Models from "./Models";
import { SortedVehicles, Vehicle, VehicleInfo, VehiclesProps } from "./types";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";
import {
    API_IS_BEING_CANCELLED,
    getFirstLetterUppercase,
    ENDPOINTS,
    useApi,
    useIsMounted,
    DROPDOWN_SIZES
} from "../../../../../../../../shared";

const Vehicles = ({ changeModelId }: VehiclesProps) => {
    const { t } = useTranslation();

    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState<SortedVehicles | null>(null);
    const [makes, setMakes] = useState<string[]>([]);
    const [selectedMake, setSelectedMake] = useState<string | null>(null);

    useEffect(() => {
        const getVehicles = async () => {
            try {
                setLoading(true);

                const { data } = await getData(
                    `${ENDPOINTS.CanAdapters}/vehicles`
                );

                if (isMounted) {
                    const sortVehicles = () => {
                        const allVehicles: Vehicle[] = data.vehicles;

                        const sortedVehicles = allVehicles.reduce<
                            SortedVehicles
                        >(
                            (
                                previous,
                                {
                                    make,
                                    model,
                                    vehicle_id,
                                    year_start,
                                    year_end
                                }
                            ) => {
                                const currentValue = {
                                    vehicle_id,
                                    model: getFirstLetterUppercase(model),
                                    year_start,
                                    year_end
                                };

                                const existingValue =
                                    previous[getFirstLetterUppercase(make)] ||
                                    [];

                                return {
                                    ...previous,
                                    ...(make
                                        ? {
                                              [getFirstLetterUppercase(make)]: [
                                                  ...existingValue,
                                                  currentValue
                                              ]
                                          }
                                        : {})
                                };
                            },
                            {}
                        );

                        setVehicles(sortedVehicles);

                        setMakes(
                            Object.keys(sortedVehicles).sort((a, b) =>
                                a.localeCompare(b)
                            )
                        );
                    };

                    sortVehicles();
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getVehicles();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    const changeMake = (makeName: string) => {
        setSelectedMake(makeName);
        changeModelId(null);
    };

    const handleChangeModel = (vehicleInfo: VehicleInfo) => {
        const modelId = vehicleInfo ? vehicleInfo.vehicle_id : null;
        changeModelId(modelId);
    };

    return (
        <Fragment>
            <Autocomplete
                fullWidth
                isRequired
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("Dialog##make")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                loading={isLoading}
                options={makes}
                onChange={(_, value) => changeMake(value)}
            />

            {vehicles && selectedMake && (
                <Models
                    key={selectedMake}
                    data={vehicles[selectedMake]}
                    change={handleChangeModel}
                />
            )}
        </Fragment>
    );
};

export default Vehicles;
