/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";

import { NewsProps } from "./types";
import TextLink, { TEXT_LINK_SIZES } from "../TextLink";

import ThemeContext from "../../../context/theme/themeContext";

const LinkInNewTab = ({ href, children }: any) => {
    return (
        <TextLink href={href} size={TEXT_LINK_SIZES.Small} target="_blank">
            {children}
        </TextLink>
    );
};

const AccordionCustom = ({
    message,
    index,
    expandedPanel,
    isLast,
    entryDate,
    rootPadding,
    onCollapse
}: NewsProps) => {
    const {
        colorsFacelift: { gray100, gray200, gray300, gray700, textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { title, body } = message;

    const getEntryMonth = (entryDate: string) => {
        const createdAt = new Date(entryDate);
        return createdAt.getMonth();
    };

    const getEntryDay = (entryDate: string) => {
        const entryDay = entryDate.split(" ")[0].split("-")[2];
        return `${parseFloat(entryDay) < 10 ? 0 : ""}${parseFloat(entryDay)}`;
    };

    return (
        <Accordion
            elevation={0}
            data-testid="news-accordion"
            expanded={expandedPanel === `panel${index}`}
            onChange={(_, expanded) => onCollapse(`panel${index}`, expanded)}
            disableGutters
            css={css({
                borderBottom: isLast ? "0" : `1px ${gray200} solid`,

                "&.MuiAccordion-root:before": {
                    backgroundColor: "transparent"
                },

                "& .MuiAccordionSummary-root": {
                    padding: rootPadding
                },

                "& .MuiAccordionSummary-content": {
                    margin: index === 0 ? "0 0 12px 0" : "12px 0"
                }
            })}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                id="accordionHeader"
                key={index}
            >
                <div
                    css={css([
                        {
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",

                            borderRadius: "8px",

                            paddingTop: "6px",
                            paddingBottom: "2px",

                            p: {
                                fontWeight: 600,
                                textTransform: "uppercase"
                            },

                            "p:first-of-type": {
                                fontSize: "10px",
                                lineHeight: "12px",
                                letterSpacing: "1px",

                                paddingLeft: "8px",
                                paddingRight: "8px"
                            },

                            "p:last-of-type": {
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px"
                            }
                        },
                        entryDate && {
                            minWidth: "60px",
                            border: `1px solid ${gray300}`,
                            background: gray100,
                            color: textDark
                        }
                    ])}
                >
                    {entryDate && (
                        <>
                            <Typography>
                                {t(
                                    `Welcome dashboard##newsMonth##${getEntryMonth(
                                        entryDate
                                    )}`
                                )}
                            </Typography>
                            <Typography>{getEntryDay(entryDate)}</Typography>
                        </>
                    )}
                </div>
                <div
                    css={css({
                        paddingLeft: "8px",
                        display: "flex",
                        alignItems: "center",

                        p: {
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            color: textDark
                        }
                    })}
                >
                    <Typography>{title}</Typography>
                </div>
            </AccordionSummary>

            <AccordionDetails
                css={css({
                    "span, a": {
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        margin: 0
                    },

                    span: { color: gray700 },

                    strong: {
                        fontWeight: 700
                    },

                    "h1, h2, h3, h4, h5, h6": {
                        fontSize: "14px",
                        fontWeight: 600,
                        margin: "revert"
                    },

                    ul: {
                        marginBlock: "1em",
                        marginInline: "0px",
                        paddingInlineStart: "40px"
                    },

                    li: {
                        listStyle: "unset"
                    }
                })}
            >
                <Typography component="span">
                    <ReactMarkdown
                        children={body}
                        remarkPlugins={[remarkGfm]}
                        components={{ a: LinkInNewTab }}
                    />
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export * from "./types";

export default AccordionCustom;
