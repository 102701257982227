/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "../../../../../context/theme/themeContext";
import NoResultsFoundIcon from "../../../../../assets/customIcons/emptyTable/NoResultsFoundIcon";

const NoNotifications = () => {
    const {
        colorsFacelift: { gray700, textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "32px"
            })}
        >
            <NoResultsFoundIcon
                css={css({
                    fontSize: "120px"
                })}
            />

            <div
                css={css({
                    color: textDark,
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    margin: "16px 0 8px 0"
                })}
            >
                {t("Notifications##no notifications")}
            </div>

            <div
                css={css({
                    color: gray700,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.1px"
                })}
            >
                {t(`Notifications##notifications will be listed`)}
            </div>
        </div>
    );
};

export default NoNotifications;
