import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ResendInvitationIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M9.9 19C8.28333 19 6.896 18.475 5.738 17.425C4.57933 16.375 4 15.0667 4 13.5C4 11.9334 4.57933 10.625 5.738 9.57505C6.896 8.52505 8.28333 8.00005 9.9 8.00005H16.2L14.3 6.10005C14.1167 5.91672 14.025 5.68338 14.025 5.40005C14.025 5.11672 14.1167 4.88338 14.3 4.70005C14.4833 4.51672 14.7167 4.42505 15 4.42505C15.2833 4.42505 15.5167 4.51672 15.7 4.70005L19.3 8.30005C19.4 8.40005 19.471 8.50838 19.513 8.62505C19.5543 8.74172 19.575 8.86672 19.575 9.00005C19.575 9.13338 19.5543 9.25838 19.513 9.37505C19.471 9.49172 19.4 9.60005 19.3 9.70005L15.7 13.3C15.5167 13.4834 15.2833 13.575 15 13.575C14.7167 13.575 14.4833 13.4834 14.3 13.3C14.1167 13.1167 14.025 12.8834 14.025 12.6C14.025 12.3167 14.1167 12.0834 14.3 11.9L16.2 10H9.9C8.85 10 7.93767 10.3334 7.163 11C6.38767 11.6667 6 12.5 6 13.5C6 14.5 6.38767 15.3334 7.163 16C7.93767 16.6667 8.85 17 9.9 17H16C16.2833 17 16.5207 17.096 16.712 17.288C16.904 17.4794 17 17.7167 17 18C17 18.2834 16.904 18.5207 16.712 18.712C16.5207 18.904 16.2833 19 16 19H9.9Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ResendInvitationIcon;
