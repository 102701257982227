import { useTranslation } from "react-i18next";
import { useContext } from "react";

import { useDialog } from "./hooks";
import ContentFrame from "./components/ContentFrame";

import CreateGroup from "../dialogs/DeviceGroups/CreateGroup/CreateGroup";
import { GROUP_ACTIONS } from "../types";

import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

const DeviceGroupsBar = () => {
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    return (
        <>
            <ContentFrame>
                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={() => openDialog(GROUP_ACTIONS.CreateGroup)}
                    >
                        {t("Button##create")}
                    </Button>
                </ActionToolbarButtonWrapper>
            </ContentFrame>

            <CreateGroup
                isOpen={isOpenDialog[GROUP_ACTIONS.CreateGroup]}
                close={closeDialog}
            />
        </>
    );
};

export default DeviceGroupsBar;
