export const dummyCompaniesData = [
    {
        current_page: 1,
        from: 1,
        to: 25,
        total: 8626,
        first_page: 1,
        last_page: 346,
        per_page: 25,
        data: [
            {
                id: 101,
                name: "Teltonika",
                company_id: 1,
                deleted_at: null,
                created_at: "2017-08-25 11:05:53",
                updated_at: "2022-03-08 09:38:18",
                created_by: {
                    id: 2407,
                    email: "ignas.galuskinas@teltonika.lt",
                    company_id: 101,
                    role: 20,
                    table_refresh: 0,
                    language: "en-US",
                    invited_by: null,
                    deleted_at: null,
                    created_at: "2019-04-04 12:25:34",
                    updated_at: "2022-02-24 12:18:52",
                    last_login: "2022-12-27 11:40:18",
                    type: null
                },
                updated_by: {
                    id: 2185,
                    email: "agnius.steponavicius@teltonika.lt",
                    company_id: 101,
                    role: 0,
                    table_refresh: 0,
                    language: "en-US",
                    invited_by: null,
                    deleted_at: null,
                    created_at: "2019-03-07 07:24:17",
                    updated_at: "2020-12-30 20:48:54",
                    last_login: "2020-12-30 13:13:41",
                    type: null
                },
                code: null,
                company: {
                    code: null,
                    id: 1,
                    name: "Root",
                    company_id: null,
                    deleted_at: null,
                    created_at: "2020-07-31 05:47:47",
                    updated_at: "2020-12-02 10:06:29",
                    created_by: null,
                    updated_by: null
                }
            }
        ],
        first_page_url: `${process.env.REACT_APP_BASE_URL}/companies?page=1`,
        next_page_url: `${process.env.REACT_APP_BASE_URL}/companies?page=2`,
        prev_page_url: null,
        last_page_url: `${process.env.REACT_APP_BASE_URL}/companies?page=346`
    }
];
