/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { INPUT_FIELD_SIZES, InputFieldProps } from "./types";

import Label from "../Label";
import { TOOLTIP_PLACEMENT, TooltipLarge } from "../Tooltips";
import ThemeContext from "../../../context/theme/themeContext";
import { muiOutlinedInputDefaultStylesOverrides } from "../../../shared";

const InputField = ({
    size,
    labelLeft,
    labelIcon,
    labelRight,
    iconLeft,
    iconRight,
    readOnly,
    isLoading,
    tooltipProps,
    errorText,
    customStyle,
    ...props
}: InputFieldProps & Omit<TextFieldProps, "size">) => {
    const {
        colorsFacelift: {
            blue700,
            blue200,
            gray100,
            gray300,
            gray400,
            gray500,
            gray700,
            red100,
            red700,
            red800,
            textDark,
            textDarkDisabled,
            textPlaceholder,
            white
        }
    } = useContext(ThemeContext);

    const paddings = {
        [INPUT_FIELD_SIZES.Tiny]: "2px 12px",
        [INPUT_FIELD_SIZES.Small]: "8px 12px",
        [INPUT_FIELD_SIZES.Medium]: "12px"
    };

    const padding = paddings[size];

    const circularProgressSize = size === INPUT_FIELD_SIZES.Tiny ? 12 : 16;

    const repeatedStyles = muiOutlinedInputDefaultStylesOverrides({
        blue700,
        blue200,
        red700,
        red100,
        gray300,
        textDarkDisabled,
        textPlaceholder
    });

    return (
        <div
            css={css({
                display: "inline-flex",
                flexDirection: "column",
                width: props.fullWidth ? "100%" : "unset",
                ...customStyle
            })}
        >
            {(labelLeft || labelRight) && (
                <Label
                    titleLeft={labelLeft}
                    titleRight={labelRight}
                    titleTooltip={labelIcon}
                    isRequired={props.required}
                />
            )}

            <TooltipLarge
                {...tooltipProps}
                title={tooltipProps?.title || ""}
                placement={TOOLTIP_PLACEMENT.Top}
            >
                <TextField
                    {...props}
                    error={Boolean(errorText)}
                    autoComplete={props.autoComplete || "off"}
                    helperText={
                        errorText ? (
                            <Fragment>
                                <ErrorIcon
                                    css={css({
                                        color: red700,
                                        fontSize: "16px",
                                        margin: "2px 4px 0 0",
                                        alignSelf: "flex-start"
                                    })}
                                />

                                {errorText}
                            </Fragment>
                        ) : (
                            ""
                        )
                    }
                    InputProps={{
                        ...props.InputProps,
                        spellCheck: "false",
                        readOnly,
                        ...(iconLeft && {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {iconLeft}
                                </InputAdornment>
                            )
                        }),
                        ...((iconRight || isLoading) && {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading && (
                                        <div
                                            css={css({
                                                display: "flex",
                                                width: "28px",
                                                height: "28px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginRight: iconRight
                                                    ? "8px"
                                                    : "0"
                                            })}
                                        >
                                            <CircularProgress
                                                size={circularProgressSize}
                                                sx={{ color: gray500 }}
                                            />
                                        </div>
                                    )}

                                    {iconRight ? iconRight : null}
                                </InputAdornment>
                            )
                        }),
                        sx: [
                            {
                                background:
                                    props.disabled || readOnly
                                        ? gray100
                                        : white,
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                borderRadius: "6px",
                                padding,

                                "& input": {
                                    transitionDelay: "9999s",
                                    transitionProperty: "background-color"
                                },

                                "&:hover": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: gray400
                                    },

                                    "&.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: red800
                                        },

                                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor: gray300
                                        }
                                },

                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: gray300
                                }
                            },
                            repeatedStyles
                        ]
                    }}
                    sx={{
                        "& .MuiFormHelperText-root": {
                            margin: "6px 0 0",
                            display: "inline-flex",
                            alignItems: "center",
                            color: gray700,
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "600",
                            letterSpacing: "0.1px",

                            "&.Mui-error": {
                                color: gray700
                            }
                        }
                    }}
                />
            </TooltipLarge>
        </div>
    );
};

export default InputField;
