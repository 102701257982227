import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogOutIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M5 6L13 6C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4L5 4C3.9 4 3 4.9 3 6L3 18C3 19.1 3.9 20 5 20L13 20C13.55 20 14 19.55 14 19C14 18.45 13.55 18 13 18L5 18L5 6Z"
                fill="currentColor"
            />

            <path
                d="M15.7141 9.09973C15.3241 8.70973 15.3241 8.08973 15.7141 7.69973C16.1041 7.30973 16.7241 7.30973 17.1141 7.69973L20.7041 11.2997C21.0941 11.6897 21.0941 12.3197 20.7041 12.7097L17.1141 16.2997C16.7241 16.6897 16.1041 16.6897 15.7141 16.2997C15.3241 15.9097 15.3241 15.2897 15.7141 14.8997L17.6141 12.9997H10.4141C9.86406 12.9997 9.41406 12.5497 9.41406 11.9997C9.41406 11.4497 9.86406 10.9997 10.4141 10.9997H17.6141L15.7141 9.09973Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default LogOutIcon;
