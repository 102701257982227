/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect } from "react";

import ToggleDrawerButton from "./ToggleDrawerButton/ToggleDrawerButton";
import { DesktopDrawerProps } from "./types";

import Navigation from "../Navigation";

import { STEP_GROUP } from "../../TutorialMode/types/enums";

import ThemeContext from "../../../context/theme/themeContext";
import RoutesContext from "../../../context/routes/routesContext";
import TutorialContext from "../../../context/tutorial/tutorialContext";
import { DESKTOP_DRAWER_OPEN, useMediaQueries } from "../../../shared";

const DesktopDrawer = ({ topBannersHeight }: DesktopDrawerProps) => {
    const {
        colorsFacelift: { blue700, gray50, gray200 }
    } = useContext(ThemeContext);

    const { to1099 } = useMediaQueries();

    const { stepGroup, stepIndex } = useContext(TutorialContext);

    const { isDesktopDrawerOpen, setDesktopDrawerStatus } =
        useContext(RoutesContext);

    useEffect(() => {
        to1099 && handleDrawerStatus(false);
        // eslint-disable-next-line
    }, [to1099]);

    const handleDrawerStatus = (isOpen: boolean) => {
        setDesktopDrawerStatus(isOpen);
        localStorage.setItem(DESKTOP_DRAWER_OPEN, `${isOpen}`);
    };

    const getTopDistance = () => {
        const height = (topBannersHeight ?? 0) + 56;
        return `${height}px`;
    };

    const isTaskGroupsTutorialActive =
        stepGroup === STEP_GROUP.TutorialTaskGroups && stepIndex === 0;

    return (
        <aside
            id="desktopDrawer"
            css={css({
                position: "fixed",
                top: getTopDistance(),
                bottom: "0",
                zIndex: "1200",
                background: gray50,
                borderRight: `1px solid ${gray200}`,
                padding: "18px 15px 18px 16px",
                width: isDesktopDrawerOpen ? "204px" : "36px",
                transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "#tasks": {
                    boxShadow: isTaskGroupsTutorialActive
                        ? `0 0 0 2px ${blue700} `
                        : "",
                    borderRadius: isTaskGroupsTutorialActive ? "8px" : ""
                }
            })}
        >
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%"
                })}
            >
                <Navigation />

                {!to1099 && (
                    <ToggleDrawerButton
                        isdrawerOpen={isDesktopDrawerOpen}
                        onOpendrawer={() => handleDrawerStatus(true)}
                        onDrawerClose={() => handleDrawerStatus(false)}
                    />
                )}
            </div>
        </aside>
    );
};

export default DesktopDrawer;
