import { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EnquiryText from "./EnquiryText";
import AuthContainer from "../AuthContainer";
import { ResetLinkProp } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import { ROUTES } from "../../../shared";

const ResetSent = ({ redirect }: ResetLinkProp) => {
    const { t } = useTranslation();

    const [toLogin, setToLogin] = useState(false);

    const handleClick = useCallback(() => setToLogin(true), []);

    return toLogin ? (
        <Navigate to={{ pathname: ROUTES.Login }} />
    ) : (
        <AuthContainer
            formName={t("Auth##link sent")}
            secondaryText={t("Auth##check email")}
        >
            <Button
                data-testid="resend-psw-signIn-button"
                fullWidth
                color={BUTTON_COLORS.Primary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                type="submit"
                onClick={handleClick}
            >
                {t("Auth##sign in")}
            </Button>

            <EnquiryText
                primaryText={t("Auth##did not receive")}
                linkText={t("Auth##resend")}
                onClick={redirect}
            />
        </AuthContainer>
    );
};

export default ResetSent;
