/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Trans } from "react-i18next";

import { SampleTemplateProps } from "./types";
import TextLink, { TEXT_LINK_SIZES } from "../TextLink";
import ThemeContext from "../../../context/theme/themeContext";

const SampleTemplate = ({ fileName }: SampleTemplateProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                color: gray700,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                margin: "16px 0"
            })}
        >
            <Trans i18nKey="Dialog##download sample file message">
                Download a
                <TextLink
                    data-testid="file-sample-template-link"
                    size={TEXT_LINK_SIZES.Normal}
                    href={`/static/${fileName}.csv`}
                    download
                >
                    sample CSV template
                </TextLink>
                to see an example of the format required.
            </Trans>
        </div>
    );
};

export default SampleTemplate;
