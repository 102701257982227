/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import TctWebIcon from "@mui/icons-material/WebOutlined";

import TctWebDialog from "./TctWebDialog";

import {
    TOOLTIP_PLACEMENT,
    TooltipOptional
} from "../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../context/theme/themeContext";
import AuthContext from "../../../../context/auth/authContext";
import RoutesContext from "../../../../context/routes/routesContext";
import { isInternalOrPremiumLevel } from "../../../../shared";

const TctWeb = () => {
    const {
        colorsFacelift: { gray100, gray200, gray700, textDarkDisabled }
    } = useContext(ThemeContext);

    const { user } = useContext(AuthContext);

    const { isDesktopDrawerOpen, isMobileDrawerOpen } =
        useContext(RoutesContext);

    const { t } = useTranslation();

    const [isDialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const isEnabled = isInternalOrPremiumLevel(user?.company_level);

    return (
        <>
            <li>
                <TooltipOptional
                    isHidden={
                        isEnabled && (isDesktopDrawerOpen || isMobileDrawerOpen)
                    }
                    title={
                        isEnabled
                            ? t("Tct##tct web")
                            : t("Tct##tct web disabled description")
                    }
                    placement={TOOLTIP_PLACEMENT.Right}
                    disableTouchListener
                >
                    <div>
                        <div
                            className="navigation-item"
                            onClick={openDialog}
                            css={css({
                                color: isEnabled ? gray700 : textDarkDisabled,
                                pointerEvents: isEnabled ? "auto" : "none",
                                cursor: "pointer",

                                "& > span": {
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px",
                                    marginLeft: "8px",
                                    whiteSpace: "nowrap"
                                },

                                "&:hover": {
                                    backgroundColor: gray100
                                },

                                "&:active": {
                                    backgroundColor: gray200
                                }
                            })}
                        >
                            <TctWebIcon />
                            <span>{t("Tct##tct web")}</span>
                        </div>
                    </div>
                </TooltipOptional>
            </li>

            <TctWebDialog isOpen={isDialogOpen} close={closeDialog} />
        </>
    );
};

export default TctWeb;
