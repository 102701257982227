import FindToolContent from "./components/FindToolContent";

import DialogCustom from "../../DesignComponents/DialogCustom";

import { DialogHandler } from "../../../shared";

const FindToolMobile = ({ isOpen, close }: DialogHandler) => {
    return (
        <DialogCustom noPadding open={isOpen} close={close}>
            <FindToolContent close={close} />
        </DialogCustom>
    );
};

export default FindToolMobile;
