import { useTranslation } from "react-i18next";

import { ModelsProps, VehicleInfo } from "./types";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";
import { DROPDOWN_SIZES } from "../../../../../../../../shared";

const Models = ({ data, change }: ModelsProps) => {
    const { t } = useTranslation();

    const renderOption = (option: VehicleInfo) => {
        const { model, year_start, year_end } = option;

        const yearEnd = year_end ? `-${year_end}` : "+";
        const year = `(${year_start}${yearEnd})`;

        return `${model} ${year}`;
    };

    const sortedData = [...data].sort((a, b) => a.model.localeCompare(b.model));

    return (
        <Autocomplete
            fullWidth
            isRequired
            size={DROPDOWN_SIZES.Medium}
            labelLeft={t("Dialog##vehicle model")}
            textFieldParams={{
                placeholder: t("Dialog##select")
            }}
            options={sortedData}
            getOptionLabel={option => renderOption(option)}
            renderOption={(props, option) => (
                <li {...props} key={option.vehicle_id}>
                    {renderOption(option)}
                </li>
            )}
            onChange={(_, value) => change(value)}
            customStyle={{
                marginTop: "16px"
            }}
        />
    );
};

export default Models;
