import { useContext } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { useFilterButton } from "./hooks";
import { FilterButtonProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../DesignComponents/Button";

import TableContext from "../../../../context/table/tableContext";
import { RectangleLoader } from "../../../../shared";

const FilterButton = ({
    children,
    onOpen,
    customLoadingWidth
}: FilterButtonProps) => {
    const { isInitialLoading } = useContext(TableContext);

    const { isIconOnly, getLeftMargin, getRightMargin, getRectangularWidth } =
        useFilterButton(customLoadingWidth);

    return isInitialLoading ? (
        <RectangleLoader
            width={getRectangularWidth()}
            height={24}
            customStyle={{
                marginLeft: getLeftMargin(),
                marginRight: getRightMargin()
            }}
        />
    ) : (
        <Button
            color={BUTTON_COLORS.White}
            size={BUTTON_SIZES.Small}
            variant={
                isIconOnly ? BUTTON_VARIANTS.IconOnly : BUTTON_VARIANTS.IconLeft
            }
            icon={<FilterAltOutlinedIcon />}
            onClick={onOpen}
        >
            {children}
        </Button>
    );
};

export default FilterButton;
