import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useDialog } from "./hooks";
import ContentFrame from "./components/ContentFrame";

import { USER_ACTIONS } from "../types";
import InviteUser from "../dialogs/Users/InviteUser/InviteUser";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";
import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

const UsersBar = () => {
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    return (
        <Fragment>
            <ContentFrame>
                <ActionToolbarButtonWrapper>
                    <Button
                        fullWidth={toSm}
                        hasRectangleLoader={toSm && isInitialLoading}
                        variant={BUTTON_VARIANTS.TextOnly}
                        size={BUTTON_SIZES.Small}
                        color={BUTTON_COLORS.Primary}
                        onClick={() => openDialog(USER_ACTIONS.InviteUser)}
                    >
                        {t("Button##invite user")}
                    </Button>
                </ActionToolbarButtonWrapper>
            </ContentFrame>

            <InviteUser
                isOpen={isOpenDialog[USER_ACTIONS.InviteUser]}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default UsersBar;
