/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, { useContext } from "react";

import AuthContext from "../../context/auth/authContext";
import RoutesContext from "../../context/routes/routesContext";
import { TABLE_NAMES, useMediaQueries, useWindowLocation } from "../../shared";

interface MainContainerProps {
    children: React.ReactNode;
}

const MainContainer = ({ children, ...props }: MainContainerProps) => {
    const { isSupportUser } = useContext(AuthContext);
    const { isStatisticsOpen } = useContext(RoutesContext);

    const { isDesktopDrawerOpen } = useContext(RoutesContext);
    const { resource } = useWindowLocation();
    const { toMd, toLg } = useMediaQueries();

    const getMarginRight = () => {
        if (isSupportUser || toLg || !isStatisticsOpen) {
            return "0";
        }

        return resource === TABLE_NAMES.Devices ? "236px" : "0";
    };

    const getMarginLeft = () => {
        if (toMd) {
            return "0";
        }
        if (!isDesktopDrawerOpen) {
            return "68px";
        }
        return "236px";
    };
    return (
        <main
            {...props}
            className="remaining-height"
            css={css({
                marginLeft: getMarginLeft(),
                marginRight: getMarginRight(),
                transition:
                    "margin-right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            })}
        >
            {children}
        </main>
    );
};

export default MainContainer;
