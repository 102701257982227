import { useState } from "react";
import EditIcon from "@mui/icons-material/ModeEdit";

import {
    RenderConditionOptions,
    isAllowedToEditCompany
} from "../../../../shared";

const useCompanyInlineActions = () => {
    const [rowData, setRowData] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const openDialog = (data: any) => {
        setRowData(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setRowData(null);
        setOpen(false);
    };

    const inlineActions = [
        {
            title: "edit company",
            icon: EditIcon,
            clickAction: (data: any) => openDialog(data),
            renderConditions: ({
                data,
                isSystemUser
            }: RenderConditionOptions) =>
                isAllowedToEditCompany(isSystemUser, data.type)
        }
    ];

    return {
        inlineActions,
        rowData,
        isOpen,
        closeDialog
    };
};

export default useCompanyInlineActions;
