/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { AlertProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import ThemeContext from "../../../context/theme/themeContext";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";
import CriticalIcon from "../../../assets/customIcons/status/CriticalIcon";
import WarningIcon from "../../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../../assets/customIcons/status/SuccessIcon";

const Alert = ({
    status,
    title,
    description,
    primaryBtn,
    secondaryBtn,
    close
}: AlertProps) => {
    const {
        colorsFacelift: {
            white,
            blue700,
            gray700,
            green600,
            red700,
            orange600,
            textDark
        }
    } = useContext(ThemeContext);

    const styleOptions = {
        info: {
            border: blue700,
            icon: (
                <InfoIcon
                    css={css({
                        color: blue700
                    })}
                />
            )
        },
        critical: {
            border: red700,
            icon: <CriticalIcon />
        },
        warning: {
            border: orange600,
            icon: <WarningIcon />
        },
        success: {
            border: green600,
            icon: <SuccessIcon />
        }
    };

    const borderColor = styleOptions[status].border;
    const iconImage = styleOptions[status].icon;

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                padding: "12px 12px 12px 8px",
                backgroundColor: white,
                borderLeft: `4px solid ${borderColor}`,
                borderRadius: "4px",
                wordBreak: "break-word",
                boxShadow:
                    "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 3px rgba(0, 0, 0, 0.1)",

                "& > svg:first-of-type": {
                    alignSelf: "flex-start",
                    marginRight: "8px"
                }
            })}
        >
            {iconImage}

            <div
                css={css({
                    flex: "1",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                    lineHeight: "20px"
                })}
            >
                <div
                    css={css({
                        color: textDark,
                        fontWeight: "600"
                    })}
                >
                    {title}
                </div>

                {description && (
                    <div
                        css={css({
                            color: gray700,
                            marginTop: "4px"
                        })}
                    >
                        {description}
                    </div>
                )}

                {(primaryBtn || secondaryBtn) && (
                    <div
                        css={css({
                            display: "flex",
                            columnGap: "8px",
                            marginTop: "12px"
                        })}
                    >
                        {primaryBtn && (
                            <Button
                                fullWidth
                                variant={BUTTON_VARIANTS.TextOnly}
                                color={BUTTON_COLORS.Primary}
                                size={BUTTON_SIZES.Small}
                                onClick={primaryBtn.action}
                            >
                                {primaryBtn.label}
                            </Button>
                        )}

                        {secondaryBtn && (
                            <Button
                                fullWidth
                                variant={BUTTON_VARIANTS.TextOnly}
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Small}
                                onClick={secondaryBtn.action}
                            >
                                {secondaryBtn.label}
                            </Button>
                        )}
                    </div>
                )}
            </div>

            {close && (
                <CloseIcon
                    onClick={close}
                    css={css({
                        alignSelf: "flex-start",
                        fontSize: "16px",
                        cursor: "pointer",
                        margin: "-4px -4px 0 8px"
                    })}
                />
            )}
        </div>
    );
};

export default Alert;
