import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { DeviceTransferModalProps, MODAL_WINDOW } from "../../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

import Dialog from "../../../../../DesignComponents/Dialog";

import ReceiveDevicesIcon from "../../../../../../assets/customIcons/actions/ReceiveDevicesIcon";
import TransferDevicesIcon from "../../../../../../assets/customIcons/actions/TransferDevicesIcon";
import { useMediaQueries } from "../../../../../../shared";

const MainScreen = ({
    isOpen,
    close,
    changeDialogWindow,
    modalCSS,
    changeBackdrop
}: DeviceTransferModalProps) => {
    const { t } = useTranslation();

    const { toMd } = useMediaQueries();

    return (
        <Dialog
            data-testid="transfer-devices-main-screen"
            title={t("Dialog##device transfer##main##title")}
            description={t("Dialog##device transfer##main##description")}
            isActionsSeparator
            open={isOpen}
            close={close}
            actions={
                <Fragment>
                    <Button
                        data-testid="switch-to-transfer-devices"
                        fullWidth
                        color={BUTTON_COLORS.PrimarySubtle}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.IconLeft}
                        icon={<TransferDevicesIcon />}
                        onClick={() =>
                            changeDialogWindow(MODAL_WINDOW.TransferDevices)
                        }
                    >
                        {t("Button##transfer my devices")}
                    </Button>

                    <Button
                        data-testid="switch-to-receive-devices"
                        fullWidth
                        color={BUTTON_COLORS.PrimarySubtle}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.IconLeft}
                        icon={<ReceiveDevicesIcon />}
                        onClick={() =>
                            changeDialogWindow(MODAL_WINDOW.ReceiveDevices)
                        }
                    >
                        {t("Button##receive devices")}
                    </Button>
                </Fragment>
            }
            css={modalCSS}
            extendToMaxWidth={toMd}
            TransitionProps={{
                onEntered: () => {
                    changeBackdrop && changeBackdrop("none !important");
                }
            }}
        />
    );
};
export default MainScreen;
