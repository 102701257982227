import {
    COLUMN_NAMES,
    COLUMN_RESOURCES,
    Column,
    SORT_RESOURCES
} from "../../shared";

export const dummyCsvFileSample = {
    path: "sample_import_list.csv",
    name: "sample_import_list.csv",
    lastModified: 1678436513175
};

export const dummyTableColumns: Column[] = [
    {
        name: COLUMN_NAMES.Imei,
        resource: COLUMN_RESOURCES.Imei,
        sortResource: SORT_RESOURCES.Imei,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Status,
        resource: COLUMN_RESOURCES.DeviceStatusIcon,
        isBadge: true,
        isDefaultActive: true,
        isDisabled: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Queue,
        resource: COLUMN_RESOURCES.QueueIcon,
        isBadge: true,
        isDefaultActive: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Description,
        resource: COLUMN_RESOURCES.Description,
        sortResource: SORT_RESOURCES.Description,
        isDefaultActive: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Firmware,
        resource: COLUMN_RESOURCES.CurrentFirmware,
        sortResource: SORT_RESOURCES.CurrentFw,
        isDefaultActive: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Configuration,
        resource: COLUMN_RESOURCES.CurrentConfiguration,
        sortResource: SORT_RESOURCES.CurrentCfg,
        isDefaultActive: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Model,
        resource: COLUMN_RESOURCES.Model,
        sortResource: SORT_RESOURCES.Model,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Serial,
        resource: COLUMN_RESOURCES.Serial,
        sortResource: SORT_RESOURCES.Serial,
        isActive: true
    },
    {
        name: COLUMN_NAMES.GsmNumber,
        resource: COLUMN_RESOURCES.GsmNumber,
        isActive: false
    },
    {
        name: COLUMN_NAMES.Iccid,
        resource: COLUMN_RESOURCES.Iccid,
        isActive: false
    },
    {
        name: COLUMN_NAMES.Imsi,
        resource: COLUMN_RESOURCES.Imsi,
        isActive: false
    },
    {
        name: COLUMN_NAMES.ProductCode,
        resource: COLUMN_RESOURCES.ProductCode,
        isActive: false
    },
    {
        name: COLUMN_NAMES.Company,
        resource: COLUMN_RESOURCES.CompanyName,
        sortResource: SORT_RESOURCES.CompanyName,
        isActive: true
    },
    {
        name: COLUMN_NAMES.Group,
        resource: COLUMN_RESOURCES.GroupName,
        sortResource: SORT_RESOURCES.GroupName,
        isActive: false
    },
    {
        name: COLUMN_NAMES.SeenAt,
        resource: COLUMN_RESOURCES.SeenAt,
        sortResource: SORT_RESOURCES.SeenAt,
        isDefaultActive: true,
        isActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isActive: false
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        sortResource: SORT_RESOURCES.UpdatedAt,
        isActive: false
    }
];

export const dummySingleBarChartData = [
    [
        "Unknown",
        {
            name: "Unknown",
            count: 4
        }
    ],
    [
        "FMB125_BMW.cfg",
        {
            name: "FMB125_BMW.cfg",
            count: 1
        }
    ]
];
