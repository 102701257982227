/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SetContent } from "./index";
import GoBack from "./GoBack";
import Autocomplete from "../../DesignComponents/Autocomplete";
import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import { DROPDOWN_SIZES } from "../../../shared";

interface RowsPerPageProps {
    setContent: SetContent;
}

type Option = "25" | "50" | "100";

const RowsPerPage = ({ setContent }: RowsPerPageProps) => {
    const { setQueryParams } = useContext(ParamsContext);

    const {
        isInnerTable,
        totalRows,
        rowsPerPage,
        setInnerTableParams
    } = useContext(TableContext);

    const { t } = useTranslation();

    const options: Option[] =
        totalRows > 50 ? ["25", "50", "100"] : ["25", "50"];

    const handleChange = (value: Option) => {
        const payload = { per_page: value };

        (isInnerTable ? setInnerTableParams : setQueryParams)({
            params: payload
        });
    };

    return (
        <Fragment>
            <GoBack setContent={setContent} />

            <ul
                css={css({
                    padding: "6px 18px 14px 16px"
                })}
            >
                <li>
                    <Autocomplete
                        fullWidth
                        disableClearable
                        size={DROPDOWN_SIZES.Medium}
                        labelLeft={t("Table##rows per page")}
                        options={options}
                        value={String(rowsPerPage)}
                        onChange={(_, value) => handleChange(value)}
                    />
                </li>
            </ul>
        </Fragment>
    );
};

export default RowsPerPage;
