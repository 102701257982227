import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const TableViewIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_4673_161382)">
                <g clipPath="url(#clip1_4673_161382)">
                    <path
                        d="M3 5V19H21V5H3ZM8.33 17H5V7H8.33V17ZM13.67 17H10.34V7H13.67V17ZM19 17H15.67V7H19V17Z"
                        fill="currentColor"
                    />
                </g>
            </g>

            <defs>
                <clipPath>
                    <rect width="24" height="24" fill={white} />
                </clipPath>

                <clipPath>
                    <rect width="24" height="24" fill={white} />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default TableViewIcon;
