import { useContext } from "react";

import Table from "../../../Table";
import useTaskInlineActions from "../../../Actions/InlineActions/hooks/useTaskInlineActions";
import TaskDetails from "../../../Actions/dialogs/Tasks/TaskDetails";

import TableContext from "../../../../context/table/tableContext";
import {
    ID_TYPE,
    SORT_RESOURCES,
    TABLE_NAMES,
    DEVICE_DETAILS_TASK_QUEUE_COLUMNS as COLUMNS,
    ORDERS,
    TABLE_NAMES_WITH_FILTERS,
    NOT_FILTER_NAMES,
    FILTER_NAMES
} from "../../../../shared";

const TaskQueue = () => {
    const { detailsData } = useContext(TableContext);

    const { inlineActions, taskData, isOpen, closeDialog, setTaskData } =
        useTaskInlineActions();

    const { imei } = detailsData;

    return (
        <>
            <Table
                name={TABLE_NAMES.Tasks}
                nameForFilters={TABLE_NAMES_WITH_FILTERS.DeviceDetailTasks}
                isInnerTable
                hideSearch
                initialColumns={COLUMNS}
                inlineActions={inlineActions}
                idType={ID_TYPE.Id}
                innerTableQueryParams={{
                    [FILTER_NAMES.DeviceImei]: imei,
                    [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
                    [NOT_FILTER_NAMES.Sort]: SORT_RESOURCES.CreatedAt
                }}
            />

            <TaskDetails
                data={taskData}
                isOpen={isOpen}
                close={closeDialog}
                setTaskData={setTaskData}
            />
        </>
    );
};

export default TaskQueue;
