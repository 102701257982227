import { SUPPORT_USER } from "./userRoles";

import {
    COLUMN_NAMES,
    COLUMN_RESOURCES,
    FEATURE_SETTINGS,
    InitialColumn,
    SORT_RESOURCES
} from "..";

export const TASK_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Status,
        resource: COLUMN_RESOURCES.TaskStatusIcon,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true,
        isBadge: true
    },
    {
        name: COLUMN_NAMES.TaskId,
        resource: COLUMN_RESOURCES.Id,
        isDefaultActive: true,
        sortResource: SORT_RESOURCES.Id
    },
    {
        name: COLUMN_NAMES.DeviceImei,
        resource: COLUMN_RESOURCES.DeviceImei,
        sortResource: SORT_RESOURCES.DeviceImei,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.DeviceCompany,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: [COLUMN_RESOURCES.CreatedBy, COLUMN_RESOURCES.Email]
    },
    {
        name: COLUMN_NAMES.UpdatedBy,
        resource: [COLUMN_RESOURCES.UpdatedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.UpdatedBy
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Schedule,
        resource: COLUMN_RESOURCES.Schedule,
        isDefaultActive: true,
        restriction: FEATURE_SETTINGS.TaskScheduleSupport
    }
];

export const COMPANY_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Name,
        resource: COLUMN_RESOURCES.Name,
        sortResource: SORT_RESOURCES.Name,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Id,
        resource: COLUMN_RESOURCES.Id
    },
    {
        name: COLUMN_NAMES.ParentCompany,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.VipStatus,
        resource: [COLUMN_RESOURCES.VipStatus],
        isDefaultActive: true,
        roleRequiredToRender: SUPPORT_USER
    },
    {
        name: COLUMN_NAMES.Type,
        resource: [COLUMN_RESOURCES.Type],
        isDefaultActive: true,
        roleRequiredToRender: SUPPORT_USER
    },
    {
        name: COLUMN_NAMES.Code,
        resource: COLUMN_RESOURCES.Code,
        sortResource: SORT_RESOURCES.Code
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: [COLUMN_RESOURCES.CreatedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.CreatedBy
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    }
];

export const DEVICE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Imei,
        resource: COLUMN_RESOURCES.Imei,
        sortResource: SORT_RESOURCES.Imei,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Status,
        resource: COLUMN_RESOURCES.DeviceStatusIcon,
        isBadge: true,
        isDefaultActive: true,
        isDisabled: true
    },
    {
        name: COLUMN_NAMES.Queue,
        resource: COLUMN_RESOURCES.QueueIcon,
        isBadge: true,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Description,
        resource: COLUMN_RESOURCES.Description,
        sortResource: SORT_RESOURCES.Description,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Firmware,
        resource: COLUMN_RESOURCES.CurrentFirmware,
        sortResource: SORT_RESOURCES.CurrentFw,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Configuration,
        resource: COLUMN_RESOURCES.CurrentConfiguration,
        sortResource: SORT_RESOURCES.CurrentCfg,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.ModemVersion,
        resource: COLUMN_RESOURCES.ModemVersion
    },
    {
        name: COLUMN_NAMES.Model,
        resource: COLUMN_RESOURCES.Model,
        sortResource: SORT_RESOURCES.Model
    },
    {
        name: COLUMN_NAMES.Serial,
        resource: COLUMN_RESOURCES.Serial,
        sortResource: SORT_RESOURCES.Serial
    },
    {
        name: COLUMN_NAMES.GsmNumber,
        resource: COLUMN_RESOURCES.GsmNumber
    },
    {
        name: COLUMN_NAMES.Iccid,
        resource: COLUMN_RESOURCES.Iccid
    },
    {
        name: COLUMN_NAMES.Imsi,
        resource: COLUMN_RESOURCES.Imsi
    },
    {
        name: COLUMN_NAMES.ProductCode,
        resource: COLUMN_RESOURCES.ProductCode
    },
    {
        name: COLUMN_NAMES.Company,
        resource: COLUMN_RESOURCES.CompanyName,
        sortResource: SORT_RESOURCES.CompanyName
    },
    {
        name: COLUMN_NAMES.Group,
        resource: COLUMN_RESOURCES.GroupName,
        sortResource: SORT_RESOURCES.GroupName
    },
    {
        name: COLUMN_NAMES.SeenAt,
        resource: COLUMN_RESOURCES.SeenAt,
        sortResource: SORT_RESOURCES.SeenAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        sortResource: SORT_RESOURCES.UpdatedAt
    }
];

export const FILE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Name,
        resource: COLUMN_RESOURCES.Name,
        sortResource: SORT_RESOURCES.Name,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Id,
        resource: COLUMN_RESOURCES.Id
    },
    {
        name: COLUMN_NAMES.Description,
        resource: COLUMN_RESOURCES.Description,
        sortResource: SORT_RESOURCES.Description,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.FileType,
        resource: COLUMN_RESOURCES.Type,
        sortResource: SORT_RESOURCES.Type,
        isDefaultActive: true,
        isTranslatable: true
    },
    {
        name: COLUMN_NAMES.Size,
        resource: COLUMN_RESOURCES.Size,
        sortResource: SORT_RESOURCES.Size
    },
    {
        name: COLUMN_NAMES.Company,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.LastUsedAt,
        resource: COLUMN_RESOURCES.LastUsedAt,
        isDefaultActive: true
    }
];

export const GROUP_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Name,
        resource: COLUMN_RESOURCES.Name,
        sortResource: SORT_RESOURCES.Name,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Id,
        resource: COLUMN_RESOURCES.Id
    },
    {
        name: COLUMN_NAMES.Company,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Firmware,
        resource: [COLUMN_RESOURCES.Firmware, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.FirmwareName,
        isDefaultActive: true,
        restriction: FEATURE_SETTINGS.SmartDeviceGroupSupport
    },
    {
        name: COLUMN_NAMES.Configuration,
        resource: [COLUMN_RESOURCES.Configuration, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.ConfigurationName,
        isDefaultActive: true,
        restriction: FEATURE_SETTINGS.SmartDeviceGroupSupport
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    }
];

export const USER_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Email,
        resource: COLUMN_RESOURCES.Email,
        sortResource: SORT_RESOURCES.Email,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Id,
        resource: COLUMN_RESOURCES.Id
    },
    {
        name: COLUMN_NAMES.Company,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Role,
        resource: [COLUMN_RESOURCES.Role],
        isDefaultActive: true,
        isTranslatable: true,
        roleRequiredToRender: SUPPORT_USER
    },
    {
        name: COLUMN_NAMES.InvitedBy,
        resource: [COLUMN_RESOURCES.InvitedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.InvitedBy
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        sortResource: SORT_RESOURCES.UpdatedAt
    }
];

export const USER_INVITE_COLUMNS: InitialColumn[] = [...USER_COLUMNS].filter(
    column => column.name !== COLUMN_NAMES.Role
);

export const PRODUCT_CODE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.ProductCode,
        resource: COLUMN_RESOURCES.ProductCode,
        sortResource: SORT_RESOURCES.ProductCode,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.CanPackage,
        resource: [COLUMN_RESOURCES.CanPackage, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CanPackage,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: COLUMN_RESOURCES.CreatedByEmail,
        sortResource: SORT_RESOURCES.CreatedByEmail,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        sortResource: SORT_RESOURCES.UpdatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.UpdatedBy,
        resource: COLUMN_RESOURCES.UpdatedByEmail,
        sortResource: SORT_RESOURCES.UpdatedByEmail,
        isDefaultActive: true
    }
];

export const DESKTOP_LICENSE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.LicenseName,
        resource: COLUMN_RESOURCES.Name,
        sortResource: SORT_RESOURCES.Name,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.Company,
        resource: [COLUMN_RESOURCES.Company, COLUMN_RESOURCES.Name],
        sortResource: SORT_RESOURCES.CompanyName,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Status,
        resource: COLUMN_RESOURCES.DesktopLicenseStatusIcon,
        sortResource: SORT_RESOURCES.Enabled,
        isBadge: true,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Expiration,
        resource: COLUMN_RESOURCES.ExpiresAt,
        sortResource: SORT_RESOURCES.ExpiresAt,
        customDateTimeFormat: "L",
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: [COLUMN_RESOURCES.CreatedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.CreatedBy,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.LastCheckedAt,
        resource: COLUMN_RESOURCES.LastCheckedAt,
        sortResource: SORT_RESOURCES.LastCheckedAt,
        isDefaultActive: false
    }
];

export const DEVICE_DETAILS_CHANGES_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.ChangedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true,
        isDisabled: true
    },
    {
        name: COLUMN_NAMES.Field,
        resource: COLUMN_RESOURCES.Attribute,
        isDefaultActive: true,
        isTranslatable: true
    },
    {
        name: COLUMN_NAMES.ValueFrom,
        resource: COLUMN_RESOURCES.Old,
        sortResource: SORT_RESOURCES.Old,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.ValueTo,
        resource: COLUMN_RESOURCES.New,
        sortResource: SORT_RESOURCES.New,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: COLUMN_RESOURCES.CreatedByEmail,
        isDefaultActive: true
    }
];

export const DEVICE_DETAILS_TASK_QUEUE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Status,
        resource: COLUMN_RESOURCES.TaskStatusIcon,
        isDefaultActive: true,
        isDisabled: true,
        isSticky: true,
        isBadge: true
    },
    {
        name: COLUMN_NAMES.BatchId,
        resource: COLUMN_RESOURCES.BatchId,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.TaskId,
        resource: COLUMN_RESOURCES.Id,
        sortResource: SORT_RESOURCES.Id,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.TaskType,
        resource: COLUMN_RESOURCES.Type,
        sortResource: SORT_RESOURCES.Type,
        isDefaultActive: true,
        isTranslatable: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: [COLUMN_RESOURCES.CreatedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.CreatedBy
    },
    {
        name: COLUMN_NAMES.UpdatedBy,
        resource: [COLUMN_RESOURCES.UpdatedBy, COLUMN_RESOURCES.Email],
        sortResource: SORT_RESOURCES.UpdatedBy
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.UpdatedAt,
        resource: COLUMN_RESOURCES.UpdatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.Schedule,
        resource: COLUMN_RESOURCES.Schedule,
        isDefaultActive: true,
        restriction: FEATURE_SETTINGS.TaskScheduleSupport
    }
];

export const OFFLINE_LICENSE_COLUMNS: InitialColumn[] = [
    {
        name: COLUMN_NAMES.Name,
        resource: COLUMN_RESOURCES.Name,
        sortResource: SORT_RESOURCES.Name,
        isDefaultActive: true,
        isSticky: true
    },
    {
        name: COLUMN_NAMES.DevicesCount,
        resource: COLUMN_RESOURCES.DevicesCount,
        sortResource: SORT_RESOURCES.DevicesCount,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.ExpiresAt,
        resource: COLUMN_RESOURCES.ExpiresAt,
        sortResource: SORT_RESOURCES.ExpiresAt,
        customDateTimeFormat: "L",
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedAt,
        resource: COLUMN_RESOURCES.CreatedAt,
        sortResource: SORT_RESOURCES.CreatedAt,
        isDefaultActive: true
    },
    {
        name: COLUMN_NAMES.CreatedBy,
        resource: COLUMN_RESOURCES.CreatedByEmail,
        sortResource: SORT_RESOURCES.CreatedByEmail,
        isDefaultActive: true
    }
];
