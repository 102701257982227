/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import CompanyTooltip from "../TableDetails/CompanyDetails/CompanyTooltip";
import { CompanyProps } from "../TableDetails/CompanyDetails/types/interfaces";

import Badge, { BADGE_COLORS } from "../DesignComponents/Badge";
import ThemeContext from "../../context/theme/themeContext";

const Company = ({ data, index, isActive }: CompanyProps) => {
    const {
        colorsFacelift: { gray200, white }
    } = useContext(ThemeContext);

    const number = `${index < 10 ? 0 : ""}${index + 1}`;

    const marginLeft = 28 * index;

    return (
        <li
            css={css({
                display: "flex",
                maxWidth: "286px",
                marginLeft: `${marginLeft}px`
            })}
        >
            <Badge
                color={isActive ? BADGE_COLORS.Info : BADGE_COLORS.Light}
                inverted={isActive}
                iconLeft={number}
            >
                <div
                    css={css({
                        position: "relative",
                        paddingLeft: "4px",
                        maxWidth: "246px"
                    })}
                >
                    <div
                        css={css({
                            position: "absolute",
                            top: "-4px",
                            bottom: "-4px",
                            left: "0",
                            width: "1px",
                            background: isActive ? white : gray200,
                            opacity: isActive ? "0.4" : "1"
                        })}
                    />

                    <CompanyTooltip name={data.name} lineH={16} fontSize={12} />
                </div>
            </Badge>
        </li>
    );
};

export default Company;
