export enum INPUT_NAMES {
    NameValue = "nameValue",
    DescriptionValue = "descriptionValue",
    ProductCodeValue = "productCodeValue"
}

export enum DROPDOWN_NAMES {
    CompanyValue = "companyValue",
    FirmwareValue = "firmwareValue",
    ConfigurationValue = "configurationValue",
    CanPackageValue = "canPackageValue"
}
