/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import { useMediaQueries } from "../../shared";
import Skeleton from "@mui/material/Skeleton";

const FilteredValuesLoader = () => {
    const {
        colorsFacelift: { gray100, gray200 }
    } = useContext(ThemeContext);

    const { fromSm } = useMediaQueries();

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                borderTop: `1px solid ${gray200}`,
                padding: "7px 24px 8px 24px",

                span: {
                    borderRadius: "6px",
                    background: gray100
                }
            })}
        >
            <Skeleton variant="rectangular" width={77} height={20} />

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    columnGap: "6px",
                    rowGap: "6px",
                    marginLeft: "16px"
                })}
            >
                <Skeleton variant="rectangular" width={140} height={32} />

                {fromSm && (
                    <Skeleton variant="rectangular" width={140} height={32} />
                )}

                {fromSm && (
                    <Skeleton variant="rectangular" width={80} height={20} />
                )}
            </div>
        </div>
    );
};

export default FilteredValuesLoader;
