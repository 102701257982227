const useBootstring = () => {
    const parsedBootstringData = (bootstring: string, dataType: 1 | 2) => {
        if (bootstring && dataType) {
            const version = bootstring.slice(0, 2);
            let softwareDate: string;
            let programNumber: string;

            if (version === "C7") {
                softwareDate = bootstring.slice(56, 62);
                programNumber = bootstring.slice(64, 72);
            } else {
                softwareDate = bootstring.slice(16, 22);
                programNumber = bootstring.slice(12, 16);
            }

            const getParsedProgramNumber = (number: string) => {
                const match = number.match(/.{2}/g);

                if (match !== null) {
                    const reversedProgramNumber = match.reverse().join("");
                    const parsedInt = parseInt(reversedProgramNumber, 16);

                    return String(parsedInt);
                } else {
                    return null;
                }
            };

            return dataType === 1
                ? getParsedProgramNumber(programNumber)
                : softwareDate;
        } else {
            return null;
        }
    };

    return { parsedBootstringData };
};

export default useBootstring;
