/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import MuiSlider from "@mui/material/Slider";

import { CombinedSliderProps, TRACK_TYPE } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Slider = ({ label, ...props }: CombinedSliderProps) => {
    const {
        colorsFacelift: { blue700, gray300, gray700, textPlaceholder }
    } = useContext(ThemeContext);

    const isInverted = props.track === TRACK_TYPE.Inverted;

    return (
        <div>
            {label && (
                <div
                    css={css({
                        color: gray700,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    })}
                >
                    {label}
                </div>
            )}

            <MuiSlider
                data-testid="slider"
                {...props}
                sx={{
                    padding: "12px 0",
                    borderRadius: "4px",
                    marginBottom: "16px",

                    "& .MuiSlider-rail": {
                        backgroundColor: isInverted ? blue700 : gray300,
                        opacity: "1",
                        transition: "background-color 300ms ease-out"
                    },

                    "& .MuiSlider-track": {
                        backgroundColor: isInverted ? gray300 : blue700,
                        border: "none",
                        transition: "background-color 300ms ease-out"
                    },

                    "& .MuiSlider-thumb": {
                        backgroundColor: blue700,
                        width: "12px",
                        height: "12px",

                        "&:after": {
                            width: "24px",
                            height: "24px"
                        },

                        "&:hover": {
                            boxShadow: `0px 0px 0px 6px rgb(0 84 166 / 16%)`
                        },

                        "&.Mui-active": {
                            boxShadow: `0px 0px 0px 8px rgb(0 84 166 / 16%)`
                        }
                    },

                    "& .MuiSlider-mark": {
                        background: gray300,
                        width: "1px",
                        height: "4px",
                        top: "24px"
                    },

                    "& .MuiSlider-markLabel": {
                        color: textPlaceholder,
                        fontSize: "10px",
                        fontWeight: "600",
                        lineHeight: "12px",
                        letterSpacing: "1px",
                        top: "32px"
                    },

                    '& .MuiSlider-markLabel[data-index="0"]': {
                        transform: "translateX(0%)"
                    },

                    '& .MuiSlider-markLabel[data-index="3"]': {
                        transform: "translateX(-100%)"
                    }
                }}
            />
        </div>
    );
};

export default Slider;
