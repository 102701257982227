import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FilesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M17 21H7C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V5C5 4.45 5.196 3.979 5.588 3.587C5.97933 3.19567 6.45 3 7 3H13L19 9V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21ZM12 10V5H7V19H17V10H12ZM7 10V5V19V10Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default FilesIcon;
