/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";
import LogoIcon from "../../assets/customIcons/contacts/LogoIcon";

const AuthLoader = () => {
    const {
        colorsFacelift: { blue800, white }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: white,
                zIndex: "9999"
            })}
        >
            <LogoIcon
                css={css({
                    color: blue800,
                    width: "30px",
                    height: "24px",
                    animationName: "pulse",
                    animationDuration: "1.5s",
                    animationTimingFunction: "linear",
                    animationIterationCount: "infinite",
                    animationDirection: "alternate",

                    "@keyframes pulse": {
                        from: {
                            transform: "scale(1)"
                        },
                        to: {
                            transform: "scale(1.3)"
                        }
                    }
                })}
            />
        </div>
    );
};

export default AuthLoader;
