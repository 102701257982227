/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { t } from "i18next";
import OpenDialogImage from "@mui/icons-material/OpenInNewRounded";

import { CompanyLevelProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import CompanyLevelDialog from "../../../CompanyLevel";

import ThemeContext from "../../../../context/theme/themeContext";
import { COMPANY_LEVELS } from "../../../../shared";

const CompanyLevel = ({ level }: CompanyLevelProps) => {
    const {
        colorsFacelift: { beigeDefault, gray200, gray700, gray800, textDark }
    } = useContext(ThemeContext);

    const [isDialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const getColor = () => {
        switch (level) {
            case COMPANY_LEVELS.Premium:
                return gray800;
            case COMPANY_LEVELS.Standard:
                return beigeDefault;
            default:
                return gray200;
        }
    };

    return (
        <>
            <div
                css={css({
                    display: "flex",
                    marginTop: "16px"
                })}
            >
                <div
                    css={css({
                        minWidth: "4px",
                        width: "4px",
                        borderRadius: "2px",
                        background: getColor()
                    })}
                />

                <div
                    css={css({
                        padding: "10px 0 10px 8px"
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            color: textDark,
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            marginBottom: "4px",

                            "& > svg": {
                                fontSize: "16px",
                                cursor: "pointer",
                                marginLeft: "4px"
                            }
                        })}
                    >
                        {t(`Dialog##company level##${level}`)}

                        <TooltipGeneral
                            placement={TOOLTIP_PLACEMENT.Top}
                            title={t("General##click for more details")}
                        >
                            <OpenDialogImage onClick={openDialog} />
                        </TooltipGeneral>
                    </div>

                    <div
                        css={css({
                            color: gray700,
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "0.4px"
                        })}
                    >
                        {t(
                            `Welcome dashboard##company level##description##${level}`
                        )}
                    </div>
                </div>
            </div>

            <CompanyLevelDialog isOpen={isDialogOpen} close={closeDialog} />
        </>
    );
};

export default CompanyLevel;
