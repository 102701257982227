import { SerializedStyles } from "@emotion/react";

import { ReactComponent as FotaWebLogo } from "../../assets/logos/FotaWebLogo.svg";
import { ReactComponent as FotaWebLogoSmall } from "../../assets/logos/FotaWebLogoSmall.svg";
import { useMediaQueries } from "../../shared";

interface LogoProps {
    hasSymbolOnly?: boolean;
    css?: SerializedStyles;
    onClick?: () => void;
}

const Logo = ({ hasSymbolOnly, ...props }: LogoProps) => {
    const { toSm } = useMediaQueries();

    return hasSymbolOnly && toSm ? (
        <FotaWebLogoSmall {...props} />
    ) : (
        <FotaWebLogo {...props} />
    );
};

export default Logo;
