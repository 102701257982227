import {
    useContext,
    useState,
    SyntheticEvent,
    useCallback,
    useEffect
} from "react";
import { useTranslation } from "react-i18next";

import { usePasswordValidation } from "./usePasswordValidation";

import { useTogglePswVisibility } from "../../hooks";
import { PASSWORD_TYPES } from "../../types";

import AuthContext from "../../../../context/auth/authContext";
import { useEmailValidation } from "../../../../shared";

export const useLogin = () => {
    const { errorMessage, loginUser, removeErrorMessage } =
        useContext(AuthContext);

    const { t } = useTranslation();

    const { passwordError, setPasswordError, clearPasswordError } =
        usePasswordValidation();

    const { PasswordVisibility, showPassword } = useTogglePswVisibility(
        PASSWORD_TYPES.Current
    );

    const [user, setUser] = useState({
        email: "",
        password: ""
    });

    const { email, password } = user;

    const {
        isEmailValid,
        emailError,
        setEmailError,
        clearEmailError,
        validateEmail
    } = useEmailValidation(email);

    useEffect(() => {
        return () => {
            errorMessage && removeErrorMessage();
        };
    }, [errorMessage, removeErrorMessage]);

    const clearCredentials = () =>
        setUser({
            email: "",
            password: ""
        });

    const handleLoginChange = useCallback(
        (e: { target: { name: string; value: string } }) => {
            const inputName = e.target.name;
            const inputValue = e.target.value;

            setUser({
                ...user,
                [inputName]: inputValue
            });

            if (inputName === "email") {
                validateEmail(e);
                clearEmailError();
            } else {
                clearPasswordError("password1");
            }
        },
        [user, clearEmailError, clearPasswordError, validateEmail]
    );

    const handleLoginSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        if (isEmailValid && password.length > 0) {
            loginUser({
                payload: { email, password },
                successCallback: clearCredentials
            });
        } else {
            if (!isEmailValid) {
                setEmailError(t("Auth##invalid email format"));
            }

            if (password.length < 1) {
                setPasswordError({
                    ...passwordError,
                    password1: t("Auth##required")
                });
            }
        }
    };

    return {
        errorMessage,
        isEmailValid,
        emailError,
        passwordError,
        showPassword,
        handleLoginSubmit,
        validateEmail,
        handleLoginChange,
        PasswordVisibility
    };
};
