/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Skeleton } from "@mui/material";

import { generateArrayOfNumbers } from "../functions";

import ThemeContext from "../../context/theme/themeContext";

export const HierarchyLoader = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const arrayOfNumbers = generateArrayOfNumbers(3);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                rowGap: "4px",
                marginTop: "8px",
                overflowX: "auto"
            })}
        >
            {arrayOfNumbers.map((item, i) => {
                const marginLeft = 28 * i;

                return (
                    <Skeleton
                        key={item}
                        variant="rectangular"
                        width={286}
                        height={24}
                        css={css({
                            background: gray100,
                            borderRadius: "12px",
                            marginLeft: `${marginLeft}px`
                        })}
                    />
                );
            })}
        </div>
    );
};
