import { useContext, useEffect } from "react";

import NotificationsContext from "../notificationsContext";
import { useBroadcasting } from "../../../shared";

const useReceiveNotifications = () => {
    const { setNotificationsCount } = useContext(NotificationsContext);

    const { receiveNotification } = useBroadcasting();

    useEffect(() => {
        receiveNotification(setNotificationsCount);
        setNotificationsCount();

        // eslint-disable-next-line
    }, []);
};

export { useReceiveNotifications };
