import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FileDownloadRoundedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M12 15.5789C11.8667 15.5789 11.7417 15.5581 11.625 15.5164C11.5083 15.4747 11.4 15.4039 11.3 15.3039L7.7 11.7039C7.51667 11.5206 7.425 11.2872 7.425 11.0039C7.425 10.7206 7.51667 10.4872 7.7 10.3039C7.88333 10.1206 8.12083 10.0247 8.4125 10.0164C8.70417 10.0081 8.94167 10.0956 9.125 10.2789L11 12.1539V5.00391C11 4.72057 11.0958 4.48307 11.2875 4.29141C11.4792 4.09974 11.7167 4.00391 12 4.00391C12.2833 4.00391 12.5208 4.09974 12.7125 4.29141C12.9042 4.48307 13 4.72057 13 5.00391V12.1539L14.875 10.2789C15.0583 10.0956 15.2958 10.0081 15.5875 10.0164C15.8792 10.0247 16.1167 10.1206 16.3 10.3039C16.4833 10.4872 16.575 10.7206 16.575 11.0039C16.575 11.2872 16.4833 11.5206 16.3 11.7039L12.7 15.3039C12.6 15.4039 12.4917 15.4747 12.375 15.5164C12.2583 15.5581 12.1333 15.5789 12 15.5789ZM6 20.0039C5.45 20.0039 4.97917 19.8081 4.5875 19.4164C4.19583 19.0247 4 18.5539 4 18.0039V16.0039C4 15.7206 4.09583 15.4831 4.2875 15.2914C4.47917 15.0997 4.71667 15.0039 5 15.0039C5.28333 15.0039 5.52083 15.0997 5.7125 15.2914C5.90417 15.4831 6 15.7206 6 16.0039V18.0039H18V16.0039C18 15.7206 18.0958 15.4831 18.2875 15.2914C18.4792 15.0997 18.7167 15.0039 19 15.0039C19.2833 15.0039 19.5208 15.0997 19.7125 15.2914C19.9042 15.4831 20 15.7206 20 16.0039V18.0039C20 18.5539 19.8042 19.0247 19.4125 19.4164C19.0208 19.8081 18.55 20.0039 18 20.0039H6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default FileDownloadRoundedIcon;
