export enum TABLE_ACTIONS {
    Delete = "delete",
    Update = "update"
}

export enum WS_CUSTOM_CHANNEL_NAMES {
    BackgroundActions = "backgroundActions",
    Licenses = "licenses",
    User = "user"
}

export enum WS_EVENT_NAMES {
    BackgroundAction = "backgroundAction",
    Company = "company",
    Device = "device",
    DeviceGroup = "group",
    File = "file",
    License = "license",
    ProductCode = "productCode",
    Task = "task",
    TaskGroup = "batch",
    User = "user",
    UserInvite = "userInvite"
}
