/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import { css } from "@emotion/react";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";

import { DrawerLabelProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../DesignComponents/Button";

import ThemeContext from "../../../../context/theme/themeContext";
import RoutesContext from "../../../../context/routes/routesContext";

export const DrawerLabel = ({
    scrollTop,
    permanentBorder
}: DrawerLabelProps) => {
    const {
        colorsFacelift: { gray200, gray700 }
    } = useContext(ThemeContext);

    const { toggleStatistics } = useContext(RoutesContext);

    const { t } = useTranslation();

    return (
        <div
            data-testid="drawer-label"
            css={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                padding: "16px 16px 15px 16px",
                borderBottom:
                    (scrollTop && scrollTop > 0) || permanentBorder
                        ? `1px solid ${gray200}`
                        : "1px solid transparent"
            })}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                })}
            >
                <BarChartRoundedIcon />

                <div
                    css={css({
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        paddingLeft: "8px",
                        color: gray700
                    })}
                >
                    {t("Statistics##table statistics")}
                </div>
            </div>

            <Button
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Tiny}
                variant={BUTTON_VARIANTS.IconOnly}
                icon={<CloseIcon />}
                onClick={toggleStatistics}
            />
        </div>
    );
};
