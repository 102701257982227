import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DeleteCircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.07107 5.07151C1.16784 8.97474 1.16784 15.3104 5.07107 19.2136C8.9743 23.1169 15.31 23.1169 19.2132 19.2136C23.1164 15.3104 23.1164 8.97474 19.2132 5.07151C15.31 1.16828 8.9743 1.16828 5.07107 5.07151ZM15.6777 10.0213L13.5563 12.1426L15.6777 14.2639C16.0666 14.6528 16.0666 15.2892 15.6777 15.6781C15.2888 16.067 14.6524 16.067 14.2635 15.6781L12.1421 13.5568L10.0208 15.6781C9.63191 16.067 8.99551 16.067 8.6066 15.6781C8.21769 15.2892 8.21769 14.6528 8.6066 14.2639L10.7279 12.1426L8.6066 10.0213C8.21769 9.63235 8.21769 8.99595 8.6066 8.60704C8.99551 8.21814 9.63191 8.21814 10.0208 8.60704L12.1421 10.7284L14.2635 8.60704C14.6524 8.21814 15.2888 8.21814 15.6777 8.60704C16.0666 8.99595 16.0666 9.63235 15.6777 10.0213Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default DeleteCircleIcon;
