import { SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";

import AlertContext from "../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import AuthContext from "../../../../../context/auth/authContext";
import TableContext from "../../../../../context/table/tableContext";
import TriSourceContext from "../../../../../context/triSource/triSourceContext";
import { ENDPOINTS, useApi } from "../../../../../shared";

const useTriSourceAction = () => {
    const { setAlert, setBackgroundActionId } = useContext(AlertContext);
    const { rootCompany } = useContext(ParamsContext);
    const { isSupportUser } = useContext(AuthContext);
    const { columns, deselectAllRows } = useContext(TableContext);

    const { activeSource, sourcePayload, isSourceReady, isSelectedSingleItem } =
        useContext(TriSourceContext);

    const { t } = useTranslation();

    const {
        postData,
        bulkPostData,
        updateData,
        bulkUpdateData,
        handleResponse
    } = useApi();

    const updateDevices = async (
        payloadData: any,
        setLoading: (value: SetStateAction<boolean>) => void,
        closeDialog: () => void
    ) => {
        try {
            setLoading(true);

            const response = await (isSelectedSingleItem
                ? updateData(
                      ENDPOINTS.Devices,
                      (sourcePayload as number[])[0],
                      payloadData
                  )
                : bulkUpdateData(ENDPOINTS.Devices, payloadData));

            if (isSelectedSingleItem) {
                deselectAllRows();
                closeDialog();
                handleResponse(response);
            } else {
                setBackgroundActionId(
                    response.data,
                    () => {
                        deselectAllRows();
                        closeDialog();
                    },
                    () => setLoading(false)
                );
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    return {
        t,
        rootCompany,
        isSupportUser,
        columns,
        activeSource,
        sourcePayload,
        isSourceReady,
        isSelectedSingleItem,
        setAlert,
        setBackgroundActionId,
        deselectAllRows,
        postData,
        bulkPostData,
        updateData,
        bulkUpdateData,
        handleResponse,
        updateDevices
    };
};

export { useTriSourceAction };
