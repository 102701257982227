import { useTranslation } from "react-i18next";

import { TranslatedUserRolesData, USER_ROLES } from "..";

export const useUserRoles = () => {
    const { t } = useTranslation();

    const TRANSLATED_USER_ROLES: TranslatedUserRolesData[] = [
        {
            name: t(`General##${USER_ROLES.User}`),
            id: USER_ROLES.User
        },
        {
            name: t(`General##${USER_ROLES.Support}`),
            id: USER_ROLES.Support
        },
        {
            name: t(`General##${USER_ROLES.Admin}`),
            id: USER_ROLES.Admin
        },
        {
            name: t(`General##${USER_ROLES.AdminManager}`),
            id: USER_ROLES.AdminManager
        },
        {
            name: t(`General##${USER_ROLES.SystemUser}`),
            id: USER_ROLES.SystemUser
        }
    ];

    const getUserRoleInfo = (role: USER_ROLES) => {
        const returnData = () => {
            for (const userRole of [...TRANSLATED_USER_ROLES].reverse()) {
                if (role >= userRole.id) {
                    return userRole;
                }
            }
        };

        return returnData() as TranslatedUserRolesData;
    };

    return { TRANSLATED_USER_ROLES, getUserRoleInfo };
};
