/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

import { DeviceStatusChart, SingleBarChart } from "./components";
import { SingleStatisticProps } from "./types";

import {
    emptyConfigurationStatItem,
    emptyDeviceStatusItem,
    emptyFirmwareStatsItem
} from "../constants";

import { useGetDeviceStatistics } from "../../../../DeviceStatistics/hooks";

import ThemeContext from "../../../../../context/theme/themeContext";
import {
    DEVICE_STATISTICS,
    LinksTitleLoader,
    RectangleLoader,
    useMediaQueries
} from "../../../../../shared";

export const SingleStatistic = ({ height, query }: SingleStatisticProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { isLoading, statItem } = useGetDeviceStatistics(query, true, true);
    const { toMd, toSm } = useMediaQueries();

    const generateEmptyStatItem = (queryFromProps: DEVICE_STATISTICS) => {
        switch (queryFromProps) {
            case DEVICE_STATISTICS.ActivityStatus:
                return emptyDeviceStatusItem;
            case DEVICE_STATISTICS.CurrentFw:
                return emptyFirmwareStatsItem;
            case DEVICE_STATISTICS.CurrentCfg:
                return emptyConfigurationStatItem;
            default:
                return null;
        }
    };

    const statisticItem = statItem || generateEmptyStatItem(query);

    const sortedDescendingStatItem =
        statisticItem &&
        [...statisticItem.stats].sort((a, b) => b.count - a.count);

    const renderStatContent = (queryFromProps: DEVICE_STATISTICS) => {
        switch (queryFromProps) {
            case DEVICE_STATISTICS.ActivityStatus:
                return (
                    <DeviceStatusChart
                        statItem={statisticItem}
                        isLoading={isLoading}
                    />
                );
            case DEVICE_STATISTICS.CurrentFw:
                return (
                    <SingleBarChart
                        statItem={sortedDescendingStatItem}
                        statTitle={t("Welcome dashboard##firmware versions")}
                    />
                );
            case DEVICE_STATISTICS.CurrentCfg:
                return (
                    <SingleBarChart
                        statItem={sortedDescendingStatItem}
                        statTitle={t("Welcome dashboard##configurations")}
                    />
                );
            default:
                return null;
        }
    };

    const renderStatisticsChartBoxWidth = (passedQuery?: DEVICE_STATISTICS) => {
        let width: string;

        if (
            (passedQuery && passedQuery === DEVICE_STATISTICS.ActivityStatus) ||
            toSm
        ) {
            width = "100%";
        } else if (toMd) {
            width = "80%";
        } else {
            width = "calc((100% / 2) - 12px)";
        }

        return width;
    };

    const renderLoaderWidth = () =>
        query === DEVICE_STATISTICS.ActivityStatus
            ? renderStatisticsChartBoxWidth()
            : "100%";

    return (
        <div
            css={css({
                height: height,
                width: renderStatisticsChartBoxWidth(query)
            })}
        >
            {isLoading ? (
                <div>
                    <LinksTitleLoader />

                    <Box sx={{ m: 2 }} />

                    <RectangleLoader
                        width={renderLoaderWidth()}
                        height="120px"
                    />
                </div>
            ) : (
                <div
                    css={css({
                        color: textDark,
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.15px"
                    })}
                >
                    {renderStatContent(query)}
                </div>
            )}
        </div>
    );
};
