import { useContext } from "react";
import MuiAvatar from "@mui/material/Avatar";

import { AvatarProps } from "../types";

import ThemeContext from "../../../../context/theme/themeContext";
import AuthContext from "../../../../context/auth/authContext";
import SelectCompanyIcon from "../../../../assets/customIcons/actions/SelectCompanyIcon";

const Avatar = ({ filteredValues }: AvatarProps) => {
    const {
        colorsFacelift: { blue700, blue100 }
    } = useContext(ThemeContext);

    const { isSupportUser } = useContext(AuthContext);

    const renderContent = () => {
        if (!isSupportUser) {
            const companiesCount = filteredValues.length;

            switch (companiesCount) {
                case 0: {
                    return (
                        <SelectCompanyIcon
                            sx={{ fontSize: "16px !important" }}
                        />
                    );
                }
                case 1: {
                    const companyName = filteredValues[0].name.trim();
                    return companyName.charAt(0).toUpperCase();
                }
                default: {
                    return companiesCount;
                }
            }
        }

        return <SelectCompanyIcon sx={{ fontSize: "16px !important" }} />;
    };

    return (
        <MuiAvatar
            sx={{
                color: blue700,
                background: blue100,
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                width: "24px",
                height: "24px"
            }}
        >
            {renderContent()}
        </MuiAvatar>
    );
};

export default Avatar;
