/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { SingleStatistic } from "./SingleStatistic";

import ThemeContext from "../../../../context/theme/themeContext";
import { DEVICE_STATISTICS } from "../../../../shared";

const Statistics = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                flexWrap: "wrap",
                rowGap: "24px",
                padding: "24px",
                justifyContent: "space-between",
                borderTop: `1px solid ${gray200}`
            })}
        >
            <SingleStatistic
                height="168px"
                query={DEVICE_STATISTICS.ActivityStatus}
            />

            <SingleStatistic
                height="156px"
                query={DEVICE_STATISTICS.CurrentFw}
            />

            <SingleStatistic
                height="156px"
                query={DEVICE_STATISTICS.CurrentCfg}
            />
        </div>
    );
};

export default Statistics;
