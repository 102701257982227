/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CircularProgress } from "@mui/material";

const AbsoluteCircularProgress = () => {
    return (
        <div
            css={css({
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                alignItems: "center",
                height: "100%",
                width: "100%",
                zIndex: "30"
            })}
        >
            <CircularProgress />
        </div>
    );
};

export default AbsoluteCircularProgress;
