import { useState } from "react";

import { TokenData, TransferDevicesValidationProps } from "../types";

import { ENDPOINTS, useApi } from "../../../../../shared";

const useTokenValidation = ({
    token,
    removeResize
}: TransferDevicesValidationProps) => {
    const { getData } = useApi();

    const [tokenError, setTokenError] = useState("");
    const [tokenLoading, setTokenLoading] = useState(false);
    const [isCompanyVisible, setCompanyVisible] = useState(true);

    const [validationData, setValidationData] = useState<TokenData | null>(
        null
    );

    const clearTokenError = () => setTokenError("");

    const getCompanyData = async (tokenResponse: TokenData) => {
        try {
            const { data } = await getData(
                `${ENDPOINTS.Companies}?query=${tokenResponse.company_id}`
            );

            setCompanyVisible(data.data.length > 0);
        } catch (error) {
            setCompanyVisible(false);
        }
    };

    const validateToken = async () => {
        if (token) {
            try {
                setTokenLoading(true);

                const { data } = await getData(`${ENDPOINTS.Tokens}/${token}`);

                await getCompanyData(data);

                setTokenError("");
                setValidationData(data);

                removeResize(false);
            } catch (error) {
                const errorMessage = (error as any).response.data.message;

                setTokenError(errorMessage);
                setValidationData(null);
            }
        }

        setTokenLoading(false);
    };

    return {
        clearTokenError,
        tokenError,
        tokenLoading,
        validateToken,
        validationData,
        isCompanyVisible
    };
};

export { useTokenValidation };
