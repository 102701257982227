/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import ChangePasswordModal from "./ChangePasswordModal";

import ContentBox from "../ContentBox";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../DesignComponents/TextLink";

const ChangePassword = () => {
    const { t } = useTranslation();

    const [isOpen, setOpen] = useState(false);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    return (
        <>
            <ContentBox
                title={t("Auth##password")}
                css={css({
                    marginBottom: "8px"
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    })}
                >
                    <div>{t("Password##account password")}</div>

                    <TextLink
                        size={TEXT_LINK_SIZES.Normal}
                        onClick={openDialog}
                    >
                        {t("Button##change")}
                    </TextLink>
                </div>
            </ContentBox>

            <ChangePasswordModal isOpen={isOpen} close={closeDialog} />
        </>
    );
};

export default ChangePassword;
