/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect } from "react";

import NoResultsFound from "./NoResultsFound";
import NoInitialResults from "./NoInitialResults";

import ThemeContext from "../../context/theme/themeContext";
import ParamsContext from "../../context/params/paramsContext";
import TableContext from "../../context/table/tableContext";
import RoutesContext from "../../context/routes/routesContext";
import { FILTER_NAMES, isFilterOrSearch, useMediaQueries } from "../../shared";

const TableEmpty = () => {
    const {
        colorsFacelift: { blue100, blue200, gray200, gray700, textDark }
    } = useContext(ThemeContext);
    const { closeStatistics } = useContext(RoutesContext);

    const { queryParams } = useContext(ParamsContext);
    const { isInnerTable, innerTableInitialParamNames, innerTableQueryParams } =
        useContext(TableContext);

    const { fromMd } = useMediaQueries();

    useEffect(() => {
        closeStatistics();
        // eslint-disable-next-line
    }, []);

    const getInnerTableQueryParams = () => {
        const copyOfQueryParams = { ...innerTableQueryParams };

        innerTableInitialParamNames.forEach(
            initialParam => delete copyOfQueryParams[initialParam]
        );

        return copyOfQueryParams;
    };

    const queryParameters = isInnerTable
        ? getInnerTableQueryParams()
        : queryParams;

    const isFilterOrSearchUsed = Object.keys(queryParameters).some(
        param =>
            isFilterOrSearch(param) &&
            param !== FILTER_NAMES.RootCompany &&
            param !== FILTER_NAMES.CompanyId
    );

    return (
        <div
            className="remaining-height"
            css={css({
                position: "relative"
            })}
        >
            <div
                className={isInnerTable ? undefined : "remaining-height"}
                css={css({
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    overflow: "auto"
                })}
            >
                <div
                    css={css({
                        height: "42px",
                        boxSizing: "border-box",
                        background: blue100,
                        borderTop: `1px solid ${blue200}`,
                        borderBottom: `1px solid ${blue200}`
                    })}
                />

                <div
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex: "1",
                        padding: "32px 24px",

                        "& > div:nth-of-type(1)": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "16px"
                        },

                        "& > div:nth-of-type(2)": {
                            color: textDark,
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            marginBottom: "8px"
                        },

                        "& > div:nth-of-type(3)": {
                            color: gray700,
                            textAlign: "center",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginBottom: "12px"
                        }
                    })}
                >
                    {isFilterOrSearchUsed ? (
                        <NoResultsFound />
                    ) : (
                        <NoInitialResults />
                    )}
                </div>

                {!isInnerTable && fromMd && (
                    <div
                        css={css({
                            height: "1px",
                            background: gray200,
                            marginBottom: "86px"
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default TableEmpty;
