import {
    Filter,
    FilteredValue,
    FilterListFieldNames,
    FILTER_NAMES,
    TABLE_NAMES,
    TABLE_NAME_RESOURCES_FOR_FILTERS,
    FilterDropdownOptions,
    isFilter,
    getRootCompanyValue,
    FilterId,
    NOT_ASSIGNED,
    transformIdArrayWithSpecIdNotAssigned,
    SPEC_ID_NOT_ASSIGNED_VALUE,
    isSpecIdFilter,
    getFiltersWithQuotes,
    FILTERS_WITH_QUOTES
} from "../../../shared";

export const getFilteredListFieldNames = (
    filterNames: FILTER_NAMES[],
    filterListFieldNames: FilterListFieldNames,
    tableName: TABLE_NAMES
) => {
    return filterNames.filter(name =>
        filterListFieldNames[
            tableName as unknown as TABLE_NAME_RESOURCES_FOR_FILTERS
        ]?.includes(name)
    );
};

export const transformedFilteredItems = (passedFilteredItems: Filter[]) =>
    passedFilteredItems.map((filteredItem: Filter) =>
        filteredItem.id === null
            ? ({
                  id: "null",
                  name: NOT_ASSIGNED
              } as Filter)
            : filteredItem
    );

export const updateQueryValue = (
    currentQueryValues: string[],
    id: number | string | "null"
) => {
    return currentQueryValues
        .filter(value => value !== String(id))
        .map(val => (val === SPEC_ID_NOT_ASSIGNED_VALUE ? val.split(",") : val))
        .flat();
};

export const findActualFilters = (
    filteredValues: FilteredValue[],
    filterName: FILTER_NAMES
) =>
    filteredValues.find(
        filteredValue => filteredValue.filterName === filterName
    );

export const updateFilterValues = (
    actualFilter: FilteredValue | undefined,
    id: FilterId
) =>
    actualFilter ? actualFilter.value.filter(filter => filter.id !== id) : [];

export const checkFilteredValues = (
    isInnerTable: boolean,
    innerTableFilteredValues: FilteredValue[],
    mainFilteredValues: FilteredValue[]
) => (isInnerTable ? innerTableFilteredValues : mainFilteredValues);

export const handleLoading = (isInnerTable: boolean, filtersExist: boolean) =>
    isInnerTable ? false : filtersExist;

export const getRootCompanyButtonTitle = (
    filteredCompanies: Filter[],
    allOptions: FilterDropdownOptions,
    rootCompanyId: string,
    t: any
) => {
    const companiesCount = filteredCompanies.length;

    const rootCompanyName = getRootCompanyValue(
        allOptions,
        rootCompanyId
    )?.name;

    if (companiesCount) {
        const isRootCompanyName =
            companiesCount === 1 &&
            String(filteredCompanies[0].id) === rootCompanyId;

        return isRootCompanyName
            ? rootCompanyName
            : t("Button##number of selected companies", {
                  companyCount: companiesCount,
                  companyName: rootCompanyName
              });
    }

    return t("Button##all of company", {
        companyName: rootCompanyName
    });
};

export const getCompanyFilterButtonTitle = (
    filteredCompanies: Filter[],
    noCompaniesTitle: string,
    multipleCompaniesTitle: string
) => {
    const companiesCount = filteredCompanies.length;

    switch (companiesCount) {
        case 0:
            return noCompaniesTitle;
        case 1:
            return filteredCompanies[0].name;
        default: {
            return multipleCompaniesTitle;
        }
    }
};

export const getFilterNames = (updatedParams: any) =>
    Object.keys(updatedParams).filter(
        filterName =>
            isFilter(filterName) &&
            filterName !== FILTER_NAMES.RootCompany &&
            filterName !== FILTER_NAMES.CompanyId
    ) as FILTER_NAMES[];

export const getDecodedFilterValue = (
    filterName: FILTER_NAMES,
    filterValue: any,
    decodeArray: (arr: string[]) => string[]
) => {
    if (Array.isArray(filterValue)) {
        const updatedFilterValue = transformIdArrayWithSpecIdNotAssigned(
            filterName,
            filterValue
        );

        return decodeArray(updatedFilterValue);
    }

    return decodeURIComponent(filterValue);
};

const encodedFilterDrodownValue = (filterName: FILTER_NAMES, value: string) => {
    const filtersWithQuotes = getFiltersWithQuotes();

    const needsToBeEncoded = filtersWithQuotes.includes(
        filterName as unknown as FILTERS_WITH_QUOTES
    );

    return needsToBeEncoded ? encodeURIComponent(value) : value;
};

export const getFilterDropdownValueIds = (
    filterName: FILTER_NAMES,
    newValue: Filter[]
) => {
    const isSpecId = isSpecIdFilter(filterName);

    if (isSpecId) {
        return newValue.map(({ id }) => String(id).split(",")).flat();
    }

    if (newValue.length === 1) {
        return [encodedFilterDrodownValue(filterName, String(newValue[0].id))];
    } else {
        return newValue.map(({ id }) =>
            encodedFilterDrodownValue(filterName, String(id))
        );
    }
};
