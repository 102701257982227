export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_INNER_TABLE_PARAMS = "SET_INNER_TABLE_PARAMS";
export const SET_CUSTOM_RESOURCE = "SET_CUSTOM_RESOURCE";
export const RESET_INNER_TABLE_ALL_PARAMS = "RESET_INNER_TABLE_ALL_PARAMS";
export const RESET_INNER_TABLE_FILTER_PARAMS =
    "RESET_INNER_TABLE_FILTER_PARAMS";
export const TOGGLE_ACTIVE_COLUMN = "TOGGLE_ACTIVE_COLUMN";
export const RESTORE_ACTIVE_COLUMNS = "RESTORE_ACTIVE_COLUMNS";
export const SET_TABLE_LOADING = "SET_TABLE_LOADING";
export const TOGGLE_SELECTION_OF_SINGLE_ROW = "TOGGLE_SELECTION_OF_SINGLE_ROW";
export const SELECT_MULTIPLE_ROWS = "SELECT_MULTIPLE_ROWS";
export const DESELECT_MULTIPLE_ROWS = "DESELECT_MULTIPLE_ROWS";
export const DESELECT_ALL_AND_SELECT_MULTIPLE =
    "DESELECT_ALL_AND_SELECT_MULTIPLE";
export const DESELECT_ALL_ROWS = "DESELECT_ALL_ROWS";
export const SET_DETAILS_DATA = "SET_DETAILS_DATA";
export const UPDATE_SINGLE_ROW_DATA = "UPDATE_SINGLE_ROW_DATA";
export const DELETE_MULTIPLE_ROWS = "DELETE_MULTIPLE_ROWS";
