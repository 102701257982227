import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ErrorBoundaryProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";
import ErrorElements, { TITLES } from "../ErrorElements";
import errorAnimation from "../../assets/animations/error_animation.json";
import { ROUTES, useWindowLocation } from "../../shared";

const ErrorBoundary = ({ error, resetError }: ErrorBoundaryProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useWindowLocation();

    useEffect(() => {
        return () => {
            error && resetError();
        };
    }, [pathname, error, resetError]);

    const animationCSS: Object = {
        position: "absolute",
        bottom: "120px",
        height: "180px",
        margin: "0 auto",

        "@media (max-width: 839px)": {
            bottom: "78px",
            height: "125px"
        },

        "@media (max-width: 599px)": {
            bottom: "60px",
            height: "110px"
        }
    };

    return (
        <Fragment>
            <ErrorElements
                title={TITLES.Error}
                animation={errorAnimation}
                animationCSS={animationCSS}
                message={t("Error##message wait")}
                errorButton={
                    <Button
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={() => {
                            pathname === ROUTES.Dashboard
                                ? window.location.reload()
                                : navigate(ROUTES.Dashboard);
                        }}
                    >
                        {pathname === ROUTES.Dashboard
                            ? t("Button##refresh page")
                            : t("Button##return home")}
                    </Button>
                }
            />
        </Fragment>
    );
};

export default ErrorBoundary;
export type { ErrorBoundaryProps } from "./types";
