/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    FIELD_NAME,
    DeviceFormInputData,
    AddDeviceProps,
    InputData
} from "../types";

import CompanyAndGroupDropdowns from "../../components/CompanyAndGroupDropdowns";
import { useDataValidation } from "../../hooks";
import { DevicePayloadProps } from "../../types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";

import { TABLE_NAMES, isValidNumberValue } from "../../../../../../shared";

const AddDeviceFromForm = ({ setDevicePayload }: AddDeviceProps) => {
    const { validImei, validSerial } = useDataValidation();

    const { t } = useTranslation();

    const [deviceInfo, setDeviceInfo] = useState<DeviceFormInputData>({
        description: "",
        imei: "",
        serial: "",
        error: {
            imei: null,
            serial: null
        }
    });

    const [contentDropdownInfo, setContentDropdownInfo] =
        useState<DevicePayloadProps | null>(null);

    const { description, imei, serial, error } = deviceInfo;

    useEffect(() => {
        const isReady =
            contentDropdownInfo && validImei(imei) && validSerial(serial);

        const payloadData = {
            description,
            imei,
            serial,
            company_id: contentDropdownInfo?.company,
            ...(contentDropdownInfo?.group !== null && {
                group_id: contentDropdownInfo?.group
            })
        };

        if (isReady) {
            const data = {
                resource: TABLE_NAMES.Devices,
                payload: payloadData
            };

            setDevicePayload(data);
        } else {
            setDevicePayload(null);
        }
        // eslint-disable-next-line
    }, [contentDropdownInfo, imei, serial, error]);

    const handleChange = (e: { target: InputData }) => {
        const { name, value } = e.target;

        if (name !== FIELD_NAME.Description) {
            validateNumberInputs(e.target);
        } else {
            setDeviceInfo({
                ...deviceInfo,
                [name]: value
            });
        }
    };

    const handleContentDropdowns = (data: DevicePayloadProps | null) =>
        setContentDropdownInfo(data);

    const validateInput = (e: { target: InputData }) => {
        const { name, value } = e.target;

        const isImeiField = name === FIELD_NAME.Imei;

        const msg = isImeiField
            ? t("Dialog##wrong imei")
            : t("Dialog##wrong serial");

        const isValid = isImeiField ? validImei(imei) : validSerial(serial);

        if (value && !isValid) {
            setDeviceInfo({
                ...deviceInfo,
                error: {
                    ...error,
                    [name]: msg
                }
            });
        }
    };

    const validateNumberInputs = ({ name, value }: InputData) => {
        if (isValidNumberValue(value)) {
            setDeviceInfo({
                ...deviceInfo,
                [name]: value,
                error: {
                    ...error,
                    [name]: null
                }
            });
        } else {
            setDeviceInfo({
                ...deviceInfo,
                error: {
                    ...error,
                    [name]:
                        deviceInfo[name as keyof typeof deviceInfo] === ""
                            ? t("Dialog##only numbers")
                            : null
                }
            });
        }
    };

    return (
        <Fragment>
            <div
                css={css({
                    "> div": {
                        paddingTop: "16px"
                    }
                })}
            >
                <InputField
                    data-testid="description-input-field"
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    onChange={handleChange}
                    value={description}
                    name={FIELD_NAME.Description}
                    labelLeft={t("Table##description")}
                    placeholder={`${t("Dialog##example")}`}
                />

                <InputField
                    data-testid="imei-input-field"
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    onChange={handleChange}
                    value={imei}
                    required
                    errorText={error.imei}
                    name={FIELD_NAME.Imei}
                    onBlur={validateInput}
                    labelLeft={t("Table##imei")}
                    placeholder={`${t("Dialog##example")}`}
                />

                <InputField
                    data-testid="serial-input-field"
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    onChange={handleChange}
                    value={serial}
                    required
                    errorText={error.serial}
                    name={FIELD_NAME.Serial}
                    onBlur={validateInput}
                    labelLeft={t("Table##serial")}
                    placeholder={`${t("Dialog##example")}`}
                />
            </div>

            <CompanyAndGroupDropdowns
                isCompanyRequired
                isRootRequired
                setPayload={handleContentDropdowns}
            />
        </Fragment>
    );
};
export default AddDeviceFromForm;
