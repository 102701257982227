export enum FIND_TOOL_TAB_VALUES {
    Device,
    User
}

export enum FIND_TOOL_TAB_LABELS {
    Device = "device",
    User = "user"
}

export enum SEARCH_VALUES {
    Email = "email",
    Imei = "imei"
}

export enum FOUND_ITEM_NAMES {
    DeviceModel = "device model",
    Role = "role"
}
