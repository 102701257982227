/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { TitleProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";

const Title = ({ text }: TitleProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                color: textDark,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.15px"
            })}
        >
            {text}
        </div>
    );
};

export default Title;
