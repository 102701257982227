export enum FILE_NAME {
    SampleImportList = "sample_import_list",
    SampleImeiList = "sample_imei_list",
    SampleAddDeviceList = "sample_add_device_list"
}

export enum MODAL_WINDOW {
    Main = "Main",
    ReceiveDevices = "Receive devices",
    TransferDevices = "Transfer devices"
}

export enum EXPORT_TYPE {
    CSV = "csv",
    XLSX = "xlsx"
}
