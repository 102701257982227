import { useContext, useState, ReactNode, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import TimeZoneContext from "./timeZoneContext";
import AlertContext, { ALERT_STATUS } from "../alert/alertContext";

interface TimeZoneStateProps {
    children: ReactNode;
}

const TimeZoneState = ({ children }: TimeZoneStateProps) => {
    const { t } = useTranslation();

    const { setAlert } = useContext(AlertContext);

    const initialTimeZone = () => {
        const names = moment.tz.names();
        const loclaStorageValue = localStorage.getItem("timeZone");
        const detectedTimeZone = moment.tz.guess(true);

        return loclaStorageValue && names.includes(loclaStorageValue)
            ? loclaStorageValue
            : detectedTimeZone;
    };

    const [currentTimeZone, setCurrentTimeZone] = useState(initialTimeZone());

    const changeTimeZone = useCallback(
        (timeZone: string) => {
            localStorage.setItem("timeZone", timeZone);
            setCurrentTimeZone(timeZone);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##time zone changed")
            });
        },
        [t, setAlert]
    );

    const value = useMemo(
        () => ({
            currentTimeZone,
            changeTimeZone
        }),
        [currentTimeZone, changeTimeZone]
    );

    return (
        <TimeZoneContext.Provider value={value}>
            {children}
        </TimeZoneContext.Provider>
    );
};

export default TimeZoneState;
