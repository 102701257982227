/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useFilters } from "../hooks";
import FilterDropdown from "../FilterDropdown";
import FilterMenu from "../components/FilterMenu";
import FilterButton from "../components/FilterButton";

import FilterSeparator from "../../DesignComponents/FilterSeparator";

import TableContext from "../../../context/table/tableContext";
import { FILTER_NAMES, useMediaQueries } from "../../../shared";

export const DeviceDetailTaskFilters = () => {
    const { innerTableQueryParams } = useContext(TableContext);

    const { t } = useTranslation();
    const { fromSm, toMd, to1099, fromLg } = useMediaQueries();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useFilters();

    const additionalParams = `${[FILTER_NAMES.DeviceImei]}=${innerTableQueryParams[FILTER_NAMES.DeviceImei]}`;

    const containerStyle = {
        flex: "1",
        maxWidth: "246px",

        "@media(max-width: 899px)": {
            maxWidth: "174px"
        },

        "@media(max-width: 839px)": {
            maxWidth: "unset"
        }
    };

    const getCustomLoadingWidth = () => {
        if (toMd) {
            return 112;
        }

        return to1099 ? 24 : 112;
    };

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                flex: "1"
            })}
        >
            <FilterDropdown
                filter={FILTER_NAMES.StatusId}
                placeholder={t(`Filter##${FILTER_NAMES.StatusId}`)}
                additionalParams={additionalParams}
                css={css({
                    width: "unset"
                })}
                containerCustomStyle={{
                    ...containerStyle,

                    "@media(max-width: 599px)": {
                        marginRight: "16px"
                    }
                }}
                containerLoaderCustomStyle={containerStyle}
            />

            {fromSm && <FilterSeparator width={1} height={36} />}

            {(fromSm && toMd) || fromLg ? (
                <FilterDropdown
                    filter={FILTER_NAMES.Type}
                    placeholder={t(`Filter##${FILTER_NAMES.TaskType}`)}
                    additionalParams={additionalParams}
                    containerCustomStyle={containerStyle}
                    containerLoaderCustomStyle={containerStyle}
                />
            ) : (
                <>
                    <FilterButton
                        onOpen={handleOpenMenu}
                        customLoadingWidth={getCustomLoadingWidth()}
                    >
                        {t("Button##more filters")}
                    </FilterButton>

                    <FilterMenu
                        isOpen={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                    >
                        <div>
                            <FilterDropdown
                                filter={FILTER_NAMES.Type}
                                placeholder={t(
                                    `Filter##${FILTER_NAMES.TaskType}`
                                )}
                                additionalParams={additionalParams}
                            />
                        </div>
                    </FilterMenu>
                </>
            )}
        </div>
    );
};
