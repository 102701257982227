import Button, { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from ".";
import { InlineActionIconButtonProps } from "./types";

import { TOOLTIP_PLACEMENT, TooltipGeneral } from "../Tooltips";
import { useMediaQueries } from "../../../shared";

const InlineActionIconButton = ({
    title,
    icon,
    disabledText,
    onButtonClick,
    isLoading
}: InlineActionIconButtonProps) => {
    const { toMd } = useMediaQueries();

    return (
        <TooltipGeneral
            disableInteractive
            disableTouchListener
            placement={TOOLTIP_PLACEMENT.Top}
            title={disabledText || title}
        >
            <span>
                <Button
                    fullWidth={toMd}
                    color={BUTTON_COLORS.PrimarySubtle}
                    size={BUTTON_SIZES.Small}
                    variant={
                        toMd
                            ? BUTTON_VARIANTS.TextOnly
                            : BUTTON_VARIANTS.IconOnly
                    }
                    icon={icon}
                    onClick={onButtonClick}
                    isLoading={isLoading}
                    disabled={Boolean(disabledText)}
                >
                    {toMd && icon}
                </Button>
            </span>
        </TooltipGeneral>
    );
};

export default InlineActionIconButton;
