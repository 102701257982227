export enum TAB_SIZES {
    Tiny = "tiny",
    Small = "small",
    Normal = "normal",
    Large = "large"
}

export enum DIRECTIONS {
    Left = "left",
    Right = "right"
}
