import DeleteLicense from "../../../shared/DeleteLicense";

import { EditInlineDialogProps } from "../../../../types";

const DeleteDesktopLicense = ({
    data,
    isOpen,
    close
}: EditInlineDialogProps) => {
    return (
        <DeleteLicense
            data={data}
            isOpen={isOpen}
            close={close}
            isDesktopLicense
        />
    );
};

export default DeleteDesktopLicense;
