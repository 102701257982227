/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";

import { MoreActionsProps } from "./types";

import NotificationsContext from "../../../../../notificationsContext";

import ThemeContext from "../../../../../../../context/theme/themeContext";
import Menu from "../../../../../../../components/DesignComponents/Menu";
import ListChoice from "../../../../../../../components/DesignComponents/ListChoice";
import MoreIcon from "../../../../../../../assets/customIcons/actions/MoreIcon";
import { MenuAnchorEl } from "../../../../../../../shared";

const MoreActions = ({ id }: MoreActionsProps) => {
    const {
        colorsFacelift: { textPlaceholder }
    } = useContext(ThemeContext);

    const { deleteNotification } = useContext(NotificationsContext);

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const openMenu = (e: React.MouseEvent<SVGSVGElement>) =>
        setAnchorEl(e.currentTarget as unknown as MenuAnchorEl);

    const closeMenu = () => setAnchorEl(null);

    const removeNotification = () => {
        closeMenu();
        deleteNotification(id);
    };

    return (
        <>
            <MoreIcon
                onClick={openMenu}
                css={css({
                    cursor: "pointer",
                    color: textPlaceholder,
                    marginTop: "4px"
                })}
            />

            <Menu
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorEl={anchorEl}
            >
                <ListChoice
                    title={t("Notifications##remove notification")}
                    iconLeft={<DeleteOutlineRounded />}
                    onClick={removeNotification}
                />
            </Menu>
        </>
    );
};

export default MoreActions;
