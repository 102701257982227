import { useContext, Children, cloneElement } from "react";
import MuiTabs from "@mui/material/Tabs";

import { CombinedTabsProps, TAB_SIZES } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Tabs = ({ children, size, ...props }: CombinedTabsProps) => {
    const {
        colorsFacelift: {
            gray50,
            gray100,
            gray300,
            gray700,
            textDark,
            textPlaceholder,
            white
        }
    } = useContext(ThemeContext);

    const isSmall = size === TAB_SIZES.Small;

    return (
        <MuiTabs
            {...props}
            sx={{
                minHeight: "unset",

                "& .MuiTabs-scrollButtons": {
                    color: gray700
                },

                "& .MuiTabs-scrollButtons.Mui-disabled": {
                    opacity: "1"
                }
            }}
        >
            {Children.map(children, child =>
                cloneElement(child, {
                    sx: {
                        color: textPlaceholder,
                        lineHeight: "20px",
                        fontWeight: "600",
                        borderRadius: "9px 9px 0 0",
                        background: white,
                        textTransform: "none",
                        minWidth: "unset",
                        minHeight: "unset",
                        padding: isSmall ? "8px 16px" : "12px 16px",
                        fontSize: isSmall ? "14px" : "16px",
                        letterSpacing: isSmall ? "0.1px" : "0.15px",
                        boxShadow: `inset 0px -2px 0px ${gray100}`,
                        transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

                        "&.Mui-selected": {
                            color: textDark
                        },

                        "&:hover": {
                            background: gray50,
                            boxShadow: `inset 0px -2px 0px ${gray300}`
                        }
                    }
                })
            )}
        </MuiTabs>
    );
};

export default Tabs;
