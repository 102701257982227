import { useTranslation } from "react-i18next";

import { DropdownValue } from "../../types";
import {
    FEATURE_SETTINGS,
    FILE_TYPES,
    useCompanyLevelSettings
} from "../../../../../shared";

export const useDisabled = () => {
    const { t } = useTranslation();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const getTooltipTitle = (company: DropdownValue | null) => {
        if (isRestricted) {
            return t(`Restrictions##general message`);
        }

        return !company
            ? t("Dialog##field disabled until company selected")
            : "";
    };

    const getMissingFileTooltipTitle = (dropdownFileType: FILE_TYPES) => {
        const tooltipTitleMain = t(
            `Dialog##no dropdown files##${dropdownFileType}##main`
        );

        const tooltipTitleDescription = t(
            `Dialog##no dropdown files##${dropdownFileType}##description`
        );

        return `${tooltipTitleMain} ${tooltipTitleDescription}`;
    };

    const isRestricted = isFeatureSettingsRestricted(
        FEATURE_SETTINGS.SmartDeviceGroupSupport
    );

    return { isRestricted, getTooltipTitle, getMissingFileTooltipTitle };
};
