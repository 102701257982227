import { getFiltersWithQuotes } from "../functions";

export const useDecoder = () => {
    const decodeStrings = (stringParams: string) => {
        if (stringParams) {
            const pairs = stringParams.split("&");

            const result = pairs.map(pair => {
                const [key, value] = pair.split("=");
                const valueArray: string[] = value.split(",");

                const filtersWithQuotes: string[] = getFiltersWithQuotes();

                if (filtersWithQuotes.includes(key)) {
                    const quotedAndSeperated = valueArray
                        .map(val => `"${val}"`)
                        .join();

                    const decodedValue = decodeURIComponent(quotedAndSeperated);

                    return `${key}=${decodedValue}`;
                }

                return `${key}=${decodeURIComponent(value)}`;
            });

            return result.join("&");
        }

        return stringParams;
    };

    const decodeArray = (arr: string[]) =>
        arr.map(element => decodeURIComponent(element));

    return { decodeStrings, decodeArray };
};
