/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback } from "react";

import ListCompany from "./ListCompany";
import { RenderCompanyProps } from "../../types";

const RenderCompany = ({ index, data, style }: RenderCompanyProps) => {
    const { listRef, itemSizesRef, companies, sendToQueryParams } = data;

    const setSize = useCallback(
        (size: number) => {
            listRef.current.resetAfterIndex(index);

            itemSizesRef.current = {
                ...itemSizesRef.current,
                [index]: size
            };
        },

        [index, itemSizesRef, listRef]
    );

    return (
        <ul
            css={css({
                ...style
            })}
        >
            <ListCompany
                data={companies[index]}
                setSize={setSize}
                selectCompany={sendToQueryParams}
            />
        </ul>
    );
};

export default RenderCompany;
