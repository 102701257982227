import { useContext } from "react";

import MobileCard from "./MobileCard";
import TableRow from "./TableRow";
import { TAB_VALUES, TableItemProps } from "../types";

import AuthContext from "../../../context/auth/authContext";
import { useMediaQueries } from "../../../shared";

const TableItem = ({ data, scrollHorizontal }: TableItemProps) => {
    const { isAdminManagerUser } = useContext(AuthContext);

    const { toMd } = useMediaQueries();

    const isPendingApproval =
        data.approvable === Number(TAB_VALUES.PendingApproval);

    const isApprovalRequired = isPendingApproval && isAdminManagerUser;

    return toMd ? (
        <MobileCard
            data={data}
            isApprovalRequired={isApprovalRequired}
            isApprovalBadgeRendered={isPendingApproval}
        />
    ) : (
        <TableRow
            data={data}
            isApprovalRequired={isApprovalRequired}
            isApprovalBadgeRendered={isPendingApproval}
            scrollHorizontal={scrollHorizontal}
        />
    );
};

export default TableItem;
