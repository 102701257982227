import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CheckboxDisabledIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { gray200, gray300 }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill={gray200}
                stroke={gray300}
            />
        </SvgIcon>
    );
};

export default CheckboxDisabledIcon;
