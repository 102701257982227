/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
    FormControlLabel,
    FormGroup,
    Stack,
    Switch,
    SwitchProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext } from "react";

import {
    generateEndItemMargin,
    generateStartItemMargin,
    generateToggleSwitchMargins,
    sizes,
    startEnditemsAlignment,
    toggleSwitchLabelStyles
} from "./styles";
import { ToggleSwitchProps, TOGGLE_SWITCH_SIZES } from "./types";

import ThemeContext from "../../../context/theme/themeContext";

const ToggleSwitch = ({
    size,
    labelPlacement,
    label,
    startItem,
    endItem,
    ...props
}: ToggleSwitchProps & Omit<SwitchProps, "size">) => {
    const { colorsFacelift } = useContext(ThemeContext);

    const rootWidth = sizes[size].rootWidth;
    const rootHeight = sizes[size].rootHeight;
    const thumbWidth = sizes[size].thumbWidth;
    const thumbHeight = sizes[size].thumbHeight;
    const thumbCheckedTransform = sizes[size].thumbCheckedTransform;

    const CustomSwitch = styled(Switch)(({ theme }) => {
        return {
            width: rootWidth,
            height: rootHeight,
            padding: 0,
            display: "flex",
            margin:
                size === TOGGLE_SWITCH_SIZES.Small
                    ? generateToggleSwitchMargins("2px", labelPlacement)
                    : generateToggleSwitchMargins("0", labelPlacement),
            alignSelf: "start",
            "&:active": {
                "& .MuiSwitch-thumb": {
                    width: thumbWidth
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                    transform: thumbCheckedTransform
                }
            },

            "& .MuiSwitch-switchBase": {
                padding: 1,
                "&.Mui-checked": {
                    transform: thumbCheckedTransform,
                    color: "#fff",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: colorsFacelift.blue700
                    }
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    backgroundColor: colorsFacelift.gray400,
                    opacity: "1"
                }
            },
            "& .MuiSwitch-thumb": {
                boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                width: thumbWidth,
                height: thumbHeight,
                borderRadius: 11,
                transition: theme.transitions.create(["width"], {
                    duration: 200
                }),
                backgroundColor: colorsFacelift.white
            },
            "& .MuiSwitch-track": {
                borderRadius: 12,
                opacity: 1,
                backgroundColor: colorsFacelift.blue400,
                boxSizing: "border-box"
            }
        };
    });

    return (
        <div
            data-testid="toggle-switch-component"
            css={css({ display: "flex", justifyContent: "center" })}
        >
            <Stack direction="row" alignItems="center" margin="0" spacing="0">
                {startItem && (
                    <div
                        css={css(startEnditemsAlignment, {
                            marginRight: generateStartItemMargin(
                                label,
                                labelPlacement
                            )
                        })}
                    >
                        {startItem}
                    </div>
                )}

                <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                                data-testid="cutom-switch"
                                {...props}
                            />
                        }
                        label={label}
                        labelPlacement={labelPlacement}
                        sx={toggleSwitchLabelStyles(
                            colorsFacelift,
                            labelPlacement,
                            endItem,
                            startItem,
                            label
                        )}
                    />
                </FormGroup>

                {endItem && (
                    <div
                        css={css(startEnditemsAlignment, {
                            marginLeft: generateEndItemMargin(
                                label,
                                labelPlacement
                            )
                        })}
                    >
                        {endItem}
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default ToggleSwitch;
