import { useState, useCallback, useRef } from "react";

export const useOverflow = (dependencyArray: any[] = []) => {
    const ref = useRef<any>(null);

    const [isOverflowing, setOverflowing] = useState(false);

    const measuredRef = useCallback(
        (node: any) => {
            if (node !== null) {
                ref.current = node;
                handleOverflowing(node);
            }
        },
        // eslint-disable-next-line
        [...dependencyArray]
    );

    const handleOverflowing = (node: any) =>
        setOverflowing(node.scrollWidth > node.clientWidth);

    return {
        measuredRef,
        isOverflowing
    };
};
