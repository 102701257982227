import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ApiTokensIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M13.0009 13C12.4409 13.56 11.5509 13.56 11.0009 13.01V13C10.4509 12.45 10.4509 11.56 11.0009 11.01V11C11.5509 10.45 12.4409 10.45 12.9909 11H13.0009C13.5509 11.55 13.5509 12.45 13.0009 13ZM12.0009 5.99996L14.1209 8.11996L16.6209 5.61996L13.4209 2.41996C12.6409 1.63996 11.3709 1.63996 10.5909 2.41996L7.39094 5.61996L9.89094 8.11996L12.0009 5.99996ZM6.00094 12L8.12094 9.87996L5.62094 7.37996L2.42094 10.58C1.64094 11.36 1.64094 12.63 2.42094 13.41L5.62094 16.61L8.12094 14.11L6.00094 12ZM18.0009 12L15.8809 14.12L18.3809 16.62L21.5809 13.42C22.3609 12.64 22.3609 11.37 21.5809 10.59L18.3809 7.38996L15.8809 9.88996L18.0009 12ZM12.0009 18L9.88094 15.88L7.38094 18.38L10.5809 21.58C11.3609 22.36 12.6309 22.36 13.4109 21.58L16.6109 18.38L14.1109 15.88L12.0009 18Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ApiTokensIcon;
