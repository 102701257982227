/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";

import ColoredCircles from "./ColoredCircles";
import { ErrorElementsProps } from "./types";

import Logo from "../Logo";
import ThemeContext from "../../context/theme/themeContext";

const ErrorElements = ({
    title,
    animation,
    animationCSS,
    message,
    errorButton
}: ErrorElementsProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark, textIconDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <Fragment>
            <header
                css={css({
                    padding: "16px 24px 15px 24px",
                    borderBottom: `1px solid ${gray200}`,
                    display: "flex",
                    alignItems: "center"
                })}
            >
                <Logo />
            </header>

            <main>
                <section className="remaining-height">
                    <div
                        className="remaining-height"
                        css={css({
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "100px 0"
                        })}
                    >
                        <div
                            css={css({
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "625px",
                                height: "416px",
                                boxSizing: "border-box",
                                border: `1px solid ${gray200}`,
                                borderRadius: "8px",

                                "@media (max-width: 839px)": {
                                    width: "410px",
                                    height: "273px"
                                },

                                "@media (max-width: 599px)": {
                                    width: "328px",
                                    height: "218px"
                                }
                            })}
                        >
                            <ColoredCircles />

                            <div>
                                {title === "Error" && (
                                    <div
                                        css={css({
                                            color: textIconDark,
                                            fontSize: "140px",
                                            fontWeight: "600",
                                            textTransform: "uppercase",

                                            "@media (max-width: 839px)": {
                                                fontSize: "92px"
                                            },

                                            "@media (max-width: 599px)": {
                                                fontSize: "74px"
                                            }
                                        })}
                                    >
                                        {t(`${title}##error`)}
                                    </div>
                                )}
                            </div>

                            <Lottie
                                animationData={animation}
                                loop={true}
                                alt={title}
                                css={css(animationCSS)}
                            />
                        </div>

                        <div
                            css={css({
                                marginTop: "32px",
                                textAlign: "center",
                                padding: "0 16px",

                                h1: {
                                    color: textDark,
                                    fontSize: "32px",
                                    fontWeight: "600",
                                    lineHeight: "40px",
                                    letterSpacing: "0.25px",

                                    "@media(max-width: 599px)": {
                                        fontSize: "24px",
                                        lineHeight: "32px",
                                        letterSpacing: "unset"
                                    }
                                },

                                p: {
                                    color: gray700,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.15px"
                                }
                            })}
                        >
                            <h1>{t(`${title}##title`)}</h1>

                            {message && <p>{message}</p>}

                            {errorButton}
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default ErrorElements;
export { TITLES } from "./types";
