import { useTranslation } from "react-i18next";
import CreateIcon from "@mui/icons-material/AddRounded";

import { FirstButtonProps, STEPS } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

const FirstButton = ({
    isInitialStep,
    setSelectedConfiguration,
    handleStep,
    handleSubmitCreate
}: FirstButtonProps) => {
    const { t } = useTranslation();

    return isInitialStep ? (
        <Button
            fullWidth
            color={BUTTON_COLORS.PrimarySubtle}
            size={BUTTON_SIZES.Normal}
            variant={BUTTON_VARIANTS.IconLeft}
            icon={<CreateIcon />}
            onClick={handleSubmitCreate}
        >
            {t("Button##create")}
        </Button>
    ) : (
        <Button
            fullWidth
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Normal}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={() => {
                handleStep(STEPS.Initial);
                setSelectedConfiguration(null);
            }}
        >
            {t("Button##back")}
        </Button>
    );
};

export default FirstButton;
