import { useContext } from "react";
import { generateColor } from "../../../../shared";
import ThemeContext from "../../../../context/theme/themeContext";

export const useStyleOptions = (inverted: boolean | undefined) => {
    const {
        colorsFacelift: {
            blue100,
            blue200,
            blue700,
            gray100,
            gray200,
            green50,
            green100,
            green700,
            green900,
            orange50,
            orange100,
            orange700,
            red50,
            red100,
            red700,
            red800,
            purple50,
            purple100,
            purple700,
            purple900,
            textWarning,
            textDark,
            white
        }
    } = useContext(ThemeContext);

    const styleVariants = {
        light: {
            text: generateColor(inverted, white, textDark),
            border: generateColor(inverted, textDark, gray200),
            background: generateColor(inverted, textDark, white)
        },
        neutral: {
            text: textDark,
            border: gray200,
            background: gray100
        },
        info: {
            text: generateColor(inverted, white, blue700),
            border: generateColor(inverted, blue700, blue200),
            background: generateColor(inverted, blue700, blue100)
        },
        success: {
            text: generateColor(inverted, white, green900),
            border: generateColor(inverted, green700, green100),
            background: generateColor(inverted, green700, green50)
        },
        warning: {
            text: generateColor(inverted, white, textWarning),
            border: generateColor(inverted, orange700, orange100),
            background: generateColor(inverted, orange700, orange50)
        },
        critical: {
            text: generateColor(inverted, white, red800),
            border: generateColor(inverted, red700, red100),
            background: generateColor(inverted, red700, red50)
        },
        premium: {
            text: generateColor(inverted, white, purple900),
            border: generateColor(inverted, purple700, purple100),
            background: generateColor(inverted, purple700, purple50)
        }
    };

    return styleVariants;
};
