import { Filter } from "../../../../shared";

export const useRootCompanySearch = () => {
    const rootCompanyFilterOptions = (
        options: Filter[],
        lowerCaseInput: string
    ) => {
        const checkIfIdMatch = (option: Filter) =>
            String(option.id) === lowerCaseInput;

        const checkIfOptionIncludesInput = (option: Filter) =>
            option.name.toLowerCase().includes(lowerCaseInput);

        const companyIdMatch = options.find(checkIfIdMatch);

        if (companyIdMatch) {
            const partialMatches = options.filter(
                option =>
                    checkIfOptionIncludesInput(option) &&
                    !checkIfIdMatch(option)
            );
            return [companyIdMatch, ...partialMatches];
        }

        return options.filter(
            option =>
                checkIfOptionIncludesInput(option) || checkIfIdMatch(option)
        );
    };

    return { rootCompanyFilterOptions };
};
