/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import { TOKEN_TYPES, TokenProps } from "../types";

import {
    TooltipLarge,
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";
import DateTime from "../../../../../DateTime";
import ThemeContext from "../../../../../../context/theme/themeContext";
import {
    COMPANY_LEVEL_SETTINGS,
    ENDPOINTS,
    FEATURE_SETTINGS,
    useApi,
    useCompanyLevelSettings
} from "../../../../../../shared";

const Token = ({ data, isFirst, isLast, removeFromList }: TokenProps) => {
    const { id, name, type, created_at, last_used_at } = data;

    const {
        colorsFacelift: { gray200, gray700, textDark, textDarkDisabled }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { deleteData, handleResponse } = useApi();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const [isLoading, setIsLoading] = useState(false);

    const deleteToken = async () => {
        try {
            setIsLoading(true);

            const response = await deleteData(ENDPOINTS.Tokens, id);

            removeFromList(id);
            handleResponse(response);
        } catch (error) {
            handleResponse(error);
            setIsLoading(false);
        }
    };

    const isRestricted =
        type === TOKEN_TYPES.Integration &&
        isFeatureSettingsRestricted(FEATURE_SETTINGS.ApiTokenSupport);

    return (
        <div
            css={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                wordBreak: "break-word",
                borderBottom: isLast ? "none" : `1px solid ${gray200}`,
                paddingTop: isFirst ? "0" : "16px",
                paddingBottom: isLast ? "0" : "16px"
            })}
        >
            <TooltipLarge
                title={
                    isRestricted
                        ? t(
                              `Restrictions##${COMPANY_LEVEL_SETTINGS.FeatureSettings}##${FEATURE_SETTINGS.ApiTokenSupport}##token list`
                          )
                        : ""
                }
                placement={TOOLTIP_PLACEMENT.Top}
            >
                <div
                    css={css({
                        fontWeight: "600",
                        lineHeight: "20px",

                        "& > div:first-of-type": {
                            color: isRestricted ? textDarkDisabled : textDark,
                            fontSize: "16px",
                            letterSpacing: "0.15px"
                        },

                        "& > div:not(:first-of-type)": {
                            fontSize: "14px",
                            letterSpacing: "0.1px",

                            "& > span:first-of-type": {
                                color: isRestricted
                                    ? textDarkDisabled
                                    : gray700,
                                marginRight: "5px"
                            },

                            "& > span:last-of-type": {
                                color: isRestricted
                                    ? textDarkDisabled
                                    : textDark
                            }
                        },

                        "& > div + div": {
                            marginTop: "4px"
                        }
                    })}
                >
                    <div>{name}</div>

                    <div>
                        <span>{t("Api tokens##token type")}:</span>

                        <span>{t(`Api tokens##titles##${type}##name`)}</span>
                    </div>

                    <div>
                        <span>{t("Table##created at")}:</span>

                        <span>
                            <DateTime date={created_at} />
                        </span>
                    </div>

                    <div>
                        <span>{t("Api tokens##last used at")}:</span>

                        <span>
                            {last_used_at ? (
                                <DateTime date={last_used_at} />
                            ) : (
                                t("Api tokens##never")
                            )}
                        </span>
                    </div>
                </div>
            </TooltipLarge>

            <TooltipGeneral
                placement={TOOLTIP_PLACEMENT.Top}
                title={t("Button##delete")}
                disableTouchListener
            >
                <Button
                    disableRipple
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Tiny}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<DeleteRoundedIcon />}
                    isLoading={isLoading}
                    onClick={deleteToken}
                    css={css({
                        color: gray700
                    })}
                />
            </TooltipGeneral>
        </div>
    );
};

export default Token;
