/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, {
    tooltipClasses,
    TooltipProps as MuiTooltipProps
} from "@mui/material/Tooltip";

import { TooltipLargeProps } from "./types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import ThemeContext from "../../../context/theme/themeContext";

export const TooltipLarge = styled(
    ({
        buttonText,
        onButtonClick,
        customMargin,
        className,
        ...props
    }: TooltipLargeProps & MuiTooltipProps) => {
        const content = props.title ? (
            <div>
                <div>{props.title}</div>

                {buttonText && onButtonClick && (
                    <Button
                        color={BUTTON_COLORS.White}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        fullWidth
                        onClick={onButtonClick}
                        css={css({
                            marginTop: "16px"
                        })}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        ) : (
            ""
        );

        return (
            <MuiTooltip
                {...props}
                enterTouchDelay={0}
                leaveTouchDelay={60000}
                title={content}
                classes={{ popper: className }}
            />
        );
    }
)(({ customMargin }) => {
    const {
        colorsFacelift: { gray700, white }
    } = useContext(ThemeContext);

    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: gray700,
            color: white,
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            padding: "16px",
            borderRadius: "12px",
            maxWidth: "311px"
        },
        [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}, &[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}, &[data-popper-placement*="left"] .${tooltipClasses.tooltip}, &[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
            {
                margin: `${customMargin ?? "0 0 0 0"} !important`
            }
    };
});
