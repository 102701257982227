import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { Change } from "../types";

import TimeZoneContext from "../../../../../../../../context/timeZone/timeZoneContext";
import { useDateTime } from "../../../../../../../../shared";

export const useScheduleTask = (change: Change) => {
    const { currentTimeZone } = useContext(TimeZoneContext);

    const { startOfTime } = useDateTime();

    const [isInverted, setInverted] = useState(false);
    const [value, setValue] = useState([24, 28]);

    const formatTime = (val: number) => {
        const startOfDay = startOfTime("day");
        const minutes = val * 30;

        return startOfDay.add(minutes, "minutes").format("LT");
    };

    const firstTimestamp = formatTime(value[0]);
    const secondTimeStamp = formatTime(value[1]);

    const fromDate = isInverted ? secondTimeStamp : firstTimestamp;
    const toDate = isInverted ? firstTimestamp : secondTimeStamp;

    useEffect(() => {
        const getTime = (isStart: boolean) =>
            moment
                .tz(isStart ? fromDate : toDate, "LT", currentTimeZone)
                .utc()
                .format("LT");

        const start = getTime(true);
        const end = getTime(false);

        const startAsMinutes = moment.duration(start).asMinutes();
        const endAsMinutes = moment.duration(end).asMinutes();

        const durationAsMinutes =
            endAsMinutes > startAsMinutes
                ? endAsMinutes - startAsMinutes
                : 1440 - startAsMinutes + endAsMinutes;

        change({ from: startAsMinutes, duration: durationAsMinutes });

        // eslint-disable-next-line
    }, [value, fromDate, toDate, currentTimeZone]);

    const toggleInvertion = () => setInverted(!isInverted);

    const handleChange = (newValue: number[]) => {
        const [from, to] = newValue;
        to - from >= 4 && setValue(newValue);
    };

    return {
        isInverted,
        value,
        fromDate,
        toDate,
        toggleInvertion,
        handleChange
    };
};
