import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DiscardIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M16.0005 18.5C15.7172 18.5 15.4797 18.4042 15.288 18.2125C15.0963 18.0208 15.0005 17.7833 15.0005 17.5C15.0005 17.2167 15.0963 16.9792 15.288 16.7875C15.4797 16.5958 15.7172 16.5 16.0005 16.5H18.0005C18.2838 16.5 18.5213 16.5958 18.713 16.7875C18.9047 16.9792 19.0005 17.2167 19.0005 17.5C19.0005 17.7833 18.9047 18.0208 18.713 18.2125C18.5213 18.4042 18.2838 18.5 18.0005 18.5H16.0005ZM16.0005 10.5C15.7172 10.5 15.4797 10.4042 15.288 10.2125C15.0963 10.0208 15.0005 9.78333 15.0005 9.5C15.0005 9.21667 15.0963 8.97917 15.288 8.7875C15.4797 8.59583 15.7172 8.5 16.0005 8.5H21.0005C21.2838 8.5 21.5213 8.59583 21.713 8.7875C21.9047 8.97917 22.0005 9.21667 22.0005 9.5C22.0005 9.78333 21.9047 10.0208 21.713 10.2125C21.5213 10.4042 21.2838 10.5 21.0005 10.5H16.0005ZM16.0005 14.5C15.7172 14.5 15.4797 14.4042 15.288 14.2125C15.0963 14.0208 15.0005 13.7833 15.0005 13.5C15.0005 13.2167 15.0963 12.9792 15.288 12.7875C15.4797 12.5958 15.7172 12.5 16.0005 12.5H20.0005C20.2838 12.5 20.5213 12.5958 20.713 12.7875C20.9047 12.9792 21.0005 13.2167 21.0005 13.5C21.0005 13.7833 20.9047 14.0208 20.713 14.2125C20.5213 14.4042 20.2838 14.5 20.0005 14.5H16.0005ZM5.00049 19.5C4.45049 19.5 3.97966 19.3042 3.58799 18.9125C3.19632 18.5208 3.00049 18.05 3.00049 17.5V8.5C2.71715 8.5 2.47965 8.40417 2.28799 8.2125C2.09632 8.02083 2.00049 7.78333 2.00049 7.5C2.00049 7.21667 2.09632 6.97917 2.28799 6.7875C2.47965 6.59583 2.71715 6.5 3.00049 6.5H6.00049V6C6.00049 5.71667 6.09632 5.47917 6.28799 5.2875C6.47965 5.09583 6.71715 5 7.00049 5H9.00049C9.28382 5 9.52132 5.09583 9.71299 5.2875C9.90465 5.47917 10.0005 5.71667 10.0005 6V6.5H13.0005C13.2838 6.5 13.5213 6.59583 13.713 6.7875C13.9047 6.97917 14.0005 7.21667 14.0005 7.5C14.0005 7.78333 13.9047 8.02083 13.713 8.2125C13.5213 8.40417 13.2838 8.5 13.0005 8.5V17.5C13.0005 18.05 12.8047 18.5208 12.413 18.9125C12.0213 19.3042 11.5505 19.5 11.0005 19.5H5.00049ZM5.00049 8.5V17.5H11.0005V8.5H5.00049Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default DiscardIcon;
