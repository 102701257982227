import { CHANGE_VALUE, CHANGE_EDITABLE } from "./generalActions";
import { Query } from "../../../../../shared";

export type InputName = "company" | "group" | "description";
export type ChangedValue = Query | string | null;

export interface GeneralInitialState {
    isEditable: {
        company: boolean;
        group: boolean;
        description: boolean;
    };
    value: {
        company: Query | null;
        group: Query | null;
        description: string;
    };
}

type Action =
    | {
          type: typeof CHANGE_EDITABLE;
          payload: {
              name: InputName;
              value: boolean;
              description: string;
              company_id: number | null;
              company_name: string | null;
              group_id: number | null;
              group_name: string | null;
          };
      }
    | {
          type: typeof CHANGE_VALUE;
          payload: {
              name: InputName;
              value: ChangedValue;
              isUpdate: boolean;
          };
      };

const GeneralReducer = (
    state: GeneralInitialState,
    action: Action
): GeneralInitialState => {
    switch (action.type) {
        case CHANGE_EDITABLE: {
            const {
                name,
                value,
                description,
                company_id,
                company_name,
                group_id,
                group_name
            } = action.payload;

            return {
                ...state,
                isEditable: {
                    company: false,
                    group: false,
                    description: false,
                    [name]: value
                },
                value: {
                    company:
                        company_id !== null && company_name !== null
                            ? { id: company_id, name: company_name }
                            : null,
                    group:
                        group_id !== null && group_name !== null
                            ? { id: group_id, name: group_name }
                            : null,
                    description: description || ""
                }
            };
        }
        case CHANGE_VALUE: {
            const { name, value, isUpdate } = action.payload;

            const resetGroup = isUpdate && name === "company";

            return {
                ...state,
                isEditable: {
                    ...state.isEditable,
                    [name]: isUpdate ? false : state.isEditable[name]
                },
                value: {
                    ...state.value,
                    ...(resetGroup ? { group: null } : {}),
                    [name]: value
                }
            };
        }
        default:
            return state;
    }
};

export default GeneralReducer;
