import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Description from "../components/Description";
import { TriSourceTabProps } from "../types";

import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

import TableContext from "../../../../../../context/table/tableContext";
import TriSourceContext from "../../../../../../context/triSource/triSourceContext";
import { TRI_SOURCES, useFormattedNumber } from "../../../../../../shared";

const Selected = ({ description, setCount }: TriSourceTabProps) => {
    const { tableName, selectedRows } = useContext(TableContext);
    const { handleNeedConfirmation } = useContext(TriSourceContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    const numberOfSelectedRows = selectedRows.length;

    // Check if confirmation is required
    useEffect(() => {
        handleNeedConfirmation(numberOfSelectedRows > 20);
        setCount && setCount(numberOfSelectedRows);
    }, [numberOfSelectedRows, handleNeedConfirmation, setCount]);

    const title = `${t(
        `Dialog##selected ${tableName}`
    )}: ${renderFormattedNumber(numberOfSelectedRows)}`;

    return (
        <div data-testid={`tri-source-${TRI_SOURCES.Selected}`}>
            <Description
                text={t(
                    `Dialog##tri source##selected tab description##${description}`
                )}
            />

            <Tag
                data-testid="tag-selected"
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                title={title}
            />
        </div>
    );
};

export default Selected;
