import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DateTime from "../../../DateTime";
import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import TableContext from "../../../../context/table/tableContext";

const Obd = () => {
    const { detailsData } = useContext(TableContext);

    const {
        obd: { chip_version, vin, period_requests, period_start }
    } = detailsData;

    const { t } = useTranslation();

    return (
        <Fragment>
            <div>{t("Table##obd")}</div>

            <div>
                {/* VIN */}
                <span>{t("Table##vin")}:</span>

                <span>
                    {vin}
                    <CopyButton size={BUTTON_SIZES.Tiny} copiedValue={vin} />
                </span>
                {/* */}

                {/*  Chip version */}
                {chip_version && (
                    <Fragment>
                        <span>{t("Table##obd chip firmware version")}:</span>
                        <span>
                            {chip_version}
                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={chip_version}
                            />
                        </span>
                    </Fragment>
                )}
                {/* */}

                {/* Period requests */}
                {period_requests >= 100 && (
                    <Fragment>
                        <span>{t("Table##exausted vin parsing limit")}:</span>

                        <span>
                            <DateTime date={period_start} />
                        </span>
                    </Fragment>
                )}
                {/* */}
            </div>
        </Fragment>
    );
};

export default Obd;
