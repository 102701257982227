import { useContext, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import AuthContainer from "../AuthContainer";
import PasswordsForm from "../SignUp/PasswordsForm";
import { useTogglePswVisibility } from "../hooks";
import { BUTTON_TYPES, PASSWORD_TYPES } from "../types";

import AuthContext from "../../../context/auth/authContext";
import { ROUTES } from "../../../shared";

const Reset = () => {
    const { isAuthenticated, logoutUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const [toDevices, setToDevices] = useState(false);

    useEffect(() => {
        isAuthenticated && logoutUser();

        //eslint-disable-next-line
    }, [isAuthenticated]);

    const inputs = [
        {
            name: PASSWORD_TYPES.NewPsw,
            title: t("Auth##new password"),
            TooltipPsw: useTogglePswVisibility(PASSWORD_TYPES.NewPsw)
        },
        {
            name: PASSWORD_TYPES.Repeat,
            title: t("Auth##repeat password"),
            TooltipPsw: useTogglePswVisibility(PASSWORD_TYPES.Repeat)
        }
    ];

    const handleRedirection = useCallback(() => setToDevices(true), []);

    return toDevices ? (
        <Navigate to={{ pathname: ROUTES.Devices }} />
    ) : (
        <AuthContainer formName={t("Auth##reset password")}>
            <PasswordsForm
                inputs={inputs}
                button={BUTTON_TYPES.ResetPsw}
                redirectToDevices={handleRedirection}
            />
        </AuthContainer>
    );
};

export default Reset;
