import {
    SET_NOTIFICATIONS_COUNT,
    SET_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    PUSH_PREVIOUS_NOTIFICATION
} from "./notificationsActions";
import { Action, NotificationsInitialState } from "./types";

const NotificationsReducer = (
    state: NotificationsInitialState,
    action: Action
): NotificationsInitialState => {
    switch (action.type) {
        case SET_NOTIFICATIONS_COUNT: {
            const { read, unread } = action.payload;

            return {
                ...state,
                readCount: read,
                unreadCount: unread
            };
        }
        case SET_NOTIFICATIONS: {
            const data = action.payload;

            return {
                ...state,
                notifications: data
            };
        }
        case DELETE_NOTIFICATION: {
            const id = action.payload;
            const notifications = [...state.notifications];

            const updatedNotifications = notifications.map(notification =>
                notification?.id === id ? null : notification
            );

            return {
                ...state,
                notifications: updatedNotifications
            };
        }
        case PUSH_PREVIOUS_NOTIFICATION: {
            const notifications = [...state.notifications];

            const updatedNotifications = notifications.filter(
                notification => notification !== null
            );

            return {
                ...state,
                notifications: updatedNotifications
            };
        }
        default:
            return state;
    }
};

export default NotificationsReducer;
