/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { RevertProps } from "./types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../DesignComponents/BannerMessage";
import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../DesignComponents/TextLink";
import DialogConfirmation from "../../../../../DesignComponents/DialogConfirmation";
import AlertContext, {
    ALERT_STATUS
} from "../../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import { ENDPOINTS, useApi } from "../../../../../../shared";

export const Revert = ({
    title,
    description,
    buttonText,
    confirmationTitle,
    confirmationDescription,
    deleteDataQuery,
    successMessage,
    successCallback
}: RevertProps) => {
    const { setAlert } = useContext(AlertContext);
    const { rootCompany } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { deleteData, handleResponse } = useApi();

    const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
        useState(false);

    const [isLoading, setLoading] = useState(false);

    const openConfirmationDialog = () => setOpenConfirmationDialog(true);
    const closeConfirmationDialog = () => setOpenConfirmationDialog(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);

            await deleteData(
                `${ENDPOINTS.Companies}`,
                rootCompany?.id as number,
                deleteDataQuery
            );

            successCallback();
            closeConfirmationDialog();

            setAlert({ status: ALERT_STATUS.Success, title: successMessage });
        } catch (error) {
            handleResponse(error);
        }

        setLoading(false);
    };

    return (
        <>
            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={title}
                description={
                    <>
                        <span>{description}</span>

                        <br />

                        <TextLink
                            size={TEXT_LINK_SIZES.Small}
                            onClick={openConfirmationDialog}
                            css={css({
                                marginTop: "4px"
                            })}
                        >
                            {buttonText}
                        </TextLink>
                    </>
                }
            />

            <DialogConfirmation
                isOpen={isOpenConfirmationDialog}
                title={confirmationTitle}
                description={confirmationDescription}
                isLoading={isLoading}
                onClose={closeConfirmationDialog}
                onSubmit={handleSubmit}
                confirmBtnTitle={t("Button##yes")}
            />
        </>
    );
};
