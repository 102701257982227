/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";

import { TableLoaderProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import { generateArrayOfNumbers, useMediaQueries } from "../../../shared";

const TableLoader = ({ hideHeader }: TableLoaderProps) => {
    const {
        colorsFacelift: { blue100, blue200, gray100, gray200, white }
    } = useContext(ThemeContext);

    const { isInnerTable } = useContext(TableContext);

    const { toMd } = useMediaQueries();

    const [rowsCount, setRowsCount] = useState(0);

    const skeletonHeightWhenHeaderIsHidden = toMd ? 56 : 44;

    const arrayOfNumbers = generateArrayOfNumbers(rowsCount);

    return (
        <div
            className="remaining-height"
            css={css({
                position: "relative",
                overflow: "hidden"
            })}
        >
            <div
                ref={(node: HTMLDivElement) => {
                    if (node) {
                        const height = node.clientHeight;

                        if (height !== 0) {
                            const getRows = () => {
                                if (toMd) {
                                    return Math.ceil(height / 56);
                                }

                                return (
                                    Math.floor(height / 44) -
                                    (isInnerTable ? 0 : 2)
                                );
                            };

                            const rows = getRows();
                            setRowsCount(rows);
                        }
                    }
                }}
                css={css({
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0"
                })}
            >
                {hideHeader ? (
                    <Skeleton
                        variant="rectangular"
                        height={skeletonHeightWhenHeaderIsHidden}
                        css={css({
                            background: white,
                            borderTop: `1px solid ${gray200}`,
                            borderBottom: `1px solid ${gray200}`
                        })}
                    />
                ) : (
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={42}
                        css={css({
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            boxSizing: "border-box",
                            background: blue100,
                            borderTop: `1px solid ${blue200}`,
                            borderBottom: `1px solid ${blue200}`
                        })}
                    />
                )}

                <div
                    css={css({
                        flex: "1"
                    })}
                >
                    {arrayOfNumbers.map((item, i) => {
                        const everySeventh = (i + 1) % 7 === 0;

                        return (
                            <Fragment key={item}>
                                <Skeleton
                                    variant="rectangular"
                                    height={toMd ? 55 : 43}
                                    css={css({
                                        background: white,
                                        borderBottom: `1px solid ${gray200}`
                                    })}
                                />

                                {toMd && everySeventh && (
                                    <div
                                        css={css({
                                            height: "8px",
                                            background: gray100
                                        })}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TableLoader;
