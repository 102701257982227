import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SpecificLogsProps } from "./types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../../DesignComponents/InputField";

const SpecificLogs = ({ order, change }: SpecificLogsProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setError(null);
    }, [order]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        const regexValue = /^(?:\d+,){0,9}\d+$/;

        const isCorrectValue = regexValue.test(newValue);

        const errorValue =
            newValue && !isCorrectValue ? t("Dialog##invalid value") : null;

        const getPendingValue = () => {
            const valueArray = newValue.split(",");

            return valueArray.filter(element => element.length > 0);
        };

        const pendingValue = isCorrectValue
            ? {
                  count: String(getPendingValue().length),
                  pending: getPendingValue()
              }
            : null;

        setError(errorValue);
        change(pendingValue);
    };

    return (
        <InputField
            fullWidth
            required
            size={INPUT_FIELD_SIZES.Medium}
            labelLeft="Rows to download"
            placeholder="1,2,3,4... (max 10 values split by ',')"
            errorText={error}
            onChange={handleChange}
        />
    );
};

export default SpecificLogs;
