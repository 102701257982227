import { useContext } from "react";

import CompanyFilterContent from "./CompanyFilterContent";
import { FilterMobileProps } from "./types";

import DialogCustom from "../../DesignComponents/DialogCustom";
import TutorialContext from "../../../context/tutorial/tutorialContext";

const FilterMobile = ({ isOpen, isLoading, close }: FilterMobileProps) => {
    const { isTutorialActive, setTutorialOpen } = useContext(TutorialContext);

    return (
        <DialogCustom
            noPadding
            open={isOpen}
            close={close}
            TransitionProps={{
                onEntered: () => isTutorialActive && setTutorialOpen(true)
            }}
        >
            <CompanyFilterContent isLoading={isLoading} close={close} />
        </DialogCustom>
    );
};

export default FilterMobile;
