import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompanyLevelIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M3 20V19L5 17V20C5 20.2833 4.90417 20.5208 4.7125 20.7125C4.52083 20.9042 4.28333 21 4 21C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20ZM7 20V15L9 13V20C9 20.2833 8.90417 20.5208 8.7125 20.7125C8.52083 20.9042 8.28333 21 8 21C7.71667 21 7.47917 20.9042 7.2875 20.7125C7.09583 20.5208 7 20.2833 7 20ZM11 20V13L13 15.025V20C13 20.2833 12.9042 20.5208 12.7125 20.7125C12.5208 20.9042 12.2833 21 12 21C11.7167 21 11.4792 20.9042 11.2875 20.7125C11.0958 20.5208 11 20.2833 11 20ZM15 20V15.025L17 13.025V20C17 20.2833 16.9042 20.5208 16.7125 20.7125C16.5208 20.9042 16.2833 21 16 21C15.7167 21 15.4792 20.9042 15.2875 20.7125C15.0958 20.5208 15 20.2833 15 20ZM19 20V11L21 8.99999V20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21C19.7167 21 19.4792 20.9042 19.2875 20.7125C19.0958 20.5208 19 20.2833 19 20ZM3.3 12.7L8.575 7.42499C8.95833 7.04165 9.43333 6.84999 10 6.84999C10.5667 6.84999 11.0417 7.04165 11.425 7.42499L14 9.99999L19.3 4.69999C19.5 4.49999 19.7375 4.40415 20.0125 4.41249C20.2875 4.42082 20.525 4.52499 20.725 4.72499C20.9083 4.92499 20.9958 5.16249 20.9875 5.43749C20.9792 5.71249 20.8833 5.94165 20.7 6.12499L15.425 11.4C15.0417 11.7833 14.5667 11.975 14 11.975C13.4333 11.975 12.9583 11.7833 12.575 11.4L10 8.82499L4.7 14.125C4.5 14.325 4.2625 14.4208 3.9875 14.4125C3.7125 14.4042 3.475 14.3 3.275 14.1C3.09167 13.9 3.00417 13.6625 3.0125 13.3875C3.02083 13.1125 3.11667 12.8833 3.3 12.7Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default CompanyLevelIcon;
