import {
    FIND_TOOL_TAB_LABELS,
    FIND_TOOL_TAB_VALUES,
    SEARCH_VALUES
} from "../types";
import DevicesIcon from "../../../../assets/customIcons/navigation/DevicesIcon";
import UsersIcon from "../../../../assets/customIcons/navigation/UsersIcon";
import { ROUTES } from "../../../../shared";

export const FIND_TOOL_TABS = [
    {
        label: FIND_TOOL_TAB_LABELS.Device,
        searchResource: SEARCH_VALUES.Imei,
        value: FIND_TOOL_TAB_VALUES.Device,
        resourceRoute: ROUTES.Devices,
        icon: DevicesIcon
    },
    {
        label: FIND_TOOL_TAB_LABELS.User,
        searchResource: SEARCH_VALUES.Email,
        value: FIND_TOOL_TAB_VALUES.User,
        resourceRoute: ROUTES.Users,
        icon: UsersIcon
    }
];
