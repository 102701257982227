import { useTranslation } from "react-i18next";

import { LEVEL_CHANGES } from "./constants";
import { RevertLevelProps } from "./types";

import { Revert } from "../shared/Revert";

const RevertLevel = ({ date, defaultLevel, resetData }: RevertLevelProps) => {
    const { t } = useTranslation();

    return (
        <Revert
            title={t("Dialog##company level manual revert##title", {
                date
            })}
            description={t("Dialog##company level manual revert##description")}
            buttonText={t("Button##revert manual level change")}
            confirmationTitle={t(
                "Dialog##company level manual revert##confirmation##title"
            )}
            confirmationDescription={t(
                "Dialog##company level manual revert##confirmation##description",
                { level: defaultLevel }
            )}
            deleteDataQuery={LEVEL_CHANGES}
            successMessage={t("Flash##company level successfully reverted")}
            successCallback={() => resetData(false)}
        />
    );
};

export default RevertLevel;
