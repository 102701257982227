/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
    TOOLTIP_PLACEMENT,
    TooltipLarge
} from "../../DesignComponents/Tooltips";
import ThemeContext from "../../../context/theme/themeContext";
import InfoIcon from "../../../assets/customIcons/status/InfoIcon";
import {
    COMPANY_LEVEL_SETTINGS,
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../shared";

interface RestrictionInfoProps {
    restriction: FEATURE_SETTINGS | undefined;
    isLarge?: boolean;
    css?: SerializedStyles;
}

const RestrictionInfo = ({
    restriction,
    isLarge,
    ...props
}: RestrictionInfoProps) => {
    const {
        colorsFacelift: { blue700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const isRestricted = isFeatureSettingsRestricted(restriction);

    return isRestricted ? (
        <TooltipLarge
            title={t(
                `Restrictions##${COMPANY_LEVEL_SETTINGS.FeatureSettings}##${restriction}##table head`
            )}
            placement={TOOLTIP_PLACEMENT.Top}
        >
            <span {...props} css={css({ display: "flex" })}>
                <InfoIcon
                    css={css({
                        color: blue700,
                        fontSize: isLarge ? "24px" : "16px"
                    })}
                />
            </span>
        </TooltipLarge>
    ) : null;
};

export default RestrictionInfo;
