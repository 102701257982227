/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import InputField, {
    ClearInputIcon,
    INPUT_FIELD_SIZES
} from "../DesignComponents/InputField";
import {
    TooltipGeneral,
    TOOLTIP_PLACEMENT
} from "../DesignComponents/Tooltips";

import ParamsContext from "../../context/params/paramsContext";
import TableContext from "../../context/table/tableContext";
import {
    FIND_TOOL_FILTER,
    NOT_FILTER_NAMES,
    RectangleLoader,
    removeFindToolFilterFromStorage,
    useDebounce,
    useMediaQueries,
    useParamsFromUrlAndBrowser
} from "../../shared";

const Search = () => {
    const { setQueryParams } = useContext(ParamsContext);

    const {
        isInnerTable,
        tableName,
        innerTableQueryParams,
        isInitialLoading,
        setInnerTableParams
    } = useContext(TableContext);

    const { t } = useTranslation();
    const { currentParams } = useParamsFromUrlAndBrowser();
    const { toMd } = useMediaQueries();

    const isFirstRender = useRef(true);

    const query = (
        isInnerTable
            ? innerTableQueryParams.query
            : currentParams[NOT_FILTER_NAMES.Query]
    ) as string | undefined;

    const [value, setValue] = useState<string>(query || "");

    const debouncedValue = useDebounce(value, 500);

    // Reset value
    useEffect(() => {
        !query && value.length >= 3 && setValue("");
        // eslint-disable-next-line
    }, [query]);

    useEffect(() => {
        const resetPagination =
            !isFirstRender.current &&
            (Boolean(query) || debouncedValue.length >= 3);

        (isInnerTable ? setInnerTableParams : setQueryParams)({
            params: { query: debouncedValue },
            resetPage: resetPagination
        });

        isFirstRender.current = false;

        // eslint-disable-next-line
    }, [debouncedValue]);

    const findToolResource = localStorage.getItem(FIND_TOOL_FILTER);

    useEffect(() => {
        findToolResource && setValue(findToolResource);

        removeFindToolFilterFromStorage();
    }, [findToolResource]);

    const changeValue = (e: { target: { value: string } }) =>
        setValue(e.target.value);

    const clearValue = () => setValue("");

    return isInitialLoading ? (
        <RectangleLoader
            customStyle={{
                width: "246px",

                "@media(max-width: 1099px)": {
                    width: "65%"
                },

                "@media(max-width: 839px)": {
                    width: "unset",
                    flex: "1"
                }
            }}
            height={36}
        />
    ) : (
        <TooltipGeneral
            placement={toMd ? TOOLTIP_PLACEMENT.Top : TOOLTIP_PLACEMENT.Right}
            title={t(`Search##search in ${tableName}`)}
            disableInteractive
        >
            <span
                css={css({
                    width: "246px",

                    "@media(max-width: 1099px)": {
                        width: "65%"
                    },

                    "@media(max-width: 839px)": {
                        width: "unset",
                        flex: "1"
                    }
                })}
            >
                <InputField
                    fullWidth
                    size={INPUT_FIELD_SIZES.Small}
                    value={value}
                    onChange={changeValue}
                    iconLeft={<SearchRoundedIcon />}
                    iconRight={
                        <ClearInputIcon value={value} clearValue={clearValue} />
                    }
                    placeholder={t("Search##search")}
                    css={css({
                        width: "100%"
                    })}
                />
            </span>
        </TooltipGeneral>
    );
};

export default Search;
