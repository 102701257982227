import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import Button from "../../DesignComponents/Button";
import {
    TooltipGeneral,
    TOOLTIP_PLACEMENT
} from "../../DesignComponents/Tooltips";
import {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button/types/enums";

const RightArrowButton = ({ ...props }) => {
    const { t } = useTranslation();

    return (
        <TooltipGeneral
            placement={TOOLTIP_PLACEMENT.Top}
            title={t("Button##next month")}
            disableTouchListener
        >
            <span>
                <Button
                    {...props}
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Tiny}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<ChevronRightRoundedIcon />}
                />
            </span>
        </TooltipGeneral>
    );
};

export default RightArrowButton;
