import { useTableFunctions } from "../../hooks";
import DateTime from "../../../DateTime";

const ScheduleData = ({ data }: any) => {
    const { scheduleTimeFrom, scheduleTimeTo } = useTableFunctions();

    return (
        <>
            {data.schedule ? (
                <span>
                    <DateTime date={scheduleTimeFrom(data)} format="LT" /> -{" "}
                    <DateTime date={scheduleTimeTo(data)} format="LT" />
                </span>
            ) : (
                "-"
            )}
        </>
    );
};

export default ScheduleData;
