import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const MoveIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <g>
                <g>
                    <path
                        d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V6H9.17L11.17 8H20V18ZM13.41 15.59L12 17L8 13L12 9L13.41 10.41L11.83 12H16V14H11.83L13.41 15.59Z"
                        fill="currentColor"
                    />
                </g>
            </g>

            <defs>
                <clipPath>
                    <rect width="24" height="24" fill={white} />
                </clipPath>

                <clipPath>
                    <rect width="24" height="24" fill={white} />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export default MoveIcon;
