/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, SyntheticEvent } from "react";
import MuiDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { CombineDialogNewFeatureProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import ThemeContext from "../../../context/theme/themeContext";
import { useMediaQueries } from "../../../shared";

const DialogNewFeature = ({
    title,
    description,
    image,
    actions,
    close,
    submit,
    ...props
}: CombineDialogNewFeatureProps) => {
    const {
        colorsFacelift: { textDark, gray700 }
    } = useContext(ThemeContext);

    const { toMd } = useMediaQueries();

    const handleSubmit = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        submit?.();
    };

    return (
        <MuiDialog
            {...props}
            onClose={close}
            fullScreen={toMd}
            sx={{
                "& .MuiDialog-paper": {
                    width: toMd ? "100%" : "448px",
                    maxWidth: toMd ? "unset" : "684px",
                    minWidth: toMd ? "unset" : "448px",
                    borderRadius: toMd ? "0" : "12px"
                }
            }}
        >
            {close && (
                <Button
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<CloseIcon onClick={close} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px"
                    })}
                />
            )}

            <div
                css={css({
                    padding: "16px 24px",
                    textAlign: "center"
                })}
            >
                <div>
                    {image}

                    <div
                        css={css({
                            color: textDark,
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            margin: "16px 0 8px 0"
                        })}
                    >
                        {title}
                    </div>

                    <div
                        css={css({
                            color: gray700,
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginBottom: "16px"
                        })}
                    >
                        {description}
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div
                        css={css({
                            display: "grid",
                            rowGap: "8px"
                        })}
                    >
                        {actions}
                    </div>
                </form>
            </div>
        </MuiDialog>
    );
};

export default DialogNewFeature;
