import { BrowserRouter } from "react-router-dom";

import Routes from "./components/Routes";
import ThemeState from "./context/theme/ThemeState";
import AlertState from "./context/alert/AlertState";
import TimeZoneState from "./context/timeZone/TimeZoneState";
import ParamsState from "./context/params/ParamsState";
import AuthState from "./context/auth/AuthState";
import LanguageState from "./context/language/LanguageState";
import TutorialState from "./context/tutorial/TutorialState";
import DropzoneState from "./context/dropzone/DropzoneState";
import RoutesState from "./context/routes/RoutesState";

const App = () => {
    return (
        <BrowserRouter>
            <ThemeState>
                <AlertState>
                    <TimeZoneState>
                        <ParamsState>
                            <AuthState>
                                <LanguageState>
                                    <TutorialState>
                                        <DropzoneState>
                                            <RoutesState>
                                                <Routes />
                                            </RoutesState>
                                        </DropzoneState>
                                    </TutorialState>
                                </LanguageState>
                            </AuthState>
                        </ParamsState>
                    </TimeZoneState>
                </AlertState>
            </ThemeState>
        </BrowserRouter>
    );
};

export default App;
