import { useContext } from "react";
import Link from "@mui/material/Link";
import RightIcon from "@mui/icons-material/ChevronRightRounded";

import { CombinedTextLinkProps, TEXT_LINK_COLORS } from "./types";
import { useStyleOptions } from "./hooks";

import ThemeContext from "../../../context/theme/themeContext";

const TextLink = ({
    children,
    size,
    color = TEXT_LINK_COLORS.Primary,
    isIcon,
    isDisabled,
    ...props
}: CombinedTextLinkProps) => {
    const {
        colorsFacelift: { textDarkDisabled }
    } = useContext(ThemeContext);

    const styleOptions = useStyleOptions(color);

    const colorDefault = isDisabled
        ? textDarkDisabled
        : styleOptions.color.default;

    const colorHover = styleOptions.color.hover;
    const colorActive = styleOptions.color.active;
    const fontSize = styleOptions.fontSize[size];
    const lineHeight = styleOptions.lineHeight[size];
    const letterSpacing = styleOptions.letterSpacing[size];

    return (
        <Link
            {...props}
            rel="noreferrer"
            sx={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "600",
                color: colorDefault,
                textDecorationColor: "inherit",
                pointerEvents: isDisabled ? "none" : "auto",
                fontSize,
                lineHeight,
                letterSpacing,
                transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "& > svg": {
                    fontSize: "16px",
                    marginLeft: "2px"
                },

                "&:hover": {
                    color: colorHover
                },

                "&:active": {
                    color: colorActive
                }
            }}
        >
            {children}

            {isIcon && <RightIcon />}
        </Link>
    );
};

export { TEXT_LINK_SIZES } from "./types";

export default TextLink;
