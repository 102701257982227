import { useApi } from "./useApi";

import { ENDPOINTS } from "../types";

import LogoIcon from "../../assets/logos/SymbolLogo.svg";

export const useTct = (close?: () => void) => {
    const { getData, handleResponse } = useApi();

    const redirectToTct = async (endpoint: string) => {
        let newWindow;

        try {
            newWindow = window.open("", "_blank");

            if (newWindow) {
                newWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Loading...</title>

                        <style>
                            html, body {
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                            }
                                
                            .tct-loader-container {
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .tct-loader {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .tct-loader img {
                                animation-name: pulse;
                                animation-duration: 1.5s;
                                animation-timing-function: linear;
                                animation-iteration-count: infinite;
                                animation-direction: alternate;
                            }

                            @keyframes pulse {
                                from { 
                                    transform: scale(1); 
                                }
                                to { 
                                    transform: scale(1.3); 
                                }
                            }
                        </style>
                    </head>

                    <body>
                        <div class="tct-loader-container">
                            <div class="tct-loader">
                                <img src="${LogoIcon}" alt="Loading..." />
                            </div>
                        </div>
                    </body>
                `);

                const result = await getData(`${ENDPOINTS.Files}/${endpoint}`);

                newWindow.location.href = result.data;

                close && close();
            }
        } catch (error) {
            handleResponse(error);

            if (newWindow && !newWindow.closed) {
                newWindow.close();
            }
        }
    };

    const createConfigurationOnline = () => redirectToTct("create");

    const editConfigurationOnline = (fileId: number) =>
        redirectToTct(`edit/${fileId}`);

    return { createConfigurationOnline, editConfigurationOnline };
};
