/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@mui/material";
import { useContext } from "react";

import ThemeContext from "../../../../../../context/theme/themeContext";
import { TRI_SOURCES } from "../../../../../../shared";

const FilteredTagLoader = () => {
    const {
        colorsFacelift: { blue100 }
    } = useContext(ThemeContext);

    return (
        <Skeleton
            data-testid={`tri-source-${TRI_SOURCES.Filtered}-tag-loader`}
            variant="rectangular"
            width={200}
            height={32}
            css={css({
                background: blue100,
                borderRadius: "4px"
            })}
        />
    );
};

export default FilteredTagLoader;
