/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import { CONTENT_NAMES, SelectedOptionProps } from "./types";

import ListChoice from "../../../../DesignComponents/ListChoice";
import ThemeContext from "../../../../../context/theme/themeContext";

const GoBack = ({ setContent }: SelectedOptionProps) => {
    const {
        colorsFacelift: { white, blue700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <ListChoice
            title={t("Button##go back")}
            iconLeft={<ChevronLeftRoundedIcon />}
            onClick={() => setContent(CONTENT_NAMES.Initial)}
            css={css({
                background: white,
                color: blue700
            })}
        />
    );
};

export default GoBack;
