export const dummyTasksTableData = {
    rows: [
        {
            id: 508,
            type: "TaskBulkCreate",
            source: "id_list",
            attributes: '{"filter":null,"file_path":null}',
            objects: 2,
            objects_processed: 2,
            company_id: 101,
            created_by: {
                id: 5685,
                email: "jonas.jonaitis@teltonika.lt",
                company_id: 101,
                role: 10,
                table_refresh: 0,
                language: "en-US",
                invited_by: 2486,
                deleted_at: null,
                created_at: "2022-10-10 08:07:25",
                updated_at: "2023-03-14 15:20:55",
                last_login: "2023-03-15 06:59:01",
                type: null
            },
            created_at: "2023-03-14 12:04:23",
            updated_at: "2023-03-14 12:04:29",
            approvable: 0,
            verified_by: 5685,
            task_type: "RxConfiguration",
            task_file_name: null,
            updated_by: null,
            task_stats: [
                {
                    status_id: 0,
                    count: 2
                }
            ]
        },
        {
            id: 504,
            type: "TaskBulkCreate",
            source: "filter",
            attributes: '{"filter":[{"group_id":["190"]}],"file_path":null}',
            objects: 2,
            objects_processed: 2,
            company_id: 101,
            created_by: {
                id: 5618,
                email: "jonas.jonaitis@teltonika.lt",
                company_id: 101,
                role: 10,
                table_refresh: 0,
                language: "en-US",
                invited_by: 3876,
                deleted_at: null,
                created_at: "2021-09-13 07:43:03",
                updated_at: "2021-09-13 07:45:32",
                last_login: "2023-03-15 08:15:15",
                type: null
            },
            created_at: "2023-03-09 11:43:59",
            updated_at: "2023-03-09 11:44:01",
            approvable: 0,
            verified_by: 5618,
            task_type: "TxConfiguration",
            task_file_name: null,
            updated_by: null,
            task_stats: [
                {
                    status_id: 4,
                    count: 2
                }
            ]
        }
    ],
    currentPage: 1,
    rowsPerPage: 25,
    totalRows: 2
};

const setTaskDetailsTableRowData = (id: number, device_imei: number) => {
    return {
        id,
        device_imei,
        company_id: 101,
        type: "RxConfiguration",
        attributes: null,
        status_id: 0,
        attempt_count: 0,
        execution_time: 0,
        created_at: "2023-03-14 12:04:28",
        updated_at: "2023-03-14 12:04:28",
        deleted_at: null,
        updated_by: {
            id: 5685,
            email: "jonas.jonaitis@teltonika.lt",
            company_id: 101,
            role: 10,
            table_refresh: 0,
            language: "en-US",
            invited_by: 2486,
            deleted_at: null,
            created_at: "2022-10-10 08:07:25",
            updated_at: "2023-03-14 15:20:55",
            last_login: "2023-03-15 06:59:01",
            type: null
        },
        file_id: null,
        created_by: {
            id: 5685,
            email: "jonas.jonaitis@teltonika.lt",
            company_id: 101,
            role: 10,
            table_refresh: 0,
            language: "en-US",
            invited_by: 2486,
            deleted_at: null,
            created_at: "2022-10-10 08:07:25",
            updated_at: "2023-03-14 15:20:55",
            last_login: "2023-03-15 06:59:01",
            type: null
        },
        batch_id: 508,
        status_name: "Pending",
        company: {
            code: null,
            id: 101,
            name: "Teltonika",
            company_id: 1,
            deleted_at: null,
            created_at: "2017-08-25 11:05:53",
            updated_at: "2022-03-08 09:38:18",
            created_by: 2407,
            updated_by: 2185
        },
        file: null,
        schedule: {
            type: "specific_hours",
            attributes: {
                from_minutes: 390,
                duration_minutes: 630
            }
        }
    };
};

export const dummyTasksDetailsTableRowData = [
    {
        device: {
            imei: 300434065739680,
            serial: 7777777777,
            status_id: 2,
            model: null,
            spec_id: null,
            current_configuration: null,
            current_firmware: null,
            description: " test",
            company_id: 101,
            group_id: 190,
            seen_at: null,
            created_at: "2023-03-08 14:27:26",
            updated_at: "2023-03-14 14:37:06",
            created_by: 5618,
            updated_by: 5618,
            gsm_number: null,
            last_sync: null,
            iccid: null,
            imsi: null,
            ble_firmware: null,
            product_code: null,
            status_name: "Pending",
            company_name: "Teltonika",
            group_name: "Edgaro grupė",
            next_task: {
                attempt_count: 0,
                attributes: null,
                batch_id: 508,
                company_id: 101,
                created_at: "2023-03-14 12:04:28",
                created_by: 5685,
                deleted_at: null,
                device_imei: 300434065739680,
                execution_time: 0,
                file_id: null,
                id: 379697,
                status_id: 0,
                status_name: "Pending",
                type: "RxConfiguration",
                updated_at: "2023-03-14 12:04:28",
                updated_by: 5685,
                schedule: {
                    type: "specific_hours",
                    attributes: {
                        from_minutes: 390,
                        duration_minutes: 630
                    }
                }
            },
            company: {
                code: null,
                id: 101,
                name: "Teltonika",
                company_id: 1,
                deleted_at: null,
                created_at: "2017-08-25 11:05:53",
                updated_at: "2022-03-08 09:38:18",
                created_by: 2407,
                updated_by: 2185
            },
            group: {
                id: 190,
                name: "Edgaro grupė",
                company_id: 101,
                firmware_file_id: null,
                configuration_file_id: null,
                deleted_at: null,
                created_at: "2022-05-25 12:05:17",
                updated_at: "2023-03-09 11:43:59",
                created_by: 5618,
                updated_by: 5618
            },
            activity_status: "Inactive",
            task_queue: "Pending",
            modem_version: null
        },
        ...setTaskDetailsTableRowData(379697, 300434065739680)
    },
    {
        device: {
            imei: 350424062601784,
            serial: 4062601784,
            status_id: 2,
            model: null,
            spec_id: null,
            current_configuration: null,
            current_firmware: null,
            description: "",
            company_id: 101,
            group_id: null,
            seen_at: null,
            created_at: "2022-05-25 07:20:56",
            updated_at: "2023-03-14 12:04:28",
            created_by: 1166,
            updated_by: 5685,
            gsm_number: null,
            last_sync: null,
            iccid: null,
            imsi: null,
            ble_firmware: null,
            product_code: null,
            status_name: "Pending",
            company_name: "Teltonika",
            group_name: null,
            next_task: {
                attempt_count: 0,
                attributes: null,
                batch_id: 508,
                company_id: 101,
                created_at: "2023-03-14 12:04:28",
                created_by: 5685,
                deleted_at: null,
                device_imei: 350424062601784,
                execution_time: 0,
                file_id: null,
                id: 379696,
                status_id: 0,
                status_name: "Pending",
                type: "RxConfiguration",
                updated_at: "2023-03-14 12:04:28",
                updated_by: 5685,
                schedule: {
                    type: "specific_hours",
                    attributes: {
                        from_minutes: 390,
                        duration_minutes: 630
                    }
                }
            },
            company: {
                code: null,
                id: 101,
                name: "Teltonika",
                company_id: 1,
                deleted_at: null,
                created_at: "2017-08-25 11:05:53",
                updated_at: "2022-03-08 09:38:18",
                created_by: 2407,
                updated_by: 2185
            },
            group: null,
            activity_status: "Inactive",
            task_queue: "Pending",
            modem_version: null
        },
        ...setTaskDetailsTableRowData(379696, 350424062601784)
    }
];
