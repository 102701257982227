export enum EDIT_VALUE_NAMES {
    ClientIp = "client_ip",
    Enabled = "enabled",
    ExpiresAt = "expires_at",
    Name = "name"
}

export enum STATUS {
    Enabled = "enabled",
    Disabled = "disabled"
}
