import { SyntheticEvent, useContext, useEffect, useState } from "react";

import Header from "./Header";
import TabSection from "./TabSection";
import Details from "./Details";
import OfflineLicenses from "./OfflineLicenses";
import { TABS } from "./types";

import TableContext from "../../../context/table/tableContext";

const LicenseDetails = () => {
    const {
        detailsData: { enabled }
    } = useContext(TableContext);

    const [tabValue, setTabValue] = useState(TABS.LicenseDetails);

    useEffect(() => {
        !enabled && setTabValue(TABS.LicenseDetails);
    }, [enabled]);

    const handleTabChange = (_: SyntheticEvent, newValue: TABS) =>
        setTabValue(newValue);

    const isDetailsTabSelected = tabValue === TABS.LicenseDetails;

    return (
        <>
            <Header isDetailsTabSelected={isDetailsTabSelected} />

            <TabSection
                tabValue={tabValue}
                isEnabled={enabled}
                handleTabChange={handleTabChange}
            />

            {isDetailsTabSelected ? <Details /> : <OfflineLicenses />}
        </>
    );
};

export default LicenseDetails;
