import { useTranslation } from "react-i18next";

import { OtpInputFieldProps } from "../types";

import InputField, {
    INPUT_FIELD_SIZES
} from "../../DesignComponents/InputField";

export const OtpInputField = ({
    isLoading,
    error,
    onChange,
    isRequired
}: OtpInputFieldProps) => {
    const { t } = useTranslation();

    return (
        <InputField
            autoFocus
            autoComplete="one-time-code"
            fullWidth
            required={isRequired}
            disabled={isLoading}
            errorText={error}
            labelLeft={t("Password##six digit code")}
            placeholder={t("Dialog##enter")}
            size={INPUT_FIELD_SIZES.Medium}
            onChange={e => onChange(e.target.value)}
        />
    );
};
