/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import DialogWithConfirmation from "../../../components/DialogWithConfirmation";
import DialogActions from "../../../components/DialogActions";

import { DEVICE_ACTIONS, DialogProps } from "../../../../types";

import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

import AlertContext from "../../../../../../context/alert/alertContext";
import TableContext from "../../../../../../context/table/tableContext";
import {
    ENDPOINTS,
    maxDialogWidth,
    TRI_SOURCES,
    useApi,
    useFormattedNumber,
    useMediaQueries
} from "../../../../../../shared";

const CancelTasksForSelection = ({ isOpen, close }: DialogProps) => {
    const { setBackgroundActionId } = useContext(AlertContext);
    const { selectedRows, deselectAllRows } = useContext(TableContext);

    const { bulkCancel, handleResponse, updateData } = useApi();
    const { renderFormattedNumber } = useFormattedNumber();
    const { fromMd, toMd } = useMediaQueries();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);

    const resourceCount = selectedRows?.length;
    const isSingle = resourceCount === 1;

    const getPayload = (singleSelected: boolean) => {
        return singleSelected
            ? { status_id: 4 }
            : {
                  source: TRI_SOURCES.Selected,
                  [TRI_SOURCES.Selected]: selectedRows
              };
    };

    const handleCloseCancelTasksDialog = () =>
        close(DEVICE_ACTIONS.CancelTasks);

    const submitCancelTask = async () => {
        try {
            setLoading(true);

            const response = await (isSingle
                ? updateData(
                      ENDPOINTS.Tasks,
                      selectedRows[0],
                      getPayload(isSingle)
                  )
                : bulkCancel(ENDPOINTS.Tasks, getPayload(isSingle)));

            if (isSingle) {
                deselectAllRows();
                handleCloseCancelTasksDialog();
                handleResponse(response);
            } else {
                setBackgroundActionId(
                    response.data,
                    () => {
                        deselectAllRows();
                        handleCloseCancelTasksDialog();
                    },
                    () => setLoading(false)
                );
            }
        } catch (error) {
            handleResponse(error);
            setLoading(false);
        }
    };

    const description = t(
        "Dialog##confirmations##cancel task confirmation##first part"
    );

    const confirmationDescription = `
        ${description} 
        ${t("Dialog##confirmations##cancel task confirmation##second part")}
    `;

    const isBulkActionLoading = !isSingle && isLoading;

    return (
        <DialogWithConfirmation
            data-testid="cancel-tasks-for-selection-dialog"
            title={t("Dialog##cancel tasks")}
            description={t(
                "Dialog##confirmations##cancel task confirmation##first part"
            )}
            isActionsSeparator
            isTitleSeparator
            open={isOpen}
            count={resourceCount}
            source={TRI_SOURCES.Selected}
            confirmationDescription={confirmationDescription}
            submit={submitCancelTask}
            close={handleCloseCancelTasksDialog}
            isBulkActionLoading={isBulkActionLoading}
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
            actions={
                <>
                    <DialogActions
                        onClose={handleCloseCancelTasksDialog}
                        isLoading={isLoading}
                        loaderText={
                            !isSingle ? t("Button##cancelling") : undefined
                        }
                        isCancelDisabled={isBulkActionLoading}
                        isReadyToConfirm
                        confirmationButtonText={t("Button##confirm")}
                        cancelButtonTestId="cancel-cancel-tasks"
                        confirmationButtonTestId="confirm-cancel-tasks"
                    />
                </>
            }
            TransitionProps={{
                onExited: () => setLoading(false)
            }}
        >
            <Tag
                data-testid="cancel-tasks-number"
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                title={`${t("Dialog##selected tasks")}: ${renderFormattedNumber(
                    resourceCount
                )}`}
            />
        </DialogWithConfirmation>
    );
};
export default CancelTasksForSelection;
