import { useContext } from "react";

import {
    DeviceDetailTaskFilters,
    DeviceFilters,
    FileFilters,
    GroupFilters,
    TaskGroupDetailTaskFilters,
    TaskGroupFilters
} from "./PageFilters";

import TableContext from "../../context/table/tableContext";
import { TABLE_NAMES_WITH_FILTERS } from "../../shared";

const Filters = () => {
    const { tableNameForFilters } = useContext(TableContext);

    const renderContent = () => {
        switch (tableNameForFilters) {
            case TABLE_NAMES_WITH_FILTERS.Devices:
                return <DeviceFilters />;
            case TABLE_NAMES_WITH_FILTERS.DeviceDetailTasks:
                return <DeviceDetailTaskFilters />;
            case TABLE_NAMES_WITH_FILTERS.TaskGroups:
                return <TaskGroupFilters />;
            case TABLE_NAMES_WITH_FILTERS.TaskGroupDetailTasks:
                return <TaskGroupDetailTaskFilters />;
            case TABLE_NAMES_WITH_FILTERS.DeviceGroups:
                return <GroupFilters />;
            case TABLE_NAMES_WITH_FILTERS.Files:
                return <FileFilters />;
            default:
                return <div />;
        }
    };

    return renderContent();
};

export default Filters;
