import { PopoverOrigin } from "@mui/material/Popover";

const centeredAnchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "center"
};

const centeredTransformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center"
};

export { centeredAnchorOrigin, centeredTransformOrigin };
