import { useContext, createContext, forwardRef, useRef, Children } from "react";
import { VariableSizeList } from "react-window";

import { Ref } from "../../types";
import RenderRow from "../../../components/RenderRow";

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<Ref, {}>((props, ref) => {
    const outerProps = useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
});

const RootCompanyListBox = forwardRef<Ref, any>((props, ref) => {
    const { children, ...other } = props;

    const rawData = Children.toArray(children);
    const itemData = rawData.map((item: {}) => ({
        ...item,
        type: "div"
    }));

    const itemCount = itemData.length;
    const itemSize = 60;

    const listRef = useRef<any>({});
    const rowHeights = useRef<any>({});

    const getHeight = () => {
        if (itemCount > 4) {
            return 240;
        }

        return itemCount * itemSize;
    };

    const getRowHeight = (i: number): number => {
        return rowHeights.current[i] || itemSize;
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    ref={listRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemCount={itemCount}
                    itemSize={getRowHeight}
                >
                    {props => RenderRow(rowHeights, listRef, true, props)}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default RootCompanyListBox;
