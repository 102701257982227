/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CompanyTooltipProps } from "./types";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";
import { useOverflow } from "../../../shared";

export const CompanyTooltip = ({
    name,
    lineH,
    fontSize
}: CompanyTooltipProps) => {
    const { measuredRef, isOverflowing } = useOverflow();

    return (
        <TooltipGeneral
            title={name}
            placement={TOOLTIP_PLACEMENT.Top}
            disableHoverListener={!isOverflowing}
        >
            <span
                ref={measuredRef}
                css={css({
                    fontSize: `${fontSize}px`,
                    lineHeight: `${lineH}px`,
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    display: "block !important"
                })}
            >
                {name}
            </span>
        </TooltipGeneral>
    );
};

export default CompanyTooltip;
