/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { BANNER_MESSAGE_STATUSES, BannerMessageProps } from "./types";
import { useStyleOptions } from "./hooks/useStyleOptions";

import ThemeContext from "../../../context/theme/themeContext";

const BannerMessage = ({
    status,
    title,
    description,
    textWidth,
    externalCustomStyle,
    close,
    ...props
}: BannerMessageProps) => {
    const {
        colorsFacelift: { blue700, textDark }
    } = useContext(ThemeContext);

    const styleOptions = useStyleOptions();

    const bgColor = styleOptions[status].background;
    const borderColor = styleOptions[status].border;
    const IconImage = styleOptions[status].icon;

    return (
        <div
            data-testid="banner-message"
            {...props}
            css={css(
                {
                    display: "flex",
                    alignItems: "center",
                    padding: "7px",
                    borderRadius: "8px",
                    background: bgColor,
                    border: `1px solid ${borderColor}`,

                    "& > svg": {
                        fontSize: "16px",
                        alignSelf: "flex-start"
                    },

                    "& > svg:first-of-type": {
                        marginRight: "8px"
                    }
                },
                externalCustomStyle
            )}
        >
            <IconImage
                css={css(
                    status === BANNER_MESSAGE_STATUSES.Info && {
                        color: blue700
                    }
                )}
            />

            <div
                css={css({
                    width: textWidth,
                    flex: "1",
                    color: textDark,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.4px"
                })}
            >
                {title && (
                    <div
                        css={css({
                            fontWeight: "600"
                        })}
                    >
                        {title}
                    </div>
                )}

                {description && (
                    <div
                        css={css({
                            fontWeight: "400",
                            marginTop: title ? "4px" : "0"
                        })}
                    >
                        {description}
                    </div>
                )}
            </div>

            {close && (
                <CloseIcon
                    onClick={close}
                    css={css({
                        alignSelf: "flex-start",
                        fontSize: "16px",
                        cursor: "pointer",
                        marginLeft: "8px"
                    })}
                />
            )}
        </div>
    );
};

export { BANNER_MESSAGE_STATUSES } from "./types/enums";

export default BannerMessage;
