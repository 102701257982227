/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";

import ThemeContext from "../../../../context/theme/themeContext";
import { useMediaQueries } from "../../../../shared";

const CompanyFilterLoader = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const { toSm } = useMediaQueries();

    return toSm ? (
        <Skeleton
            variant="rectangular"
            width={36}
            height={36}
            css={css({
                background: gray100,
                borderRadius: "6px"
            })}
        />
    ) : (
        <Skeleton
            variant="rectangular"
            width={160}
            height={36}
            css={css({
                background: gray100,
                borderRadius: "6px"
            })}
        />
    );
};

export default CompanyFilterLoader;
