import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LicensesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10L8.37 20.45C7.93 21.11 8.4 22 9.2 22H14.8C15.6 22 16.08 21.11 15.63 20.45L14 18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2ZM21 14H3V5C3 4.45 3.45 4 4 4H20C20.55 4 21 4.45 21 5V14Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default LicensesIcon;
