/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { Skeleton } from "@mui/material";

import ThemeContext from "../../context/theme/themeContext";
import { useMediaQueries } from "../../shared";
import Divider from "../DesignComponents/Divider";

interface IDeviceStatsinitialDrawerLoaderProps {
    isLast: boolean;
}

const DeviceStatsInitialDrawerLoader = ({
    isLast
}: IDeviceStatsinitialDrawerLoaderProps) => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const { fromMd } = useMediaQueries();

    return (
        <Fragment>
            <Skeleton
                variant="rectangular"
                width="100%"
                height={228}
                css={css({
                    backgroundColor: gray100,
                    marginBottom: fromMd && isLast ? "16px" : "0",
                    marginTop: "12px",
                    borderRadius: "6px"
                })}
            />

            {!isLast && <Divider hasMarginOnTop={true} />}
        </Fragment>
    );
};

export default DeviceStatsInitialDrawerLoader;
