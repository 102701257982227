/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { DialogActionsProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";

const DialogActions = ({
    isLoading,
    isReadyToConfirm,
    onClose,
    isCancelDisabled,
    loaderText,
    submitBtnTooltipText,
    confirmationButtonText,
    confirmationButtonTestId,
    cancelButtonTestId
}: DialogActionsProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Button
                data-testid={cancelButtonTestId}
                fullWidth
                color={BUTTON_COLORS.Secondary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                disabled={isCancelDisabled}
                onClick={onClose}
            >
                {t("Button##cancel")}
            </Button>

            <TooltipGeneral
                title={submitBtnTooltipText}
                placement={TOOLTIP_PLACEMENT.Top}
            >
                <span css={css({ display: "flex" })}>
                    <Button
                        data-testid={confirmationButtonTestId ?? "save-button"}
                        fullWidth
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        type="submit"
                        isLoading={isLoading}
                        loaderText={loaderText}
                        disabled={!isReadyToConfirm}
                    >
                        {confirmationButtonText ?? t("Button##save")}
                    </Button>
                </span>
            </TooltipGeneral>
        </>
    );
};

export default DialogActions;
