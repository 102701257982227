import { useState } from "react";

import { useTriSourceAction } from "../hooks";

import TriSource from "../../components/TriSource/TriSource";
import DialogActions from "../../components/DialogActions";
import CanPackageDropdown from "../../ProductCodes/components/CanPackageDropdown";
import { DropdownValue } from "../../types";

import { DEVICE_ACTIONS, DialogProps } from "../../../types";

import { TRI_SOURCES } from "../../../../../shared";

const AssignCanPackage = ({ isOpen, close }: DialogProps) => {
    const {
        t,
        rootCompany,
        isSupportUser,
        activeSource,
        sourcePayload,
        isSourceReady,
        isSelectedSingleItem,
        updateDevices
    } = useTriSourceAction();

    const [isLoading, setLoading] = useState(false);
    const [canPackage, setCanPackage] = useState<DropdownValue | null>(null);

    const isReady = isSourceReady && sourcePayload !== null;

    const handleCloseDialog = () => close(DEVICE_ACTIONS.AssignCanPackage);

    const handleChangePackage = (option: DropdownValue | null) =>
        setCanPackage(option);

    const getFormData = () => {
        const formData = new FormData();

        formData.append("source", activeSource);
        formData.append("file", sourcePayload as Blob);

        isSupportUser &&
            formData.append("root_id", String(rootCompany?.id as number));

        formData.append(
            "data[can_package_id_override]",
            String(canPackage?.id ?? null)
        );

        return formData;
    };

    const getPayloadData = () => {
        const data = {
            can_package_id_override: canPackage?.id ?? null
        };

        const payload = {
            source: activeSource,
            [activeSource]: sourcePayload,
            data
        };

        return isSelectedSingleItem ? data : payload;
    };

    const handleSubmit = () => {
        const payloadData =
            activeSource === TRI_SOURCES.FromFile
                ? getFormData()
                : getPayloadData();

        updateDevices(payloadData, setLoading, handleCloseDialog);
    };

    const isBulkActionLoading = !isSelectedSingleItem && isLoading;

    return (
        <TriSource
            title={t("General##assign can package")}
            description={t("Dialog##assign can package description")}
            confirmationDescription={t(
                "Dialog##confirmations##assign can package confirmation"
            )}
            close={handleCloseDialog}
            isBulkActionLoading={isBulkActionLoading}
            open={isOpen}
            submit={handleSubmit}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    loaderText={
                        !isSelectedSingleItem
                            ? t("Button##assigning")
                            : undefined
                    }
                    isCancelDisabled={isBulkActionLoading}
                    isReadyToConfirm={isReady}
                    confirmationButtonText={t("Button##assign")}
                />
            }
            TransitionProps={{
                onExited: () => {
                    setLoading(false);
                    setCanPackage(null);
                }
            }}
        >
            <CanPackageDropdown
                change={handleChangePackage}
                disabled={isLoading}
                customStyle={{
                    marginBottom: "16px"
                }}
            />
        </TriSource>
    );
};

export default AssignCanPackage;
