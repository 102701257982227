/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import ParamsContext from "../../context/params/paramsContext";
import TableContext from "../../context/table/tableContext";
import NoResultsFoundIcon from "../../assets/customIcons/emptyTable/NoResultsFoundIcon";

const NoResultsFound = () => {
    const { resetAllParams } = useContext(ParamsContext);
    const { isInnerTable, resetInnerTableAllParams } = useContext(TableContext);

    const { t } = useTranslation();

    return (
        <Fragment>
            <div>
                <NoResultsFoundIcon
                    css={css({
                        fontSize: "120px"
                    })}
                />
            </div>

            <div>{t("Table##empty table##no results found##title")}</div>

            <div>{t("Table##empty table##no results found##description")}</div>

            <Button
                color={BUTTON_COLORS.Primary}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.TextOnly}
                onClick={
                    isInnerTable ? resetInnerTableAllParams : resetAllParams
                }
            >
                {t("Table##empty table##button##reset view")}
            </Button>
        </Fragment>
    );
};

export default NoResultsFound;
