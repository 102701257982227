export enum RENDER_NAMES {
    Generate = "generate",
    List = "list"
}

export enum TOKEN_TYPES {
    Integration = "Integration",
    Tct = "Tct"
}

export enum SELECTED_TOKEN_KEYS {
    Type = "type",
    Name = "name"
}
