/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Skeleton } from "@mui/material";

import ThemeContext from "../../context/theme/themeContext";

export const HierarchyTitleLoader = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return (
        <Skeleton
            variant="rectangular"
            width={180}
            height={20}
            css={css({
                background: gray200,
                borderRadius: "6px"
            })}
        />
    );
};
