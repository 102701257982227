/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import ListCompanies from "./ListCompanies";

import InputField, {
    ClearInputIcon,
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

const CompanySelector = () => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState("");

    const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) =>
        setSearchValue(e.target.value);

    const clearSearchValue = () => setSearchValue("");

    return (
        <>
            <div
                css={css({
                    padding: "12px 16px"
                })}
            >
                <InputField
                    autoFocus
                    fullWidth
                    size={INPUT_FIELD_SIZES.Medium}
                    placeholder={t("Search##search company name")}
                    iconLeft={<SearchRoundedIcon />}
                    value={searchValue}
                    onChange={handleSearchValue}
                    onKeyDown={e => e.stopPropagation()} // prevents from losing focus on pressing "c" key
                    iconRight={
                        <ClearInputIcon
                            value={searchValue}
                            clearValue={clearSearchValue}
                        />
                    }
                />
            </div>

            <ListCompanies searchValue={searchValue} />
        </>
    );
};

export default CompanySelector;
