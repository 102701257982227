import { ID_TYPE, ORDERS, SORT_RESOURCES } from "../../../../shared";

export const getInitialCustomData = (
    sortFromNewest: boolean,
    customData: any[],
    idType: ID_TYPE
) =>
    sortFromNewest
        ? customData.toSorted((a, b) => b[idType] - a[idType])
        : customData;

export const sortCustomDataRows = (
    customDataRows: any[],
    sort: SORT_RESOURCES | "",
    order: ORDERS
) => {
    customDataRows.sort((a, b) => {
        const aItem = a[sort];
        const bItem = b[sort];

        if (aItem < bItem) {
            return order === ORDERS.Ascending ? -1 : 1;
        }

        if (aItem > bItem) {
            return order === ORDERS.Ascending ? 1 : -1;
        }

        return 0;
    });
};
