import { useContext } from "react";

import TableContext from "../../../../../../context/table/tableContext";
import { ENDPOINTS, useApi } from "../../../../../../shared";

export const useUpdateDesktopLicense = () => {
    const { setDetailsData } = useContext(TableContext);

    const { getData, handleResponse } = useApi();

    const updateLicenseDetails = async (licenseId: number) => {
        try {
            const { data } = await getData(
                `${ENDPOINTS.DesktopLicenses}/${licenseId}`
            );

            setDetailsData(data);
        } catch (error) {
            handleResponse(error);
        }
    };

    return { updateLicenseDetails };
};
