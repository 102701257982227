import { ReactNode } from "react";
import { TOGGLE_SWITCH_LABEL_PLACEMENT, TOGGLE_SWITCH_SIZES } from "./types";

export const sizes: any = {
    [TOGGLE_SWITCH_SIZES.Large]: {
        rootWidth: "48px",
        rootHeight: "24px",
        thumbWidth: "22px",
        thumbHeight: "22px",
        thumbCheckedTransform: "translateX(24px)"
    },
    [TOGGLE_SWITCH_SIZES.Medium]: {
        rootWidth: "36px",
        rootHeight: "20px",
        thumbWidth: "18px",
        thumbHeight: "18px",
        thumbCheckedTransform: "translateX(16px)"
    },
    [TOGGLE_SWITCH_SIZES.Small]: {
        rootWidth: "30px",
        rootHeight: "16px",
        thumbWidth: "14px",
        thumbHeight: "14px",
        thumbCheckedTransform: "translateX(14px)"
    }
};

export const generateLabelRightMargin = (
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT,
    endItem?: ReactNode
) => {
    if (endItem && labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.End) {
        return "12px";
    }
    return "0";
};

export const generateLabelLeftMargin = (
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT,
    startItem?: ReactNode
) => {
    if (startItem && labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.Start) {
        return "12px";
    }
    return "0";
};

export const toggleSwitchLabelStyles = (
    colorsFacelift: any,
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT,
    endItem?: ReactNode,
    startItem?: ReactNode,
    label?: string
) => {
    return {
        alignItems: "center",
        color: colorsFacelift.textDark,
        marginRight: generateLabelRightMargin(labelPlacement, endItem),
        marginLeft: generateLabelLeftMargin(labelPlacement, startItem),
        "& .MuiTypography-root": {
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            wordBreak: "break-all",
            marginLeft: label && !labelPlacement ? "12px" : "unset"
        },
        "& .MuiFormControlLabel-label.Mui-disabled": {
            opacity: 1,
            color: colorsFacelift.gray400
        }
    };
};

export const startEnditemsAlignment = {
    display: "flex",
    alignSelf: "flex-start"
};

export const generateToggleSwitchMargins = (
    marginTop: string,
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT
) => {
    if (labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.End) {
        return `${marginTop} 12px 0 0`;
    } else if (labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.Start) {
        return `${marginTop} 0 0 12px`;
    }
    return `${marginTop} 0 0 0`;
};

export const generateStartItemMargin = (
    label?: string,
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT
) => {
    if (label && labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.Start) {
        return "unset";
    }
    return "12px !important";
};

export const generateEndItemMargin = (
    label?: string,
    labelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT
) => {
    if (label && labelPlacement === TOGGLE_SWITCH_LABEL_PLACEMENT.End) {
        return "unset";
    }
    return "12px !important";
};
