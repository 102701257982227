import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useDialog } from "./hooks";
import ContentFrame from "./components/ContentFrame";

import AddProductCode from "../dialogs/ProductCodes/AddProductCode";
import { PRODUCT_CODE_ACTIONS } from "../types";

import ActionToolbarButtonWrapper from "../../DesignComponents/Button/ActionToolbarButtonWrapper";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import AuthContext from "../../../context/auth/authContext";
import TableContext from "../../../context/table/tableContext";
import { useMediaQueries } from "../../../shared";

const ProductCodesBar = () => {
    const { isAdminManagerUser } = useContext(AuthContext);
    const { isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const { isOpenDialog, openDialog, closeDialog } = useDialog();

    return (
        <>
            <ContentFrame>
                {isAdminManagerUser && (
                    <ActionToolbarButtonWrapper>
                        <Button
                            fullWidth={toSm}
                            hasRectangleLoader={toSm && isInitialLoading}
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Small}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={() =>
                                openDialog(PRODUCT_CODE_ACTIONS.AddProductCode)
                            }
                        >
                            {t("Button##add new")}
                        </Button>
                    </ActionToolbarButtonWrapper>
                )}
            </ContentFrame>

            <AddProductCode
                isOpen={isOpenDialog[PRODUCT_CODE_ACTIONS.AddProductCode]}
                close={closeDialog}
            />
        </>
    );
};

export default ProductCodesBar;
