/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useContext, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField/TextField";

import { BUTTON_ACTIONS, StepperProps } from "./types";

import {
    TooltipOptional,
    TOOLTIP_PLACEMENT
} from "../../DesignComponents/Tooltips";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import ThemeContext from "../../../context/theme/themeContext";
import { useFormattedNumber } from "../../../shared";

const Stepper = ({
    value,
    iconLeft,
    iconRight,
    iconFirst,
    iconLast,
    minValue,
    maxValue,
    tooltipLeft,
    tooltipRight,
    tooltipFirst,
    tooltipLast,
    buttonClick,
    customValue,
    ...props
}: StepperProps) => {
    const {
        colorsFacelift: { black, gray700, gray200, textDark }
    } = useContext(ThemeContext);

    const { renderFormattedNumber } = useFormattedNumber();
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(value);

    useEffect(() => {
        setCurrentPage(value);
    }, [value]);

    const isLeftBtnDisabled = minValue !== undefined && value <= minValue;
    const isRightBtnDisabled = maxValue !== undefined && value >= maxValue;

    const getPage = (input: number) => {
        if (minValue && input < minValue) {
            return minValue;
        }

        if (maxValue && input > maxValue) {
            return maxValue;
        }

        return input;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const userInput = +e.target.value.replace(/\D/g, "");
        const newValue = getPage(userInput);

        maxValue !== 0 && setCurrentPage(newValue);
    };

    return (
        <div
            {...props}
            css={css({
                display: "flex",
                alignItems: "center",

                button: {
                    color: black
                }
            })}
        >
            <TooltipOptional
                placement={TOOLTIP_PLACEMENT.Top}
                title={tooltipFirst ?? ""}
                isHidden={isLeftBtnDisabled}
                disableTouchListener
            >
                <span>
                    <Button
                        disabled={isLeftBtnDisabled}
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={iconFirst}
                        onClick={() => buttonClick(BUTTON_ACTIONS.FirstPage)}
                        css={css({
                            marginRight: "8px"
                        })}
                    />
                </span>
            </TooltipOptional>

            <TooltipOptional
                placement={TOOLTIP_PLACEMENT.Top}
                title={tooltipLeft ?? ""}
                isHidden={isLeftBtnDisabled}
                disableTouchListener
            >
                <span>
                    <Button
                        disabled={isLeftBtnDisabled}
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={iconLeft}
                        onClick={() => buttonClick(BUTTON_ACTIONS.PreviousPage)}
                    />
                </span>
            </TooltipOptional>

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "36px",
                    minWidth: "36px",
                    padding: "0 8px",
                    boxSizing: "border-box",

                    span: {
                        color: textDark,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        marginLeft: "4px",
                        whiteSpace: "nowrap"
                    }
                })}
            >
                <TextField
                    variant="filled"
                    size="small"
                    onChange={handleChange}
                    value={
                        maxValue === 0 ? 0 : renderFormattedNumber(currentPage)
                    }
                    onBlur={() => customValue(currentPage)}
                    onKeyDown={ev =>
                        ev.key === "Enter" && customValue(currentPage)
                    }
                    css={css({
                        width: `${currentPage.toString().length + 1}ch`,

                        transition:
                            "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

                        "&:hover": {
                            backgroundColor: gray200,
                            borderRadius: "6px 6px 0 0"
                        },

                        "& .MuiInputBase-input": {
                            height: "unset",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textAlign: "center"
                        },

                        "& .MuiFilledInput-input": {
                            padding: "8px 4px"
                        },

                        "& .MuiFilledInput-root::before": {
                            borderBottom: `1px solid ${gray700}`
                        },

                        "& .MuiFilledInput-root::after": {
                            borderBottom: "unset"
                        },

                        "& .MuiFilledInput-root, &.Mui-focused": {
                            backgroundColor: "transparent"
                        }
                    })}
                />

                <span>
                    {maxValue !== undefined &&
                        t("Button##pages of", {
                            total: renderFormattedNumber(maxValue)
                        })}
                </span>
            </div>

            <TooltipOptional
                placement={TOOLTIP_PLACEMENT.Top}
                title={tooltipRight ?? ""}
                isHidden={isRightBtnDisabled}
                disableTouchListener
            >
                <span>
                    <Button
                        disabled={isRightBtnDisabled}
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={iconRight}
                        onClick={() => buttonClick(BUTTON_ACTIONS.NextPage)}
                        css={css({
                            marginRight: "8px"
                        })}
                    />
                </span>
            </TooltipOptional>

            <TooltipOptional
                placement={TOOLTIP_PLACEMENT.Top}
                title={tooltipLast ?? ""}
                isHidden={isRightBtnDisabled}
                disableTouchListener
            >
                <span>
                    <Button
                        disabled={isRightBtnDisabled}
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={iconLast}
                        onClick={() => buttonClick(BUTTON_ACTIONS.LastPage)}
                    />
                </span>
            </TooltipOptional>
        </div>
    );
};

export default Stepper;
