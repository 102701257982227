/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import Navigation from "./Navigation";
import Controls from "./Controls";

import Logo from "../Logo";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";
import ListChoice from "../DesignComponents/ListChoice";

import ThemeContext from "../../context/theme/themeContext";
import TutorialContext from "../../context/tutorial/tutorialContext";
import RoutesContext from "../../context/routes/routesContext";
import Notifications from "../../context/notifications/NotificationsState";
import { ROUTES, useWindowLocation } from "../../shared";

const MobileDrawer = () => {
    const {
        colorsFacelift: {
            blue700,
            gray100,
            gray200,
            textDark,
            textPlaceholder,
            white
        }
    } = useContext(ThemeContext);

    const {
        isDrawerOpen,
        isTutorialActive,
        isTutorialOpen,
        setTutorialOpen,
        stepIndex,
        stepGroup
    } = useContext(TutorialContext);

    const { isMobileDrawerOpen, setMobileDrawerStatus } =
        useContext(RoutesContext);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useWindowLocation();

    const [isInitialView, setIsInitialView] = useState(true);

    useEffect(() => {
        isTutorialOpen && !isDrawerOpen && setMobileDrawerStatus(false);

        //eslint-disable-next-line
    }, [stepGroup, stepIndex, isTutorialOpen]);

    const handleInitialView = (val: boolean) => setIsInitialView(val);

    return (
        <Drawer
            anchor="top"
            open={isMobileDrawerOpen}
            onClose={() => setMobileDrawerStatus(false)}
            SlideProps={{
                onExited: () => handleInitialView(true),
                onEntered: () => isTutorialActive && setTutorialOpen(true)
            }}
            sx={{
                "& .MuiDrawer-paper": {
                    height: "100%"
                }
            }}
        >
            <div
                css={css({
                    padding: "10px 24px 10px 24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                })}
            >
                <Logo
                    css={css({
                        cursor: "pointer"
                    })}
                    onClick={() => {
                        pathname !== ROUTES.Dashboard &&
                            navigate(ROUTES.Dashboard);
                    }}
                />

                <Button
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={
                        <CloseIcon
                            css={css({
                                color: textDark
                            })}
                        />
                    }
                    onClick={() => setMobileDrawerStatus(false)}
                />
            </div>

            {isInitialView ? (
                <div
                    css={css({
                        padding: "10px 24px"
                    })}
                >
                    <div
                        css={css({
                            color: textPlaceholder,
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "16px",
                            letterSpacing: "0.4px",
                            marginBottom: "4px"
                        })}
                    >
                        {t("General##menu")}
                    </div>

                    <Navigation />

                    <div
                        css={css({
                            "& > button": {
                                display: "flex",
                                justifyContent: "flex-start",
                                marginTop: "8px",
                                padding: "6px"
                            }
                        })}
                    >
                        <Notifications handleDrawer={setMobileDrawerStatus} />

                        <Button
                            disableRipple
                            fullWidth
                            color={BUTTON_COLORS.White}
                            size={BUTTON_SIZES.Small}
                            variant={BUTTON_VARIANTS.IconLeft}
                            icon={<SettingsIcon />}
                            onClick={() => handleInitialView(false)}
                            css={css({
                                "&:hover": {
                                    background: gray100
                                },

                                "&:active": {
                                    background: gray200
                                }
                            })}
                        >
                            {t("Button##settings")}
                        </Button>
                    </div>
                </div>
            ) : (
                <Fragment>
                    <ListChoice
                        title={t("Button##go back")}
                        iconLeft={<ChevronLeftRoundedIcon />}
                        onClick={() => handleInitialView(true)}
                        css={css({
                            background: white,
                            color: blue700
                        })}
                    />

                    <Controls
                        hideNotifications
                        handleDrawer={setMobileDrawerStatus}
                    />
                </Fragment>
            )}
        </Drawer>
    );
};

export default MobileDrawer;
