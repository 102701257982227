/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import {
    TOOLTIP_PLACEMENT,
    TOOLTIP_SIZES,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import ThemeContext from "../../../../context/theme/themeContext";

interface IToggledrawerButtonProps {
    isdrawerOpen: boolean;
    onOpendrawer: () => void;
    onDrawerClose: () => void;
}

const ToggleDrawerButton = ({
    isdrawerOpen,
    onOpendrawer,
    onDrawerClose
}: IToggledrawerButtonProps) => {
    const {
        colorsFacelift: { gray100, gray700 }
    } = useContext(ThemeContext);
    const { t } = useTranslation();

    return (
        <div
            onClick={isdrawerOpen ? onDrawerClose : onOpendrawer}
            className="navigation-item"
            css={css({
                cursor: "pointer",
                "& > svg": {
                    color: gray700
                },

                "&:hover": {
                    backgroundColor: gray100
                }
            })}
        >
            {isdrawerOpen ? (
                <KeyboardDoubleArrowLeftIcon />
            ) : (
                <TooltipGeneral
                    title={t("General##expand menu")}
                    placement={TOOLTIP_PLACEMENT.Right}
                    size={TOOLTIP_SIZES.Small}
                    disableTouchListener
                >
                    <div
                        css={css({
                            height: "24px",
                            color: gray700
                        })}
                    >
                        <KeyboardDoubleArrowRightIcon />
                    </div>
                </TooltipGeneral>
            )}

            <span
                css={css({
                    marginLeft: "8px",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    color: gray700
                })}
            >
                {t("General##collapse menu")}
            </span>
        </div>
    );
};

export default ToggleDrawerButton;
