/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SingleDeviceStatsItemProps } from "./types";

import { useGetDeviceStatistics } from "../../hooks";
import {
    DoughnutChart,
    SingleDeviceStatsListItem,
    translateStatName,
    translateStatTitle
} from "../../shared";

import Divider from "../../../DesignComponents/Divider";
import AbsoluteCircularProgress from "../../../Loaders/AbsoluteCircularProgress";
import DeviceStatsInitialDrawerLoader from "../../../Loaders/DeviceStatsInitialDrawerLoader";

import ThemeContext from "../../../../context/theme/themeContext";
import { DeviceStats, DEVICE_STATISTICS } from "../../../../shared";

export const SingleDeviceStatsItem = ({
    query,
    isLast
}: SingleDeviceStatsItemProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const { isLoading, statItem } = useGetDeviceStatistics(query, true);

    const styles = {
        opacity: isLoading ? "0.4" : 1
    };

    const sortedDescendingStatItem =
        statItem && [...statItem.stats].sort((a, b) => b.count - a.count);

    const statsTitles = {
        activity: t("Statistics##activity"),
        model: t("Statistics##model"),
        firmware: t("Statistics##firmware"),
        configuration: t("Statistics##configuration")
    };

    const statsValues = {
        offline: t("General##Offline"),
        online: t("General##Online"),
        inactive: t("General##Inactive"),
        unknown: t("General##unknown"),
        other: t("General##other")
    };

    const renderStatsContent = () => {
        if (isLoading && !statItem) {
            return <DeviceStatsInitialDrawerLoader isLast={isLast} />;
        }

        if (statItem) {
            return (
                <>
                    <div
                        css={css({
                            marginTop: "12px",
                            position: "relative"
                        })}
                    >
                        {isLoading && <AbsoluteCircularProgress />}

                        <div
                            css={css({
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                opacity: styles.opacity
                            })}
                        >
                            {query === DEVICE_STATISTICS.ActivityStatus ? (
                                <DoughnutChart
                                    statItem={statItem}
                                    diameter="95"
                                    cutout={35}
                                    numberFontStyle={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        lineHeight: "12px",
                                        letterSpacing: "0.4px"
                                    }}
                                    centerTitle={t("General##total")}
                                    centerTitleFontStyle={{
                                        fontSize: "10px",
                                        fontWeight: 600,
                                        lineHeight: "12px",
                                        letterSpacing: "1px",
                                        color: gray700
                                    }}
                                    topAlignment="29"
                                    centerItemsLineHeight="20"
                                />
                            ) : (
                                translateStatTitle(
                                    query,
                                    statsTitles.activity,
                                    statsTitles.model,
                                    statsTitles.firmware,
                                    statsTitles.configuration
                                )
                            )}
                        </div>

                        {sortedDescendingStatItem.map(
                            (item: [string, DeviceStats]) => {
                                return (
                                    <SingleDeviceStatsListItem
                                        key={item[0]}
                                        isLoading={isLoading}
                                        count={item[1].count}
                                        name={translateStatName(
                                            item[1].name,
                                            statsValues.offline,
                                            statsValues.online,
                                            statsValues.inactive,
                                            statsValues.unknown,
                                            statsValues.other
                                        )}
                                        itemMargins="8px 0"
                                    />
                                );
                            }
                        )}
                    </div>

                    {!isLast && <Divider hasMarginOnTop={true} />}
                </>
            );
        }

        return null;
    };

    return renderStatsContent();
};
