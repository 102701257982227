import { useContext } from "react";
import Grid from "@mui/material/Grid";

import General from "./General";
import Obd from "./Obd";
import CanAdapter from "./CanAdapter";
import Can from "./Can";
import Sim from "./Sim";
import Accessories from "./Accessories";
import Carrier from "./Carrier";
import { DetailsData } from "./types";

import DetailsContainer from "../../components/DetailsContainer";

import AuthContext from "../../../../context/auth/authContext";
import TableContext from "../../../../context/table/tableContext";

const Details = () => {
    const { isAdminUser, isCarrierUser } = useContext(AuthContext);
    const { detailsData } = useContext(TableContext);

    const {
        obd,
        can_adapter,
        gsm_number,
        iccid,
        imsi,
        accessories,
        carrier,
        can,
        can_package
    }: DetailsData = detailsData;

    const renderCan =
        !!can_package ||
        !!can?.can_package ||
        !!can?.chip_version ||
        !!can?.vehicle ||
        !!can?.vehicle_id ||
        !!can?.can_package_id;

    const renderSim = gsm_number || iccid || imsi;

    const renderCarrier = (isAdminUser || isCarrierUser) && carrier;

    return (
        <DetailsContainer>
            <Grid item xs0={12} md={6} lg={4}>
                <General />
            </Grid>

            {obd && (
                <Grid item xs0={12} md={6} lg={4}>
                    <Obd />
                </Grid>
            )}

            {can_adapter && (
                <Grid item xs0={12} md={6} lg={4}>
                    <CanAdapter />
                </Grid>
            )}

            {renderCan && (
                <Grid item xs0={12} md={6} lg={4}>
                    <Can />
                </Grid>
            )}

            {renderSim && (
                <Grid item xs0={12} md={6} lg={4}>
                    <Sim />
                </Grid>
            )}

            {accessories && (
                <Grid item xs0={12} md={6} lg={4}>
                    <Accessories />
                </Grid>
            )}

            {renderCarrier && (
                <Grid item xs0={12} md={6} lg={4}>
                    <Carrier />
                </Grid>
            )}
        </DetailsContainer>
    );
};

export default Details;
