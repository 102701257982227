import { useContext } from "react";
import { Step } from "react-joyride";
import { useMediaQueries } from "../../shared";
import TutorialContext from "../../context/tutorial/tutorialContext";

const useTutorialStepGroups = () => {
    const { toSm, fromMd } = useMediaQueries();

    const { isInitialRender } = useContext(TutorialContext);

    const companySelectorInitialRenderCheck = isInitialRender
        ? "\n\nIf you wish to move on to the next feature tutorial, press the “Next feature” button."
        : "";

    const firstTimeUserSteps: Step[] = [
        {
            target: "body",
            title: "Welcome to FOTA WEB.",
            content:
                "Welcome to FOTA WEB tutorial mode. In this mode we will guide you through some of the new features of FOTA WEB.\nIf you wish to exit the tutorial, press the “Exit” button at the bottom of your screen. If you wish to start the tutorial again at any time, you can do so from the settings.\n\nTo begin, press the “Get started” button.",
            placement: "center",
            disableBeacon: true
        }
    ];

    const companySelectorSteps: Step[] = [
        {
            target: toSm
                ? "#companyFilterOpenBtnMobile"
                : "#companyFilterOpenBtn",
            title: "Companies selector",
            content:
                "On the top navigation bar you will see the companies selector option. Clicking on the button will open a selection, that allows you to choose any number of companies you wish to work with in FOTA.",
            placement: "bottom",
            disableBeacon: true
        },
        {
            target: fromMd ? "#companyFilterMenu" : "body",
            title: "Companies selector",
            content: `This screen allows you to select a specific company, or number of companies to work with. You will only see lists related to the companies that have been selected. Logging out of FOTA will always save the last used selection.${companySelectorInitialRenderCheck}`,
            placement: fromMd ? "left-start" : "center",
            disableBeacon: true
        }
    ];

    const taskGroupsSteps: Step[] = [
        {
            target: fromMd ? "#desktopDrawer" : "#mobileDrawerBtn",
            title: "Task groups",
            content:
                "Task groups are located on the left side navigation bar. Selecting Task groups option will take you to that page.",
            placement: "right-start",
            disableBeacon: true
        },

        {
            target: "body",
            title: "Task groups",
            content:
                "This is the Task groups screen. Any bulk task created for more than one device will appear in the table view together with the status of each task inside the task group. More detailed information can be discovered in details by selecting a row.",
            placement: "center",
            disableBeacon: true
        }
    ];

    const taskGroupsStepsMobile: Step[] = [
        {
            target: "#mobileDrawerBtn",
            title: "Task groups",
            content:
                "Task groups are located inside the menu, along other options.",
            placement: "right-start",
            disableBeacon: true
        },

        {
            target: "#tasks",
            title: "Task groups",
            content:
                "Opening the menu and selecting Task groups option will take you to that page.",
            placement: "bottom",
            disableBeacon: true
        },

        {
            target: "body",
            title: "Task groups",
            content:
                "This is the Task groups screen. Any bulk task created for more than one device will appear in the table view together with the status of each task inside the task group. More detailed information can be discovered in details by selecting a row.",
            placement: "center",
            disableBeacon: true
        }
    ];

    const finishTutorialSteps: Step[] = [
        {
            target: "body",
            title: "Tutorial completed!",
            content:
                "You have completed the tutorial. If at any time you wish to go through the tutorial again, you can do so through the settings screen.",
            placement: "center",
            disableBeacon: true
        }
    ];

    return {
        companySelectorSteps,
        firstTimeUserSteps,
        finishTutorialSteps,
        taskGroupsSteps,
        taskGroupsStepsMobile
    };
};

export default useTutorialStepGroups;
