import { useContext } from "react";
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from "..";
import ThemeContext from "../../../../context/theme/themeContext";

export const useStyleOptions = () => {
    const {
        colorsFacelift: {
            blue100,
            blue200,
            blue400,
            blue700,
            blue800,
            blue900,
            gray100,
            gray200,
            gray300,
            gray700,
            red100,
            red200,
            red300,
            red700,
            red800,
            red900,
            textDark,
            white
        }
    } = useContext(ThemeContext);

    const styleVariants = {
        colors: {
            [BUTTON_COLORS.Primary]: {
                text: white,
                background: {
                    rest: blue700,
                    hover: blue800,
                    active: blue900
                },
                loader: white
            },
            [BUTTON_COLORS.PrimarySubtle]: {
                text: blue700,
                background: {
                    rest: blue100,
                    hover: blue200,
                    active: blue400
                },
                loader: blue700
            },
            [BUTTON_COLORS.Secondary]: {
                text: textDark,
                background: {
                    rest: gray100,
                    hover: gray200,
                    active: gray300
                },
                loader: textDark
            },
            [BUTTON_COLORS.White]: {
                text: gray700,
                background: {
                    rest: white,
                    hover: gray200,
                    active: gray300
                },
                loader: gray700
            },
            [BUTTON_COLORS.Transparent]: {
                text: white,
                background: {
                    rest: "rgba(255, 255, 255, 0.05)",
                    hover: "rgba(255, 255, 255, 0.1)",
                    active: "rgba(255, 255, 255, 0.12)"
                },
                loader: white
            },
            [BUTTON_COLORS.Critical]: {
                text: white,
                background: {
                    rest: red700,
                    hover: red800,
                    active: red900
                },
                loader: white
            },
            [BUTTON_COLORS.CriticalSubtle]: {
                text: red800,
                background: {
                    rest: red100,
                    hover: red200,
                    active: red300
                },
                loader: red800
            }
        },
        paddings: {
            [BUTTON_VARIANTS.TextOnly]: {
                tiny: "4px 8px",
                small: "8px 12px",
                normal: "12px 16px",
                large: "15px 24px"
            },
            [BUTTON_VARIANTS.IconLeft]: {
                tiny: "4px 8px 4px 4px",
                small: "6px 12px 6px 8px",
                normal: "10px 16px 10px 12px",
                large: "14px 24px 14px 16px"
            },
            [BUTTON_VARIANTS.IconRight]: {
                tiny: "4px 4px 4px 8px",
                small: "6px 8px 6px 12px",
                normal: "10px 12px 10px 16px",
                large: "14px 16px 14px 24px"
            },
            [BUTTON_VARIANTS.TwoIcons]: {
                tiny: "4px",
                small: "6px 8px",
                normal: "10px 12px",
                large: "14px 16px"
            },
            [BUTTON_VARIANTS.IconOnly]: {
                tiny: "4px",
                small: "6px",
                normal: "10px",
                large: "14px"
            }
        },
        typography: {
            [BUTTON_SIZES.Tiny]: {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px"
            },
            [BUTTON_SIZES.Small]: {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px"
            },
            [BUTTON_SIZES.Normal]: {
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px"
            },
            [BUTTON_SIZES.Large]: {
                fontSize: "16px",
                lineHeight: "20px",
                letterSpacing: "0.15px"
            }
        }
    };

    return styleVariants;
};
