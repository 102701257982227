/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";

import ThemeContext from "../../../context/theme/themeContext";

const PaginationLoader = ({ ...props }) => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const paginationLoadersWidth = [
        { width: 36, id: 1 },
        { width: 36, id: 2 },
        { width: 20, id: 3 },
        { width: 20, id: 4 },
        { width: 20, id: 5 },
        { width: 36, id: 6 },
        { width: 36, id: 7 }
    ];

    return (
        <div
            {...props}
            css={css({
                display: "flex",
                alignItems: "center",

                span: {
                    height: "36px",
                    borderRadius: "6px",
                    background: gray100
                },

                "& > span:nth-of-type(3),span:nth-of-type(4),span:nth-of-type(5)":
                    {
                        height: "24px",
                        borderRadius: "46px"
                    },

                "& > span + span": {
                    marginLeft: "8px"
                }
            })}
        >
            {paginationLoadersWidth.map((loaderwidth: any) => (
                <Skeleton
                    variant="rectangular"
                    width={loaderwidth.width}
                    key={loaderwidth.id}
                />
            ))}
        </div>
    );
};

export default PaginationLoader;
