import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import PasswordsForm from "./PasswordsForm";
import AuthContainer from "../AuthContainer";
import { useTogglePswVisibility } from "../hooks";
import { BUTTON_TYPES, PASSWORD_TYPES } from "../types";

import AuthContext from "../../../context/auth/authContext";
import { ROUTES } from "../../../shared";

const Signup = () => {
    const { isAuthenticated, logoutUser, checkAuth } = useContext(AuthContext);

    const { t } = useTranslation();

    const [triggerRedirection, setTriggerRedirection] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            triggerRedirection ? setRedirect(true) : logoutUser();
        }

        //eslint-disable-next-line
    }, [isAuthenticated, triggerRedirection]);

    const inputs = [
        {
            name: PASSWORD_TYPES.NewPsw,
            title: t("Auth##password"),
            TooltipPsw: useTogglePswVisibility(PASSWORD_TYPES.NewPsw)
        },
        {
            name: PASSWORD_TYPES.Repeat,
            title: t("Auth##repeat password"),
            TooltipPsw: useTogglePswVisibility(PASSWORD_TYPES.Repeat)
        }
    ];

    const handleRedirection = useCallback(() => {
        setTriggerRedirection(true);
        checkAuth();
    }, [checkAuth]);

    return redirect ? (
        <Navigate to={{ pathname: ROUTES.Devices }} />
    ) : (
        <AuthContainer formName={t("Auth##create initial password")}>
            <PasswordsForm
                inputs={inputs}
                button={BUTTON_TYPES.SignUp}
                redirectToDevices={handleRedirection}
                hasTermsAndConditions
            />
        </AuthContainer>
    );
};

export default Signup;
