import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CancelAllTasksIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <path
                d="M18.2222 3.77778H14.5067C14.1333 2.74667 13.1556 2 12 2C10.8444 2 9.86667 2.74667 9.49333 3.77778H5.77778C4.8 3.77778 4 4.57778 4 5.55556V19.7778C4 20.7556 4.8 21.5556 5.77778 21.5556H18.2222C19.2 21.5556 20 20.7556 20 19.7778V5.55556C20 4.57778 19.2 3.77778 18.2222 3.77778ZM12 3.77778C12.4889 3.77778 12.8889 4.17778 12.8889 4.66667C12.8889 5.15556 12.4889 5.55556 12 5.55556C11.5111 5.55556 11.1111 5.15556 11.1111 4.66667C11.1111 4.17778 11.5111 3.77778 12 3.77778ZM17.3333 19.7778H6.66667C6.17778 19.7778 5.77778 19.3778 5.77778 18.8889V6.44444C5.77778 5.95556 6.17778 5.55556 6.66667 5.55556H7.55556V6.44444C7.55556 7.42222 8.35556 8.22222 9.33333 8.22222H14.6667C15.6444 8.22222 16.4444 7.42222 16.4444 6.44444V5.55556H17.3333C17.8222 5.55556 18.2222 5.95556 18.2222 6.44444V18.8889C18.2222 19.3778 17.8222 19.7778 17.3333 19.7778Z"
                fill={textDark}
            />

            <path
                d="M11.7778 14.9422L10.08 16.64C9.82222 16.8978 9.39556 16.8978 9.13778 16.64C8.88 16.3822 8.88 15.9556 9.13778 15.6978L10.8356 14L9.13778 12.3022C8.88 12.0444 8.88 11.6178 9.13778 11.36C9.39556 11.1022 9.82222 11.1022 10.08 11.36L11.7778 13.0578L13.4756 11.36C13.7333 11.1022 14.16 11.1022 14.4178 11.36C14.6756 11.6178 14.6756 12.0444 14.4178 12.3022L12.72 14L14.4178 15.6978C14.6756 15.9556 14.6756 16.3822 14.4178 16.64C14.16 16.8978 13.7333 16.8978 13.4756 16.64L11.7778 14.9422Z"
                fill={textDark}
            />
        </SvgIcon>
    );
};

export default CancelAllTasksIcon;
