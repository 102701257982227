import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { RadioButtonUnchecked } from "@mui/icons-material";

import GoBack from "./GoBack";
import { TutorialProps } from "./types";

import ListChoice from "../../../../DesignComponents/ListChoice";
import { STEP_GROUP } from "../../../../TutorialMode/types/enums";

import SuccessIcon from "../../../../../assets/customIcons/status/SuccessIcon";
import TutorialContext from "../../../../../context/tutorial/tutorialContext";
import ThemeContext from "../../../../../context/theme/themeContext";

const Tutorials = ({ isMobile, setContent, closeMenu }: TutorialProps) => {
    const { setTutorialOpen } = useContext(TutorialContext);

    const {
        colorsFacelift: { textDarkDisabled }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const startTutorial = (stepGroup: STEP_GROUP) => {
        setTutorialOpen(true, 0, stepGroup);
        closeMenu();
    };

    const checkIfTutorialCompleted = (tutorialName: STEP_GROUP) => {
        return localStorage.getItem(tutorialName) === "true" ? (
            <SuccessIcon />
        ) : (
            <RadioButtonUnchecked sx={{ color: textDarkDisabled }} />
        );
    };

    return (
        <div>
            {!isMobile && <GoBack setContent={setContent} />}

            <ListChoice
                title={t("Filter##companies selector")}
                iconLeft={checkIfTutorialCompleted(
                    STEP_GROUP.TutorialCompanySelector
                )}
                iconRight={<ChevronRightRoundedIcon />}
                onClick={() =>
                    startTutorial(STEP_GROUP.TutorialCompanySelector)
                }
            />

            <ListChoice
                title={t("General##task groups")}
                iconLeft={checkIfTutorialCompleted(
                    STEP_GROUP.TutorialTaskGroups
                )}
                iconRight={<ChevronRightRoundedIcon />}
                onClick={() => startTutorial(STEP_GROUP.TutorialTaskGroups)}
            />
        </div>
    );
};

export default Tutorials;
