/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import SingleDataItem from "./SingleDataItem";
import { TaskGeneralDataProps } from "../types";

import DateTime from "../../../../../DateTime";
import RestrictionInfo from "../../../../../Table/components/RestrictionInfo";
import { useTableFunctions } from "../../../../../Table/hooks";
import {
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../shared";

const TaskGeneralData = ({ data }: TaskGeneralDataProps) => {
    const { t } = useTranslation();
    const { scheduleTimeFrom, scheduleTimeTo } = useTableFunctions();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();

    const scheduleTimeRestriction = FEATURE_SETTINGS.TaskScheduleSupport;

    const isScheduleTimeRestricted = isFeatureSettingsRestricted(
        scheduleTimeRestriction
    );

    return (
        data && (
            <Fragment>
                <h4
                    css={css({
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "0.15px",
                        lineHeight: "20px"
                    })}
                >
                    {t("Table##general data")}
                </h4>

                <div
                    css={css({
                        marginTop: "8px"
                    })}
                >
                    <SingleDataItem name={t("Table##id")} data={data.id} />

                    {data.batch_id !== 0 && (
                        <SingleDataItem
                            name={t("Table##batch id")}
                            data={data.batch_id}
                        />
                    )}

                    <SingleDataItem
                        name={t("Table##type")}
                        data={t(`General##${data.type}`)}
                    />

                    <SingleDataItem
                        name={t("Table##device imei")}
                        data={data.device_imei}
                    />

                    <SingleDataItem
                        name={t("Table##company")}
                        data={data.company === null ? "—" : data.company.name}
                    />

                    <SingleDataItem
                        name={t("Table##created by")}
                        data={data.created_by ? data.created_by.email : "—"}
                    />

                    <SingleDataItem
                        name={t("Table##created at")}
                        data={<DateTime date={data.created_at} />}
                    />

                    <SingleDataItem
                        name={t("Table##updated by")}
                        data={data.updated_by ? data.updated_by.email : "—"}
                    />

                    <SingleDataItem
                        name={t("Table##updated at")}
                        data={<DateTime date={data.updated_at} />}
                    />

                    {data.schedule && (
                        <SingleDataItem
                            name={t("Dialog##schedule time")}
                            data={
                                <>
                                    <DateTime
                                        date={scheduleTimeFrom(data)}
                                        format="LT"
                                    />{" "}
                                    -{" "}
                                    <DateTime
                                        date={scheduleTimeTo(data)}
                                        format="LT"
                                    />
                                </>
                            }
                            icon={
                                <RestrictionInfo
                                    restriction={scheduleTimeRestriction}
                                    css={css({
                                        alignSelf: "center",
                                        marginRight: "4px"
                                    })}
                                />
                            }
                            isRestricted={isScheduleTimeRestricted}
                        />
                    )}

                    <SingleDataItem
                        name={t("Attributes##Message")}
                        data={data.message ?? "—"}
                    />
                </div>
            </Fragment>
        )
    );
};
export default TaskGeneralData;
