import { useContext } from "react";

import DesktopDetails from "./DesktopDetails";
import MobileDetails from "./MobileDetails";
import DeviceDetails from "./DeviceDetails";
import TaskGroupDetails from "./TaskGroupDetails";
import CompanyDetails from "./CompanyDetails";
import LicenseDetails from "./LicenseDetails";
import { TableDetailsProps } from "./types";

import TableContext from "../../context/table/tableContext";
import { TABLE_NAMES, useMediaQueries } from "../../shared";

const TableDetails = ({ heights, setDefaultHeigth }: TableDetailsProps) => {
    const { tableName } = useContext(TableContext);

    const { toMd } = useMediaQueries();

    const renderContent = () => {
        switch (tableName) {
            case TABLE_NAMES.Devices:
                return <DeviceDetails />;
            case TABLE_NAMES.TaskGroups:
                return <TaskGroupDetails />;
            case TABLE_NAMES.Companies:
                return <CompanyDetails />;
            case TABLE_NAMES.DesktopLicenses:
                return <LicenseDetails />;
            default:
                return <div></div>;
        }
    };

    return toMd ? (
        <MobileDetails>{renderContent()}</MobileDetails>
    ) : (
        <DesktopDetails heights={heights} setDefaultHeigth={setDefaultHeigth}>
            {renderContent()}
        </DesktopDetails>
    );
};

export default TableDetails;
