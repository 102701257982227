import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { RENDER_NAMES, TokenListButtonsProps } from "../types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

const TokenListButtons = ({
    changeRenderName,
    close
}: TokenListButtonsProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Button
                fullWidth
                color={BUTTON_COLORS.Secondary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                onClick={close}
            >
                {t("Button##close")}
            </Button>

            <Button
                fullWidth
                color={BUTTON_COLORS.Primary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                onClick={() => changeRenderName(RENDER_NAMES.Generate)}
            >
                {t("Api tokens##add token")}
            </Button>
        </Fragment>
    );
};

export default TokenListButtons;
