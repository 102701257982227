/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import Actions from "./Actions";
import { useTctWebDialog } from "./hooks";
import { STEPS } from "./types";

import Dialog from "../../../../DesignComponents/Dialog";
import QueryDropdown from "../../../../QueryDropdown";

import {
    DROPDOWN_SIZES,
    DialogHandler,
    ENDPOINTS,
    FILTER_NAMES,
    ID_TYPE,
    maxDialogWidth,
    useMediaQueries
} from "../../../../../shared";

const TctWebDialog = ({ isOpen, close }: DialogHandler) => {
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const {
        isLoading,
        isEditDisabled,
        isInitialStep,
        selectedConfiguration,
        setStep,
        setSelectedConfiguration,
        handleStep,
        handleFileChange,
        handleSubmitCreate,
        handleSubmitEdit
    } = useTctWebDialog(isOpen, close);

    return (
        <Dialog
            open={isOpen}
            close={close}
            title={
                isInitialStep
                    ? t("Tct##open tct")
                    : t("Tct##edit configuration")
            }
            description={
                isInitialStep
                    ? t("Tct##open tct description")
                    : t("Tct##select configuration file")
            }
            isTitleSeparator
            isActionsSeparator={!isInitialStep}
            TransitionProps={{
                onExited: () => {
                    setStep(STEPS.Initial);
                    setSelectedConfiguration(null);
                }
            }}
            actions={
                <Actions
                    isLoading={isLoading}
                    isEditDisabled={isEditDisabled}
                    isInitialStep={isInitialStep}
                    selectedConfiguration={selectedConfiguration}
                    setSelectedConfiguration={setSelectedConfiguration}
                    handleStep={handleStep}
                    handleSubmitCreate={handleSubmitCreate}
                    handleSubmitEdit={handleSubmitEdit}
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            {isInitialStep ? null : (
                <QueryDropdown
                    resource={`${ENDPOINTS.Files}?${FILTER_NAMES.WebEditorSupport}=true`}
                    idType={ID_TYPE.Id}
                    size={DROPDOWN_SIZES.Medium}
                    fullWidth
                    value={selectedConfiguration}
                    change={(_, val) => handleFileChange(val)}
                    labelLeft="Select file"
                    textFieldParams={{
                        placeholder: t("Dialog##select")
                    }}
                    customStyle={{ marginBottom: "8px" }}
                    isRequired
                    isRootRequired
                />
            )}
        </Dialog>
    );
};

export default TctWebDialog;
