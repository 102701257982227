/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import FilteredTagLoader from "../loaders/FilteredTagLoader";
import Description from "../components/Description";
import { TriSourceTabProps } from "../types";

import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../DesignComponents/Tag";

import ThemeContext from "../../../../../../context/theme/themeContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import TableContext from "../../../../../../context/table/tableContext";
import TriSourceContext from "../../../../../../context/triSource/triSourceContext";
import {
    useApi,
    useIsMounted,
    useFormattedNumber,
    TRI_SOURCES,
    API_IS_BEING_CANCELLED,
    getStringifiedParams,
    useDecoder,
    getFilterParamsWithoutRoot
} from "../../../../../../shared";

const Filtered = ({ description, setCount }: TriSourceTabProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    const { filterQueryParams } = useContext(ParamsContext);
    const { tableName, rowsPerPage } = useContext(TableContext);

    const { filteredOnlyCount, handleNeedConfirmation, setFilteredOnlyCount } =
        useContext(TriSourceContext);

    const { t } = useTranslation();
    const { cancelSource, isCanceled, getData } = useApi();
    const { renderFormattedNumber } = useFormattedNumber();
    const { decodeStrings } = useDecoder();
    const isMounted = useIsMounted();

    const activeFiltersExist = Object.keys(filterQueryParams).length > 0;

    // Check if confirmation is required
    useEffect(() => {
        handleNeedConfirmation(Number(filteredOnlyCount) > 20);
        setCount && setCount(Number(filteredOnlyCount));
    }, [filteredOnlyCount, handleNeedConfirmation, setCount]);

    // Get the number of filtered items, excluding the search query
    useEffect(() => {
        const getCount = async () => {
            const stringifiedFilterParams =
                getStringifiedParams(filterQueryParams);

            try {
                const {
                    data: { total }
                } = await getData(
                    `${tableName}?${decodeStrings(
                        stringifiedFilterParams
                    )}&per_page=${rowsPerPage}`
                );

                if (isMounted) {
                    setFilteredOnlyCount(total);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    setFilteredOnlyCount(0);
                }
            }
        };

        activeFiltersExist && filteredOnlyCount === null && getCount();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    const getFilterNames = () => {
        const filterParamsWithoutRoot =
            getFilterParamsWithoutRoot(filterQueryParams);

        return Object.keys(filterParamsWithoutRoot);
    };

    const title = `${t(
        `Dialog##filtered ${tableName}`
    )}: ${renderFormattedNumber(Number(filteredOnlyCount))}`;

    const filterNames = getFilterNames();

    return (
        <div data-testid={`tri-source-${TRI_SOURCES.Filtered}`}>
            <Description
                text={t(
                    `Dialog##tri source##filtered tab description##${description}`
                )}
            />

            {filteredOnlyCount === null ? (
                <FilteredTagLoader />
            ) : (
                <Tag
                    size={TAG_SIZES.Medium}
                    color={TAG_COLORS.Primary}
                    title={title}
                />
            )}

            <div
                css={css({
                    marginTop: "16px",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "4px"
                })}
            >
                <span
                    css={css({
                        color: gray700,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    })}
                >
                    {t("Filter##filters used")}:
                </span>

                {filterNames.map(filterName => (
                    <Tag
                        key={filterName}
                        size={TAG_SIZES.Medium}
                        color={TAG_COLORS.Primary}
                        title={t(`Filter##${filterName}`)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Filtered;
