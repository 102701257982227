import TaskGroupsTable from "./TaskGroupsTable";

import MainContainer from "../MainContainer";
import Table from "../Table";

import { ID_TYPE, TABLE_NAMES, TABLE_NAMES_WITH_FILTERS } from "../../shared";

const Tasks = () => {
    return (
        <MainContainer>
            <Table
                name={TABLE_NAMES.TaskGroups}
                nameForFilters={TABLE_NAMES_WITH_FILTERS.TaskGroups}
                idType={ID_TYPE.Id}
                customTable={<TaskGroupsTable />}
                hasActionBar
                hideSearch
            />
        </MainContainer>
    );
};

export default Tasks;
