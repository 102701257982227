/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { useFilters } from "../hooks";
import FilterDropdown from "../FilterDropdown";
import FilterButton from "../components/FilterButton";
import FilterMenu from "../components/FilterMenu";

import {
    useMediaQueries,
    FILTER_NAMES,
    filterBlockStyle
} from "../../../shared";

export const FileFilters = () => {
    const { t } = useTranslation();
    const { fromSm, toMd, fromLg } = useMediaQueries();
    const { anchorEl, handleOpenMenu, handleCloseMenu } = useFilters();

    return (
        <div css={css(filterBlockStyle)}>
            {(fromSm && toMd) || fromLg ? (
                <FilterDropdown
                    filter={FILTER_NAMES.Type}
                    placeholder={t("Filter##file type")}
                />
            ) : (
                <Fragment>
                    <FilterButton onOpen={handleOpenMenu}>
                        {t("Button##filters")}
                    </FilterButton>

                    <FilterMenu
                        isOpen={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                    >
                        <div>
                            <FilterDropdown
                                filter={FILTER_NAMES.Type}
                                placeholder={t("Filter##file type")}
                            />
                        </div>
                    </FilterMenu>
                </Fragment>
            )}
        </div>
    );
};
