/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { BadgeProps } from "./types";
import { useStyleOptions } from "./hooks/useStyleOptions";

const Badge = ({
    children,
    color,
    iconLeft,
    iconRight,
    inverted
}: BadgeProps) => {
    const styleOptions = useStyleOptions(inverted);

    const textColor = styleOptions[color].text;
    const borderColor = styleOptions[color].border;
    const bgColor = styleOptions[color].background;

    return (
        <div
            css={css({
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.4px",
                borderRadius: "12px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3px 7px",
                color: textColor,
                backgroundColor: bgColor,
                border: `1px solid ${borderColor}`,
                whiteSpace: "nowrap",

                span: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    svg: {
                        fontSize: "16px"
                    }
                }
            })}
        >
            {iconLeft && (
                <span
                    css={css({
                        marginRight: "4px"
                    })}
                >
                    {iconLeft}
                </span>
            )}

            <span>{children}</span>

            {iconRight && (
                <span
                    css={css({
                        marginLeft: "4px"
                    })}
                >
                    {iconRight}
                </span>
            )}
        </div>
    );
};

export * from "./types/interfaces";
export { BADGE_COLORS } from "./types/enums";

export default Badge;
