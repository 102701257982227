import { useState } from "react";
import { useTranslation } from "react-i18next";

import { PasswordErrorValues } from "../../types";

export const usePasswordValidation = () => {
    const { t } = useTranslation();

    const [passwordRequirements, setPasswordRequirements] = useState({
        uppercase: false,
        length: false
    });

    const [passwordError, setPasswordError] = useState<PasswordErrorValues>({
        password1: null,
        password2: null
    });

    const validatePasswordCreation = (password: string) => {
        setPasswordRequirements({
            uppercase: /[A-Z]/.test(password),
            length: password.length >= 8
        });
    };

    const validatePasswordMatching = (password1: string, password2: string) => {
        if (password1 !== password2) {
            setPasswordError({
                ...passwordError,
                password2: t("Auth##passwords do not match")
            });
        }
    };

    const validatePassword = (passwordName: string) => {
        const requirementValues = Object.values(passwordRequirements);
        const isValid = requirementValues.indexOf(false) === -1;

        if (passwordName.length > 0 && !isValid) {
            setPasswordError({
                ...passwordError,
                password1: t("Auth##invalid password format")
            });
        }
    };

    const clearPasswordError = (passwordName: string) => {
        setPasswordError({
            ...passwordError,
            [passwordName]: null
        });
    };

    return {
        passwordRequirements,
        passwordError,
        setPasswordError,
        validatePasswordCreation,
        validatePasswordMatching,
        validatePassword,
        clearPasswordError
    };
};
