/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CompanyLevelBodyItemProps } from "../types/interfaces";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

import SuccessIcon from "../../../../assets/customIcons/status/SuccessIcon";
import DetailedInfoIcon from "../../../../assets/customIcons/status/DetailedInfoIcon";

const CompanyLevelBodyItem = ({
    companyLevel,
    tooltipText,
    itemCSS
}: CompanyLevelBodyItemProps) => {
    return (
        <div css={css([itemCSS])}>
            {companyLevel === "SuccessIcon" ? (
                <SuccessIcon />
            ) : (
                <>
                    <span>{companyLevel}</span>

                    <div>
                        {tooltipText && (
                            <TooltipGeneral
                                placement={TOOLTIP_PLACEMENT.Top}
                                title={tooltipText}
                                css={css({
                                    whiteSpace: "pre-line"
                                })}
                            >
                                <div
                                    css={css({
                                        padding: "4px"
                                    })}
                                >
                                    <DetailedInfoIcon />
                                </div>
                            </TooltipGeneral>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default CompanyLevelBodyItem;
