import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AdapterTypesProps, CanAdapterType } from "./types";

import Autocomplete from "../../../../../../../DesignComponents/Autocomplete";
import {
    API_IS_BEING_CANCELLED,
    DROPDOWN_SIZES,
    ENDPOINTS,
    TASK_TYPES,
    useApi,
    useIsMounted
} from "../../../../../../../../shared";

const AdapterTypes = ({ taskType, change }: AdapterTypesProps) => {
    const { t } = useTranslation();
    const { getData, handleResponse, cancelSource, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(false);
    const [adapters, setAdapters] = useState([]);

    useEffect(() => {
        const getAdapterTypes = async () => {
            try {
                setLoading(true);

                const { data } = await getData(ENDPOINTS.CanAdapterTypes);

                if (isMounted) {
                    const internalType =
                        taskType === TASK_TYPES.TxCanAdapterModeChange ? 0 : 1;

                    const filteredAdapters = data.filter(
                        (adapter: CanAdapterType) =>
                            adapter.internal === internalType
                    );

                    setAdapters(filteredAdapters);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getAdapterTypes();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [taskType]);

    const handleChange = (adapterInfo: CanAdapterType) => {
        const protocolId = adapterInfo
            ? { protocol_id: adapterInfo.protocol_id }
            : { protocol_id: null };

        change(protocolId);
    };

    return (
        <Autocomplete
            fullWidth
            isRequired
            size={DROPDOWN_SIZES.Medium}
            labelLeft={t("Dialog##can adapter")}
            getOptionLabel={option => `${option.type} (${option.seo_name})`}
            textFieldParams={{
                placeholder: t("Dialog##select")
            }}
            loading={isLoading}
            options={adapters}
            onChange={(_, value) => handleChange(value)}
        />
    );
};

export default AdapterTypes;
