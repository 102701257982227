import moment, { Moment } from "moment";
import {
    DateValidationError,
    PickerChangeHandlerContext
} from "@mui/x-date-pickers";

export const useDatePicker = () => {
    const hasDatePickerError = (
        context: PickerChangeHandlerContext<DateValidationError>
    ) => context.validationError !== null;

    const parseDatePickerValue = (
        value: Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>,
        defaultValue: string | null
    ) => {
        if (value !== null) {
            const hasError = hasDatePickerError(context);

            return hasError ? defaultValue : moment(value).format("YYYY-MM-DD");
        }

        return null;
    };

    return {
        hasDatePickerError,
        parseDatePickerValue
    };
};
