import { useState } from "react";

export const useClearHistory = () => {
    const [isClearHistory, setClearHistory] = useState(false);

    const handleClearHistory = (value: boolean) => setClearHistory(value);

    return {
        isClearHistory,
        handleClearHistory
    };
};
