/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BodyLoader, TitleLoader } from "./Loaders";
import SingleResourceLimit from "./SingleResourceLimit";
import { LimitData, RESOURCES } from "./types";

import Title from "../../Title";

import ThemeContext from "../../../../context/theme/themeContext";
import ParamsContext from "../../../../context/params/paramsContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    FILTER_NAMES,
    useApi,
    useIsMounted
} from "../../../../shared";

const ResourceLimitsWidget = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { queryParams } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { cancelSource, getData, handleResponse, isCanceled } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(true);
    const [limitData, setLimitData] = useState<LimitData[]>([]);

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

    useEffect(() => {
        const getLimitData = async () => {
            try {
                setLoading(true);

                const {
                    data: { resource_limits }
                } = await getData(
                    `${ENDPOINTS.Companies}/${rootCompanyId}/resourceLimits`
                );

                if (isMounted) {
                    const sortedData: LimitData[] = resource_limits.sort(
                        (a: LimitData, b: LimitData) => {
                            const order = [
                                RESOURCES.Companies,
                                RESOURCES.Users,
                                RESOURCES.ConfigurationFiles
                            ];

                            return (
                                order.indexOf(a.type) - order.indexOf(b.type)
                            );
                        }
                    );

                    setLimitData(sortedData);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getLimitData();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [rootCompanyId]);

    return (
        <div
            css={css({
                padding: "24px",
                borderTop: `1px solid ${gray200}`,

                "@media (max-width: 1439px)": {
                    borderTop: "none"
                },

                "@media (max-width: 839px)": {
                    borderTop: `1px solid ${gray200}`
                }
            })}
        >
            {isLoading ? (
                <TitleLoader />
            ) : (
                <Title text={t("Welcome dashboard##resource limits")} />
            )}

            {isLoading ? (
                <BodyLoader />
            ) : (
                limitData.map(({ type, current, limit }) => (
                    <SingleResourceLimit
                        key={type}
                        title={t(
                            `Welcome dashboard##resource limit types##${type}`
                        )}
                        current={current}
                        limit={limit}
                    />
                ))
            )}
        </div>
    );
};

export default ResourceLimitsWidget;
