import { useUrlQueryParams, useWindowLocation } from ".";
import {
    FILTER_NAMES,
    getCompanyFilterQueryParamsFromBrowser,
    getQueryParamsFromBrowser,
    getStringifiedParams,
    updateParamsWithCorrectSpecIdValue
} from "..";

export const useParamsFromUrlAndBrowser = () => {
    const { getUrlParams } = useUrlQueryParams();
    const { resource } = useWindowLocation();
    const queryParamsFromUrl = getUrlParams();

    const paramsFromBrowser = {
        ...getQueryParamsFromBrowser(resource),
        ...getCompanyFilterQueryParamsFromBrowser(FILTER_NAMES.RootCompany),
        ...getCompanyFilterQueryParamsFromBrowser(FILTER_NAMES.CompanyId)
    };

    const currentParams = {
        ...paramsFromBrowser,
        ...queryParamsFromUrl
    };

    const paramsWithCorrectSpecId =
        updateParamsWithCorrectSpecIdValue(currentParams);

    const stringifiedCurrentParams = getStringifiedParams(
        paramsWithCorrectSpecId
    );

    const intermediateRootCompanyId =
        paramsWithCorrectSpecId[FILTER_NAMES.RootCompany];

    const companyIdFromUrlAndBrowser =
        paramsWithCorrectSpecId[FILTER_NAMES.CompanyId];

    return {
        currentParams: paramsWithCorrectSpecId,
        stringifiedCurrentParams,
        intermediateRootCompanyId,
        companyIdFromUrlAndBrowser
    };
};
