/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment } from "react";
import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import CircularProgress from "@mui/material/CircularProgress";

import TableItem from "../TableItem";
import TableMobile from "../TableMobile";
import TabFilters from "../TabFilters";
import { TaskGroupsTableProps } from "../types";

import { useTableData, useTableDetailHeights } from "../../Table/hooks";
import Toolbar from "../../Toolbar";
import TableLoader from "../../Loaders/TableLoader";
import TableEmpty from "../../TableEmpty";
import TableDetails from "../../TableDetails";

import ThemeContext from "../../../context/theme/themeContext";
import AuthContext from "../../../context/auth/authContext";
import TableContext from "../../../context/table/tableContext";
import { noop, useMediaQueries, useScroll } from "../../../shared";

const TaskGroupsTable = ({ onScroll = true }: TaskGroupsTableProps) => {
    const {
        colorsFacelift: { blue100, blue200, gray200, textDark, white }
    } = useContext(ThemeContext);

    const { isSupportUser } = useContext(AuthContext);

    const { rows, isTableLoading, isInnerTable, idType } =
        useContext(TableContext);

    const { tableSectionRef, detailsHeights, setDefaultHeigth } =
        useTableDetailHeights();

    useTableData();

    const { toMd } = useMediaQueries();
    const { scrollHorizontal, handleScrollHorizontal } = useScroll();

    const renderTableData = () => {
        if (rows.length === 0) {
            return isTableLoading ? <TableLoader hideHeader /> : <TableEmpty />;
        }

        return (
            <section
                ref={isInnerTable ? null : tableSectionRef}
                className="remaining-height"
            >
                <div
                    className="remaining-height"
                    css={css({
                        position: "relative",
                        overflow: "hidden"
                    })}
                >
                    {isTableLoading && (
                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                top: "88px",
                                width: "100%",
                                zIndex: "4"
                            })}
                        >
                            <CircularProgress />
                        </div>
                    )}

                    <div
                        onScroll={onScroll ? handleScrollHorizontal : noop}
                        css={css({
                            borderTop: `1px solid ${gray200}`,
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            overflow: "auto",

                            "&:active": {
                                pointerEvents: isTableLoading ? "none" : "auto"
                            }
                        })}
                    >
                        {toMd ? (
                            <TableMobile />
                        ) : (
                            <TableMui
                                stickyHeader
                                css={css({
                                    tbody: {
                                        opacity: isTableLoading ? "0.4" : "1",
                                        transition: "opacity 300ms ease-out"
                                    },

                                    td: {
                                        color: textDark,
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        letterSpacing: "0.4px",
                                        whiteSpace: "nowrap",
                                        background: white,
                                        padding: "10px 12px",
                                        borderBottom: "none",
                                        boxShadow: `inset 0px -1px 0px ${gray200}`
                                    },

                                    "td:first-of-type": {
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        letterSpacing: "0.1px",
                                        padding: "10px 12px 10px 24px"
                                    },

                                    "td:last-of-type": {
                                        padding: "10px 24px 10px 12px"
                                    },

                                    "tbody tr": {
                                        cursor: isTableLoading
                                            ? "auto"
                                            : "pointer",

                                        "&.Mui-selected td": {
                                            background: blue100
                                        },

                                        "&:hover": {
                                            "&.Mui-selected td": {
                                                background: blue200
                                            }
                                        }
                                    }
                                })}
                            >
                                <TableBody>
                                    {rows.map((row: any, i: number) => (
                                        <TableItem
                                            key={row[idType]}
                                            data={row}
                                            scrollHorizontal={scrollHorizontal}
                                        />
                                    ))}
                                </TableBody>
                            </TableMui>
                        )}
                    </div>
                </div>

                <TableDetails
                    heights={detailsHeights}
                    setDefaultHeigth={setDefaultHeigth}
                />
            </section>
        );
    };

    return (
        <Fragment>
            {isSupportUser && <TabFilters />}

            <Toolbar />

            {renderTableData()}
        </Fragment>
    );
};

export default TaskGroupsTable;
