/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TaskStatsProps } from "./types";

import TaskStatusIcon from "../../../Table/TableItem/CustomValues/TaskStatusIcon";

import { useMediaQueries } from "../../../../shared";

const TaskStats = ({ data }: TaskStatsProps) => {
    const { toMd } = useMediaQueries();

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                flexWrap: toMd ? "wrap" : "nowrap",
                columnGap: "4px",
                rowGap: "2px"
            })}
        >
            {data.map(({ status_id, count }) => (
                <TaskStatusIcon key={status_id} id={status_id} count={count} />
            ))}
        </div>
    );
};

export default TaskStats;
