import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompaniesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M4.8 20C4.305 20 3.8814 19.8261 3.5292 19.4782C3.1764 19.1298 3 18.7111 3 18.2222V5.77778C3 5.28889 3.1764 4.87022 3.5292 4.52178C3.8814 4.17393 4.305 4 4.8 4H10.2C10.695 4 11.1189 4.17393 11.4717 4.52178C11.8239 4.87022 12 5.28889 12 5.77778V7.55556H19.2C19.695 7.55556 20.1189 7.72948 20.4717 8.07733C20.8239 8.42578 21 8.84444 21 9.33333V18.2222C21 18.7111 20.8239 19.1298 20.4717 19.4782C20.1189 19.8261 19.695 20 19.2 20H4.8ZM4.8 18.2222H10.2V16.4444H4.8V18.2222ZM4.8 14.6667H10.2V12.8889H4.8V14.6667ZM4.8 11.1111H10.2V9.33333H4.8V11.1111ZM4.8 7.55556H10.2V5.77778H4.8V7.55556ZM12 18.2222H19.2V9.33333H12V18.2222ZM13.8 12.8889V11.1111H17.4V12.8889H13.8ZM13.8 16.4444V14.6667H17.4V16.4444H13.8Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default CompaniesIcon;
