/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import FoundResourceCompanyRow from "./FoundResourceCompanyRow";
import FoundResourceInfoRow from "./FoundResourceInfoRow";

import { FoundResourceDetailsProps } from "../types/interfaces";

import TextLink, { TEXT_LINK_SIZES } from "../../../DesignComponents/TextLink";
import {
    TooltipOptional,
    TOOLTIP_PLACEMENT
} from "../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../context/theme/themeContext";
import ParamsContext from "../../../../context/params/paramsContext";
import {
    FIND_TOOL_FILTER,
    ROUTES,
    useWindowLocation,
    NOT_FILTER_NAMES,
    FILTER_NAMES
} from "../../../../shared";

const FoundResourceDetails = ({
    resourceData,
    tabData,
    close
}: FoundResourceDetailsProps) => {
    const {
        colorsFacelift: { textDark, gray700 }
    } = useContext(ThemeContext);

    const { queryParams, changeRootCompany, setQueryParams } =
        useContext(ParamsContext);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useWindowLocation();

    const {
        imei,
        email,
        company_id,
        company_name,
        internal_company_name,
        controlled_by_user
    } = resourceData;

    const { label, resourceRoute } = tabData;

    const handleLinkClick = () => {
        const rootCompanyId = queryParams[FILTER_NAMES.RootCompany] as
            | string
            | undefined;

        const queryResource = resourceRoute === ROUTES.Devices ? imei : email;

        localStorage.setItem(FIND_TOOL_FILTER, String(queryResource));

        const updatedParams = {
            [NOT_FILTER_NAMES.Query]: String(queryResource),
            [FILTER_NAMES.RootCompany]: String(company_id)
        };

        pathname !== resourceRoute &&
            navigate({
                pathname: resourceRoute,
                search: createSearchParams(updatedParams).toString()
            });

        setQueryParams({
            params: updatedParams
        });

        String(company_id) !== rootCompanyId &&
            changeRootCompany(String(company_id), {
                [NOT_FILTER_NAMES.Query]: String(queryResource)
            });

        close();
    };

    return resourceData ? (
        <div
            css={css({
                marginTop: "14px",

                "> div": {
                    display: "flex",
                    gridTemplateColumns: "auto auto",
                    padding: "4px 0"
                },

                "> div:last-of-type": {
                    padding: "4px 0 0"
                },

                "div > span": {
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px"
                },

                "div > span:first-of-type": {
                    color: gray700,
                    minWidth: "136px",
                    paddingRight: "4px"
                },

                "div > span:last-of-type": {
                    color: textDark,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            })}
        >
            <FoundResourceInfoRow
                resourceData={resourceData}
                resourceRoute={resourceRoute}
            />

            <FoundResourceCompanyRow
                companyType={t(`Dialog##company`)}
                companyName={company_name}
            />

            <FoundResourceCompanyRow
                companyType={t(`Dialog##regional company`)}
                companyName={internal_company_name}
            />

            <div>
                <TooltipOptional
                    title={t(`Dialog##find tool##${label}##no permission`)}
                    placement={TOOLTIP_PLACEMENT.Top}
                    isHidden={controlled_by_user}
                >
                    <div>
                        <TextLink
                            onClick={handleLinkClick}
                            size={TEXT_LINK_SIZES.Normal}
                            isDisabled={!controlled_by_user}
                        >
                            {t(`Dialog##find tool##${label}##link`)}
                        </TextLink>
                    </div>
                </TooltipOptional>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default FoundResourceDetails;
