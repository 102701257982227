/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { MouseEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { hasLicenseExpired } from "../functions";
import { HeaderProps } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../DesignComponents/Button";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";
import CreateOfflineLicense from "../../../Actions/dialogs/Licenses/OfflineLicenses/CreateOfflineLicense";
import { useDialog } from "../../../Actions/ActionBar/hooks";
import { LICENSE_ACTIONS } from "../../../Actions/types";

import ThemeContext from "../../../../context/theme/themeContext";
import TableContext from "../../../../context/table/tableContext";
import { useFormattedNumber, useOverflow } from "../../../../shared";

const Header = ({ isDetailsTabSelected }: HeaderProps) => {
    const {
        colorsFacelift: { textDark, textPlaceholder }
    } = useContext(ThemeContext);

    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();
    const { measuredRef, isOverflowing } = useOverflow();
    const { renderFormattedNumber } = useFormattedNumber();
    const { isOpenDialog, closeDialog, openDialog } = useDialog();

    const { expires_at, imei_restricted, name, offline_licenses } = detailsData;

    const handleOpenDialog = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        openDialog(LICENSE_ACTIONS.CreateOfflineLicense);
    };

    const handleCloseDialog = () =>
        closeDialog(LICENSE_ACTIONS.CreateOfflineLicense);

    const isDesktopLicenseUnrestricted = !imei_restricted;
    const hasDesktopLicenseExpired = hasLicenseExpired(expires_at);

    const isCreateOfflineLicenseDisabled =
        isDesktopLicenseUnrestricted || hasDesktopLicenseExpired;

    const getCreateOfflineLicenseTooltipTitle = () => {
        if (isDesktopLicenseUnrestricted) {
            return t("Button##cannot create offline licenses");
        }

        return hasDesktopLicenseExpired
            ? t("General##license has expired")
            : "";
    };

    return (
        <>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "32px",
                    marginBottom: "16px",

                    "@media (max-width: 839px)": {
                        display: "block",
                        margin: "0 60px 24px 0"
                    }
                })}
            >
                <div
                    css={css({
                        overflowX: "hidden"
                    })}
                >
                    <div
                        css={css({
                            color: textDark,
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "32px",
                            marginBottom: "4px"
                        })}
                    >
                        <TooltipGeneral
                            title={name}
                            placement={TOOLTIP_PLACEMENT.Top}
                            disableHoverListener={!isOverflowing}
                        >
                            <span
                                ref={measuredRef}
                                css={css({
                                    display: "block",
                                    whiteSpace: "nowrap",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis"
                                })}
                            >
                                {name}
                            </span>
                        </TooltipGeneral>
                    </div>

                    <div
                        css={css({
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",

                            "span:first-of-type": {
                                color: textPlaceholder,
                                paddingRight: "5px"
                            },

                            "span:last-of-type": {
                                color: textDark
                            }
                        })}
                    >
                        <span>{t("General##offline licenses")}:</span>

                        <span>
                            {renderFormattedNumber(offline_licenses.length)}
                        </span>
                    </div>
                </div>

                {!isDetailsTabSelected && (
                    <TooltipGeneral
                        title={getCreateOfflineLicenseTooltipTitle()}
                        placement={TOOLTIP_PLACEMENT.Top}
                        disableTouchListener
                    >
                        <span
                            css={css({
                                marginRight: "60px",

                                "@media (max-width: 839px)": {
                                    marginRight: "0",
                                    marginTop: "16px"
                                }
                            })}
                        >
                            <Button
                                color={BUTTON_COLORS.Secondary}
                                size={BUTTON_SIZES.Small}
                                variant={BUTTON_VARIANTS.IconLeft}
                                icon={<AddRoundedIcon />}
                                onClick={handleOpenDialog}
                                disabled={isCreateOfflineLicenseDisabled}
                            >
                                {t("Button##create")}
                            </Button>
                        </span>
                    </TooltipGeneral>
                )}
            </div>

            <CreateOfflineLicense
                isOpen={isOpenDialog[LICENSE_ACTIONS.CreateOfflineLicense]}
                close={handleCloseDialog}
            />
        </>
    );
};

export default Header;
