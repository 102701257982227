import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const ErrorCheckedIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { blue700, red50, red700, white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="11"
                    fill={blue700}
                    stroke={red50}
                    strokeWidth="2"
                />
                <circle cx="12" cy="12" r="4" fill={white} />
                <rect
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    stroke={red700}
                />
            </g>
        </SvgIcon>
    );
};

export default ErrorCheckedIcon;
