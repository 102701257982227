import { useState, Fragment, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";

import SettingsMenuDesktop from "./SettingsMenuDesktop";
import SettingsContent from "./SettingsContent";
import ApiTokensDialog from "./ApiTokens";
import SecurityDialog from "./Security";
import { DIALOG_NAMES, SettingsProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../DesignComponents/Button";
import CompanyLevelDialog from "../../../CompanyLevel";

import { MenuAnchorEl, useMediaQueries } from "../../../../shared";

const Settings = ({ handleDrawer }: SettingsProps) => {
    const { t } = useTranslation();
    const { fromMd } = useMediaQueries();

    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const [isDialogOpen, setDialogOpen] = useState({
        apiTokens: false,
        security: false,
        companyLevel: false
    });

    const openMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) =>
        setAnchorEl(e.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    const openDialog = (name: DIALOG_NAMES) => {
        closeMenu();
        setDialogOpen({ ...isDialogOpen, [name]: true });
    };

    const closeDialog = () => {
        handleDrawer && handleDrawer(false);

        setDialogOpen({
            apiTokens: false,
            security: false,
            companyLevel: false
        });
    };

    return (
        <Fragment>
            {fromMd && (
                <Button
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconLeft}
                    icon={<SettingsIcon />}
                    onClick={openMenu}
                >
                    {t("Button##settings")}
                </Button>
            )}

            {fromMd ? (
                <SettingsMenuDesktop
                    isMobile={false}
                    anchorEl={anchorEl}
                    close={closeMenu}
                    openDialog={openDialog}
                />
            ) : (
                <SettingsContent
                    isMobile={true}
                    openDialog={openDialog}
                    closeMenu={closeMenu}
                />
            )}

            <ApiTokensDialog
                isOpen={isDialogOpen.apiTokens}
                close={closeDialog}
            />

            <SecurityDialog
                isOpen={isDialogOpen.security}
                close={closeDialog}
            />

            <CompanyLevelDialog
                isOpen={isDialogOpen.companyLevel}
                close={closeDialog}
            />
        </Fragment>
    );
};

export default Settings;
