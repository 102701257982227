/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { ControlsItemFrameProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";
import Logo from "../../../../Logo";

import ThemeContext from "../../../../../context/theme/themeContext";
import {
    ROUTES,
    useMediaQueries,
    useWindowLocation
} from "../../../../../shared";

const ControlsItemFrame = ({
    close,
    boxPadding,
    itemName,
    hasBottomBorder
}: ControlsItemFrameProps) => {
    const {
        colorsFacelift: { gray200, textDark }
    } = useContext(ThemeContext);

    const { toMd, fromMd } = useMediaQueries();
    const navigate = useNavigate();
    const { pathname } = useWindowLocation();

    return (
        <>
            {toMd && (
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px 0 4px 24px"
                    })}
                >
                    <Logo
                        css={css({
                            cursor: "pointer"
                        })}
                        onClick={() => {
                            pathname !== ROUTES.Dashboard &&
                                navigate(ROUTES.Dashboard);
                            close();
                        }}
                    />
                </div>
            )}

            <div
                css={css({
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: boxPadding,
                    borderBottom: hasBottomBorder
                        ? `1px solid ${gray200}`
                        : "none",

                    "& > h2": {
                        color: textDark,
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        alignSelf: "flex-end"
                    },

                    "& > button": {
                        position: "absolute",
                        top: "4px",
                        right: "4px"
                    }
                })}
            >
                <h2>{itemName}</h2>

                {fromMd && (
                    <Button
                        id="controlsItemFrameBtn"
                        color={BUTTON_COLORS.White}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.IconOnly}
                        icon={<CloseIcon />}
                        onClick={close}
                    />
                )}
            </div>
        </>
    );
};

export default ControlsItemFrame;
