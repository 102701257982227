import { useState, useEffect, useContext, useRef } from "react";

import {
    API_IS_BEING_CANCELLED,
    useApi,
    useIsMounted,
    LINK_IDS,
    ENDPOINTS,
    FILTER_NAMES,
    FilterId
} from "..";
import { COUNTER_INITIAL_STATE } from "../constants/resources";

import ParamsContext from "../../context/params/paramsContext";
import { CounterData } from "../../components/TableDetails/CompanyDetails/types/interfaces";

export const useCounterData = (
    id: FilterId[] | number | undefined,
    doesHaveSameRootId?: boolean,
    doesIncludeHierarchy?: boolean
) => {
    const { queryParams } = useContext(ParamsContext);

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();
    const isInitialLoad = useRef(true);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [counter, setCounter] = useState<CounterData>(COUNTER_INITIAL_STATE);

    const { Users, Groups, Files, Devices, Tasks, ChildCompanies } = LINK_IDS;

    const getStatisticsEndpoint = () => {
        const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

        const rootCompanyParams = `${FILTER_NAMES.RootCompany}=${doesHaveSameRootId ? id : rootCompanyId}`;

        const isCompanyIdExists = Array.isArray(id)
            ? id.length !== 0
            : id !== undefined;

        const companyIdParams =
            isCompanyIdExists && !doesIncludeHierarchy
                ? `&${FILTER_NAMES.CompanyId}=${id}`
                : "";

        const params = `?${rootCompanyParams}${companyIdParams}`;

        return `${ENDPOINTS.Companies}/stats${params}`;
    };

    const statisticsEndpoint = getStatisticsEndpoint();

    useEffect(() => {
        const handleCatchError = (error: any) => {
            if (isMounted && !isCanceled(error)) {
                handleResponse(error);
                setCounter(COUNTER_INITIAL_STATE);
                setLoading(false);
            }
        };

        const getCounterData = async () => {
            try {
                setLoading(true);

                const {
                    data: {
                        user_count,
                        group_count,
                        file_count,
                        device_count,
                        task_count,
                        company_count
                    }
                } = await getData(statisticsEndpoint);

                setCounter({
                    [Users]: user_count,
                    [Groups]: group_count,
                    [Files]: file_count,
                    [Devices]: device_count,
                    [Tasks]: task_count,
                    [ChildCompanies]: company_count
                });

                setLoading(false);
            } catch (error) {
                handleCatchError(error);
            }

            if (isInitialLoad.current) {
                isInitialLoad.current = false;
            }
        };

        if (isMounted && id !== undefined) {
            getCounterData();
        }

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isMounted, statisticsEndpoint]);

    return { isInitialLoad: isInitialLoad.current, isLoading, counter };
};
