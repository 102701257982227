import { useContext } from "react";

import {
    TABLE_ACTIONS,
    WS_CUSTOM_CHANNEL_NAMES,
    WS_EVENT_NAMES
} from "./types";

import { TABLE_NAMES } from "../../types";

import AuthContext from "../../../context/auth/authContext";

export const useBroadcasting = () => {
    const { user } = useContext(AuthContext);

    const listenForEvents = (
        channelName: string,
        eventName: string,
        callback: () => void
    ) => {
        const channel = window.Echo.channel(channelName);

        if (channel && channel.events && channel.events[eventName]) {
            return; // Skip subscribing if already listening
        }

        channel.listen(eventName, callback);
    };

    const leaveChannelGeneral = (fullChannelName: string) => {
        if (window.Echo.channel(fullChannelName)) {
            window.Echo.leaveChannel(fullChannelName);
        }
    };

    const listenForTableEvents = (
        action: TABLE_ACTIONS,
        tableName: TABLE_NAMES,
        id: number,
        callbackFunc: () => void
    ) => {
        const updateEventNames = {
            [TABLE_NAMES.Companies]: WS_EVENT_NAMES.Company,
            [TABLE_NAMES.DesktopLicenses]: WS_EVENT_NAMES.License,
            [TABLE_NAMES.DeviceGroups]: WS_EVENT_NAMES.DeviceGroup,
            [TABLE_NAMES.Devices]: WS_EVENT_NAMES.Device,
            [TABLE_NAMES.Files]: WS_EVENT_NAMES.File,
            [TABLE_NAMES.ProductCodes]: WS_EVENT_NAMES.ProductCode,
            [TABLE_NAMES.TaskGroups]: WS_EVENT_NAMES.TaskGroup,
            [TABLE_NAMES.Tasks]: WS_EVENT_NAMES.Task,
            [TABLE_NAMES.Users]: WS_EVENT_NAMES.User
        };

        const deleteEventNames = {
            [TABLE_NAMES.Companies]: WS_EVENT_NAMES.Company,
            [TABLE_NAMES.DesktopLicenses]: WS_EVENT_NAMES.License,
            [TABLE_NAMES.DeviceGroups]: WS_EVENT_NAMES.DeviceGroup,
            [TABLE_NAMES.Devices]: WS_EVENT_NAMES.Device,
            [TABLE_NAMES.Files]: WS_EVENT_NAMES.File,
            [TABLE_NAMES.ProductCodes]: WS_EVENT_NAMES.ProductCode,
            [TABLE_NAMES.Users]: WS_EVENT_NAMES.User
        };

        const isUpdateAction = action === TABLE_ACTIONS.Update;

        const eventNames = isUpdateAction ? updateEventNames : deleteEventNames;

        if (tableName in eventNames) {
            const eventName = eventNames[tableName as keyof typeof eventNames];

            const channelName =
                tableName === TABLE_NAMES.DesktopLicenses
                    ? WS_CUSTOM_CHANNEL_NAMES.Licenses
                    : tableName;

            const fullChannelName = `${channelName}.${id}`;
            const fullEventName = `.${eventName}_${isUpdateAction ? "updating" : "deleting"}`;

            listenForEvents(fullChannelName, fullEventName, callbackFunc);
        }
    };

    const updateTableData = (
        tableName: TABLE_NAMES,
        id: number,
        callbackFunc: () => void
    ) =>
        listenForTableEvents(TABLE_ACTIONS.Update, tableName, id, callbackFunc);

    const deleteTableData = (
        tableName: TABLE_NAMES,
        id: number,
        callbackFunc: () => void
    ) =>
        listenForTableEvents(TABLE_ACTIONS.Delete, tableName, id, callbackFunc);

    const updateCompanyData = (id: number, callbackFunc: () => void) =>
        updateTableData(TABLE_NAMES.Companies, id, callbackFunc);

    const receiveNotification = (callbackFunc: () => void) => {
        const fullChannelName = `${WS_CUSTOM_CHANNEL_NAMES.User}.${user?.id}`;
        const fullEventName = "._creating";

        listenForEvents(fullChannelName, fullEventName, callbackFunc);
    };

    const receiveBackgroundActionCompletion = (
        id: string,
        callbackFunc: () => void
    ) => {
        const fullChannelName = `${WS_CUSTOM_CHANNEL_NAMES.BackgroundActions}.${id}`;
        const fullEventName = `.${WS_EVENT_NAMES.BackgroundAction}_updating`;

        listenForEvents(fullChannelName, fullEventName, callbackFunc);
    };

    const leaveChannel = (channelName: TABLE_NAMES, id: number) => {
        const fullChannelName = `${channelName}.${id}`;

        leaveChannelGeneral(fullChannelName);
    };

    const leaveBackgroundActionChannel = (id: string) => {
        const fullChannelName = `${WS_CUSTOM_CHANNEL_NAMES.BackgroundActions}.${id}`;

        leaveChannelGeneral(fullChannelName);
    };

    return {
        receiveBackgroundActionCompletion,
        receiveNotification,
        updateCompanyData,
        updateTableData,
        deleteTableData,
        leaveBackgroundActionChannel,
        leaveChannel
    };
};
