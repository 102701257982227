/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { DrawerLabel, SingleDeviceStatsItem } from "./components";
import { DeviceStatisticsContentProps } from "./types";

import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../DesignComponents/BannerMessage";

import { DEVICE_STATISTICS, noop, useScroll } from "../../../shared";

const DeviceStatisticsContent = ({
    contentPadding,
    onScroll,
    permanentBorder
}: DeviceStatisticsContentProps) => {
    const { t } = useTranslation();

    const { scrollTop, handleScroll } = useScroll();

    const deviceStatsQueries = Object.values(DEVICE_STATISTICS);

    return (
        <Fragment>
            <DrawerLabel
                scrollTop={scrollTop}
                permanentBorder={permanentBorder}
            />

            <div
                onScroll={onScroll ? handleScroll : noop}
                css={css({
                    overflowY: "scroll",
                    padding: contentPadding,
                    overflowX: "hidden",
                    position: "relative"
                })}
            >
                <BannerMessage
                    status={BANNER_MESSAGE_STATUSES.Info}
                    title={t("Statistics##statistics message")}
                />

                {deviceStatsQueries.map(
                    (query: DEVICE_STATISTICS, index: number) => (
                        <SingleDeviceStatsItem
                            key={query}
                            query={query}
                            isLast={deviceStatsQueries.length - 1 === index}
                        />
                    )
                )}
            </div>
        </Fragment>
    );
};

export default DeviceStatisticsContent;
