import { useContext, useEffect } from "react";

import ParamsContext from "../../../context/params/paramsContext";
import TableContext from "../../../context/table/tableContext";
import {
    BatchTaskStatus,
    FILTER_NAMES,
    TABLE_NAMES,
    isRefreshableTaskType,
    useApi,
    useIsMounted
} from "../../../shared";

export const useTaskGroupUpdate = (
    id: number,
    taskStats: BatchTaskStatus[],
    isSelected: boolean
) => {
    const { queryParams } = useContext(ParamsContext);
    const { updateSingleRowData } = useContext(TableContext);

    const { getData, isCanceled, handleResponse } = useApi();
    const isMounted = useIsMounted();

    const getRequestStatus = () => {
        const isAtLeastOneRefreshable = taskStats.some(
            ({ status_id }: BatchTaskStatus) => isRefreshableTaskType(status_id)
        );

        return isSelected && isAtLeastOneRefreshable;
    };

    const isReadyToRequest = getRequestStatus();

    useEffect(() => {
        const getBatchData = async () => {
            try {
                const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];
                const companyIds = queryParams[FILTER_NAMES.CompanyId];

                const rootQuery = `?${FILTER_NAMES.RootCompany}=${rootCompanyId}`;

                const companyIdQuery = companyIds
                    ? `&${FILTER_NAMES.CompanyId}=${companyIds}`
                    : "";

                const { data: batchData } = await getData(
                    `${TABLE_NAMES.TaskGroups}/${id}${rootQuery}${companyIdQuery}`
                );

                if (isMounted) {
                    updateSingleRowData(batchData);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                }
            }
        };

        isReadyToRequest && getBatchData();

        const timer = setInterval(() => {
            isReadyToRequest && getBatchData();
        }, 60000);

        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [isReadyToRequest]);
};
