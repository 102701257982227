/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SyntheticEvent, useContext, useState } from "react";
import { Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

import CompanyLevelMobileBody from "./CompanyLevelMobileBody";
import CompanyLevelRectangle from "../shared/components/CompanyLevelRectangle";
import { COMPANY_LEVEL_DIALOGS } from "../shared/types/enums";
import { CompanyLevelDesktopMobileProps } from "../shared/types/interfaces";
import CompanyLevelCallToAction from "../shared/components/CompanyLevelCallToAction";
import CompanyLevelFAQ from "../shared/components/CompanyLevelFAQ";
import CompanyLevelHeaderSubtitleTag from "../shared/components/CompanyLevelHeaderSubtitleTag";

import SegmentedToggle, {
    TAB_SIZES
} from "../../DesignComponents/SegmentedToggle";
import ThemeContext from "../../../context/theme/themeContext";
import { useMediaQueries } from "../../../shared";

const CompanyLevelMobile = ({
    faqRef,
    currentLevel,
    isVirtualCompany
}: CompanyLevelDesktopMobileProps) => {
    const {
        colorsFacelift: { gray100, white }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { toSm } = useMediaQueries();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (_: SyntheticEvent, newValue: number) =>
        setTabValue(newValue);

    const currentCompanyLevel = Object.values(COMPANY_LEVEL_DIALOGS)[
        tabValue + 1
    ];

    return (
        <div
            css={css({
                overflowY: "auto",
                scrollBehavior: "smooth"
            })}
        >
            <div
                css={css({
                    padding: toSm
                        ? "32px 16px 16px 16px"
                        : "32px 24px 16px 24px",

                    "> div:first-of-type": {
                        marginBottom: "24px"
                    }
                })}
            >
                <CompanyLevelRectangle companyLevelName={currentCompanyLevel} />
                <SegmentedToggle
                    value={tabValue}
                    size={TAB_SIZES.Small}
                    fullWidth
                    onChange={handleTabChange}
                >
                    <Tab
                        label={t(
                            `Dialog##company level##${COMPANY_LEVEL_DIALOGS.Basic}`
                        )}
                    />
                    <Tab
                        label={t(
                            `Dialog##company level##${COMPANY_LEVEL_DIALOGS.Standard}`
                        )}
                    />
                    <Tab
                        label={t(
                            `Dialog##company level##${COMPANY_LEVEL_DIALOGS.Premium}`
                        )}
                    />
                </SegmentedToggle>
                <div
                    css={css({
                        "> span": { padding: "16px 0px 8px 0px" },
                        "span, div": {
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center"
                        }
                    })}
                >
                    <CompanyLevelHeaderSubtitleTag
                        currentLevel={currentLevel}
                        companyLevelName={currentCompanyLevel}
                    />
                </div>
            </div>

            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",

                    "> div": {
                        backgroundColor: gray100
                    }
                })}
            >
                <CompanyLevelMobileBody
                    companyLevelName={currentCompanyLevel}
                />

                <div
                    ref={faqRef}
                    css={css({
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",

                        "> div": {
                            padding: "0 24px",
                            backgroundColor: white
                        },
                        "> div:first-of-type": {
                            margin: "24px 0"
                        }
                    })}
                >
                    {!isVirtualCompany && <CompanyLevelCallToAction />}
                    <CompanyLevelFAQ />
                </div>
            </div>
        </div>
    );
};

export default CompanyLevelMobile;
