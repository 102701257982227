/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { DividerProps } from "./types";
import ThemeContext from "../../../context/theme/themeContext";

const Divider = ({ marginBottom, hasMarginOnTop }: DividerProps) => {
    const {
        colorsFacelift: { gray300 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                height: "1px",
                width: "100%",
                backgroundColor: gray300,
                marginTop: hasMarginOnTop ? "12px" : "0",
                marginBottom: marginBottom || "0"
            })}
        ></div>
    );
};

export default Divider;
