/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { SingleValidationProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";

const SingleValidation = ({
    isRuleMet,
    rule,
    ruleName
}: SingleValidationProps) => {
    const {
        colorsFacelift: { green600, textDark, textDarkDisabled }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                padding: "2px 0"
            })}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    height: "20px"
                })}
            >
                <CheckCircleRoundedIcon
                    sx={{
                        fontSize: "16px",
                        color: isRuleMet[rule] ? green600 : textDarkDisabled
                    }}
                />
            </div>

            <span
                css={css({
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    marginLeft: "8px"
                })}
            >
                {ruleName}
            </span>
        </div>
    );
};

export default SingleValidation;
