import {
    SET_LOADING,
    REMOVE_LOADING,
    SET_USER,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    SET_COOKIE_CONSENT,
    UPDATE_USER_COMPANY,
    TOGGLE_2FA_VERIFICATION,
    REMOVE_ERROR_MESSAGE,
    TOGGLE_2FA_ENABLE,
    TOGGLE_2FA_SUGGESTION
} from "./authActions";
import { AuthAction, AuthInitialState, Cookies } from "./types";

import {
    ADMIN_USER,
    CARRIER_COMPANY,
    COOKIE_NAMES,
    SYSTEM_USER,
    SUPPORT_USER,
    ADMIN_MANAGER_USER,
    getEnvironment
} from "../../shared";

const AuthReducer = (
    state: AuthInitialState,
    action: AuthAction
): AuthInitialState => {
    const { isDevelopmentEnv } = getEnvironment();

    switch (action.type) {
        case TOGGLE_2FA_SUGGESTION: {
            const { isOpen, hasSeen } = action.payload;

            return {
                ...state,
                is2FaSuggestionOpen: isOpen,
                is2FaEnableFlowSeen: hasSeen,
                isLoading: false
            };
        }
        case TOGGLE_2FA_VERIFICATION: {
            return {
                ...state,
                errorMessage: null,
                is2FaVerification: action.payload,
                isLoading: false
            };
        }
        case LOGIN_SUCCESS:
        case SET_USER: {
            const { user, companyLevelSettings } = action.payload;

            const { company_id, role, two_factor_auth_enabled } = user;

            const isSupportUser = role >= SUPPORT_USER;

            const is2FaSuggestionOpen =
                isSupportUser && !two_factor_auth_enabled;

            const is2FaEnableFlowSeen =
                isSupportUser && !isDevelopmentEnv
                    ? two_factor_auth_enabled
                    : true;

            return {
                ...state,
                user,
                allCompanyLevelSettings: companyLevelSettings,
                errorMessage: null,
                is2FaSuggestionOpen,
                is2FaEnableFlowSeen,
                is2FaVerification: false,
                isAuthenticated: true,
                isSupportUser,
                isAdminUser: role >= ADMIN_USER,
                isAdminManagerUser: role >= ADMIN_MANAGER_USER,
                isSystemUser: role >= SYSTEM_USER,
                isCarrierUser: company_id === CARRIER_COMPANY,
                isLoading: false,
                isAuthLoading: false
            };
        }
        case AUTH_ERROR: {
            return {
                ...state,
                user: null,
                allCompanyLevelSettings: null,
                errorMessage: action.payload || null,
                is2FaVerification: false,
                isAuthenticated: false,
                isSupportUser: false,
                isAdminUser: false,
                isAdminManagerUser: false,
                isSystemUser: false,
                isCarrierUser: false,
                isLoading: false,
                isAuthLoading: false
            };
        }
        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessage: null
            };
        }
        case TOGGLE_2FA_ENABLE: {
            const { user } = state;

            return {
                ...state,
                user: user
                    ? {
                          ...user,
                          two_factor_auth_enabled: action.payload
                      }
                    : null
            };
        }
        case UPDATE_USER_COMPANY: {
            return {
                ...state,
                user: action.payload
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case REMOVE_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SET_COOKIE_CONSENT: {
            const yearFromNowToExpire = new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
            );

            const { cookieName, cookieValue } = action.payload;

            const allCookies = { ...state.cookieConsent };

            const allCookieNames = Object.keys(allCookies) as COOKIE_NAMES[];

            const setCookies = () => {
                if (cookieName === COOKIE_NAMES.All) {
                    const allCookiesToSet = {} as Cookies;

                    allCookieNames.forEach(nameOfCookie => {
                        document.cookie = `${nameOfCookie}=${cookieValue}; expires=${yearFromNowToExpire}`;

                        allCookiesToSet[nameOfCookie as keyof Cookies] =
                            cookieValue.toString();
                    });

                    return allCookiesToSet;
                } else {
                    document.cookie = `${cookieName}=${cookieValue}; expires=${yearFromNowToExpire}`;
                }
            };

            return {
                ...state,
                cookieConsent: {
                    ...state.cookieConsent,
                    ...setCookies()
                }
            };
        }
        default:
            return state;
    }
};

export default AuthReducer;
