import { useContext, useEffect } from "react";

import DeviceStatisticsContent from "./DeviceStatisticsContent";

import DialogCustom from "../DesignComponents/DialogCustom";
import RoutesContext from "../../context/routes/routesContext";

const DeviceStatisticsMobile = () => {
    const { isStatisticsOpen, closeStatistics } = useContext(RoutesContext);

    useEffect(() => {
        if (isStatisticsOpen) {
            closeStatistics();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DialogCustom noPadding open={isStatisticsOpen}>
            <DeviceStatisticsContent
                permanentBorder
                contentPadding={"8px 16px"}
            />
        </DialogCustom>
    );
};

export default DeviceStatisticsMobile;
