/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useContext, useEffect, useRef } from "react";
import Joyride, { EVENTS, STATUS } from "react-joyride";

import { TutorialTooltip } from "./TutorialTooltip";
import { STEP_GROUP } from "./types/enums";
import useTutorialStepGroups from "./useTutorialStepGroups";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import ThemeContext from "../../context/theme/themeContext";
import TutorialContext from "../../context/tutorial/tutorialContext";
import { useMediaQueries } from "../../shared";

const TutorialMode = () => {
    const {
        isTutorialOpen,
        setTutorialOpen,
        stepGroup,
        stepIndex,
        setDrawerStatus,
        setInitialRender
    } = useContext(TutorialContext);

    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    const {
        companySelectorSteps,
        finishTutorialSteps,
        firstTimeUserSteps,
        taskGroupsSteps,
        taskGroupsStepsMobile
    } = useTutorialStepGroups();

    const { fromMd, toSm } = useMediaQueries();

    const handleExit = () => {
        setTutorialOpen(false, 0, STEP_GROUP.TutorialCompanySelector);
        document.getElementById("controlsItemFrameBtn")?.click();
        setDrawerStatus(false);

        localStorage.setItem("tutorialInitialRender", "false");
        setInitialRender(false);
    };

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        handleExit();
        //eslint-disable-next-line
    }, [fromMd, toSm]);

    useEffect(() => {
        const closeTutorial = (e: any) => {
            if (e.key === "Escape") {
                handleExit();
            }
        };

        window.addEventListener("keydown", closeTutorial);
        //eslint-disable-next-line
    }, []);

    const handleClicks = (data: any) => {
        const { status, type } = data;

        if ([EVENTS.STEP_AFTER].includes(type)) {
            if (
                stepGroup === STEP_GROUP.TutorialCompanySelector &&
                stepIndex === 1
            ) {
                toSm
                    ? document
                          .getElementById("companyFilterOpenBtnMobile")
                          ?.click()
                    : document.getElementById("companyFilterOpenBtn")?.click();
            } else {
                document.getElementById("controlsItemFrameBtn")?.click();
                document.getElementById("dialogCustomCloseBtn")?.click();
            }

            if (stepGroup === STEP_GROUP.FinishTutorial) {
                setTutorialOpen(true);
            }
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setTutorialOpen(false);
        }
    };

    const currentStepGroup = (() => {
        switch (stepGroup) {
            case STEP_GROUP.FirstTimeUser:
                return firstTimeUserSteps;

            case STEP_GROUP.FinishTutorial:
                return finishTutorialSteps;

            case STEP_GROUP.TutorialTaskGroups:
                return fromMd ? taskGroupsSteps : taskGroupsStepsMobile;

            default:
                return companySelectorSteps;
        }
    })();

    return (
        <Fragment>
            <Joyride
                floaterProps={{ hideArrow: true }}
                scrollOffset={100}
                styles={{
                    options: {
                        zIndex: 10000
                    }
                }}
                disableOverlayClose
                steps={currentStepGroup}
                stepIndex={stepIndex}
                callback={handleClicks}
                run={isTutorialOpen}
                tooltipComponent={TutorialTooltip}
            />

            {isTutorialOpen && (
                <div
                    css={css({
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            fontWeight: 600,
                            padding: "8px",
                            zIndex: 1000000,
                            maxWidth: "233px",
                            width: "100%",
                            bottom: "32px",
                            position: "fixed",
                            backgroundColor: white,
                            borderRadius: "12px",
                            boxShadow: "0px 4px 16px rgba(15, 23, 42, 0.16)"
                        })}
                    >
                        <div>Tutorial mode</div>

                        <Button
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Small}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={handleExit}
                        >
                            Exit
                        </Button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default TutorialMode;
