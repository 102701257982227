/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import { TASK_ATTRIBUTES, TaskAttributesProps } from "../types";

import DateTime from "../../../../../DateTime";
import ThemeContext from "../../../../../../context/theme/themeContext";

const TaskAttributes = ({ separateAttributes }: TaskAttributesProps) => {
    const { t } = useTranslation();

    const {
        colorsFacelift: { textPlaceholder, gray700 }
    } = useContext(ThemeContext);

    const getValueData = (key: string, value: string) => {
        const rawName = `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

        const name = t(`Attributes##${rawName}`);

        const realValue = value ?? "—";

        const valueData =
            key === TASK_ATTRIBUTES.Order
                ? t(`Attributes##${value}`)
                : realValue;

        const includesDate = rawName.includes(TASK_ATTRIBUTES.Date);

        const checkIfEmpty = (valueArray: string[]) => {
            return valueArray.length > 0
                ? valueArray.map(item => item).join(", ")
                : "—";
        };

        const checkIfIncludesDate = includesDate ? (
            <DateTime date={valueData} format="L" />
        ) : (
            valueData
        );

        const finalMessage = Array.isArray(value)
            ? checkIfEmpty(value)
            : checkIfIncludesDate;

        return { name, finalMessage };
    };

    return (
        <Fragment>
            <h4
                css={css({
                    textTransform: "uppercase",
                    fontWeight: "700",
                    letterSpacing: "0.15px",
                    lineHeight: "20px",
                    marginBottom: "8px"
                })}
            >
                {t("Table##attributes")}
            </h4>

            <div
                css={css({
                    marginBottom: "24px"
                })}
            >
                {separateAttributes.map(([key, value]: any) => {
                    const { name, finalMessage } = getValueData(key, value);

                    return (
                        <div
                            key={name}
                            css={css({
                                display: "flex",
                                gridTemplateColumns: "auto auto",

                                marginTop: "4px",

                                span: {
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    lineHeight: "20px",
                                    letterSpacing: "0.1px"
                                },

                                "span:first-of-type": {
                                    color: textPlaceholder,
                                    marginRight: "8px"
                                },

                                "span:last-of-type": {
                                    color: gray700
                                }
                            })}
                        >
                            <span>{name}:</span>

                            <span>{finalMessage}</span>
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default TaskAttributes;
