import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const DefaultCheckedIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
            />

            <circle cx="12" cy="12" r="4" fill={white} />
        </SvgIcon>
    );
};

export default DefaultCheckedIcon;
