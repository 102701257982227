import { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/ModeEdit";
import EditOnlineIcon from "@mui/icons-material/WebOutlined";

import AuthContext from "../../../../context/auth/authContext";
import FileDownloadRoundedIcon from "../../../../assets/customIcons/actions/FileDownloadRoundedIcon";
import {
    checkIfDownloadIsAllowed,
    downloadFile,
    FILE_TYPES,
    isGlobalFile,
    isInternalOrPremiumLevel,
    RenderConditionOptions,
    useApi,
    useTct
} from "../../../../shared";

const useFileInlineActions = () => {
    const { isAdminUser, isAdminManagerUser, isCarrierUser, user } =
        useContext(AuthContext);

    const { getData, handleResponse } = useApi();
    const { editConfigurationOnline } = useTct();

    const [rowData, setRowData] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const openDialog = (data: any) => {
        setRowData(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setRowData(null);
        setOpen(false);
    };

    const canEdit = (type: FILE_TYPES) => {
        switch (type) {
            case FILE_TYPES.Psub:
            case FILE_TYPES.AdasFw:
            case FILE_TYPES.DsmFw:
            case FILE_TYPES.MeiGModemFw:
            case FILE_TYPES.QuectelModemFw:
                return isAdminUser;
            case FILE_TYPES.CarrierApxFw:
            case FILE_TYPES.CarrierAdvanceFw:
            case FILE_TYPES.CarrierApxSettings:
            case FILE_TYPES.CarrierAdvanceSettings:
                return isAdminUser || isCarrierUser;
            case FILE_TYPES.Dtb:
                return isAdminManagerUser;
            case FILE_TYPES.FirmwareBundle:
            case FILE_TYPES.BundleMainDtb:
            case FILE_TYPES.BundleMainFw:
                return false;
            default:
                return true;
        }
    };

    const inlineActions = [
        {
            title: "edit in tct web",
            icon: EditOnlineIcon,
            getDisabledText: () =>
                isInternalOrPremiumLevel(user?.company_level)
                    ? undefined
                    : "edit in tct web disabled",
            clickAction: (data: any) => editConfigurationOnline(data?.id),
            renderConditions: ({ data }: RenderConditionOptions) =>
                data.web_editor_support
        },
        {
            title: "download file",
            hasLoader: true,
            icon: FileDownloadRoundedIcon,
            clickAction: (data: any, removeLoading: () => void) =>
                downloadFile({
                    data,
                    getData,
                    handleResponse,
                    removeLoading
                }),
            renderConditions: ({ data }: RenderConditionOptions) =>
                checkIfDownloadIsAllowed(data)
        },
        {
            title: "edit file",
            icon: EditIcon,
            clickAction: (data: any) => openDialog(data),
            renderConditions: ({ data }: RenderConditionOptions) =>
                !isGlobalFile(data.company_id) && canEdit(data.type)
        }
    ];

    return {
        inlineActions,
        rowData,
        isOpen,
        closeDialog
    };
};

export default useFileInlineActions;
