/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { forwardRef, useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";

import {
    getActiveBgColor,
    getCursor,
    getDescriptionColor,
    getDescriptionFontSize,
    getDescriptionLetterSpacing,
    getDescriptionLineHeight,
    getDescriptionMarginTop,
    getHoverBgColor,
    getPadding,
    getSelectedBgColor,
    getSelectedHoverBgColor,
    getWhiteSpace,
    getWordBreak
} from "./functions";
import { ListChoiceProps, Ref } from "./types";

import Checkbox from "../Checkbox";

import ThemeContext from "../../../context/theme/themeContext";
import { noop } from "../../../shared";

const ListChoice = forwardRef<Ref, ListChoiceProps>(
    (
        {
            title,
            description,
            isNotification,
            isNotClickable,
            isNormalWhiteSpace,
            isCheckbox,
            iconLeft,
            iconRight,
            onClick,
            ...props
        },
        ref
    ) => {
        const {
            colorsFacelift: {
                blue100,
                blue200,
                gray100,
                gray200,
                gray700,
                textDark,
                textDarkDisabled
            }
        } = useContext(ThemeContext);

        const [isSelected, setSelected] = useState(Boolean(props.selected));

        useEffect(() => {
            setSelected(Boolean(props.selected));
        }, [props.selected]);

        const handleSelect = () => {
            if (props.selected) {
                setSelected(!isSelected);
            }

            onClick && onClick();
        };

        return (
            <MenuItem
                {...props}
                ref={ref}
                selected={props.selected && isSelected}
                onClick={isNotClickable ? noop : handleSelect}
                disableTouchRipple={isNotClickable}
                sx={{
                    color: textDark,
                    padding: getPadding(
                        Boolean(isCheckbox),
                        iconLeft,
                        iconRight
                    ),
                    cursor: getCursor(Boolean(isNotClickable)),
                    whiteSpace: getWhiteSpace(Boolean(isNormalWhiteSpace)),
                    wordBreak: getWordBreak(Boolean(isNormalWhiteSpace)),
                    transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

                    "&.Mui-selected": {
                        background: getSelectedBgColor(
                            Boolean(isNotification),
                            Boolean(isCheckbox),
                            blue100,
                            blue200
                        ),

                        "&:hover": {
                            background: getSelectedHoverBgColor(
                                Boolean(isNotification),
                                Boolean(isCheckbox),
                                blue100,
                                blue200,
                                gray100
                            )
                        }
                    },

                    "&.Mui-disabled": {
                        color: textDarkDisabled,
                        opacity: "1"
                    },

                    "&:hover": {
                        backgroundColor: getHoverBgColor(
                            Boolean(isNotClickable),
                            gray100
                        )
                    },

                    "&:active": {
                        backgroundColor: getActiveBgColor(
                            Boolean(isNotClickable),
                            gray200
                        )
                    }
                }}
            >
                {iconLeft && (
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            marginRight: "8px"
                        })}
                    >
                        {iconLeft}
                    </div>
                )}

                <div
                    css={css({
                        flex: "1",

                        "& > span": {
                            display: "block"
                        }
                    })}
                >
                    <span
                        css={css({
                            color: "inherit",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px"
                        })}
                    >
                        {title}
                    </span>

                    {description && (
                        <span
                            css={css({
                                color: getDescriptionColor(
                                    Boolean(props.disabled),
                                    textDarkDisabled,
                                    gray700
                                ),
                                fontWeight: "400",
                                fontSize: getDescriptionFontSize(
                                    Boolean(isNotification)
                                ),
                                lineHeight: getDescriptionLineHeight(
                                    Boolean(isNotification)
                                ),
                                letterSpacing: getDescriptionLetterSpacing(
                                    Boolean(isNotification)
                                ),
                                whiteSpace: "normal",
                                marginTop: getDescriptionMarginTop(
                                    Boolean(isNotification)
                                )
                            })}
                        >
                            {description}
                        </span>
                    )}
                </div>

                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",

                        "& > svg, & > label": {
                            marginLeft: "8px"
                        }
                    })}
                >
                    {iconRight}

                    {isCheckbox && (
                        <Checkbox
                            checked={isSelected}
                            disabled={props.disabled}
                        />
                    )}
                </div>
            </MenuItem>
        );
    }
);

export default ListChoice;
