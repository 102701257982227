/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Skeleton } from "@mui/material";

import { generateArrayOfNumbers } from "../functions";

import ThemeContext from "../../context/theme/themeContext";

export const DashboardResourceLoader = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const arrayOfNumbers = generateArrayOfNumbers(3);

    const widthArr = [24, 72, 72];
    const heightArr = [24, 14, 12];

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                rowGap: "6px",
                alignItems: "center"
            })}
        >
            {arrayOfNumbers.map((item, i) => {
                return (
                    <Skeleton
                        key={item}
                        variant="rectangular"
                        width={widthArr[i]}
                        height={heightArr[i]}
                        css={css({
                            background: gray100,
                            borderRadius: "12px"
                        })}
                    />
                );
            })}
        </div>
    );
};
