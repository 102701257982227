/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, SyntheticEvent } from "react";
import MuiDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { CombinedDialogProps } from "./types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import ThemeContext from "../../../context/theme/themeContext";
import { useMediaQueries } from "../../../shared";

const Dialog = ({
    title,
    actions,
    titleIcon,
    children,
    description,
    isTitleSeparator,
    isActionsSeparator,
    extendToMaxWidth,
    close,
    submit,
    ...props
}: CombinedDialogProps) => {
    const {
        colorsFacelift: { gray300, textDark }
    } = useContext(ThemeContext);

    const { toMd } = useMediaQueries();

    const handleSubmit = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        submit?.();
    };

    return (
        <MuiDialog
            {...props}
            onClose={close}
            fullScreen={toMd}
            sx={{
                "& .MuiDialog-paper": {
                    width: extendToMaxWidth ? "100%" : "unset",
                    maxWidth: toMd ? "unset" : "684px",
                    minWidth: toMd ? "unset" : "448px",
                    borderRadius: toMd ? "0" : "12px",
                    overflowY: "hidden"
                }
            }}
        >
            {close && (
                <Button
                    color={BUTTON_COLORS.White}
                    size={BUTTON_SIZES.Small}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<CloseIcon onClick={close} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px"
                    })}
                />
            )}

            <div
                css={css({
                    padding: "24px 24px 16px 24px",
                    borderBottom: isTitleSeparator
                        ? `1px solid ${gray300}`
                        : "none",

                    "& > div": {
                        color: textDark,
                        fontWeight: "600"
                    }
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        marginRight: "32px",
                        fontSize: "24px",
                        lineHeight: "32px",
                        wordBreak: "break-word",

                        "& > svg": {
                            fontSize: "32px",
                            marginRight: "4px"
                        }
                    })}
                >
                    {titleIcon}
                    {title}
                </div>

                {description && (
                    <div
                        css={css({
                            marginTop: "8px",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            wordBreak: "break-word"
                        })}
                    >
                        {description}
                    </div>
                )}
            </div>

            <form
                onSubmit={handleSubmit}
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "hidden"
                })}
            >
                {children && (
                    <div
                        css={css({
                            flex: "1",
                            overflowY: "auto",
                            padding: "16px 24px"
                        })}
                    >
                        {children}
                    </div>
                )}

                <div
                    css={css({
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoColumns: "1fr",
                        padding: "12px 16px 16px",
                        columnGap: "12px",
                        rowGap: "12px",
                        borderTop: isActionsSeparator
                            ? `1px solid ${gray300}`
                            : "none",

                        "@media (max-width:599px)": {
                            gridAutoFlow: "row"
                        }
                    })}
                >
                    {actions}
                </div>
            </form>
        </MuiDialog>
    );
};

export * from "./types/types";

export default Dialog;
