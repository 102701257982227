import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { GenerateTokenButtonsProps, RENDER_NAMES } from "../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../DesignComponents/Button";

const GenerateTokenButtons = ({
    isLoading,
    isDisabled,
    token,
    resetValue,
    changeRenderName,
    close
}: GenerateTokenButtonsProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Button
                fullWidth
                variant={BUTTON_VARIANTS.TextOnly}
                size={BUTTON_SIZES.Normal}
                color={BUTTON_COLORS.Secondary}
                onClick={close}
            >
                {t("Button##close")}
            </Button>

            {token ? (
                <Button
                    fullWidth
                    variant={BUTTON_VARIANTS.TextOnly}
                    size={BUTTON_SIZES.Normal}
                    color={BUTTON_COLORS.Primary}
                    onClick={() => {
                        changeRenderName(RENDER_NAMES.List);
                        resetValue();
                    }}
                >
                    {t("Api tokens##show token list")}
                </Button>
            ) : (
                <Button
                    fullWidth
                    variant={BUTTON_VARIANTS.TextOnly}
                    size={BUTTON_SIZES.Normal}
                    color={BUTTON_COLORS.Primary}
                    type="submit"
                    disabled={isDisabled}
                    isLoading={isLoading}
                >
                    {t("Api tokens##generate")}
                </Button>
            )}
        </Fragment>
    );
};

export default GenerateTokenButtons;
