/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { DeviceStatusChartProps } from "../types";

import { translateStatName } from "../../../../../DeviceStatistics/shared/constants";

import {
    DoughnutChart,
    SingleDeviceStatsListItem
} from "../../../../../DeviceStatistics/shared";

import ThemeContext from "../../../../../../context/theme/themeContext";
import { DeviceStats } from "../../../../../../shared";

export const DeviceStatusChart = ({
    statItem,
    isLoading
}: DeviceStatusChartProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const sortedDescendingStatItem =
        statItem && [...statItem.stats].sort((a, b) => b.count - a.count);

    const statsValues = {
        offline: t("General##Offline"),
        online: t("General##Online"),
        inactive: t("General##Inactive"),
        unknown: t("General##unknown"),
        other: t("General##other")
    };
    return (
        <>
            {t("Welcome dashboard##device status")}

            <div
                css={css({
                    paddingTop: "16px",
                    paddingLeft: "17px",
                    display: "flex"
                })}
            >
                <DoughnutChart
                    statItem={statItem}
                    diameter="124"
                    cutout={49}
                    numberFontStyle={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    }}
                    centerTitle={t("Welcome dashboard##devices")}
                    centerTitleFontStyle={{
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "16px",
                        letterSpacing: "0.4px",
                        color: gray700
                    }}
                    topAlignment="40"
                    centerItemsLineHeight="16"
                />

                <div css={css({ marginLeft: "24px" })}>
                    {sortedDescendingStatItem.map(
                        (item: [string, DeviceStats], index: number) => {
                            return (
                                <SingleDeviceStatsListItem
                                    key={item[0]}
                                    isLoading={isLoading}
                                    count={
                                        statItem.total === 0 ? 0 : item[1].count
                                    }
                                    name={translateStatName(
                                        item[1].name,
                                        statsValues.offline,
                                        statsValues.online,
                                        statsValues.inactive,
                                        statsValues.unknown,
                                        statsValues.other
                                    )}
                                    itemMargins={
                                        index <
                                        sortedDescendingStatItem.length - 1
                                            ? "0 0 8px 0"
                                            : "0"
                                    }
                                />
                            );
                        }
                    )}
                </div>
            </div>
        </>
    );
};
