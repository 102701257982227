import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ApproveIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M6 17.9999H18C18.55 17.9999 19 18.4499 19 18.9999C19 19.5499 18.55 19.9999 18 19.9999H6C5.45 19.9999 5 19.5499 5 18.9999C5 18.4499 5.45 17.9999 6 17.9999ZM11.01 13.8999C10.23 14.6699 8.97 14.6699 8.19 13.8899L6 11.6999C5.45 11.1499 5.46 10.2599 6.03 9.72994C6.57 9.20994 7.43 9.22994 7.95 9.74994L9.6 11.3999L16.03 4.96994C16.57 4.42994 17.44 4.42994 17.98 4.96994L18.02 5.00994C18.56 5.54994 18.56 6.42994 18.01 6.96994L11.01 13.8999Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ApproveIcon;
