/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../DesignComponents/Tooltips";
import Button from "../../DesignComponents/Button";
import {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button/types/enums";

const LeftArrowButton = ({ ...props }) => {
    const { t } = useTranslation();

    return (
        <TooltipGeneral
            placement={TOOLTIP_PLACEMENT.Top}
            title={t("Button##previous month")}
            disableTouchListener
        >
            <span>
                <Button
                    {...props}
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Tiny}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<ChevronLeftRoundedIcon />}
                    css={css({
                        marginRight: "-20px"
                    })}
                />
            </span>
        </TooltipGeneral>
    );
};

export default LeftArrowButton;
