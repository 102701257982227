import { useState } from "react";

import {
    Actions,
    COMPANY_ACTIONS,
    DEVICE_ACTIONS,
    FILE_ACTIONS,
    GROUP_ACTIONS,
    LICENSE_ACTIONS,
    PRODUCT_CODE_ACTIONS,
    TASK_GROUP_ACTIONS,
    USER_ACTIONS
} from "../../types";

export const useDialog = (closeMenu?: () => void) => {
    const [isOpenDialog, setOpenDialog] = useState({
        [COMPANY_ACTIONS.CreateCompany]: false,
        [COMPANY_ACTIONS.TransferCompanies]: false,
        [COMPANY_ACTIONS.MergeCompanies]: false,
        [COMPANY_ACTIONS.DeleteCompanies]: false,
        [COMPANY_ACTIONS.CompanyLevelManualChange]: false,
        [COMPANY_ACTIONS.CustomResourceLimits]: false,
        [DEVICE_ACTIONS.CancelTasks]: false,
        [DEVICE_ACTIONS.CreateTask]: false,
        [DEVICE_ACTIONS.TaskDetails]: false,
        [DEVICE_ACTIONS.AddDevice]: false,
        [DEVICE_ACTIONS.MoveDevices]: false,
        [DEVICE_ACTIONS.ImportDevices]: false,
        [DEVICE_ACTIONS.ExportDeviceData]: false,
        [DEVICE_ACTIONS.AssignCanPackage]: false,
        [DEVICE_ACTIONS.TransferDevice]: false,
        [DEVICE_ACTIONS.NoTableActions]: false,
        [FILE_ACTIONS.UploadFiles]: false,
        [FILE_ACTIONS.DeleteFiles]: false,
        [GROUP_ACTIONS.CreateGroup]: false,
        [GROUP_ACTIONS.DeleteDeviceGroups]: false,
        [LICENSE_ACTIONS.CreateDesktopLicense]: false,
        [LICENSE_ACTIONS.CreateOfflineLicense]: false,
        [LICENSE_ACTIONS.DeleteDesktopLicense]: false,
        [LICENSE_ACTIONS.DeleteOfflineLicense]: false,
        [LICENSE_ACTIONS.EditDesktopLicense]: false,
        [PRODUCT_CODE_ACTIONS.AddProductCode]: false,
        [PRODUCT_CODE_ACTIONS.DeleteProductCode]: false,
        [TASK_GROUP_ACTIONS.RetryFailedTasks]: false,
        [USER_ACTIONS.InviteUser]: false,
        [USER_ACTIONS.DeleteUsers]: false
    });

    const openDialog = (action: Actions) => {
        closeMenu && closeMenu();
        setOpenDialog({ ...isOpenDialog, [action]: true });
    };

    const closeDialog = (action: Actions) =>
        setOpenDialog({ ...isOpenDialog, [action]: false });

    return { isOpenDialog, openDialog, closeDialog };
};
