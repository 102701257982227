/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CircularProgress from "@mui/material/CircularProgress";

import SingleNotification from "./SingleNotification";
import ShowAllButton from "./ShowAllButton";
import NoNotifications from "./NoNotifications";
import { NotificationListProps } from "./types";

import NotificationsContext from "../../../notificationsContext";

import ThemeContext from "../../../../theme/themeContext";

import Separator from "../../../../../components/DesignComponents/Separator";
import { useMediaQueries } from "../../../../../shared";

const NotificationList = ({ isLoading }: NotificationListProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { notifications, pushPreviousNotification } =
        useContext(NotificationsContext);

    const { fromMd } = useMediaQueries();

    const [isShowingAll, setIsShowingAll] = useState(false);

    const renderContent = () => {
        if (isLoading) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "104px"
                    })}
                >
                    <CircularProgress />
                </div>
            );
        }

        const hasNotifications = notifications.length > 0;
        const hasShowAllBtn = notifications.length > 10 && !isShowingAll;

        return hasNotifications ? (
            <ul
                css={css({
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: fromMd ? "536px" : "unset",

                    ".slide-exit-active": {
                        filter: "blur(2px)",
                        transform: "translateX(100%)",
                        transition: "all 300ms cubic-bezier(0, 0, 0.2, 1)"
                    }
                })}
            >
                <TransitionGroup component={null}>
                    {notifications.map((notification, i) => {
                        const shouldShow =
                            notification && (isShowingAll || i < 10);

                        return (
                            shouldShow && (
                                <CSSTransition
                                    key={notification.id}
                                    timeout={300}
                                    classNames="slide"
                                    onExited={() => pushPreviousNotification()}
                                >
                                    <>
                                        <SingleNotification
                                            data={notification}
                                        />

                                        <Separator
                                            isListSeparator
                                            css={css({
                                                background: gray200
                                            })}
                                        />
                                    </>
                                </CSSTransition>
                            )
                        );
                    })}
                </TransitionGroup>

                {hasShowAllBtn && <ShowAllButton showAll={setIsShowingAll} />}
            </ul>
        ) : (
            <NoNotifications />
        );
    };

    return renderContent();
};

export default NotificationList;
