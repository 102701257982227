/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import EnableTwoFactorAuthentication from "./EnableTwoFactorAuthentication";
import DisableTwoFactorAuthentication from "./DisableTwoFactorAuthentication";

import ContentBox from "../ContentBox";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../DesignComponents/TextLink";

import ThemeContext from "../../../../../../context/theme/themeContext";
import AuthContext from "../../../../../../context/auth/authContext";
import TwoFactorAuthenticationIcon from "../../../../../../assets/customIcons/security/TwoFactorAuthenticationIcon";
import { getEnvironment } from "../../../../../../shared";

const TwoFactorAuthentication = () => {
    const {
        colorsFacelift: { green700, red800, textDark }
    } = useContext(ThemeContext);

    const { isSupportUser, user } = useContext(AuthContext);

    const { t } = useTranslation();
    const { isDevelopmentEnv } = getEnvironment();

    const [isEnableDialogOpen, setEnableDialogOpen] = useState(false);
    const [isDisableDialogOpen, setDisableDialogOpen] = useState(false);

    const openDialog = () =>
        isEnabled ? setDisableDialogOpen(true) : setEnableDialogOpen(true);

    const closeDialog = () => {
        setEnableDialogOpen(false);
        setDisableDialogOpen(false);
    };

    const isEnabled = user?.two_factor_auth_enabled;

    return (
        <>
            <ContentBox
                title={t("Password##two-factor authentication")}
                description={t(
                    "Password##two-factor authentication description"
                )}
                css={css({
                    margin: "32px 0 8px 0"
                })}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            alignItems: "center"
                        })}
                    >
                        <TwoFactorAuthenticationIcon
                            css={css({
                                color: textDark,
                                marginRight: "4px"
                            })}
                        />

                        <span
                            css={css({
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",

                                "& > span": {
                                    color: isEnabled ? green700 : red800,
                                    fontWeight: "700"
                                }
                            })}
                        >
                            {t("Password##two-factor authentication status")}{" "}
                            <span>
                                {isEnabled
                                    ? t("Password##enabled")
                                    : t("Password##disabled")}
                            </span>
                        </span>
                    </div>

                    {(isDevelopmentEnv ||
                        !isSupportUser ||
                        (isSupportUser && !isEnabled)) && (
                        <TextLink
                            size={TEXT_LINK_SIZES.Normal}
                            onClick={openDialog}
                        >
                            {isEnabled
                                ? t("Button##disable")
                                : t("Button##enable")}
                        </TextLink>
                    )}
                </div>
            </ContentBox>

            <EnableTwoFactorAuthentication
                isOpen={isEnableDialogOpen}
                close={closeDialog}
            />

            <DisableTwoFactorAuthentication
                isOpen={isDisableDialogOpen}
                close={closeDialog}
            />
        </>
    );
};

export default TwoFactorAuthentication;
