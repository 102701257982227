import { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ApproveDiscardProps } from "../types";
import InlineActionIconButton from "../../DesignComponents/Button/InlineActionIconButton";
import ParamsContext from "../../../context/params/paramsContext";
import DiscardIcon from "../../../assets/customIcons/actions/DiscardIcon";
import ApproveIcon from "../../../assets/customIcons/actions/ApproveIcon";
import { ENDPOINTS, TABLE_NAMES, useApi } from "../../../shared";

const ApproveDiscard = ({ id }: ApproveDiscardProps) => {
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { postData, handleResponse, isCanceled } = useApi();

    const [isLoading, setLoading] = useState({
        approve: false,
        discard: false
    });

    const handleApprove = async () => {
        try {
            setLoading({ ...isLoading, approve: true });

            const response = await postData(
                `${ENDPOINTS.Batches}/${id}/approve`
            );

            handleResponse(response);
            setReloadItems(TABLE_NAMES.TaskGroups);
        } catch (error) {
            if (!isCanceled(error)) {
                handleResponse(error);
            }
        }

        setLoading({ ...isLoading, approve: false });
    };

    const handleDiscard = async () => {
        try {
            setLoading({ ...isLoading, discard: true });

            const response = await postData(
                `${ENDPOINTS.Batches}/${id}/discard`
            );

            handleResponse(response);
            setReloadItems(TABLE_NAMES.TaskGroups);
        } catch (error) {
            if (!isCanceled(error)) {
                handleResponse(error);
            }
        }

        setLoading({ ...isLoading, discard: false });
    };

    return (
        <Fragment>
            <InlineActionIconButton
                title={t("Button##discard")}
                icon={<DiscardIcon />}
                onButtonClick={handleDiscard}
                isLoading={isLoading.discard}
            />

            <InlineActionIconButton
                title={t("Button##approve")}
                icon={<ApproveIcon />}
                onButtonClick={handleApprove}
                isLoading={isLoading.approve}
            />
        </Fragment>
    );
};

export default ApproveDiscard;
