/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import TableItem from "../TableItem";
import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";

const TableMobile = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const { rows, isTableLoading, idType } = useContext(TableContext);

    return (
        <div
            css={css({
                background: gray100,
                opacity: isTableLoading ? "0.4" : "1",
                transition: "opacity 300ms ease-out",

                "& > div + div": {
                    marginTop: "8px"
                }
            })}
        >
            {rows.map((row: any) => (
                <TableItem key={row[idType]} data={row} />
            ))}
        </div>
    );
};

export default TableMobile;
