import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";

import "./i18next/config";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

declare global {
    interface Window {
        Echo: any;
        Pusher: any;
    }
}

WebFont.load({
    google: {
        families: ["Open Sans:300,400,600,700,800", "sans-serif"]
    }
});

const root = createRoot(document.getElementById("root") as Element);

root.render(<App />);

serviceWorker.unregister();
