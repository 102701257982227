import { useTranslation } from "react-i18next";

import { ReceiveDeviceProps } from "../../../types";

import DialogActions from "../../../../components/DialogActions";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";

const ReceiveDeviceActions = ({
    close,
    loading,
    token
}: ReceiveDeviceProps) => {
    const { t } = useTranslation();

    return !token ? (
        <DialogActions
            onClose={close}
            isLoading={loading}
            isReadyToConfirm
            confirmationButtonText={t("Button##confirm")}
            cancelButtonTestId="cancel-receive-devices"
            confirmationButtonTestId="confirm-receive-devices"
        />
    ) : (
        <Button
            fullWidth
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Normal}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={close}
        >
            {t("Button##close")}
        </Button>
    );
};
export default ReceiveDeviceActions;
