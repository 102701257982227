import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { CopyButtonProps } from "./types";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../Button";

import { TOOLTIP_PLACEMENT, TooltipGeneral } from "../Tooltips";
import CopyIcon from "../../../assets/customIcons/actions/CopyIcon";

const CopyButton = ({ size, copiedValue }: CopyButtonProps) => {
    const { t } = useTranslation();

    const [copied, setCopied] = useState(false);

    return (
        <CopyToClipboard text={copiedValue} onCopy={() => setCopied(true)}>
            <TooltipGeneral
                placement={TOOLTIP_PLACEMENT.Top}
                title={
                    copied
                        ? t("Button##copied")
                        : t("Button##copy to clipboard")
                }
                TransitionProps={{
                    onExited: () => setCopied(false)
                }}
                disableTouchListener
            >
                <Button
                    color={BUTTON_COLORS.Secondary}
                    size={size}
                    variant={BUTTON_VARIANTS.IconOnly}
                    icon={<CopyIcon isLarge={size === BUTTON_SIZES.Tiny} />}
                />
            </TooltipGeneral>
        </CopyToClipboard>
    );
};

export default CopyButton;
