/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useRef } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";

import CompanyLevelDesktop from "./CompanyLevelDesktop";
import CompanyLevelMobile from "./CompanyLevelMobile";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../DesignComponents/Button";

import { CustomCompanyLevelDialogProps } from "./shared/types/interfaces";

import TextLink, { TEXT_LINK_SIZES } from "../DesignComponents/TextLink";
import ThemeContext from "../../context/theme/themeContext";
import RoutesContext from "../../context/routes/routesContext";
import { useMediaQueries } from "../../shared";

type CombinedCustomDialogProps = CustomCompanyLevelDialogProps & MuiDialogProps;

const CustomCompanyLevelDialog = ({
    title,
    actions,
    children,
    description,
    isActionsSeparator,
    currentLevel,
    isVirtualCompany,
    close,
    ...props
}: CombinedCustomDialogProps) => {
    const {
        colorsFacelift: { gray300, textDark }
    } = useContext(ThemeContext);

    const {
        isCompanyLevelDialogOpen,
        isCompanyLevelDialogScrollToFaq,
        toggleCompanyLevelDialogOpen
    } = useContext(RoutesContext);

    const { toMd } = useMediaQueries();

    const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        const behaviorValue = isCompanyLevelDialogScrollToFaq
            ? "instant"
            : "smooth";

        scrollableContainerRef.current?.scrollIntoView({
            behavior: behaviorValue as ScrollBehavior
        });
    };

    return (
        <MuiDialog
            {...props}
            onClose={close}
            fullScreen={toMd}
            TransitionProps={{
                onEnter: () =>
                    isCompanyLevelDialogOpen &&
                    isCompanyLevelDialogScrollToFaq &&
                    scrollToBottom(),
                onEntered: () =>
                    isCompanyLevelDialogOpen &&
                    toggleCompanyLevelDialogOpen(true, false)
            }}
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                    maxWidth: toMd ? "unset" : "1156px",
                    minWidth: toMd ? "unset" : "448px",
                    borderRadius: toMd ? "0" : "12px"
                }
            }}
        >
            <Button
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconOnly}
                icon={<CloseIcon onClick={close} />}
                css={css({
                    position: "absolute",
                    top: "4px",
                    right: "4px"
                })}
            />

            <div
                css={css({
                    padding: "24px 16px 12px 16px",
                    borderBottom: `1px solid ${gray300}`,
                    justifyContent: "center",
                    color: textDark,
                    fontWeight: "600",
                    "& > span": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }
                })}
            >
                <span
                    css={css({
                        fontSize: "24px",
                        lineHeight: "32px"
                    })}
                >
                    {title}
                </span>

                {description ?? (
                    <div
                        css={css({
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            whiteSpace: "pre-line",
                            textAlign: "center",
                            marginTop: "8px"
                        })}
                    >
                        All companies in FOTA WEB now have a level attributed.
                        While continuing to provide remote update features to
                        all of our users, we reserve Standard and Premium plans
                        for most active Teltonika Telematics enablers. Contact
                        your Teltonika Telematics provider to know more or check
                        our{" "}
                        <TextLink
                            onClick={scrollToBottom}
                            size={TEXT_LINK_SIZES.Normal}
                        >
                            FAQ.
                        </TextLink>
                    </div>
                )}
            </div>

            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "hidden"
                })}
            >
                {currentLevel && (
                    <div
                        css={css({
                            flex: "1",
                            overflowY: "auto"
                        })}
                    >
                        {!toMd ? (
                            <CompanyLevelDesktop
                                faqRef={scrollableContainerRef}
                                currentLevel={currentLevel}
                                isVirtualCompany={isVirtualCompany}
                            />
                        ) : (
                            <CompanyLevelMobile
                                faqRef={scrollableContainerRef}
                                currentLevel={currentLevel}
                                isVirtualCompany={isVirtualCompany}
                            />
                        )}
                    </div>
                )}

                <div
                    css={css({
                        padding: "12px 16px 16px",
                        borderTop: isActionsSeparator
                            ? `1px solid ${gray300}`
                            : "none"
                    })}
                >
                    {actions}
                </div>
            </div>
        </MuiDialog>
    );
};

export default CustomCompanyLevelDialog;
