/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment } from "react";

import TableMobileHead from "./TableHead";

import TableItem from "../TableItem";
import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";

const TableMobile = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const { rows, selectedRows, isTableLoading, idType } =
        useContext(TableContext);

    const [isActiveHead, setActiveHead] = useState(selectedRows.length > 0);

    const toggleActiveHead = (value: boolean) => setActiveHead(value);

    return (
        <Fragment>
            <TableMobileHead
                isActiveHead={isActiveHead}
                toggleActiveHead={toggleActiveHead}
            />

            <div
                css={css({
                    background: gray100,
                    opacity: isTableLoading ? "0.4" : "1",
                    transition: "opacity 300ms ease-out",

                    "& > div + div": {
                        marginTop: "8px"
                    }
                })}
            >
                {rows.map((row: any, i: number) => (
                    <TableItem
                        key={row[idType]}
                        data={row}
                        isMobileHeadActive={isActiveHead}
                        isFirstItem={i === 0}
                    />
                ))}
            </div>
        </Fragment>
    );
};

export default TableMobile;
