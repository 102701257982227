import { useContext } from "react";
import LanguageContext from "../../context/language/languageContext";

export const useFormattedNumber = (props?: number) => {
    const { language } = useContext(LanguageContext);

    const renderFormattedNumber = (number: number) =>
        number
            ? number.toLocaleString((language as unknown) as string)
            : String(number);

    const formatedNumber = 
        props ? props.toLocaleString(language) : String(props);

    return { renderFormattedNumber, formatedNumber };
};
