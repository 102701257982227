/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { SendOtpCode } from "./content";
import { DISABLE_AUTHENTICATION_STEPS } from "./types";

import Dialog from "../../../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";

import AuthContext from "../../../../../../../context/auth/authContext";
import {
    DialogHandler,
    ENDPOINTS,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const DisableTwoFactorAuthentication = ({ isOpen, close }: DialogHandler) => {
    const { toggle2FaEnable } = useContext(AuthContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [step, setStep] = useState(DISABLE_AUTHENTICATION_STEPS.Confirmation);
    const [isLoading, setLoading] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const changeStep = (newStep: DISABLE_AUTHENTICATION_STEPS) =>
        setStep(newStep);

    const changeOtpCode = (newValue: string) => {
        setError(null);
        setOtpCode(newValue);
    };

    const sendOtpCodeSubmit = async () => {
        try {
            setLoading(true);

            const response = await postData(`${ENDPOINTS.Users}/tfa/disable`, {
                otp_code: otpCode
            });

            toggle2FaEnable(false);
            close();
            handleResponse(response);
        } catch (error) {
            const err = error as AxiosError;

            if (err.response?.status === 400) {
                setError(t("Password##incorrect code"));
            } else {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    const steps = {
        [DISABLE_AUTHENTICATION_STEPS.Confirmation]: {
            hasActionSeparator: false,
            title: t("Password##are you sure"),
            description: t("Password##disable description confirmation"),
            submitBtn: t("Button##yes"),
            isSubmitBtnDisabled: false,
            content: null,
            onSubmit: () => changeStep(DISABLE_AUTHENTICATION_STEPS.SendOtpCode)
        },
        [DISABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
            hasActionSeparator: true,
            title: t("Password##two-factor authentication"),
            description: t("Password##disable description send code"),
            submitBtn: t("Button##continue"),
            isSubmitBtnDisabled: otpCode.length < 6,
            content: (
                <SendOtpCode
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSubmit: sendOtpCodeSubmit
        }
    };

    const hasActionSeparator = steps[step].hasActionSeparator;
    const title = steps[step].title;
    const description = steps[step].description;
    const submitBtnName = steps[step].submitBtn;
    const isDisabled = steps[step].isSubmitBtnDisabled;
    const content = steps[step].content;
    const onSubmit = steps[step].onSubmit;

    return (
        <Dialog
            open={isOpen}
            close={close}
            isTitleSeparator
            isActionsSeparator={hasActionSeparator}
            extendToMaxWidth
            title={title}
            description={description}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => {
                    setStep(DISABLE_AUTHENTICATION_STEPS.Confirmation);
                    setOtpCode("");
                    setError(null);
                }
            }}
            actions={
                <>
                    <Button
                        fullWidth
                        color={BUTTON_COLORS.Secondary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={close}
                    >
                        {t("Button##cancel")}
                    </Button>

                    {submitBtnName && (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            disabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {submitBtnName}
                        </Button>
                    )}
                </>
            }
            css={css(fromMd && maxDialogWidth)}
        >
            {content}
        </Dialog>
    );
};

export default DisableTwoFactorAuthentication;
