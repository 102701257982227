export const filterBlockStyle = {
    "@media(max-width: 839px)": {
        flex: "1"
    },

    "@media(max-width: 599px)": {
        flex: "unset",
        marginLeft: "8px"
    }
};

export const deviceAndGroupfiltersBlockStyle = {
    display: "flex",
    alignItems: "center",
    ...filterBlockStyle
};

export const filterDropdownCustomWidth = {
    width: "246px",

    "@media(max-width: 839px)": {
        width: "unset",
        flex: "1"
    }
};

export const filtersHeight = "36px";

export const filterDropdownCustomMargin = {
    marginRight: "16px",

    "@media(max-width: 839px)": {
        marginRight: "0"
    }
};

export const maxDialogWidth = {
    "& .MuiDialog-paper": {
        maxWidth: "448px"
    }
};

export const maxDialogHeight = {
    "& .MuiDialog-paper": {
        maxHeight: "730px"
    }
};

export const fileFormatBlockStyle = {
    display: "flex",
    flexDirection: "row",
    label: {
        marginLeft: "-8px",
        padding: "0 16px 16px 6px",
        "& > span:first-of-type": {
            marginRight: "4px"
        }
    }
};
