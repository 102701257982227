/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import SecondSelectContent from "./SecondSelectContent";
import { CreateTaskContentProps, SchedulePayload } from "./types";
import { SecondSelectData } from "./SecondSelectContent/types/interfaces";
import ScheduleTask from "./ScheduleTask";

import useTaskTypes from "../hooks/useTaskTypes";
import { SCHEDULE_TYPE, Task } from "../types";

import Autocomplete from "../../../../../DesignComponents/Autocomplete";
import {
    TOOLTIP_PLACEMENT,
    TooltipLarge
} from "../../../../../DesignComponents/Tooltips";
import Checkbox from "../../../../../DesignComponents/Checkbox";
import {
    DROPDOWN_SIZES,
    FEATURE_SETTINGS,
    useCompanyLevelSettings
} from "../../../../../../shared";

const CreateTaskContent = ({ setPayload }: CreateTaskContentProps) => {
    const { t } = useTranslation();
    const { isFeatureSettingsRestricted } = useCompanyLevelSettings();
    const { visibleTasks } = useTaskTypes();

    const [selectedTask, setSelectedTask] = useState<Task | null>(null);

    const [
        secondSelectData,
        setSecondSelectData
    ] = useState<SecondSelectData | null>(null);

    const [isScheduled, setScheduled] = useState(false);
    const [isCancelTasksChecked, setIsCancelTasksChecked] = useState(true);
    const [scheduledTime, setScheduledTime] = useState<SchedulePayload | null>(
        null
    );

    useEffect(() => {
        if (
            selectedTask &&
            (selectedTask.secondSelect ? secondSelectData !== null : true)
        ) {
            const data = {
                type: selectedTask.type,
                secondSelectData,
                schedule:
                    scheduledTime !== null
                        ? {
                              type: SCHEDULE_TYPE.Type,
                              attributes: {
                                  from_minutes: scheduledTime.from,
                                  duration_minutes: scheduledTime.duration
                              }
                          }
                        : null,
                expire_existing_tasks: isCancelTasksChecked
            };

            setPayload(data);
        } else {
            setPayload(null);
        }
        // eslint-disable-next-line
    }, [selectedTask, secondSelectData, scheduledTime, isCancelTasksChecked]);

    const handleChangeValue = (taskType: Task) => {
        setSelectedTask(taskType);
        setSecondSelectData(null);
    };

    const handleSecondSelectData = (data: SecondSelectData | null) =>
        setSecondSelectData(data);

    const toggleTaskCancellation = () =>
        setIsCancelTasksChecked(!isCancelTasksChecked);

    const toggleScheduled = () => {
        isScheduled && setScheduledTime(null);
        setScheduled(!isScheduled);
    };

    const handleScheduledTime = (data: SchedulePayload) =>
        setScheduledTime(data);

    const isRestricted = isFeatureSettingsRestricted(
        FEATURE_SETTINGS.TaskScheduleSupport
    );

    return (
        <Fragment>
            <Autocomplete
                data-testid="task-type-dropdown"
                fullWidth
                isRequired
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("General##task type")}
                textFieldParams={{
                    placeholder: t("Dialog##select")
                }}
                options={visibleTasks}
                groupBy={option => t(`Dialog##${option.group}`)}
                getOptionLabel={option => t(`General##${option.type}`)}
                isOptionEqualToValue={(option, value) =>
                    option.type === value.type
                }
                onChange={(_, taskType) => handleChangeValue(taskType)}
                customStyle={{
                    marginTop: "16px"
                }}
            />

            {selectedTask?.secondSelect && (
                <SecondSelectContent
                    secondSelectName={selectedTask.secondSelect}
                    type={selectedTask.type}
                    changePayload={handleSecondSelectData}
                />
            )}

            <div
                css={css({
                    marginTop: "16px"
                })}
            >
                <Checkbox
                    data-testid="cancel-pending-tasks-checkbox"
                    label={t("Dialog##cancel pending tasks")}
                    onChange={toggleTaskCancellation}
                    defaultChecked
                />
            </div>

            <div
                css={css({
                    display: "flex",
                    margin: "16px 0"
                })}
            >
                <TooltipLarge
                    placement={TOOLTIP_PLACEMENT.TopStart}
                    title={
                        isRestricted ? t(`Restrictions##general message`) : ""
                    }
                    customMargin="0 0 6px 0"
                >
                    <span css={css({ display: "inline-flex" })}>
                        <Checkbox
                            data-testid="schedule-time-checkbox"
                            disabled={isRestricted}
                            label={t("Dialog##schedule time")}
                            onChange={toggleScheduled}
                        />
                    </span>
                </TooltipLarge>
            </div>

            {isScheduled && <ScheduleTask change={handleScheduledTime} />}
        </Fragment>
    );
};

export default CreateTaskContent;
