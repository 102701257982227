import { useContext } from "react";
import { Navigate, Route as RouterRoute } from "react-router-dom";

import PrivateRoute from "../PrivateRoute";
import { Route } from "../types";

import Login from "../../Auth/Login";
import RequestReset from "../../Auth/PasswordReset/RequestReset";
import Reset from "../../Auth/Reset/Reset";
import Signup from "../../Auth/SignUp/Signup";
import Maintenance from "../../Maintenance";
import Dashboard from "../../Dashboard";
import Devices from "../../Devices";
import TaskGroups from "../../TaskGroups";
import DeviceGroups from "../../DeviceGroups";
import Files from "../../Files";
import Companies from "../../Companies";
import Users from "../../Users";
import ProductCodes from "../../ProductCodes";
import Licenses from "../../Licenses";

import AuthContext from "../../../context/auth/authContext";
import DashboardIcon from "../../../assets/customIcons/navigation/DashboardIcon";
import DevicesIcon from "../../../assets/customIcons/navigation/DevicesIcon";
import TasksIcon from "../../../assets/customIcons/navigation/TasksIcon";
import GroupsIcon from "../../../assets/customIcons/navigation/GroupsIcon";
import FilesIcon from "../../../assets/customIcons/navigation/FilesIcon";
import CompaniesIcon from "../../../assets/customIcons/navigation/CompaniesIcon";
import UsersIcon from "../../../assets/customIcons/navigation/UsersIcon";
import ProductCodesIcon from "../../../assets/customIcons/navigation/ProductCodesIcon";
import LicensesIcon from "../../../assets/customIcons/navigation/LicensesIcon";
import {
    COMPANIES,
    DASHBOARD,
    DESKTOP_LICENSES,
    DEVICES,
    FILES,
    GROUPS,
    PRODUCT_CODES,
    ROUTES,
    TASKS,
    USERS
} from "../../../shared";

const useRoutes = () => {
    const { isAdminUser, isSupportUser } = useContext(AuthContext);

    const routes: Route[] = [
        { path: ROUTES.Login, component: Login },
        { path: ROUTES.RequestReset, component: RequestReset },
        { path: ROUTES.Reset, component: Reset },
        { path: ROUTES.Signup, component: Signup },
        { path: ROUTES.Maintenance, component: Maintenance },
        {
            path: ROUTES.Dashboard,
            component: Dashboard,
            isPrivate: true,
            navigation: {
                name: DASHBOARD,
                icon: <DashboardIcon />
            }
        },
        {
            path: ROUTES.Devices,
            component: Devices,
            isPrivate: true,
            navigation: {
                name: DEVICES,
                icon: <DevicesIcon />
            }
        },
        {
            path: ROUTES.TaskGroups,
            component: TaskGroups,
            isPrivate: true,
            navigation: {
                name: TASKS,
                icon: <TasksIcon />
            }
        },
        {
            path: ROUTES.DeviceGroups,
            component: DeviceGroups,
            isPrivate: true,
            navigation: {
                name: GROUPS,
                icon: <GroupsIcon />
            }
        },
        {
            path: ROUTES.Files,
            component: Files,
            isPrivate: true,
            navigation: {
                name: FILES,
                icon: <FilesIcon />
            }
        },
        {
            path: ROUTES.Companies,
            component: Companies,
            isPrivate: true,
            navigation: {
                name: COMPANIES,
                icon: <CompaniesIcon />
            }
        },
        {
            path: ROUTES.Users,
            component: Users,
            isPrivate: true,
            navigation: {
                name: USERS,
                icon: <UsersIcon />
            }
        },
        { path: ROUTES.UserInvite, component: Users, isPrivate: true },
        {
            path: ROUTES.ProductCodes,
            component: ProductCodes,
            isPrivate: true,
            isRestricted: !isSupportUser,
            navigation: {
                name: PRODUCT_CODES,
                icon: <ProductCodesIcon />,
                hasSeparator: true
            }
        },
        {
            path: ROUTES.DesktopLicenses,
            component: Licenses,
            isPrivate: true,
            isRestricted: !isAdminUser,
            navigation: {
                name: DESKTOP_LICENSES,
                icon: <LicensesIcon />
            }
        }
    ];

    const renderRoutes = () => (
        <>
            {routes.map(
                ({ path, component: Component, isPrivate, isRestricted }) => (
                    <RouterRoute
                        key={path}
                        path={path}
                        element={
                            isPrivate ? (
                                <PrivateRoute
                                    component={<Component />}
                                    isRestricted={isRestricted}
                                />
                            ) : (
                                <Component />
                            )
                        }
                    />
                )
            )}

            <RouterRoute
                path={ROUTES.NotFound}
                element={<Navigate to={{ pathname: ROUTES.Login }} replace />}
            />
        </>
    );

    return { routes, renderRoutes };
};

export { useRoutes };
