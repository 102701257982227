import { useContext, useEffect, useState } from "react";

import { STEPS } from "../types";

import ParamsContext from "../../../../../../context/params/paramsContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    FILTER_NAMES,
    Query,
    useApi,
    useIsMounted,
    useTct
} from "../../../../../../shared";

export const useTctWebDialog = (isOpen: boolean, close: () => void) => {
    const { queryParams } = useContext(ParamsContext);

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();

    const { createConfigurationOnline, editConfigurationOnline } =
        useTct(close);

    const isMounted = useIsMounted();

    const [isLoading, setLoading] = useState(false);
    const [isEditDisabled, setEditDisabled] = useState(true);
    const [step, setStep] = useState(STEPS.Initial);

    const isInitialStep = step === STEPS.Initial;

    const [selectedConfiguration, setSelectedConfiguration] =
        useState<Query | null>(null);

    useEffect(() => {
        const getConfigurationCount = async () => {
            try {
                setLoading(true);

                const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];

                const params = `?${FILTER_NAMES.RootCompany}=${rootCompanyId}&${FILTER_NAMES.WebEditorSupport}=true`;

                const {
                    data: { total }
                } = await getData(`${ENDPOINTS.Files}${params}`);

                if (isMounted) {
                    const isEmpty = total === 0;

                    setEditDisabled(isEmpty);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                    setEditDisabled(true);
                }
            }

            setLoading(false);
        };

        isOpen && getConfigurationCount();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isOpen, queryParams]);

    const handleStep = (value: STEPS) => setStep(value);

    const handleFileChange = (configurationValue: Query | null) =>
        setSelectedConfiguration(configurationValue);

    const handleSubmitCreate = () => createConfigurationOnline();

    const handleSubmitEdit = () => {
        selectedConfiguration &&
            editConfigurationOnline(selectedConfiguration?.id);
    };

    return {
        isLoading,
        isEditDisabled,
        isInitialStep,
        selectedConfiguration,
        setStep,
        setSelectedConfiguration,
        handleStep,
        handleFileChange,
        handleSubmitCreate,
        handleSubmitEdit
    };
};
