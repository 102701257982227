import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { CookieItem, SingleCookieItem } from "..";

import { TOGGLE_SWITCH_LABEL_PLACEMENT } from "../../../DesignComponents/ToggleSwitch";

interface ICookiesmodalContentProps {
    setIsAnalyticsCookies: Dispatch<SetStateAction<boolean>>;
    isAnalyticsCookies: boolean | undefined;
}

export const CookiesModalContent = ({
    setIsAnalyticsCookies,
    isAnalyticsCookies
}: ICookiesmodalContentProps) => {
    const { t } = useTranslation();

    const handleAnalyticsToggleChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setIsAnalyticsCookies(event.target.checked);
    };

    const COOKIE_ITEMS_LIST: CookieItem[] = [
        {
            haveItemSeparator: true,
            title: t("Cookies##essential cookies"),
            description: t("Cookies##essential cookies description"),
            label: t("Cookies##always active"),
            labelPlacement: TOGGLE_SWITCH_LABEL_PLACEMENT.Start,
            isToggleDisabled: true,
            isToggleChecked: true,
            onToggleChange: null,
            customStyle: undefined
        },
        {
            haveItemSeparator: false,
            title: t("Cookies##analytics cookies"),
            description: t("Cookies##analytics cookies description"),
            label: undefined,
            labelPlacement: undefined,
            isToggleDisabled: false,
            isToggleChecked: isAnalyticsCookies,
            onToggleChange: handleAnalyticsToggleChange,
            customStyle: { paddingTop: "24px" }
        }
    ];

    return (
        <>
            {COOKIE_ITEMS_LIST.map(
                ({
                    title,
                    haveItemSeparator,
                    description,
                    label,
                    labelPlacement,
                    onToggleChange,
                    isToggleDisabled,
                    isToggleChecked,
                    customStyle
                }: CookieItem) => (
                    <SingleCookieItem
                        key={title}
                        isItemSeparator={haveItemSeparator}
                        cookieDescription={description}
                        cookieTitle={title}
                        switcherLabel={label}
                        switcherLabelPlacement={labelPlacement}
                        onToggleChange={onToggleChange}
                        toggleDisable={isToggleDisabled}
                        toggleChecked={isToggleChecked}
                        customStyle={customStyle}
                    />
                )
            )}
        </>
    );
};
