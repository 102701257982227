/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

const DashboardBar = () => {
    const { t } = useTranslation();

    return (
        <div>
            <h1
                css={css({
                    "@media(max-width: 599px)": {
                        marginBottom: "8px"
                    }
                })}
            >
                <span>{t(`General##dashboard`)}</span>
            </h1>
        </div>
    );
};

export default DashboardBar;
