/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { ClearInputIconProps } from "./types";

import ThemeContext from "../../../context/theme/themeContext";
import DeleteCircleIcon from "../../../assets/customIcons/actions/DeleteCircleIcon";

export const ClearInputIcon = ({ value, clearValue }: ClearInputIconProps) => {
    const {
        colorsFacelift: { textPlaceholder, gray500 }
    } = useContext(ThemeContext);

    return value.length > 0 ? (
        <DeleteCircleIcon
            onMouseDown={clearValue}
            css={css({
                color: textPlaceholder,
                cursor: "pointer",
                transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "&:hover": {
                    color: gray500
                }
            })}
        />
    ) : null;
};
