/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { SendOtpCodeProps } from "../types";

import { OtpInputField } from "../../../../../../../Auth/shared";
import BannerMessage, {
    BANNER_MESSAGE_STATUSES
} from "../../../../../../../DesignComponents/BannerMessage";

export const SendOtpCode = ({
    error,
    isLoading,
    changeCode
}: SendOtpCodeProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <OtpInputField
                isLoading={isLoading}
                error={error}
                onChange={changeCode}
                isRequired
            />

            <BannerMessage
                status={BANNER_MESSAGE_STATUSES.Info}
                title={t("Password##disable no access")}
                css={css({ marginTop: "16px" })}
            />
        </div>
    );
};
