import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const TransferCompaniesArrowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M3.83375 13.511L14.0573 13.511C14.0635 13.511 14.0684 13.506 14.0684 13.4998L14.0684 11.6613C14.0684 11.6551 14.0635 11.6501 14.0573 11.6501L3.83375 11.6501C3.31989 11.6501 2.90332 12.0667 2.90332 12.5806C2.90332 13.0944 3.31989 13.511 3.83375 13.511Z"
                fill="currentColor"
            />

            <path
                d="M12.2149 18.1139C12.2051 18.0532 12.2177 17.9917 12.2376 17.9335L14.0684 12.5806L12.2376 7.22763C12.2177 7.16947 12.2051 7.10794 12.2149 7.04726C12.2785 6.65346 12.742 6.44169 13.086 6.68245L20.8585 12.1232C21.176 12.3455 21.176 12.8157 20.8585 13.0379L13.086 18.4787C12.742 18.7194 12.2785 18.5077 12.2149 18.1139Z"
                fill="currentColor"
            />

            <path
                d="M3.83375 13.511L14.0573 13.511C14.0635 13.511 14.0684 13.506 14.0684 13.4998L14.0684 11.6613C14.0684 11.6551 14.0635 11.6501 14.0573 11.6501L3.83375 11.6501C3.31989 11.6501 2.90332 12.0667 2.90332 12.5806C2.90332 13.0944 3.31989 13.511 3.83375 13.511Z"
                stroke="currentColor"
                strokeWidth="0.930427"
            />

            <path
                d="M12.2149 18.1139C12.2051 18.0532 12.2177 17.9917 12.2376 17.9335L14.0684 12.5806L12.2376 7.22763C12.2177 7.16947 12.2051 7.10794 12.2149 7.04726C12.2785 6.65346 12.742 6.44169 13.086 6.68245L20.8585 12.1232C21.176 12.3455 21.176 12.8157 20.8585 13.0379L13.086 18.4787C12.742 18.7194 12.2785 18.5077 12.2149 18.1139Z"
                stroke="currentColor"
                strokeWidth="0.930427"
            />
        </SvgIcon>
    );
};

export default TransferCompaniesArrowIcon;
