import { RadioGroup } from "@mui/material";

import { DeviceRadioGroupProps } from "../types";

import Radio from "../../../../DesignComponents/Radio";

import { fileFormatBlockStyle } from "../../../../../shared";

const DeviceRadioGroup = ({
    customStyle,
    target,
    onChange,
    firstRadioLabel,
    firstRadioValue,
    secondRadioLabel,
    secondRadioValue
}: DeviceRadioGroupProps) => {
    return (
        <>
            <RadioGroup
                value={target}
                onChange={onChange}
                sx={customStyle || fileFormatBlockStyle}
            >
                <Radio value={firstRadioValue} label={firstRadioLabel} />

                <Radio value={secondRadioValue} label={secondRadioLabel} />
            </RadioGroup>
        </>
    );
};

export default DeviceRadioGroup;
