import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import TableContext from "../../../../context/table/tableContext";

const Carrier = () => {
    const { detailsData } = useContext(TableContext);

    const {
        carrier: {
            unit_status,
            unit_type,
            module_id,
            serial_id,
            version,
            filename
        }
    } = detailsData;

    const { t } = useTranslation();

    return (
        <Fragment>
            <div>{t("Dialog##carrier")}</div>

            <div>
                {/* Carrier unit status */}
                <span>{t("Table##carrier unit status")}:</span>

                <span>
                    {unit_status}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={unit_status}
                    />
                </span>
                {/* */}

                {/* Carrier unit type */}
                <span>{t("Table##carrier unit type")}:</span>

                <span>
                    {unit_type}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={unit_type}
                    />
                </span>
                {/* */}

                {/* Carrier module ID */}
                <span>{t("Table##carrier module id")}:</span>

                <span>
                    {module_id}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={module_id}
                    />
                </span>
                {/* */}

                {/* Carrier serial ID */}
                <span>{t("Table##carrier serial id")}:</span>

                <span>
                    {serial_id}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={serial_id}
                    />
                </span>
                {/* */}

                {/* Carrier revision */}
                <span>{t("Table##carrier revision")}:</span>

                <span>
                    {version}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={version}
                    />
                </span>
                {/* */}

                {/* Carrier settings filename */}
                <span>{t("Table##carrier settings filename")}:</span>

                <span>
                    {filename}{" "}
                    <CopyButton
                        size={BUTTON_SIZES.Tiny}
                        copiedValue={filename}
                    />
                </span>
                {/* */}
            </div>
        </Fragment>
    );
};

export default Carrier;
