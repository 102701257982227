import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompaniesIconRounded = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.13331 20C4.63831 20 4.21471 19.8261 3.86251 19.4782C3.50971 19.1298 3.33331 18.7111 3.33331 18.2222V5.77778C3.33331 5.28889 3.50971 4.87022 3.86251 4.52178C4.21471 4.17393 4.63831 4 5.13331 4H10.5333C11.0283 4 11.4522 4.17393 11.805 4.52178C12.1572 4.87022 12.3333 5.28889 12.3333 5.77778V7.55556H19.5333C20.0283 7.55556 20.4522 7.72948 20.805 8.07733C21.1572 8.42578 21.3333 8.84444 21.3333 9.33333V18.2222C21.3333 18.7111 21.1572 19.1298 20.805 19.4782C20.4522 19.8261 20.0283 20 19.5333 20H5.13331ZM5.13331 18.2222H10.5333V16.4444H5.13331V18.2222ZM5.13331 14.6667H10.5333V12.8889H5.13331V14.6667ZM5.13331 11.1111H10.5333V9.33333H5.13331V11.1111ZM5.13331 7.55556H10.5333V5.77778H5.13331V7.55556ZM12.3333 18.2222H19.5333V9.33333H12.3333V18.2222ZM14.1333 12.8889V11.1111H17.7333V12.8889H14.1333ZM14.1333 16.4444V14.6667H17.7333V16.4444H14.1333Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default CompaniesIconRounded;
