/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FilterMenuProps } from "./types";

import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../../DesignComponents/Menu";

import ThemeContext from "../../../../context/theme/themeContext";

const FilterMenu = ({
    isOpen,
    anchorEl,
    children,
    onClose
}: FilterMenuProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    return (
        <Menu
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            MenuListProps={{ variant: "menu" }}
            anchorOrigin={centeredAnchorOrigin}
            transformOrigin={centeredTransformOrigin}
            css={css({
                "& .MuiMenu-paper": {
                    minWidth: "276px"
                }
            })}
        >
            <div
                css={css({
                    padding: "15px"
                })}
            >
                <div
                    css={css({
                        color: gray700,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        marginBottom: "16px"
                    })}
                >
                    {t("Filter##table filters")}
                </div>

                {children}
            </div>
        </Menu>
    );
};

export default FilterMenu;
