/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { SingleDataItemProps } from "../types";
import ThemeContext from "../../../../../../context/theme/themeContext";

const SingleDataItem = ({
    name,
    data,
    icon,
    isRestricted,
    ...props
}: SingleDataItemProps) => {
    const {
        colorsFacelift: { textPlaceholder, gray700, textDarkDisabled }
    } = useContext(ThemeContext);

    const commonStyles = {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        letterSpacing: "0.1px"
    };

    return (
        <p
            {...props}
            css={css({
                display: "flex",
                margin: "4px 0 4px 0"
            })}
        >
            {icon}

            <span
                css={css({
                    ...commonStyles,
                    color: textPlaceholder,
                    marginRight: "8px"
                })}
            >
                {name}:
            </span>

            <span
                css={css({
                    ...commonStyles,
                    color: isRestricted ? textDarkDisabled : gray700
                })}
            >
                {data}
            </span>
        </p>
    );
};

export default SingleDataItem;
