/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import DialogActions from "../../components/DialogActions";
import { DialogProps, USER_ACTIONS } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import Tag, { TAG_COLORS, TAG_SIZES } from "../../../../DesignComponents/Tag";
import Label from "../../../../DesignComponents/Label";
import { useApi } from "../../../../../shared/hooks/useApi";
import ParamsContext from "../../../../../context/params/paramsContext";
import { ENDPOINTS, TABLE_NAMES, useIsMounted } from "../../../../../shared";

interface CancelInviteProps extends DialogProps {
    data?: any;
}

const CancelInvite = ({ isOpen, close, data }: CancelInviteProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const { setReloadItems } = useContext(ParamsContext);
    const { handleResponse, deleteData } = useApi();
    const { t } = useTranslation();
    const isMounted = useIsMounted();

    const handleCloseDialog = useCallback(() => {
        close(USER_ACTIONS.DeleteUsers);
    }, [close]);

    const handleSubmit = useCallback(async () => {
        try {
            setLoading(true);
            const response = await deleteData(ENDPOINTS.UserInvite, data?.id);

            if (isMounted) {
                handleCloseDialog();
                handleResponse(response);
                setReloadItems(TABLE_NAMES.UserInvite);
            }
        } catch (error) {
            if (isMounted) {
                handleResponse(error);
            }
        }

        setLoading(false);
    }, [
        data?.id,
        deleteData,
        handleCloseDialog,
        handleResponse,
        isMounted,
        setReloadItems
    ]);

    return (
        <Dialog
            title={t("General##cancel invite")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm
                    confirmationButtonText={t("Button##confirm")}
                    cancelButtonTestId="cancel-revoke-invite"
                    confirmationButtonTestId="confirm-revoke-invite"
                />
            }
        >
            <Label
                titleLeft={t(`General##cancel invite confirm`)}
                css={css({
                    margin: "8px 0"
                })}
            />

            <Tag
                data-testid="cancel-invite-email"
                title={data?.email}
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                css={css({
                    marginBottom: "16px"
                })}
            />
        </Dialog>
    );
};

export default CancelInvite;
