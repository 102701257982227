import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import lt from "./locales/lt.json";
import ru from "./locales/ru.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import fr from "./locales/fr.json";

import { LANGUAGES } from "../shared";

export const resources = {
    [LANGUAGES.English]: { translation: en },
    [LANGUAGES.Lithuanian]: { translation: lt },
    [LANGUAGES.Russian]: { translation: ru },
    [LANGUAGES.Spanish]: { translation: es },
    [LANGUAGES.Portuguese]: { translation: pt },
    [LANGUAGES.French]: { translation: fr }
} as const;

i18n.use(initReactI18next).init({
    lng: LANGUAGES.English,
    fallbackLng: LANGUAGES.English,
    resources,
    keySeparator: "##",
    interpolation: {
        escapeValue: false
    },
    returnEmptyString: false,
    react: {
        useSuspense: true
    }
});
