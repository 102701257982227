export enum LOADERS {
    LevelType = "level type",
    Submit = "submit"
}

export enum LEVEL_TYPES {
    Automatic = "automatic",
    Manual = "manual"
}

export enum TIME_PERIODS {
    January31NextYear = "january 31 next year",
    Custom = "custom"
}

export enum ADDITIONAL_DATA {
    ClientEmail = "clientEmail",
    SalesEmail = "salesEmail",
    Explanation = "explanation"
}
