import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { API_MESSAGES, ROUTES } from "../../types";
import AuthContext from "../../../context/auth/authContext";
import AlertContext, {
    ALERT_STATUS
} from "../../../context/alert/alertContext";

const useResponse = () => {
    const authContext = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const showSuccessAlert = (data: any) => {
        const message = data.message || data;

        if (message === API_MESSAGES.GroupWasAdded) {
            setAlert({
                status: ALERT_STATUS.Success,
                title: t(`Flash##${message}`, {
                    group: data.data.name
                })
            });
        } else {
            setAlert({
                status: ALERT_STATUS.Success,
                title: t(`Flash##${message}`, { defaultValue: message })
            });
        }
    };

    const logoutOrNavigateUser = (error: any) => {
        if (error.response.status === 503) {
            navigate(ROUTES.Maintenance, { replace: true });
        }

        if (error.response.status === 401 && authContext) {
            authContext.logoutUser();
        }

        return error.response.status !== 503;
    };

    const getMessageWithResponse = (error: any) => {
        if (error.response.data.errors) {
            return error.response.data.errors[0].errors[0];
        } else {
            return error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
        }
    };

    const getMessageWithoutResponse = (error: any) => {
        if (error.request) {
            if (error.request.statusText) {
                return error.request.statusText;
            } else {
                return error.message;
            }
        } else {
            return error.message;
        }
    };

    const getCriticalMessage = (errorResponse: any) => {
        const error = errorResponse;
        let message;
        let isAlert = true;

        if (error.response) {
            message = getMessageWithResponse(error);
            isAlert = logoutOrNavigateUser(error);
        } else {
            message = getMessageWithoutResponse(error);
        }

        return { message, isAlert };
    };

    const showCriticalAlert = (error: any) => {
        const { message, isAlert } = getCriticalMessage(error);

        isAlert &&
            setAlert({
                status: ALERT_STATUS.Critical,
                title: t(`Flash##${message}`, { defaultValue: message })
            });
    };

    const showResponse = (res: any) => {
        if (res.data) {
            showSuccessAlert(res.data);
        } else {
            showCriticalAlert(res);
        }
    };

    return { showResponse, getCriticalMessage };
};

export default useResponse;
