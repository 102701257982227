/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { TableSortLabelProps } from "./types";
import SortAscIcon from "../../../assets/customIcons/actions/SortAscIcon";
import SortDescIcon from "../../../assets/customIcons/actions/SortDescIcon";
import ThemeContext from "../../../context/theme/themeContext";
import { ORDERS } from "../../../shared";

const TableSortLabel = ({
    children,
    isActive,
    direction,
    onClick
}: TableSortLabelProps) => {
    const {
        colorsFacelift: { textDark, textDarkDisabled }
    } = useContext(ThemeContext);

    const getColor = (order: ORDERS) =>
        isActive && order === direction ? textDark : textDarkDisabled;

    return (
        <span
            onClick={onClick}
            css={css({
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
            })}
        >
            {children}

            <span
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "8px",

                    "& > svg": {
                        fontSize: "8px",
                        transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                    }
                })}
            >
                <SortAscIcon
                    css={css({
                        color: getColor(ORDERS.Ascending)
                    })}
                />

                <SortDescIcon
                    css={css({
                        color: getColor(ORDERS.Descending)
                    })}
                />
            </span>
        </span>
    );
};

export default TableSortLabel;
