/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Skeleton } from "@mui/material";

import ThemeContext from "../../../../context/theme/themeContext";
import { generateArrayOfNumbers } from "../../../../shared";

const NewsLoader = () => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    const arrayOfNumbers = generateArrayOfNumbers(3);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                rowGap: "2px",
                marginTop: "16px",
                overflowX: "auto"
            })}
        >
            {arrayOfNumbers.map((item, i: number) => {
                return (
                    <Skeleton
                        key={item}
                        variant="rectangular"
                        height={i > 0 ? 60 : 130}
                        css={css({
                            background: gray100,
                            borderRadius: "6px"
                        })}
                    />
                );
            })}
        </div>
    );
};

export default NewsLoader;
