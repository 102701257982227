/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import TaskStats from "./TaskStats";
import ApproveDiscard from "./ApproveDiscard";
import { TableItemInstanceProps } from "../types";

import Badge, { BADGE_COLORS } from "../../DesignComponents/Badge";
import InlineActionIconButton from "../../DesignComponents/Button/InlineActionIconButton";
import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import TaskDetailsIcon from "../../../assets/customIcons/actions/TaskDetailsIcon";

const MobileCard = ({
    data,
    isApprovalRequired,
    isApprovalBadgeRendered
}: TableItemInstanceProps) => {
    const {
        colorsFacelift: { gray200, gray700, textDark, white }
    } = useContext(ThemeContext);

    const { tableName, setDetailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const {
        id,
        task_type,
        type,
        task_file_name,
        objects,
        created_at,
        created_by,
        task_stats
    } = data;

    return (
        <div
            css={css({
                background: white
            })}
        >
            <div
                css={css({
                    "& > div": {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "56px",
                        boxSizing: "border-box",
                        borderBottom: `1px solid ${gray200}`,
                        padding: "0 24px",

                        "& > div:first-of-type": {
                            color: gray700,
                            fontSize: "10px",
                            fontWeight: "600",
                            lineHeight: "12px",
                            letterSpacing: "1px"
                        },

                        "& > div:last-of-type": {
                            color: textDark,
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis"
                        }
                    }
                })}
            >
                <div>
                    <div>{t("General##task type")}</div>

                    <div>
                        {task_type
                            ? t(`General##${task_type}`)
                            : t(`Task groups##bulk type##${type}`)}
                    </div>
                </div>

                {task_file_name && (
                    <div>
                        <div>{t("General##file")}</div>
                        <div>{task_file_name}</div>
                    </div>
                )}

                <div>
                    <div>{t("General##id")}</div>
                    <div>{id}</div>
                </div>

                <div>
                    <div>{t("General##created at")}</div>
                    <div>{created_at}</div>
                </div>

                {created_by && (
                    <div>
                        <div>{t("General##created by")}</div>
                        <div>{created_by.email}</div>
                    </div>
                )}
            </div>

            {(isApprovalRequired || task_stats.length > 0) && (
                <div
                    css={css({
                        padding: "9px 24px"
                    })}
                >
                    <div
                        css={css({
                            color: gray700,
                            fontSize: "10px",
                            fontWeight: "600",
                            lineHeight: "12px",
                            letterSpacing: "1px",
                            marginBottom: "2px"
                        })}
                    >
                        {t("General##status")}
                    </div>

                    {isApprovalBadgeRendered ? (
                        <div>
                            <Badge color={BADGE_COLORS.Light}>
                                {objects} {t("General##Pending approval")}
                            </Badge>
                        </div>
                    ) : (
                        <TaskStats data={task_stats} />
                    )}
                </div>
            )}

            <div
                css={css({
                    display: "flex",
                    padding: "8px 24px",
                    borderTop: `1px solid ${gray200}`,

                    "& > span": {
                        display: "inline-flex",
                        width: "100%"
                    },

                    "& > span + span": {
                        marginLeft: "16px"
                    }
                })}
            >
                {isApprovalRequired && <ApproveDiscard id={id} />}

                <InlineActionIconButton
                    title={t(`Table##show details##${tableName}`)}
                    icon={<TaskDetailsIcon />}
                    onButtonClick={() => setDetailsData(data)}
                />
            </div>
        </div>
    );
};

export default MobileCard;
