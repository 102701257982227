import Grid from "@mui/material/Grid";

import General from "./General";
import OtherInfo from "./OtherInfo";

import DetailsContainer from "../../components/DetailsContainer";

const Details = () => {
    return (
        <DetailsContainer>
            <Grid item xs0={12} md={6} lg={4}>
                <General />
            </Grid>

            <Grid item xs0={12} md={6} lg={4}>
                <OtherInfo />
            </Grid>
        </DetailsContainer>
    );
};

export default Details;
