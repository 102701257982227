/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LinkItemProps } from "./types";

import {
    TOOLTIP_PLACEMENT,
    TooltipOptional
} from "../../DesignComponents/Tooltips";

import ThemeContext from "../../../context/theme/themeContext";
import RoutesContext from "../../../context/routes/routesContext";

const LinkItem = ({ name, icon, link }: LinkItemProps) => {
    const {
        colorsFacelift: { gray100, gray200, gray300, gray700 }
    } = useContext(ThemeContext);

    const { isDesktopDrawerOpen, isMobileDrawerOpen } =
        useContext(RoutesContext);

    const { t } = useTranslation();

    const isActive = useMatch(link) !== null;

    return (
        <li>
            <TooltipOptional
                isHidden={isDesktopDrawerOpen || isMobileDrawerOpen}
                title={t(`General##${name}`)}
                placement={TOOLTIP_PLACEMENT.Right}
                disableTouchListener
            >
                <Link
                    to={link}
                    id={name}
                    className="navigation-item"
                    onClick={e => isActive && e.preventDefault()}
                    css={css({
                        color: gray700,
                        backgroundColor: isActive ? gray200 : "transparent",

                        "& > svg": {
                            color: gray700
                        },

                        "& > span": {
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginLeft: "8px",
                            whiteSpace: "nowrap",
                            color: gray700
                        },

                        "&:hover": {
                            backgroundColor: isActive ? gray300 : gray100
                        },

                        "&:active": {
                            backgroundColor: isActive ? gray300 : gray200
                        }
                    })}
                >
                    {icon} <span>{t(`General##${name}`)}</span>
                </Link>
            </TooltipOptional>
        </li>
    );
};

export default LinkItem;
