import { useDateTime } from "../../../../../../../../shared";

export const useMarks = () => {
    const { startOfTime } = useDateTime();

    const marks = [
        {
            value: 0,
            label: startOfTime("day")
                .add(0, "minutes")
                .format("LT")
        },
        {
            value: 16,
            label: startOfTime("day")
                .add(16 * 30, "minutes")
                .format("LT")
        },
        {
            value: 32,
            label: startOfTime("day")
                .add(32 * 30, "minutes")
                .format("LT")
        },
        {
            value: 48,
            label: startOfTime("day")
                .add(48 * 30 - 1, "minutes")
                .format("LT")
        }
    ];

    return { marks };
};
