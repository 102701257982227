/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { RectangleLoader } from "./RectangleLoader";
import ThemeContext from "../../context/theme/themeContext";

interface ActionBarTitleLoaderProps {
    customStyle?: any;
}

export const ActionBarTitleLoader = ({
    customStyle
}: ActionBarTitleLoaderProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    return (
        <div
            css={[
                css({
                    display: "flex",
                    alignItems: "center",
                    padding: "6px 0",

                    "span + span": {
                        marginLeft: "16px"
                    },

                    "@media (max-width: 599px)": {
                        padding: "0"
                    }
                }),
                customStyle
            ]}
        >
            <RectangleLoader
                width={216}
                borderRadius={"46px"}
                backgroundColor={gray200}
                height={24}
            />

            <RectangleLoader
                width={38}
                borderRadius={"46px"}
                backgroundColor={gray200}
                height={24}
            />
        </div>
    );
};
