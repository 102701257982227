/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import {
    CompanyLevelBodyRows,
    CompanyLevelContentParamsProps,
    CompanyLevelHeaderParams
} from "./shared/types";

import ThemeContext from "../../context/theme/themeContext";
import { COMPANY_LEVELS, useFormattedNumber } from "../../shared";

const useCompanyLevelContent = () => {
    const {
        colorsFacelift: { beigeDefault, blue700, gray200, gray300, gray800 }
    } = useContext(ThemeContext);

    const { renderFormattedNumber } = useFormattedNumber();

    const getCompanyLevelDesktopCSS = (
        params: CompanyLevelContentParamsProps,
        isLast: boolean,
        isInfo: boolean
    ) => {
        const { isTitle, paddingBottom } = params;

        const textCSS = {
            fontWeight: isTitle ? "600" : "400",
            fontSize: isTitle ? "24px" : "16px",
            lineHeight: isTitle ? "32px" : "24px",
            letterSpacing: isTitle ? "0" : "0.15px"
        };

        const alignmentCSS = {
            display: "flex",
            paddingBottom,
            paddingRight: isLast ? "initial" : "8px",
            paddingLeft: !isInfo ? "8px" : "unset",
            alignItems: "center",
            "& span": {
                flex: "1"
            }
        };

        const iconCSS = {
            "& div, span": {
                marginLeft: "auto",
                display: "flex",

                "& > svg": {
                    fontSize: "16px",
                    color: blue700
                }
            }
        };

        return [textCSS, alignmentCSS, iconCSS];
    };

    const getCompanyLevelMobileCSS = (
        params: CompanyLevelContentParamsProps,
        isInfo: boolean
    ) => {
        const { isTitle } = params;

        const textCSS = {
            fontWeight: isTitle ? "600" : "400",
            fontSize: isTitle ? "20px" : "16px",
            lineHeight: isTitle ? "24px" : "20px",
            letterSpacing: "0.15px"
        };

        const alignmentCSS = {
            display: "flex",
            padding: isTitle ? "16px 24px" : "14px 24px",
            justifyContent: isInfo ? "flex-start" : "flex-end",
            alignItems: "center"
        };

        const iconCSS = {
            "& div, span": {
                display: "flex",
                "& > svg": {
                    fontSize: "16px",
                    color: blue700
                }
            }
        };

        return [textCSS, alignmentCSS, iconCSS];
    };

    const getGridItemCSS = (
        isLast: boolean,
        isHeader: boolean,
        isInfo?: boolean
    ) => {
        return css([
            {
                padding: isInfo ? "0 16px 0 8px" : "0 8px",
                textAlign: isInfo ? "unset" : "center",
                borderRight: isLast ? "none" : `1px solid ${gray300}`,

                display: isInfo ? "initial" : "flex"
            },
            !isHeader && {
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }
        ]);
    };

    const getIndexInfo = (index: number, item?: any) => {
        const getCompanyGroup = (() => {
            if (item)
                switch (index) {
                    case 0:
                        return item.info;

                    case 1:
                        return item[COMPANY_LEVELS.Basic];

                    case 2:
                        return item[COMPANY_LEVELS.Standard];

                    default:
                        return item[COMPANY_LEVELS.Premium];
                }
        })();

        return {
            isFirst: index === 0,
            isLast: index === Object.values(COMPANY_LEVELS).length - 1,
            entryLevel: item && getCompanyGroup
        };
    };

    const getRowTitle = (title: string) => {
        return {
            info: title,
            infoTooltip: "",
            [COMPANY_LEVELS.Basic]: "",
            [COMPANY_LEVELS.Standard]: "",
            [COMPANY_LEVELS.Premium]: "",
            params: { isTitle: true, paddingBottom: 16 }
        };
    };

    const getDuplicatingRow = (
        infoValue: string,
        tooltipText: string,
        rowValue: string,
        paddingBottom: number
    ) => {
        return {
            info: infoValue,
            infoTooltip: tooltipText,
            [COMPANY_LEVELS.Basic]: rowValue,
            [COMPANY_LEVELS.Standard]: rowValue,
            [COMPANY_LEVELS.Premium]: rowValue,
            params: { paddingBottom }
        };
    };

    const companyLevelHeaderParams: CompanyLevelHeaderParams = {
        backgroundColors: {
            [COMPANY_LEVELS.Basic]: gray200,
            [COMPANY_LEVELS.Standard]: beigeDefault,
            [COMPANY_LEVELS.Premium]: gray800
        },
        subtitleText: {
            [COMPANY_LEVELS.Basic]: "Essentials for remote device update",
            [COMPANY_LEVELS.Standard]: "Advanced automation and integration",
            [COMPANY_LEVELS.Premium]:
                "Enterprise scaling and early access to updates"
        }
    };

    const companyLevelBodyRows: CompanyLevelBodyRows[] = [
        getRowTitle("Key features"),

        getDuplicatingRow(
            "Update firmware",
            "Send a firmware file to a device.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Update configuration",
            "Send a configuration file to a device.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Receive configuration",
            "Receive the current configuration of the device and save the file in FOTA WEB.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Receive internal device log",
            "Receive internal device log from the device for debugging.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Device list export limit",
            "Maximum number of exported devices per file (.xlxs or .csv format).",
            `${renderFormattedNumber(100000)}`,
            24
        ),
        getDuplicatingRow(
            "Transfer TLS certificate",
            "Transfer the TLS certificate over the air to the device and ensure an encrypted connection between the device and a tracking platform.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Device transfer",
            "Transfer devices between unrelated companies in FOTA WEB.",
            "SuccessIcon",
            48
        ),

        getRowTitle("Accessories control"),

        getDuplicatingRow(
            "Update CAN adapter",
            "Update LV-CAN200, ALL-CAN300, CAN-CONTROL, and FMx140 CAN adapter software version.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Update FMx150 CAN chip",
            "Update FMx150 devices CAN chip software version.",
            "SuccessIcon",
            24
        ),
        getDuplicatingRow(
            "Update CAN OEM file",
            "Upload CAN OEM configuration file for a selected make and model of the vehicle.",
            "SuccessIcon",
            48
        ),

        getRowTitle("Automation"),

        {
            info: "Smart groups",
            infoTooltip:
                "An ability to assign firmware and configuration files for a device group. Adding devices to a smart group creates firmware and configuration update tasks automatically.",
            [COMPANY_LEVELS.Basic]: "—",
            [COMPANY_LEVELS.Standard]: "SuccessIcon",
            [COMPANY_LEVELS.Premium]: "SuccessIcon",
            params: {
                paddingBottom: 24
            }
        },

        {
            info: "Task schedule",
            infoTooltip:
                "An ability to make tasks to be executed if a device connects to FOTA during a selected time window.",
            [COMPANY_LEVELS.Basic]: "—",
            [COMPANY_LEVELS.Standard]: "SuccessIcon",
            [COMPANY_LEVELS.Premium]: "SuccessIcon",
            params: {
                paddingBottom: 48
            }
        },

        getRowTitle("History and expiration"),

        getDuplicatingRow(
            "Tasks and task groups history",
            "The time period for how long tasks and task groups history will be stored.\n1 month equals 30 days.",
            "24 months",
            24
        ),
        {
            info: "Pending task expiration",
            infoTooltip:
                "The time period after which pending tasks will be marked as expired.\n1 month equals 30 days.",
            [COMPANY_LEVELS.Basic]: "1 month",
            [COMPANY_LEVELS.Standard]: "12 months",
            [COMPANY_LEVELS.Premium]: "12 months",
            params: {
                paddingBottom: 24
            }
        },
        {
            info: "File expiration",
            infoTooltip:
                "The period after which files are deleted from FOTA WEB. The calculation starts from the file's last used time (file creation, file update (description and company), file used in task creation, and file download).\n1 month equals 30 days.",
            [COMPANY_LEVELS.Basic]: "12 months",
            [COMPANY_LEVELS.Standard]: "24 months",
            [COMPANY_LEVELS.Premium]: "24 months",
            params: {
                paddingBottom: 24
            }
        },
        {
            info: "Device change history",
            infoTooltip:
                "The time period for how long device change history will be visible.\n1 month equals 30 days.",
            [COMPANY_LEVELS.Basic]: "6 months",
            [COMPANY_LEVELS.Standard]: "24 months",
            [COMPANY_LEVELS.Premium]: "24 months",
            params: {
                paddingBottom: 48
            }
        },

        getRowTitle("Resources"),
        {
            info: "Companies",
            infoTooltip:
                "The total number of child companies that can be created in the company hierarchy.",
            [COMPANY_LEVELS.Basic]: `${renderFormattedNumber(5)}`,
            [COMPANY_LEVELS.Standard]: `${renderFormattedNumber(100)}`,
            [COMPANY_LEVELS.Premium]: `${renderFormattedNumber(500)}`,
            params: {
                paddingBottom: 24
            }
        },
        {
            info: "Users",
            infoTooltip:
                "The total number of users that can be created in the company hierarchy.",
            [COMPANY_LEVELS.Basic]: `${renderFormattedNumber(10)}`,
            [COMPANY_LEVELS.Standard]: `${renderFormattedNumber(100)}`,
            [COMPANY_LEVELS.Premium]: `${renderFormattedNumber(500)}`,
            params: {
                paddingBottom: 24
            }
        },
        {
            info: "Tasks per task group",
            infoTooltip:
                "The number of tasks that can be in one task group. The number of task groups is not limited.",
            [COMPANY_LEVELS.Basic]: `${renderFormattedNumber(100)}`,
            [COMPANY_LEVELS.Standard]: `${renderFormattedNumber(10000)}`,
            [COMPANY_LEVELS.Premium]: `${renderFormattedNumber(30000)}`,
            params: {
                paddingBottom: 24
            }
        },

        getDuplicatingRow(
            "Configuration files",
            `The number of configuration files that can be stored, 1 device equals 1 configuration file. The minimum guaranteed amount of configuration files is 100 for each company level. E.g.:
            A company with 10 devices can store up to 100 configuration files. A company with 150 devices can store 150 configuration files.`,
            "1 per device count",
            48
        ),

        getRowTitle("Integrations"),
        {
            info: "FOTA WEB API",
            infoTooltip: "An ability to create FOTA WEB API integrations.",
            [COMPANY_LEVELS.Basic]: "—",
            [COMPANY_LEVELS.Standard]: "SuccessIcon",
            [COMPANY_LEVELS.Premium]: "SuccessIcon",
            params: {
                paddingBottom: 24
            }
        },
        {
            info: "TCT web",
            infoTooltip:
                "Create configuration from scratch and edit existing configuration in TCT web-based version.",
            [COMPANY_LEVELS.Basic]: "—",
            [COMPANY_LEVELS.Standard]: "—",
            [COMPANY_LEVELS.Premium]: "SuccessIcon",
            params: {
                paddingBottom: 0
            }
        }
    ];

    const frequentlyAskedQuestionsContent: any = [
        {
            id: 0,
            title: "How are the company levels attributed in FOTA WEB?",
            body: "A level is attributed to a company depending on the stage of our business partnership. Please contact your Teltonika Telematics provider to get more details."
        },
        {
            id: 1,
            title: "When will the company levels come into force?",
            body: "Time and feature aspects of company levels will come into force on January 30th, 2024. Resource and API aspects will be applied on July 16th, 2024. We will send additional reminders before the implementation dates."
        },
        {
            id: 2,
            title: "I have more child companies, users and / or configuration files than my current level allows. What will happen with extra entities?",
            body: "Extra entities will continue to be available in your FOTA WEB account. However, there will be no possibility to create new companies, users and / or configuration files until extra entities are deleted."
        },
        {
            id: 3,
            title: "Will the contents of Company levels change in future?",
            body: "The contents of each level might change in future, depending on the new features released in FOTA WEB or on growth of the system itself. Meantime the core functionality, like remote device update feature, will continue to be available for all Teltonika Telematics clients. Any change we might introduce in future will be announced in advance."
        },
        {
            id: 4,
            title: "Each of the levels has expiration period for tasks and files. How this period will be calculated?",
            body: "The expiration date for tasks will be calculated starting from task creation. It will allow us to define for how long the tasks could have a status “Pending” in FOTA WEB. The expiration date for files will be calculated starting from the last day if was used in any way (for example, used in a task). It will allow us to detect the files that are not relevant to our clients anymore. Please pay attention to the following changes that might affect tasks, task groups, device change history, and files."
        }
    ];

    return {
        companyLevelHeaderParams,
        companyLevelBodyRows,
        frequentlyAskedQuestionsContent,
        getCompanyLevelDesktopCSS,
        getCompanyLevelMobileCSS,
        getGridItemCSS,
        getIndexInfo
    };
};

export default useCompanyLevelContent;
