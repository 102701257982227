import NewsWidget from "./NewsWidget";
import Statistics from "./Statistics";

const LeftSide = () => {
    return (
        <>
            <NewsWidget />
            <Statistics />
        </>
    );
};

export default LeftSide;
