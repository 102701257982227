export enum TEXT_LINK_SIZES {
    Small = "small",
    Normal = "normal",
    Large = "large"
}

export enum TEXT_LINK_COLORS {
    Primary = "primary",
    Critical = "critical"
}
