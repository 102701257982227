import { useState } from "react";

import Language from "./Language";
import TimeZone from "./TimeZone";
import InitialContent from "./InitialContent";
import Tutorials from "./Tutorials";
import { CONTENT_NAMES, SettingsContentProps } from "./types";

const SettingsContent = ({
    isMobile,
    openDialog,
    closeMenu
}: SettingsContentProps) => {
    const [content, setContent] = useState<CONTENT_NAMES>(
        CONTENT_NAMES.Initial
    );

    const handleSetContent = (newContent: CONTENT_NAMES) => {
        setContent(newContent);
    };

    const renderContent = () => {
        switch (content) {
            case CONTENT_NAMES.Language:
                return <Language setContent={handleSetContent} />;
            case CONTENT_NAMES.TimeZone:
                return <TimeZone setContent={handleSetContent} />;
            case CONTENT_NAMES.TutorialMode:
                return (
                    <Tutorials
                        isMobile={isMobile}
                        closeMenu={closeMenu}
                        setContent={handleSetContent}
                    />
                );
            default:
                return (
                    <InitialContent
                        setContent={handleSetContent}
                        openDialog={openDialog}
                    />
                );
        }
    };

    return renderContent();
};

export default SettingsContent;
