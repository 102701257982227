/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import RootCompanyListBox from "./RootCompanyListBox";

import CompanyLevelTagLoader from "../../components/CompanyLevelTagLoader";
import { useRootCompanySearch } from "../../hooks";
import { RootCompanyProps } from "../../types";

import Autocomplete from "../../../../DesignComponents/Autocomplete";
import CompanyLevelTag from "../../../../CompanyLevel/shared/components/CompanyLevelTag";

import ParamsContext from "../../../../../context/params/paramsContext";
import {
    DROPDOWN_SIZES,
    FILTER_NAMES,
    Filter,
    getRootCompanyValue
} from "../../../../../shared";

const RootCompany = ({ isLoading }: RootCompanyProps) => {
    const {
        queryParams,
        filterDropdownOptions,
        rootCompanyLevel,
        changeRootCompany
    } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { rootCompanyFilterOptions } = useRootCompanySearch();

    const [isLocalLoading, setIsLocalLoading] = useState(isLoading);

    const rootCompanyId = queryParams[FILTER_NAMES.RootCompany];
    const rootCompanies = filterDropdownOptions[FILTER_NAMES.RootCompany] ?? [];

    const value = getRootCompanyValue(
        filterDropdownOptions,
        rootCompanyId as string
    );

    useEffect(() => {
        setIsLocalLoading(isLoading);
    }, [isLoading]);

    const handleChange = (companyId: string) => {
        changeRootCompany(companyId);
        setIsLocalLoading(true);
    };

    const renderLevelTag = () =>
        isLocalLoading ? (
            <CompanyLevelTagLoader />
        ) : (
            <CompanyLevelTag level={rootCompanyLevel} />
        );

    return (
        <div
            css={css({
                padding: "12px 16px 4px 16px"
            })}
        >
            <Autocomplete
                fullWidth
                size={DROPDOWN_SIZES.Medium}
                labelLeft={t("Dialog##root company")}
                includeInputInList
                disableClearable
                value={value}
                options={rootCompanies}
                onChange={(_, newFilterValue) =>
                    handleChange(String(newFilterValue.id))
                }
                onKeyDown={e => e.stopPropagation()} // prevents from losing focus on pressing "c" key
                ListboxComponent={RootCompanyListBox}
                getOptionLabel={(option: Filter) => option.name}
                iconRight={renderLevelTag()}
                filterOptions={(options, { inputValue }) =>
                    rootCompanyFilterOptions(options, inputValue.toLowerCase())
                }
                renderOption={(propsData, options) => {
                    return (
                        <li {...propsData} key={options.id}>
                            <div>{options.name}</div>
                            <div>{options.id}</div>
                        </li>
                    );
                }}
            />
        </div>
    );
};

export default RootCompany;
