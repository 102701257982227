import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SortDescIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="12" height="8" viewBox="0 0 12 8">
            <path
                d="M2.12021 0.7925L6.00022 4.6725L9.88021 0.7925C10.2702 0.4025 10.9002 0.4025 11.2902 0.7925C11.6802 1.1825 11.6802 1.8125 11.2902 2.2025L6.70022 6.7925C6.31021 7.1825 5.68021 7.1825 5.29022 6.7925L0.700215 2.2025C0.310215 1.8125 0.310215 1.1825 0.700215 0.7925C1.09021 0.4125 1.73022 0.4025 2.12021 0.7925Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SortDescIcon;
