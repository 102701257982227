/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";
import { Grid } from "@mui/material";

import useCompanyLevelContent from "../useCompanyLevelContent";
import CompanyLevelBodyItem from "../shared/components/CompanyLevelBodyItem";
import {
    COMPANY_LEVEL_DIALOGS,
    CompanyLevelBodyRows,
    CompanyLevelProps
} from "../shared/types";

import ThemeContext from "../../../context/theme/themeContext";

const CompanyLevelMobileBody = ({ companyLevelName }: CompanyLevelProps) => {
    const { companyLevelBodyRows, getCompanyLevelMobileCSS } =
        useCompanyLevelContent();

    const {
        colorsFacelift: { gray200, white }
    } = useContext(ThemeContext);

    const mobileRowCSS = (item: CompanyLevelBodyRows, index: number) => {
        return {
            marginTop: item.params.isTitle && index !== 0 ? `16px` : "",
            backgroundColor: white,
            borderBottom: item.params.isTitle ? "" : `1px ${gray200} solid`,
            borderTop:
                item.params.isTitle && index !== 0 ? `1px ${gray200} solid` : ""
        };
    };

    return (
        <Grid
            container
            css={css({
                justifyContent: "center",
                position: "relative",
                borderBottom: `1px ${gray200} solid`
            })}
        >
            {companyLevelBodyRows.map((item, index) => (
                <Fragment key={item.info}>
                    <Grid
                        item
                        xs0={6}
                        md={6}
                        lg={6}
                        css={css(mobileRowCSS(item, index))}
                    >
                        <CompanyLevelBodyItem
                            companyLevel={item.info}
                            tooltipText={item.infoTooltip}
                            itemCSS={getCompanyLevelMobileCSS(
                                item.params,
                                true
                            )}
                        />
                    </Grid>

                    <Grid
                        item
                        xs0={6}
                        md={6}
                        lg={6}
                        css={css([
                            mobileRowCSS(item, index),
                            {
                                display: "inline-flex",
                                justifyContent: "flex-end"
                            }
                        ])}
                    >
                        <CompanyLevelBodyItem
                            companyLevel={
                                item[
                                    companyLevelName as
                                        | COMPANY_LEVEL_DIALOGS.Basic
                                        | COMPANY_LEVEL_DIALOGS.Standard
                                        | COMPANY_LEVEL_DIALOGS.Premium
                                ]
                            }
                            itemCSS={getCompanyLevelMobileCSS(
                                item.params,
                                false
                            )}
                        />
                    </Grid>
                </Fragment>
            ))}
        </Grid>
    );
};

export default CompanyLevelMobileBody;
