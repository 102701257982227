import { FILTER_NAMES } from "../../../../../shared";

export const getFilteredValues = (
    filterName: FILTER_NAMES,
    id: number,
    name: string
) => ({
    filterName,
    value: [{ id, name }]
});
