import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { TriSourceConfirmationDialogProps } from "../../types";
import DialogConfirmation from "../../../../../../DesignComponents/DialogConfirmation";
import Tag, {
    TAG_COLORS,
    TAG_SIZES
} from "../../../../../../DesignComponents/Tag";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../../DesignComponents/InputField";
import TableContext from "../../../../../../../context/table/tableContext";
import DropzoneContext from "../../../../../../../context/dropzone/dropzoneContext";
import TriSourceContext from "../../../../../../../context/triSource/triSourceContext";
import { TRI_SOURCES, useFormattedNumber } from "../../../../../../../shared";

const TriSourceConfirmationDialog = ({
    isOpenMainDialog,
    description,
    onSubmit
}: TriSourceConfirmationDialogProps) => {
    const { tableName, selectedRows } = useContext(TableContext);
    const { lineCount } = useContext(DropzoneContext);

    const {
        activeSource,
        filteredOnlyCount,
        showConfirmation,
        handleShowConfirmation
    } = useContext(TriSourceContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    const [enteredNumber, setEnteredNumber] = useState("");
    const [isConfirmed, setConfirmed] = useState(false);

    const closeDialog = () => handleShowConfirmation(false);

    const getCount = () => {
        if (activeSource === TRI_SOURCES.Selected) {
            return selectedRows.length;
        }

        if (activeSource === TRI_SOURCES.Filtered) {
            return Number(filteredOnlyCount);
        }

        return lineCount;
    };

    const handleEnteredNumberChange = (e: { target: { value: any } }) => {
        const count = getCount();
        const value = e.target.value.trim();

        setEnteredNumber(value);
        setConfirmed(value === String(count));
    };

    const handleSubmit = () => {
        onSubmit && onSubmit();
        handleShowConfirmation(false);
    };

    const getTitleAndLabel = () => {
        const count = getCount();

        if (activeSource === TRI_SOURCES.FromFile) {
            return {
                tagTitle: `${t(
                    "Dialog##confirmations##number of lines"
                )}: ${renderFormattedNumber(count)}`,
                inputLabel: t("Dialog##confirmations##file")
            };
        } else {
            const selectedOrFiltered =
                activeSource === TRI_SOURCES.Selected ? "selected" : "filtered";

            return {
                tagTitle: `${t(
                    `Dialog##${selectedOrFiltered} ${tableName}`
                )}: ${renderFormattedNumber(count)}`,
                inputLabel: t(
                    `Dialog##confirmations##${activeSource}##${tableName}`
                )
            };
        }
    };

    const { tagTitle, inputLabel } = getTitleAndLabel();

    const fullDescription = `${description} ${t(
        "Dialog##confirmations##description second part"
    )}`;

    const handleOnExited = () => {
        setEnteredNumber("");
        setConfirmed(false);
    };

    return (
        <DialogConfirmation
            isOpen={showConfirmation && isOpenMainDialog}
            description={fullDescription}
            onClose={closeDialog}
            onSubmit={handleSubmit}
            onExited={handleOnExited}
            isConfirmBtnDisabled={!isConfirmed}
            confirmBtnTestId="confirm-create-task-button"
        >
            <Tag
                size={TAG_SIZES.Medium}
                color={TAG_COLORS.Primary}
                title={tagTitle}
            />

            <InputField
                fullWidth
                required
                size={INPUT_FIELD_SIZES.Medium}
                value={enteredNumber}
                placeholder="0"
                onChange={handleEnteredNumberChange}
                labelLeft={inputLabel}
                customStyle={{
                    marginTop: "8px"
                }}
            />
        </DialogConfirmation>
    );
};

export default TriSourceConfirmationDialog;
