/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Skeleton } from "@mui/material";
import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";

interface RectangleLoaderProps {
    width?: string | any;
    height?: string | any;
    customStyle?: any;
    backgroundColor?: string;
    borderRadius?: string;
}

export const RectangleLoader = ({
    width,
    height,
    customStyle,
    backgroundColor,
    borderRadius
}: RectangleLoaderProps) => {
    const {
        colorsFacelift: { gray100 }
    } = useContext(ThemeContext);

    return (
        <Skeleton
            data-testid="rectangle-loader"
            variant="rectangular"
            width={width}
            height={height}
            css={css([
                {
                    background: backgroundColor || gray100,
                    borderRadius: borderRadius || "6px"
                },
                customStyle
            ])}
        />
    );
};
