import { useContext } from "react";
import ThemeContext from "../../../../context/theme/themeContext";

export const useStyleOptions = (
    isSelected: boolean,
    onDelete?: (event: any) => void
) => {
    const {
        colorsFacelift: {
            beigeActive,
            beigeDefault,
            beigeHover,
            blue100,
            blue200,
            blue400,
            blue700,
            blue800,
            blue900,
            gray100,
            gray200,
            gray300,
            gray400,
            gray600,
            gray700,
            gray800,
            textDark,
            white
        }
    } = useContext(ThemeContext);

    const styleVariants = {
        colors: {
            primary: {
                backgroundRest: isSelected ? blue700 : blue100,
                backgroundHover: isSelected ? blue800 : blue200,
                backgroundActive: isSelected ? blue900 : blue400,
                textRest: isSelected ? white : blue700,
                textActive: isSelected ? white : blue700,
                border: isSelected ? blue700 : blue100,
                borderHover: isSelected ? blue800 : blue200,
                borderActive: isSelected ? blue900 : blue400,
                icon: isSelected ? white : blue700,
                iconHover: blue800,
                iconActive: blue900
            },
            white: {
                backgroundRest: white,
                backgroundHover: gray100,
                backgroundActive: gray200,
                textRest: gray700,
                textActive: textDark,
                border: gray200,
                borderHover: gray200,
                borderActive: gray200,
                icon: gray700,
                iconHover: gray700,
                iconActive: gray700
            },
            basicBundle: {
                backgroundRest: gray200,
                backgroundHover: gray300,
                backgroundActive: gray400,
                textRest: gray700,
                textActive: textDark,
                border: gray200,
                borderHover: gray300,
                borderActive: gray400,
                icon: gray700,
                iconHover: gray700,
                iconActive: gray700
            },
            standardBundle: {
                backgroundRest: beigeDefault,
                backgroundHover: beigeHover,
                backgroundActive: beigeActive,
                textRest: gray700,
                textActive: textDark,
                border: beigeDefault,
                borderHover: beigeHover,
                borderActive: beigeActive,
                icon: gray700,
                iconHover: gray700,
                iconActive: gray700
            },
            premiumBundle: {
                backgroundRest: gray800,
                backgroundHover: gray700,
                backgroundActive: gray600,
                textRest: white,
                textActive: white,
                border: gray800,
                borderHover: gray700,
                borderActive: gray600,
                icon: white,
                iconHover: white,
                iconActive: white
            }
        },
        paddings: {
            medium: "5px 8px",
            small: "1px 8px",
            tiny: onDelete ? "1px 4px 1px 8px" : "1px 8px"
        }
    };

    return styleVariants;
};
