import { TAB_SIZES } from "..";

export const useStyleOptions = () => {
    const styleVariants = {
        [TAB_SIZES.Tiny]: {
            padding: "3px 4px",
            tabPadding: "2px 12px 2px 8px",
            scrollButtonLeftMargin: "-4px 4px -4px -4px",
            scrollButtonRightMargin: "-4px -4px -4px 4px",
            columnGap: "4px",
            borderRadius: "8px"
        },
        [TAB_SIZES.Small]: {
            padding: "3px 4px",
            tabPadding: "2px 12px 2px 8px",
            scrollButtonLeftMargin: "-4px 4px -4px -4px",
            scrollButtonRightMargin: "-4px -4px -4px 4px",
            columnGap: "4px",
            borderRadius: "9px"
        },
        [TAB_SIZES.Normal]: {
            padding: "7px 8px",
            tabPadding: "6px 12px 6px 8px",
            scrollButtonLeftMargin: "0 4px 0 0",
            scrollButtonRightMargin: "0 0 0 4px",
            columnGap: "8px",
            borderRadius: "10px"
        },
        [TAB_SIZES.Large]: {
            padding: "7px 8px",
            tabPadding: "6px 12px 6px 8px",
            scrollButtonLeftMargin: "0 4px 0 0",
            scrollButtonRightMargin: "0 0 0 4px",
            columnGap: "8px",
            borderRadius: "12px"
        }
    };

    return styleVariants;
};
