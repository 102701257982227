/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { TimestampProps } from "./types";

import ThemeContext from "../../../../../../theme/themeContext";
import TimeZoneContext from "../../../../../../timeZone/timeZoneContext";
import LanguageContext from "../../../../../../language/languageContext";

import DateTime from "../../../../../../../components/DateTime";
import { isEnglish, LOCALES } from "../../../../../../../shared";

const Timestamp = ({ date }: TimestampProps) => {
    const {
        colorsFacelift: { textPlaceholder }
    } = useContext(ThemeContext);

    const { currentTimeZone } = useContext(TimeZoneContext);
    const { language } = useContext(LanguageContext);

    const { t } = useTranslation();

    const renderTimestamp = () => {
        const now = moment().tz(currentTimeZone);
        const startDate = moment.utc(date).tz(currentTimeZone);
        const minutesDiff = now.diff(startDate, "minutes");

        // Less than 1 minute
        if (minutesDiff < 1) {
            return t("Notifications##timestamps##moments ago");
        }

        // Range: 1 minute to 59 minutes
        if (minutesDiff < 60) {
            return t("Notifications##timestamps##minutes ago", {
                minutes: minutesDiff
            });
        }

        // Range: 1 hour to 23 hours
        if (minutesDiff < 1440) {
            const hoursDiff = Math.floor(minutesDiff / 60);
            return t("Notifications##timestamps##hours ago", {
                hours: hoursDiff
            });
        }

        // Range: 1 day to 6 days
        if (minutesDiff < 10080) {
            const daysDiff = Math.floor(minutesDiff / 1440);
            return t("Notifications##timestamps##days ago", { days: daysDiff });
        }

        // Range: 7 days and older
        const isEnglishLng = isEnglish(language);

        return (
            <DateTime
                date={date}
                format="LL"
                locale={isEnglishLng ? LOCALES.English : undefined}
            />
        );
    };

    return (
        <div
            css={css({
                color: textPlaceholder,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px"
            })}
        >
            {renderTimestamp()}
        </div>
    );
};

export default Timestamp;
