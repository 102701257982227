import { useState, useEffect, Fragment } from "react";

import unknown from "../../../assets/models/unknown.png";
import unknown2 from "../../../assets/models/unknown@2x.png";
import { API_IS_BEING_CANCELLED, useApi } from "../../../shared";

interface ModelImageProps {
    model: string;
}

const ModelImage = ({ model }: ModelImageProps) => {
    const { cancelSource, isCanceled } = useApi();

    const [modelPaths, setModelPaths] = useState({
        path1: unknown,
        path2: unknown2
    });

    useEffect(() => {
        let isSubscribed = true;

        const getModelSrc = () => {
            try {
                const src1 = require(`../../../assets/models/${model}.png`);
                const src2 = require(`../../../assets/models/${model}@2x.png`);

                if (isSubscribed) {
                    setModelPaths({
                        path1: src1,
                        path2: src2
                    });
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    setModelPaths({
                        path1: unknown,
                        path2: unknown2
                    });
                }
            }
        };

        getModelSrc();

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [model]);

    return (
        <Fragment>
            <img
                src={modelPaths.path1}
                srcSet={`${modelPaths.path2}`}
                alt={model || "Unknown model"}
            />
        </Fragment>
    );
};

export default ModelImage;
