import { useContext, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { usePapaParse } from "react-papaparse";

import UploadedFileSingle from "./UploadedFileSingle";

import Frame from "../Frame";
import { DataProps, SingleFileProps } from "../types";

import { BANNER_MESSAGE_STATUSES } from "../../DesignComponents/BannerMessage";

import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import { Message } from "../../../context/dropzone/dropzoneReducer";

const SingleFile = ({
    supportedFormats,
    isDisabled,
    maxFileNameLength,
    countCsv,
    tooltipText
}: SingleFileProps) => {
    const { t } = useTranslation();

    const { file, setFile, setMessage } = useContext(DropzoneContext);

    const { readString } = usePapaParse();

    const [isDragged, setDragged] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "text/html": supportedFormats },
        disabled: isDisabled,
        multiple: false,
        onDragEnter: () => setDragged(true),
        onDragLeave: () => setDragged(false),
        onDrop: () => setDragged(false),
        onDropAccepted: acceptedFiles => {
            const acceptedFile = acceptedFiles[0];

            const isCorrectNameLength = maxFileNameLength
                ? acceptedFile.name.length <= maxFileNameLength
                : true;

            if (countCsv) {
                const reader = new FileReader();

                reader.readAsText(acceptedFile);

                reader.onload = () => {
                    readString(reader.result as string, {
                        worker: true,
                        delimiter: ",",
                        skipEmptyLines: true,
                        complete: ({ data }: DataProps) => {
                            isCorrectNameLength && setFile(acceptedFile, data);
                        }
                    });
                };
            } else {
                isCorrectNameLength && setFile(acceptedFile);
            }

            !isCorrectNameLength &&
                setMessage(
                    {
                        status: BANNER_MESSAGE_STATUSES.Critical,
                        title: t("Dropzone##too long file name", {
                            maxLength: maxFileNameLength
                        })
                    },
                    true
                );
        },
        onDropRejected: rejected => {
            const errorCodes = rejected.reduce(
                (previous: string[], current) => {
                    const currentErrors = current.errors.map(
                        error => error.code
                    );

                    return [...previous, ...currentErrors];
                },
                []
            );

            const uniqueErrorCodes = _.uniq(errorCodes);

            const errors: Message[] = uniqueErrorCodes.map(errorCode => {
                if (errorCode === "file-invalid-type") {
                    return {
                        status: BANNER_MESSAGE_STATUSES.Critical,
                        title: t("Dropzone##file invalid type")
                    };
                } else {
                    return {
                        status: BANNER_MESSAGE_STATUSES.Critical,
                        title: t("Dropzone##only one file can be attached")
                    };
                }
            });

            setMessage(errors, true);
        }
    });

    return file ? (
        <UploadedFileSingle countCsv={countCsv} />
    ) : (
        <Frame
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragged={isDragged}
            supportedFormats={supportedFormats}
            isDisabled={isDisabled}
            tooltipText={tooltipText}
        />
    );
};

export default SingleFile;
