/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { RectangleLoader } from "./RectangleLoader";

interface TaskGroupsLoaderProps {
    height: string;
    firstWidth: string;
    secondWidth: string;
    thirdWidth?: string;
}

export const TaskGroupsLoader = ({
    height,
    firstWidth,
    secondWidth,
    thirdWidth
}: TaskGroupsLoaderProps) => {
    return (
        <div
            css={css({
                display: "flex",
                columnGap: "16px",

                "& > span": {
                    borderRadius: "46px"
                }
            })}
        >
            <span>
                <RectangleLoader width={firstWidth} height={height} />
            </span>

            <span>
                <RectangleLoader width={secondWidth} height={height} />
            </span>

            {thirdWidth && (
                <span>
                    <RectangleLoader width={thirdWidth} height={height} />
                </span>
            )}
        </div>
    );
};
