import RectangularSkeleton from "./RectangularSkeleton";

import { StatsLoaderProps } from "../types";

import { generateArrayOfNumbers } from "../../../../../../shared";

const StatsLoader = ({ count }: StatsLoaderProps) => {
    const arrayOfNumbers = generateArrayOfNumbers(count);

    return (
        <div>
            {arrayOfNumbers.map(item => (
                <RectangularSkeleton key={item} />
            ))}
        </div>
    );
};

export default StatsLoader;
