import { useContext } from "react";
import { Routes } from "react-router-dom";

import { useRoutes } from "./hooks";

import Snackbar from "../Snackbar";
import TutorialMode from "../TutorialMode";
import Navbar from "../Navbar";
import CookiesBanner from "../Privacy/Cookies/CookiesBanner";

import AuthContext from "../../context/auth/authContext";
import { useGoogleAnalytics, useHotjar, useWindowLocation } from "../../shared";

const RouterContent = () => {
    const { is2FaEnableFlowSeen, user } = useContext(AuthContext);

    useGoogleAnalytics(user);
    useHotjar();

    const { renderRoutes } = useRoutes();
    const { pathname } = useWindowLocation();

    const signUpRoute = pathname.includes("signup");
    const resetRoute = pathname.includes("reset");
    const maintenanceRoute = pathname.includes("maintenance");

    const isNotPrivateRoute = !signUpRoute && !resetRoute && !maintenanceRoute;
    const isAllowedToSeeContent = user !== null && is2FaEnableFlowSeen;
    const renderNavbar = isNotPrivateRoute && isAllowedToSeeContent;

    return (
        <>
            {isAllowedToSeeContent && <TutorialMode />}
            {renderNavbar && <Navbar />}
            <Routes>{renderRoutes()}</Routes>
            <CookiesBanner />
            <Snackbar />
        </>
    );
};

export default RouterContent;
