import { useState } from "react";
import EditIcon from "@mui/icons-material/ModeEdit";

const useDeviceGroupInlineActions = () => {
    const [rowData, setRowData] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const openDialog = (data: any) => {
        setRowData(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setRowData(null);
        setOpen(false);
    };

    const inlineActions = [
        {
            title: "edit group",
            icon: EditIcon,
            clickAction: (data: any) => openDialog(data)
        }
    ];

    return {
        inlineActions,
        rowData,
        isOpen,
        closeDialog
    };
};

export default useDeviceGroupInlineActions;
