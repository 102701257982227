/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ContentFrameWithButtonProps } from "../types";
import TableContext from "../../../../context/table/tableContext";
import { ActionBarTitleLoader, useFormattedNumber } from "../../../../shared";

const ContentFrameWithButton = ({
    children,
    button
}: ContentFrameWithButtonProps) => {
    const { tableName, totalRows, isInitialLoading } = useContext(TableContext);

    const { t } = useTranslation();
    const { renderFormattedNumber } = useFormattedNumber();

    return (
        <>
            {isInitialLoading ? (
                <ActionBarTitleLoader
                    customStyle={{
                        "@media(max-width: 599px)": {
                            marginBottom: "8px"
                        }
                    }}
                />
            ) : (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",

                        "& > button": {
                            marginLeft: "16px"
                        },

                        "@media(max-width: 599px)": {
                            justifyContent: "space-between",
                            marginBottom: "8px"
                        }
                    })}
                >
                    <h1>
                        <span>{t(`General##${tableName}`)}</span>
                        <span>({renderFormattedNumber(totalRows)})</span>
                    </h1>

                    {button}
                </div>
            )}

            <div
                css={css({
                    "& > button + button": {
                        marginLeft: "8px"
                    },

                    "@media(max-width: 599px)": {
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoColumns: "1fr",
                        gridColumnGap: "16px",

                        "& > button + button": {
                            marginLeft: "0"
                        }
                    }
                })}
            >
                {children}
            </div>
        </>
    );
};

export default ContentFrameWithButton;
