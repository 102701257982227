/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { DateValidationError, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
    DesktopDatePicker,
    DesktopDatePickerProps
} from "@mui/x-date-pickers/DesktopDatePicker";
import { ExpandMoreRounded as SwitchViewIcon } from "@mui/icons-material";

import LeftArrowButton from "./actionComponents/LeftArrowButton";
import RightArrowButton from "./actionComponents/RightArrowButton";
import TextField from "./actionComponents/TextField";
import { DatePickerProps } from "./types";

import Label from "../DesignComponents/Label";

import ThemeContext from "../../context/theme/themeContext";
import LanguageContext from "../../context/language/languageContext";
import { isEnglish, Language } from "../../shared";

const DatePicker = ({
    label,
    textFieldParams = {},
    customStyle,
    getError,
    ...props
}: DatePickerProps & DesktopDatePickerProps<Moment>) => {
    const {
        colorsFacelift: {
            blue700,
            gray300,
            gray700,
            textDark,
            textDarkDisabled,
            textPlaceholder,
            white
        }
    } = useContext(ThemeContext);

    const { languages, language } = useContext(LanguageContext);

    const { t } = useTranslation();

    const [error, setError] = useState<DateValidationError>(null);

    useEffect(() => {
        getError && getError(error);
    }, [error, getError]);

    const { momentLocale } = languages.find(
        lang => lang.id === language
    ) as Language;

    const isEnglishLng = isEnglish(language);

    const adapterLocale = isEnglishLng ? "en" : momentLocale;

    const textStyles = {
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0.15px"
    };

    return (
        <div
            css={css({
                ...customStyle
            })}
        >
            <Label titleLeft={label} isRequired={textFieldParams.required} />

            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={adapterLocale}
            >
                <DesktopDatePicker
                    {...props}
                    timezone={props.timezone || "UTC"}
                    format={isEnglishLng ? "YYYY-MM-DD" : undefined}
                    views={["year", "month", "day"]}
                    onError={reason => setError(reason)}
                    slots={{
                        switchViewIcon: SwitchViewIcon,
                        previousIconButton: LeftArrowButton,
                        nextIconButton: RightArrowButton,
                        textField: TextField
                    }}
                    slotProps={{
                        openPickerButton: {
                            sx: {
                                color: textPlaceholder
                            }
                        },
                        textField: {
                            timeZoneExplanatory:
                                textFieldParams.timeZoneExplanatory,
                            tooltipProps: textFieldParams.tooltipProps,
                            helperText: error
                                ? t(`Date picker##${error}`)
                                : error,
                            sx: {
                                "& .MuiInputAdornment-root": {
                                    "& .MuiIconButton-root": {
                                        color: "red !important"
                                    }
                                }
                            }
                        } as any,
                        layout: {
                            sx: {
                                "& .MuiDateCalendar-root": {
                                    width: "324px",
                                    height: "324px"
                                },

                                "& .MuiPickersCalendarHeader-root": {
                                    margin: "16px 0",
                                    padding: "0 24px",
                                    minHeight: "unset"
                                },

                                "& .MuiPickersCalendarHeader-label": {
                                    color: textDark,
                                    marginRight: "4px",
                                    textTransform: " capitalize",
                                    ...textStyles
                                },

                                // Fixes library bug when changing month
                                "& .MuiPickersCalendarHeader-label:nth-of-type(2)":
                                    {
                                        position: "absolute",
                                        top: "0",
                                        left: "0"
                                    },

                                "& .MuiPickersCalendarHeader-switchViewButton":
                                    {
                                        padding: "4px",
                                        svg: {
                                            fontSize: "16px",
                                            color: textDark
                                        }
                                    },

                                "& .MuiDayCalendar-header": {
                                    marginBottom: "4px"
                                },

                                "& .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton":
                                    {
                                        color: gray700,
                                        height: "32px",
                                        margin: "4px 0",
                                        width: "calc(100% - 8px)",
                                        ...textStyles,

                                        "&.Mui-selected": {
                                            color: white,
                                            background: blue700
                                        },
                                        "&.Mui-selected:focus": {
                                            background: blue700
                                        }
                                    },

                                "& .MuiYearCalendar-root, .MuiMonthCalendar-root":
                                    {
                                        width: "unset",
                                        marginTop: "-4px",
                                        padding: "0 8px 12px 8px"
                                    },

                                "& .MuiYearCalendar-root": {
                                    maxHeight: "252px"
                                },

                                "& .MuiDayCalendar-weekDayLabel": {
                                    color: gray700,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    width: "44px",
                                    height: "unset",
                                    margin: "0 0 4px 0"
                                },

                                "& .MuiDayCalendar-weekContainer": {
                                    margin: "4px 0"
                                },

                                "& .MuiPickersDay-root": {
                                    color: textDark,
                                    fontSize: "16px",
                                    width: "32px",
                                    height: "32px",
                                    margin: "0 6px",
                                    ...textStyles,

                                    "&.Mui-selected": {
                                        background: blue700,
                                        color: white,
                                        fontWeight: "600"
                                    },

                                    "&.Mui-selected:focus": {
                                        background: blue700
                                    },

                                    "&.Mui-disabled:not(.Mui-selected)": {
                                        color: textDarkDisabled,
                                        textDecoration: "line-through"
                                    }
                                },

                                "& .MuiPickersDay-root:focus": {
                                    background: white
                                },

                                "& .MuiPickersDay-root:not(.Mui-selected)": {
                                    borderColor: gray300
                                }
                            }
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePicker;
export { useDatePicker } from "./hooks";
