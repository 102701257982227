import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ProductCodesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M5 11H9C10.1 11 11 10.1 11 9V5C11 3.9 10.1 3 9 3H5C3.9 3 3 3.9 3 5V9C3 10.1 3.9 11 5 11ZM5 5H9V9H5V5Z"
                fill="currentColor"
            />

            <path
                d="M5 21H9C10.1 21 11 20.1 11 19V15C11 13.9 10.1 13 9 13H5C3.9 13 3 13.9 3 15V19C3 20.1 3.9 21 5 21ZM5 15H9V19H5V15Z"
                fill="currentColor"
            />

            <path
                d="M13 5V9C13 10.1 13.9 11 15 11H19C20.1 11 21 10.1 21 9V5C21 3.9 20.1 3 19 3H15C13.9 3 13 3.9 13 5ZM19 9H15V5H19V9Z"
                fill="currentColor"
            />

            <path
                d="M21 20.5V19.5C21 19.22 20.78 19 20.5 19H19.5C19.22 19 19 19.22 19 19.5V20.5C19 20.78 19.22 21 19.5 21H20.5C20.78 21 21 20.78 21 20.5Z"
                fill="currentColor"
            />

            <path
                d="M13 13.5V14.5C13 14.78 13.22 15 13.5 15H14.5C14.78 15 15 14.78 15 14.5V13.5C15 13.22 14.78 13 14.5 13H13.5C13.22 13 13 13.22 13 13.5Z"
                fill="currentColor"
            />

            <path
                d="M16.5 15H15.5C15.22 15 15 15.22 15 15.5V16.5C15 16.78 15.22 17 15.5 17H16.5C16.78 17 17 16.78 17 16.5V15.5C17 15.22 16.78 15 16.5 15Z"
                fill="currentColor"
            />

            <path
                d="M13 17.5V18.5C13 18.78 13.22 19 13.5 19H14.5C14.78 19 15 18.78 15 18.5V17.5C15 17.22 14.78 17 14.5 17H13.5C13.22 17 13 17.22 13 17.5Z"
                fill="currentColor"
            />

            <path
                d="M15.5 21H16.5C16.78 21 17 20.78 17 20.5V19.5C17 19.22 16.78 19 16.5 19H15.5C15.22 19 15 19.22 15 19.5V20.5C15 20.78 15.22 21 15.5 21Z"
                fill="currentColor"
            />

            <path
                d="M17.5 19H18.5C18.78 19 19 18.78 19 18.5V17.5C19 17.22 18.78 17 18.5 17H17.5C17.22 17 17 17.22 17 17.5V18.5C17 18.78 17.22 19 17.5 19Z"
                fill="currentColor"
            />

            <path
                d="M18.5 13H17.5C17.22 13 17 13.22 17 13.5V14.5C17 14.78 17.22 15 17.5 15H18.5C18.78 15 19 14.78 19 14.5V13.5C19 13.22 18.78 13 18.5 13Z"
                fill="currentColor"
            />

            <path
                d="M19.5 17H20.5C20.78 17 21 16.78 21 16.5V15.5C21 15.22 20.78 15 20.5 15H19.5C19.22 15 19 15.22 19 15.5V16.5C19 16.78 19.22 17 19.5 17Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ProductCodesIcon;
