/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import Token from "./Token";

import ThemeContext from "../../../../../../context/theme/themeContext";
import NoInitialResultsIcon from "../../../../../../assets/customIcons/emptyTable/TableIsEmptyIcon";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    useApi
} from "../../../../../../shared";

const TokenList = () => {
    const {
        colorsFacelift: { gray700, textDark }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { getData, cancelSource, isCanceled, handleResponse } = useApi();

    const [isLoading, setLoading] = useState(true);
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        const getTokens = async () => {
            try {
                const { data } = await getData(ENDPOINTS.Tokens);

                if (isSubscribed) {
                    setTokens(data);
                }
            } catch (error) {
                if (isSubscribed && !isCanceled(error)) {
                    handleResponse(error);
                }
            }

            setLoading(false);
        };

        getTokens();

        return () => {
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, []);

    const removeToken = (id: number) =>
        setTokens(prevTokens =>
            prevTokens.filter((token: any) => token.id !== id)
        );

    const renderContent = () => {
        if (isLoading) {
            return (
                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "92px"
                    })}
                >
                    <CircularProgress />
                </div>
            );
        }

        return tokens.length > 0 ? (
            <div
                css={css({
                    transition: "opacity 300ms ease-out"
                })}
            >
                {tokens.map((token: any, i: number) => (
                    <Token
                        key={token.id}
                        data={token}
                        isFirst={i === 0}
                        isLast={tokens.length === i + 1}
                        removeFromList={removeToken}
                    />
                ))}
            </div>
        ) : (
            <div
                css={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "16px",

                    "& > svg": {
                        fontSize: "120px"
                    }
                })}
            >
                <NoInitialResultsIcon />

                <div
                    css={css({
                        color: textDark,
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.15px",
                        margin: "16px 0 8px 0"
                    })}
                >
                    {t("Api tokens##no tokens")}
                </div>

                <div
                    css={css({
                        color: gray700,
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px"
                    })}
                >
                    {t("Api tokens##no tokens description")}
                </div>
            </div>
        );
    };

    return renderContent();
};

export default TokenList;
