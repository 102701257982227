/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import {
    DateValidationError,
    PickerChangeHandlerContext
} from "@mui/x-date-pickers";

import { CREATE_VALUE_NAMES, Value } from "./types";

import { useLicenseValidation } from "../hooks";

import DialogActions from "../../../components/DialogActions";

import { DialogProps, LICENSE_ACTIONS } from "../../../../types";

import Dialog from "../../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../../DesignComponents/InputField";
import CompanyDropdown from "../../../../../QueryDropdown/components/CompanyDropdown";
import Autocomplete from "../../../../../DesignComponents/Autocomplete";
import DatePicker, { useDatePicker } from "../../../../../DatePicker";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../../context/params/paramsContext";
import AuthContext from "../../../../../../context/auth/authContext";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    Query,
    TABLE_NAMES,
    disableDates,
    isInternalTypeCompany,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../shared";

const CreateDesktopLicense = ({ isOpen, close }: DialogProps) => {
    const { setAlert } = useContext(AlertContext);
    const { rootCompanyType, setReloadItems } = useContext(ParamsContext);
    const { isSystemUser } = useContext(AuthContext);

    const isInternalType = isInternalTypeCompany(rootCompanyType);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { parseDatePickerValue } = useDatePicker();
    const { postData, handleResponse } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [hasExpiresAtError, setHasExpiresAtError] = useState(false);

    const [value, setValue] = useState<Value>({
        [CREATE_VALUE_NAMES.Name]: "",
        [CREATE_VALUE_NAMES.Company]: null,
        [CREATE_VALUE_NAMES.HardwareKey]: "",
        [CREATE_VALUE_NAMES.ImeiRestricted]: null,
        [CREATE_VALUE_NAMES.ExpiresAt]: null,
        [CREATE_VALUE_NAMES.ClientIp]: ""
    });

    const {
        name,
        company,
        hardware_key,
        imei_restricted,
        expires_at,
        client_ip
    } = value;

    const {
        nameError,
        hardwareKeyError,
        ipv4Error,
        isValidHardwareKey,
        isValidIpv4,
        validateHardwareKeyOrIpv4,
        resetError
    } = useLicenseValidation(name);

    const handleCloseDialog = () => close(LICENSE_ACTIONS.CreateDesktopLicense);

    const handleCompanyDropdownChange = (newValue: Query | null) =>
        setValue(prevValue => ({
            ...prevValue,
            [CREATE_VALUE_NAMES.Company]: newValue
        }));

    const handleImeiRestrictionDropdownChange = (newValue: boolean | null) =>
        setValue(prevValue => ({
            ...prevValue,
            [CREATE_VALUE_NAMES.ImeiRestricted]: newValue
        }));

    const handleExpiresAtChange = (
        dateValue: Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>
    ) => {
        const newValue = parseDatePickerValue(dateValue, context, expires_at);

        setValue(prevValue => ({
            ...prevValue,
            [CREATE_VALUE_NAMES.ExpiresAt]: newValue
        }));
    };

    const handleExpiresAtError = (error: string | null) =>
        setHasExpiresAtError(error !== null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const newValue = e.target.value;
        const isHardwareKeyInput = name === CREATE_VALUE_NAMES.HardwareKey;
        const isIpInput = name === CREATE_VALUE_NAMES.ClientIp;

        resetError(isHardwareKeyInput, isIpInput);
        setValue(prevValue => ({ ...prevValue, [name]: newValue }));
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const getImeiRestrictedValue = () => {
                if (isInternalType) {
                    return false;
                }

                return isSystemUser ? imei_restricted! : true;
            };

            const payload = {
                name,
                company_id: company?.id,
                hardware_key,
                expires_at,
                imei_restricted: getImeiRestrictedValue(),
                ...(client_ip ? { client_ip } : {})
            };

            const {
                data: { name: licenseName }
            } = await postData(ENDPOINTS.DesktopLicenses, payload);

            handleCloseDialog();
            setReloadItems(TABLE_NAMES.DesktopLicenses);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##desktop license added", {
                    name: licenseName
                })
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    const hasImeiRestrictionInputField = !isInternalType && isSystemUser;

    const isReady =
        name.trim() &&
        !nameError &&
        company !== null &&
        isValidHardwareKey(hardware_key) &&
        (!hasImeiRestrictionInputField || imei_restricted !== null) &&
        expires_at !== null &&
        !hasExpiresAtError &&
        isValidIpv4(client_ip);

    return (
        <Dialog
            title={t("Dialog##create desktop license")}
            description={t("Dialog##create desktop license description")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    resetError(true, true);

                    setValue({
                        [CREATE_VALUE_NAMES.Name]: "",
                        [CREATE_VALUE_NAMES.Company]: null,
                        [CREATE_VALUE_NAMES.HardwareKey]: "",
                        [CREATE_VALUE_NAMES.ImeiRestricted]: null,
                        [CREATE_VALUE_NAMES.ExpiresAt]: null,
                        [CREATE_VALUE_NAMES.ClientIp]: ""
                    });
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={isReady}
                    confirmationButtonText={t("Button##create")}
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            <div
                css={css({
                    "& > div + div": {
                        marginTop: "16px"
                    },

                    marginBottom: "16px"
                })}
            >
                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    required
                    onChange={handleInputChange}
                    labelLeft={t("Dialog##name")}
                    placeholder={`${t("Dialog##example")} Desktop license 1`}
                    name={CREATE_VALUE_NAMES.Name}
                    errorText={nameError}
                />

                <CompanyDropdown
                    isRequired
                    isRootRequired
                    companyValue={company}
                    onChange={(_, val) => handleCompanyDropdownChange(val)}
                />

                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    required
                    onChange={handleInputChange}
                    labelLeft={t("General##hardware key")}
                    placeholder={t("Dialog##enter")}
                    name={CREATE_VALUE_NAMES.HardwareKey}
                    onBlur={() =>
                        validateHardwareKeyOrIpv4(
                            CREATE_VALUE_NAMES.HardwareKey,
                            hardware_key
                        )
                    }
                    errorText={hardwareKeyError}
                />

                {hasImeiRestrictionInputField && (
                    <Autocomplete
                        labelLeft={t("Dialog##imei restriction")}
                        textFieldParams={{ placeholder: t("Dialog##select") }}
                        fullWidth
                        isRequired
                        size={DROPDOWN_SIZES.Medium}
                        options={[false, true]}
                        getOptionLabel={option =>
                            t(
                                `General##${option ? "restricted" : "unrestricted"}`
                            )
                        }
                        onChange={(_, val) =>
                            handleImeiRestrictionDropdownChange(val)
                        }
                    />
                )}

                <DatePicker
                    label={t("Dialog##expires at")}
                    disablePast
                    value={expires_at ? moment.utc(expires_at) : null}
                    shouldDisableDate={day => disableDates(day, 2, "years")}
                    onChange={handleExpiresAtChange}
                    getError={handleExpiresAtError}
                    textFieldParams={{
                        required: true
                    }}
                />

                <InputField
                    size={INPUT_FIELD_SIZES.Medium}
                    fullWidth
                    onChange={handleInputChange}
                    labelLeft={`${t("General##client ip")} (${t("Dialog##optional")})`}
                    placeholder={t("Dialog##enter")}
                    name={CREATE_VALUE_NAMES.ClientIp}
                    onBlur={() =>
                        validateHardwareKeyOrIpv4(
                            CREATE_VALUE_NAMES.ClientIp,
                            client_ip
                        )
                    }
                    errorText={ipv4Error}
                />
            </div>
        </Dialog>
    );
};

export default CreateDesktopLicense;
