/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddDeviceProps } from "../types";

import CompanyAndGroupDropdowns from "../../components/CompanyAndGroupDropdowns";
import { DevicePayloadProps, FILE_NAME } from "../../types";

import SampleTemplate from "../../../../../DesignComponents/SampleTemplate";
import Dropzone from "../../../../../Dropzone";

import DropzoneContext from "../../../../../../context/dropzone/dropzoneContext";
import { ENDPOINTS, FILE_NAME_MAX_LENGTH } from "../../../../../../shared";

const AddDeviceFromFile = ({ setDevicePayload }: AddDeviceProps) => {
    const { file, lineCount } = useContext(DropzoneContext);

    const { t } = useTranslation();

    const [contentDropdownInfo, setContentDropdownInfo] =
        useState<DevicePayloadProps | null>(null);

    useEffect(() => {
        const isReady = contentDropdownInfo && file && lineCount > 0;

        if (isReady) {
            const data = {
                resource: `${ENDPOINTS.Devices}/import`,
                payload: getFormData()
            };

            setDevicePayload(data);
        } else {
            setDevicePayload(null);
        }
        // eslint-disable-next-line
    }, [contentDropdownInfo, file]);

    const getFormData = useCallback(() => {
        const formData = new FormData();

        if (contentDropdownInfo) {
            formData.append("file", file as Blob);
            formData.append("company_id", String(contentDropdownInfo.company));

            contentDropdownInfo?.group &&
                formData.append("group_id", String(contentDropdownInfo.group));
        }

        return formData;
    }, [file, contentDropdownInfo]);

    const handleDropdowns = (data: DevicePayloadProps | null) =>
        setContentDropdownInfo(data);

    return (
        <div
            css={css({
                marginTop: "16px",
                marginBottom: "64px",

                "> div:nth-of-type(2)": {
                    marginTop: file === null ? 0 : "45px"
                }
            })}
        >
            <Dropzone
                supportedFormats={[".csv"]}
                countCsv
                maxFileNameLength={FILE_NAME_MAX_LENGTH}
                tooltipText={`
                    ${t("Dropzone##file syntax")} 
                    ${t("Dialog##imei")}*, 
                    ${t("Dialog##sn")}*, 
                    ${t("Dialog##description")}
                `}
            />

            <SampleTemplate fileName={FILE_NAME.SampleAddDeviceList} />

            <CompanyAndGroupDropdowns
                isCompanyRequired
                isRootRequired
                setPayload={handleDropdowns}
            />
        </div>
    );
};
export default AddDeviceFromFile;
