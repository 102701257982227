/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { ShowAllButtonProps } from "./types";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../../components/DesignComponents/TextLink";

const ShowAllButton = ({ showAll }: ShowAllButtonProps) => {
    const { t } = useTranslation();

    return (
        <li
            css={css({
                display: "flex",
                padding: "7px 16px 16px 16px"
            })}
        >
            <TextLink
                size={TEXT_LINK_SIZES.Small}
                onClick={() => showAll(true)}
            >
                {t("Notifications##see previous notifications")}
            </TextLink>
        </li>
    );
};

export default ShowAllButton;
