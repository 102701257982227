import { useMediaQueries } from "../../../../../shared";

export const useFilterButton = (customLoadingWidth: number | undefined) => {
    const { toSm, toLg, to1099, fromMd } = useMediaQueries();

    const isIconOnly = fromMd && to1099;

    const getLeftMargin = () => {
        if (toSm) {
            return "24px";
        }

        if (isIconOnly) {
            return "6px";
        }

        if (toLg) {
            return "8px";
        }

        return "24px";
    };

    const getRightMargin = () => {
        if (toSm) {
            return "12px";
        }

        if (isIconOnly) {
            return "6px";
        }

        if (toLg) {
            return "12px";
        }

        return "0";
    };

    const getRectangularWidth = () => {
        if (customLoadingWidth !== undefined) {
            return customLoadingWidth;
        }

        if (isIconOnly) {
            return 24;
        }

        return toLg ? 75 : 112;
    };

    return { isIconOnly, getLeftMargin, getRightMargin, getRectangularWidth };
};
