import { useState, useEffect, useContext } from "react";

import { getInitialCustomData, sortCustomDataRows } from "./functions";
import { Sorting } from "./types";

import TableContext from "../../../../context/table/tableContext";
import {
    NOT_FILTER_NAMES,
    ORDERS,
    QueryParams,
    SORT_RESOURCES
} from "../../../../shared";

export const useSorting = (
    queryParams: QueryParams,
    setParams: (data: { params: QueryParams }) => void
) => {
    const { customData, idType, rows, sortFromNewest, setTableData } =
        useContext(TableContext);

    const [sorting, setSorting] = useState<Sorting>({
        [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
        [NOT_FILTER_NAMES.Sort]: ""
    });

    const { order, sort } = sorting;

    // Regular data sorting
    useEffect(() => {
        const sortingKeys = Object.keys(queryParams).filter(
            queryKey =>
                queryKey === NOT_FILTER_NAMES.Order ||
                queryKey === NOT_FILTER_NAMES.Sort
        );

        if (sortingKeys.length > 0) {
            const sortingParams = sortingKeys.reduce((previous, current) => {
                const singleObj = {
                    [current]:
                        queryParams[
                            current as
                                | NOT_FILTER_NAMES.Order
                                | NOT_FILTER_NAMES.Sort
                        ]
                };

                return { ...previous, ...singleObj };
            }, sorting);

            setSorting(sortingParams);
        } else {
            setSorting({
                [NOT_FILTER_NAMES.Order]: ORDERS.Descending,
                [NOT_FILTER_NAMES.Sort]: ""
            });
        }

        // eslint-disable-next-line
    }, [queryParams]);

    // Custom data sorting
    useEffect(() => {
        if (customData) {
            const initialCustomData = getInitialCustomData(
                sortFromNewest,
                customData,
                idType
            );

            const customDataRows = [...rows];

            sortCustomDataRows(customDataRows, sort, order);

            const sortedRows = sort === "" ? initialCustomData : customDataRows;

            setTableData({ rows: sortedRows });
        }

        // eslint-disable-next-line
    }, [customData, sort, order]);

    const sortColumn = (resource: SORT_RESOURCES) => {
        const isDesc = order === ORDERS.Descending;

        const getOrder = () => {
            if (sort === resource) {
                return isDesc ? ORDERS.Ascending : ORDERS.Descending;
            }

            return ORDERS.Descending;
        };

        const getSort = () => {
            if (!isDesc && sort === resource) {
                return "";
            }

            return resource;
        };

        const newOrder = getOrder();
        const newSort = getSort();

        if (customData) {
            setSorting({
                [NOT_FILTER_NAMES.Order]: newOrder,
                [NOT_FILTER_NAMES.Sort]: newSort
            });
        } else {
            const isNewSortEmpty = newSort === "";

            setParams({
                params: {
                    [NOT_FILTER_NAMES.Sort]: isNewSortEmpty ? "" : newSort,
                    [NOT_FILTER_NAMES.Order]: isNewSortEmpty ? "" : newOrder
                }
            });
        }
    };

    return { order, sort, sortColumn };
};
