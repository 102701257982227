/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { SingleDeviceStatsListItemProps } from "./types";

import { deviceStatsValues } from "../constants";

import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

import ThemeContext from "../../../../context/theme/themeContext";
import { useFormattedNumber, useOverflow } from "../../../../shared";

export const SingleDeviceStatsListItem = ({
    count,
    name,
    isLoading,
    itemMargins
}: SingleDeviceStatsListItemProps) => {
    const {
        colorsFacelift: {
            blue700,
            gray400,
            green600,
            red600,
            textDark,
            textPlaceholder
        }
    } = useContext(ThemeContext);

    const { formatedNumber } = useFormattedNumber(count);
    const { measuredRef, isOverflowing } = useOverflow();

    const color = (nameTitle: string) => {
        switch (nameTitle) {
            case deviceStatsValues.online:
                return green600;
            case deviceStatsValues.offline:
                return red600;
            case deviceStatsValues.inactive:
                return gray400;
            case deviceStatsValues.other:
                return gray400;
            case deviceStatsValues.unknown:
                return gray400;
            default:
                return blue700;
        }
    };

    return (
        <div
            css={css({
                margin: itemMargins,
                display: "flex",
                alignItems: "center",
                opacity: isLoading ? "0.4" : 1
            })}
        >
            <div
                css={css({
                    width: "2px",
                    height: "28px",
                    marginRight: "8px",
                    backgroundColor: color(name),
                    borderRadius: "1px",
                    alignSelf: "center",
                    flexShrink: "0"
                })}
            ></div>

            <div css={css({ overflowX: "hidden", flex: 1 })}>
                <div
                    css={css({
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        color: textDark
                    })}
                >
                    {formatedNumber}
                </div>

                <TooltipGeneral
                    title={name}
                    disableHoverListener={!isOverflowing}
                    disableTouchListener={!isOverflowing}
                    placement={TOOLTIP_PLACEMENT.Top}
                >
                    <div
                        ref={measuredRef}
                        css={css({
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: textPlaceholder,
                            overflow: "hidden",
                            width: "100%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            flex: 1
                        })}
                    >
                        {name}
                    </div>
                </TooltipGeneral>
            </div>
        </div>
    );
};
