import { Fragment, useCallback, useState } from "react";

import Header from "./Header";
import Tasks from "./Tasks";

export interface DataFromTasksTable {
    tasksRows: any;
    isTableLoading: boolean;
    totalRows: number;
}

const TaskGroupDetails = () => {
    const [taskRowsData, setTaskRowsData] = useState<DataFromTasksTable>({
        tasksRows: [],
        isTableLoading: true,
        totalRows: 0
    });

    const getTasksInfo = useCallback(
        (rows: any, isTableLoading: boolean, totalRows: number) => {
            setTaskRowsData({
                tasksRows: rows,
                isTableLoading,
                totalRows
            });
        },
        []
    );

    return (
        <Fragment>
            <Header dataFromTables={taskRowsData} />
            <Tasks getTasksRows={getTasksInfo} />
        </Fragment>
    );
};

export default TaskGroupDetails;
