import { useContext } from "react";

import { TEXT_LINK_COLORS, TEXT_LINK_SIZES } from "../types";
import ThemeContext from "../../../../context/theme/themeContext";

export const useStyleOptions = (color: TEXT_LINK_COLORS) => {
    const {
        colorsFacelift: { blue700, blue800, blue900, red900, red800 }
    } = useContext(ThemeContext);

    const styleVariants = {
        color: {
            default: color === TEXT_LINK_COLORS.Primary ? blue700 : red800,
            hover: color === TEXT_LINK_COLORS.Primary ? blue800 : red800,
            active: color === TEXT_LINK_COLORS.Primary ? blue900 : red900
        },
        fontSize: {
            [TEXT_LINK_SIZES.Small]: "12px",
            [TEXT_LINK_SIZES.Normal]: "14px",
            [TEXT_LINK_SIZES.Large]: "16px"
        },
        lineHeight: {
            [TEXT_LINK_SIZES.Small]: "16px",
            [TEXT_LINK_SIZES.Normal]: "20px",
            [TEXT_LINK_SIZES.Large]: "20px"
        },
        letterSpacing: {
            [TEXT_LINK_SIZES.Small]: "0.4px",
            [TEXT_LINK_SIZES.Normal]: "0.1px",
            [TEXT_LINK_SIZES.Large]: "0.15px"
        }
    };

    return styleVariants;
};
