import { useContext, useEffect, useState } from "react";

import useStatisticsStringParams from "./useStatisticsStringParams";

import RoutesContext from "../../../context/routes/routesContext";
import {
    API_IS_BEING_CANCELLED,
    DEVICE_STATISTICS,
    ENDPOINTS,
    StatisticsData,
    useApi,
    useIsMounted
} from "../../../shared";

export const useGetDeviceStatistics = (
    statQueryParam: DEVICE_STATISTICS,
    isIncludeParams?: boolean,
    fromDashboard?: boolean
) => {
    const { isStatisticsOpen, closeStatistics } = useContext(RoutesContext);

    const { queryStats, handleResponse, isCanceled, cancelSource } = useApi();
    const isMounted = useIsMounted();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [statItem, setStatItem] = useState<any>(null);

    const statisticsStringParams = useStatisticsStringParams();

    const getStringParams = () => {
        if (isIncludeParams) {
            return statisticsStringParams;
        }
        return "";
    };

    const setUpStatisticsItems = (data: Object) => {
        const total = Object.values(data).reduce(
            (sum, { count }) => sum + count,
            0
        );

        if (total !== 0) {
            const initialArray: [string, StatisticsData][] =
                Object.entries(data);

            const sortedArray = [...initialArray].sort(
                ([, aValue], [, bValue]) => bValue.count - aValue.count
            );

            setStatItem({ stats: sortedArray, total });
            setIsLoading(false);
        } else {
            setStatItem(null);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleCatcherror = (error: any) => {
            if (!isCanceled(error)) {
                handleResponse(error);
                setIsLoading(false);
                closeStatistics();
            }
        };

        const handleDataRetrieval = async () => {
            try {
                setIsLoading(true);

                const { data } = await queryStats(
                    `${ENDPOINTS.Devices}/stats?field=${statQueryParam}`,
                    getStringParams()
                );

                setUpStatisticsItems(data);
            } catch (error) {
                handleCatcherror(error);
            }
        };

        const getStats = () => {
            if (isMounted) {
                handleDataRetrieval();
            }
        };

        if (statQueryParam.length > 0) {
            (isStatisticsOpen || fromDashboard) && getStats();
        } else {
            setIsLoading(false);
        }

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };

        // eslint-disable-next-line
    }, [isStatisticsOpen, statisticsStringParams]);

    return { isLoading, statItem };
};
