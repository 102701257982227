/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useFilteredValues } from "../hooks";

import Tag, { TAG_COLORS, TAG_SIZES } from "../../DesignComponents/Tag";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import FilteredValuesLoader from "../../Loaders/FilteredValuesLoader";

import ThemeContext from "../../../context/theme/themeContext";
import {
    FILTER_NAMES,
    isTranslatableFilter,
    NOT_ASSIGNED
} from "../../../shared";

const FilteredValues = () => {
    const {
        colorsFacelift: { gray200, textDark }
    } = useContext(ThemeContext);

    const { isLoading, filteredValues, handleDelete, handleClearAll } =
        useFilteredValues();

    const { t } = useTranslation();

    const getTitle = (
        name: string,
        isTranslatable: boolean,
        filterName: FILTER_NAMES
    ) => {
        const isNotAssigned = name === NOT_ASSIGNED;

        if (isNotAssigned) {
            return t(`General##not assigned ${filterName}`);
        }

        if (isTranslatable) {
            return t(`General##${name}`);
        }

        return name;
    };

    const renderContent = () => {
        if (isLoading) {
            return <FilteredValuesLoader />;
        }

        return filteredValues.length > 0 ? (
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    borderTop: `1px solid ${gray200}`,
                    padding: "7px 24px 8px 24px"
                })}
            >
                <div
                    css={css({
                        color: textDark,
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "36px",
                        letterSpacing: "0.1px",
                        alignSelf: "flex-start",
                        whiteSpace: "nowrap",
                        marginRight: "16px"
                    })}
                >
                    {`${t("Filter##filtered by")}:`}
                </div>

                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        columnGap: "6px",
                        rowGap: "6px"
                    })}
                >
                    {filteredValues.map(({ filterName, value }) => {
                        const isTranslatable = isTranslatableFilter(filterName);

                        return value.map(({ id, name }) => {
                            const title = getTitle(
                                name,
                                isTranslatable,
                                filterName
                            );

                            return (
                                <Tag
                                    key={`${id}_${name}`}
                                    size={TAG_SIZES.Medium}
                                    color={TAG_COLORS.Primary}
                                    title={title}
                                    onDelete={() =>
                                        handleDelete(filterName, id)
                                    }
                                />
                            );
                        });
                    })}

                    <Button
                        color={BUTTON_COLORS.White}
                        size={BUTTON_SIZES.Small}
                        variant={BUTTON_VARIANTS.TextOnly}
                        onClick={handleClearAll}
                    >
                        {t("Button##clear all")}
                    </Button>
                </div>
            </div>
        ) : null;
    };

    return renderContent();
};

export default FilteredValues;
