import { useState } from "react";
import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";

import { useDialog } from "../../ActionBar/hooks";
import { LICENSE_ACTIONS } from "../../types";

import { hasLicenseExpired } from "../../../TableDetails/LicenseDetails/functions";

import FileDownloadRoundedIcon from "../../../../assets/customIcons/actions/FileDownloadRoundedIcon";
import { downloadFile, ENDPOINTS, useApi } from "../../../../shared";

const useOfflineLicenseInlineActions = () => {
    const { isOpenDialog, closeDialog, openDialog } = useDialog();
    const { getData, handleResponse } = useApi();

    const [rowData, setRowData] = useState(null);

    const openDeleteLicenseDialog = (data: any) => {
        setRowData(data);
        openDialog(LICENSE_ACTIONS.DeleteOfflineLicense);
    };

    const inlineActions = [
        {
            title: "download offline license",
            hasLoader: true,
            icon: FileDownloadRoundedIcon,
            getDisabledText: (data: any) =>
                hasLicenseExpired(data.expires_at)
                    ? "license has expired"
                    : undefined,
            clickAction: (data: any, removeLoading: () => void) =>
                downloadFile({
                    data,
                    getData,
                    handleResponse,
                    customEndpoint: `${ENDPOINTS.OfflineLicenses}/${data.id}/download`,
                    removeLoading
                })
        },
        {
            title: "delete offline license",
            icon: DeleteOutlineRounded,
            clickAction: (data: any) => openDeleteLicenseDialog(data)
        }
    ];

    return {
        inlineActions,
        rowData,
        isOpen: isOpenDialog[LICENSE_ACTIONS.DeleteOfflineLicense],
        closeDialog: () => closeDialog(LICENSE_ACTIONS.DeleteOfflineLicense)
    };
};

export default useOfflineLicenseInlineActions;
