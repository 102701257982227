/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment, useContext } from "react";

import LinkItem from "./LinkItem";
import TctWeb from "./TctWeb";

import Separator from "../../DesignComponents/Separator";
import { useRoutes } from "../../Routes/hooks";

import ThemeContext from "../../../context/theme/themeContext";

const Navigation = () => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { routes } = useRoutes();

    return (
        <nav>
            <ul
                css={css({
                    "li + li": {
                        marginTop: "8px"
                    }
                })}
            >
                {routes.map(({ navigation, isRestricted, path }) => {
                    if (navigation && !isRestricted) {
                        const { name, icon, hasSeparator } = navigation;

                        return (
                            <Fragment key={name}>
                                {hasSeparator && (
                                    <Separator
                                        isListSeparator
                                        css={css({
                                            background: gray200
                                        })}
                                    />
                                )}

                                <LinkItem name={name} icon={icon} link={path} />
                            </Fragment>
                        );
                    }

                    return null;
                })}

                <Separator
                    isListSeparator
                    css={css({
                        background: gray200
                    })}
                />

                <TctWeb />
            </ul>
        </nav>
    );
};

export default Navigation;
