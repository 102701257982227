import { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { SetContent } from "./index";

import ListChoice from "../../DesignComponents/ListChoice";
import TableContext from "../../../context/table/tableContext";

interface InitialContentProps {
    setContent: SetContent;
}

const InitialContent = ({ setContent }: InitialContentProps) => {
    const { columns } = useContext(TableContext);

    const { t } = useTranslation();

    return (
        <Fragment>
            {columns.length > 0 && (
                <ListChoice
                    title={t("Button##select columns to show")}
                    iconRight={<ChevronRightRoundedIcon />}
                    onClick={() => setContent("columns")}
                />
            )}

            <ListChoice
                title={t("Button##rows count per page")}
                iconRight={<ChevronRightRoundedIcon />}
                onClick={() => setContent("rows")}
            />
        </Fragment>
    );
};

export default InitialContent;
