/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import CompanyFilterContent from "./CompanyFilterContent";
import { CompanyFilterDesktopProps } from "./types";

import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../DesignComponents/Menu";

import TutorialContext from "../../../context/tutorial/tutorialContext";

const FilterDesktop = ({
    anchorEl,
    isLoading,
    close
}: CompanyFilterDesktopProps) => {
    const { isTutorialActive, setTutorialOpen } = useContext(TutorialContext);

    return (
        <Menu
            disableEscapeKeyDown={isTutorialActive}
            open={Boolean(anchorEl)}
            onClose={close}
            anchorEl={anchorEl}
            anchorOrigin={centeredAnchorOrigin}
            transformOrigin={centeredTransformOrigin}
            slotProps={{
                paper: {
                    id: "companyFilterMenu"
                }
            }}
            TransitionProps={{
                onEntered: () => isTutorialActive && setTutorialOpen(true)
            }}
            css={css({
                "& .MuiMenu-paper": {
                    width: "564px"
                }
            })}
        >
            <li>
                <CompanyFilterContent isLoading={isLoading} close={close} />
            </li>
        </Menu>
    );
};

export default FilterDesktop;
