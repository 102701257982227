/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { forwardRef, useContext } from "react";

import { ListSectionTitleProps, Ref } from "../../types";
import ThemeContext from "../../../../../context/theme/themeContext";

const ListSectionTitle = forwardRef<Ref, ListSectionTitleProps>(
    ({ children }, ref) => {
        const {
            colorsFacelift: { textPlaceholder }
        } = useContext(ThemeContext);

        return (
            <div
                ref={ref}
                css={css({
                    color: textPlaceholder,
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    padding: "12px 16px 4px 16px"
                })}
            >
                {children}
            </div>
        );
    }
);

export default ListSectionTitle;
