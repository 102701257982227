import { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/ModeEdit";

import AuthContext from "../../../../context/auth/authContext";

const useProductCodeInlineActions = () => {
    const { isAdminManagerUser } = useContext(AuthContext);

    const [rowData, setRowData] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const openDialog = (data: any) => {
        setRowData(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setRowData(null);
        setOpen(false);
    };

    const inlineActions = [
        {
            title: "edit product code",
            icon: EditIcon,
            clickAction: (data: any) => openDialog(data),
            renderConditions: () => isAdminManagerUser
        }
    ];

    return { inlineActions, rowData, isOpen, closeDialog };
};

export default useProductCodeInlineActions;
