/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Trans } from "react-i18next";

import { TermsAndConditionsProps } from "./types";

import Checkbox from "../../DesignComponents/Checkbox";
import TextLink, { TEXT_LINK_SIZES } from "../../DesignComponents/TextLink";

import ThemeContext from "../../../context/theme/themeContext";
import LanguageContext from "../../../context/language/languageContext";
import { LANGUAGES } from "../../../shared";

const TermsAndConditions = ({ toggleValue }: TermsAndConditionsProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { language } = useContext(LanguageContext);

    const isLithuanian = language === LANGUAGES.Lithuanian;

    const translatedLinkPart = isLithuanian
        ? "lt/apie-mus/politika-ir-sertifikatai"
        : "about-us/policies-certificates";

    return (
        <div
            css={css({
                borderTop: `1px solid ${gray200}`
            })}
        >
            <Checkbox
                label={
                    <Trans
                        i18nKey="Auth##user consent text"
                        components={{
                            1: (
                                <TextLink
                                    size={TEXT_LINK_SIZES.Normal}
                                    href={`https://teltonika-iot-group.com/${translatedLinkPart}/terms-of-service`}
                                    target="_blank"
                                    rel="noreferrer"
                                    underline="none"
                                    css={css({ display: "inline" })}
                                >
                                    Terms of Service
                                </TextLink>
                            ),
                            2: (
                                <TextLink
                                    size={TEXT_LINK_SIZES.Normal}
                                    href={`https://teltonika-iot-group.com/${translatedLinkPart}/data-processing-agreement`}
                                    target="_blank"
                                    rel="noreferrer"
                                    underline="none"
                                    css={css({ display: "inline" })}
                                >
                                    Data Processing Agreement
                                </TextLink>
                            )
                        }}
                    />
                }
                onChange={(_, value) => toggleValue(value)}
                customStyle={{ margin: "16px 0" }}
            />
        </div>
    );
};

export default TermsAndConditions;
