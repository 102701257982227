/** @jsxImportSource @emotion/react */

import { Fragment } from "react";
import { Grid } from "@mui/material";

import useCompanyLevelContent from "../../../useCompanyLevelContent";
import CompanyLevelBodyItem from "../../../shared/components/CompanyLevelBodyItem";

import { COMPANY_LEVELS } from "../../../../../shared";

const CompanyLevelDesktopBody = () => {
    const {
        companyLevelBodyRows,
        getCompanyLevelDesktopCSS,
        getGridItemCSS,
        getIndexInfo
    } = useCompanyLevelContent();

    return (
        <>
            {companyLevelBodyRows.map(item => (
                <Fragment key={item.info}>
                    {Object.values(COMPANY_LEVELS).map((entry, index) => {
                        const { isFirst, isLast, entryLevel } = getIndexInfo(
                            index,
                            item
                        );

                        return (
                            <Grid
                                key={`${entry}_body`}
                                item
                                xs0={3}
                                md={3}
                                lg={3}
                                css={getGridItemCSS(isLast, false, isFirst)}
                            >
                                <CompanyLevelBodyItem
                                    companyLevel={entryLevel}
                                    itemCSS={getCompanyLevelDesktopCSS(
                                        item.params,
                                        isLast,
                                        isFirst
                                    )}
                                    tooltipText={
                                        isFirst ? item.infoTooltip : undefined
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Fragment>
            ))}
        </>
    );
};

export default CompanyLevelDesktopBody;
