import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CREATE_VALUE_NAMES } from "../CreateDesktopLicense/types";

import { useStringInputValidation } from "../../../../../../shared";

export const useLicenseValidation = (nameValue: string) => {
    const { t } = useTranslation();
    const { stringInputError: nameError } = useStringInputValidation(nameValue);

    const [inputValueError, setInputValueError] = useState({
        [CREATE_VALUE_NAMES.HardwareKey]: null,
        [CREATE_VALUE_NAMES.ClientIp]: null
    });

    const { hardware_key: hardwareKeyError, client_ip: ipv4Error } =
        inputValueError;

    const isValidHardwareKey = (value: string) => {
        if (value === "") {
            return true;
        }

        const hardwareKeyRegex = /^([A-Z0-9]{4}-){7}[A-Z0-9]{4}$/;

        return hardwareKeyRegex.test(value);
    };

    const isValidIpv4 = (value: string) => {
        if (value === "") {
            return true;
        }

        const part1 = "25[0-5]"; // Matches 250-255
        const part2 = "2[0-4]\\d"; // Matches 200-249
        const part3 = "[01]?\\d\\d?"; // Matches 0-199

        const ipv4Regex = new RegExp(
            `^(${part1}|${part2}|${part3})(\\.(${part1}|${part2}|${part3})){3}$`
        );

        return ipv4Regex.test(value);
    };

    const validateHardwareKeyOrIpv4 = (
        inputName: CREATE_VALUE_NAMES.HardwareKey | CREATE_VALUE_NAMES.ClientIp,
        value: string
    ) => {
        const isHardwareKeyInput = inputName === CREATE_VALUE_NAMES.HardwareKey;

        const isValid = isHardwareKeyInput
            ? isValidHardwareKey(value)
            : isValidIpv4(value);

        if (!isValid) {
            const error = t(
                `Dialog##invalid ${isHardwareKeyInput ? "hardware key" : "ipv4"}`
            );

            setInputValueError(prevValue => ({
                ...prevValue,
                [inputName]: error
            }));
        } else {
            setInputValueError(prevValue => ({
                ...prevValue,
                [inputName]: null
            }));
        }
    };

    const resetError = (hardwareKey: boolean, clientIp: boolean) =>
        setInputValueError({
            [CREATE_VALUE_NAMES.HardwareKey]: hardwareKey
                ? null
                : hardwareKeyError,
            [CREATE_VALUE_NAMES.ClientIp]: clientIp ? null : ipv4Error
        });

    return {
        nameError,
        hardwareKeyError,
        ipv4Error,
        isValidHardwareKey,
        isValidIpv4,
        validateHardwareKeyOrIpv4,
        resetError
    };
};
