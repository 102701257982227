/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CompanyLevel from "./CompanyLevel";

import Title from "../../Title";

import ParamsContext from "../../../../context/params/paramsContext";

const CompanyLevelWidget = () => {
    const { rootCompanyLevel } = useContext(ParamsContext);

    const { t } = useTranslation();

    return (
        <div
            css={css({
                padding: "24px"
            })}
        >
            <Title text={t("General##company level")} />
            <CompanyLevel level={rootCompanyLevel} />
        </div>
    );
};

export default CompanyLevelWidget;
