export enum CUSTOM_SECOND_SELECT {
    Certificate = "certificate",
    Logs = "logs",
    CanAdapters = "canAdapters",
    CanVehicles = "canVehicles",
    FeatureControl = "featureControl"
}

export enum FEATURES {
    Obd = "obd"
}
