/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";

import useTabFilters from "./hooks/useTabFilters";
import { TabFiltersProps } from "./types";

import Tabs from "../DesignComponents/Tabs";
import ThemeContext from "../../context/theme/themeContext";
import { ROUTES } from "../../shared";

const TabFilters = ({ tableName }: TabFiltersProps) => {
    const {
        colorsFacelift: { gray200 }
    } = useContext(ThemeContext);

    const { t } = useTranslation();
    const { tabIndex } = useTabFilters(tableName);
    const navigate = useNavigate();

    return (
        <div
            css={css({
                padding: "8px 24px 0 24px",
                boxShadow: `inset 0px -1px 0px ${gray200}`
            })}
        >
            <Tabs value={tabIndex}>
                <Tab
                    label={t("General##users")}
                    onClick={() => {
                        navigate(ROUTES.Users);
                    }}
                />

                <Tab
                    label={t("General##userInvite")}
                    onClick={() => {
                        navigate(ROUTES.UserInvite);
                    }}
                />
            </Tabs>
        </div>
    );
};

export default TabFilters;
