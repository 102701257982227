import { useContext } from "react";
import { Navigate } from "react-router-dom";

import TwoFactorRequirement from "./TwoFactorRequirement";
import { PrivateRouteProps } from "./types";

import AuthLoader from "../Loaders/AuthLoader";

import ParamsContext from "../../context/params/paramsContext";
import AuthContext from "../../context/auth/authContext";
import { FILTER_NAMES, ROUTES } from "../../shared";

const PrivateRoute = ({ component, isRestricted }: PrivateRouteProps) => {
    const { queryParams } = useContext(ParamsContext);

    const { isAuthenticated, isAuthLoading, is2FaEnableFlowSeen } =
        useContext(AuthContext);

    const rootCompany = queryParams[FILTER_NAMES.RootCompany];
    const isRootCompanyNotSet = rootCompany === undefined;
    const isLoading = isAuthLoading || (isAuthenticated && isRootCompanyNotSet);

    if (isAuthenticated && isRestricted) {
        return <Navigate to={{ pathname: ROUTES.Dashboard }} replace />;
    }

    if (isAuthenticated && !is2FaEnableFlowSeen) {
        return <TwoFactorRequirement />;
    }

    if (isLoading) {
        return <AuthLoader />;
    }

    return isAuthenticated ? (
        <>{component}</>
    ) : (
        <Navigate to={{ pathname: ROUTES.Login }} replace />
    );
};

export default PrivateRoute;
