/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, Fragment, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import ColumnsToShow from "./ColumnsToShow";
import RowsPerPage from "./RowsPerPage";
import InitialContent from "./InitialContent";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import Menu from "../../DesignComponents/Menu";
import TableViewIcon from "../../../assets/customIcons/actions/TableViewIcon";
import { MenuAnchorEl, RectangleLoader } from "../../../shared";

export type TableViewContent = "initial" | "columns" | "rows";
export type SetContent = (newContent: TableViewContent) => void;

interface ITableViewProps {
    isInitialLoading?: boolean;
}

const TableView = ({ isInitialLoading }: ITableViewProps) => {
    const { t } = useTranslation();

    const [content, setContent] = useState<TableViewContent>("initial");
    const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

    const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setContent("initial");
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    const handleSetContent = (newContent: TableViewContent) =>
        setContent(newContent);

    const renderContent = () => {
        switch (content) {
            case "columns":
                return <ColumnsToShow setContent={handleSetContent} />;
            case "rows":
                return <RowsPerPage setContent={handleSetContent} />;
            default:
                return <InitialContent setContent={handleSetContent} />;
        }
    };

    return isInitialLoading ? (
        <RectangleLoader
            width={105}
            height={24}
            customStyle={{
                marginLeft: "24px",
                marginRight: "12px",
                "@media(max-width: 599px)": {
                    marginLeft: "0",
                    marginTop: "8px"
                }
            }}
        />
    ) : (
        <Fragment>
            <Button
                color={BUTTON_COLORS.White}
                size={BUTTON_SIZES.Small}
                variant={BUTTON_VARIANTS.IconLeft}
                icon={<TableViewIcon />}
                onClick={handleOpenMenu}
                css={css({
                    marginLeft: "16px",

                    "@media(max-width: 599px)": {
                        marginTop: "2px",
                        marginLeft: "0"
                    }
                })}
            >
                {t("Button##table view")}
            </Button>

            <Menu
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                MenuListProps={{ disableListWrap: true }}
                css={css({
                    "& .MuiMenu-paper": {
                        maxHeight: "384px",
                        minWidth: "276px"
                    }
                })}
            >
                {renderContent()}
            </Menu>
        </Fragment>
    );
};

export default TableView;
