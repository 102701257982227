import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SortAscIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="12" height="7" viewBox="0 0 12 7">
            <path
                d="M2.12021 6.29252L6.00022 2.41252L9.88021 6.29252C10.2702 6.68252 10.9002 6.68252 11.2902 6.29252C11.6802 5.90252 11.6802 5.27252 11.2902 4.88252L6.70021 0.292522C6.31021 -0.0974779 5.68021 -0.0974779 5.29022 0.292522L0.700215 4.88252C0.310215 5.27252 0.310215 5.90252 0.700215 6.29252C1.09021 6.67252 1.73021 6.68252 2.12021 6.29252Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default SortAscIcon;
