import { useTranslation } from "react-i18next";

import { ClearHistoryProps } from "./types";

import Checkbox from "../../../../DesignComponents/Checkbox";

const ClearHistory = ({ customStyle = {}, ...props }: ClearHistoryProps) => {
    const { t } = useTranslation();

    return (
        <Checkbox
            {...props}
            label={t("Dialog##clear device history before transfer")}
            tooltipText={
                props.disabled
                    ? t("Dialog##clear device history mandatory")
                    : ""
            }
            customStyle={customStyle}
        />
    );
};

export default ClearHistory;
export { useClearHistory } from "./hooks";
