import { TABLE_NAMES } from "..";

export const muiOutlinedInputDefaultStylesOverrides = ({
    blue700,
    blue200,
    red700,
    red100,
    gray300,
    textDarkDisabled,
    textPlaceholder
}: any) => {
    return {
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: blue700,
                borderWidth: "1px",
                boxShadow: `-2px -2px 0px ${blue200}, -2px 2px 0px ${blue200}, 2px -2px 0px ${blue200}, 2px 2px 0px ${blue200}`
            },

            "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: red700,
                boxShadow: `-2px -2px 0px ${red100}, -2px 2px 0px ${red100}, 2px -2px 0px ${red100}, 2px 2px 0px ${red100}`
            }
        },

        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: red700
        },

        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: gray300
        },

        "& .MuiOutlinedInput-input": {
            height: "unset",
            padding: "0",

            "&.Mui-disabled": {
                color: textDarkDisabled,
                WebkitTextFillColor: textDarkDisabled
            },

            "&::placeholder": {
                color: textPlaceholder,
                lineHeight: "20px",
                opacity: "1"
            }
        }
    };
};

export const filtersBlockStyle = (hideSearch?: boolean) => {
    return {
        "@media(max-width: 839px)": {
            flex: hideSearch ? "0.5" : "1"
        },

        "@media(max-width: 599px)": {
            flex: hideSearch ? "1" : "unset",
            marginLeft: hideSearch ? "0" : "8px"
        }
    };
};

export const tableColumnDropShadow = (
    scrollHorizontal: boolean | undefined
) => {
    return {
        filter: scrollHorizontal
            ? `drop-shadow(-6px 0px 7px rgba(15, 23, 42, 0.08))`
            : "unset",
        transition: `filter 0.5s ease-in`
    };
};

export const tableColumnRightDropShadow = (
    scrollHorizontal: boolean | undefined
) => {
    return {
        filter: scrollHorizontal
            ? `drop-shadow(6px 0px 7px rgba(15, 23, 42, 0.08))`
            : "unset",
        transition: `filter 0.5s ease-in`
    };
};

export const toolbarPadding = (tableName: TABLE_NAMES) => {
    return tableName === TABLE_NAMES.Tasks ? "16px 0" : "16px 24px";
};
