/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";
import OfflineLicensesIcon from "@mui/icons-material/WifiOffRounded";

import CloneProps from "./CloneProps";

import { TabSectionProps } from "../types";

import SegmentedToggle, {
    TAB_SIZES
} from "../../../DesignComponents/SegmentedToggle";
import {
    TOOLTIP_PLACEMENT,
    TooltipGeneral
} from "../../../DesignComponents/Tooltips";

import InfoIcon from "../../../../assets/customIcons/status/InfoIcon";
import { useMediaQueries } from "../../../../shared";

const TabSection = ({
    tabValue,
    isEnabled,
    handleTabChange
}: TabSectionProps) => {
    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    return (
        <div>
            <SegmentedToggle
                value={tabValue}
                size={TAB_SIZES.Small}
                tabWidth="377px"
                fullWidth={toMd}
                onChange={handleTabChange}
            >
                <Tab label="License details" icon={<InfoIcon />} />

                <CloneProps>
                    {(tabProps: any) => (
                        <TooltipGeneral
                            placement={TOOLTIP_PLACEMENT.Top}
                            title={
                                !isEnabled
                                    ? t("Table##offline licenses tab disabled")
                                    : ""
                            }
                        >
                            <div>
                                <Tab
                                    {...tabProps}
                                    label={t("General##offline licenses")}
                                    icon={<OfflineLicensesIcon />}
                                    disabled={!isEnabled}
                                    css={css({
                                        width: "100%"
                                    })}
                                />
                            </div>
                        </TooltipGeneral>
                    )}
                </CloneProps>
            </SegmentedToggle>
        </div>
    );
};

export default TabSection;
