import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CustomResourceLimitsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M4.5 17C4.5 17.4583 4.875 17.8333 5.33333 17.8333H9.5V16.1667H5.33333C4.875 16.1667 4.5 16.5417 4.5 17ZM4.5 7C4.5 7.45833 4.875 7.83333 5.33333 7.83333H12.8333V6.16667H5.33333C4.875 6.16667 4.5 6.54167 4.5 7ZM12.8333 18.6667V17.8333H18.6667C19.125 17.8333 19.5 17.4583 19.5 17C19.5 16.5417 19.125 16.1667 18.6667 16.1667H12.8333V15.3333C12.8333 14.875 12.4583 14.5 12 14.5C11.5417 14.5 11.1667 14.875 11.1667 15.3333V18.6667C11.1667 19.125 11.5417 19.5 12 19.5C12.4583 19.5 12.8333 19.125 12.8333 18.6667ZM7.83333 10.3333V11.1667H5.33333C4.875 11.1667 4.5 11.5417 4.5 12C4.5 12.4583 4.875 12.8333 5.33333 12.8333H7.83333V13.6667C7.83333 14.125 8.20833 14.5 8.66667 14.5C9.125 14.5 9.5 14.125 9.5 13.6667V10.3333C9.5 9.875 9.125 9.5 8.66667 9.5C8.20833 9.5 7.83333 9.875 7.83333 10.3333ZM19.5 12C19.5 11.5417 19.125 11.1667 18.6667 11.1667H11.1667V12.8333H18.6667C19.125 12.8333 19.5 12.4583 19.5 12ZM15.3333 9.5C15.7917 9.5 16.1667 9.125 16.1667 8.66667V7.83333H18.6667C19.125 7.83333 19.5 7.45833 19.5 7C19.5 6.54167 19.125 6.16667 18.6667 6.16667H16.1667V5.33333C16.1667 4.875 15.7917 4.5 15.3333 4.5C14.875 4.5 14.5 4.875 14.5 5.33333V8.66667C14.5 9.125 14.875 9.5 15.3333 9.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default CustomResourceLimitsIcon;
