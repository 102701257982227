/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import NewsLoader from "./NewsLoader";

import AccordionCustom, {
    NewsData
} from "../../../DesignComponents/AccordionCustom";

import ThemeContext from "../../../../context/theme/themeContext";
import {
    API_IS_BEING_CANCELLED,
    ENDPOINTS,
    LinksTitleLoader,
    useApi,
    useIsMounted
} from "../../../../shared";

const NewsWidget = () => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const isMounted = useIsMounted();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [messages, setMessages] = useState<NewsData[]>([]);
    const [expanded, setExpanded] = useState<string>("panel0");

    const handleCollapse = (panel: string, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };

    useEffect(() => {
        const getMessages = async () => {
            try {
                setLoading(true);

                const {
                    data: { data }
                } = await getData(
                    `${ENDPOINTS.Messages}?sort=created_at&order=desc`
                );

                if (isMounted) {
                    setMessages(data);
                }
            } catch (error) {
                if (isMounted && !isCanceled(error)) {
                    handleResponse(error);
                    setMessages([]);
                }
            }

            setLoading(false);
        };

        getMessages();

        return () => {
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div css={css({ padding: "24px 0 0 24px" })}>
            {isLoading ? (
                <>
                    <LinksTitleLoader />
                    <NewsLoader />
                </>
            ) : (
                <>
                    <div
                        css={css({
                            color: textDark,
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.15px",
                            marginBottom: "16px"
                        })}
                    >
                        {t("Welcome dashboard##news")}
                    </div>

                    <div
                        css={css({
                            height: "255px",
                            overflowY: "auto"
                        })}
                    >
                        {messages.map((entry: NewsData, index: number) => {
                            return (
                                <AccordionCustom
                                    rootPadding="0 16px 0 0"
                                    key={entry.id}
                                    message={entry}
                                    index={index}
                                    onCollapse={handleCollapse}
                                    expandedPanel={expanded}
                                    isLast={messages.length - 1 === index}
                                    entryDate={entry.created_at}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default NewsWidget;
