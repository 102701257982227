/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import { useContext } from "react";

import ThemeContext from "../../context/theme/themeContext";
import { ROUTES, useWindowLocation } from "../../shared";

const GlobalStyles = () => {
    const {
        colorsFacelift: { blue200, blue400, white }
    } = useContext(ThemeContext);

    const { pathname } = useWindowLocation();

    const checkIfToShowBodyScrollBar = (path: string) => {
        switch (path) {
            case ROUTES.Login:
            case ROUTES.Dashboard:
                return "unset";
            default:
                return "hidden";
        }
    };

    return (
        <Global
            styles={css({
                html: {
                    height: "100%",
                    background: white
                },

                body: {
                    height: "100%",
                    margin: "0",
                    fontFamily: '"Open Sans", sans-serif',
                    overflowY: checkIfToShowBodyScrollBar(pathname),
                    overflowX: "hidden",

                    "header + main": {
                        width: "calc(100vw - 18px)"
                    },

                    "@media (max-width: 959px)": {
                        "header + main, #root > footer": {
                            width: "100%"
                        }
                    }
                },

                "#root": {
                    minHeight: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    scrollbarColor: `${blue200} transparent !important`
                },

                "div, ul": {
                    scrollbarWidth: "thin",
                    scrollbarColor: `${blue200} transparent !important`
                },

                "main, .remaining-height": {
                    display: "flex",
                    flexDirection: "column",
                    flex: "1"
                },

                ".navigation-item": {
                    display: "flex",
                    alignItems: "center",
                    padding: "6px",
                    borderRadius: "4px",
                    overflowX: "hidden",
                    transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },

                "h1, h2, h3, h4, h5, h6": {
                    margin: "0",
                    fontWeight: "unset"
                },

                ul: {
                    margin: "0",
                    padding: "0"
                },

                li: {
                    listStyle: "none"
                },

                a: {
                    textDecoration: "none",
                    color: "inherit",
                    WebkitTapHighlightColor: "transparent"
                },

                "@media (min-width: 840px)": {
                    "*": {
                        "&::-webkit-scrollbar": {
                            width: "18px"
                        },

                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: blue200,
                            borderRadius: "100px",
                            border: `5px solid ${white}`,
                            minHeight: "60px"
                        },

                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: blue400
                        }
                    }
                }
            })}
        />
    );
};

export default GlobalStyles;
