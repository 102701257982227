/** @jsxImportSource @emotion/react */

import { Grid } from "@mui/material";

import CompanyLevelHeaderContent from "./CompanyLevelHeaderContent";

import useCompanyLevelContent from "../../../useCompanyLevelContent";
import { COMPANY_LEVEL_DIALOGS } from "../../../shared/types/enums";
import { CompanyLevelHeaderProps } from "../../../shared/types/interfaces";

const CompanyLevelHeader = ({ currentLevel }: CompanyLevelHeaderProps) => {
    const { getGridItemCSS, getIndexInfo } = useCompanyLevelContent();

    return Object.values(COMPANY_LEVEL_DIALOGS).map((entry, index) => {
        const { isFirst, isLast } = getIndexInfo(index);

        return (
            <Grid
                key={`${entry}_header`}
                item
                xs0={3}
                md={3}
                lg={3}
                css={getGridItemCSS(isLast, true, isFirst)}
            >
                <CompanyLevelHeaderContent
                    companyLevelName={entry}
                    currentLevel={currentLevel}
                />
            </Grid>
        );
    });
};

export default CompanyLevelHeader;
