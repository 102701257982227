import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import {
    useWindowLocation,
    isAnalyticsCookieAccepted,
    getEnvironment
} from "..";

import AuthContext from "../../context/auth/authContext";

export const useGoogleAnalytics = (user: any) => {
    const { cookieConsent } = useContext(AuthContext);

    const { pathname, searchStrings, href } = useWindowLocation();
    const location = useLocation();

    const { isProductionEnv } = getEnvironment();

    const [initialized, setInitialized] = useState(false);

    const userExists = Boolean(user?.email);
    const fullPageUrl = `${pathname}${searchStrings}`;
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

    useEffect(() => {
        if (
            isProductionEnv &&
            trackingId &&
            isAnalyticsCookieAccepted(cookieConsent) &&
            !initialized &&
            userExists
        ) {
            ReactGA.initialize(trackingId);
            ReactGA.set({
                dimension1: user.email,
                dimension2: user.company.name
            });

            setInitialized(true);
        }
    }, [
        isProductionEnv,
        trackingId,
        cookieConsent,
        href,
        initialized,
        userExists,
        user
    ]);

    useEffect(() => {
        if (initialized && userExists) {
            ReactGA.set({
                dimension3: fullPageUrl
            });

            ReactGA.send({ hitType: "pageview", page: fullPageUrl });
        }
    }, [initialized, userExists, user, location, fullPageUrl]);
};
