import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CopyButton from "../../../DesignComponents/CopyButton";
import { BUTTON_SIZES } from "../../../DesignComponents/Button";
import DateTime from "../../../DateTime";

import TableContext from "../../../../context/table/tableContext";

const OtherInfo = () => {
    const { detailsData } = useContext(TableContext);

    const { t } = useTranslation();

    const { created_by, created_at, updated_by, updated_at, last_checked_at } =
        detailsData;

    return (
        <>
            <div>{t("Table##other information")}</div>

            <div>
                {created_by !== null && (
                    <>
                        <span>{t("Table##created by")}:</span>

                        <span>
                            {created_by.email}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={created_by.email}
                            />
                        </span>
                    </>
                )}

                <>
                    <span>{t("Table##created at")}:</span>

                    <span>
                        <DateTime date={created_at} />
                    </span>
                </>

                {updated_by !== null && (
                    <>
                        <span>{t("Table##updated by")}:</span>

                        <span>
                            {updated_by.email}

                            <CopyButton
                                size={BUTTON_SIZES.Tiny}
                                copiedValue={updated_by.email}
                            />
                        </span>
                    </>
                )}

                {updated_at !== null && (
                    <>
                        <span>{t("Table##updated at")}:</span>

                        <span>
                            <DateTime date={updated_at} />
                        </span>
                    </>
                )}

                {last_checked_at !== null && (
                    <>
                        <span>{t("Table##last checked at")}:</span>

                        <span>
                            <DateTime date={last_checked_at} />
                        </span>
                    </>
                )}
            </div>
        </>
    );
};

export default OtherInfo;
