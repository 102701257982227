/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../context/theme/themeContext";
import TableContext from "../../../context/table/tableContext";
import GoBack from "./GoBack";
import ListChoice from "../../DesignComponents/ListChoice";
import { SetContent } from "./index";
import { noop } from "../../../shared/functions/functions";
import { Column } from "../../../shared";

interface ColumnsToShowProps {
    setContent: SetContent;
}

const ColumnsToShow = ({ setContent }: ColumnsToShowProps) => {
    const {
        colorsFacelift: { gray300, blue700, white }
    } = useContext(ThemeContext);

    const { columns, toggleActiveColumn, restoreActiveColumns } =
        useContext(TableContext);

    const { t } = useTranslation();

    const areDefaultColumns = columns.every(
        column => Boolean(column.isDefaultActive) === column.isActive
    );

    const [isResetDisabled, setResetDisabled] = useState(areDefaultColumns);

    useEffect(() => {
        setResetDisabled(areDefaultColumns);
    }, [columns, areDefaultColumns]);

    return (
        <Fragment>
            <GoBack setContent={setContent} />

            {columns.map(({ name, isActive, isDisabled }: Column) => {
                return (
                    <ListChoice
                        key={`${name}-${isActive}`}
                        title={t(`Table##${name}`)}
                        selected={isActive}
                        isCheckbox
                        disabled={isDisabled}
                        onClick={() => toggleActiveColumn(name)}
                    />
                );
            })}

            <ListChoice
                title={t("Button##reset to default")}
                onClick={isResetDisabled ? noop : restoreActiveColumns}
                disabled={isResetDisabled}
                css={css({
                    position: "sticky",
                    bottom: "0",
                    background: white,
                    zIndex: "1",
                    color: blue700,
                    borderTop: `1px solid ${gray300}`,
                    paddingTop: "13px",

                    "&:hover": {
                        "&.Mui-disabled": {
                            background: white
                        }
                    },

                    "&.Mui-disabled": {
                        pointerEvents: "auto"
                    }
                })}
            />
        </Fragment>
    );
};

export default ColumnsToShow;
