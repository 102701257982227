/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import ThemeContext from "../../../../../../context/theme/themeContext";

interface DescriptionProps {
    text: string;
}

const Description = ({ text }: DescriptionProps) => {
    const {
        colorsFacelift: { gray700 }
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                color: gray700,
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                marginBottom: "16px"
            })}
        >
            {text}
        </div>
    );
};

export default Description;
