/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import GoBack from "./GoBack";
import { SelectedOptionProps } from "./types";

import ListChoice from "../../../../DesignComponents/ListChoice";
import LanguageContext from "../../../../../context/language/languageContext";
import { noop, useMediaQueries } from "../../../../../shared";

const Language = ({ setContent }: SelectedOptionProps) => {
    const { languages, language, setNewLanguage } = useContext(LanguageContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    return (
        <div
            css={css({
                marginTop: toMd ? "-48px" : "0"
            })}
        >
            <GoBack setContent={setContent} />

            {languages.map(({ id, text, flagSrc }) => {
                const isSelected = id === language;

                return (
                    <ListChoice
                        key={id}
                        title={t(`Languages##${text}`)}
                        iconLeft={<img src={flagSrc} alt="" />}
                        selected={isSelected}
                        css={css({
                            pointerEvents: isSelected ? "none" : "auto"
                        })}
                        onClick={() =>
                            isSelected ? noop() : setNewLanguage(id)
                        }
                    />
                );
            })}
        </div>
    );
};

export default Language;
