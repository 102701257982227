export enum GROUPS {
    General = "general",
    LogFiles = "log files",
    CanObd = "canObd",
    FeatureControl = "feature control",
    Carrier = "carrier"
}

export enum PAYLOAD_ID {
    FileId = "file_id",
    Attributes = "attributes"
}
