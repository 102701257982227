/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import MuiCheckbox from "@mui/material/Checkbox";

import { CombinedCheckboxProps } from "./types";

import { TooltipGeneral } from "../Tooltips";

import ThemeContext from "../../../context/theme/themeContext";
import CheckboxDefaultIcon from "../../../assets/customIcons/checkbox/CheckboxDefaultIcon";
import CheckboxDefaultCheckedIcon from "../../../assets/customIcons/checkbox/CheckboxDefaultCheckedIcon";
import CheckboxDefaultIndeterminateIcon from "../../../assets/customIcons/checkbox/CheckboxDefaultIndeterminateIcon";
import CheckboxDisabledIcon from "../../../assets/customIcons/checkbox/CheckboxDisabledIcon";
import CheckboxDisabledCheckedIcon from "../../../assets/customIcons/checkbox/CheckboxDisabledCheckedIcon";
import CheckboxDisabledIndeterminateIcon from "../../../assets/customIcons/checkbox/CheckboxDisabledIndeterminateIcon";
import CheckboxErrorIcon from "../../../assets/customIcons/checkbox/CheckboxErrorIcon";
import CheckboxErrorCheckedIcon from "../../../assets/customIcons/checkbox/CheckboxErrorCheckedIcon";
import CheckboxErrorIndeterminateIcon from "../../../assets/customIcons/checkbox/CheckboxErrorIndeterminateIcon";

const Checkbox = ({
    label,
    info,
    tooltipText,
    isError,
    customStyle,
    ...props
}: CombinedCheckboxProps) => {
    const {
        colorsFacelift: {
            blue700,
            blue800,
            gray300,
            gray400,
            gray700,
            textDark,
            textDarkDisabled
        }
    } = useContext(ThemeContext);

    const [isHover, setHover] = useState(false);

    const handleHover = (value: boolean) => setHover(value);

    const getIcons = () => {
        if (props.disabled) {
            return {
                icon: <CheckboxDisabledIcon />,
                checkedIcon: <CheckboxDisabledCheckedIcon />,
                indeterminateIcon: <CheckboxDisabledIndeterminateIcon />
            };
        } else if (isError) {
            return {
                icon: <CheckboxErrorIcon />,
                checkedIcon: <CheckboxErrorCheckedIcon />,
                indeterminateIcon: <CheckboxErrorIndeterminateIcon />
            };
        } else {
            return {
                icon: <CheckboxDefaultIcon />,
                checkedIcon: <CheckboxDefaultCheckedIcon />,
                indeterminateIcon: <CheckboxDefaultIndeterminateIcon />
            };
        }
    };

    const icon = getIcons().icon;
    const checkedIcon = getIcons().checkedIcon;
    const indeterminateIcon = getIcons().indeterminateIcon;

    const isDefault = !props.disabled && !isError;

    const getHoverColor = () => {
        if (!isDefault) {
            return blue700;
        }

        return props.indeterminate ? blue800 : gray400;
    };

    const hoverColor = getHoverColor();

    return (
        <label
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            css={css({
                display: "inline-flex",
                alignItems: "center",
                ...customStyle
            })}
        >
            <TooltipGeneral
                title={tooltipText || ""}
                placement="top"
                open={isHover}
            >
                <span
                    css={css({
                        display: "inline-flex",
                        alignSelf: "flex-start"
                    })}
                >
                    <MuiCheckbox
                        data-testid="checkbox"
                        {...props}
                        disableRipple
                        size="small"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        indeterminateIcon={indeterminateIcon}
                        sx={{
                            padding: "0",
                            color: gray300,
                            transition:
                                "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                            "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                                color: blue700
                            },

                            "& > svg": {
                                fontSize: "24px"
                            },

                            "&:hover": {
                                color: hoverColor,

                                "&.Mui-checked": {
                                    color: isDefault ? blue800 : blue700
                                }
                            }
                        }}
                    />
                </span>
            </TooltipGeneral>

            <div
                css={css({
                    cursor: props.disabled ? "auto" : "pointer",
                    marginLeft: label || info ? "8px" : "0",

                    "& > span": {
                        display: "block"
                    }
                })}
            >
                {label && (
                    <span
                        css={css({
                            color: props.disabled ? textDarkDisabled : textDark,
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterspacing: "0.1px"
                        })}
                    >
                        {label}
                    </span>
                )}

                {info && (
                    <span
                        css={css({
                            color: props.disabled ? textDarkDisabled : gray700,
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterspacing: "0.4px",
                            marginTop: label ? "0" : "2px"
                        })}
                    >
                        {info}
                    </span>
                )}
            </div>
        </label>
    );
};

export default Checkbox;
export type { CombinedCheckboxProps } from "./types";
