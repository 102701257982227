import { useContext } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { ThemeContext } from "../../../context/theme";

const CheckboxDefaultIcon = (props: SvgIconProps) => {
    const {
        colorsFacelift: { white }
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill={white}
                stroke="currentColor"
            />
        </SvgIcon>
    );
};

export default CheckboxDefaultIcon;
