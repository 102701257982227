/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import DialogActions from "../../components/DialogActions";
import CanPackageDropdown from "../components/CanPackageDropdown";
import { DropdownValue } from "../../types";

import { DialogProps, PRODUCT_CODE_ACTIONS } from "../../../types";

import Dialog from "../../../../DesignComponents/Dialog";
import InputField, {
    INPUT_FIELD_SIZES
} from "../../../../DesignComponents/InputField";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../context/alert/alertContext";
import ParamsContext from "../../../../../context/params/paramsContext";
import {
    ENDPOINTS,
    TABLE_NAMES,
    maxDialogWidth,
    useApi,
    useMediaQueries,
    useStringInputValidation
} from "../../../../../shared";

const AddProductCode = ({ isOpen, close }: DialogProps) => {
    const { setAlert } = useContext(AlertContext);
    const { setReloadItems } = useContext(ParamsContext);

    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();
    const { postData, handleResponse } = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [productCode, setProductCode] = useState("");
    const [canPackage, setCanPackage] = useState<DropdownValue | null>(null);

    const { stringInputError } = useStringInputValidation(productCode, 200);

    const handleChangeCode = (e: { target: { value: string } }) =>
        setProductCode(e.target.value.trim());

    const handleChangePackage = (option: DropdownValue | null) =>
        setCanPackage(option);

    const handleCloseDialog = () => close(PRODUCT_CODE_ACTIONS.AddProductCode);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const payload = {
                product_code: productCode,
                can_package_id: canPackage?.id || null
            };

            const {
                data: {
                    data: { product_code }
                }
            } = await postData(ENDPOINTS.ProductCodes, payload);

            handleCloseDialog();
            setReloadItems(TABLE_NAMES.ProductCodes);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Flash##product code added", {
                    code: product_code
                })
            });
        } catch (error) {
            handleResponse(error);
        }

        setIsLoading(false);
    };

    return (
        <Dialog
            title={t("Dialog##add new product code")}
            description={t("Dialog##add new product code description")}
            isTitleSeparator
            isActionsSeparator
            open={isOpen}
            close={handleCloseDialog}
            submit={handleSubmit}
            TransitionProps={{
                onExited: () => {
                    setProductCode("");
                    setCanPackage(null);
                }
            }}
            actions={
                <DialogActions
                    onClose={handleCloseDialog}
                    isLoading={isLoading}
                    isReadyToConfirm={productCode && !stringInputError}
                    confirmationButtonText={t("Button##add")}
                />
            }
            css={css(fromMd && maxDialogWidth)}
            extendToMaxWidth={toMd}
        >
            <InputField
                data-testid="add-product-code-input"
                size={INPUT_FIELD_SIZES.Medium}
                fullWidth
                required
                onChange={handleChangeCode}
                labelLeft={t("Table##product code")}
                placeholder={t("Dialog##enter")}
                errorText={stringInputError}
                customStyle={{
                    marginBottom: "16px"
                }}
            />

            <CanPackageDropdown change={handleChangePackage} />
        </Dialog>
    );
};

export default AddProductCode;
