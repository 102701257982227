import { useContext, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ListSectionTitle from "./ListSectionTitle";
import { ListCompanyProps } from "../../types";

import ListChoice from "../../../../DesignComponents/ListChoice";
import ParamsContext from "../../../../../context/params/paramsContext";

const ListCompany = ({ data, setSize, selectCompany }: ListCompanyProps) => {
    const { companyFilteredValues } = useContext(ParamsContext);

    const { t } = useTranslation();

    const { id, name, count } = data;

    const companyItemRef = useRef<any>(null);

    useEffect(() => {
        companyItemRef.current && setSize(companyItemRef.current.clientHeight);
    }, [setSize]);

    const handleClick = () => selectCompany(data);

    const isSelected =
        companyFilteredValues.findIndex(company => company.id === id) !== -1;

    return id === "null" ? (
        <ListSectionTitle ref={companyItemRef}>
            {t(`Dialog##${name}`)} ({count})
        </ListSectionTitle>
    ) : (
        <ListChoice
            ref={companyItemRef}
            title={name}
            isNormalWhiteSpace
            isCheckbox
            onClick={handleClick}
            selected={isSelected}
        />
    );
};

export default ListCompany;
