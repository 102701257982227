import { useMemo, useState } from "react";

export const useScroll = () => {
    const [scrollTop, setScrollTop] = useState<number>(0);
    const [scrollHorizontal, setScrollHorizontal] = useState<boolean>(false);

    const handleScroll = (event: any) => {
        return setScrollTop(event.target.scrollTop);
    };

    const horizontalScroll = (event: React.SyntheticEvent) => {
        return event.currentTarget.scrollLeft
            ? setScrollHorizontal(true)
            : setScrollHorizontal(false);
    };

    const handleScrollHorizontal = useMemo(() => horizontalScroll, []);

    return {
        scrollTop,
        handleScroll,
        scrollHorizontal,
        handleScrollHorizontal
    };
};
