/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import moment from "moment-timezone";

import GoBack from "./GoBack";
import { SelectedOptionProps } from "./types";

import Autocomplete from "../../../../DesignComponents/Autocomplete";
import TimeZoneContext from "../../../../../context/timeZone/timeZoneContext";
import { DROPDOWN_SIZES, useMediaQueries } from "../../../../../shared";

export interface TimeZoneWithOffset {
    name: string;
    offset: string;
}

const TimeZone = ({ setContent }: SelectedOptionProps) => {
    const { currentTimeZone, changeTimeZone } = useContext(TimeZoneContext);

    const { t } = useTranslation();
    const { toMd } = useMediaQueries();

    const formTimeZoneWithOffset = (timeZoneName: string) => {
        return {
            name: timeZoneName,
            offset: moment()
                .tz(timeZoneName)
                .format("Z")
        };
    };

    const [timeZones, setTimeZones] = useState<TimeZoneWithOffset[]>([]);

    const [timeZoneValue, setTimeZoneValue] = useState(
        formTimeZoneWithOffset(currentTimeZone)
    );

    useEffect(() => {
        const countryCodes = moment.tz.countries();

        const names = countryCodes.reduce(
            (list: string[], code) => [
                ...list,
                ...moment.tz.zonesForCountry(code)
            ],
            []
        );

        const uniqueNames = lodash.uniq(names);

        const namesWithOffset = uniqueNames.map(name =>
            formTimeZoneWithOffset(name)
        );

        const compareByName = (
            a: TimeZoneWithOffset,
            b: TimeZoneWithOffset
        ) => {
            if (a.name < b.name && -1) {
                return -1;
            }

            if (a.name > b.name) {
                return 1;
            }

            return 0;
        };

        namesWithOffset.sort(compareByName);

        namesWithOffset.sort((a, b) => {
            const [ahh, amm] = a.offset.split(":");
            const [bhh, bmm] = b.offset.split(":");

            return (
                Number(ahh) * 60 +
                Number(amm) -
                (Number(bhh) * 60 + Number(bmm))
            );
        });

        setTimeZones(namesWithOffset);
    }, []);

    const handleChange = (timeZone: TimeZoneWithOffset) => {
        setTimeZoneValue(timeZone);
        changeTimeZone(timeZone.name);
    };

    return (
        <div
            css={css({
                marginTop: toMd ? "-48px" : "0"
            })}
        >
            <GoBack setContent={setContent} />

            <div
                css={css({
                    padding: "8px 24px 24px 24px"
                })}
            >
                <Autocomplete
                    fullWidth
                    disableClearable
                    size={DROPDOWN_SIZES.Medium}
                    labelLeft={t("General##time zone")}
                    options={timeZones}
                    value={timeZoneValue}
                    groupBy={option => option.offset}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                    }
                    onChange={(
                        _: SyntheticEvent,
                        timeZone: TimeZoneWithOffset
                    ) => handleChange(timeZone)}
                />
            </div>
        </div>
    );
};

export default TimeZone;
