import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { FileUploadActionsProps } from "../../../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";

const FileUploadActions = ({
    isUploaded,
    isLoading,
    isReady,
    resetDialog,
    close
}: FileUploadActionsProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Button
                data-testid="close-upload-file"
                fullWidth
                color={BUTTON_COLORS.Secondary}
                size={BUTTON_SIZES.Normal}
                variant={BUTTON_VARIANTS.TextOnly}
                onClick={close}
            >
                {isUploaded ? t("Button##close") : t("Button##cancel")}
            </Button>

            {isUploaded ? (
                <Button
                    data-testid="add-more-files-button"
                    fullWidth
                    color={BUTTON_COLORS.PrimarySubtle}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    type="submit"
                    onClick={resetDialog}
                >
                    {t("Button##add more files")}
                </Button>
            ) : (
                <Button
                    data-testid="upload-file-button"
                    fullWidth
                    color={BUTTON_COLORS.Primary}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    type="submit"
                    isLoading={isLoading}
                    disabled={!isReady}
                >
                    {t("Button##upload")}
                </Button>
            )}
        </Fragment>
    );
};

export default FileUploadActions;
