import qs from "query-string";
import moment, { Moment } from "moment";

import {
    COMPANY_FILTER,
    COMPANY_LEVELS,
    COMPANY_TYPES,
    DownloadFileParameters,
    ENDPOINTS,
    ENVIRONMENTS,
    FILE_TYPES,
    Filter,
    FILTER_NAMES,
    FilterDropdownOptions,
    FILTERS_WITH_QUOTES,
    FIND_TOOL_FILTER,
    FIRMWARE_FILE_TYPES,
    ID_TYPE,
    LANGUAGES,
    NOT_ASSIGNED,
    NOT_FILTER_NAMES,
    QueryParamName,
    QueryParams,
    Resource,
    RETRIABLE_TASK_TYPES,
    ROOT_COMPANY,
    SPEC_ID_NOT_ASSIGNED_VALUE,
    TABLE_NAMES,
    TASK_STATUSES,
    TASK_TYPES,
    TELTONIKA_FILES_COMPANY,
    TRANSLATABLE_FILTER_NAMES
} from "../";

export const noop = () => {
    // This is intentional
};

export const getValidLanguage = (lang: any, defaultLang: LANGUAGES) => {
    const isValid = Object.values(LANGUAGES).includes(lang);

    return isValid ? (lang as LANGUAGES) : defaultLang;
};

export const isValidParamName = (paramName: string) => {
    const allNames = Object.values({ ...FILTER_NAMES, ...NOT_FILTER_NAMES });
    return allNames.includes(paramName as any);
};

export const isRootCompanyOrCompanyIdFilter = (
    paramName: FILTER_NAMES | NOT_FILTER_NAMES
) =>
    paramName === FILTER_NAMES.RootCompany ||
    paramName === FILTER_NAMES.CompanyId;

export const isFilter = (paramName: string) =>
    Object.values(FILTER_NAMES).includes(paramName as any);

export const isFilterOrSearch = (paramName: string) =>
    isFilter(paramName) || paramName === NOT_FILTER_NAMES.Query;

export const isTranslatableFilter = (filterName: string) =>
    Object.values(TRANSLATABLE_FILTER_NAMES).includes(filterName as any);

export const isFirmwareFile = (fileType: string) =>
    Object.values(FIRMWARE_FILE_TYPES).includes(fileType as any);

export const isCertificateFile = (fileType: string) =>
    fileType === FILE_TYPES.Certificate;

export const isDeviceTreeFile = (fileType: string) =>
    fileType === FILE_TYPES.Dtb;

export const isFailedOrCompletedWithErrorsTask = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Failed ||
    statusId === TASK_STATUSES.CompletedWithErrors;

export const isRetriableTaskType = (taskType: TASK_TYPES) =>
    Object.keys(RETRIABLE_TASK_TYPES).includes(taskType);

export const isPendingOrRunningTask = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Pending || statusId === TASK_STATUSES.Running;

export const isRefreshableTaskType = (statusId: TASK_STATUSES) =>
    statusId === TASK_STATUSES.Pending ||
    statusId === TASK_STATUSES.Running ||
    statusId === TASK_STATUSES.Failed ||
    statusId === TASK_STATUSES.CompletedWithErrors;

export const filteredExistingItems = (
    options: Filter[],
    filterValue: string | string[] | undefined | null
) =>
    options.filter(option => {
        const isArray = Array.isArray(filterValue);
        const id = String(option.id);

        return isArray ? filterValue.includes(id) : id === String(filterValue);
    });

export const getRootCompanyValue = (
    allOptions: FilterDropdownOptions,
    rootCompanyId: string
) => {
    const rootCompanies = allOptions[FILTER_NAMES.RootCompany] || [];

    return filteredExistingItems(rootCompanies, rootCompanyId)[0];
};

export const getFilteredQueryParams = (
    params: QueryParams,
    includeSearch?: boolean
) => {
    const allParams = { ...params };
    const allParamNames = Object.keys(allParams) as QueryParamName[];

    allParamNames.forEach(paramName => {
        const mustBeDeleted = includeSearch
            ? !isFilterOrSearch(paramName)
            : !isFilter(paramName);

        if (mustBeDeleted) {
            delete allParams[paramName];
        }
    });

    return allParams;
};

export const getStringifiedParams = (params: QueryParams) => {
    const copyParams = { ...params };
    delete copyParams[NOT_FILTER_NAMES.Selected];

    return qs.stringify(copyParams, {
        arrayFormat: "comma"
    });
};

export const getIds = (rows: any, idType: ID_TYPE) =>
    rows.map((row: any) => row[idType]);

export const addNotAssigned = (options: Filter[], filterName: FILTER_NAMES) => {
    const isSpecId = isSpecIdFilter(filterName);

    if (isSpecId) {
        const shouldHaveNotAssigned = options.some(
            ({ id }) => id === null || id === 0 || id === 1
        );

        for (let i = options.length - 1; i >= 0; i--) {
            const id = options[i].id;

            if (id === null || id === 0 || id === 1) {
                options.splice(i, 1);
            }
        }

        shouldHaveNotAssigned &&
            options.unshift({
                id: SPEC_ID_NOT_ASSIGNED_VALUE,
                name: NOT_ASSIGNED
            });
    } else {
        options.forEach((field: Filter, i: number) => {
            if (field.id === null) {
                options.splice(i, 1);

                options.unshift({
                    id: "null",
                    name: NOT_ASSIGNED
                });
            }
        });
    }
};

export const parseResource = (resource: Resource, data: any): string | null => {
    const isNestedResource = Array.isArray(resource);

    if (isNestedResource) {
        return resource.reduce((object, objectKey) => {
            return object !== null ? object[objectKey] : null;
        }, data);
    } else {
        return data[resource];
    }
};

export const generateColor = (
    inverted: boolean | undefined,
    firstcolor: string,
    secondColor: string
) => (inverted ? firstcolor : secondColor);

export const isGlobalFile = (companyId: number) =>
    companyId === TELTONIKA_FILES_COMPANY;

export const checkIfDownloadIsAllowed = (data: any) =>
    !isGlobalFile(data.company_id) &&
    !isFirmwareFile(data.type) &&
    !isCertificateFile(data.type) &&
    !isDeviceTreeFile(data.type);

export const downloadFile = async (parameters: DownloadFileParameters) => {
    const {
        data,
        getData,
        handleResponse,
        customEndpoint,
        setLoading,
        removeLoading
    } = parameters;

    try {
        setLoading && setLoading();

        const endpoint =
            customEndpoint || `${ENDPOINTS.Files}/download/${data.id}`;

        const { request } = await getData(endpoint);

        window.location.href = request.responseURL;
    } catch (error) {
        handleResponse(error);
    }

    removeLoading && removeLoading();
};

export const isAnalyticsCookieAccepted = (cookieConsent: any) =>
    cookieConsent.analytics === "true";

export const getEnvironment = () => {
    const env = process.env.REACT_APP_ENVIRONMENT;

    return {
        env,
        isProductionEnv: env === ENVIRONMENTS.Production,
        isTestEnv: env === ENVIRONMENTS.Staging,
        isDevelopmentEnv:
            env === ENVIRONMENTS.Dev ||
            env === ENVIRONMENTS.Development ||
            env === ENVIRONMENTS.DevelopmentLocalApi,
        isNotLocalEnv:
            env !== ENVIRONMENTS.Development &&
            env !== ENVIRONMENTS.DevelopmentLocalApi
    };
};

export const getFilteredCompaniesIds = (companyFilteredValues: Filter[]) => {
    return companyFilteredValues.map(company => company.id);
};

export const getCompanyFilterQueryParamsFromBrowser = (
    filterName: FILTER_NAMES.RootCompany | FILTER_NAMES.CompanyId
) => {
    try {
        const localStorageName =
            filterName === FILTER_NAMES.RootCompany
                ? ROOT_COMPANY
                : COMPANY_FILTER;

        const value: string | string[] = JSON.parse(
            localStorage.getItem(localStorageName) || ""
        );

        return value.length > 0 ? { [filterName]: value } : {};
    } catch (error) {
        return {};
    }
};

export const getQueryParamsFromBrowser = (resource: TABLE_NAMES) => {
    try {
        return (
            JSON.parse(sessionStorage.getItem(`${resource}Query`) || "") || {}
        );
    } catch (error) {
        return {};
    }
};

export const getFirstLetterUppercase = (value: string) => {
    if (value.length) {
        return value[0].toUpperCase() + value.slice(1);
    }

    return value;
};

export const removeFindToolFilterFromStorage = () =>
    localStorage.removeItem(FIND_TOOL_FILTER);

export const isValidNumberValue = (value: string) => {
    const regexNumber = /^[0-9\b]+$/;

    return value === "" || regexNumber.test(value);
};

export const getFilterParamsWithoutRoot = (filterParams: QueryParams) => {
    const copiedParams = { ...filterParams };

    delete copiedParams[FILTER_NAMES.RootCompany];

    return copiedParams;
};

export const generateArrayOfNumbers = (count: number) =>
    Array.from({ length: count }, (_, i) => i + 1);

export const isClientTypeCompany = (type: any) => type === COMPANY_TYPES.Client;

export const isInternalTypeCompany = (type: any) =>
    type === COMPANY_TYPES.Internal;

export const isAllowedToEditCompany = (
    isSystemUser: boolean,
    companyType: COMPANY_TYPES | undefined
) => {
    if (isInternalTypeCompany(companyType)) {
        return isSystemUser;
    }

    return true;
};

export const isInternalOrPremiumLevel = (
    companyLevel: COMPANY_LEVELS | undefined
) =>
    companyLevel === COMPANY_LEVELS.Internal ||
    companyLevel === COMPANY_LEVELS.Premium;

export const isNumericValue = (value: string) => {
    const regexValue = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)?$/;

    return regexValue.test(value);
};

export const isNumericIntegerValue = (value: string) => {
    const regexValue = /^\d+$/;

    return regexValue.test(value);
};

export const disableDates = (
    day: Moment,
    durationAmount: moment.DurationInputArg1,
    durationUnit: moment.unitOfTime.DurationConstructor
) => {
    const today = moment();
    const maxAllowedDate = today.clone().add(durationAmount, durationUnit);

    return (
        moment(day).isSame(moment(), "day") ||
        moment(day).isAfter(maxAllowedDate, "day")
    );
};

export const isEnglish = (language: LANGUAGES) =>
    language === LANGUAGES.English;

export const isSpecIdFilter = (filterName: FILTER_NAMES) =>
    filterName === FILTER_NAMES.SpecId;

export const getFiltersWithQuotes = () => Object.values(FILTERS_WITH_QUOTES);

const specIdHasAllNotAssignedParts = (valueArray: string[]) =>
    valueArray.includes("null") &&
    valueArray.includes("0") &&
    valueArray.includes("1");

const hasNoSpecIdNotAssigned = (value: string) =>
    value !== "null" && value !== "0" && value !== "1";

export const transformIdArrayWithSpecIdNotAssigned = (
    filterName: FILTER_NAMES,
    idArray: string[]
) => {
    const isSpecId = isSpecIdFilter(filterName);

    if (isSpecId) {
        const hasNotAssigned = specIdHasAllNotAssignedParts(idArray);

        return hasNotAssigned
            ? [
                  ...idArray.filter(hasNoSpecIdNotAssigned),
                  SPEC_ID_NOT_ASSIGNED_VALUE
              ]
            : idArray;
    }

    return idArray;
};

export const updateParamsWithCorrectSpecIdValue = (params: QueryParams) => {
    const updatedQueryParams = { ...params };
    const specIdFilterValue = updatedQueryParams[FILTER_NAMES.SpecId];

    if (specIdFilterValue) {
        const isArray = Array.isArray(specIdFilterValue);
        const valueArray = isArray ? specIdFilterValue : [specIdFilterValue];

        // Normalize zero-like values (e.g., "00", "000") to "0"
        const normalizedValueArray = valueArray.map(value => {
            if (/^0+$/.test(value)) {
                return "0";
            }

            return value;
        });

        const hasNotAssignedPart =
            normalizedValueArray.includes("null") ||
            normalizedValueArray.includes("0") ||
            normalizedValueArray.includes("1");

        if (hasNotAssignedPart) {
            const hasAllNotAssignedAllParts =
                specIdHasAllNotAssignedParts(normalizedValueArray);

            if (!hasAllNotAssignedAllParts) {
                updatedQueryParams[FILTER_NAMES.SpecId] =
                    normalizedValueArray.filter(hasNoSpecIdNotAssigned);
            }
        }
    }

    return updatedQueryParams;
};
