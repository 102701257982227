/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { IconRightButtonProps } from "./types";

const IconRightButton = ({ title, icon }: IconRightButtonProps) => {
    return (
        <Fragment>
            {title}

            <span
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                `}
            >
                {icon}
            </span>
        </Fragment>
    );
};

export default IconRightButton;
