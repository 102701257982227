/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import NotificationsContent from "./NotificationsContent";
import { NotificationsDesktopProps } from "./types";

import Menu, {
    centeredAnchorOrigin,
    centeredTransformOrigin
} from "../../../components/DesignComponents/Menu";

const NotificationsDesktop = ({
    anchorEl,
    close
}: NotificationsDesktopProps) => {
    return (
        <Menu
            open={Boolean(anchorEl)}
            onClose={close}
            anchorEl={anchorEl}
            anchorOrigin={centeredAnchorOrigin}
            transformOrigin={centeredTransformOrigin}
            css={css({
                "& .MuiMenu-paper": {
                    width: "564px"
                }
            })}
        >
            <li>
                <NotificationsContent close={close} />
            </li>
        </Menu>
    );
};

export default NotificationsDesktop;
