import { SetStateAction } from "react";

import { ProcessSnackPackOptions, SnackbarData } from "./types";

import { ALERT_STATUS } from "../../context/alert/types";

const processSnackPack = (parameters: ProcessSnackPackOptions) => {
    const {
        status,
        snackPack,
        title,
        description,
        primaryBtn,
        secondaryBtn,
        setSnackPack,
        setOpen
    } = parameters;

    status
        ? setSnackPack([
              ...snackPack,
              { status, title, description, primaryBtn, secondaryBtn }
          ])
        : setOpen(false);
};

const processAlertData = (
    snackPack: SnackbarData[],
    alertStatus: ALERT_STATUS | null,
    isOpen: boolean,
    setAlertData: (value: SetStateAction<SnackbarData>) => void,
    setOpen: (value: SetStateAction<boolean>) => void
) => {
    if (snackPack.length > 0) {
        if (!alertStatus) {
            setAlertData(snackPack.shift()!);
            setOpen(true);
        } else if (alertStatus && isOpen) {
            setOpen(false);
        }
    }
};

export { processSnackPack, processAlertData };
