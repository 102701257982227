import { useContext, useState, useEffect } from "react";

import TableContext from "../../../../context/table/tableContext";
import { getIds, useFormattedNumber } from "../../../../shared";

const useTableHeadToolbar = () => {
    const {
        idType,
        rows,
        selectableRows,
        selectedRows,
        selectMultipleRows,
        deselectMultipleRows
    } = useContext(TableContext);

    const { renderFormattedNumber } = useFormattedNumber();

    const [selected, setSelected] = useState({
        atLeastSingleSelected: false,
        allSelected: false
    });

    useEffect(() => {
        const atLeastOneSelectedOnPage = rows.some((row: any) =>
            selectedRows.includes(row[idType])
        );

        const allSelectedOnPage =
            selectableRows.length > 0 &&
            selectableRows.every((item: any) =>
                selectedRows.includes(item[idType])
            );

        setSelected({
            atLeastSingleSelected: atLeastOneSelectedOnPage,
            allSelected: allSelectedOnPage
        });
    }, [idType, rows, selectableRows, selectedRows]);

    const { atLeastSingleSelected, allSelected } = selected;

    const handleCheckboxChange = () => {
        const ids = getIds(selectableRows, idType);

        atLeastSingleSelected
            ? deselectMultipleRows(ids)
            : selectMultipleRows(ids);
    };

    const rowsCount = renderFormattedNumber(selectedRows.length);

    return {
        atLeastSingleSelected,
        allSelected,
        rowsCount,
        handleCheckboxChange
    };
};

export default useTableHeadToolbar;
