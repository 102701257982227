import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CancelTaskIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M21,8.3V5c0-1.1-0.9-2-2-2h-4.2c-0.4-1.2-1.5-2-2.8-2c-1.3,0-2.4,0.8-2.8,2H5C4.9,3,4.7,3,4.6,3
	c-0.4,0.1-0.7,0.3-1,0.5C3.4,3.8,3.3,4,3.2,4.2C3,4.5,3,4.7,3,5v14c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.5,0.4,0.6
	c0.3,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4,0h3.3c1.7,1.8,4,3,6.7,3c5,0,9-4,9-9C24,12.3,22.8,10,21,8.3z M12,2.8
	c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8C11.2,3.1,11.6,2.8,12,2.8z M5,19V5h14v1.9C17.8,6.3,16.4,6,15,6
	c-5,0-9,4-9,9c0,1.4,0.3,2.8,0.9,4H5z M15,22c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S18.9,22,15,22z"
            />

            <polygon
                points="19.1,12.7 17.6,11.3 15.2,13.7 12.7,11.3 11.3,12.7 13.8,15.1 11.3,17.6 12.7,19 15.2,16.6 17.6,19 
	19.1,17.6 16.6,15.1 "
            />

            <circle fill="none" cx="15" cy="15" r="7" />
        </SvgIcon>
    );
};

export default CancelTaskIcon;
