import { useTranslation } from "react-i18next";

import { DesktopLicenseStatusIconProps } from "./types";

import Badge, { BADGE_COLORS } from "../../../../DesignComponents/Badge";

const DesktopLicenseStatusIcon = ({
    isEnabled
}: DesktopLicenseStatusIconProps) => {
    const { t } = useTranslation();

    return (
        <Badge
            color={isEnabled ? BADGE_COLORS.Success : BADGE_COLORS.Warning}
            inverted
        >
            {t(`Table##license ${isEnabled ? "enabled" : "disabled"}`)}
        </Badge>
    );
};

export default DesktopLicenseStatusIcon;
