/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { DialogConfirmationProps } from "./types";

import Dialog from "../Dialog";

import DialogActions from "../../Actions/dialogs/components/DialogActions";

import { maxDialogWidth, useMediaQueries } from "../../../shared";

const DialogConfirmation = ({
    isOpen,
    description,
    onClose,
    onSubmit,
    title,
    children,
    isLoading,
    isConfirmBtnDisabled,
    confirmBtnTitle,
    confirmBtnTestId,
    onExited
}: DialogConfirmationProps) => {
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    return (
        <Dialog
            data-testid="dialog-confirmation"
            isTitleSeparator
            isActionsSeparator={Boolean(children)}
            title={title || t("Dialog##confirmations##are you sure")}
            description={description}
            open={isOpen}
            close={onClose}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => onExited && onExited()
            }}
            actions={
                <DialogActions
                    onClose={onClose}
                    isLoading={Boolean(isLoading)}
                    isReadyToConfirm={!isConfirmBtnDisabled}
                    confirmationButtonText={
                        confirmBtnTitle || t("Button##confirm")
                    }
                    confirmationButtonTestId={confirmBtnTestId}
                />
            }
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            {children}
        </Dialog>
    );
};

export default DialogConfirmation;
