import { TooltipGeneral } from ".";
import { TooltipOptionalProps, TooltipGeneralProps } from "./types";

export const TooltipOptional = ({
    children,
    isHidden,
    ...props
}: TooltipOptionalProps & TooltipGeneralProps) => {
    if (!isHidden) {
        return <TooltipGeneral {...props}>{children}</TooltipGeneral>;
    }
    return children;
};
