import useDesktopLicenseInlineActions from "../Actions/InlineActions/hooks/useDesktopLicenseInlineActions";
import EditDesktopLicense from "../Actions/dialogs/Licenses/DesktopLicenses/Inline/EditDesktopLicense";
import DeleteDesktopLicense from "../Actions/dialogs/Licenses/DesktopLicenses/Inline/DeleteDesktopLicense";
import MainContainer from "../MainContainer";
import Table from "../Table";
import { LICENSE_ACTIONS } from "../Actions/types";

import {
    ID_TYPE,
    TABLE_NAMES,
    DESKTOP_LICENSE_COLUMNS as COLUMNS,
    ENDPOINTS
} from "../../shared";

const Licenses = () => {
    const {
        inlineActions,
        rowData,
        isEditOpen,
        isDeleteOpen,
        closeLicenseDialog
    } = useDesktopLicenseInlineActions();

    return (
        <>
            <MainContainer>
                <Table
                    name={TABLE_NAMES.DesktopLicenses}
                    initialColumns={COLUMNS}
                    inlineActions={inlineActions}
                    idType={ID_TYPE.Id}
                    customResource={ENDPOINTS.DesktopLicenses}
                    isNotSelectable
                    sortFromNewest
                    hideFilters
                    hasActionBar
                    hasDetails
                />
            </MainContainer>

            <EditDesktopLicense
                data={rowData}
                isOpen={isEditOpen}
                close={() =>
                    closeLicenseDialog(LICENSE_ACTIONS.EditDesktopLicense)
                }
            />

            <DeleteDesktopLicense
                data={rowData}
                isOpen={isDeleteOpen}
                close={() =>
                    closeLicenseDialog(LICENSE_ACTIONS.DeleteDesktopLicense)
                }
            />
        </>
    );
};

export default Licenses;
