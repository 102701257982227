import { Fragment } from "react";

import TextLink, {
    TEXT_LINK_SIZES
} from "../../../../DesignComponents/TextLink";
import QueryDropdown from "../../../../QueryDropdown";
import {
    DROPDOWN_SIZES,
    ENDPOINTS,
    ID_TYPE,
    Query
} from "../../../../../shared";

interface EditDropdownProps {
    isEditable: boolean;
    value: Query | null;
    resource: string;
    handleChange: (groupValue: Query | null) => void;
    onClick?: () => void;
}

const EditDropdown = ({
    isEditable,
    value,
    resource,
    handleChange,
    onClick
}: EditDropdownProps) => {
    const renderContentValue = () => {
        return value ? (
            <TextLink size={TEXT_LINK_SIZES.Normal} onClick={onClick}>
                {value.name}
            </TextLink>
        ) : (
            <Fragment>-</Fragment>
        );
    };

    const renderContent = () => {
        if (isEditable) {
            return (
                <QueryDropdown
                    resource={resource}
                    idType={ID_TYPE.Id}
                    value={value}
                    size={DROPDOWN_SIZES.Tiny}
                    width="201px"
                    disableClearable={resource === ENDPOINTS.Companies}
                    clearOnEscape={resource !== ENDPOINTS.Companies}
                    change={(_, val) => handleChange(val)}
                />
            );
        }

        return renderContentValue();
    };

    return renderContent();
};

export default EditDropdown;
