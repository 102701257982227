import useMediaQuery from "@mui/material/useMediaQuery";

export const useMediaQueries = () => {
    const toSm = useMediaQuery("(max-width: 599px)", { noSsr: true });
    const fromSm = useMediaQuery("(min-width: 600px)", { noSsr: true });
    const toMd = useMediaQuery("(max-width: 839px)", { noSsr: true });
    const fromMd = useMediaQuery("(min-width: 840px)", { noSsr: true });
    const toLg = useMediaQuery("(max-width: 1439px)", { noSsr: true });
    const fromLg = useMediaQuery("(min-width: 1440px)", { noSsr: true });
    const to899 = useMediaQuery("(max-width: 899px)", { noSsr: true });
    const to1099 = useMediaQuery("(max-width: 1099px)", { noSsr: true });

    return {
        fromSm,
        toSm,
        toMd,
        fromMd,
        fromLg,
        toLg,
        to899,
        to1099
    };
};
