import { useReducer, useMemo } from "react";

import AlertContext from "./alertContext";
import AlertReducer from "./alertReducer";
import {
    SET_ALERT,
    REMOVE_ALERT,
    SET_BACKGROUND_ACTION_ID
} from "./alertActions";
import { AlertStateProps, SetAlertOptions } from "./types";

import { BackgroundActionData } from "../../shared";

const AlertState = ({ children }: AlertStateProps) => {
    const initialState = {
        status: null,
        title: null,
        description: null,
        primaryBtn: null,
        secondaryBtn: null,
        hasAutoHide: true,
        backgroundActionId: null,
        backgroundActionSuccessCallback: null,
        backgroundActionFailureCallback: null
    };

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (parameters: SetAlertOptions) => {
        const {
            status,
            title,
            description,
            primaryBtn,
            secondaryBtn,
            hasManualHide
        } = parameters;

        dispatch({
            type: SET_ALERT,
            payload: {
                status,
                title,
                description: description ?? null,
                primaryBtn: primaryBtn ?? null,
                secondaryBtn: secondaryBtn ?? null,
                hasManualHide: Boolean(hasManualHide)
            }
        });
    };

    const removeAlert = () =>
        dispatch({
            type: REMOVE_ALERT
        });

    const setBackgroundActionId = (
        responseData: BackgroundActionData,
        successCallbackFn: () => void,
        failureCallbackFn: () => void
    ) =>
        dispatch({
            type: SET_BACKGROUND_ACTION_ID,
            payload: {
                data: responseData,
                successCallbackFn,
                failureCallbackFn
            }
        });

    const value = useMemo(
        () => ({
            status: state.status,
            title: state.title,
            description: state.description,
            primaryBtn: state.primaryBtn,
            secondaryBtn: state.secondaryBtn,
            hasAutoHide: state.hasAutoHide,
            backgroundActionId: state.backgroundActionId,
            backgroundActionSuccessCallback:
                state.backgroundActionSuccessCallback,
            backgroundActionFailureCallback:
                state.backgroundActionFailureCallback,
            setAlert,
            setBackgroundActionId,
            removeAlert
        }),
        [
            state.status,
            state.title,
            state.description,
            state.primaryBtn,
            state.secondaryBtn,
            state.hasAutoHide,
            state.backgroundActionId,
            state.backgroundActionSuccessCallback,
            state.backgroundActionFailureCallback
        ]
    );

    return (
        <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
    );
};

export default AlertState;
