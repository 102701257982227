/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import useCompanyLevelContent from "../../useCompanyLevelContent";
import AccordionCustom from "../../../DesignComponents/AccordionCustom";

const CompanyLevelFAQ = () => {
    const { frequentlyAskedQuestionsContent } = useCompanyLevelContent();

    const [expanded, setExpanded] = useState<string>("");

    const handleCollapse = (panel: string, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };
    return (
        <div
            data-testid="company-level-faq"
            css={css([
                {
                    background: "white",
                    "> span": {
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "32px",
                        margin: "8px 0 16px 0",
                        textAlign: "center",
                        display: "block"
                    }
                }
            ])}
        >
            <span>FAQ</span>
            {frequentlyAskedQuestionsContent.map(
                (entry: any, index: number) => {
                    return (
                        <AccordionCustom
                            rootPadding="0px"
                            key={`FAQ_${entry.id}`}
                            message={entry}
                            index={index}
                            onCollapse={handleCollapse}
                            expandedPanel={expanded}
                            isLast={
                                frequentlyAskedQuestionsContent.length - 1 ===
                                index
                            }
                            entryDate={entry.created_at}
                        />
                    );
                }
            )}
        </div>
    );
};

export default CompanyLevelFAQ;
