/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import TaskAttributes from "./TaskAttributes";
import TaskGeneralData from "./TaskGeneralData";
import TaskFile from "./TaskFile";
import { TASK_ATTRIBUTES, TaskDetailsProps } from "./types";

import { DEVICE_ACTIONS } from "../../../types";

import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../DesignComponents/Button";
import Dialog from "../../../../DesignComponents/Dialog";

import { maxDialogWidth, useMediaQueries } from "../../../../../shared";

const TaskDetails = ({
    data,
    isOpen,
    setTaskData,
    close
}: TaskDetailsProps) => {
    const { t } = useTranslation();
    const { toMd, fromMd } = useMediaQueries();

    const [separateAttributes, setSeparateAttributes] = useState<any>(null);

    const checkIfAttributesExist = () => {
        if (data?.attributes) {
            try {
                const parsedData = JSON.parse(data.attributes);

                const entries = Object.entries(parsedData);

                const filteredEntries = entries.filter(
                    ([key]) =>
                        key !== TASK_ATTRIBUTES.Price &&
                        key !== TASK_ATTRIBUTES.Message
                );

                setSeparateAttributes(filteredEntries);
            } catch (error) {
                setSeparateAttributes(null);
            }
        }
    };

    const handleCloseDialog = () => close(DEVICE_ACTIONS.TaskDetails);

    const taskTypeName = data ? t(`General##${data.type}`) : "";

    return (
        <Dialog
            data-testid="task-details-dialog"
            isTitleSeparator
            isActionsSeparator
            title={taskTypeName}
            open={isOpen}
            close={handleCloseDialog}
            actions={
                <Button
                    fullWidth
                    color={BUTTON_COLORS.Secondary}
                    size={BUTTON_SIZES.Normal}
                    variant={BUTTON_VARIANTS.TextOnly}
                    onClick={handleCloseDialog}
                >
                    {t("Button##close")}
                </Button>
            }
            TransitionProps={{
                onExited: () => {
                    setTaskData(null);
                    setSeparateAttributes(null);
                },
                onEntering: () => {
                    checkIfAttributesExist();
                }
            }}
            extendToMaxWidth={toMd}
            css={css(fromMd && maxDialogWidth)}
        >
            {separateAttributes && (
                <TaskAttributes separateAttributes={separateAttributes} />
            )}

            <TaskGeneralData data={data} />

            {data?.file_id && <TaskFile id={data.file_id} file={data.file} />}
        </Dialog>
    );
};

export default TaskDetails;
