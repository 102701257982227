import { useContext } from "react";
import { Drawer } from "@mui/material";

import DeviceStatisticsContent from "./DeviceStatisticsContent";

import RoutesContext from "../../context/routes/routesContext";

const DeviceStatisticsDesktop = () => {
    const { isStatisticsOpen } = useContext(RoutesContext);

    return (
        <aside>
            <Drawer
                variant="persistent"
                anchor="right"
                open={isStatisticsOpen}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "236px",
                        top: "56px",
                        bottom: "0",
                        height: "unset",
                        overflowY: "unset"
                    }
                }}
            >
                <DeviceStatisticsContent
                    onScroll
                    contentPadding={"0 0 0 16px"}
                />
            </Drawer>
        </aside>
    );
};

export default DeviceStatisticsDesktop;
