import { useContext, useEffect, useState } from "react";
import _ from "lodash";

import { useTableFunctions } from "./useTableFunctions";

import ParamsContext from "../../../context/params/paramsContext";
import AuthContext from "../../../context/auth/authContext";
import TableContext from "../../../context/table/tableContext";
import {
    API_IS_BEING_CANCELLED,
    TABLE_NAMES,
    useApi,
    useBroadcasting
} from "../../../shared";

export const useTableRowActions = (data: any) => {
    const { setReloadItems } = useContext(ParamsContext);
    const { user } = useContext(AuthContext);

    const { tableName, idType, isInnerTable, updateSingleRowData } =
        useContext(TableContext);

    const { updateTableData, deleteTableData, leaveChannel } =
        useBroadcasting();

    const { cancelSource, isCanceled, getData, handleResponse } = useApi();
    const { getEditRowEndpoint, handleEditRowItemData } = useTableFunctions();

    const [blink, setBlink] = useState(false);

    const id: number = data[idType];

    const isUserCompanyRow =
        tableName === TABLE_NAMES.Companies && id === user?.company_id;

    // Update the user company
    useEffect(() => {
        if (isUserCompanyRow) {
            const updatedData = user?.fullCompany;

            if (updatedData) {
                const areEqual = _.isEqual(data, {
                    ...updatedData,
                    company: { ...updatedData.company, code: updatedData.code }
                });

                if (!areEqual) {
                    updateSingleRowData(updatedData);
                    setBlink(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [isUserCompanyRow, user?.company]);

    useEffect(() => {
        let isSubscribed = true;

        const editRow = async () => {
            if (!isUserCompanyRow) {
                const endpoint = getEditRowEndpoint(tableName, id);

                try {
                    const { data: itemData } = await getData(endpoint);

                    handleEditRowItemData(
                        itemData,
                        isInnerTable,
                        isSubscribed,
                        updateSingleRowData,
                        setBlink,
                        setReloadItems
                    );
                } catch (error) {
                    if (isSubscribed && !isCanceled(error)) {
                        handleResponse(error);
                    }
                }
            }
        };

        updateTableData(tableName, id, editRow);

        deleteTableData(tableName, id, () => setReloadItems(tableName));

        return () => {
            leaveChannel(tableName, id);
            isSubscribed = false;
            cancelSource.cancel(API_IS_BEING_CANCELLED);
        };
        // eslint-disable-next-line
    }, [id]);

    const resetBlink = (e: { animationName: string }) =>
        e.animationName === "blink" && setBlink(false);

    return { blink, resetBlink };
};
