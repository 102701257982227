export enum LOCALES {
    English = "en-gb",
    French = "fr",
    Lithuanian = "lt",
    Portuguese = "pt",
    Russian = "ru",
    Spanish = "es"
}

export enum ROUTES {
    Companies = "/companies",
    Dashboard = "/dashboard",
    DesktopLicenses = "/desktopLicenses",
    DeviceGroups = "/groups",
    Devices = "/devices",
    Files = "/files",
    Login = "/",
    Maintenance = "/maintenance",
    NotFound = "*",
    ProductCodes = "/productCodes",
    RequestReset = "/requestResetPassword",
    Reset = "/resetPassword",
    Signup = "/signup/:token",
    TaskGroups = "/batches",
    UserInvite = "/userInvite",
    Users = "/users"
}

export enum ENDPOINTS {
    BackgroundActions = "backgroundActions",
    Batches = "batches",
    CanAdapterTypes = "seoCanAdapterTypes",
    CanAdapters = "canAdapters",
    Companies = "companies",
    CompanyLevels = "companyLevels",
    CustomResourceLimits = "companies/customResourceLimits",
    DesktopLicenses = "desktop/licenses",
    Devices = "devices",
    Files = "files",
    Groups = "groups",
    Login = "login",
    Logout = "logout",
    Messages = "messages",
    Notifications = "notifications",
    OfflineLicenses = "desktop/offlineLicenses",
    ProductCodes = "productCodes",
    RequestResetPassword = "requestResetPassword",
    ResetPassword = "resetPassword",
    Tasks = "tasks",
    Tokens = "tokens",
    UserInvite = "userInvite",
    Users = "users"
}

export enum TABLE_NAMES {
    Changes = "changes", // inner table
    Companies = "companies",
    DesktopLicenses = "desktopLicenses",
    DeviceGroups = "groups",
    Devices = "devices",
    Files = "files",
    OfflineLicenses = "offlineLicenses", // inner table
    ProductCodes = "productCodes",
    TaskGroups = "batches",
    Tasks = "tasks", // inner table
    UserInvite = "userInvite",
    Users = "users"
}

export enum TABLE_NAMES_WITH_FILTERS {
    DeviceDetailTasks = "device detail tasks",
    DeviceGroups = "groups",
    Devices = "devices",
    Files = "files",
    TaskGroupDetailTasks = "task group details tasks",
    TaskGroups = "batches"
}

export enum TABLE_NAME_RESOURCES_FOR_FILTERS {
    DeviceGroups = "groups",
    Devices = "devices",
    Files = "files",
    TaskGroups = "batches",
    Tasks = "tasks"
}

export enum COLUMN_NAMES {
    BatchId = "batch id",
    CanPackage = "can package",
    ChangedAt = "changed at",
    Code = "code",
    Company = "company",
    Configuration = "configuration",
    CreatedAt = "created at",
    CreatedBy = "created by",
    Description = "description",
    DeviceCompany = "device company",
    DeviceImei = "device imei",
    DevicesCount = "devices count",
    Email = "email",
    Expiration = "expiration",
    ExpiresAt = "expires at",
    Field = "field",
    FileType = "file type",
    Firmware = "firmware",
    Group = "group",
    GsmNumber = "gsm number",
    Iccid = "iccid",
    Id = "id",
    Imei = "imei",
    Imsi = "imsi",
    InvitedBy = "invited by",
    LastCheckedAt = "last checked at",
    LastUsedAt = "last used at",
    LicenseName = "license name",
    Model = "model",
    ModemVersion = "modem version",
    Name = "name",
    ParentCompany = "parent company",
    ProductCode = "product code",
    Role = "role",
    Queue = "queue",
    Schedule = "schedule",
    SeenAt = "seen at",
    Serial = "serial",
    Size = "size",
    Status = "status",
    TaskId = "task id",
    TaskType = "task type",
    Type = "type",
    UpdatedAt = "updated at",
    UpdatedBy = "updated by",
    ValueFrom = "value from",
    ValueTo = "value to",
    VipStatus = "vip status"
}

export enum COLUMN_RESOURCES {
    Attribute = "attribute",
    BatchId = "batch_id",
    CanPackage = "can_package",
    Code = "code",
    Company = "company",
    CompanyName = "company_name",
    Configuration = "configuration",
    CreatedAt = "created_at",
    CreatedBy = "created_by",
    CreatedByEmail = "created_by_email",
    CurrentConfiguration = "current_configuration",
    CurrentFirmware = "current_firmware",
    Description = "description",
    DesktopLicenseCreated = "desktopLicenseCreated",
    DesktopLicenseStatusIcon = "enabled",
    DeviceImei = "device_imei",
    DevicesCount = "devices_count",
    DeviceStatusIcon = "activity_status",
    Email = "email",
    ExpiresAt = "expires_at",
    Firmware = "firmware",
    GroupName = "group_name",
    GsmNumber = "gsm_number",
    Iccid = "iccid",
    Id = "id",
    Imei = "imei",
    Imsi = "imsi",
    InvitedBy = "invited_by",
    LastCheckedAt = "last_checked_at",
    LastUsedAt = "last_used_at",
    Model = "model",
    ModemVersion = "modem_version",
    Name = "name",
    New = "new",
    Old = "old",
    ProductCode = "product_code",
    Role = "role",
    QueueIcon = "task_queue",
    Schedule = "schedule",
    SeenAt = "seen_at",
    Serial = "serial",
    Size = "size",
    TaskStatusIcon = "status_id",
    Type = "type",
    UpdatedAt = "updated_at",
    UpdatedBy = "updated_by",
    UpdatedByEmail = "updated_by_email",
    VipStatus = "vip_status"
}

export enum SORT_RESOURCES {
    BatchId = "batch_id",
    CanPackage = "can_package",
    Code = "code",
    CompanyName = "company_name",
    ConfigurationName = "configuration_name",
    CreatedAt = "created_at",
    CreatedBy = "created_by",
    CreatedByEmail = "created_by_email",
    CurrentCfg = "current_configuration",
    CurrentFw = "current_firmware",
    Description = "description",
    DeviceImei = "device_imei",
    DevicesCount = "devices_count",
    Email = "email",
    Enabled = "enabled",
    ExpiresAt = "expires_at",
    FirmwareName = "firmware_name",
    GroupName = "group_name",
    Id = "id",
    Imei = "imei",
    InvitedBy = "invited_by",
    LastCheckedAt = "last_checked_at",
    Model = "model",
    Name = "name",
    New = "new",
    Old = "old",
    ProductCode = "product_code",
    SeenAt = "seen_at",
    Serial = "serial",
    Size = "size",
    Type = "type",
    UpdatedAt = "updated_at",
    UpdatedBy = "updated_by",
    UpdatedByEmail = "updated_by_email"
}

export enum FILTER_NAMES {
    ActivityStatus = "activity_status",
    Approvable = "approvable",
    BatchId = "batch_id",
    CompanyId = "company_id",
    ConfigurationFileId = "configuration_file_id",
    CreatedBy = "created_by",
    CurrentFw = "current_firmware",
    CurrentCfg = "current_configuration",
    DeviceImei = "device_imei",
    FirmwareFileId = "firmware_file_id",
    GroupId = "group_id",
    Id = "id",
    Model = "model",
    RootCompany = "root",
    SpecId = "spec_id",
    StatusId = "status_id",
    TaskQueue = "task_queue",
    TaskType = "task_type",
    Type = "type",
    WebEditorSupport = "web_editor_support"
}

export enum NOT_FILTER_NAMES {
    Query = "query",
    Sort = "sort",
    Order = "order",
    Page = "page",
    PerPage = "per_page",
    Selected = "selected"
}

export enum TRANSLATABLE_FILTER_NAMES {
    ActivityStatus = "activity_status",
    TaskQueue = "task_queue",
    StatusId = "status_id",
    Type = "type",
    TaskType = "task_type"
}

export enum FILE_TYPES {
    AdasFw = "adas_firmware",
    ApxFw = "apx_firmware",
    BlackBox = "black_box",
    BleFw = "ble_fw",
    BlueNrg = "blue_nrg",
    BundleMainDtb = "fw_bundle_dtb",
    BundleMainFw = "fw_bundle_mainfw",
    CanChipFw = "can_chip_firmware",
    CarrierApxFw = "carrier_apx_firmware",
    CarrierApxSettings = "carrier_apx_settings",
    CarrierAdvanceFw = "carrier_advance_firmware",
    CarrierAdvanceSettings = "carrier_advance_settings",
    CarrierExtractions = "carrier_extractions",
    CarrierLoggerFiles = "carrier_logger_files",
    Certificate = "certificate",
    Configuration = "configuration",
    DeviceBulkAttributeUpdate = "device_bulk_attribute_update",
    DeviceExportFileCsv = "device_export_file_csv",
    DeviceExportFileXlsx = "device_export_file_xlsx",
    DeviceImportFile = "DeviceImportFile",
    DsmFw = "dsm_firmware",
    Dtb = "dtb",
    Firmware = "firmware",
    FirmwareBundle = "fw_bundle",
    FmObdChipFw = "obd_chip_firmware",
    GlobalFw = "global_firmware",
    GnssmDownloadAssistant = "gnssm_download_assistant",
    GnssmFw = "gnssm_firmware",
    IdCollection = "id_collection_file",
    Log = "log",
    MeiGModemFw = "meig_modem_firmware",
    Psub = "psub",
    QuectelModemFw = "quectel_modem_firmware",
    WdLog = "wd_log"
}

export enum FIRMWARE_FILE_TYPES {
    AdasFw = "adas_firmware",
    ApxFw = "apx_firmware",
    BleFw = "ble_fw",
    BlueNrg = "blue_nrg",
    BundleMainDtb = "fw_bundle_dtb",
    BundleMainFw = "fw_bundle_mainfw",
    CanChipFw = "can_chip_firmware",
    CarrierAdvanceFw = "carrier_advance_firmware",
    CarrierApxFw = "carrier_apx_firmware",
    DsmFw = "dsm_firmware",
    Firmware = "firmware",
    FirmwareBundle = "fw_bundle",
    GlobalFw = "global_firmware",
    GnssmDownloadAssistant = "gnssm_download_assistant",
    GnssmFw = "gnssm_firmware",
    MeiGModemFw = "meig_modem_firmware",
    QuectelModemFw = "quectel_modem_firmware"
}

export enum TASK_TYPES {
    RxBlackBox = "RxBlackBox",
    RxCarrierExtractions = "RxCarrierExtractions",
    RxCarrierLoggerFiles = "RxCarrierLoggerFiles",
    RxConfiguration = "RxConfiguration",
    RxLog = "RxLog",
    RxWdLog = "RxWdLog",
    SetBindSim = "SetBindSim",
    SetDisableFeature = "SetDisableFeature",
    SetEnableFeature = "SetEnableFeature",
    SetForceLockSim = "SetForceLockSim",
    SetUnbindSim = "SetUnbindSim",
    TxAdasFirmware = "TxAdasFirmware",
    TxBleFirmware = "TxBleFirmware",
    TxBlueNrgFirmware = "TxBlueNrgFirmware",
    TxCanAdapterModeChange = "TxCanAdapterModeChange",
    TxCanAdapterModeChangeInternal = "TxCanAdapterModeChangeInternal",
    TxCanAdapterUpdate = "TxCanAdapterUpdate",
    TxCanChipFirmware = "TxCanChipFirmware",
    TxCanConfiguration = "TxCanConfiguration",
    TxCarrierAdvanceFirmware = "TxCarrierAdvanceFirmware",
    TxCarrierAdvanceSettings = "TxCarrierAdvanceSettings",
    TxCarrierApxFirmware = "TxCarrierApxFirmware",
    TxCarrierApxSettings = "TxCarrierApxSettings",
    TxConfiguration = "TxConfiguration",
    TxDsmFirmware = "TxDsmFirmware",
    TxDtb = "TxDtb",
    TxFirmware = "TxFirmware",
    TxFirmwareBundle = "TxFirmwareBundle",
    TxGNSSMDownloadAssistant = "TxGNSSMDownloadAssistant",
    TxGNSSMFirmware = "TxGNSSMFirmware",
    TxMeigModemDeltaFirmware = "TxMeigModemDeltaFirmware",
    TxPSub = "TxPSub",
    TxQuectelModemDeltaFirmware = "TxQuectelModemDeltaFirmware",
    TxUserCertificate = "TxUserCertificate"
}

export enum RETRIABLE_TASK_TYPES {
    TxAdasFirmware = "TxAdasFirmware",
    TxBleFirmware = "TxBleFirmware",
    TxBlueNrgFirmware = "TxBlueNrgFirmware",
    TxCanAdapterModeChange = "TxCanAdapterModeChange",
    TxCanAdapterModeChangeInternal = "TxCanAdapterModeChangeInternal",
    TxCanAdapterUpdate = "TxCanAdapterUpdate",
    TxCanChipFirmware = "TxCanChipFirmware",
    TxCanConfiguration = "TxCanConfiguration",
    TxCarrierAdvanceFirmware = "TxCarrierAdvanceFirmware",
    TxCarrierAdvanceSettings = "TxCarrierAdvanceSettings",
    TxCarrierApxFirmware = "TxCarrierApxFirmware",
    TxCarrierApxSettings = "TxCarrierApxSettings",
    TxConfiguration = "TxConfiguration",
    TxDsmFirmware = "TxDsmFirmware",
    TxDtb = "TxDtb",
    TxFirmware = "TxFirmware",
    TxFirmwareBundle = "TxFirmwareBundle",
    TxGNSSMDownloadAssistant = "TxGNSSMDownloadAssistant",
    TxGNSSMFirmware = "TxGNSSMFirmware",
    TxMeigModemDeltaFirmware = "TxMeigModemDeltaFirmware",
    TxPSub = "TxPSub",
    TxQuectelModemDeltaFirmware = "TxQuectelModemDeltaFirmware",
    TxUserCertificate = "TxUserCertificate"
}

export enum TASK_STATUSES {
    Pending,
    Running,
    Completed,
    CompletedWithErrors,
    Cancelled,
    Failed,
    Expired,
    PendingApproval
}

export enum TRI_SOURCES {
    Selected = "id_list",
    Filtered = "filter",
    FromFile = "file"
}

export enum LANGUAGES {
    English = "en-US",
    Lithuanian = "lt-LT",
    Russian = "ru-RU",
    Spanish = "es-ES",
    Portuguese = "pt-BR",
    French = "fr-FR"
}

export enum DEVICE_STATISTICS {
    ActivityStatus = "activity_status",
    Model = "model",
    CurrentFw = "current_firmware",
    CurrentCfg = "current_configuration"
}

export enum ACTIVITY_STATUS {
    Inactive = "Inactive",
    Offline = "Offline",
    Online = "Online"
}

export enum TASK_QUEUE {
    Empty = "Empty",
    Pending = "Pending",
    Executing = "Executing"
}

export enum ID_TYPE {
    Imei = "imei",
    Id = "id"
}

export enum FILE_ID_TYPE {
    Imei = "imei",
    SerialId = "sn"
}

export enum PER_PAGE {
    SmallCount = "25",
    MediumCount = "50",
    LargeCount = "100"
}
export enum FILE_UPLOAD_STATUS {
    Success = "success",
    Failed = "failed",
    Ready = "ready"
}

export enum FILE_COUNT {
    File = "file",
    Files = "files"
}

export enum LINK_IDS {
    Users = "users",
    Groups = "groups",
    Files = "files",
    Devices = "devices",
    Tasks = "tasks",
    ChildCompanies = "child companies"
}

export enum ORDERS {
    Ascending = "asc",
    Descending = "desc"
}

export enum COOKIE_NAMES {
    All = "all",
    Analytics = "analytics"
}

export enum ENVIRONMENTS {
    Development = "development", // local
    DevelopmentLocalApi = "developmentLocalApi", // local
    Dev = "dev", // DEV
    Staging = "staging", // TEST
    Production = "production"
}

export enum API_MESSAGES {
    GroupWasAdded = "Group was added."
}

export enum FILTERS_WITH_QUOTES {
    CurrentCfg = FILTER_NAMES.CurrentCfg,
    CurrentFw = FILTER_NAMES.CurrentFw,
    Model = FILTER_NAMES.Model
}

export enum COMPANY_LEVELS {
    Internal = "Internal",
    Basic = "Basic",
    Standard = "Standard",
    Premium = "Premium"
}

export enum COMPANY_TYPES {
    Internal = "Internal",
    Client = "Client",
    Virtual = "Virtual"
}

export enum COMPANY_LEVEL_SETTINGS {
    FeatureSettings = "feature_settings",
    TimeLimitSettings = "time_limit_settings",
    ResourceLimitSettings = "resource_limit_settings"
}

export enum FEATURE_SETTINGS {
    SmartDeviceGroupSupport = "smart_device_group_support",
    TaskScheduleSupport = "task_schedule_support",
    ApiTokenSupport = "api_token_support",
    UserApiRequestRateLimitPerMinute = "user_api_request_rate_limit_per_minute"
}

export enum TIME_LIMIT_SETTINGS {
    TaskExpirationAfterDays = "task_expiration_after_days",
    FileExpirationAfterDays = "file_expiration_after_days",
    DeviceChangeHistoryRetentionPeriodDays = "device_change_history_retention_period_days"
}

export enum RESOURCE_LIMIT_SETTINGS {
    Companies = "companies",
    ConfigurationFilesPerDevice = "configuration_files_per_device",
    TasksPerGroup = "tasks_per_group",
    Users = "users"
}

export enum DROPDOWN_SIZES {
    Tiny = "tiny",
    Small = "small",
    Medium = "medium"
}

export enum USER_ROLES {
    User = 0,
    Support = 5,
    Admin = 10,
    AdminManager = 15,
    SystemUser = 20
}
